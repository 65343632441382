import { searchableColumns } from "./config";

export const filterItem = (item, filteringText) => {
  return searchableColumns
    .map((key) => item[key])
    .some(
      (value) =>
        typeof value === "string" &&
        value.toLowerCase().indexOf(filteringText.toLowerCase()) > -1
    );
};

export const clearFilter = (actions) => {
  actions.setFiltering("");
};
