import React from "react";
import {
  getSectionConstant,
  SECTION_CONSTANT_TYPE,
} from "../../../../util/constants/section";
import { uiToApiMap } from "../../../../util/adapter/toUi/adapter";
import { ColumnLayout, ExpandableSection } from "@amzn/awsui-components-react";
import Header from "@amzn/awsui-components-react/polaris/header";
import Button from "@amzn/awsui-components-react/polaris/button";
import PropTypes from "prop-types";
import { useHistory, useParams } from "react-router-dom";
import { createValueField } from "../../../Review/CreateValueField";
import { canEdit } from "../../../../../external/components/FundRequest/Review/util";

export const OpportunityReviewSectionContainer = ({
  fundingData,
  index,
  step,
  template,
  filterFields,
  schema,
  valueFactory,
}) => {
  const history = useHistory();
  const { id } = useParams();
  const fields = [];

  for (let sectionTitle of template[step].sections) {
    const sectionName = getSectionConstant({
      type: SECTION_CONSTANT_TYPE.NAME,
      value: sectionTitle,
    });

    const apiKey = uiToApiMap[sectionName];

    const filteredFields = filterFields({
      section: schema[sectionTitle],
      program: fundingData.program,
      hide: true,
    });

    for (let field of filteredFields) {
      fields.push(
        createValueField({
          field,
          value: valueFactory({
            apiKey: "opportunityDetail",
            sectionTitle,
            name: field.name,
            data: fundingData[apiKey],
            field,
          }),
        })
      );
    }
  }

  return (
    <React.Fragment key={index}>
      <ExpandableSection
        variant="container"
        defaultExpanded={true}
        header={
          <Header
            actions={
              canEdit({
                status: fundingData["status"],
                stage: fundingData["stage"],
              }) ? (
                <Button
                  onClick={() =>
                    history.push(
                      `/fund-request/${id}/${template[step].name}/edit`
                    )
                  }
                >
                  Edit
                </Button>
              ) : (
                ""
              )
            }
          >
            {template[step].title}
          </Header>
        }
      >
        <ColumnLayout columns={4} variant="text-grid">
          {fields}
        </ColumnLayout>
      </ExpandableSection>
      <br />
    </React.Fragment>
  );
};

OpportunityReviewSectionContainer.propTypes = {
  fundingData: PropTypes.object,
  index: PropTypes.number,
  step: PropTypes.array,
  template: PropTypes.object,
  filterFields: PropTypes.func,
  schema: PropTypes.object,
  valueFactory: PropTypes.func,
};
