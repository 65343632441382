import React from "react";
import { LinkProps } from "@amzn/awsui-components-react";
import Link from "@amzn/awsui-components-react/polaris/link";

/**
 * From Cloudscape: https://code.amazon.com/packages/AWS-UI-Examples/blobs/mainline-3.0/--/src/pages/commons/info-link.tsx
 */

interface InfoLinkProps {
  id?: string;
  ariaLabel?: string;
  onFollow: LinkProps["onFollow"];
}

const InfoLink = (props: InfoLinkProps) => (
  <Link variant="info" {...props}>
    Info
  </Link>
);

export default InfoLink;
