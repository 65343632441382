export const MIGRATION_PHASE_KEY = "migrationPhase";
export const ANNUAL_RUN_RATE_KEY = "annualRunRate";
export const MODERNIZATION_ANNUAL_RUN_RATE_KEY = "modernizationArrUsdAmount";
export const MODERNIZATION_ACKNOWLEDGEMENT_KEY =
  "modernizationIncentiveEligible";
export const ACCURATE_WORKLOAD_ACKNOWLEDGEMENT_KEY =
  "accurateWorkloadAcknowledgement";
export const VMWARE_ACKNOWLEDGEMENT_KEY = "vmwareAcknowledgement";
export const VMWARE_MSP_ACKNOWLEDGEMENT_KEY = "vmwareMSPAcknowledgement";
export const MAP_LITE_THRESHOLD = 500000;

export const MAP_ARR_THRESHOLD = 100000;
export const validateAnnualRunRateForMAP = (annualRunRate, updateError) => {
  if (!annualRunRate || annualRunRate < MAP_ARR_THRESHOLD) {
    updateError(
      ANNUAL_RUN_RATE_KEY,
      "The entered amount must be at least $100,000 or above to qualify for MAP funding."
    );
    return false;
  }
  return true;
};

export const disableStartButton = (state) => {
  if (!state[MODERNIZATION_ACKNOWLEDGEMENT_KEY]) {
    return !(state[MIGRATION_PHASE_KEY] && state[ANNUAL_RUN_RATE_KEY]);
  } else {
    return !(
      state[MIGRATION_PHASE_KEY] &&
      state[ANNUAL_RUN_RATE_KEY] &&
      state[MODERNIZATION_ANNUAL_RUN_RATE_KEY]
    );
  }
};

export const resetState = (state) => {
  if (state[ANNUAL_RUN_RATE_KEY] < MAP_LITE_THRESHOLD) {
    state[MODERNIZATION_ACKNOWLEDGEMENT_KEY] = false;
    state[MODERNIZATION_ANNUAL_RUN_RATE_KEY] = null;
  }
  if (!state[MODERNIZATION_ACKNOWLEDGEMENT_KEY]) {
    state[MODERNIZATION_ANNUAL_RUN_RATE_KEY] = null;
  }
};

export const formatAnnualRunRate = (annualRunRate) => {
  if (annualRunRate) {
    return !isNaN(annualRunRate)
      ? parseFloat(annualRunRate).toLocaleString("en-US")
      : annualRunRate;
  } else {
    return "";
  }
};
