import { IGenericObject } from "shared/programs/migration-acceleration-program/2024/fund-request/types/CommonTypes";
import { MAP_LITE_THRESHOLD } from "shared/programs/migration-acceleration-program/2024/fund-request/utils/constants";
import { MAP2024_MIGRATION_PHASE } from "external/programs/migration-acceleration-program/2024/fund-request/components/create/eligibility/util";

import { removeCurrencyFormatting } from "shared/programs/migration-acceleration-program/2024/fund-request/util/currency";

export const isMobilizeMapLarge = (data: IGenericObject) => {
  return (
    data &&
    isMobilizePhase(data["migrationPhase"]) &&
    isMapLarge(data["arrUsdAmount"])
  );
};

export const isMapLarge = (arrUsdAmount?: string) => {
  return (
    arrUsdAmount &&
    parseInt(removeCurrencyFormatting(arrUsdAmount)) >= MAP_LITE_THRESHOLD
  );
};

export const isMobilizePhase = (migrationPhase?: string) => {
  return migrationPhase && migrationPhase === MAP2024_MIGRATION_PHASE.MOBILIZE;
};

export const isAssessPhase = (migrationPhase?: string) => {
  return migrationPhase && migrationPhase === MAP2024_MIGRATION_PHASE.ASSESS;
};

export const isWwps = (data: IGenericObject) => {
  if (data?.customerType === "PS") {
    return true;
  }
  if (data?.customerBizUnit === "PS") {
    return true;
  }
  return false;
};
