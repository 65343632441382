import { FieldType } from "shared/programs/migration-acceleration-program/2024/fund-request/components/fields/FieldComponent";
import { IFieldSchema } from "../../../../types/SchemaTypes";
import { ValidationType } from "shared/programs/migration-acceleration-program/2024/fund-request/components/fields/validations";
import { SelectOptionType } from "shared/programs/migration-acceleration-program/2024/fund-request/hooks/useSelectOptions";

export const CashClaimActualsSchema: IFieldSchema[] = [
  {
    label: "Claim amount (local currency)",
    keyName: "claimAmount",
    placeholder: "Claim amount in local currency",
    fieldType: FieldType.Input,
    validations: [ValidationType.ValidNumber],
  },
  {
    label: `AWS account ID for actual delivery`,
    optional: true,
    description:
      "If your AWS account ID has changed since submitting your fund request, update it here.",
    keyName: "awsAccountId",
    placeholder: "AWS account ID for actual delivery",
    fieldType: FieldType.Input,
    validations: [ValidationType.AwsAccountId],
  },
  {
    label: "Milestone start date",
    keyName: "startDate",
    placeholder: "YYYY/MM/DD",
    fieldType: FieldType.DatePicker,
  },
  {
    label: "Milestone end date",
    keyName: "endDate",
    placeholder: "YYYY/MM/DD",
    fieldType: FieldType.DatePicker,
  },
  {
    label: "Production date",
    keyName: "productionDate",
    placeholder: "YYYY/MM/DD",
    optional: true,
    fieldType: FieldType.DatePicker,
  },
];

export const VmwareCashClaimActualsSchema: IFieldSchema[] = [
  {
    label: "Claim amount (local currency)",
    keyName: "claimAmount",
    placeholder: "Claim amount in local currency",
    fieldType: FieldType.ReadOnly,
    validations: [ValidationType.ValidNumber],
  },
  {
    label: `AWS account ID for actual delivery`,
    optional: true,
    description:
      "If your AWS account ID has changed since submitting your fund request, update it here.",
    keyName: "awsAccountId",
    placeholder: "AWS account ID for actual delivery",
    fieldType: FieldType.Input,
    validations: [ValidationType.AwsAccountId],
  },
  {
    label: "Milestone start date",
    keyName: "startDate",
    placeholder: "YYYY/MM/DD",
    fieldType: FieldType.DatePicker,
  },
  {
    label: "Milestone end date",
    keyName: "endDate",
    placeholder: "YYYY/MM/DD",
    fieldType: FieldType.DatePicker,
  },
  {
    label: "Production date",
    keyName: "productionDate",
    placeholder: "YYYY/MM/DD",
    optional: true,
    fieldType: FieldType.DatePicker,
  },
  {
    label: "Number of VMs hosted on VMware in scope for migration to AWS",
    keyName: "numberOfVmwareToMigrate",
    fieldType: FieldType.Input,
    optional: false,
    validations: [ValidationType.ValidNumber],
    conditional: ({ data }) => data?.vmwareClaim === true,
  },
  {
    label: "Total number of servers in scope for migration to AWS",
    keyName: "totalNumberOfWorkloadsInUse",
    fieldType: FieldType.Input,
    optional: false,
    validations: [ValidationType.ValidNumber],
    conditional: ({ data }) => data?.vmwareClaim === true,
  },
  {
    label: "Tools used for generating migration report",
    keyName: "toolsUsedForGeneratingMigrationReport",
    fieldType: FieldType.MultiSelect,
    selectOptionType: SelectOptionType.ToolsUsedForGeneratingMigrationReport,
    optional: false,
    conditional: ({ data }) => data?.vmwareClaim === true,
  },
];
