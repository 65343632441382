import React, { useState } from "react";
import { Header } from "@amzn/awsui-components-react";
import TableActions from "./TableActions";
import DelegationModal from "./CreateDelegationModal/DelegationModal";
import PropTypes from "prop-types";
import { getUsername } from "../../../util/services/auth/AuthService";
import { DELEGATION_KEYS, DELEGATION_MODAL_TYPE } from "../DelegationConstants";

const getDefaultDateRange = () => {
  /* default the startDate to now (datetime)
   * default the endDate to 1 day from now (datetime)
   * make the range type absolute.
   */
  let now = new Date();
  let tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  return {
    type: "absolute",
    startDate: now.toISOString(),
    endDate: tomorrow.toISOString(),
  };
};

const DelegationsTableHeader = ({
  counter,
  refresh,
  selectedItems,
  setNotifications,
}) => {
  const [isVisible, showModal] = useState(false);

  const defaultState = {
    [DELEGATION_KEYS.FROM_KEY]: getUsername(),
    [DELEGATION_KEYS.TO_KEY]: "",
    [DELEGATION_KEYS.DELEGATION_PERIOD]: getDefaultDateRange(),
    [DELEGATION_KEYS.REASON_KEY]: "",
  };

  const [state, setState] = useState(defaultState);

  const resetModalState = () => {
    setState(defaultState);
  };

  const showEditModal = () => {
    if (selectedItems.length > 0) {
      const [delegation] = selectedItems;

      const { delegationId, delegatee, startTimestamp, endTimestamp, reason } =
        delegation;
      let delegationPeriod = {
        type: "absolute",
        startDate: new Date(startTimestamp).toISOString(),
        endDate: new Date(endTimestamp).toISOString(),
      };
      setState({
        modalType: DELEGATION_MODAL_TYPE.EDIT,
        delegationId,
        [DELEGATION_KEYS.FROM_KEY]: getUsername(),
        [DELEGATION_KEYS.TO_KEY]: delegatee,
        [DELEGATION_KEYS.DELEGATION_PERIOD]: delegationPeriod,
        [DELEGATION_KEYS.REASON_KEY]: reason,
      });
      showModal(true);
    }
  };

  const showCreateModal = () => {
    resetModalState();
    showModal(true);
  };

  return (
    <React.Fragment>
      <Header
        counter={counter}
        actions={
          <TableActions
            refresh={refresh}
            showCreateModal={showCreateModal}
            showEditModal={showEditModal}
            selectedItems={selectedItems}
            setNotifications={setNotifications}
          />
        }
      >
        Delegations
      </Header>
      <DelegationModal
        isVisible={isVisible}
        refresh={refresh}
        resetModalState={resetModalState}
        setState={setState}
        showModal={showModal}
        state={state}
      />
    </React.Fragment>
  );
};

DelegationsTableHeader.propTypes = {
  counter: PropTypes.string,
  refresh: PropTypes.func,
  selectedItems: PropTypes.array,
  setNotifications: PropTypes.func,
};

export default DelegationsTableHeader;
