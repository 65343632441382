import CashRequestDetailsEditContainer from "../CashRequestDetailsEditContainer";
import {
  attachmentsAdapter,
  cashRequestDetailsAdapter,
  claimInvoiceAdapter,
  migrationDetailsAdapter,
  projectDetailsAdapter,
} from "internal/programs/migration-acceleration-program/2024/fund-request/api/adapters";
import {
  editAttachments,
  editCashRequestDetails,
  editClaimInvoice,
  editMigrationDetails,
  editProjectDetails,
  saveClaimInvoice,
} from "internal/programs/migration-acceleration-program/2024/fund-request/api/onSubmit";
import ProjectDetailsEditContainer from "internal/programs/migration-acceleration-program/2024/fund-request/components/edit/ProjectDetailsEditContainer";
import AttachmentsEditContainer from "internal/programs/migration-acceleration-program/2024/fund-request/components/edit/AttachmentsEditContainer";
import {
  IClaim,
  IMAPFundRequest,
} from "shared/programs/migration-acceleration-program/2024/fund-request/types/SchemaTypes";
import MigrationDetailsEditContainer from "internal/programs/migration-acceleration-program/2024/fund-request/components/edit/MigrationDetailsEditContainer";
import { IGenericObject } from "shared/programs/migration-acceleration-program/2024/fund-request/types/CommonTypes";
import ClaimInvoiceEditContainer from "internal/programs/migration-acceleration-program/2024/fund-request/components/edit/ClaimInvoiceEditContainer/ClaimInvoiceEditContainer";
import { Result, ResultType } from "shared/util/api/util";

export enum EditContainerType {
  Attachments = "attachments",
  ProjectDetails = "project details",
  CashRequestDetails = "cash request details",
  MigrationDetails = "migration details",
  ClaimInvoice = "claim invoice & payment",
}

export interface IEditComponentProps {
  [key: string]: {
    title: string;
    content: IGenericObject;
    adapt?: (
      response: IGenericObject,
      claimId?: string
    ) => IMAPFundRequest | IClaim;
    onSubmit?: (
      data: IMAPFundRequest,
      claimId?: string
    ) => Promise<Result<ResultType>>;
    onSaveDraft?: (
      data: IMAPFundRequest,
      claimId?: string
    ) => Promise<Result<ResultType>>;
  };
}

const EditContainerProps: IEditComponentProps = {
  [EditContainerType.CashRequestDetails]: {
    title: "Cash request details",
    content: CashRequestDetailsEditContainer,
    adapt: cashRequestDetailsAdapter,
    onSubmit: editCashRequestDetails,
  },
  [EditContainerType.MigrationDetails]: {
    title: "Migration details",
    content: MigrationDetailsEditContainer,
    adapt: migrationDetailsAdapter,
    onSubmit: editMigrationDetails,
  },
  [EditContainerType.ProjectDetails]: {
    title: "Project details",
    content: ProjectDetailsEditContainer,
    adapt: projectDetailsAdapter,
    onSubmit: editProjectDetails,
  },
  [EditContainerType.ClaimInvoice]: {
    title: "Claim invoice",
    content: ClaimInvoiceEditContainer,
    adapt: claimInvoiceAdapter,
    onSubmit: editClaimInvoice,
    onSaveDraft: saveClaimInvoice,
  },
  [EditContainerType.Attachments]: {
    title: "Additional Artifacts",
    content: AttachmentsEditContainer,
    adapt: attachmentsAdapter,
    onSubmit: editAttachments,
  },
};

export const getEditComponentProps = (editContainerType: EditContainerType) => {
  return EditContainerProps[editContainerType] || {};
};
