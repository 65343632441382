import React from "react";

import AppLayout from "@amzn/awsui-components-react/polaris/app-layout";
import BreadcrumbGroup from "@amzn/awsui-components-react/polaris/breadcrumb-group";
import MAPCashClaimActualsSubmission from "../../programs/migration-acceleration-program/2024/fund-request/views/MAPCashClaimActualsSubmission";
import {
  HelpPanelContentContext,
  HelpPanelContext,
} from "shared/util/context/help/HelpContext";
import NotificationsProvider from "shared/util/context/notifications/NotificationsContext";
import NotificationsFlashbar from "shared/components/common/notifications/NotificationsFlashbar";
import { getFundRequestId } from "external/util/common/helper";

const SubmitCashClaimActualsPage = () => {
  const { isOpen, toggle } = React.useContext(HelpPanelContext);
  const { getHelpContent } = React.useContext(HelpPanelContentContext);
  const Panel = getHelpContent();

  return (
    <NotificationsProvider>
      <AppLayout
        breadcrumbs={
          <BreadcrumbGroup
            items={[
              { text: "Dashboard", href: "#" },
              {
                text: getFundRequestId(),
                href: "#",
              },
              { text: "Submit cash claim actuals", href: "#" },
            ]}
          />
        }
        contentType="form"
        notifications={<NotificationsFlashbar />}
        toolsOpen={isOpen}
        onToolsChange={toggle}
        tools={Panel}
        headerSelector=".navbar"
        content={<MAPCashClaimActualsSubmission />}
        navigationHide
      />
    </NotificationsProvider>
  );
};

export default SubmitCashClaimActualsPage;
