import React, { useContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useFundRequest } from "shared/programs/migration-acceleration-program/2024/fund-request/hooks/useFundRequest";
import FundRequestDetailsContainer from "internal/programs/migration-acceleration-program/2024/fund-request/components/details/FundRequestDetailsContainer";
import { getFundRequest } from "internal/util/services/data/InternalDataService";
import { IGenericObject } from "shared/programs/migration-acceleration-program/2024/fund-request/types/CommonTypes";
import { RouteOrigin } from "shared/util/constants/RouteState";
import { NotificationsContext } from "shared/util/context/notifications/NotificationsContext";
import { reviewAdapter } from "internal/programs/migration-acceleration-program/2024/fund-request/api/adapters";
import { IRouteState } from "internal/programs/migration-acceleration-program/2024/fund-request/types/SchemaType";

const MAPFundRequestDetails = () => {
  const [data, setData] = useState<IGenericObject>({});
  const { id } = useParams<IGenericObject>();
  const { state } = useLocation<IGenericObject>();
  const { addNotification } = useContext(NotificationsContext);

  const { isLoading, refresh } = useFundRequest({
    id,
    adapt: reviewAdapter,
    setData,
    fetch: getFundRequest,
  });

  const handleDetailsOrigin = ({ state }: { state: IRouteState }) => {
    addNotification({
      content: state?.message,
      type: "success",
    });
  };

  const handleEditOrigin = ({ state }: { state: IRouteState }) => {
    addNotification({
      content: state?.message,
      type: "success",
    });
  };

  const routeStateHandler = ({ origin }: { origin: string }) => {
    const handlers: {
      [key in RouteOrigin]: ({ state }: { state: IRouteState }) => void;
    } = {
      [RouteOrigin.DetailsAction]: handleDetailsOrigin,
      [RouteOrigin.Edit]: handleEditOrigin,
      [RouteOrigin.Wizard]: () => {},
      [RouteOrigin.ClaimSubmission]: () => {},
    };
    return handlers[origin as RouteOrigin];
  };

  useEffect(() => {
    const handle = routeStateHandler({ origin: state?.origin });
    if (!handle) return;
    handle({ state });
  }, []);

  return (
    <>
      <FundRequestDetailsContainer
        fundRequestId={id}
        isLoading={isLoading}
        refresh={refresh}
        data={data}
      />
    </>
  );
};

export default MAPFundRequestDetails;
