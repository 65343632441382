import React from "react";
import PropTypes from "prop-types";
import { ValueFactory } from "./ValueFactory";
import { filterFields, schema } from "../../../config/schema";
import withFeatureFlag from "shared/components/FeatureFlag";
import { FEATURE_FLAG_ENUMS } from "shared/util/constants/featureFlagValues";
import { isSMPLarge } from "shared/util/common/util";
import InternalMigrationEngagementReviewSectionTable from "./MigrationEngagement/MigrationEngagementTable/InternalMigrationEngagementReviewSectionTable";
import { MigrationEngagementSectionContainer } from "./MigrationEngagement/MigrationEngagementGrid/MigrationEngagementSectionContainer";

const MigrationEngagementsContainer = ({
  fundingData,
  index,
  setNotificationsItems,
  page,
  migrationEngagementProps,
}) => {
  if (isSMPLarge(fundingData)) {
    return (
      <React.Fragment>
        {fundingData?.migrationEngagements.length > 1 ? (
          <InternalMigrationEngagementReviewSectionTable
            fundingData={fundingData}
            page={page}
            migrationEngagementProps={migrationEngagementProps}
          ></InternalMigrationEngagementReviewSectionTable>
        ) : (
          <MigrationEngagementSectionContainer
            fundingData={fundingData}
            schema={schema}
            index={index}
            setNotificationsItems={setNotificationsItems}
            valueFactory={ValueFactory}
            filterFields={filterFields}
            page={page}
          />
        )}
        <br />
      </React.Fragment>
    );
  } else {
    return null;
  }
};

MigrationEngagementsContainer.propTypes = {
  fundingData: PropTypes.object,
  index: PropTypes.number,
  setNotificationsItems: PropTypes.func,
  page: PropTypes.string,
  migrationEngagementProps: PropTypes.object,
};

export default withFeatureFlag(
  MigrationEngagementsContainer,
  FEATURE_FLAG_ENUMS.DISPLAY_MPE_RECORDS_table,
  true
);
