import {
  STAGE,
  STATUS,
} from "../../../../shared/util/constants/fundRequestStatusType";
import { PAGE_TO_STAGE, PAGES } from "../../../util/constants/pages";
import {
  getUsername,
  getProfile,
  getInternalRoles,
} from "../../../util/services/auth/AuthService";
import { FUNDING_TYPE } from "../../../../shared/util/constants/programType";
import { ROLES } from "../../../util/constants/roles";

export const ASSIGNEE_TYPE = {
  team: "Team",
  person: "Person",
};

export const AUDIT_CODE = {
  SUCCESS: "Success",
  FAILED: "Failed",
  SAMPLING_ERROR: "Sampling Error",
  ABANDONED: "Abandoned",
  OTHER: "Other",
};

export const isPersonAssigned = ({ username = getUsername(), assigneeId }) => {
  return username === assigneeId;
};

export const isTeamAssigned = ({ profile = getProfile(), assigneeId }) => {
  return profile.includes(assigneeId);
};

export const isAssigned = ({ assigneeId }) => {
  return isPersonAssigned({ assigneeId }) || isTeamAssigned({ assigneeId });
};

export const disableReviewButtons = ({ assignee, page, stage, status }) =>
  status !== STATUS.ACTIVE ||
  stage !== PAGE_TO_STAGE[page] ||
  !isAssigned(assignee);

export const canComplete = ({ page, fundingType, stage }) => {
  let showComplete = false;

  if (
    fundingType === FUNDING_TYPE.CREDIT &&
    page === PAGES.PRE_APPROVAL &&
    stage === STAGE.PRE_APPROVAL
  ) {
    showComplete = true;
  }

  if (
    (fundingType === FUNDING_TYPE.CASH || fundingType === FUNDING_TYPE.COMBO) &&
    page === PAGES.CASH_CLAIM_APPROVAL &&
    stage === STAGE.CASH_CLAIM
  ) {
    showComplete = true;
  }

  return showComplete;
};

export const canAudit = (userRoles = getInternalRoles()) => {
  return (
    userRoles.includes(ROLES.TECH_AUDITOR) ||
    userRoles.includes(ROLES.CASH_CLAIM_AUDITOR) ||
    userRoles.includes(ROLES.TECH_APPROVER) ||
    userRoles.includes(ROLES.FUNDING_ADMIN)
  );
};
