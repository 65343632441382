import React, { useEffect, useState } from "react";
import { isFeatureEnabled } from "../../../../shared/util/services/features/FeatureFlagsService";
import { FEATURE_FLAG_ENUMS } from "../../../../shared/util/constants/featureFlagValues";
import MdfWallet from "../MdfWallet/MdfWallet";
import PropTypes from "prop-types";
import WalletCards from "../../common/Wallet/WalletCards";
import Loading from "../../../../shared/components/common/Loading";

const Wallet = ({ setNotificationsItems }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [pifEnabled, setPifEnabled] = useState(false);

  useEffect(async () => {
    setPifEnabled(await isFeatureEnabled(FEATURE_FLAG_ENUMS.PIF_ENABLED));
    setIsLoading(false);
  }, []);

  if (!isLoading && pifEnabled) {
    return <WalletCards variant="Container" />;
  }

  if (!isLoading && !pifEnabled) {
    return <MdfWallet setNotificationsItems={setNotificationsItems} />;
  }

  return <Loading />;
};

Wallet.propTypes = {
  setNotificationsItems: PropTypes.func,
};

export default Wallet;
