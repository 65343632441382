import AdditionalArtifactsSection from "external/programs/migration-acceleration-program/2024/fund-request/components/create/wizard/Steps/Artifacts/Sections/AdditionalArtifactsSection";
import React from "react";
import { IGenericObject } from "shared/programs/migration-acceleration-program/2024/fund-request/types/CommonTypes";
import { attachFileToCashClaim } from "external/util/services/data/FundRequestService";

const DEFAULT_CLAIM_EXCLUDED_DOCUMENTS: string[] = [
  "Customer Sign Off Template",
  "Migration Completion report",
  "Managed Services Agreement",
];

const ClaimAdditionalArtifactsSection = ({
  claimId,
  data,
  ...props
}: {
  claimId: string;
  data: IGenericObject;
}) => {
  const claimAttach = async ({
    body,
    fundRequestId,
  }: {
    body: unknown;
    fundRequestId: unknown;
  }) => {
    const newBody = {
      ...(body as IGenericObject),
      fundClaimId: claimId,
    };
    const response = await attachFileToCashClaim({
      body: newBody,
      fundRequestId,
      fundClaimId: claimId,
    });
    return response;
  };

  return (
    <AdditionalArtifactsSection
      {...props}
      data={data?.artifacts}
      claimId={claimId}
      excludedDocumentTypes={DEFAULT_CLAIM_EXCLUDED_DOCUMENTS}
      description={
        "Provide additional documentation that supports your cash claim."
      }
      attach={claimAttach}
    />
  );
};

export default ClaimAdditionalArtifactsSection;
