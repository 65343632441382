import Link from "@amzn/awsui-components-react/polaris/link";
import {
  defaultPartnerName,
  defaultPartnerType,
  defaultProgram,
  defaultProjectGeo,
  defaultStage,
  defaultStatus,
  defaultTemplate,
  searchableColumns,
} from "internal/components/Dashboard/ApprovalList/config";
import { INTERNAL_REVIEW_ACTION_TYPE } from "internal/components/common/CommentModal/reviewActionType";
import {
  ALLOWED_STAGE_FOR_REVIEW,
  STAGE_TO_PAGE,
} from "internal/util/constants/pages";
import React from "react";
import { convertSMPtoMAP } from "shared/util/common/convertSMPtoMAP";
import { STAGE } from "shared/util/constants/fundRequestStatusType";
import { FUNDING_TYPE } from "shared/util/constants/programType";
import { getNumberFromString } from "../../../../shared/util/common/helper";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import Checkbox from "@amzn/awsui-components-react/polaris/checkbox";
import PropTypes from "prop-types";

export function matchesCountText(count) {
  return count === 1 ? `1 match` : `${count} matches`;
}

export const generateFundingLink = (fundRequestId, stage) => {
  let result = fundRequestId;
  if (ALLOWED_STAGE_FOR_REVIEW.includes(stage)) {
    result = (
      <Link href={`/${STAGE_TO_PAGE[stage]}/${fundRequestId}/review`}>
        {fundRequestId}
      </Link>
    );
  }

  return result;
};

export const calculateDwellTime = (stageTransitionOn) => {
  const utcTimestamp = new Date().getTime();
  let dwellTime = "Not Available";
  if (stageTransitionOn) {
    dwellTime = `${Math.round(
      (utcTimestamp - Number(stageTransitionOn.timestamp)) /
        (1000 * 60 * 60 * 24)
    )}`;
  }
  return dwellTime;
};

const matchesTemplate = (item, selectedTemplate) => {
  return (
    selectedTemplate.value === defaultTemplate.value ||
    item.template === selectedTemplate.value
  );
};

const matchesProgram = (item, selectedProgram) => {
  return (
    selectedProgram.value === defaultProgram.value ||
    item.program === selectedProgram.value
  );
};

const matchesStage = (item, selectedStage) => {
  return (
    selectedStage.value === defaultStage.value ||
    item.stage === selectedStage.value
  );
};

const matchesStatus = (item, selectedStatus) => {
  return (
    selectedStatus.value === defaultStatus.value ||
    item.status === selectedStatus.value
  );
};

const matchesPartnerType = (item, selectedPartnerType) => {
  return (
    selectedPartnerType.value === defaultPartnerType.value ||
    item.partnerType === selectedPartnerType.value
  );
};

const matchesPartnerName = (item, selectedPartnerName) => {
  return (
    selectedPartnerName.value === defaultPartnerName.value ||
    item.partnerName === selectedPartnerName.value
  );
};

const matchesProjectGeo = (item, selectedProjectGeo) => {
  return (
    selectedProjectGeo.value === defaultProjectGeo.value ||
    item.projectGeo === selectedProjectGeo.value
  );
};

export const prepareSelectOptions = (data, field, defaultOption) => {
  const optionSet = [];

  data.forEach((item) => {
    if (optionSet.indexOf(item[field]) === -1) {
      optionSet.push(item[field]);
    }
  });

  optionSet.sort();

  const options = [defaultOption];

  optionSet.forEach((item) =>
    options.push({ label: convertSMPtoMAP(item), value: item })
  );
  return options;
};

export const filterItem = (
  item,
  filteringText,
  template,
  program,
  stage,
  status,
  partnerType,
  partnerName,
  projectGeo
) => {
  if (!matchesTemplate(item, template)) {
    return false;
  }

  if (!matchesProgram(item, program)) {
    return false;
  }

  if (!matchesStage(item, stage)) {
    return false;
  }

  if (!matchesStatus(item, status)) {
    return false;
  }

  if (!matchesPartnerType(item, partnerType)) {
    return false;
  }

  if (!matchesPartnerName(item, partnerName)) {
    return false;
  }

  if (!matchesProjectGeo(item, projectGeo)) {
    return false;
  }

  return searchableColumns
    .map((key) => item[key])
    .some(
      (value) =>
        typeof value === "string" &&
        value.toLowerCase().indexOf(filteringText.toLowerCase()) > -1
    );
};

export const clearFilter = (
  actions,
  setTemplate,
  setProgram,
  setStage,
  setStatus,
  setPartnerType,
  setPartnerName,
  setProjectGeo
) => {
  actions.setFiltering("");
  setTemplate(defaultTemplate);
  setProgram(defaultProgram);
  setStage(defaultStage);
  setStatus(defaultStatus);
  setPartnerType(defaultPartnerType);
  setPartnerName(defaultPartnerName);
  setProjectGeo(defaultProjectGeo);
};

export const getFundingTypeFromFrId = (fundRequestId) => {
  let fundingType = "";
  if (fundRequestId.toLowerCase().includes(FUNDING_TYPE.CASH.toLowerCase())) {
    fundingType = FUNDING_TYPE.CASH;
  } else if (
    fundRequestId.toLowerCase().includes(FUNDING_TYPE.CREDIT.toLowerCase())
  ) {
    fundingType = FUNDING_TYPE.CREDIT;
  } else if (
    fundRequestId.toLowerCase().includes(FUNDING_TYPE.COMBO.toLowerCase())
  ) {
    fundingType = FUNDING_TYPE.COMBO;
  }
  return fundingType;
};

const canComplete = ({ fundingType, stage }) => {
  let showComplete = false;

  if (fundingType === FUNDING_TYPE.CREDIT && stage === STAGE.PRE_APPROVAL) {
    showComplete = true;
  }
  if (
    (fundingType === FUNDING_TYPE.CASH || fundingType === FUNDING_TYPE.COMBO) &&
    stage === STAGE.CASH_CLAIM
  ) {
    showComplete = true;
  }
  return showComplete;
};

export const getSelectedType = ({ fundingType, stage }) => {
  let newSelectedType = "";
  if (canComplete({ fundingType, stage })) {
    newSelectedType = INTERNAL_REVIEW_ACTION_TYPE.COMPLETE;
  } else {
    newSelectedType = INTERNAL_REVIEW_ACTION_TYPE.APPROVE;
  }
  return newSelectedType;
};

const EQUALS_OPERATORS = {
  "=": (a, b) => a == b,
  "!=": (a, b) => a != b,
};
const OTHER_OPERATORS = {
  ">": (a, b) => a > b,
  "<": (a, b) => a < b,
  ">=": (a, b) => a >= b,
  "<=": (a, b) => a <= b,
};

export const tableOperators = (
  itemStr,
  tokenStr,
  operator,
  valueFunc = getNumberFromString,
  noneValue = "Not Available"
) => {
  const itemValue = valueFunc(itemStr);
  const tokenValue = valueFunc(tokenStr);
  if (operator in EQUALS_OPERATORS) {
    return EQUALS_OPERATORS[operator](itemValue, tokenValue);
  } else {
    // If we are using the none value, the comparision isn't really valid so we will default to equals
    if (tokenStr == noneValue) {
      return EQUALS_OPERATORS["="](itemValue, tokenValue);
    } else {
      return OTHER_OPERATORS[operator](itemValue, tokenValue);
    }
  }
};

export const CustomCheckboxForm = (checkboxOptions) => {
  const CheckboxForm = ({ value, onChange }) => {
    return (
      <FormField>
        {checkboxOptions.map((option, i) => (
          <Checkbox
            key={i}
            checked={(value || []).includes(option.value)}
            onChange={(event) => {
              const newValue = [...(value || [])];
              if (event.detail.checked) {
                newValue.push(option.value);
              } else {
                newValue.splice(newValue.indexOf(option.value), 1);
              }
              onChange(newValue);
            }}
          >
            {option.value}
          </Checkbox>
        ))}
      </FormField>
    );
  };

  CheckboxForm.propTypes = {
    value: PropTypes.array,
    onChange: PropTypes.func,
  };

  return CheckboxForm;
};
