import { Action } from "../constant";

export const PatchOperations = {
  ADD: "add",
  REPLACE: "replace",
  REMOVE: "remove",
  TEST: "test",
};

export const getOperation = {
  [Action.CREATE]: PatchOperations.ADD,
  [Action.EDIT]: PatchOperations.REPLACE,
  [Action.DELETE]: PatchOperations.REMOVE,
};
