import React, { useState } from "react";
import PropTypes from "prop-types";
import Header from "@amzn/awsui-components-react/polaris/header";
import Button from "@amzn/awsui-components-react/polaris/button";
import { SpaceBetween } from "@amzn/awsui-components-react";
import { exportDataToCSV, CSV_TYPE } from "../util";

const TableHeader = ({
  headerName,
  itemsLength,
  allItemsLength,
  refresh,
  data,
  setNotificationsItems,
}) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const handleExport = () => {
    const exportProps = {
      data,
      csvType: CSV_TYPE.CREDITS,
      setNotificationsItems,
    };
    setIsDownloading(true);
    exportDataToCSV(exportProps);
    setIsDownloading(false);
  };
  return (
    <Header
      counter={itemsLength + "/" + allItemsLength}
      actions={
        <SpaceBetween direction="horizontal" size="s">
          <Button
            iconName="refresh"
            variant="normal"
            onClick={async () => await refresh()}
          />
          <Button
            loading={isDownloading}
            text="export"
            variant="primary"
            onClick={handleExport}
          >
            Export
          </Button>
        </SpaceBetween>
      }
    >
      {headerName}
    </Header>
  );
};

TableHeader.propTypes = {
  headerName: PropTypes.string,
  itemsLength: PropTypes.number,
  allItemsLength: PropTypes.number,
  refresh: PropTypes.func,
  data: PropTypes.array,
  setNotificationsItems: PropTypes.func,
};

export default TableHeader;
