import React, { useEffect, useState } from "react";
import { isFeatureEnabled } from "shared/util/services/features/FeatureFlagsService";

export default function withFeatureFlag(Component, flagName, noRender) {
  function WithFeatureFlag(props) {
    const [isEnabled, setIsEnabled] = useState(false);

    useEffect(() => {
      isFeatureEnabled(flagName).then((res) => setIsEnabled(res));
    }, []);

    if (!isEnabled && noRender) {
      return null;
    } else if (!isEnabled) {
      return <Component disabled {...props} />;
    }
    return <Component {...props} />;
  }

  WithFeatureFlag.displayName = `withFeatureFlag(${
    Component.displayName || Component.name
  }`;
  return WithFeatureFlag;
}
