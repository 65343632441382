import Header from "@amzn/awsui-components-react/polaris/header";
import { ColumnLayout, CopyToClipboard } from "@amzn/awsui-components-react";
import Container from "@amzn/awsui-components-react/polaris/container";
import React from "react";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import ValueWithLabel from "shared/programs/migration-acceleration-program/2024/fund-request/components/fields/ValueWithLabel";
import { IReadOnlyComponentProps } from "../../../../../types/SchemaTypes";
import { longLocalDateFormat } from "shared/util/common/date";
import ConditionalField from "shared/components/common/Conditional/ConditionalField";
import { display } from "shared/util/common/display";
import useFeatureFlag from "shared/util/hooks/useFeatureFlag";
import { FEATURE_FLAG_ENUMS } from "shared/util/constants/featureFlagValues";
import { MAP2024_MIGRATION_PHASE } from "../../../../create/eligibility/util";

const ReadOnlyProjectDetails = ({
  data,
  actions = <></>,
}: IReadOnlyComponentProps) => {
  const { fundRequestId, owner, migrationPhase } = data;
  const projectDetails = data["projectDetails"];
  const vmwareAcknowledgement = projectDetails?.vmwareAcknowledgement;
  const isVMwareMapEnabled = useFeatureFlag(
    FEATURE_FLAG_ENUMS.VMWARE_MAP_ENABLED
  );

  const executedState = display({
    data: projectDetails,
    key: "executedState",
  });

  return (
    <Container
      header={
        <Header variant="h2" actions={actions}>
          Project details
        </Header>
      }
    >
      <SpaceBetween size="l">
        <ColumnLayout columns={3} variant="text-grid">
          <SpaceBetween size="l">
            <ValueWithLabel label="Fund request owner">{owner}</ValueWithLabel>
            <ValueWithLabel label="AWS account ID">
              {display({
                data: projectDetails,
                key: "awsAccountId",
              })}
            </ValueWithLabel>
            <ConditionalField
              showField={
                isVMwareMapEnabled &&
                vmwareAcknowledgement &&
                migrationPhase === MAP2024_MIGRATION_PHASE.MOBILIZE
              }
            >
              <ValueWithLabel label="Number of VMs hosted on VMware in scope for migration to AWS">
                {display({
                  data: projectDetails,
                  key: "numberOfVmwareToMigrate",
                })}
              </ValueWithLabel>
            </ConditionalField>
          </SpaceBetween>
          <SpaceBetween size="l">
            <ValueWithLabel label="Project start date">
              {
                display({
                  data: projectDetails,
                  key: "plannedStartDate",
                  format: (value) => longLocalDateFormat(value),
                }) as string
              }
            </ValueWithLabel>
            <ValueWithLabel label="Project country">
              {display({ data: projectDetails, key: "executedCountry" })}
            </ValueWithLabel>
            <ConditionalField
              showField={
                isVMwareMapEnabled &&
                vmwareAcknowledgement &&
                migrationPhase === MAP2024_MIGRATION_PHASE.MOBILIZE
              }
            >
              <ValueWithLabel label="Total number of servers in scope for migration to AWS">
                {display({
                  data: projectDetails,
                  key: "totalNumberOfWorkloadsInUse",
                })}
              </ValueWithLabel>
            </ConditionalField>
          </SpaceBetween>
          <SpaceBetween size="l">
            <ValueWithLabel label="Project end date">
              {
                display({
                  data: projectDetails,
                  key: "plannedEndDate",
                  format: (value) => longLocalDateFormat(value),
                }) as string
              }
            </ValueWithLabel>
            <ConditionalField showField={!!executedState}>
              <ValueWithLabel label="Project state">
                {executedState}
              </ValueWithLabel>
            </ConditionalField>
          </SpaceBetween>
        </ColumnLayout>
        <ValueWithLabel label="Project name">
          {display({
            data: projectDetails,
            key: "activityName",
          })}
        </ValueWithLabel>
        <ValueWithLabel label="Business description">
          {display({
            data: projectDetails,
            key: "description",
          })}
        </ValueWithLabel>
        <ValueWithLabel label="Fund request ID">
          <CopyToClipboard
            textToCopy={fundRequestId || "-"}
            copySuccessText="Copied to clipboard."
            copyErrorText="Failed to copy to clipboard."
            variant="inline"
          />
        </ValueWithLabel>
      </SpaceBetween>
    </Container>
  );
};

export default ReadOnlyProjectDetails;
