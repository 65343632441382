import { Box, Button, SpaceBetween } from "@amzn/awsui-components-react";
import { BULK_ACTION_TYPES_TO_COLUMNS } from "internal/components/BulkActions/config";
import { nanoid } from "nanoid";
import Papa from "papaparse";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import React from "react";

const Submit = ({
  file,
  resetNewBulkAction,
  isDisabled,
  selectedBulkAction,
  setNotificationsItems,
}) => {
  const history = useHistory();

  const isCsvValidAgainstTemplate = (records, bulkActionType) => {
    const actualRecordColumns = Object.keys(records[0]);
    const expectedRecordColumns =
      BULK_ACTION_TYPES_TO_COLUMNS[bulkActionType.value];
    return (
      actualRecordColumns !== undefined &&
      actualRecordColumns.length == expectedRecordColumns.length &&
      JSON.stringify(actualRecordColumns) ===
        JSON.stringify(expectedRecordColumns)
    );
  };
  return (
    <Box float="right">
      <SpaceBetween direction="horizontal" size="m">
        <Button variant="link" onClick={resetNewBulkAction}>
          Cancel
        </Button>
        <Button
          variant="primary"
          disabled={isDisabled}
          onClick={() => {
            file
              .text()
              .then((text) => {
                let parsedCSV = Papa.parse(text, {
                  header: true,
                  // Will skip empty lines and lines without content (whitespaces)
                  skipEmptyLines: "greedy",
                  delimiter: ",",
                });
                let parseErrors = parsedCSV.errors;
                if (parseErrors.length != 0) {
                  if (parseErrors[0].type === "Delimiter") {
                    throw new Error(
                      "Failed to parse CSV. Please make sure the CSV is comma-separated!"
                    );
                  }
                  throw new Error("Failed to parse CSV");
                }
                if (parsedCSV.data.length == 0) {
                  throw new Error("CSV file cannot be empty.");
                }
                return parsedCSV;
              })
              .then((parsedCSV) => {
                let data = parsedCSV.data;
                if (isCsvValidAgainstTemplate(data, selectedBulkAction)) {
                  resetNewBulkAction();
                  history.push({
                    pathname: "/bulk-actions/review",
                    state: {
                      records: parsedCSV.data,
                      bulkActionType: selectedBulkAction,
                      fileName: file.name,
                    },
                  });
                }
                throw new Error(
                  'CSV file doesn\'t match expected format. Please use the template by clicking the "Download Template" button.'
                );
              })
              .catch((error) => {
                setNotificationsItems([
                  {
                    type: "error",
                    dismissible: true,
                    onDismiss: () => setNotificationsItems([]),
                    content: error.message,
                    id: nanoid(10),
                  },
                ]);
                resetNewBulkAction();
              });
          }}
        >
          Submit
        </Button>
      </SpaceBetween>
    </Box>
  );
};

Submit.propTypes = {
  file: PropTypes.object,
  resetNewBulkAction: PropTypes.func,
  isDisabled: PropTypes.bool,
  selectedBulkAction: PropTypes.object,
  setNotificationsItems: PropTypes.func,
};

export default Submit;
