import React, { useEffect } from "react";

import { SpaceBetween } from "@amzn/awsui-components-react";
import DOMPurify from "dompurify";

import PropTypes from "prop-types";

import SectionContainerFactory from "./SectionFactory";
import { TITLE } from "../../../../shared/util/constants/title";
import { useParams } from "react-router-dom";

const Step = ({
  activeIndex,
  data,
  setData,
  errors,
  fieldToIdMap,
  setFieldToIdMap,
  setErrors,
  template,
  validators,
  setNotificationItems,
  showHelperPanel,
}) => {
  useEffect(() => {
    const currentStepName = Object.keys(template)[activeIndex];
    const currentStep = template[currentStepName];
    showHelperPanel({
      title: currentStep.title,
      keys: [currentStep.name],
      open: false,
    });
  }, [activeIndex]);

  const { id } = useParams();
  const updateErrors = (key, value) => {
    setErrors({
      ...errors,
      [key]: value,
    });
  };

  const dataLoad = (data) => {
    setData((originalData) => {
      const fundRequestInfo = {
        fundRequestId: originalData["fundRequestId"],
        fundingType: originalData["fundingType"],
        program: originalData["program"],
        subProgram: originalData["subProgram"],
        migrationPhase: originalData["migrationPhase"],
        annualRunRateUnits: originalData["annualRunRateUnits"],
      };

      return { ...fundRequestInfo, ...data };
    });
  };

  const dataInput = (inputData) => {
    setData((originalData) => ({ ...originalData, ...inputData }));
  };

  const stepName = template && Object.keys(template)[activeIndex];
  const step = template && template[stepName];
  const { title } = step;
  const Section = SectionContainerFactory({
    stepTitle: title,
  });

  useEffect(() => {
    document.title = DOMPurify.sanitize(`${TITLE} - ${id} - ${title}`);
  }, []);

  return (
    <div className="step-details">
      <SpaceBetween size="l">
        <Section
          data={data}
          setData={setData}
          errors={errors}
          dataLoad={dataLoad}
          dataInput={dataInput}
          updateErrors={updateErrors}
          fieldToIdMap={fieldToIdMap}
          setFieldToIdMap={setFieldToIdMap}
          stepTitle={title}
          validators={validators}
          setNotificationItems={setNotificationItems}
          showHelperPanel={showHelperPanel}
        />
      </SpaceBetween>
    </div>
  );
};

Step.propTypes = {
  activeIndex: PropTypes.number,
  data: PropTypes.object,
  setData: PropTypes.func,
  errors: PropTypes.object,
  setErrors: PropTypes.func,
  template: PropTypes.object,
  validators: PropTypes.any,
  fieldToIdMap: PropTypes.object,
  setFieldToIdMap: PropTypes.func,
  setNotificationItems: PropTypes.func,
  showHelperPanel: PropTypes.func,
};

export default React.memo(Step);
