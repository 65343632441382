import React, { useContext, useState } from "react";

import Table from "@amzn/awsui-components-react/polaris/table";
import { CollectionPreferencesProps } from "@amzn/awsui-components-react/polaris/collection-preferences/interfaces";
import {
  filter,
  getColumnsAsVisible,
  getCount,
  getTableCollectionPreferences,
} from "external/programs/migration-acceleration-program/2024/fund-request/components/shared/table/util";
import {
  CollectionPreferences,
  Icon,
  Pagination,
} from "@amzn/awsui-components-react";
import { useCollection } from "@amzn/awsui-collection-hooks";
import TableEmptyState from "external/components/common/TableEmptyState";
import TableNoMatchState from "external/components/common/TableNoMatchState";
import Header from "@amzn/awsui-components-react/polaris/header";
import InfoLink from "shared/components/InfoLink";
import TextFilter from "@amzn/awsui-components-react/polaris/text-filter";
import { IGenericObject } from "shared/programs/migration-acceleration-program/2024/fund-request/types/CommonTypes";
import { SplitPanelContext } from "shared/util/context/split-panel/SplitPanelContext";
import MigrationProgramEngagementSplitPanel from "internal/programs/migration-acceleration-program/2024/fund-request/components/details/sections/migration/split/MigrationProgramEngagementSplitPanel";
import { generateEngagementIdLink } from "../../../../../../../../util/constants/generateEngagementIdLink";

const TABLE_RESOURCE_NAME = "migration program engagements";

const MigrationProgramEngagementsTable = ({
  data,
  isLoading,
}: {
  data: IGenericObject;
  isLoading: boolean;
}) => {
  const { setPanel } = useContext(SplitPanelContext);

  const [migrationProgramEngagements] = useState(
    data?.migrationProgramEngagements || []
  );
  const [preferences, setPreferences] =
    useState<CollectionPreferencesProps.Preferences>({
      pageSize: 10,
      visibleContent: getColumnsAsVisible({ columnDefinitions }),
    });
  const [selectedItems, setSelectedItems] = useState<Array<unknown>>([]);

  const { items, actions, collectionProps, filterProps, paginationProps } =
    useCollection(migrationProgramEngagements || [], {
      filtering: {
        empty: <TableEmptyState resourceName={TABLE_RESOURCE_NAME} />,
        noMatch: (
          <TableNoMatchState onClearFilter={() => actions.setFiltering("")} />
        ),
        filteringFunction: (item, filteringText) => {
          return filter({
            item: item as IGenericObject,
            filteringText,
            columnDefinitions,
          });
        },
      },
      sorting: {},
      selection: {},
      pagination: { pageSize: preferences.pageSize },
    });

  const count = getCount({ collection: migrationProgramEngagements });

  return (
    <Table
      {...collectionProps}
      items={items}
      selectedItems={selectedItems}
      columnDefinitions={columnDefinitions}
      loading={isLoading}
      loadingText={`Loading ${TABLE_RESOURCE_NAME}`}
      selectionType="multi"
      onSelectionChange={({ detail }) => {
        setSelectedItems(detail.selectedItems);
        setPanel(
          <MigrationProgramEngagementSplitPanel
            selectedEngagements={detail.selectedItems}
          />
        );
      }}
      header={
        <Header
          counter={count}
          description="All scheduled instances must have the same credit duration."
          info={
            <InfoLink
              onFollow={() => {
                //TODO: Implement help panel
              }}
            />
          }
        >
          Migration program engagements
        </Header>
      }
      pagination={<Pagination {...paginationProps} />}
      preferences={
        <CollectionPreferences
          {...getTableCollectionPreferences({
            name: TABLE_RESOURCE_NAME,
            columnDefinitions,
            editable: ({ id }: { id?: string }) => id !== "engagementId",
          })}
          preferences={preferences}
          onConfirm={({ detail }) => setPreferences(detail)}
        />
      }
      filter={
        <TextFilter
          {...filterProps}
          filteringPlaceholder={`Find ${TABLE_RESOURCE_NAME}`}
        />
      }
    />
  );
};

export default MigrationProgramEngagementsTable;

const columnDefinitions = [
  {
    id: "engagementId",
    header: (
      <span>
        Engagement ID <Icon name="external" size="inherit" variant="normal" />{" "}
      </span>
    ),
    cell: (e: IGenericObject) => generateEngagementIdLink(e.engagementId),
  },
  {
    id: "recordStatus",
    header: "Record status",
    cell: (e: IGenericObject) => e.recordStatus,
  },
  {
    id: "engagementStatus",
    header: "Engagement status",
    cell: (e: IGenericObject) => e.engagementStatus,
  },
  {
    id: "estimatedMigrationArr",
    header: "Estimated migration ARR (USD)",
    cell: (e: IGenericObject) => e.estimatedMigrationArr,
  },
  {
    id: "consultingPartnerEngagement",
    header: "Consulting partner engagement",
    cell: (e: IGenericObject) => e.consultingPartnerEngagement,
  },
  {
    id: "agreementCategory",

    header: "Agreement category",
    cell: (e: IGenericObject) => e.agreementCategory,
  },
];
