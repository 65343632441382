import React, { useState } from "react";
import { isQueryEqual } from "./util";
import SaveFilterSetModal from "./Modals/saveFilterSetModal";
import UpdateFilterSetModal from "./Modals/updateFilterSetModal";
import DeleteFilterSetModal from "./Modals/deleteFilterSetModal";
import { FilterSetAction } from "./constants";

const useFilterSets = ({
  filterSets,
  query,
  filteringProperties,
  updateFilters,
  updateSavedFilterSets,
}) => {
  const [currentFilterSet, setCurrentFilterSet] = useState(null);
  const [filterSetAction, setFilterSetAction] = useState(null);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [selectedFilterSetValue, setSelectedFilterSetValue] = useState(null);

  const unsavedFilterSetOption = {
    value: "___unsaved___",
    label: currentFilterSet ? `${currentFilterSet.name} (unsaved)` : undefined,
  };

  const filterSetOptions = filterSets?.map((filterSet) => ({
    value: filterSet.name,
    description: filterSet.description,
  }));

  const showUnsavedFilterOption = hasUnsavedChanges && currentFilterSet;

  if (showUnsavedFilterOption) {
    filterSetOptions?.unshift(unsavedFilterSetOption);
  }
  const selectedFilterSetOption = showUnsavedFilterOption
    ? unsavedFilterSetOption
    : filterSetOptions?.find(({ value }) => value === selectedFilterSetValue) ??
      null;

  const [prevQuery, setPrevQuery] = useState(query);
  if (query !== prevQuery) {
    setPrevQuery(query);

    const hasFilters = query.tokens.length > 0;

    if (!hasFilters) {
      setHasUnsavedChanges(false);
      setCurrentFilterSet(null);
      setSelectedFilterSetValue(null);
    } else if (!hasUnsavedChanges && hasFilters) {
      if (!currentFilterSet || !isQueryEqual(query, currentFilterSet.query)) {
        setHasUnsavedChanges(true);
      }
    } else if (
      hasUnsavedChanges &&
      currentFilterSet &&
      isQueryEqual(query, currentFilterSet.query)
    ) {
      setHasUnsavedChanges(false);
    }
  }

  const selectProps = {
    placeholder: "Choose a filter set",
    options: filterSetOptions,
    selectedOption: selectedFilterSetOption,
    empty: "No saved filter sets",
    onChange: ({ detail }) => {
      if (
        !detail.selectedOption ||
        detail.selectedOption === unsavedFilterSetOption
      ) {
        return;
      }

      const newFilterSet =
        filterSets?.find(({ name }) => name === detail.selectedOption.value) ??
        null;
      setCurrentFilterSet(newFilterSet);
      if (newFilterSet) {
        updateFilters(newFilterSet.query);
      }
      setSelectedFilterSetValue(detail.selectedOption.value ?? null);
      setHasUnsavedChanges(false);
    },
  };

  const buttonDropdownProps = {
    ariaLabel: "Filter actions",
    mainAction: {
      text: "Clear filters",
      onClick: () => {
        updateFilters({ operation: "and", tokens: [] });
        setCurrentFilterSet(null);
        setHasUnsavedChanges(false);
        setSelectedFilterSetValue(null);
      },
    },
    items: [
      {
        id: FilterSetAction.NEW,
        text: "Save as new filter set",
      },
      {
        id: FilterSetAction.UPDATE,
        text: "Update current filter set",
        disabled: !hasUnsavedChanges || !currentFilterSet,
      },
      {
        id: FilterSetAction.DELETE,
        text: "Delete current filter set",
        disabled: hasUnsavedChanges || !currentFilterSet,
      },
    ],
    onItemClick: ({ detail: { id } }) => setFilterSetAction(id),
  };

  let actionModal = null;
  if (
    filterSetAction === FilterSetAction.UPDATE &&
    currentFilterSet &&
    hasUnsavedChanges
  ) {
    actionModal = (
      <UpdateFilterSetModal
        filterSets={filterSets}
        currentFilterSet={currentFilterSet}
        filteringProperties={filteringProperties}
        newQuery={query}
        onCancel={() => {
          setFilterSetAction(null);
        }}
        onSubmit={async () => {
          if (!hasUnsavedChanges) {
            return;
          }
          currentFilterSet.query = query;
          const updateSuccessful = await updateSavedFilterSets(
            FilterSetAction.UPDATE,
            filterSets ? [...filterSets] : []
          );
          if (updateSuccessful) {
            setSelectedFilterSetValue(currentFilterSet.name);
            setHasUnsavedChanges(false);
          }
          setFilterSetAction(null);
        }}
      />
    );
  } else if (filterSetAction === FilterSetAction.DELETE && currentFilterSet) {
    actionModal = (
      <DeleteFilterSetModal
        currentFilterSet={currentFilterSet}
        filteringProperties={filteringProperties}
        onCancel={() => {
          setFilterSetAction(null);
        }}
        onSubmit={async () => {
          const filterIndex = filterSets?.indexOf(currentFilterSet);
          filterSets?.splice(filterIndex, 1);
          const deleteSuccessful = await updateSavedFilterSets(
            FilterSetAction.DELETE,
            filterSets ? [...filterSets] : []
          );
          if (deleteSuccessful) {
            setSelectedFilterSetValue(null);
            setHasUnsavedChanges(false);
            updateFilters({ operation: "and", tokens: [] });
          } else {
            filterSets?.splice(filterIndex, 0, currentFilterSet);
          }
          setFilterSetAction(null);
        }}
      />
    );
  } else if (filterSetAction === FilterSetAction.NEW) {
    actionModal = (
      <SaveFilterSetModal
        filterSets={filterSets}
        query={query}
        filteringProperties={filteringProperties}
        onCancel={() => {
          setFilterSetAction(null);
        }}
        onSubmit={async (name, description) => {
          const newFilterSet = {
            name: name,
            description: description,
            query,
          };
          const newFilterSets = filterSets
            ? [...filterSets, newFilterSet]
            : [newFilterSet];
          const saveSuccessful = await updateSavedFilterSets(
            FilterSetAction.NEW,
            newFilterSets
          );
          if (saveSuccessful) {
            setCurrentFilterSet(newFilterSet);
            setSelectedFilterSetValue(newFilterSet.name);
            setHasUnsavedChanges(false);
          }
          setFilterSetAction(null);
        }}
      />
    );
  }

  return { selectProps, buttonDropdownProps, actionModal };
};

export default useFilterSets;
