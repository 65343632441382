import React, { useEffect, useState } from "react";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import { Box, FormField, SpaceBetween } from "@amzn/awsui-components-react";
import Button from "@amzn/awsui-components-react/polaris/button";
import PropTypes from "prop-types";
import Textarea from "@amzn/awsui-components-react/polaris/textarea";
import { capitalize } from "../../../util/helper";

const COMMENT_TYPE = {
  INTERNAL: "internal",
  EXTERNAL: "external",
};

const CommentModal = ({
  reviewActionType,
  isLoading,
  isVisible,
  reviewAction,
  updateVisibility,
}) => {
  const [comment, setComment] = useState({
    [COMMENT_TYPE.INTERNAL]: "",
    [COMMENT_TYPE.EXTERNAL]: "",
  });
  const [error, setNewError] = useState({
    [COMMENT_TYPE.INTERNAL]: "",
    [COMMENT_TYPE.EXTERNAL]: "",
  });

  useEffect(() => {
    setComment({
      [COMMENT_TYPE.INTERNAL]: "",
      [COMMENT_TYPE.EXTERNAL]: "",
    });
  }, [reviewActionType]);

  return (
    <Modal
      id="comment-modal"
      onDismiss={() => updateVisibility(reviewActionType, false)}
      visible={isVisible}
      closeAriaLabel="Close modal"
      size="large"
      header={`${capitalize(reviewActionType)} Fund Request`}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              onClick={() => {
                updateVisibility(reviewActionType, false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              loading={isLoading}
              onClick={async () => {
                await reviewAction({
                  internalComment: comment[COMMENT_TYPE.INTERNAL],
                  externalComment: comment[COMMENT_TYPE.EXTERNAL],
                });
              }}
            >
              Submit
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <SpaceBetween size="l">
        <FormField
          label="Please enter an internal comment."
          errorText={error[COMMENT_TYPE.INTERNAL]}
        >
          <Textarea
            id="internal-comment"
            value={comment[COMMENT_TYPE.INTERNAL]}
            onChange={({ detail }) => {
              setNewError({ ...error, [COMMENT_TYPE.INTERNAL]: "" });
              setComment({ ...comment, [COMMENT_TYPE.INTERNAL]: detail.value });
            }}
          />
        </FormField>
        <FormField
          label="Please enter a comment for the partner."
          description="This comment will be visible by the partner."
          errorText={error[COMMENT_TYPE.EXTERNAL]}
        >
          <Textarea
            id="external-comment"
            value={comment[COMMENT_TYPE.EXTERNAL]}
            onChange={({ detail }) => {
              setNewError({ ...error, [COMMENT_TYPE.EXTERNAL]: "" });
              setComment({ ...comment, [COMMENT_TYPE.EXTERNAL]: detail.value });
            }}
          />
        </FormField>
      </SpaceBetween>
    </Modal>
  );
};

CommentModal.propTypes = {
  isLoading: PropTypes.bool,
  isVisible: PropTypes.bool,
  reviewActionType: PropTypes.string,
  reviewAction: PropTypes.func,
  updateVisibility: PropTypes.func,
};

export default CommentModal;
