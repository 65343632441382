import { SECTION_NAME } from "../../../../shared/util/constants/section";
import { generateMultiSectionKey } from "../../../../shared/util/common/generateMultiSectionKey";

const defaultValue = ({ data, id }) => {
  return data[id];
};

const additionalIncentivesValue = ({ data, fieldToIdMap, index }) => {
  const sectionKey = generateMultiSectionKey({
    index,
    sectionName: SECTION_NAME.MIGRATION_WORKLOADS,
  });
  const connectARRThreshold = 500000;
  const annualRunRateUnits = data && data.annualRunRateUnits;
  const specializedWorkloadId = fieldToIdMap[sectionKey]["specializedWorkload"];
  const postMigrationArrId =
    fieldToIdMap[sectionKey]["postMigrationAnnualRunRate"];

  const specializedWorkload = (data && data[specializedWorkloadId]) || "";

  let postMigrationArr = data && data[postMigrationArrId];

  if (typeof postMigrationArr === "string") {
    postMigrationArr =
      parseFloat(data[postMigrationArrId].replace(/,/g, "")) || 0;
  }

  //TODO: Define the cases as constants.
  switch (specializedWorkload) {
    case "Windows":
      return (postMigrationArr * 0.05).toLocaleString("en-US");
    case "SAP":
      return (postMigrationArr * 0.5).toLocaleString("en-US");
    case "DB/Analytics":
      return (postMigrationArr * 0.1).toLocaleString("en-US");
    case "Commercial DB/Analytics":
      return (postMigrationArr * 0.25).toLocaleString("en-US");
    case "Oracle":
      return (postMigrationArr * 0.5).toLocaleString("en-US");
    case "Non-Commercial Database":
      return (postMigrationArr * 0.1).toLocaleString("en-US");
    case "Non-Commercial Analytics":
      return (postMigrationArr * 0.1).toLocaleString("en-US");
    case "Commercial Database":
      return (postMigrationArr * 0.25).toLocaleString("en-US");
    case "Commercial Analytics":
      return (postMigrationArr * 0.25).toLocaleString("en-US");
    case "Connect":
      if (annualRunRateUnits >= connectARRThreshold)
        return (postMigrationArr * 0.25).toLocaleString("en-US");
      else return 0;
    default: //For the remaining specializedWorkloads the additionalIncentivesValue is 0
      return 0;
  }
};

const fundRequestOwnerValue = ({ data, fieldToIdMap }) => {
  const fundRequestOwnerId =
    fieldToIdMap[SECTION_NAME.FUND_REQUEST_INFORMATION]["fundRequestOwner"];
  return data[fundRequestOwnerId];
};

const currencyValue = ({ data, field, fieldToIdMap, id, sectionName }) => {
  let value = defaultValue({ data, id });

  if (
    (sectionName !== SECTION_NAME.CREDIT_REQUEST_DETAILS &&
      sectionName !== SECTION_NAME.CASH_REQUEST_INFORMATION) ||
    field.name === "poCurrency"
  ) {
    const currentCurrencyId =
      fieldToIdMap[SECTION_NAME.CASH_REQUEST_INFORMATION]["currency"];
    value = data[currentCurrencyId];
  }

  if (sectionName === SECTION_NAME.CREDIT_REQUEST_DETAILS) {
    const currentCurrencyId =
      fieldToIdMap[SECTION_NAME.CREDIT_REQUEST_DETAILS]["currency"];
    value = data[currentCurrencyId];
  }
  return value;
};

const executedCountryValue = ({ data, fieldToIdMap, id }) => {
  let value = defaultValue({ data, id });

  if (value !== "US") {
    const executedStateId =
      fieldToIdMap[SECTION_NAME.PROJECT_INFORMATION]["executedState"];
    data[executedStateId] = [];
  }
  return value;
};

export const ValueFactory = (name) => {
  switch (name) {
    case "fundRequestOwner":
      return fundRequestOwnerValue;
    case "additionalIncentive":
      return additionalIncentivesValue;
    case "poCurrency":
    case "currency":
      return currencyValue;
    case "executedCountry":
      return executedCountryValue;
    default:
      return defaultValue;
  }
};
