import React from "react";
import Config from "config";
import { FUNDING_PORTAL } from "./constants";

const ENGAGEMENT_ID_PREFIX = "PE-";

const engagementIdLinkUrl_default = {
  dev: "https://salesconsole.aws.dev/investments/engagements/details/",
  uat: "https://salesconsole.aws.dev/investments/engagements/details/",
  prod: "https://salesconsole.aws.dev/investments/engagements/details/",
};
const engagementIdLinkUrl = Object.assign(
  engagementIdLinkUrl_default,
  Config.ENGAGEMENT_ID_LINK_URL || {}
);

export const generateEngagementIdLink = (engagement_id) => {
  let link = "-";
  let userFriendlyMPEId = engagement_id;

  if (!engagement_id.includes(ENGAGEMENT_ID_PREFIX)) {
    userFriendlyMPEId = ENGAGEMENT_ID_PREFIX + engagement_id;
  }

  if (window.location.host === FUNDING_PORTAL.prod) {
    link = engagementIdLinkUrl.prod;
  } else if (window.location.host === FUNDING_PORTAL.gamma) {
    link = engagementIdLinkUrl.uat;
  } else if (window.location.host === FUNDING_PORTAL.alpha) {
    link = engagementIdLinkUrl.dev;
  } else if (window.location.host.includes("localhost")) {
    link = engagementIdLinkUrl.dev;
  }

  if (userFriendlyMPEId) {
    return (
      <a
        href={`${link}${userFriendlyMPEId}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {userFriendlyMPEId}
      </a>
    );
  }
};
