import React, { ReactElement } from "react";
import { FILE_STATUS } from "shared/util/constants/fileStatus";
import { StatusIndicatorProps } from "@amzn/awsui-components-react/polaris/status-indicator/internal";
import StatusIndicator from "@amzn/awsui-components-react/polaris/status-indicator";
import { Box, Popover } from "@amzn/awsui-components-react";
import { useTranslation } from "react-i18next";

const ERROR_STATUSES = [FILE_STATUS.ScannedQuarantined];
const SUCCESS_STATUSES = [FILE_STATUS.ScannedClean];

export const FileScanStatus = ({ status }: { status: string }) => {
  if (!status) return <></>;

  const { t } = useTranslation();

  let type: StatusIndicatorProps.Type = "in-progress";
  let popoverContent = (
    <Box>{t("help.wizard.supportingDocumentation.tooltip.scanning")}</Box>
  );
  let showPopover = true;

  const scanToStatusMapping = {
    [FILE_STATUS.ScannedClean]: "Passed",
    [FILE_STATUS.ScannedQuarantined]: "Failed",
    [FILE_STATUS.PendingScan]: "Scanning",
    [FILE_STATUS.PendingUpload]: "Scanning",
  };

  if (ERROR_STATUSES.includes(status)) {
    type = "error";
    showPopover = true;
    popoverContent = (
      <Box>{t("help.wizard.supportingDocumentation.tooltip.error")}</Box>
    );
  }

  if (SUCCESS_STATUSES.includes(status)) {
    type = "success";
    showPopover = false;
    popoverContent = <></>;
  }

  return (
    <ConditionalScanStatusPopover
      showPopover={showPopover}
      content={popoverContent}
    >
      <StatusIndicator type={type}>
        {scanToStatusMapping[status]}
      </StatusIndicator>
    </ConditionalScanStatusPopover>
  );
};

const ConditionalScanStatusPopover = ({
  children,
  showPopover = false,
  content = <></>,
}: {
  children: ReactElement;
  content?: ReactElement;
  showPopover?: boolean;
}) => {
  if (!showPopover) return children;

  const { t } = useTranslation();

  return (
    <Popover
      header={t("help.wizard.supportingDocumentation.tooltip.header.title")}
      content={content}
    >
      {children}
    </Popover>
  );
};
