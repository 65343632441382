import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Table from "@amzn/awsui-components-react/polaris/table";
import Grid from "@amzn/awsui-components-react/polaris/grid";
import Input from "@amzn/awsui-components-react/polaris/input";
import Header from "@amzn/awsui-components-react/polaris/header";
import Pagination from "@amzn/awsui-components-react/polaris/pagination";
import { useCollection } from "@amzn/awsui-collection-hooks";
import TableEmptyState from "../../../../common/TableEmptyState";
import TableNoMatchState from "../../../../common/TableNoMatchState";
import {
  fundingHistoryColumnDefinitions,
  searchableColumns,
} from "./tableConfig";
import { filterItem } from "../util";
import { Box } from "@amzn/awsui-components-react";
import { getFundRequestHistory } from "../../../../../util/services/data/InternalDataService";
import { timestampComparator } from "../../../../../../shared/util/common/helper";

export const FundingHistoryTable = ({ getFundingHistory }) => {
  const pageSize = 10;
  const [isLoading, setIsLoading] = useState(true);
  const [history, setHistory] = useState([]);

  useEffect(async () => {
    const data = await getFundingHistory({
      getFundRequestHistory: getFundRequestHistory,
      setIsLoading,
    });
    setHistory(data);
    setIsLoading(false);
  }, []);

  const { items, actions, collectionProps, filterProps, paginationProps } =
    useCollection(history || [], {
      pagination: { pageSize },
      empty: <TableEmptyState resourceName="history" />,
      filtering: {
        noMatch: (
          <TableNoMatchState onClearFilter={() => actions.setFiltering("")} />
        ),
        filteringFunction: (item, filteringText) => {
          return filterItem({ searchableColumns, item, filteringText });
        },
      },
      sorting: {
        defaultState: {
          sortingColumn: {
            sortingField: "date",
            sortingComparator: (e1, e2) =>
              timestampComparator(e1.date, e2.date),
          },
          isDescending: true,
        },
      },
      selection: {},
    });

  return (
    <Table
      {...collectionProps}
      loading={isLoading}
      loadingText="Loading fund request history..."
      items={items}
      columnDefinitions={fundingHistoryColumnDefinitions}
      wrapLines={true}
      empty={
        <Box textAlign="center" color="inherit">
          <Box padding={{ bottom: "s" }} variant="p" color="inherit">
            No fund request history available.
          </Box>
        </Box>
      }
      filter={
        <Grid
          gridDefinition={[
            { colspan: 4 },
            { colspan: 1 },
            { colspan: 1 },
            { colspan: 1 },
            { colspan: 1 },
          ]}
        >
          <Input
            type="search"
            value={filterProps.filteringText}
            onChange={(event) => {
              actions.setFiltering(event.detail.value);
            }}
            placeholder="Enter search criteria..."
            label="Search history"
            ariaDescribedby={null}
          />
        </Grid>
      }
      header={
        <Header counter={items.length + "/" + history.length}>
          Fund Request History
        </Header>
      }
      pagination={<Pagination {...paginationProps} />}
    />
  );
};

FundingHistoryTable.propTypes = {
  getFundingHistory: PropTypes.func,
};
