import { useEffect, useState } from "react";
import {
  getSelfServiceHelperText,
  helperTextMap,
  selfServiceHelperTextEntityMap,
} from "../constants/helperText";
import { isFeatureEnabled } from "../services/features/FeatureFlagsService";
import { FEATURE_FLAG_ENUMS } from "../constants/featureFlagValues";

const useHelpText = () => {
  const [isHelperPanelOpen, setIsHelperPanelOpen] = useState(false);
  const [helperPanelContent, setHelperPanelContent] = useState({});
  const [selfServiceHelperTextFlag, setSelfServiceHelperTextFlag] =
    useState(false);

  useEffect(() => {
    const featureFlag = async () => {
      const helperTextFlag = await isFeatureEnabled(
        FEATURE_FLAG_ENUMS.SELF_SERVICE_HELPER_TEXT
      );
      if (!ignore) {
        setSelfServiceHelperTextFlag(helperTextFlag);
      }
    };

    let ignore = false;
    featureFlag();
    return () => {
      ignore = true;
    };
  }, []);

  const showHelperPanel = async ({ title, keys, open }) => {
    let content = [];
    for (let key of keys) {
      let text = "";
      if (selfServiceHelperTextFlag) {
        if (selfServiceHelperTextEntityMap[key]) {
          text = await getSelfServiceHelperText(
            selfServiceHelperTextEntityMap[key]
          );
        }
      } else {
        if (helperTextMap[key]) {
          text = helperTextMap[key];
        }
      }
      content.push(text);
    }
    setHelperPanelContent({
      title,
      content: content,
      selfServiceHelperTextFlag,
    });
    setIsHelperPanelOpen(open);
  };

  return {
    isHelperPanelOpen,
    helperPanelContent,
    setIsHelperPanelOpen,
    showHelperPanel,
  };
};
export default useHelpText;
