import React, { createContext, useContext } from "react";
import PropTypes from "prop-types";
// import { generateIds } from "../../internal/util/templateGeneration";

const TemplateContext = createContext([]);

const useTemplateContext = () => useContext(TemplateContext);

const TemplateProvider = ({ providedTemplate, setTemplate, children }) => {
  return (
    <TemplateContext.Provider value={[providedTemplate, setTemplate]}>
      {children}
    </TemplateContext.Provider>
  );
};

TemplateProvider.propTypes = {
  children: PropTypes.element.isRequired,
  providedTemplate: PropTypes.object,
  setTemplate: PropTypes.func,
};

export { useTemplateContext, TemplateProvider };
