import { CSVLink } from "react-csv";
import React, { useRef, useState } from "react";
import { Link } from "@amzn/awsui-components-react";
import {
  ERROR_TYPE,
  hasError,
  retryApiCall,
} from "shared/util/services/data/DataService";
import PropTypes from "prop-types";
import { getBulkActionResult } from "../../../../util/services/data/InternalDataService";
import { BULK_ACTION_TYPE_TO_RESULT_TRANSFORM_FN } from "./config";

const ExportToCSV = ({ action, task_id, filename, setNotificationsItems }) => {
  const [result, setResult] = useState([]);

  // react-csv doesn't support async loading in one button click.
  // It will fetch the data after the first click, which means that the first download will have weird symbols in it and the second will work.
  // The workaround is hiding the csv link within a button and forcing the click when the data is fetched
  const csvLink = useRef();

  const getResult = async () => {
    retryApiCall({
      callApi: getBulkActionResult({ taskId: task_id }),
    })
      .then((response) => {
        if (hasError(response) && response.errorType === ERROR_TYPE.BANNER) {
          throw Error;
        }
        const resultCSV = BULK_ACTION_TYPE_TO_RESULT_TRANSFORM_FN[action](
          response.bulkActionResult
        );
        setResult(resultCSV);
        csvLink.current.link.click();
      })
      .catch(() => {
        setNotificationsItems([
          {
            header: "An error occured while exporting the result.",
            type: "error",
            dismissible: true,
            dismissLabel: "Dismiss message",
            onDismiss: () => setNotificationsItems([]),
          },
        ]);
      });
  };

  const filenameWithoutExtension = filename.replace(/\.[^/.]+$/, "");
  const resultFileName =
    filename.length == 0
      ? "result.csv"
      : `${filenameWithoutExtension}_result.csv`;

  return (
    <div>
      <Link onFollow={getResult} href="#">
        Export Result to CSV
      </Link>
      <CSVLink
        data={result}
        filename={resultFileName}
        className="hidden"
        ref={csvLink}
        target="_blank"
      />
    </div>
  );
};

ExportToCSV.propTypes = {
  action: PropTypes.string,
  task_id: PropTypes.string,
  filename: PropTypes.string,
  setNotificationsItems: PropTypes.func,
};

export default ExportToCSV;
