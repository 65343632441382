import React, { useState } from "react";

import DetailsActionWithModal from "shared/programs/migration-acceleration-program/2024/fund-request/components/modal/DetailsActionWithModal";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import { FormField } from "@amzn/awsui-components-react";
import Textarea from "@amzn/awsui-components-react/polaris/textarea";
import { BaseChangeDetail } from "@amzn/awsui-components-react/polaris/input/interfaces";
import { submitCompletion } from "internal/util/services/data/InternalDataService";
import { hasError, retryApiCall } from "shared/util/services/data/DataService";
import {
  AudienceType,
  BaseDetailsAction,
  DetailsActionProps,
} from "./DetailsActionsTypes";
import { useHistory } from "react-router-dom";
import { RouteOrigin } from "shared/util/constants/RouteState";
import { getFundRequestId } from "internal/util/helper";
import { FormError, Result, Success } from "shared/util/api/util";

interface CompleteActionProps extends DetailsActionProps {}

const DEFAULT_ACTION_STATE = {
  [AudienceType.Internal]: "",
  [AudienceType.External]: "",
};

const createPayload = ({
  internal,
  external,
}: {
  internal: string;
  external: string;
}) => {
  return {
    internalComment: internal,
    externalComment: external,
  };
};

const CompleteAction = ({ loading }: CompleteActionProps) => {
  const history = useHistory();
  const [comments, setComments] =
    useState<BaseDetailsAction>(DEFAULT_ACTION_STATE);

  const [errors, setErrors] = useState(DEFAULT_ACTION_STATE);

  const submit = async () => {
    const payload = createPayload({
      internal: comments[AudienceType.Internal],
      external: comments[AudienceType.External],
    });

    console.table([payload, submit]);

    if (!submit) {
      return new Result<FormError>({
        success: false,
        payload: new FormError("Unable to submit completion."),
      });
    }

    //ts-ignore-error Legacy JS
    const response = await retryApiCall({
      callApi: submitCompletion({ payload, fundRequestId: getFundRequestId() }),
      error: "",
    });

    if (hasError(response)) {
      return new Result<FormError>({
        success: false,
        payload: new FormError(
          response.message || "Failed to submit completion successfully."
        ),
      });
    }

    resetModalState();
    history.push("/dashboard", {
      origin: RouteOrigin.DetailsAction,
      message: "The fund request has been successfully completed.",
    });
    return new Result<Success>({});
  };

  const resetModalState = () => {
    setErrors(DEFAULT_ACTION_STATE);
    setComments(DEFAULT_ACTION_STATE);
  };

  const onChange = ({
    detail,
    audience,
  }: {
    detail: BaseChangeDetail;
    audience: AudienceType;
  }) => {
    if (errors[audience]) setErrors(DEFAULT_ACTION_STATE);
    setComments({
      ...comments,
      [audience]: detail.value,
    });
  };

  return (
    <DetailsActionWithModal
      isLoading={loading}
      submit={submit}
      cancel={resetModalState}
      labels={{ actionButton: "Complete", title: "Complete fund request" }}
    >
      <SpaceBetween size="l">
        <FormField
          label="Internal comment"
          errorText={errors[AudienceType.Internal]}
        >
          <Textarea
            value={comments[AudienceType.Internal]}
            placeholder="Enter an internal comment"
            onChange={({ detail }) => {
              onChange({ detail, audience: AudienceType.Internal });
            }}
          />
        </FormField>
        <FormField
          label="Partner comment"
          description="This comment will be visible by the partner."
          errorText={errors[AudienceType.External]}
        >
          <Textarea
            value={comments[AudienceType.External]}
            placeholder="Enter a comment for the partner."
            onChange={({ detail }) => {
              onChange({ detail, audience: AudienceType.External });
            }}
          />
        </FormField>
      </SpaceBetween>
    </DetailsActionWithModal>
  );
};

export default CompleteAction;
