import { Input } from "@amzn/awsui-components-react";
import React from "react";

export const REVIEW_TABLE_SIZE = 50;

export const ariaLabels = {
  activateEditLabel: (column, item) => `Edit ${item.name} ${column.header}`,
  cancelEditLabel: (column) => `Cancel editing ${column.header}`,
  submitEditLabel: (column) => `Submit editing ${column.header}`,
  tableLabel: "Table with inline editing",
};

// Bulk Unlock

const createUnlockRecordId = (record) => {
  const fr_id = record["Fund Request ID"];
  const fund_claim_id = record["Fund Claim ID"];
  return fr_id + "#" + fund_claim_id;
};

const bulkUnlockRecordTransform = (records) => {
  return records.map((record) => {
    return {
      entryId: createUnlockRecordId(record),
      realizedRevenueAmount: record["Realized Revenue Amount (USD)"],
      internalComment: record["Comment"],
    };
  });
};

const bulkUnlockColumnDefinitions = [
  {
    id: "Fund Request ID",
    header: "Fund Request ID",
    cell: (item) => {
      return item["Fund Request ID"];
    },
    isRowHeader: true,
    editConfig: {
      ariaLabel: "Fund Request ID",
      editIconAriaLabel: "editable",
      errorIconAriaLabel: "Fund Request ID Error",
      editingCell: (item, { currentValue, setValue }) => {
        return (
          <Input
            autoFocus={true}
            value={currentValue ?? item["Fund Request ID"]}
            ariaLabel="Edit Fund Request ID"
            onChange={(event) => {
              setValue(event.detail.value);
            }}
          />
        );
      },
    },
  },
  {
    id: "Fund Claim ID",
    header: "Fund Claim ID",
    cell: (item) => {
      return item["Fund Claim ID"];
    },
    isRowHeader: true,
    editConfig: {
      ariaLabel: "Fund Claim ID",
      editIconAriaLabel: "editable",
      errorIconAriaLabel: "Fund Claim ID Error",
      editingCell: (item, { currentValue, setValue }) => {
        return (
          <Input
            autoFocus={true}
            value={currentValue ?? item["Fund Claim ID"]}
            ariaLabel="Edit Fund Claim ID"
            onChange={(event) => {
              setValue(event.detail.value);
            }}
          />
        );
      },
    },
  },
  {
    id: "Realized Revenue Amount (USD)",
    header: "Realized Revenue Amount (USD)",
    cell: (item) => {
      return item["Realized Revenue Amount (USD)"];
    },
    isRowHeader: true,
    editConfig: {
      ariaLabel: "Realized Revenue Amount (USD)",
      editIconAriaLabel: "editable",
      errorIconAriaLabel: "Realized Revenue Amount (USD) Error",
      editingCell: (item, { currentValue, setValue }) => {
        return (
          <Input
            autoFocus={true}
            value={currentValue ?? item["Realized Revenue Amount (USD)"]}
            ariaLabel="Edit Realized Revenue Amount (USD)"
            onChange={(event) => {
              setValue(event.detail.value);
            }}
          />
        );
      },
      validation: (item, value) => {
        if (value != undefined && isNaN(value)) {
          return "Not a valid number!";
        }
        return undefined;
      },
    },
  },
  {
    id: "Comment",
    header: "Comment",
    cell: (item) => {
      return item["Comment"];
    },
    isRowHeader: true,
    editConfig: {
      ariaLabel: "Comment",
      editIconAriaLabel: "editable",
      errorIconAriaLabel: "Comment",
      editingCell: (item, { currentValue, setValue }) => {
        return (
          <Input
            autoFocus={true}
            value={currentValue ?? item["Comment"]}
            ariaLabel="Edit Comment"
            onChange={(event) => {
              setValue(event.detail.value);
            }}
          />
        );
      },
    },
  },
];

// Bulk Expire

const bulkExpirationRecordTransform = (records) => {
  return records.map((record) => {
    return {
      entryId: record["Fund Request ID"],
    };
  });
};

const bulkExpirationColumnDefinitions = [
  {
    id: "Fund Request ID",
    header: "Fund Request ID",
    width: 180,
    mindWidth: 176,
    cell: (item) => {
      return item["Fund Request ID"];
    },
    isRowHeader: true,
    editConfig: {
      ariaLabel: "Fund Request ID",
      editIconAriaLabel: "editable",
      errorIconAriaLabel: "Fund Request ID Error",
      editingCell: (item, { currentValue, setValue }) => {
        return (
          <Input
            autoFocus={true}
            value={currentValue ?? item["Fund Request ID"]}
            ariaLabel="Edit Fund Request ID"
            onChange={(event) => {
              setValue(event.detail.value);
            }}
          />
        );
      },
    },
  },
];

// Bulk Update Cash Claim Invoice

const createUpdateCashClaimInvoiceRecordId = (record) => {
  const fr_id = record["Fund Request ID"];
  const fund_claim_id = record["Fund Claim ID"];
  return fr_id + "#" + fund_claim_id;
};

const parseDatetimefieldFromString = (str) => {
  const timestamp = Date.parse(str);
  return {
    timestamp: timestamp,
  };
};

const parseMoneyTypeFromAmountStr = (amountStr, currency) => {
  const amount = Number(amountStr);
  return {
    units: Math.floor(amount),
    nanos: parseInt((amount - Math.floor(amount)) * 1e9),
    currency: currency,
  };
};

const bulkUpdateCashClaimInvoiceRecordTransform = (records) => {
  return records.map((record) => {
    return {
      entryId: createUpdateCashClaimInvoiceRecordId(record),
      amount: parseMoneyTypeFromAmountStr(
        record["Invoice Amount (Local Currency)"],
        record["Invoice Currency"]
      ),
      scheduledPaidDate: parseDatetimefieldFromString(
        record["Scheduled Paid Date (month/day/year)"]
      ),
      description: record["Description"],
      currency: record["Invoice Currency"],
    };
  });
};

const bulkUpdateCashClaimInvoiceColumnDefinitions = [
  {
    id: "Fund Request ID",
    header: "Fund Request ID",
    cell: (item) => {
      return item["Fund Request ID"];
    },
    isRowHeader: true,
    editConfig: {
      ariaLabel: "Fund Request ID",
      editIconAriaLabel: "editable",
      errorIconAriaLabel: "Fund Request ID Error",
      editingCell: (item, { currentValue, setValue }) => {
        return (
          <Input
            autoFocus={true}
            value={currentValue ?? item["Fund Request ID"]}
            ariaLabel="Edit Fund Request ID"
            onChange={(event) => {
              setValue(event.detail.value);
            }}
          />
        );
      },
    },
  },
  {
    id: "Fund Claim ID",
    header: "Fund Claim ID",
    cell: (item) => {
      return item["Fund Claim ID"];
    },
    isRowHeader: true,
    editConfig: {
      ariaLabel: "Fund Claim ID",
      editIconAriaLabel: "editable",
      errorIconAriaLabel: "Fund Claim ID Error",
      editingCell: (item, { currentValue, setValue }) => {
        return (
          <Input
            autoFocus={true}
            value={currentValue ?? item["Fund Claim ID"]}
            ariaLabel="Edit Fund Claim ID"
            onChange={(event) => {
              setValue(event.detail.value);
            }}
          />
        );
      },
    },
  },
  {
    id: "Invoice Currency",
    header: "Invoice Currency",
    cell: (item) => {
      return item["Invoice Currency"];
    },
    isRowHeader: true,
    editConfig: {
      ariaLabel: "Invoice Currency",
      editIconAriaLabel: "editable",
      errorIconAriaLabel: "Invoice Currency Error",
      editingCell: (item, { currentValue, setValue }) => {
        return (
          <Input
            autoFocus={true}
            value={currentValue ?? item["Invoice Currency"]}
            ariaLabel="Edit Invoice Currency"
            onChange={(event) => {
              setValue(event.detail.value);
            }}
          />
        );
      },
    },
  },
  {
    id: "Invoice Amount (Local Currency)",
    header: "Invoice Amount (Local Currency)",
    cell: (item) => {
      return item["Invoice Amount (Local Currency)"];
    },
    isRowHeader: true,
    editConfig: {
      ariaLabel: "Invoice Amount (Local Currency)",
      editIconAriaLabel: "editable",
      errorIconAriaLabel: "Invoice Amount (Local Currency) Error",
      editingCell: (item, { currentValue, setValue }) => {
        return (
          <Input
            autoFocus={true}
            value={currentValue ?? item["Invoice Amount (Local Currency)"]}
            ariaLabel="Edit Invoice Amount (Local Currency)"
            onChange={(event) => {
              setValue(event.detail.value);
            }}
          />
        );
      },
    },
  },
  {
    id: "Scheduled Paid Date (month/day/year)",
    header: "Scheduled Paid Date (month/day/year)",
    cell: (item) => {
      return item["Scheduled Paid Date (month/day/year)"];
    },
    isRowHeader: true,
    editConfig: {
      ariaLabel: "Scheduled Paid Date (month/day/year)",
      editIconAriaLabel: "editable",
      errorIconAriaLabel: "Scheduled Paid Date (month/day/year) Error",
      editingCell: (item, { currentValue, setValue }) => {
        return (
          <Input
            autoFocus={true}
            value={currentValue ?? item["Scheduled Paid Date (month/day/year)"]}
            ariaLabel="Edit Scheduled Paid Date (month/day/year)"
            onChange={(event) => {
              setValue(event.detail.value);
            }}
          />
        );
      },
    },
  },
  {
    id: "Description",
    header: "Description",
    cell: (item) => {
      return item["Description"];
    },
    isRowHeader: true,
    editConfig: {
      ariaLabel: "Description",
      editIconAriaLabel: "editable",
      errorIconAriaLabel: "Description Error",
      editingCell: (item, { currentValue, setValue }) => {
        return (
          <Input
            autoFocus={true}
            value={currentValue ?? item["Description"]}
            ariaLabel="Edit Description"
            onChange={(event) => {
              setValue(event.detail.value);
            }}
          />
        );
      },
    },
  },
];

export const BULK_ACTION_TYPE_TO_TRANSFORM_FN = {
  expire_fund_request: (records) => bulkExpirationRecordTransform(records),
  update_cash_claim_invoice: (records) =>
    bulkUpdateCashClaimInvoiceRecordTransform(records),
  unlock_claim: (records) => bulkUnlockRecordTransform(records),
};

export const BULK_ACTION_TYPE_TO_COLUMN_DEFINITION = {
  expire_fund_request: bulkExpirationColumnDefinitions,
  update_cash_claim_invoice: bulkUpdateCashClaimInvoiceColumnDefinitions,
  unlock_claim: bulkUnlockColumnDefinitions,
};
