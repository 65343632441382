import React, { useState } from "react";
import PropTypes from "prop-types";
import Header from "@amzn/awsui-components-react/polaris/header";
import TableActions from "./TableActions";
import ItemsModal from "./Modal/ItemsModal";
import { Action } from "../../../constant.js";
import { ROOT_ENTITY_NAME } from "../../../constant.js";
import { useParams } from "react-router-dom";
import { setErrorMessage } from "../../../../../../shared/util/common/helper";
import { entityListLengthValidation } from "./Modal/constant";
import { rootEntityListLengthValidation } from "./Modal/constant";

const SelfServiceEntityTableHeader = ({
  refresh,
  counter,
  currentEntityVersion,
  changeEntityVersion,
  isValidEntity,
  entityDetails,
  selectedItems,
  setNotifications,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [action, setAction] = useState("");
  const [item, setItem] = useState({});
  const { entity } = useParams();

  const showModal = (action) => {
    setAction(action);
    if (
      selectedItems.length > 0 &&
      (action === Action.EDIT || action === Action.DELETE)
    ) {
      setItem(selectedItems[0]);
    }

    if (action === Action.CREATE) {
      const limit =
        entityDetails.entityType === ROOT_ENTITY_NAME
          ? rootEntityListLengthValidation.limit
          : entityListLengthValidation.limit;
      const message =
        entityDetails.entityType === ROOT_ENTITY_NAME
          ? rootEntityListLengthValidation.message
          : entityListLengthValidation.message;

      if (entityDetails.entityListLength >= limit) {
        setErrorMessage({
          setNotificationsItems: setNotifications,
          content: message,
        });
        updateModalVisibility(false);
        return;
      }
    }

    updateModalVisibility(true);
  };

  const updateModalVisibility = (visibility) => {
    setIsModalVisible(visibility);
  };

  return (
    <>
      <Header
        variant="h2"
        counter={counter}
        actions={
          <TableActions
            entityDetails={entityDetails}
            currentEntityVersion={currentEntityVersion}
            changeEntityVersion={changeEntityVersion}
            isValidEntity={isValidEntity}
            selectedItems={selectedItems}
            showModal={showModal}
          />
        }
      >
        {isValidEntity ? entity : ""} Items
      </Header>

      <ItemsModal
        refresh={refresh}
        isModalVisible={isModalVisible}
        updateModalVisibility={updateModalVisibility}
        action={action}
        item={item}
        setItem={setItem}
        entityDetails={entityDetails}
        setNotifications={setNotifications}
      ></ItemsModal>
    </>
  );
};

SelfServiceEntityTableHeader.propTypes = {
  refresh: PropTypes.func,
  counter: PropTypes.string,
  currentEntityVersion: PropTypes.number,
  changeEntityVersion: PropTypes.func,
  isValidEntity: PropTypes.bool,
  entityDetails: PropTypes.object,
  selectedItems: PropTypes.array,
  setNotifications: PropTypes.func,
};

export default SelfServiceEntityTableHeader;
