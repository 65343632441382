import { useCollection } from "@amzn/awsui-collection-hooks";
import { Input } from "@amzn/awsui-components-react";
import Grid from "@amzn/awsui-components-react/polaris/grid";
import Pagination from "@amzn/awsui-components-react/polaris/pagination";
import Select from "@amzn/awsui-components-react/polaris/select";
import Table from "@amzn/awsui-components-react/polaris/table";
import Header from "internal/components/Dashboard/ApprovalList/Header";
import {
  oldApprovalsColumnDefinition,
  defaultPartnerName,
  defaultPartnerType,
  defaultProgram,
  defaultProjectGeo,
  defaultStage,
  defaultStatus,
  defaultTemplate,
  visibleColumns,
} from "internal/components/Dashboard/ApprovalList/config";
import {
  calculateDwellTime,
  clearFilter,
  filterItem,
  getFundingTypeFromFrId,
  getSelectedType,
  prepareSelectOptions,
} from "internal/components/Dashboard/ApprovalList/util";
import TableEmptyState from "internal/components/common/TableEmptyState/TableEmptyState";
import TableNoMatchState from "internal/components/common/TableNoMatchState/TableNoMatchState";
import {
  getProfile,
  getUsername,
} from "internal/util/services/auth/AuthService";
import { getFundRequestListing } from "internal/util/services/data/InternalDataService";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  setErrorMessage,
  timestampComparator,
} from "shared/util/common/helper";
import { STAGE } from "shared/util/constants/fundRequestStatusType";
import {
  ERROR_TYPE,
  hasError,
  retryApiCall,
} from "shared/util/services/data/DataService";
import { convertSMPtoMAP } from "shared/util/common/convertSMPtoMAP";

export const OldFilterTable = ({ setNotificationsItems }) => {
  const pageSize = 10;
  const [isLoading, setIsLoading] = useState(true);
  const [approvalsData, setApprovalData] = useState([]);
  const [template, setTemplate] = useState(defaultTemplate);
  const [program, setProgram] = useState(defaultProgram);
  const [stage, setStage] = useState(defaultStage);
  const [status, setStatus] = useState(defaultStatus);
  const [partnerType, setPartnerType] = useState(defaultPartnerType);
  const [partnerName, setPartnerName] = useState(defaultPartnerName);
  const [projectGeo, setProjectGeo] = useState(defaultProjectGeo);
  const [selectedType, setSelectedType] = useState("");

  useEffect(async () => {
    await getApprovalList();
  }, []);

  const getApprovalList = async () => {
    setIsLoading(true);
    setApprovalData([]);
    const assignees = [getUsername()].concat(getProfile());
    const data = [];
    for (let assignee of assignees) {
      let nextPageToken = "";
      while (nextPageToken != null) {
        try {
          const response = await retryApiCall({
            callApi: getFundRequestListing({
              assignedTo: assignee,
              nextPageToken: nextPageToken,
            }),
          });

          if (hasError(response) && response.errorType === ERROR_TYPE.BANNER) {
            setErrorMessage({
              setNotificationsItems,
              content: response.message,
              status: response.status,
            });
            setIsLoading(false);
            return;
          }

          for (let request of response.assignedRequests) {
            data.push({
              fundRequestId: request.fundRequestId,
              template: convertSMPtoMAP(request.program),
              program: convertSMPtoMAP(request.subProgram),
              activityName: request.activityName,
              partnerName: request.partnerName,
              assignedTo: request.assignedTo,
              stage: request.stage,
              dwellTime: calculateDwellTime(request.stageTransitionOn),
              status: request.status,
              opportunityId: request.opportunityId,
              approvedCashAmount:
                (request && request.approvedCashAmount) || "-",
              approvedCreditAmount:
                (request && request.approvedCreditAmount) || "-",
              projectGeo: request.locationGeo,
              partnerType: request.partnerType,
              lastUpdatedOn: request.lastUpdatedOn,
              createdOn: request.createdOn,
              eligibleForPsaAutoApproval: request.eligibleForPsaAutoApproval,
              poNumber: (request && request.poNumber) || "-",
              sector: (request && request.sector) || "-",
            });
          }

          nextPageToken = response.nextPageToken;
        } catch (e) {
          console.error(e);
          setErrorMessage({
            setNotificationsItems,
          });
        }
      }
    }

    setApprovalData(data);
    setIsLoading(false);
  };

  const [selectedItems, setSelectedItems] = React.useState([]);

  const { items, actions, collectionProps, filterProps, paginationProps } =
    useCollection(approvalsData || [], {
      pagination: { pageSize },
      empty: <TableEmptyState resourceName="resources" />,
      filtering: {
        noMatch: (
          <TableNoMatchState
            onClearFilter={() =>
              clearFilter(
                actions,
                setTemplate,
                setProgram,
                setStage,
                setStatus,
                setPartnerType,
                setPartnerName,
                setProjectGeo
              )
            }
          />
        ),
        filteringFunction: (item, filteringText) => {
          return filterItem(
            item,
            filteringText,
            template,
            program,
            stage,
            status,
            partnerType,
            partnerName,
            projectGeo
          );
        },
      },
      sorting: {
        defaultState: {
          sortingColumn: {
            sortingField: "lastUpdatedOn",
            sortingComparator: (e1, e2) =>
              timestampComparator(e1.lastUpdatedOn, e2.lastUpdatedOn),
          },
          isDescending: true,
        },
      },
      selection: {},
    });

  const selectTemplateOptions = prepareSelectOptions(
    approvalsData,
    "template",
    defaultTemplate
  );

  const selectProgramOptions = prepareSelectOptions(
    approvalsData,
    "program",
    defaultProgram
  );

  const selectStageOptions = prepareSelectOptions(
    approvalsData,
    "stage",
    defaultStage
  );

  const selectStatusOptions = prepareSelectOptions(
    approvalsData,
    "status",
    defaultStatus
  );

  const selectPartnerTypeOptions = prepareSelectOptions(
    approvalsData,
    "partnerType",
    defaultPartnerType
  );

  const selectPartnerNameOptions = prepareSelectOptions(
    approvalsData,
    "partnerName",
    defaultPartnerName
  );

  const selectProjectGeoOptions = prepareSelectOptions(
    approvalsData,
    "projectGeo",
    defaultProjectGeo
  );

  const matchesSelectedType = ({ fundingType, stage }) => {
    return (
      selectedType === "" ||
      getSelectedType({ fundingType, stage }) === selectedType
    );
  };

  return (
    <Table
      {...collectionProps}
      loading={isLoading}
      loadingText="Loading pending approvals..."
      selectionType="multi"
      items={items}
      isItemDisabled={({ fundRequestId, stage }) =>
        stage === STAGE.COMPLETED ||
        !matchesSelectedType({
          fundingType: getFundingTypeFromFrId(fundRequestId),
          stage,
        })
      }
      columnDefinitions={oldApprovalsColumnDefinition}
      visibleColumns={visibleColumns}
      filter={
        <Grid
          gridDefinition={[
            { colspan: 4 },
            { colspan: 8 },
            { colspan: 2 },
            { colspan: 2 },
            { colspan: 2 },
            { colspan: 2 },
            { colspan: 2 },
            { colspan: 2 },
            { colspan: 2 },
          ]}
        >
          <Input
            type="search"
            value={filterProps.filteringText}
            onChange={(event) => {
              actions.setFiltering(event.detail.value);
            }}
            placeholder="Find resources"
            label="Find resources"
            ariaDescribedby={null}
          />
          <br />
          <Select
            selectedOption={template}
            onChange={(event) => {
              setTemplate(event.detail.selectedOption);
            }}
            options={selectTemplateOptions}
          />
          <Select
            selectedOption={program}
            onChange={(event) => {
              setProgram(event.detail.selectedOption);
            }}
            options={selectProgramOptions}
          />
          <Select
            className="dashboard-list-table-select"
            selectedOption={stage}
            onChange={(event) => {
              setStage(event.detail.selectedOption);
            }}
            options={selectStageOptions}
          />
          <Select
            className="dashboard-list-table-select"
            selectedOption={status}
            onChange={(event) => {
              setStatus(event.detail.selectedOption);
            }}
            options={selectStatusOptions}
            selectedAriaLabel="Selected"
          />
          <Select
            className="dashboard-list-table-select"
            selectedOption={partnerType}
            onChange={(event) => {
              setPartnerType(event.detail.selectedOption);
            }}
            options={selectPartnerTypeOptions}
            selectedAriaLabel="Selected"
          />
          <Select
            className="dashboard-list-table-select"
            selectedOption={partnerName}
            onChange={(event) => {
              setPartnerName(event.detail.selectedOption);
            }}
            options={selectPartnerNameOptions}
            selectedAriaLabel="Selected"
          />
          <Select
            className="dashboard-list-table-select"
            selectedOption={projectGeo}
            onChange={(event) => {
              setProjectGeo(event.detail.selectedOption);
            }}
            options={selectProjectGeoOptions}
            selectedAriaLabel="Selected"
          />
        </Grid>
      }
      header={
        <Header
          counter={items.length + "/" + approvalsData.length}
          refresh={getApprovalList}
          disabled={isLoading || !selectedItems.length > 0}
          // dataLoading,
          // setDataLoading,
          setNotificationsItems={setNotificationsItems}
          selectedItems={selectedItems}
          selectedType={selectedType}
        />
      }
      onSelectionChange={({ detail }) => {
        let newSelectedType = "";

        if (detail.selectedItems.length > 0) {
          const { fundRequestId, stage } = detail.selectedItems[0];
          const fundingType = getFundingTypeFromFrId(fundRequestId);
          newSelectedType = getSelectedType({ fundingType, stage });
        }

        setSelectedType(newSelectedType);
        setSelectedItems(detail.selectedItems);
      }}
      selectedItems={selectedItems}
      pagination={<Pagination {...paginationProps} />}
    />
  );
};
OldFilterTable.propTypes = {
  setNotificationsItems: PropTypes.func,
};

export default OldFilterTable;
