import React, { useContext } from "react";
import { PartnerApplicationSection } from "internal/components/Review/Sections/PartnerApplication/PartnerApplicationSection";
import { NotificationsContext } from "shared/util/context/notifications/NotificationsContext";

const ApplicationsDetails = () => {
  const { setNotifications } = useContext(NotificationsContext);
  return <PartnerApplicationSection setNotificationsItems={setNotifications} />;
};

export default ApplicationsDetails;
