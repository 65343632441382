import Link from "@amzn/awsui-components-react/polaris/link";
import { STAGE_TO_PAGE } from "./pages";
import { STAGE } from "../../../shared/util/constants/fundRequestStatusType";
import React from "react";

export const generateFundingLinkForDuplicates = (fundRequestId) => {
  let result;
  result = (
    <Link
      href={`/${STAGE_TO_PAGE[STAGE.REVIEW]}/${fundRequestId}/review`}
      target="_blank"
      rel="noopener noreferrer"
    >
      {fundRequestId}
    </Link>
  );

  return result;
};
