export const InMemoryStorage = () => {
  let storage = {};

  return {
    getItem: (key) => (storage[key] ? storage[key] : null),
    setItem: (key, value) => {
      storage[key] = value;
    },
    clear: () => {
      storage = {};
    },
    removeItem: (key) => {
      delete storage[key];
    },
  };
};
