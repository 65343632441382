import { COMPONENT_TYPE } from "../constants/componentType";

const createInputSchema = ({
  errorMessage,
  fieldName,
  schema,
  type,
  pattern,
}) => {
  const inputSchema = {
    $id: `${schema ? `${schema}/` : ""}${fieldName}`,
    type: type || "string",
  };

  if (type === "string") {
    if (pattern) {
      inputSchema["pattern"] = pattern;
    }
    if (errorMessage) {
      inputSchema["errorMessage"] = errorMessage;
    }
  }

  if (type === "money") {
    inputSchema["type"] = "object";
    inputSchema["errorMessage"] = "must be number";
  }

  return inputSchema;
};

const createCheckboxSchema = ({ fieldName, schema }) => ({
  $id: `${schema ? `${schema}/` : ""}${fieldName}`,
  type: "boolean",
});

const createDatePickerSchema = ({ fieldName, schema }) => ({
  $id: `${schema ? `${schema}/` : ""}${fieldName}`,
  type: "object",
  errorMessage: "Please select a date.",
});

const createMultiSelectSchema = ({ fieldName, schema }) => ({
  $id: `${schema ? `${schema}/` : ""}${fieldName}`,
  type: "array",
});

const JsonSchemaFactory = (component) => {
  switch (component) {
    case COMPONENT_TYPE.CHECKBOX:
      return createCheckboxSchema;
    case COMPONENT_TYPE.DATEPICKER:
      return createDatePickerSchema;
    case COMPONENT_TYPE.MULTI_SELECT:
      return createMultiSelectSchema;
    default:
      return createInputSchema;
  }
};

export const createFieldSchemaObject = ({
  component,
  errorMessage,
  fieldName,
  schema,
  type,
  pattern,
}) => {
  return JsonSchemaFactory(component)({
    errorMessage,
    fieldName,
    schema,
    type,
    pattern,
  });
};

export const createJsonSchemaObject = ({
  sectionName,
  fields,
  program,
  page,
}) => {
  const properties = {};

  const requiredFields = fields.filter((field) => {
    let required =
      !field.auto && field.Required && field.Required.includes(program);

    if (field.edit) {
      required = field.edit.includes(page) && required;
    }

    return required;
  });
  const optionalFields = fields.filter(
    (field) => !field.auto && field.Optional && field.Optional.includes(program)
  );

  const required = requiredFields.map((field) => field.name);

  for (let field of requiredFields) {
    const attributes = {
      schema: sectionName,
      fieldName: field.name,
      type: field.type || undefined,
      pattern: field.pattern || undefined,
      errorMessage: field.errorMessage || undefined,
    };
    properties[field.name] = JsonSchemaFactory(field.component)(attributes);
  }

  for (let field of optionalFields) {
    const attributes = {
      schema: sectionName,
      fieldName: field.name,
      type: field.type || undefined,
      pattern: field.pattern || undefined,
      errorMessage: field.errorMessage || undefined,
    };
    properties[field.name] = JsonSchemaFactory(field.component)(attributes);
  }

  return {
    $id: sectionName,
    type: "object",
    properties,
    required,
  };
};
