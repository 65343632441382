import { FieldType } from "shared/programs/migration-acceleration-program/2024/fund-request/components/fields/FieldComponent";
import { IFieldSchema } from "../../../../../types/SchemaTypes";
import { ValidationType } from "shared/programs/migration-acceleration-program/2024/fund-request/components/fields/validations";
import { SelectOptionType } from "shared/programs/migration-acceleration-program/2024/fund-request/hooks/useSelectOptions";

import i18next from "external/i18n";

export const cashRequestDetailsSchema: IFieldSchema[] = [
  {
    label: i18next.t(
      "wizard.section.fundingDetails.content.cashRequestDetails.fields.currency.label"
    ),
    keyName: "currency",
    description: i18next.t(
      "wizard.section.fundingDetails.content.cashRequestDetails.fields.currency.description"
    ) as string,
    placeholder: i18next.t(
      "wizard.section.fundingDetails.content.cashRequestDetails.fields.currency.placeholder"
    ) as string,
    selectOptionType: SelectOptionType.Currencies,
    fieldType: FieldType.Select,
  },
  {
    label: i18next.t(
      "wizard.section.fundingDetails.content.cashRequestDetails.fields.totalCostOfActivity.label"
    ),
    keyName: "totalCostOfActivity",
    description: i18next.t(
      "wizard.section.fundingDetails.content.cashRequestDetails.fields.totalCostOfActivity.description"
    ) as string,
    fieldType: FieldType.Input,
    validations: [ValidationType.ValidNumber],
  },
  {
    label: i18next.t(
      "wizard.section.fundingDetails.content.cashRequestDetails.fields.cashFundingAmount.label"
    ),
    keyName: "cashFundingAmount",
    description: i18next.t(
      "wizard.section.fundingDetails.content.cashRequestDetails.fields.cashFundingAmount.description"
    ) as string,
    fieldType: FieldType.Input,
    validations: [ValidationType.ValidNumber],
  },
];

export const invoiceDetails: IFieldSchema[] = [
  {
    label: i18next.t(
      "wizard.section.fundingDetails.content.invoiceDetails.fields.invoiceEntityName.label"
    ),
    keyName: "invoiceEntityName",
    description: i18next.t(
      "wizard.section.fundingDetails.content.invoiceDetails.fields.invoiceEntityName.description"
    ) as string,
    fieldType: FieldType.Input,
  },
  {
    label: i18next.t(
      "wizard.section.fundingDetails.content.invoiceDetails.fields.invoiceRemitCountry.label"
    ),
    keyName: "invoiceRemitCountry",
    selectOptionType: SelectOptionType.Countries,
    fieldType: FieldType.Select,
  },
  {
    label: i18next.t(
      "wizard.section.fundingDetails.content.invoiceDetails.fields.invoiceRemitStreet.label"
    ),
    keyName: "invoiceRemitStreet",
    description: i18next.t(
      "wizard.section.fundingDetails.content.invoiceDetails.fields.invoiceRemitStreet.description"
    ) as string,
    fieldType: FieldType.Input,
  },
  {
    label: i18next.t(
      "wizard.section.fundingDetails.content.invoiceDetails.fields.invoiceRemitUnit.label"
    ),
    keyName: "invoiceRemitUnit",
    fieldType: FieldType.Input,
    optional: true,
  },
  {
    label: i18next.t(
      "wizard.section.fundingDetails.content.invoiceDetails.fields.invoiceRemitCity.label"
    ),
    keyName: "invoiceRemitCity",
    fieldType: FieldType.Input,
  },
  {
    label: i18next.t(
      "wizard.section.fundingDetails.content.invoiceDetails.fields.invoiceRemitState.label"
    ),
    keyName: "invoiceRemitState",
    fieldType: FieldType.Input,
    conditional: ({ data }) =>
      (data && data["invoiceRemitCountry"] !== "US") || false,
  },
  {
    label: i18next.t(
      "wizard.section.fundingDetails.content.invoiceDetails.fields.invoiceRemitState.label"
    ),
    keyName: "invoiceRemitState",
    fieldType: FieldType.Select,
    selectOptionType: SelectOptionType.USStates,
    conditional: ({ data }) =>
      (data && data["invoiceRemitCountry"] === "US") || false,
  },
  {
    label: i18next.t(
      "wizard.section.fundingDetails.content.invoiceDetails.fields.invoiceRemitPostalCode.label"
    ),
    keyName: "invoiceRemitPostalCode",
    fieldType: FieldType.Input,
  },
];
