import React from "react";
import { COMPONENT_TYPE } from "../../shared/util/constants/componentType";
import { PROGRAM_TYPE } from "../../shared/util/constants/programType";
import { SECTION, SECTION_NAME } from "../../shared/util/constants/section";
import { FINANCIAL_APPROVAL_GUIDE_LINK } from "../../shared/util/constants/constantValues";

export const fundRequestInformationSectionV2 = {
  title: SECTION.FUND_REQUEST_INFORMATION_V2,
  name: SECTION_NAME.FUND_REQUEST_INFORMATION_V2,
  supportedProgram: [PROGRAM_TYPE.MAP2024],
  fields: [
    {
      name: "activityName",
      label: "Project Name",
      auto: false,
      placeholder: "Project or activity name",
      component: COMPONENT_TYPE.INPUT,
      type: "string",
      Required: [PROGRAM_TYPE.MAP2024],
    },
    {
      name: "businessDescription",
      label: "Business Description",
      description: "List out the reasons for why you're requesting funding",
      placeholder:
        "Include business justification, project summary, objectives, success criteria, usage reason for cash claim etc.",
      constraintText:
        "Max of 5000 characters including special characters e.g. space, comma, etc.",
      auto: false,
      component: COMPONENT_TYPE.TEXTAREA,
      type: "string",
      Required: [PROGRAM_TYPE.MAP2024],
    },
    {
      name: "awsCalculatorUrl",
      label: "AWS Pricing Calculator",
      description: "Enter the URL for AWS Pricing Calculator.",
      placeholder:
        "https://calculator.aws/#/estimate?id=1708b1e754b34567890090900ghjfd64ee6f6f3",
      constraintText:
        "For multiple AWS Pricing Calculator URLs, enter each URL separated by a comma ',' with no space in between",
      auto: false,
      component: COMPONENT_TYPE.TEXTAREA,
      type: "string",
      Optional: [PROGRAM_TYPE.MAP2024],
    },
    {
      name: "fundRequestId",
      label: "Fund Request Id",
      auto: true,
      component: COMPONENT_TYPE.KEY_VALUE,
      Required: [PROGRAM_TYPE.MAP2024],
    },
    {
      name: "fundRequestOwner",
      label: "Fund Request Owner",
      auto: true,
      component: COMPONENT_TYPE.KEY_VALUE,
      type: "string",
      Required: [PROGRAM_TYPE.MAP2024],
    },
    {
      name: "status",
      label: "Active Status",
      auto: true,
      component: COMPONENT_TYPE.KEY_VALUE,
      Required: [PROGRAM_TYPE.MAP2024],
    },
  ],
};

export const opportunityInformationSectionV2 = {
  title: SECTION.OPPORTUNITY_INFORMATION_V2,
  name: SECTION_NAME.OPPORTUNITY_INFORMATION_V2,
  supportedProgram: [PROGRAM_TYPE.MAP2024, PROGRAM_TYPE.PIF],
  fields: [
    {
      name: "opportunityId",
      label: "Opportunity Id",
      auto: false,
      component: COMPONENT_TYPE.OPPORTUNITY_SELECT,
      errorMessage: "Please select a valid opportunity.",
      type: "string",
      Required: [PROGRAM_TYPE.MAP2024],
      Optional: [PROGRAM_TYPE.PIF],
    },
    {
      name: "aceOpportunityId",
      label: "ACE Opportunity",
      auto: true,
      hidden: true,
      component: COMPONENT_TYPE.INPUT,
      type: "string",
      Required: [PROGRAM_TYPE.MAP2024],
    },
    {
      name: "awsPartnerManager",
      label: "AWS Partner Manager",
      auto: true,
      component: COMPONENT_TYPE.INPUT,
      type: "string",
      Required: [PROGRAM_TYPE.MAP2024],
    },
    {
      name: "customerCompanyName",
      label: "Customer Company Name",
      auto: true,
      component: COMPONENT_TYPE.INPUT,
      type: "string",
      Required: [PROGRAM_TYPE.MAP2024],
    },
    {
      name: "opportunityOwner",
      label: "Opportunity Owner",
      auto: true,
      component: COMPONENT_TYPE.INPUT,
      type: "string",
      Required: [PROGRAM_TYPE.MAP2024],
    },
    {
      name: "opportunityOwnership",
      label: "Opportunity Ownership",
      auto: true,
      component: COMPONENT_TYPE.INPUT,
      type: "string",
      Required: [PROGRAM_TYPE.MAP2024],
    },
    {
      name: "targetCloseDate",
      label: "Target Close Date",
      auto: true,
      component: COMPONENT_TYPE.INPUT,
      type: "string",
      Required: [PROGRAM_TYPE.MAP2024],
    },
    {
      name: "projectDescription",
      label: "Project Description",
      auto: true,
      component: COMPONENT_TYPE.INPUT,
      type: "string",
      Required: [PROGRAM_TYPE.MAP2024],
    },
    {
      name: "status",
      label: "Status",
      auto: true,
      component: COMPONENT_TYPE.INPUT,
      type: "string",
      Required: [PROGRAM_TYPE.MAP2024],
    },
    {
      name: "aceValidationStage",
      label: "Ace Validation Stage",
      auto: true,
      component: COMPONENT_TYPE.INPUT,
      type: "string",
      Required: [PROGRAM_TYPE.MAP2024],
    },
  ],
};

export const projectInformationSectionV2 = {
  title: SECTION.PROJECT_INFORMATION_V2,
  name: SECTION_NAME.PROJECT_INFORMATION_V2,
  supportedProgram: [PROGRAM_TYPE.MAP2024],
  fields: [
    {
      name: "awsAccountId",
      label: "AWS Account ID",
      description: "Approved credits will be applied to this account ID",
      auto: false,
      component: COMPONENT_TYPE.INPUT,
      type: "string",
      pattern: "^[0-9]{12}$",
      errorMessage: "must be a 12 digit number.",
      Required: [PROGRAM_TYPE.MAP2024],
    },
    {
      name: "plannedStartDate",
      label: "Planned Delivery Start Date",
      description:
        "Work may begin 14 business days after the fund request submission date.",
      auto: false,
      component: COMPONENT_TYPE.DATEPICKER,
      Required: [PROGRAM_TYPE.MAP2024],
    },
    {
      name: "plannedEndDate",
      label: "Planned Delivery End Date",
      description:
        "Cash claims are typically due 30 days after the project end date.",
      auto: false,
      component: COMPONENT_TYPE.DATEPICKER,
      Required: [PROGRAM_TYPE.MAP2024],
    },
    {
      name: "extendedStartDate",
      label: "Extended Start Date",
      auto: true,
      hidden: true,
      component: COMPONENT_TYPE.DATEPICKER,
      Optional: [PROGRAM_TYPE.MAP2024],
    },
    {
      name: "extendedReason",
      label: "Reason for Extension",
      auto: true,
      hidden: true,
      component: COMPONENT_TYPE.TEXTAREA,
      edit: [],
      Optional: [PROGRAM_TYPE.MAP2024],
    },
    {
      name: "extendedEndDate",
      label: "Extended End Date",
      auto: true,
      hidden: true,
      component: COMPONENT_TYPE.DATEPICKER,
      Optional: [PROGRAM_TYPE.MAP2024],
    },
    {
      name: "executedCountry",
      label: "Country Activity is Executed",
      auto: false,
      key: "countries",
      component: COMPONENT_TYPE.SELECT,
      Required: [PROGRAM_TYPE.MAP2024],
    },
    {
      name: "executedState",
      label: "Location/State Activity is Executed (Select All That Apply)",
      auto: false,
      key: "locationSlashStateActivityExecuted",
      component: COMPONENT_TYPE.MULTI_SELECT,
      Required: [PROGRAM_TYPE.MAP2024],
    },
    {
      name: "projectType",
      label: "Project Type",
      auto: true,
      hidden: true,
      key: "projectType",
      component: COMPONENT_TYPE.KEY_VALUE,
    },
    {
      name: "customerConsidering",
      label: "Customer Also Considering",
      auto: false,
      component: COMPONENT_TYPE.SELECT,
      key: "customerAlsoConsidering",
      Required: [PROGRAM_TYPE.MAP2024],
    },
  ],
};

export const cashRequestInformationSectionV2 = {
  title: SECTION.CASH_REQUEST_INFORMATION_V2,
  name: SECTION_NAME.CASH_REQUEST_INFORMATION_V2,
  description: (
    <React.Fragment>
      The{" "}
      <a
        href={FINANCIAL_APPROVAL_GUIDE_LINK}
        target="_blank"
        rel="noopener noreferrer"
      >
        Finance Approval Guide
      </a>{" "}
      provides guidance how to accurately complete cash request information
      using your company’s accounts in Payee Central.
    </React.Fragment>
  ),
  supportedProgram: [PROGRAM_TYPE.MAP2024],
  fields: [
    {
      name: "invoiceEntityName",
      label: "Invoice title",
      placeholder: "Invoice title",
      component: COMPONENT_TYPE.INPUT,
      type: "string",
      auto: false,
      Required: [PROGRAM_TYPE.MAP2024],
    },
    {
      name: "invoiceRemitAddress",
      label: "Invoice billing address",
      component: COMPONENT_TYPE.TEXTAREA,
      placeholder: "100 main street, NY, 11238 USA",
      type: "string",
      auto: false,
      Required: [PROGRAM_TYPE.MAP2024],
    },
    {
      name: "invoiceRemitCountry",
      label: "Invoice Country",
      component: COMPONENT_TYPE.SELECT,
      placeholder: "Select country",
      key: "countries",
      auto: false,
      Required: [PROGRAM_TYPE.MAP2024],
    },
    {
      name: "currency",
      label: "Currency",
      description:
        "The currency must match the preferred currency selected in your company's Amazon Payee Central.",
      placeholder: "Select currency",
      component: COMPONENT_TYPE.SELECT,
      key: "currencies",
      auto: false,
      Required: [PROGRAM_TYPE.MAP2024],
    },
    {
      name: "totalCostOfActivity",
      label: "Total project cost",
      description: "Total cost on SOW or Project Plan.",
      placeholder: "Total project cost",
      component: COMPONENT_TYPE.INPUT,
      type: "money",
      auto: false,
      Required: [PROGRAM_TYPE.MAP2024],
    },
    {
      name: "totalRequestedCashAmount",
      label: "Total fund request from AWS",
      placeholder: "Total cash fund request from AWS",
      component: COMPONENT_TYPE.INPUT,
      type: "money",
      auto: false,
      Required: [PROGRAM_TYPE.MAP2024],
    },
  ],
};

export const cashClaimPlansSectionV2 = {
  title: SECTION.CASH_CLAIM_PLANS_V2,
  name: SECTION_NAME.CASH_CLAIM_PLANS_V2,
  supportedProgram: [PROGRAM_TYPE.MAP2024],
  fields: [
    {
      name: "fundClaimId",
      label: "Fund Claim Id",
      component: COMPONENT_TYPE.INPUT,
      auto: true,
      Required: [PROGRAM_TYPE.MAP2024],
    },
    {
      name: "startDate",
      label: "Planned Milestone/Activity Start Date",
      component: COMPONENT_TYPE.DATEPICKER,
      auto: false,
      Required: [PROGRAM_TYPE.MAP2024],
    },
    {
      name: "endDate",
      label: "Planned Milestone/Activity End Date",
      component: COMPONENT_TYPE.DATEPICKER,
      auto: false,
      Required: [PROGRAM_TYPE.MAP2024],
    },
    {
      name: "dueDate",
      label: "Claim Due Date",
      description:
        "All claims must be submitted before this date with the required Proof of Performance attached. The claim due date is calculated as 30 days after the activity planned end date and prior to December 15 of each year.",
      component: COMPONENT_TYPE.DATEPICKER,
      auto: true,
      Required: [PROGRAM_TYPE.MAP2024],
    },
    {
      name: "percentageCompletion",
      label: "% of Project Complete at this Milestone",
      description:
        "Input the percentage of work completed at time of milestone claim.",
      component: COMPONENT_TYPE.INPUT,
      auto: false,
      type: "string",
      pattern: "^[0-9][0-9]?$|^100$",
      errorMessage: "must be a whole number less than 100.",
      Required: [PROGRAM_TYPE.MAP2024],
    },
    {
      name: "expectedRevenueRamp",
      label: "Expected Revenue Ramp for the Milestone",
      description:
        "Please input the expected quarterly revenue ramp, which will be validated at time of claim for payment.",
      placeholder: "Quarterly revenue ramp should be shown in USD",
      component: COMPONENT_TYPE.INPUT,
      auto: false,
      type: "money",
      pattern: "^[0-9]*$",
      errorMessage: "must be a number.",
      Required: [PROGRAM_TYPE.MAP2024],
    },
  ],
};

export const cashClaimActualsSectionV2 = {
  title: SECTION.CASH_CLAIM_ACTUALS_V2,
  name: SECTION_NAME.CASH_CLAIM_ACTUALS_V2,
  supportedProgram: [PROGRAM_TYPE.MAP2024],
  fields: [
    {
      name: "claimAmount",
      label: "Claim Amount (Local Currency)",
      placeholder: "Enter the amount you would like to claim.",
      component: COMPONENT_TYPE.INPUT,
      type: "money",
      Required: [PROGRAM_TYPE.MAP2024],
    },
    {
      name: "awsAccountId",
      label: "AWS Account ID for Actual Delivery",
      description:
        "Please provide the AWS Account ID associated with this activity or project, and if it's different from the AWS Account ID provided earlier.",
      component: COMPONENT_TYPE.INPUT,
      type: "string",
      pattern: "^[0-9]{12}$",
      errorMessage: "must be a 12 digit number.",
      Optional: [PROGRAM_TYPE.MAP2024],
    },
    {
      name: "startDate",
      label: "Actual Milestone/Activity Start Date",
      component: COMPONENT_TYPE.DATEPICKER,
      Required: [PROGRAM_TYPE.MAP2024],
    },
    {
      name: "endDate",
      label: "Actual Milestone/Activity End Date",
      component: COMPONENT_TYPE.DATEPICKER,
      Required: [PROGRAM_TYPE.MAP2024],
    },
    {
      name: "productionDate",
      label: "Actual Production Date",
      component: COMPONENT_TYPE.DATEPICKER,
      Optional: [PROGRAM_TYPE.MAP2024],
    },
    {
      name: "customerSignOffAttached",
      label: "Proof-Of-Performance Attached",
      component: COMPONENT_TYPE.CHECKBOX,
      Required: [PROGRAM_TYPE.MAP2024],
    },
    {
      name: "completed",
      label: "Claim Completed",
      auto: true,
      component: COMPONENT_TYPE.CHECKBOX,
      Required: [PROGRAM_TYPE.MAP2024],
    },
  ],
};

export const cashClaimInvoicePaymentSectionV2 = {
  title: SECTION.CASH_CLAIM_INVOICE_PAYMENT_V2,
  name: SECTION_NAME.CASH_CLAIM_INVOICE_PAYMENT_V2,
  supportedProgram: [PROGRAM_TYPE.MAP2024],
  fields: [
    {
      name: "currency",
      label: "Invoice Currency",
      component: COMPONENT_TYPE.INPUT,
      key: "currencies",
      auto: true,
      Required: [PROGRAM_TYPE.MAP2024],
    },
    {
      name: "amount",
      label: "Invoice Amount (Local Currency)",
      component: COMPONENT_TYPE.INPUT,
      type: "money",
      auto: true,
      Required: [PROGRAM_TYPE.MAP2024],
    },
    {
      name: "scheduledPaidDate",
      label: "Scheduled Paid Date",
      component: COMPONENT_TYPE.DATEPICKER,
      auto: true,
      Required: [PROGRAM_TYPE.MAP2024],
    },
  ],
};

export const attachmentsSectionV2 = {
  title: SECTION.ATTACHMENTS_V2,
  name: SECTION_NAME.ATTACHMENTS_V2,
  supportedProgram: [PROGRAM_TYPE.MAP2024],
  fields: [
    {
      name: "attachment",
      label:
        "Please attach all supporting documents as required by each funding type, e.g., Project Plan/Checklist, AWS Pricing Calculator, Migration Capability Review, etc.",
      Required: [PROGRAM_TYPE.MAP2024],
      component: COMPONENT_TYPE.FILE_UPLOAD,
    },
  ],
};
