import { hasError, retryApiCall } from "shared/util/services/data/DataService";
import { dateRangeToMs, isExtended } from "shared/util/common/helper";
import { STATUS } from "shared/util/constants/fundRequestStatusType";
import {
  extendFundRequest,
  getFundRequest,
} from "internal/util/services/data/InternalDataService";
import { Enums } from "./Constants";
import { groupBy } from "shared/util/common/util";
import { MAP2024_MAP_PREFIX } from "../../../../shared/util/constants/fund-request";

const convertFromNewFundRequest = (fundRequest) => {
  const oldFundRequestFormat = {
    fundRequestId: fundRequest?.fundRequestId,
    project: {
      plannedEndDate: {
        timestamp: new Date(fundRequest?.project.endDate).getTime(),
      },
    },
    projectExtension: {
      extendedStartDate: null,
      extendedEndDate: null,
    },
  };

  const extensions = fundRequest?.project?.extensions;

  if (extensions?.length > 0) {
    const latestExtension = extensions.slice(-1);
    oldFundRequestFormat["projectExtension"] = {
      extendedStartDate: latestExtension.startDate,
      extendedEndDate: latestExtension.endDate,
    };
  }
  return oldFundRequestFormat;
};

export const preloadFundRequests = async (fundRequests, dispatch) => {
  dispatch({ type: Enums.SET_LOADING, payload: true });
  const work = fundRequests.map((f) =>
    retryApiCall({ callApi: getFundRequest(f.fundRequestId) }).then((resp) => {
      let fundRequest = resp;
      if (f.fundRequestId.startsWith(MAP2024_MAP_PREFIX)) {
        fundRequest = convertFromNewFundRequest(resp["fundRequest"]);
      }
      dispatch({ type: Enums.ADD_FUND_REQUEST, payload: fundRequest });
    })
  );
  await Promise.all(work).catch((err) => {
    console.error(err);
    dispatch({ type: Enums.SET_LOADING, payload: false });
  });
  dispatch({ type: Enums.SET_LOADING, payload: false });
};

export const submitExtends = async (
  selected,
  fundRequests,
  dateRange,
  reason,
  dispatch,
  setNotificationsItems
) => {
  dispatch({ type: Enums.SET_LOADING, payload: true });
  const msExtends = dateRangeToMs(dateRange);
  await Promise.all(
    selected
      .flatMap((s) => fundRequests[s.fundRequestId] || [])
      .map((fr) => {
        const now = Date.now();
        const endDate = fr.project.plannedEndDate.timestamp;
        const extensionStartDate = fr.projectExtension.extendedStartDate;
        const extensionEndDate = fr.projectExtension.extendedEndDate;

        let newExtendStartDate = 0;
        let newExtendEndDate = 0;

        // NOTE: extension is expired so we create a new date.
        if (fr.status === STATUS.EXPIRED && dateRange.type == "relative") {
          newExtendStartDate = now;
          newExtendEndDate = newExtendStartDate + msExtends;
        }
        // NOTE: there is a previous extension and we are currently in that extension.
        //  first extension 5/10/23 --> 6/12/23 (now) --> 6/17/23 --> add 1 month extension
        // second extension 5/10/23 -->       --      --> 7/17/23
        else if (isExtended(fr) && dateRange.type === "relative") {
          newExtendStartDate = extensionStartDate.timestamp;
          newExtendEndDate = extensionEndDate.timestamp + msExtends;
        } else if (dateRange.type === "absolute") {
          newExtendStartDate = new Date(dateRange.startDate).getTime();
          newExtendEndDate = new Date(dateRange.endDate).getTime();
        } else {
          // NOTE: this is the first ever extension
          // or we are overwriting one that hasnt started
          newExtendStartDate = endDate;
          newExtendEndDate = newExtendStartDate + msExtends;
        }
        return retryApiCall({
          callApi: extendFundRequest({
            payload: {
              extendedStartDate: { timestamp: newExtendStartDate },
              extendedEndDate: { timestamp: newExtendEndDate },
              extendedReason: reason,
            },
            fundRequestId: fr.fundRequestId,
          }),
        }).then((resp) => ({ response: resp, fundRequest: fr }));
      })
  ).then((executions) => {
    const { errors, results } = groupBy(executions, ({ response }) =>
      hasError(response) ? "errors" : "results"
    );
    if (errors && errors.length !== 0) {
      const notifications = errors.map((e) => {
        const fundRequestId = e.fundRequest.fundRequestId;
        const errors = Object.values(e.response.errors || e.response.message);
        return {
          header: `Failed to extend ${fundRequestId}`,
          content: errors,
          type: "error",
          dismissible: true,
          dimissLabel: "Dismiss message",
          onDismiss: () => setNotificationsItems([]),
        };
      });
      setNotificationsItems(notifications);
    }
    if (results && results.length != 0) {
      const notifications = results.map((r) => {
        const fundRequestId = r.fundRequest.fundRequestId;
        return {
          header: `Successfully extended ${fundRequestId}`,
          type: "success",
          dismissible: true,
          dimissLabel: "Dismiss message",
          onDismiss: () => setNotificationsItems([]),
        };
      });
      setNotificationsItems(notifications);
    }
  });
  dispatch({ type: Enums.SET_LOADING, payload: false });
  dispatch({ type: Enums.SET_MODAL, payload: false });
};
