import React from "react";
import { Header } from "@amzn/awsui-components-react";
import Table from "@amzn/awsui-components-react/polaris/table";
import { getTableProgress } from "./util";
import { STAGE_NAME } from "shared/components/StatusBar/util";

import { IGenericObject } from "shared/programs/migration-acceleration-program/2024/fund-request/types/CommonTypes";
import { sentenceCase } from "external/programs/migration-acceleration-program/2024/fund-request/utils/string";
import InfoLink from "shared/components/InfoLink";
import { HelpPanelContentContext } from "shared/util/context/help/HelpContext";

interface StageProgressTableProps {
  fundingStage?: string;
}

const getStageName = (stage: string): string => {
  if (!stage) return "";
  if (stage === "partner") return "Created";

  // @ts-expect-error Ignoring since STAGE_NAME comes from legacy js code.
  return STAGE_NAME[stage];
};

const getColumnDefinitions = (validStages: Array<string>) => {
  return validStages.map((stage: string) => ({
    id: stage,
    header: sentenceCase(getStageName(stage)),
    cell: (item: IGenericObject) => item[stage],
  }));
};

const StageProgressTable = ({ fundingStage }: StageProgressTableProps) => {
  const MAPStageList: Array<string> = [
    "partner",
    "business_approval",
    "finance_approval",
    "cash_claim",
    "completed",
  ];

  const { setHelpPanelContent } = React.useContext(HelpPanelContentContext);

  const columnDefinition = getColumnDefinitions(MAPStageList);

  return (
    <Table
      columnDefinitions={columnDefinition}
      items={getTableProgress(fundingStage, MAPStageList)}
      empty={<b>Failed to load Fund request approval progression</b>}
      header={
        <Header
          info={
            <InfoLink
              onFollow={() => {
                setHelpPanelContent("details.progression");
              }}
            />
          }
        >
          Fund request progression
        </Header>
      }
    />
  );
};

export default StageProgressTable;
