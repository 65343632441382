import React from "react";
import { v4 as uuid } from "uuid";
import PropTypes from "prop-types";

export const HelperPanelContent = ({ content, selfServiceHelperTextFlag }) => {
  let helperText = [];
  if (!selfServiceHelperTextFlag && content) {
    helperText = [content];
    if (typeof content === "string" || content instanceof String) {
      helperText = content.split("|");
    }
  }
  return selfServiceHelperTextFlag ? (
    <React.Fragment>{content}</React.Fragment>
  ) : (
    <React.Fragment>
      {helperText.map((content) => (
        <p key={uuid()}>{content}</p>
      ))}
    </React.Fragment>
  );
};

HelperPanelContent.propTypes = {
  content: PropTypes.any,
  selfServiceHelperTextFlag: PropTypes.bool,
};
