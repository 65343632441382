import React from "react";

import { IDetailsProps } from "shared/programs/migration-acceleration-program/2024/fund-request/types/SchemaTypes";
import ReadOnlyOpportunityDetails from "internal/programs/migration-acceleration-program/2024/fund-request/components/details/sections/opportunities/ReadOnlyOpportunityDetails";

const OpportunityView = ({ ...props }: IDetailsProps) => {
  return <ReadOnlyOpportunityDetails {...props} />;
};

export default OpportunityView;
