import React from "react";

import AppLayout from "@amzn/awsui-components-react/polaris/app-layout";
import BreadcrumbGroup from "@amzn/awsui-components-react/polaris/breadcrumb-group";
import { HelpPanel } from "@amzn/awsui-components-react";
import EditContainerForm from "internal/programs/migration-acceleration-program/2024/fund-request/view/EditContainerForm";
import { EditContainerType } from "internal/programs/migration-acceleration-program/2024/fund-request/components/edit/EditContainerFactory/EditContainerComponent";
import { getFundRequestId } from "internal/util/helper";

const MAPMigrationDetailsEditPage = () => {
  return (
    <AppLayout
      breadcrumbs={
        <BreadcrumbGroup
          items={[
            { text: "Dashboard", href: "/dashboard" },
            {
              text: `${getFundRequestId()}`,
              href: `/fund-requests/${getFundRequestId()}/details`,
            },
            { text: "Edit migration details", href: "#" },
          ]}
        />
      }
      contentType="form"
      //TODO: Implement help panel
      tools={<HelpPanel header={<h2>Overview</h2>}>Help content</HelpPanel>}
      content={
        <EditContainerForm
          editContainerType={EditContainerType.MigrationDetails}
        />
      }
      navigationHide
    ></AppLayout>
  );
};

export default MAPMigrationDetailsEditPage;
