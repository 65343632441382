import React from "react";
import PropTypes from "prop-types";
import Button from "@amzn/awsui-components-react/polaris/button";
const RemoveButton = ({ index, removeSection }) => {
  return (
    <div>
      <Button
        onClick={() => {
          removeSection(index);
        }}
      >
        Remove
      </Button>
    </div>
  );
};

RemoveButton.propTypes = {
  index: PropTypes.number,
  removeSection: PropTypes.func,
};

export default RemoveButton;
