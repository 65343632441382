import { MIGRATION_PHASE } from "./migrationPhaseType";
let MAP_LITE_THRESHOLD = 500000;

export const smpMapLite = (data) => {
  return (
    data &&
    data["migrationPhase"] === MIGRATION_PHASE.ASSES_BUSINESS_CASE &&
    data["annualRunRateUnits"] < MAP_LITE_THRESHOLD
  );
};

export const mapLiteCheck = (data) => {
  return data["annualRunRateUnits"] < MAP_LITE_THRESHOLD;
};
