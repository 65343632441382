import { useHistory, useParams } from "react-router-dom";
import { Header, SpaceBetween } from "@amzn/awsui-components-react";
import Button from "@amzn/awsui-components-react/polaris/button";
import {
  getSectionConstant,
  SECTION_CONSTANT_TYPE,
} from "../../../../../shared/util/constants/section";
import { formatData } from "../../../../../shared/util/adapter/toApi/adapter";
import { ValidationFactory } from "../../../../../shared/components/FundRequest/StepsContainer/ValidationFactory";
import {
  ERROR_TYPE,
  hasError,
  retryApiCall,
} from "../../../../../shared/util/services/data/DataService";
import { setErrorMessage } from "../../../../../shared/util/common/helper";
import PropTypes from "prop-types";
import React from "react";
import { DataSubmissionFactory } from "../../../../util/services/data/dataFactory";
export const OpportunityInformationEditFooter = ({
  isLoadingNextStep,
  setIsLoadingNextStep,
  data,
  sectionTitle,
  fieldToIdMap,
  validators,
  setNotificationItems,
  setErrors,
  getTemplate,
  schema,
  filterFields,
  page,
}) => {
  const history = useHistory();
  const { id } = useParams();
  const source = page ? page : "fund-request";
  return (
    <div>
      <Header
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              onClick={() => {
                history.push(`/${source}/${id}/review`);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              loading={isLoadingNextStep}
              onClick={async () => {
                setIsLoadingNextStep(true);
                const template = getTemplate({
                  fundingType: data["fundingType"],
                  migrationPhase: data["migrationPhase"],
                  program: data["program"],
                });

                const currentSectionName = schema[sectionTitle].name;
                const currentSchema = template[currentSectionName];

                let validated = true;
                let validationErrors = {};
                let submissionData = {};

                // Format, and validate data
                const sectionName = getSectionConstant({
                  type: SECTION_CONSTANT_TYPE.NAME,
                  value: sectionTitle,
                });

                const formattedData = formatData(sectionName)({
                  step: currentSchema,
                  stepName: sectionName,
                  stepTitle: sectionTitle,
                  data,
                  fieldMap: fieldToIdMap,
                  schema,
                  filterFields,
                  page,
                });

                //Validate formatted data
                const { errors, valid } = ValidationFactory(sectionTitle)({
                  validators,
                  sectionName,
                  data: formattedData,
                  rawData: data,
                  program: data.program,
                });
                validated = valid && validated;

                submissionData = {
                  ...submissionData,
                  [sectionTitle]: { ...formattedData },
                };

                validationErrors = {
                  ...validationErrors,
                  ...errors,
                };
                let submitted;

                if (!validated) {
                  setErrorMessage({
                    setNotificationsItems: setNotificationItems,
                    content: validationErrors[sectionName],
                  });
                  setIsLoadingNextStep(false);
                } else {
                  // Attempt submission and capture errors.
                  for (let sectionTitle of Object.keys(submissionData)) {
                    const submissionResponse = await retryApiCall({
                      callApi: DataSubmissionFactory({
                        title: sectionTitle,
                        page,
                      })(submissionData[sectionTitle]),
                    });

                    const success = !hasError(submissionResponse);
                    submitted = success;

                    if (!success) {
                      //Handle error
                      const responseBodyKeys = Object.keys(submissionResponse);
                      if (
                        responseBodyKeys.length > 0 &&
                        responseBodyKeys.includes("errors") &&
                        typeof submissionResponse["errors"] === "object" &&
                        submissionResponse["errorType"] !== ERROR_TYPE.BANNER
                      ) {
                        validationErrors = {
                          ...validationErrors,
                          [sectionName]: {
                            ...submissionResponse["errors"],
                            ...validationErrors[sectionName],
                          },
                        };
                      } else {
                        setErrorMessage({
                          setNotificationsItems: setNotificationItems,
                          content:
                            submissionResponse &&
                            (submissionResponse.message ||
                              JSON.stringify(submissionResponse)),
                          status: submissionResponse.status,
                        });
                      }
                    }
                  }

                  setIsLoadingNextStep(false);

                  //Handling errors and preventing continuation.
                  if (!submitted) {
                    const newErrors = {};
                    Object.keys(validationErrors).forEach((sectionName) => {
                      Object.keys(validationErrors[sectionName]).forEach(
                        (fieldName) => {
                          let errorId = fieldToIdMap[sectionName][fieldName];
                          newErrors[errorId] =
                            validationErrors[sectionName][fieldName];
                        }
                      );
                    });
                    setErrors({ ...errors, ...newErrors });
                    return;
                  }
                  history.push(`/${source}/${id}/review`);
                }
              }}
            >
              Save
            </Button>
          </SpaceBetween>
        }
      />
    </div>
  );
};

OpportunityInformationEditFooter.propTypes = {
  isLoadingNextStep: PropTypes.bool,
  setIsLoadingNextStep: PropTypes.func,
  data: PropTypes.object,
  sectionTitle: PropTypes.string,
  fieldToIdMap: PropTypes.object,
  validators: PropTypes.object,
  setNotificationItems: PropTypes.func,
  setErrors: PropTypes.func,
  errors: PropTypes.object,
  getTemplate: PropTypes.func,
  schema: PropTypes.object,
  filterFields: PropTypes.func,
  page: PropTypes.string,
};
