import React, { ReactElement } from "react";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import CashClaimActionsHeader from "internal/programs/migration-acceleration-program/2024/fund-request/components/details/header/claims/CashClaimDetailsActionsHeader";
import { IGenericObject } from "shared/programs/migration-acceleration-program/2024/fund-request/types/CommonTypes";
import ReadOnlyCashClaim from "internal/programs/migration-acceleration-program/2024/fund-request/components/details/sections/funding-details/claims/CashClaim";
import { claimStagesKeys } from "shared/programs/migration-acceleration-program/2024/fund-request/util/claims";
import CashClaimPlan from "internal/programs/migration-acceleration-program/2024/fund-request/components/details/sections/funding-details/claims/CashClaimPlan";

const CashClaimsContainer = ({
  data,
  loading,
}: {
  data: IGenericObject;
  loading: boolean;
  actions?: ReactElement;
}) => {
  if (!data) return <></>;
  const claims: Array<IGenericObject> = data?.claims;

  if (!claims || claims.length === 0) return <></>;
  return (
    <SpaceBetween size="l">
      {claims.map((claim, index) => {
        const claimId = claim.claimId;

        const currentStage = claim?.claimStage;

        const headerTitle = `Cash claim ${index + 1}`;
        const key = `${claimId}-${index}`;

        if (!currentStage || currentStage === claimStagesKeys.partner) {
          return (
            <CashClaimPlan
              key={key}
              headerTitle={headerTitle}
              data={data}
              claim={claim}
              actions={
                <CashClaimActionsHeader
                  data={data}
                  claim={claim}
                  loading={loading}
                />
              }
            />
          );
        }

        return (
          <ReadOnlyCashClaim
            key={key}
            headerTitle={headerTitle}
            data={data}
            claim={claim}
            actions={
              <CashClaimActionsHeader
                data={data}
                claim={claim}
                loading={loading}
              />
            }
          />
        );
      })}
    </SpaceBetween>
  );
};

export default CashClaimsContainer;
