import React from "react";
import Header from "@amzn/awsui-components-react/polaris/header";
import PropTypes from "prop-types";
import { useOptionsContext } from "../../../util/context/OptionsContext";

import {
  schema,
  getInternalTemplate,
  filterFields,
} from "../../../config/schema";
import useFields from "../../../../shared/util/hooks/useFields";
import { getFundRequest } from "../../../util/services/data/InternalDataService";
import {
  SECTION,
  SECTION_NAME,
} from "../../../../shared/util/constants/section";
import Section from "../../../../shared/components/FundRequest/StepsContainer/Section";
import { PROGRAM_TYPE } from "../../../../shared/util/constants/programType";
import { InputPropsBuilder } from "../../../../shared/components/FundRequest/InputPropsBuilder";
import { DisableFactory } from "./DisableFactory";
import { ValueFactory } from "./ValueFactory";
import { InputFactory } from "../../../../shared/components/FundRequest/InputFactory";
import Loading from "../../../../shared/components/common/Loading";

const ProjectInformationEditSectionContainer = ({
  data,
  errors,
  dataInput,
  dataLoad,
  fieldToIdMap,
  updateErrors,
  setFieldToIdMap,
  stepTitle,
  validators,
  page,
  showHelperPanel,
}) => {
  const [options] = useOptionsContext();
  const stepTemplate = schema[stepTitle];
  const [{ fieldTemplate, isLoading }] = useFields({
    data,
    stepTemplate,
    fieldToIdMap,
    updateFieldMap: setFieldToIdMap,
    dataLoad,
    dataInput,
    getTemplate: getInternalTemplate,
    filterFields,
    schema,
    getFundRequest,
  });

  const WwpsSection = () => {
    return (
      <Section
        header={<Header variant="h2">{SECTION.PUBLIC_SECTOR_DETAILS}</Header>}
      >
        <React.Fragment>
          {fieldTemplate &&
            fieldTemplate[SECTION_NAME.PUBLIC_SECTOR_DETAILS] &&
            fieldTemplate[SECTION_NAME.PUBLIC_SECTOR_DETAILS].fields.map(
              (field, index) => {
                if (
                  data["program"] === PROGRAM_TYPE.SMP &&
                  field.name === "wwpsConsent"
                ) {
                  field.description = (
                    <div>
                      By checking this box, you represent and warrant that you
                      have the legal authority to bind the APN Partner applying
                      for funding Benefits (“Partner”) and that Partner agrees
                      to the following:
                      <br />
                      <br />
                      Partner certifies that all information and materials
                      provided to AWS in connection with this request are true,
                      complete, and accurate. Any funding Benefits issued in
                      response to this request must be used only to support the
                      above project (“Project”), and any AWS Promotional Credit
                      (“Credits”) may be redeemed exclusively on the AWS Account
                      ID you provide above. Partner may not retain any surplus
                      Benefits and will inform AWS of any remaining unused
                      Benefits at Project completion. If Partner has not yet
                      been awarded the Project, the funding Benefits are
                      contingent on a final agreement regarding the Project
                      between Partner and the customer and any other conditions
                      under applicable APN funding rules.
                      <br />
                      <br />
                      These funding Benefits are intended to offset the
                      customer’s costs under the Project. Credits will offset
                      AWS Services fees, and cash funding must offset the
                      customer’s other costs, such as the Partner’s professional
                      services fees. Partner may not retain or use any portion
                      of Credits or other funding as additional compensation or
                      margin. Accordingly, Partner must pass on the full value
                      of the Benefits to the customer as a discount or rebate
                      for the work performed under the Project. Partner may not
                      use the funding Benefits in any way to benefit Government
                      personnel (including travel, lodging, gifts, or other
                      contributions). AWS recommends you confirm with the
                      customer’s contracting officer, ethics official, or
                      similar representative that Partner’s use of funding
                      Benefits is lawful, ethical, and permissible.
                      <br />
                      <br />
                      If the customer is a Government customer (as defined in
                      the APN Terms & Conditions), to the maximum extent
                      permitted by applicable bidding or tendering rules,
                      Partner will disclose in writing to the customer that AWS
                      is sponsoring the Project by providing funding (including
                      the amount). Upon request, and to the extent permitted by
                      applicable law or confidentiality agreement between the
                      Partner and the customer, Partner will provide to AWS a
                      copy of such disclosure (or a portion thereof), with
                      appropriate redactions of Partner’s competitively
                      sensitive information. Notwithstanding any nondisclosure
                      agreement between the Partner and AWS, AWS may also
                      disclose details regarding the funding Benefits to the
                      customer or other Government entities.
                    </div>
                  );
                }

                const inputProps = InputPropsBuilder({
                  data,
                  errors,
                  field,
                  options,
                  fieldToIdMap,
                  disableFactory: DisableFactory,
                  valueFactory: ValueFactory,
                  page,
                });

                return (
                  <InputFactory
                    key={index}
                    dataInput={dataInput}
                    fieldToIdMap={fieldToIdMap}
                    updateErrors={updateErrors}
                    validators={validators}
                    showHelperPanel={showHelperPanel}
                    {...inputProps}
                  />
                );
              }
            )}
        </React.Fragment>
      </Section>
    );
  };

  const section = isLoading ? (
    <Loading />
  ) : (
    <React.Fragment>
      <Section header={<Header variant="h2">{stepTitle}</Header>}>
        <React.Fragment>
          {fieldTemplate &&
            fieldTemplate[stepTemplate.name] &&
            fieldTemplate[stepTemplate.name].fields.map((field, index) => {
              const inputProps = InputPropsBuilder({
                data,
                errors,
                field,
                options,
                fieldToIdMap,
                disableFactory: DisableFactory,
                valueFactory: ValueFactory,
                page,
              });

              return (
                <InputFactory
                  key={index}
                  dataInput={dataInput}
                  fieldToIdMap={fieldToIdMap}
                  updateErrors={updateErrors}
                  validators={validators}
                  showHelperPanel={showHelperPanel}
                  {...inputProps}
                />
              );
            })}
        </React.Fragment>
      </Section>
      <br />
      {fieldTemplate[SECTION_NAME.PUBLIC_SECTOR_DETAILS] ? <WwpsSection /> : ""}
    </React.Fragment>
  );
  return <React.Fragment>{section}</React.Fragment>;
};

ProjectInformationEditSectionContainer.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object,
  dataInput: PropTypes.func,
  dataLoad: PropTypes.func,
  fieldToIdMap: PropTypes.object,
  updateErrors: PropTypes.func,
  setTemplate: PropTypes.func,
  validators: PropTypes.any,
  setFieldToIdMap: PropTypes.func,
  stepTitle: PropTypes.string,
  page: PropTypes.string,
  showHelperPanel: PropTypes.func,
};

export default React.memo(ProjectInformationEditSectionContainer);
