import Link from "@amzn/awsui-components-react/polaris/link";
import React from "react";

export const selfServiceColumnDefinition = [
  {
    id: "index",
    header: "Index",
    cell: (e) => e.index,
    sortingField: "index",
  },
  {
    id: "name",
    header: "Entity",
    cell: (e) => {
      const entityType = e.name;
      const link = `/self-service/${entityType}`;
      return <Link href={link}>{entityType}</Link>;
    },
    sortingField: "name",
  },
  {
    id: "value",
    header: "Description",
    cell: (e) => e.value,
    sortingField: "value",
  },
];

export const visibleColumns = ["name", "value"];
export const searchableColumns = ["name", "value"];
export const sortingField = "index";
