import Config from "config";
import { getData, hasError, retryApiCall } from "../data/DataService";

export const API_V1_END_POINT = `${Config.FUNDING_ENDPOINT}/v1`;
export const FEATURE_FLAGS_ENDPOINT = `${API_V1_END_POINT}/features`;

export const getFeatureFlagValue = async (flagName, useContext = null) => {
  const endpoint = `${FEATURE_FLAGS_ENDPOINT}/${flagName}`;
  const queryParam = useContext ? ["withUserContext=t"] : [];
  return await getData({
    endpoint: endpoint,
    queryParam,
  });
};

export const isFeatureEnabled = async (flagName, useContext = null) => {
  try {
    const response = await retryApiCall({
      callApi: getFeatureFlagValue(flagName, useContext),
    });
    if (hasError(response)) {
      return false;
    }
    return response.enabled;
  } catch (e) {
    console.error(e);
    return false;
  }
};
