import React, { useEffect, useState } from "react";
import Link from "@amzn/awsui-components-react/polaris/link";
import TableComponent from "./TableComponent/TableComponent";
import UploadComponent from "./UploadComponent";
import {
  ERROR_TYPE,
  hasError,
  retryApiCall,
} from "../../../../shared/util/services/data/DataService";
import { setErrorMessage } from "../../../util/helper";
import {
  getFileList,
  getCreditDownloadUrl,
} from "../../../util/services/data/InternalDataService";
import withAppLayout from "../../../../shared/components/withAppLayout";
import Loading from "../../../../shared/components/common/Loading";

import "./style.css";

const Credit = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isTableLoading, setIsTableLoading] = useState(true);
  const [uploadComponentVisible, setUploadComponentVisible] = useState(false);
  const [notificationsItems, setNotificationsItems] = useState([]);
  const [fileData, setFileData] = useState([]);
  const [isOpenEnd, setIsOpenEnd] = useState(true);

  useEffect(async () => {
    await getFiles();
  }, []);

  const getFiles = async () => {
    const data = [];

    setIsTableLoading(true);
    let nextPageToken = "";
    while (nextPageToken != null) {
      try {
        let response = await retryApiCall({
          callApi: getFileList(nextPageToken),
        });

        console.log(response);

        //TODO: Add failure condition
        if (hasError(response) && response.errorType === ERROR_TYPE.BANNER) {
          setErrorMessage(setNotificationsItems, "Error", response.message);
          setIsTableLoading(false);
          setIsLoading(false);
          return;
        }

        for (let item of response.items) {
          data.push({
            actions: generateActionButton(item.status, item.fileId),
            status: item.status,
            startTime:
              item.startTime && new Date(item.startTime).toLocaleString(),
            endTime: item.endTime && new Date(item.endTime).toLocaleString(),
            successful: item.successful || 0,
            unsuccessful: item.unsuccessful || 0,
            totalProcessed: item.totalProcessed || 0,
          });
        }

        nextPageToken = response.nextPageToken;
        setFileData(data.slice(0));
      } catch (err) {
        console.error(err);
      }

      setIsTableLoading(false);
      setIsLoading(false);
    }

    setIsOpenEnd(false);
  };

  const downLoadFile = async (fileId) => {
    try {
      let response = await retryApiCall({
        callApi: getCreditDownloadUrl(fileId),
      });

      //TODO: Add failure condition
      if (hasError(response) && response.errorType === ERROR_TYPE.BANNER) {
        setErrorMessage(setNotificationsItems, "Error", response.message);
        setIsLoading({ ...isLoading, review: false });
        return;
      }

      window.location.replace(response.url);
    } catch (err) {
      console.error(err);
    }
  };

  const generateActionButton = (status, fileId) => {
    if (!status || status === "Processing") {
      return "";
    }

    return (
      <div onClick={() => downLoadFile(fileId)}>
        <Link variant="primary">Download</Link>
      </div>
    );
  };

  return isLoading ? (
    <Loading />
  ) : (
    withAppLayout({
      component: (
        <div>
          <TableComponent
            data={fileData}
            setUploadComponentVisible={setUploadComponentVisible}
            isOpenEnd={isOpenEnd}
            isTableLoading={isTableLoading}
            refresh={getFiles}
          />
          <UploadComponent
            uploadComponentVisible={uploadComponentVisible}
            setUploadComponentVisible={setUploadComponentVisible}
            setNotificationsItems={setNotificationsItems}
          />
        </div>
      ),
      toolsHide: true,
      notificationsItems: notificationsItems,
    })()
  );
};

export default Credit;
