import React from "react";

import ConditionalField from "shared/components/common/Conditional/ConditionalField";
import { Button } from "@amzn/awsui-components-react";
import { useHistory } from "react-router-dom";

const DetailsSectionHeaderActions = ({
  visible,
  editPath,
}: {
  visible: boolean;
  editPath: string;
}) => {
  const history = useHistory();
  return (
    <ConditionalField showField={visible}>
      <Button onClick={() => history.push(editPath)}>Edit</Button>
    </ConditionalField>
  );
};

export default DetailsSectionHeaderActions;
