import React from "react";
import PropTypes from "prop-types";
import APFPProgressBar from "./APFPProgressBar";
import Container from "@amzn/awsui-components-react/polaris/container";
import { generateStatus, getProgressValue, showApprovedAmount } from "./util";

import "./style.css";
import { Box } from "@amzn/awsui-components-react";
import { FUNDING_TYPE } from "../../util/constants/programType";
import { uiToApiMap } from "../../util/adapter/toUi/adapter";
import { SECTION_NAME } from "../../util/constants/section";

const StatusBar = ({ data, stage, program, fundingType }) => {
  const cashRequestApiKey = uiToApiMap[SECTION_NAME.CASH_REQUEST_INFORMATION];

  const approvedCashAmountData =
    data && data[cashRequestApiKey]["approvedCashAmount"];
  const approvedCashAmount =
    approvedCashAmountData && approvedCashAmountData.units;

  const cashCurrencyData = data && data[cashRequestApiKey]["currency"];
  const cashCurrency = cashCurrencyData ? `(${cashCurrencyData})` : "";

  const creditRequestApiKey = uiToApiMap[SECTION_NAME.CREDIT_REQUEST_DETAILS];
  const approvedCreditAmountData =
    data && data[creditRequestApiKey]["approvedCreditAmount"];
  const approvedCreditAmount =
    approvedCreditAmountData && approvedCreditAmountData.units;

  const ApprovedFundingDisplay = () => {
    return showApprovedAmount({
      approvedCashAmount,
      approvedCreditAmount,
      stage,
    }) ? (
      <React.Fragment>
        <div id="statusbar__approved-status">
          {approvedCashAmount &&
          !isNaN(approvedCashAmount) &&
          (fundingType === FUNDING_TYPE.CASH ||
            fundingType === FUNDING_TYPE.COMBO) ? (
            <Box textAlign="center" variant="h2" color="text-status-success">
              <span>
                Approved Cash Amount {cashCurrency}:{" "}
                {parseInt(approvedCashAmount).toLocaleString("en-US")}
              </span>
            </Box>
          ) : (
            ""
          )}
          {approvedCreditAmount &&
          !isNaN(approvedCreditAmount) &&
          (fundingType === FUNDING_TYPE.CREDIT ||
            fundingType === FUNDING_TYPE.COMBO) ? (
            <Box textAlign="center" variant="h2" color="text-status-success">
              <span>
                Approved Credit Amount (USD):
                {parseInt(approvedCreditAmount).toLocaleString("en-US")}
              </span>
            </Box>
          ) : (
            ""
          )}
        </div>
      </React.Fragment>
    ) : (
      ""
    );
  };
  return (
    <Container>
      <ApprovedFundingDisplay />
      <APFPProgressBar
        value={getProgressValue(stage, program, fundingType)}
        additionalInfo={generateStatus(stage, program, fundingType)}
        label="Progress Stages"
      />
    </Container>
  );
};

StatusBar.propTypes = {
  data: PropTypes.object,
  stage: PropTypes.string,
  program: PropTypes.string,
  fundingType: PropTypes.string,
};

export default StatusBar;
