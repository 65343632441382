// @ts-nocheck
import React from "react";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import { getFieldComponent } from "shared/programs/migration-acceleration-program/2024/fund-request/components/fields/FieldComponent";
import { IFieldSchema } from "external/programs/migration-acceleration-program/2024/fund-request/types/SchemaTypes";
import { generateCustomComponentProps } from "shared/programs/migration-acceleration-program/2024/fund-request/components/fields/CustomComponentProps";
import {
  getValidations,
  ValidationType,
} from "shared/programs/migration-acceleration-program/2024/fund-request/components/fields/validations";
import ConditionalField from "shared/components/common/Conditional/ConditionalField";
import { formatLabel } from "shared/programs/migration-acceleration-program/2024/fund-request/components/fields/input/InputSectionFieldsFactory";

export interface ISectionFieldsFactory {
  data: { [key: string]: string };
  errors: { [key: string]: string };
  onChange: ({
    key,
    value,
  }: {
    key: string;
    value: string | undefined;
  }) => void;
  onError: ({ key, value }: { key: string; value: string }) => void;
  schema: IFieldSchema[];
  isLoading?: boolean;
}

const InputSectionFieldsFactory = ({
  data,
  errors,
  onChange,
  onError,
  schema,
  isLoading = false,
}: ISectionFieldsFactory) => {
  const fields = schema.map(
    (
      {
        label,
        keyName,
        placeholder,
        description,
        fieldType,
        constraintText,
        validations,
        optional,
        selectOptionType,
        conditional = () => true,
      }: IFieldSchema,
      index: number
    ) => {
      const formattedLabel = formatLabel({ label, optional });
      const value: string = (data && data[keyName]) || "";
      const FieldComponent = getFieldComponent(fieldType);
      const customComponentProps = generateCustomComponentProps({ keyName });

      return (
        <ConditionalField
          key={`${keyName}-conditional-${index}`}
          showField={!!conditional({ data })}
        >
          <FormField
            label={formattedLabel}
            key={`${keyName}-form`}
            description={description}
            errorText={errors[keyName]}
            constraintText={constraintText && constraintText(value)}
          >
            <FieldComponent
              // TODO: this needs to get cleaned up by someone...
              // with an interface for FieldComponent...maybe...?
              label={label}
              value={value}
              key={`${keyName}-input`}
              keyName={keyName}
              placeholder={placeholder}
              selectOptionType={selectOptionType}
              disabled={isLoading}
              onChange={({ detail }) => {
                const value = detail["value"];
                const errors = applyValidations(validations, value);
                if (errors.length) {
                  console.error(`Errors: ${errors.join(",")}`);
                  onError({ key: keyName, value: errors.join(",") });
                  return;
                }
                onChange({ key: keyName, value });
              }}
              {...customComponentProps}
            />
          </FormField>
        </ConditionalField>
      );
    }
  );

  return <SpaceBetween size="l">{fields}</SpaceBetween>;
};

const applyValidations = (
  validations: undefined | ValidationType[],
  value: string | undefined
): string[] => {
  const error: string[] = [];

  if (!validations || !validations.length || !value) return error;

  const inputValidations: ((value: string) => string | undefined)[] =
    getValidations(validations);

  for (const validate of inputValidations) {
    const validated = validate(value);
    if (validated) error.push(validated);
  }

  return error;
};

export default InputSectionFieldsFactory;
