import React from "react";
import {
  ERROR_TYPE,
  hasError,
  retryApiCall,
} from "../../../../../shared/util/services/data/DataService";
import { setErrorMessage } from "../../../../../shared/util/common/helper";
import PropTypes from "prop-types";
import { PartnerApplicationTable } from "./PartnerApplicationTable";

export const PartnerApplicationSection = ({ setNotificationsItems }) => {
  const getPartnerAppDetails = async ({
    getPartnerApplicationDetails,
    setIsLoading,
  }) => {
    const data = [];
    let nextPageToken = "";
    while (nextPageToken != null) {
      try {
        const response = await retryApiCall({
          callApi: getPartnerApplicationDetails({ nextPageToken }),
        });
        console.log(response);
        if (hasError(response) && response.errorType === ERROR_TYPE.BANNER) {
          setErrorMessage({
            setNotificationsItems,
            content: response.message,
            status: response.status,
          });
          setIsLoading(false);
          break;
        }

        data.push(...response.applications);
        nextPageToken = response["nextPageToken"];
      } catch (e) {
        console.error(e);
        setIsLoading(false);
        break;
      }
    }
    return data;
  };

  return (
    <PartnerApplicationTable getPartnerAppDetails={getPartnerAppDetails} />
  );
};

PartnerApplicationSection.propTypes = {
  setNotificationsItems: PropTypes.func,
};
