import {
  PROGRAM_TYPE,
  FUNDING_TYPE,
} from "../../../../../shared/util/constants/programType";

const USD_SUFFIX = "(USD)";

export function getWalletLabel(item) {
  if (item.templateType === "MDF") {
    return createMDFWalletLabel(item.name, item.fundingType);
  }
  return createPIFBudgetLabel(item.name, item.fundingType);
}

function createMDFWalletLabel(walletName, fundingType) {
  const type = fundingType ? fundingType : "";
  if (walletName === "APN and Marketplace MDF") {
    return `${walletName} ${type} Wallet ${USD_SUFFIX}`;
  }
  if (walletName === "training_partner_mdf") {
    walletName = "Training Partner MDF";
  } else if (walletName === "wwps_mdf") {
    walletName = "WWPS MDF";
  }
  return `${walletName} ${type} ${USD_SUFFIX}`;
}

function createPIFBudgetLabel(budgetName, fundingType) {
  const type = fundingType ? fundingType : "";
  return `${budgetName} ${type} ${USD_SUFFIX}`;
}

export function formatWalletValue(amount) {
  if (amount === "-" || amount === null) {
    return "-";
  } else {
    return Number(amount.toFixed(2));
  }
}

export function getUserFriendlyAmount(amount) {
  return `$ ${amount.toLocaleString("en-us")}`;
}

//Method to Discard all those wallets whose allocation, utilization & balance are zeros/null
export function removeInvalidWallets(walletList) {
  return walletList.filter((wallet) => {
    return !(
      (wallet.allocation === 0 &&
        wallet.balance === 0 &&
        wallet.utilized === 0) ||
      (wallet.allocation === null &&
        wallet.balance === null &&
        wallet.utilized === null)
    );
  });
}

//Method to build MDF wallet from custom_mdf & prescriptive_mdf wallet objects
export function buildMDFWalletfromCustomAndPrescriptive(walletList) {
  let MDFCashWallet;
  let MDFCreditWallet;
  let prescriptive_mdf_cash;
  let prescriptive_mdf_credit;
  let custom_mdf_cash;
  let custom_mdf_credit;

  walletList.forEach((wallet) => {
    if (wallet.name === "prescriptive_mdf" && wallet.fundingType === "Cash") {
      prescriptive_mdf_cash = wallet;
    }
    if (wallet.name === "prescriptive_mdf" && wallet.fundingType === "Credit") {
      prescriptive_mdf_credit = wallet;
    }
    if (wallet.name === "custom_mdf" && wallet.fundingType === "Cash") {
      custom_mdf_cash = wallet;
    }
    if (wallet.name === "custom_mdf" && wallet.fundingType === "Credit") {
      custom_mdf_credit = wallet;
    }
  });

  let MDFWalletList = walletList.filter((wallet) => {
    return wallet.name !== "custom_mdf" && wallet.name !== "prescriptive_mdf";
  });
  MDFCashWallet = {
    allocation:
      prescriptive_mdf_cash["allocation"] + custom_mdf_cash["allocation"],
    balance: prescriptive_mdf_cash["balance"] + custom_mdf_cash["balance"],
    fundingType: FUNDING_TYPE.CASH,
    name: "APN and Marketplace MDF",
    templateType: PROGRAM_TYPE.MDF,
    utilized: prescriptive_mdf_cash["utilized"] + custom_mdf_cash["utilized"],
  };

  MDFCreditWallet = {
    allocation:
      prescriptive_mdf_credit["allocation"] + custom_mdf_credit["allocation"],
    balance: prescriptive_mdf_credit["balance"] + custom_mdf_credit["balance"],
    fundingType: FUNDING_TYPE.CREDIT,
    name: "APN and Marketplace MDF",
    templateType: PROGRAM_TYPE.MDF,
    utilized:
      prescriptive_mdf_credit["utilized"] + custom_mdf_credit["utilized"],
  };

  MDFWalletList.push(MDFCashWallet);
  MDFWalletList.push(MDFCreditWallet);
  return MDFWalletList;
}

export function getUtilizationPercentage(totalAmount, utilizedAmount) {
  let innerMetricValue = "";
  let innerMetricDescription = "";

  //calculate utilization % only if there is some budget allocated
  if (
    ![0, "-", null].includes(totalAmount) &&
    !["-", null].includes(utilizedAmount)
  ) {
    innerMetricValue = `${formatWalletValue(
      (utilizedAmount / totalAmount) * 100
    )}%`;
    innerMetricDescription = "Utilization";
  }
  return {
    innerMetricValue: innerMetricValue,
    innerMetricDescription: innerMetricDescription,
  };
}

export function formatWalletsForSpecialCases(wallets) {
  let updatedWallets = [];
  wallets.forEach((wallet) => {
    const updatedWallet = { ...wallet };
    //when utilization is greater than allocated fund or when balance is negative, It indicates zero balance
    if (
      updatedWallet.allocation &&
      (updatedWallet.utilized > updatedWallet.allocation ||
        updatedWallet.balance < 0)
    ) {
      updatedWallet.balance = 0;
    }
    //when budget is not allocated (0$), there is no need to display balance and allocated amounts
    if (updatedWallet.allocation === 0) {
      updatedWallet.balance = "-";
      updatedWallet.allocation = "-";
    }
    updatedWallets.push(updatedWallet);
  });
  return updatedWallets;
}
