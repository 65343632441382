import React, { useEffect, useState } from "react";
import { Box, ExpandableSection } from "@amzn/awsui-components-react";
import PropTypes from "prop-types";
import { v4 as uuid } from "uuid";

import FilesList from "../FundRequest/FilesList";
import {
  ERROR_TYPE,
  hasError,
  retryApiCall,
} from "../../util/services/data/DataService";
import Loading from "../common/Loading";
import { setErrorMessage } from "../../util/common/helper";

export const AttachmentsReviewSectionContainer = ({
  setNotificationsItems,
  getFundRequestAttachments,
  getDownloadUrl,
  header,
}) => {
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(async () => {
    await retrieveFiles();
    setIsLoading(false);
  }, []);

  const retrieveFiles = async () => {
    const data = [];
    let nextPageToken = "";
    while (nextPageToken != null) {
      try {
        let response = await retryApiCall({
          callApi: getFundRequestAttachments(nextPageToken),
        });

        //TODO: Add failure condition
        if (hasError(response) && response.errorType === ERROR_TYPE.BANNER) {
          setErrorMessage({
            setNotificationsItems,
            content: response.message,
            status: response.status,
          });
          setIsLoading(false);
          return;
        }

        for (let item of response.items) {
          const parsedItem = item;
          if (
            !Object.keys(parsedItem).includes("fundClaimId") ||
            !parsedItem["fundClaimId"]
          ) {
            data.push(parsedItem);
          }
        }

        nextPageToken = response.nextPageToken;
      } catch (err) {
        console.error(err);
        break;
      }
    }
    setFiles(data);
  };

  return isLoading ? (
    <Loading />
  ) : (
    <React.Fragment key={uuid()}>
      <ExpandableSection
        variant="container"
        defaultExpanded={true}
        header={header}
      >
        {files.length > 0 ? (
          <FilesList
            files={files}
            setNotificationsItems={setNotificationsItems}
            getDownloadUrl={getDownloadUrl}
          />
        ) : (
          <Box textAlign="center" color="text-body-secondary">
            No attachments available for this fund request.
          </Box>
        )}
      </ExpandableSection>
      <br />
    </React.Fragment>
  );
};

AttachmentsReviewSectionContainer.propTypes = {
  setNotificationsItems: PropTypes.func,
  getFundRequestAttachments: PropTypes.func,
  getDownloadUrl: PropTypes.func,
  header: PropTypes.element,
};
