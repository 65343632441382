import React from "react";
import Container from "@amzn/awsui-components-react/polaris/container";
import Header from "@amzn/awsui-components-react/polaris/header";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Box from "@amzn/awsui-components-react/polaris/box";
import DefaultSubProgramContainer from "./DefaultSubProgramContainer";
import PropTypes from "prop-types";
import { PROGRAM_TYPE } from "../../../../../shared/util/constants/programType";
import PIFSubProgramContainer from "./PIFSubProgramContainer";

const SubProgram = (props) => {
  const { template } = props;
  let ProgramContainer = DefaultSubProgramContainer;

  if (template === PROGRAM_TYPE.PIF) {
    ProgramContainer = PIFSubProgramContainer;
  }

  return (
    <Container header={<Header variant="h2">Programs</Header>}>
      <SpaceBetween size="s">
        <Box>Select program</Box>
        <ProgramContainer {...props} />
      </SpaceBetween>
    </Container>
  );
};

SubProgram.propTypes = {
  template: PropTypes.string,
};

export default SubProgram;
