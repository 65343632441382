import React from "react";
import PartnerCommentDetailsActionWithModal, {
  handlePartnerCommentErrorResponse,
  ISubmitPayload,
} from "external/programs/migration-acceleration-program/2024/fund-request/components/details/FundRequestDetailsContainer/header/actions/PartnerCommentDetailsActionWithModal";
import { terminateFundRequest } from "external/util/services/data/FundRequestService";
import { hasError } from "shared/util/services/data/DataService";
import { IGenericObject } from "shared/programs/migration-acceleration-program/2024/fund-request/types/CommonTypes";
import { Result, Success } from "shared/util/api/util";
import { Box } from "@amzn/awsui-components-react";

const Terminate = ({ isLoading }: { isLoading: boolean }) => {
  const onSubmit = async ({ payload }: ISubmitPayload) => {
    let result = new Result<Success>({});
    const response: IGenericObject = await terminateFundRequest(payload);
    if (hasError(response)) {
      result = handlePartnerCommentErrorResponse({ response });
    }

    return result;
  };

  return (
    <>
      <PartnerCommentDetailsActionWithModal
        isLoading={isLoading}
        submit={onSubmit}
        labels={{
          actionButton: "Terminate",
          title: "Terminate fund request",
          description:
            "Provide the reason why you are terminating this fund request.",
          label: "Terminate reason",
          submitButton: "Terminate fund request",
        }}
      >
        <Box variant="p">
          Some common reasons Partners may terminate a fund request:
          <ul>
            <li>
              Inaccurate information was provided while creating the fund
              request.
            </li>
            <li>
              The customer and/or the partner have had a change in direction.
            </li>
            <li>Funding from AWS is no longer required.</li>
          </ul>
        </Box>
      </PartnerCommentDetailsActionWithModal>
    </>
  );
};

export default Terminate;
