import { getFundRequestId } from "../../../../external/util/common/helper";
import {
  getData,
  postData,
} from "../../../../shared/util/services/data/DataService";
import Config from "config";

export const APPROVAL_AUTOMATION_BASE = Config.AIBA_APPROVAL_REQUEST_ENDPOINT;
export const APPROVAL_AUTOMATION_V1_END_POINT = `${APPROVAL_AUTOMATION_BASE}/v1`;
export const APPROVAL_AUTOMATION_ENDPOINT = `${APPROVAL_AUTOMATION_V1_END_POINT}/approval-result/fund-request`;

export const ASK_QUESTION_BASE = Config.AIBA_ASK_QUESTION_ENDPOINT;
export const ASK_QUESTION_V1_END_POINT = `${ASK_QUESTION_BASE}/v1`;
export const ASK_QUESTION_ENDPOINT = `${ASK_QUESTION_V1_END_POINT}/ask-question/fund-request`;
export const getApprovalAutomationResult = async () => {
  return await getData({
    endpoint: APPROVAL_AUTOMATION_ENDPOINT,
    routeParam: getFundRequestId(),
  });
};

export const askQuestionToAIBA = async ({ payload }) => {
  return await postData({
    endpoint: `${ASK_QUESTION_ENDPOINT}/${getFundRequestId()}`,
    body: JSON.stringify({
      ...payload,
    }),
  });
};
