import React from "react";

import SystemErrorAlert from "shared/programs/migration-acceleration-program/2024/fund-request/components/common/SystemErrorAlert";
import NonQualifiedOpportunityAlert from "external/programs/migration-acceleration-program/2024/fund-request/components/create/wizard/Steps/OpportunityDetails/alerts/errors/NonQualifiedOpportunityAlert";

const opportunityValidationErrors = [
  "Unable to associate opportunity, please contact your PSM.",
];

const OpportunityErrorAlert = ({
  userFriendlyId,
  error,
}: {
  userFriendlyId?: string;
  error: string;
}) => {
  if (opportunityValidationErrors.includes(error) && userFriendlyId) {
    return (
      <NonQualifiedOpportunityAlert
        isVisible={true}
        userFriendlyOpportunityId={userFriendlyId}
      />
    );
  }
  return <SystemErrorAlert isVisible={!!error} error={error} />;
};

export default OpportunityErrorAlert;
