import { STAGE } from "../../../shared/util/constants/fundRequestStatusType";

const financeApprovalOptions = [
  {
    label: "Payee Central Account Set Up Issue (Country/SOR)",
    value: "Payee Central Account Set Up Issue (Country/SOR)",
  },
  {
    label: "Payee Central Account Set Up Issue (City/Street Address)",
    value: "Payee Central Account Set Up Issue (City/Street Address)",
  },
  {
    label: "Payee Central Account Set Up Issue (Currency)",
    value: "Payee Central Account Set Up Issue (Currency)",
  },
  {
    label: "Work started prior to pre-approval",
    value: "Work started prior to pre-approval",
  },
  {
    label: "Claim actuals do not match PoE details or 3rd party receipts",
    value: "Claim actuals do not match PoE details or 3rd party receipts",
  },
  {
    label: "Missing Required Proof of Execution",
    value: "Missing Required Proof of Execution",
  },
  {
    label: "Rejection Requested by Upstream Stakeholder",
    value: "Rejection Requested by Upstream Stakeholder",
  },
  {
    label: "Invalid AWS Account ID for auto-redeemed credits",
    value: "Invalid AWS Account ID for auto-redeemed credits",
  },
  {
    label: "Other",
    value: "Other",
  },
];

const cashClaimApprovalOptions = [
  {
    label: "Payee Central Account Set Up Issue (Country/SOR)",
    value: "Payee Central Account Set Up Issue (Country/SOR)",
  },
  {
    label: "Payee Central Account Set Up Issue (City/Street Address)",
    value: "Payee Central Account Set Up Issue (City/Street Address)",
  },
  {
    label: "Payee Central Account Set Up Issue (Currency)",
    value: "Payee Central Account Set Up Issue (Currency)",
  },
  {
    label: "Work started prior to pre-approval",
    value: "Work started prior to pre-approval",
  },
  {
    label: "Claim actuals do not match PoE details or 3rd party receipts",
    value: "Claim actuals do not match PoE details or 3rd party receipts",
  },
  {
    label: "Missing Required Proof of Execution",
    value: "Missing Required Proof of Execution",
  },
  {
    label: "Rejection Requested by Upstream Stakeholder",
    value: "Rejection Requested by Upstream Stakeholder",
  },
  {
    label: "Invalid AWS Account ID for auto-redeemed credits",
    value: "Invalid AWS Account ID for auto-redeemed credits",
  },
  {
    label: "Other",
    value: "Other",
  },
];

const preApprovalOptions = [
  {
    label: "Payee Central Account Set Up Issue (Country/SOR)",
    value: "Payee Central Account Set Up Issue (Country/SOR)",
  },
  {
    label: "Payee Central Account Set Up Issue (City/Street Address)",
    value: "Payee Central Account Set Up Issue (City/Street Address)",
  },
  {
    label: "Payee Central Account Set Up Issue (Currency)",
    value: "Payee Central Account Set Up Issue (Currency)",
  },
  {
    label: "Work started prior to pre-approval",
    value: "Work started prior to pre-approval",
  },
  {
    label: "Claim actuals do not match PoE details or 3rd party receipts",
    value: "Claim actuals do not match PoE details or 3rd party receipts",
  },
  {
    label: "Missing Required Proof of Execution",
    value: "Missing Required Proof of Execution",
  },
  {
    label: "Rejection Requested by Upstream Stakeholder",
    value: "Rejection Requested by Upstream Stakeholder",
  },
  {
    label: "Invalid AWS Account ID for auto-redeemed credits",
    value: "Invalid AWS Account ID for auto-redeemed credits",
  },
  {
    label: "Other",
    value: "Other",
  },
];

const businessApprovalOptions = [
  {
    label: "Checklist Quarantined",
    value: "Checklist Quarantined",
  },
  {
    label: "Checklist Outdated",
    value: "Checklist Outdated",
  },
  {
    label: "Checklist Incomplete",
    value: "Checklist Incomplete",
  },
  {
    label: "Checklist Missing",
    value: "Checklist Missing",
  },
  {
    label: "Checklist Incorrect (wrong phase)",
    value: "Checklist Incorrect (wrong phase)",
  },
  {
    label: "Project Plan/SOW Missing",
    value: "Project Plan/SOW Missing",
  },
  {
    label: "Project Plan/SOW Quarantined",
    value: "Project Plan/SOW Quarantined",
  },
  {
    label: "Project Plan/SOW Incomplete",
    value: "Project Plan/SOW Incomplete",
  },
  {
    label: "Design Win Missing",
    value: "Design Win Missing",
  },
  {
    label: "Missing Project Details : timeline, resources and budget",
    value: "Missing Project Details : timeline, resources and budget",
  },
  {
    label: "ARR Missing or Inconsistent with artifacts",
    value: "ARR Missing or Inconsistent with artifacts",
  },
  {
    label: "Start date in the past in Project Plan/SOW or Opportunity",
    value: "Start date in the past in Project Plan/SOW or Opportunity",
  },
  {
    label: "Milestone payments not supported",
    value: "Milestone payments not supported",
  },
  {
    label: "Additional Information Needed",
    value: "Additional Information Needed",
  },
  {
    label: "Invalid Opportunity Stage",
    value: "Invalid Opportunity Stage",
  },
  {
    label: "Invalid Request-Duplicate/Test",
    value: "Invalid Request-Duplicate/Test",
  },
  {
    label: "MAP BD Approval Email Missing",
    value: "MAP BD Approval Email Missing",
  },
  {
    label: "Missing AWS reference in the marketing activity",
    value: "Missing AWS reference in the marketing activity",
  },
  {
    label: "Not Aligned with Joint Marketing Plan",
    value: "Not Aligned with Joint Marketing Plan",
  },
  {
    label: "Business Description incomplete",
    value: "Business Description incomplete",
  },
  {
    label: "Missing ROI or forecast metrics",
    value: "Missing ROI or forecast metrics",
  },
  {
    label: "Ineligible activity identified",
    value: "Ineligible activity identified",
  },
  {
    label: "Incorrect MPE Information",
    value: "Incorrect MPE Information",
  },
  {
    label: "Other",
    value: "Other",
  },
];

const reviewOptions = [
  {
    label: "Checklist Quarantined",
    value: "Checklist Quarantined",
  },
  {
    label: "Checklist Outdated",
    value: "Checklist Outdated",
  },
  {
    label: "Checklist Incomplete",
    value: "Checklist Incomplete",
  },
  {
    label: "Checklist Missing",
    value: "Checklist Missing",
  },
  {
    label: "Checklist Incorrect (wrong phase)",
    value: "Checklist Incorrect (wrong phase)",
  },
  {
    label: "Project Plan/SOW Missing",
    value: "Project Plan/SOW Missing",
  },
  {
    label: "Project Plan/SOW Quarantined",
    value: "Project Plan/SOW Quarantined",
  },
  {
    label: "Project Plan/SOW Incomplete",
    value: "Project Plan/SOW Incomplete",
  },
  {
    label: "Design Win Missing",
    value: "Design Win Missing",
  },
  {
    label: "Missing Project Details : timeline, resources and budget",
    value: "Missing Project Details : timeline, resources and budget",
  },
  {
    label: "ARR Missing or Inconsistent with artifacts",
    value: "ARR Missing or Inconsistent with artifacts",
  },
  {
    label: "Start date in the past in Project Plan/SOW or Opportunity",
    value: "Start date in the past in Project Plan/SOW or Opportunity",
  },
  {
    label: "Milestone payments not supported",
    value: "Milestone payments not supported",
  },
  {
    label: "Additional Information Needed",
    value: "Additional Information Needed",
  },
  {
    label: "Invalid Opportunity Stage",
    value: "Invalid Opportunity Stage",
  },
  {
    label: "Invalid Request-Duplicate/Test",
    value: "Invalid Request-Duplicate/Test",
  },
  {
    label: "MAP BD Approval Email Missing",
    value: "MAP BD Approval Email Missing",
  },
  {
    label: "Missing AWS reference in the marketing activity",
    value: "Missing AWS reference in the marketing activity",
  },
  {
    label: "Not Aligned with Joint Marketing Plan",
    value: "Not Aligned with Joint Marketing Plan",
  },
  {
    label: "Business Description incomplete",
    value: "Business Description incomplete",
  },
  {
    label: "Missing ROI or forecast metrics",
    value: "Missing ROI or forecast metrics",
  },
  {
    label: "Ineligible activity identified",
    value: "Ineligible activity identified",
  },
  {
    label: "Other",
    value: "Other",
  },
];

const techApprovalOptions = [
  {
    label: "Customer workloads already in AWS, not MAP eligible",
    value: "Customer workloads already in AWS, not MAP eligible",
  },
  {
    label: "SoW includes out of scope activities that do not qualify for MAP",
    value: "SoW includes out of scope activities that do not qualify for MAP",
  },
  {
    label: "SoW activities are missing or are poorly defined",
    value: "SoW activities are missing or are poorly defined",
  },
  {
    label: "Solution Architecture is missing or is poorly defined",
    value: "Solution Architecture is missing or is poorly defined",
  },
  {
    label: "Workloads are missing or are poorly defined",
    value: "Workloads are missing or are poorly defined",
  },
  {
    label: "Directional plan is missing or is poorly defined",
    value: "Directional plan is missing or is poorly defined",
  },
  {
    label: "Pattern analysis is missing or is poorly defined",
    value: "Pattern analysis is missing or is poorly defined",
  },
  {
    label: "Discrepancy between checklist and SoW",
    value: "Discrepancy between checklist and SoW",
  },
  {
    label: "Other SoW clarifications required",
    value: "Other SoW clarifications required",
  },
  {
    label: "Project Plan/SOW Missing",
    value: "Project Plan/SOW Missing",
  },
  {
    label: "Missing Pricing Calculator",
    value: "Missing Pricing Calculator",
  },
  {
    label: "Incorrect MPE Information",
    value: "Incorrect MPE Information",
  },
  {
    label: "Other",
    value: "Other",
  },
];

export const optionsMap = {
  [STAGE.REVIEW]: reviewOptions,
  [STAGE.TECH_APPROVAL]: techApprovalOptions,
  [STAGE.BUSINESS_APPROVAL]: businessApprovalOptions,
  [STAGE.FINANCE_APPROVAL]: financeApprovalOptions,
  [STAGE.PRE_APPROVAL]: preApprovalOptions,
  [STAGE.CASH_CLAIM]: cashClaimApprovalOptions,
};

export const selfServiceRejectionReasonCodeEntityMap = {
  [STAGE.REVIEW]: "AwsReviewRejectionReasonCode",
  [STAGE.TECH_APPROVAL]: "TechApprovalRejectionReasonCode",
  [STAGE.BUSINESS_APPROVAL]: "BusinessApprovalRejectionReasonCode",
  [STAGE.FINANCE_APPROVAL]: "FinanceApprovalRejectionReasonCode",
  [STAGE.PRE_APPROVAL]: "PreApprovalRejectionReasonCode",
  [STAGE.CASH_CLAIM]: "CashClaimApprovalRejectionReasonCode",
};
