import React from "react";
import withAppLayout from "../../../shared/components/withAppLayout";
import SideNavigation from "../../components/common/SideNavigation";
import { getBreadcrumbs } from "../../../shared/util/common/getBreadcrumbs";
import BulkActionReviewTable from "internal/components/BulkActions/SubmitBulkAction/BulkActionReviewTable";
import { useLocation, useHistory } from "react-router-dom";

const BulkActionsReviewPage = () => {
  const location = useLocation();
  const state = location.state;

  // If the user navigates to the URL directly, redirect them to the main page
  if (state === undefined) {
    const history = useHistory();
    history.replace({
      pathname: "/bulk-actions",
    });
    return null;
  }
  const { records, bulkActionType, fileName } = state;

  return withAppLayout({
    component: (
      <React.Fragment>
        <BulkActionReviewTable
          records={records}
          bulkActionType={bulkActionType}
          fileName={fileName}
        />
      </React.Fragment>
    ),
    breadcrumbs: getBreadcrumbs({ text: "Bulk Actions" }),
    navigationHide: false,
    navigation: <SideNavigation />,
    toolsHide: true,
    notificationsItems: [],
  })();
};

export default BulkActionsReviewPage;
