import React, { useState } from "react";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import PropTypes from "prop-types";
import {
  Box,
  FormField,
  SpaceBetween,
  Textarea,
} from "@amzn/awsui-components-react";
import Button from "@amzn/awsui-components-react/polaris/button";
import {
  ERROR_TYPE,
  hasError,
  retryApiCall,
} from "../../../../../shared/util/services/data/DataService";
import {
  setErrorMessage,
  setSuccessMessage,
} from "../../../../../shared/util/common/helper";
import { changeStatus } from "../../../../util/services/data/dataFactory";
import { EXTERNAL_REVIEW_ACTION_TYPE } from "../../../../util/constants/reviewActions";
import { PROGRAM_TYPE } from "../../../../../shared/util/constants/programType";

const ChangeStatus = ({
  history,
  reviewAction,
  visible,
  setNotificationsItems,
  setModalVisibility,
  program,
  id,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [comment, setComment] = useState("");
  const [error, setNewError] = useState("");

  const submitStatusChange = async () => {
    setIsLoading(true);
    try {
      const response = await retryApiCall({
        callApi: changeStatus(reviewAction)({ comment }),
      });
      console.log(response);

      //TODO: Add failure condition
      if (hasError(response) && response.errorType === ERROR_TYPE.BANNER) {
        setErrorMessage({
          setNotificationsItems,
          content: response.message,
          status: response.status,
        });
        setIsLoading(false);
        setModalVisibility(reviewAction.toLowerCase(), false);
      } else {
        setIsLoading(false);
        setModalVisibility(reviewAction.toLowerCase(), false);
        setSuccessMessage(
          setNotificationsItems,
          "Updated",
          `Successfully ${reviewAction.toLowerCase()}ed fund request.`
        );
        if (reviewAction === EXTERNAL_REVIEW_ACTION_TYPE.RECALL) {
          let redirect = `/fund-request/${id}/draft`;
          if (program === PROGRAM_TYPE.POC || program === PROGRAM_TYPE.SMP) {
            redirect = `/fund-request/${id}/select-program/edit`;
          }
          history.push(redirect, {
            origin: "Recall",
            successMessage: `Successfully recalled fund request: ${id}`,
          });
        }
        if (reviewAction === EXTERNAL_REVIEW_ACTION_TYPE.TERMINATE) {
          history.push(`/dashboard`, {
            origin: "Terminate",
            successMessage: `Successfully terminated fund request: ${id}`,
          });
        }
      }
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <Modal
      id="change-status"
      onDismiss={() => setModalVisibility(reviewAction.toLowerCase(), false)}
      visible={visible}
      closeAriaLabel="Close modal"
      size="large"
      header={`${reviewAction} Fund Request`}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              onClick={() => {
                setModalVisibility(reviewAction.toLowerCase(), false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              disabled={!comment}
              loading={isLoading}
              onClick={async () => {
                await submitStatusChange();
              }}
            >
              Submit
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <FormField label="Comment" errorText={error}>
        <Textarea
          id="status-comment"
          value={comment}
          onChange={({ detail }) => {
            setNewError("");
            setComment(detail.value);
          }}
        />
      </FormField>
    </Modal>
  );
};

ChangeStatus.propTypes = {
  history: PropTypes.any,
  program: PropTypes.string,
  reviewAction: PropTypes.string,
  visible: PropTypes.bool,
  setNotificationsItems: PropTypes.func,
  setModalVisibility: PropTypes.func,
  id: PropTypes.string,
};

export default ChangeStatus;
