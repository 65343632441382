import Link from "@amzn/awsui-components-react/polaris/link";
import { PARTNER_FUNDING_BENEFIT_GUIDE_LINK } from "../../../shared/util/constants/constantValues";
import React from "react";

export const mdfBanner = (setNotificationsItems) => {
  const currentDate = new Date();
  const currentYear = new Date().getFullYear();
  const targetDate = new Date(currentYear, 6, 12);

  if (currentDate <= targetDate) {
    setNotificationsItems([
      {
        type: "warning",
        content: (
          <React.Fragment>
            Reminder: For MDF funding, up to 50% of your MDF Cash Wallet Balance
            will expire on June 30th if these funds are not associated with a
            fund request submission (regardless of activity start date). Refer
            to the{" "}
            <Link
              href={PARTNER_FUNDING_BENEFIT_GUIDE_LINK}
              color="inverted"
              target="_blank"
              rel="noopener noreferrer"
            >
              AWS Partner Funding Benefit Guide
            </Link>{" "}
            for more details.
          </React.Fragment>
        ),
        dismissible: true,
        dimissLabel: "Dismiss message",
        onDismiss: () => setNotificationsItems([]),
        id: "mdf_display_banner",
      },
    ]);
  }
};
