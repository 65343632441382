import { hasError } from "shared/util/services/data/DataService";
import { setErrorMessage, setSuccessMessage } from "shared/util/common/helper";
import { getReducerType } from "shared/components/UserPersistedSettings/util";
import {
  saveUserSettings,
  updateUserSettings,
  deleteUserSettings,
} from "shared/components/UserPersistedSettings/Api";
import { FilterSetAction } from "./constants";

export const submitSavedFilterSets = async ({
  version,
  componentName,
  componentAttribute,
  entityType,
  filterSetAction,
  newFilterSets,
  userSettingsDispatch,
  setNotificationsItems,
}) => {
  let response = "";
  try {
    const isSavedFilterSetsEmpty = newFilterSets?.length === 0;
    const isSavedFilterSetsSizeOne = newFilterSets?.length === 1;
    if (isSavedFilterSetsEmpty) {
      response = await deleteUserSettings(
        version,
        componentName,
        componentAttribute
      );
    } else if (
      isSavedFilterSetsSizeOne &&
      filterSetAction === FilterSetAction.NEW
    ) {
      response = await saveUserSettings(
        version,
        componentName,
        componentAttribute,
        entityType,
        newFilterSets
      );
    } else {
      response = await updateUserSettings(
        version,
        componentName,
        componentAttribute,
        entityType,
        newFilterSets
      );
    }

    if (response && hasError(response)) {
      console.log(response);
      setErrorMessage({
        setNotificationsItems: setNotificationsItems,
        content: JSON.stringify(response.errors),
        status: response.status,
      });
      return false;
    }
    userSettingsDispatch({
      type: getReducerType(version, componentName, componentAttribute),
      data: newFilterSets,
    });
    setSuccessMessage(
      setNotificationsItems,
      "Success",
      "Successfully updated the saved filter sets!"
    );
    return true;
  } catch (e) {
    console.error(e);
    setErrorMessage({
      setNotificationsItems: setNotificationsItems,
    });
    return false;
  }
};
