import Dashboard from "../components/Dashboard";
import StepsContainer from "../components/FundRequest/StepsContainer";
import Review from "../components/FundRequest/Review";
import EditContainer from "../components/FundRequest/Edit/EditContainer";
import ProgramSelectEditPage from "../pages/ProgramSelect/Edit";
import WalletPage from "../pages/Wallet";
import CreateFundRequestWizardPage from "../pages/CreateFundRequestWizard";
import SubmitCashClaimActualsPage from "../pages/SubmitCashClaimActuals";
import FundRequestDetailsPage from "../pages/FundRequestDetails/FundRequestDetailsPage";
import ProgramSelectSwitcher from "../components/FundRequest/ProgramSelectSwitcher";
import EditMigrationEligibilityPage from "../pages/edit/EditMigrationEligibilityPage";

export const SYSTEM_ADMIN = "System Administrator";
export const PARTNER_USER = "APN Partner User";
export const ALLIANCE_LEAD = "APN Alliance Lead";

export const RouteType = {
  PARENT: "parent",
  CHILD: "child",
};

// Order matters here. The first item that shows up will be the default page that gets loaded for users.
const possibleRoutes = [
  {
    name: "TOBEDELETED - Details",
    permitted: [SYSTEM_ADMIN],
    path: "/map2024details",
    main: false,
    component: FundRequestDetailsPage,
  },
  {
    name: "TOBEDELETED - Wizard",
    permitted: [SYSTEM_ADMIN],
    path: "/map2024",
    main: false,
    component: CreateFundRequestWizardPage,
  },
  {
    name: "TOBEDELETED - Submit Cash Claim Actual",
    permitted: [SYSTEM_ADMIN],
    path: "/map2024/cash-claim-actual/submit",
    main: false,
    component: SubmitCashClaimActualsPage,
  },
  {
    name: "Wallet",
    permitted: [SYSTEM_ADMIN, PARTNER_USER, ALLIANCE_LEAD],
    path: "/wallet",
    main: false,
    component: WalletPage,
  },
  {
    name: "Edit Template",
    permitted: [SYSTEM_ADMIN, PARTNER_USER, ALLIANCE_LEAD],
    path: "/fund-request/:id/select-program/edit",
    main: false,
    component: ProgramSelectEditPage,
  },
  {
    name: "Edit Template",
    permitted: [SYSTEM_ADMIN, PARTNER_USER, ALLIANCE_LEAD],
    path: "/fund-requests/:id/select-program/edit",
    main: false,
    component: EditMigrationEligibilityPage,
  },
  {
    name: "Edit Cash Claim",
    permitted: [SYSTEM_ADMIN, PARTNER_USER, ALLIANCE_LEAD],
    path: "/fund-request/:id/:section/:cashClaimId/edit",
    main: false,
    component: EditContainer,
  },
  {
    name: "Edit Fund Request",
    permitted: [SYSTEM_ADMIN, PARTNER_USER, ALLIANCE_LEAD],
    path: "/fund-request/:id/:section/edit",
    main: false,
    component: EditContainer,
  },
  {
    name: "Edit Fund Request Attachments",
    permitted: [SYSTEM_ADMIN, PARTNER_USER, ALLIANCE_LEAD],
    path: "/fund-request/:id/attachments/edit",
    main: false,
    component: EditContainer,
  },
  {
    name: "Submit Claim",
    permitted: [SYSTEM_ADMIN, PARTNER_USER, ALLIANCE_LEAD],
    path: "/fund-requests/:id/:claimId/new",
    main: false,
    component: SubmitCashClaimActualsPage,
  },
  {
    name: "Fund Request Details",
    permitted: [SYSTEM_ADMIN, PARTNER_USER, ALLIANCE_LEAD],
    path: "/fund-requests/:id/details",
    main: false,
    component: FundRequestDetailsPage,
  },
  {
    name: "Fund Request Creation Wizard",
    permitted: [SYSTEM_ADMIN, PARTNER_USER, ALLIANCE_LEAD],
    path: "/fund-requests/:id/new",
    main: false,
    component: CreateFundRequestWizardPage,
  },
  {
    name: "Create Fund Request",
    permitted: [SYSTEM_ADMIN, PARTNER_USER, ALLIANCE_LEAD],
    path: "/fund-request/:id/draft",
    main: false,
    component: StepsContainer,
  },
  {
    name: "Program Select",
    permitted: [SYSTEM_ADMIN, PARTNER_USER, ALLIANCE_LEAD],
    path: "/fund-request/select-program",
    main: false,
    component: ProgramSelectSwitcher,
  },
  {
    name: "Review",
    permitted: [SYSTEM_ADMIN, PARTNER_USER, ALLIANCE_LEAD],
    path: "/fund-request/:id/review",
    main: false,
    component: Review,
  },
];

export const routeFactory = (roles) => {
  let routes = [
    {
      name: "Dashboard",
      permitted: [SYSTEM_ADMIN, PARTNER_USER, ALLIANCE_LEAD],
      path: "/dashboard",
      main: true,
      component: Dashboard,
    },
  ];
  const addedRoutes = new Set();
  if (roles.includes(SYSTEM_ADMIN)) {
    routes = routes.concat(possibleRoutes);
  } else {
    for (let role of roles) {
      routes = routes.concat(
        possibleRoutes.filter((route) => {
          const permitted =
            !addedRoutes.has(route.path) && route.permitted.includes(role);
          if (!addedRoutes.has(route.path) && route.permitted.includes(role)) {
            addedRoutes.add(route.path);
          }
          return permitted;
        })
      );
    }
  }

  return routes;
};
