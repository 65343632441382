const bulkExpirationResultTransform = (result) => {
  let csvData = [["Fund Request ID", "Status", "Reason"]];
  result.forEach((resultEntry) => {
    csvData.push([
      resultEntry.entryId ?? "",
      resultEntry.status.toUpperCase(),
      resultEntry.reason ?? "",
    ]);
  });

  return csvData;
};

const bulkUpdateCashClaimInvoiceResultTransform = (result) => {
  let csvData = [["Fund Request ID", "Fund Claim ID", "Status", "Reason"]];
  result.forEach((resultEntry) => {
    let fr_id, fund_claim_id;
    [fr_id, fund_claim_id] = resultEntry.entryId.split("#");
    csvData.push([
      fr_id ?? "",
      fund_claim_id ?? "",
      resultEntry.status.toUpperCase(),
      resultEntry.reason ?? "",
    ]);
  });

  return csvData;
};

const bulkMAPUnlockResultTransform = (result) => {
  let csvData = [["Fund Request ID", "Fund Claim ID", "Status", "Reason"]];
  result.forEach((resultEntry) => {
    let fr_id, fund_claim_id;
    [fr_id, fund_claim_id] = resultEntry.entryId.split("#");
    csvData.push([
      fr_id ?? "",
      fund_claim_id ?? "",
      resultEntry.status.toUpperCase(),
      resultEntry.reason ?? "",
    ]);
  });

  return csvData;
};

export const BULK_ACTION_TYPE_TO_RESULT_TRANSFORM_FN = {
  expire_fund_request: (records) => bulkExpirationResultTransform(records),
  update_cash_claim_invoice: (records) =>
    bulkUpdateCashClaimInvoiceResultTransform(records),
  unlock_claim: (records) => bulkMAPUnlockResultTransform(records),
};
