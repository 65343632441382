import React, { ReactNode } from "react";
import { Box } from "@amzn/awsui-components-react";

const ValueWithLabel = ({
  label,
  children,
}: {
  label: string;
  children: ReactNode;
}) => {
  return (
    <div>
      <Box variant="awsui-key-label">{label}</Box>
      <div>{children}</div>
    </div>
  );
};

export default ValueWithLabel;
