import React from "react";
import Button from "@amzn/awsui-components-react/polaris/button";
import Header from "@amzn/awsui-components-react/polaris/header";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Terminate from "external/programs/migration-acceleration-program/2024/fund-request/components/details/FundRequestDetailsContainer/header/actions/Terminate";
import Recall from "external/programs/migration-acceleration-program/2024/fund-request/components/details/FundRequestDetailsContainer/header/actions/Recall";
import ChangeOwner from "external/programs/migration-acceleration-program/2024/fund-request/components/details/FundRequestDetailsContainer/header/actions/ChangeOwner";
import Extend from "external/programs/migration-acceleration-program/2024/fund-request/components/details/FundRequestDetailsContainer/header/actions/Extend";
import ConditionalField from "shared/components/common/Conditional/ConditionalField";
import { IGenericObject } from "shared/programs/migration-acceleration-program/2024/fund-request/types/CommonTypes";
import { useHistory } from "react-router-dom";
import { showRecall, showSubmit, showTerminate, showExtend } from "../util";

const FundRequestDetailsHeader = ({
  data,
  refresh,
  isLoading,
}: {
  data: IGenericObject;
  refresh: () => void;
  isLoading: boolean;
}) => {
  const history = useHistory();

  return (
    <Header
      description="Migration Acceleration Program (MAP) fund request"
      variant="h1"
      actions={
        <SpaceBetween direction="horizontal" size="xs">
          <Button
            loading={isLoading}
            iconName="refresh"
            onClick={() => refresh()}
          >
            Refresh
          </Button>
          <ConditionalField showField={showSubmit(data)}>
            <Button
              variant="primary"
              onClick={() =>
                history.push(
                  `/fund-requests/${data.fundRequestId}/select-program/edit`
                )
              }
            >
              Submit
            </Button>
          </ConditionalField>
          <ConditionalField showField={showTerminate(data)}>
            <Terminate isLoading={isLoading} />
          </ConditionalField>
          <ConditionalField showField={showRecall(data)}>
            <Recall isLoading={isLoading} />
          </ConditionalField>
          <ChangeOwner isLoading={isLoading} />
          <ConditionalField showField={showExtend(data)}>
            <Extend isLoading={isLoading} />
          </ConditionalField>
        </SpaceBetween>
      }
    >
      {data?.fundRequestId}
    </Header>
  );
};

export default FundRequestDetailsHeader;
