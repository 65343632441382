import React from "react";
import { isSMPLarge } from "shared/util/common/util";
import { Alert } from "@amzn/awsui-components-react";
import { FEATURE_FLAG_ENUMS } from "shared/util/constants/featureFlagValues";
import PropTypes from "prop-types";
import withFeatureFlag from "shared/components/FeatureFlag";
import { MigrationEngagementHeaderAction } from "../Sections/MigrationEngagement/MigrationEngagementHeaderAction";

const MigrationEngagementAlert = ({ fundingData, page }) => {
  return (
    isSMPLarge(fundingData) &&
    fundingData.migrationEngagements.length === 0 && (
      <Alert
        statusIconAriaLabel="Error"
        type="error"
        header="No Migration program Engagement (MPE) match"
        action={
          <MigrationEngagementHeaderAction
            fundingData={fundingData}
            page={page}
          ></MigrationEngagementHeaderAction>
        }
      >
        Work with the migration BD to review if the MPE has been correctly
        created and is at the qualified stage before proceeding.
      </Alert>
    )
  );
};

MigrationEngagementAlert.propTypes = {
  fundingData: PropTypes.object,
  page: PropTypes.string,
};

export default withFeatureFlag(
  MigrationEngagementAlert,
  FEATURE_FLAG_ENUMS.DISPLAY_MPE_RECORDS_table,
  true
);
