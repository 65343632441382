import React, { Suspense, useEffect } from "react";
import Routes from "./components/common/Routes";
import Loading from "../shared/components/common/Loading";
import { getUsername } from "./util/services/auth/AuthService";
import { logout } from "../shared/util/services/auth/AuthService";

import {
  getFundrequestId,
  getUserCognitoId,
} from "../shared/util/services/auth/AuthService";
import Config from "config";
import "./App.css";
import TopNavBar from "../shared/components/common/TopNavBar";
import { analytics } from "../internal/util/services/metrics/analytics";
import { useLocation } from "react-router-dom";

function App() {
  const location = useLocation();
  useEffect(async () => {
    if (Config.RUM_APP_ID && Config.RUM_ROLE_ARN && Config.RUM_IDPOOL_ID) {
      analytics.enable();
      analytics.addSessionAttributes({
        fundrequestId: getFundrequestId() || "",
        userCognitoId: getUserCognitoId() || "",
      });
      analytics.recordPageView(location.pathname);
    }
  }, [location.pathname]);

  return (
    <div className="awsui">
      <TopNavBar
        utilities={[
          {
            type: "menu-dropdown",
            text: getUsername(),
            iconName: "user-profile",
            onItemClick: ({ detail: { id } }) => {
              if (id === "signout") {
                logout();
                window.location.replace(window.location.href);
              }
            },
            items: [
              {
                id: "signout",
                text: "Refresh Credentials",
              },
            ],
          },
        ]}
      />
      <Suspense fallback={<Loading />}>
        <Routes />
      </Suspense>
    </div>
  );
}

export default App;
