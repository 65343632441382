export const yyyymmddLocalDateFormat = (date: Date) => {
  if (!date) return "-";

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}/${month}/${day}`;
};

export const longLocalDateFormat = (date: string) => {
  return new Date(date).toLocaleString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });
};

export const absoluteHumanReadableDateFormat = (date: string) => {
  const tempDate = new Date(date);

  const longDateFormat = tempDate.toLocaleString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });

  const timeFormat = tempDate.toLocaleTimeString("en-US", {
    hour12: false,
    hour: "2-digit",
    minute: "2-digit",
    timeZone: "UTC",
  });

  const formattedDate = `${longDateFormat}, ${timeFormat} (UTC`;

  console.table([longDateFormat, timeFormat, formattedDate]);

  return formattedDate;
};
