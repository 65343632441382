import React from "react";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Button from "@amzn/awsui-components-react/polaris/button";
import ConditionalField from "shared/components/common/Conditional/ConditionalField";

const ArtifactsDetailsActions = ({
  isLoading,
  isEnabled,
  areFilesMutable,
  download,
  remove,
  refresh,
  addAttachment,
}: {
  isLoading: boolean;
  isEnabled: boolean;
  areFilesMutable: boolean;
  download: () => void;
  remove: () => void;
  refresh: () => void;
  addAttachment: () => void;
}) => {
  return (
    <SpaceBetween direction="horizontal" size="xs">
      <Button
        iconName="download"
        disabled={!isEnabled}
        loading={isLoading}
        onClick={download}
      >
        Download
      </Button>
      <ConditionalField showField={areFilesMutable}>
        <Button disabled={!isEnabled} loading={isLoading} onClick={remove}>
          Remove
        </Button>
      </ConditionalField>
      <Button iconName={"refresh"} loading={isLoading} onClick={refresh}>
        Refresh scan status
      </Button>
      <ConditionalField showField={areFilesMutable}>
        <Button loading={isLoading} onClick={addAttachment}>
          Add attachment
        </Button>
      </ConditionalField>
    </SpaceBetween>
  );
};

export default ArtifactsDetailsActions;
