import React, { createContext, useReducer } from "react";
import PropTypes from "prop-types";
import { reducer } from "./Reducer";
import { initialData } from "./Constants";

export const ExtendContext = createContext(initialData);

export const ExtendContextProvider = ({ children }) => {
  const [extendState, extendDispatch] = useReducer(reducer, initialData);

  const providerState = {
    extendState,
    extendDispatch,
  };

  return (
    <ExtendContext.Provider value={providerState}>
      {children}
    </ExtendContext.Provider>
  );
};

ExtendContextProvider.propTypes = {
  children: PropTypes.any,
};
