import React from "react";
import { Box } from "@amzn/awsui-components-react";
import PropTypes from "prop-types";
import { UtilizationChart } from "./UtilizationChart";
import Grid from "@amzn/awsui-components-react/polaris/grid";

export const AvailableFunds = ({
  budget,
  fundType,
  isLoading,
  utilization,
}) => {
  return (
    <div>
      <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
        <div>
          <Box textAlign="center" variant="h4">
            Total {fundType}
          </Box>
          <Box textAlign="center">
            {parseFloat(budget.toFixed(2)).toLocaleString("en-US")}
          </Box>
        </div>
        <div>
          <Box textAlign="center" variant="h4">
            {fundType} Available
          </Box>
          <Box textAlign="center">
            {parseFloat((budget - utilization).toFixed(2)).toLocaleString(
              "en-US"
            )}
          </Box>
        </div>
      </Grid>

      <UtilizationChart
        isLoading={isLoading}
        utilization={utilization}
        budget={budget}
      />
    </div>
  );
};

AvailableFunds.propTypes = {
  budget: PropTypes.number,
  fundType: PropTypes.string,
  isLoading: PropTypes.bool,
  utilization: PropTypes.number,
};
