import { SECTION_NAME } from "../../constants/section";

export const apiToUiMap = {
  details: SECTION_NAME.FUND_REQUEST_INFORMATION,
  opportunity: SECTION_NAME.OPPORTUNITY_INFORMATION,
  project: SECTION_NAME.PROJECT_INFORMATION,
  migrations: SECTION_NAME.MIGRATION_DETAILS,
  workloads: SECTION_NAME.MIGRATION_WORKLOADS,
  migrationEngagements: SECTION_NAME.MIGRATION_PROGRAM_ENGAGEMENTS,
  publicSector: SECTION_NAME.PUBLIC_SECTOR_DETAILS,
  cashRequest: SECTION_NAME.CASH_REQUEST_INFORMATION,
  creditRequest: SECTION_NAME.CREDIT_REQUEST_DETAILS,
  attachments: SECTION_NAME.ATTACHMENTS,
  apnPartner: SECTION_NAME.APN_PARTNER_DETAILS,
  approval: SECTION_NAME.APPROVAL_DETAILS,
};

export const uiToApiMap = {
  [SECTION_NAME.FUND_REQUEST_INFORMATION]: "details",
  [SECTION_NAME.OPPORTUNITY_INFORMATION]: "opportunity",
  [SECTION_NAME.PROJECT_INFORMATION]: "project",
  [SECTION_NAME.MIGRATION_DETAILS]: "migrations",
  [SECTION_NAME.MIGRATION_WORKLOADS]: "workloads",
  [SECTION_NAME.MIGRATION_PROGRAM_ENGAGEMENTS]: "migrationEngagements",
  [SECTION_NAME.PUBLIC_SECTOR_DETAILS]: "publicSector",
  [SECTION_NAME.CASH_REQUEST_INFORMATION]: "cashRequest",
  [SECTION_NAME.CREDIT_REQUEST_DETAILS]: "creditRequest",
  [SECTION_NAME.ATTACHMENTS]: "attachments",
  [SECTION_NAME.APN_PARTNER_DETAILS]: "apnPartner",
  [SECTION_NAME.APPROVAL_DETAILS]: "approval",
};
