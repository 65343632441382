import React, { ReactElement } from "react";
import { AppLayoutProps, HelpPanel } from "@amzn/awsui-components-react";
import { IHelpDefinitions } from "shared/util/context/help/types/HelpTypes";

export type HelpPanelContextType = {
  isOpen: boolean;
  toggle: ({ detail }: { detail: AppLayoutProps.ChangeDetail }) => void;
};

export type HelpPanelContentContextType = {
  key: string;
  setHelpPanelContent: (newKey: string) => void;
  getHelpContent: () => ReactElement;
  setKey: React.Dispatch<React.SetStateAction<string>>;
};

const defaultHelpPanelState = {
  isOpen: false,
  toggle: () => {},
};
const defaultHelpPanelContentState = {
  key: "",
  getHelpContent: () => <></>,
  setHelpPanelContent: () => {},
  setKey: () => {},
};

export const HelpPanelContext = React.createContext<HelpPanelContextType>(
  defaultHelpPanelState
);

export const HelpPanelContentContext =
  React.createContext<HelpPanelContentContextType>(
    defaultHelpPanelContentState
  );

const HelpPanelProvider = ({
  children,
  definitions,
}: {
  children: ReactElement;
  definitions: IHelpDefinitions;
}) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const toggle = ({ detail }: { detail: AppLayoutProps.ChangeDetail }) =>
    setIsOpen(detail.open);

  const [key, setKey] = React.useState("");

  const setHelpPanelContent = (newKey: string) => {
    setIsOpen(true);
    setKey(newKey);
  };

  const getHelpContent = () => {
    const definition = definitions[key];
    if (!definition) return <></>;

    return (
      <HelpPanel header={definition.header} footer={definition?.footer}>
        {definition.content}
      </HelpPanel>
    );
  };
  return (
    <HelpPanelContext.Provider value={{ isOpen, toggle }}>
      <HelpPanelContentContext.Provider
        value={{ key, getHelpContent, setHelpPanelContent, setKey }}
      >
        {children}
      </HelpPanelContentContext.Provider>
    </HelpPanelContext.Provider>
  );
};

export default HelpPanelProvider;
