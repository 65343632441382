import React, { useEffect, useState } from "react";
import SideNavigation from "@amzn/awsui-components-react/polaris/side-navigation";
import { useHistory, useLocation } from "react-router-dom";
import { ROLES } from "../../../util/constants/roles";
import { getInternalRoles } from "../../../util/services/auth/AuthService";
import { isFeatureEnabled } from "../../../../shared/util/services/features/FeatureFlagsService";
import { FEATURE_FLAG_ENUMS } from "../../../../shared/util/constants/featureFlagValues";

export const getPages = (pages, roles) => {
  return pages?.reduce((filteredPages, page) => {
    if (page.type == "link") {
      // Default to true if no permissions are specified
      if (
        page.permission == undefined ||
        page.permission.some((r) => roles.includes(r))
      ) {
        filteredPages.push(page);
      }
    }
    if (page.type == "section") {
      let filteredSectionItems = getPages(page?.items, roles);
      if (
        Array.isArray(filteredSectionItems) &&
        filteredSectionItems.length > 0
      ) {
        filteredPages.push({
          ...page,
          items: filteredSectionItems,
        });
      }
    }
    return filteredPages;
  }, []);
};

const ApfpSideNavigation = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const roles = getInternalRoles();
  const [activePage, setActivePage] = useState("");
  const [selfServiceEnabled, setSelfServiceEnabled] = useState(false);
  const [bulkActionsEnabled, setBulkActionsEnabled] = useState(false);

  useEffect(() => {
    setActivePage(pathname);
  }, [pathname]);

  useEffect(() => {
    const featureFlag = async () => {
      const selfServiceFlag = await isFeatureEnabled(
        FEATURE_FLAG_ENUMS.SELF_SERVICE_INTERNAL
      );
      if (!ignore) {
        setSelfServiceEnabled(selfServiceFlag);
      }
    };

    let ignore = false;
    featureFlag();
    return () => {
      ignore = true;
    };
  }, []);

  useEffect(() => {
    const featureFlag = async () => {
      const bulkActionsFlag = await isFeatureEnabled(
        FEATURE_FLAG_ENUMS.BULK_ACTIONS
      );
      if (!ignore) {
        setBulkActionsEnabled(bulkActionsFlag);
      }
    };

    let ignore = false;
    featureFlag();
    return () => {
      ignore = true;
    };
  }, []);

  let manageSection = [];
  let manageSectionItems = [];
  if (selfServiceEnabled) {
    manageSectionItems.push({
      type: "link",
      text: "Self Service",
      href: "/self-service",
      permission: [ROLES.SELF_SERVICE_USER],
    });
  }

  if (bulkActionsEnabled) {
    manageSectionItems.push({
      type: "link",
      text: "Bulk Actions",
      href: "/bulk-actions",
      permission: [ROLES.FUNDING_OPS],
    });
  }

  if (manageSectionItems.length != 0) {
    manageSection.push({
      type: "section",
      text: "Manage",
      items: manageSectionItems,
    });
  }

  const PAGES = [
    { type: "link", text: "My Action Items", href: "/dashboard" },
    { type: "link", text: "Delegations", href: "/delegations" },
    {
      type: "link",
      text: "Audits",
      href: "/audits",
      permission: [
        ROLES.FUNDING_ADMIN,
        ROLES.TECH_AUDITOR,
        ROLES.CASH_CLAIM_AUDITOR,
      ],
    },
    ...manageSection,
  ];

  return (
    <React.Fragment>
      <SideNavigation
        activeHref={activePage}
        header={{ href: "/", text: "AWS Partner Funding Portal" }}
        onFollow={(event) => {
          if (!event.detail.external) {
            event.preventDefault();
            history.push(event.detail.href);
          }
        }}
        items={getPages(PAGES, roles)}
      />
    </React.Fragment>
  );
};

export default ApfpSideNavigation;
