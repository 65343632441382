import React, { useState } from "react";
import { SpaceBetween } from "@amzn/awsui-components-react";
import Button from "@amzn/awsui-components-react/polaris/button";
import PropTypes from "prop-types";
import Header from "@amzn/awsui-components-react/polaris/header";
import { useParams } from "react-router-dom";
import DeployModal from "./SelfServiceEntityTableHeader/Modal/DeployModal";
import { isEntityDeploymentVersionValid } from "./SelfServiceEntityTableHeader/Modal/util";

const SelfServiceEntityMainHeader = ({
  entityDetails,
  refresh,
  isValidEntity,
  entityEvents,
  currentEntityVersion,
  setNotifications,
}) => {
  const { entity } = useParams();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const updateModalVisibility = (visibility) => {
    setIsModalVisible(visibility);
  };

  return (
    <>
      <Header
        variant="h2"
        actions={
          <SpaceBetween size="s" direction="horizontal">
            <Button
              iconName="refresh"
              variant="normal"
              onClick={() => refresh()}
            />
            {isValidEntity && (
              <Button
                variant="primary"
                onClick={() => updateModalVisibility(true)}
                disabled={isEntityDeploymentVersionValid(
                  entityDetails.version,
                  currentEntityVersion
                )}
              >
                {"Deploy"}
              </Button>
            )}
          </SpaceBetween>
        }
      >
        {entity}
      </Header>
      <DeployModal
        refresh={refresh}
        isModalVisible={isModalVisible}
        updateModalVisibility={updateModalVisibility}
        entityDetails={entityDetails}
        entityEvents={entityEvents}
        currentEntityVersion={currentEntityVersion}
        setNotifications={setNotifications}
      ></DeployModal>
    </>
  );
};

SelfServiceEntityMainHeader.propTypes = {
  entityDetails: PropTypes.object,
  refresh: PropTypes.func,
  isValidEntity: PropTypes.bool,
  entityEvents: PropTypes.array,
  currentEntityVersion: PropTypes.number,
  setNotifications: PropTypes.func,
};

export default SelfServiceEntityMainHeader;
