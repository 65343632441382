import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import eligibility from "external/i18n/languages/en/eligibility";
import wizard from "external/i18n/languages/en/wizard";
import help from "external/i18n/languages/en/help";

let isInitialized = false;
let initializationError;
let i18nInitializationCallbacks = [];

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(
    {
      fallbackLng: "en",
      interpolation: {
        escapeValue: false,
      },
      resources: {
        en: {
          translation: {
            ...eligibility,
            ...wizard,
            ...help,
          },
        },
      },
    },
    (err) => {
      initializationError = err;
      isInitialized = true;
      i18nInitializationCallbacks.forEach((callback) => {
        callback(initializationError);
      });
      i18nInitializationCallbacks.length = 0;
    }
  );

const i18nWhenReady = (callback) => {
  if (isInitialized) {
    callback(initializationError);
  } else {
    i18nInitializationCallbacks.push(callback);
  }
};

export { i18nWhenReady };
export default i18n;
