import React from "react";
import {
  getSectionConstant,
  SECTION,
  SECTION_CONSTANT_TYPE,
  SECTION_NAME,
} from "../../util/constants/section";
import { uiToApiMap } from "../../util/adapter/toUi/adapter";
import { createValueField } from "./CreateValueField";
import { ColumnLayout, ExpandableSection } from "@amzn/awsui-components-react";
import PropTypes from "prop-types";
import { PROGRAM_TYPE } from "../../util/constants/programType";
import { COMPONENT_TYPE } from "../../util/constants/componentType";
import { PAGES } from "../../../internal/util/constants/pages";

export const ProjectInformationReviewSectionContainer = ({
  fundingData,
  index,
  step,
  template,
  valueFactory,
  schema,
  filterFields,
  header,
}) => {
  const fields = [];
  const sections = template[step].sections;

  const opportunityApiKey = uiToApiMap[SECTION_NAME.OPPORTUNITY_INFORMATION];
  if (
    fundingData &&
    fundingData[opportunityApiKey] &&
    fundingData[opportunityApiKey]["wwps"] &&
    !sections.includes(SECTION.PUBLIC_SECTOR_DETAILS)
  ) {
    sections.push(SECTION.PUBLIC_SECTOR_DETAILS);
  }

  for (let sectionTitle of sections) {
    const sectionName = getSectionConstant({
      type: SECTION_CONSTANT_TYPE.NAME,
      value: sectionTitle,
    });

    const filteredFields = filterFields({
      section: schema[sectionTitle],
      program: fundingData.program,
    });

    if (sectionTitle === SECTION.PROJECT_INFORMATION) {
      if (fundingData.program === PROGRAM_TYPE.SMP) {
        const frInfo = "fundingTemplate";
        const migrationPhaseField = {
          name: "migrationPhase",
          label: "Migration Phase",
          auto: true,
          component: COMPONENT_TYPE.SELECT,
          key: "migrationPhase",
          edit: [PAGES.AWS_APPROVAL],
          Required: [PROGRAM_TYPE.SMP],
        };

        fields.push(
          createValueField({
            field: migrationPhaseField,
            value: valueFactory({
              apiKey: frInfo,
              sectionTitle,
              name: migrationPhaseField.name,
              data: fundingData,
              field: migrationPhaseField,
            }),
          })
        );
      }

      if (
        [PROGRAM_TYPE.SMP, PROGRAM_TYPE.POC, PROGRAM_TYPE.PIF].includes(
          fundingData.program
        )
      ) {
        const arrField = {
          name: "annualRunRate",
          label: "Annual Run Rate",
          type: "money",
          auto: true,
          component: COMPONENT_TYPE.INPUT,
          edit: [PAGES.AWS_APPROVAL, PAGES.BUSINESS_APPROVAL],
          Required: [PROGRAM_TYPE.SMP, PROGRAM_TYPE.POC, PROGRAM_TYPE.PIF],
        };

        fields.push(
          createValueField({
            field: arrField,
            value: valueFactory({
              apiKey: "fundingTemplate",
              sectionTitle,
              name: arrField.name,
              data: fundingData,
              field: arrField,
            }),
          })
        );
      }
    }

    for (let field of filteredFields) {
      let apiKey = uiToApiMap[sectionName];
      if (field.name === "migrationPhase" || field.name === "annualRunRate") {
        apiKey = "fundingTemplate";
      }

      fields.push(
        createValueField({
          field,
          value: valueFactory({
            apiKey,
            sectionTitle,
            name: field.name,
            data: fundingData,
            field,
          }),
        })
      );
    }
  }

  return (
    <React.Fragment key={index}>
      <ExpandableSection
        variant="container"
        defaultExpanded={true}
        header={header}
      >
        <ColumnLayout columns={4} variant="text-grid">
          {fields}
        </ColumnLayout>
      </ExpandableSection>
      <br />
    </React.Fragment>
  );
};

ProjectInformationReviewSectionContainer.propTypes = {
  fundingData: PropTypes.object,
  index: PropTypes.number,
  step: PropTypes.string,
  template: PropTypes.object,
  valueFactory: PropTypes.func,
  schema: PropTypes.object,
  filterFields: PropTypes.func,
  header: PropTypes.element,
};
