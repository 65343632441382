import { dateRangeToMs } from "shared/util/common/helper";
import { ValidationError } from "shared/util/validation/error";
import {
  getISODate,
  getDateFromISO,
  isExtended,
} from "shared/util/common/helper";

export const notValidRangeInput = (dateRange) => {
  if (Number.isNaN(dateRange.amount))
    throw new ValidationError("Not a valid date input!");
};

export const negativeRangeInput = (dateRange) => {
  if (dateRange.type === "relative" && dateRange.amount <= 0) {
    throw new ValidationError("Not a valid date input!");
  }
};

export const dateRange90Days = (dateRange) => {
  const ms = dateRangeToMs(dateRange);
  if (ms > 7776000000) {
    throw new ValidationError(
      "The selected date range cannot be more than 90 days."
    );
  }
  return;
};

export const mustBeAfterNowWithAbsoluteRange = (dateRange) => {
  if (dateRange.type === "absolute") {
    const todaysDate = getISODate({ timestamp: Date.now() });
    if (todaysDate > getDateFromISO(dateRange.startDate)) {
      throw new ValidationError(
        `The selected start date must be after the current time.`
      );
    }
  }
};

export const mustBeAfterProjectStartDateWithAbsoluteRange = (
  fundRequest,
  dateRange
) => {
  if (dateRange.type === "absolute") {
    const validStartDate = getISODate(fundRequest.project.plannedStartDate);
    if (validStartDate > getDateFromISO(dateRange.startDate)) {
      throw new ValidationError(
        `The selected start date must be after the projects planned start date of ${validStartDate}.`
      );
    }
  }
};

export const activeExtensionWithAbsoluteRange = (fundRequest, dateRange) => {
  if (
    dateRange.type === "absolute" &&
    isExtended(fundRequest, { isActive: true })
  ) {
    const validStartDate = getISODate(
      fundRequest.projectExtension.extendedStartDate
    );
    if (validStartDate !== getDateFromISO(dateRange.startDate)) {
      throw new ValidationError(
        `The selected start date must be the previous extension start date of ${validStartDate}.`
      );
    }
  }
  return;
};
