import React from "react";
import MigrationProgramEngagementsTable from "internal/programs/migration-acceleration-program/2024/fund-request/components/details/sections/migration/MigrationProgramEngagementsTable";
import { IGenericObject } from "shared/programs/migration-acceleration-program/2024/fund-request/types/CommonTypes";
import MigrationProgramEngagementsDetails from "internal/programs/migration-acceleration-program/2024/fund-request/components/details/sections/migration/MigrationProgramEngagementsDetails";

const MigrationProgramEngagementsView = ({
  ...props
}: {
  data: IGenericObject;
  isLoading: boolean;
}) => {
  const { data } = props;
  const migrationProgramEngagements = data?.migrationProgramEngagements;
  if (
    !migrationProgramEngagements ||
    migrationProgramEngagements.length === 0
  ) {
    return <></>;
  }

  if (migrationProgramEngagements.length === 1) {
    return <MigrationProgramEngagementsDetails {...props} />;
  }

  return <MigrationProgramEngagementsTable {...props} />;
};

export default MigrationProgramEngagementsView;
