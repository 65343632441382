import {
  updateFundRequestInformation,
  updateProjectInformation,
  updateOpportunityInformation,
  updateCashRequestInformation,
  updateCreditRequestDetails,
  updateMigrationWorkloads,
  submitFundRequest,
  terminateFundRequest,
  recallFundRequest,
  updatePublicSectorDetails,
  updateCashClaim,
  submitCashClaim,
  attachFileToFundRequest,
  attachFileToCashClaim,
  detachFileFromFundRequest,
  detachFileFromCashClaim,
  deleteFile,
  getFundRequestAttachments,
  getUploadFileUrl,
  uploadFile,
  getDownloadUrl,
  deleteFundRequest,
} from "./FundRequestService";

import { SECTION } from "../../../../shared/util/constants/section";
import { EXTERNAL_REVIEW_ACTION_TYPE } from "../../constants/reviewActions";
import { FILE_EVENT_TYPE } from "../../constants/eventType";

export const DataSubmissionFactory = ({ title, submit }) => {
  switch (title) {
    case SECTION.FUND_REQUEST_INFORMATION:
      return updateFundRequestInformation;
    case SECTION.PROJECT_INFORMATION:
      return updateProjectInformation;
    case SECTION.OPPORTUNITY_INFORMATION:
      return updateOpportunityInformation;
    case SECTION.CASH_REQUEST_INFORMATION:
      return updateCashRequestInformation;
    case SECTION.CASH_CLAIM_ACTUALS: {
      if (submit) {
        return submitCashClaim;
      }
      return updateCashClaim;
    }
    case SECTION.CREDIT_REQUEST_DETAILS:
      return updateCreditRequestDetails;
    case SECTION.MIGRATION_WORKLOADS:
      return updateMigrationWorkloads;
    case SECTION.PUBLIC_SECTOR_DETAILS:
      return updatePublicSectorDetails;
    default:
      return submitFundRequest;
  }
};

const ChangeStatusFactory = (status) => {
  switch (status) {
    case EXTERNAL_REVIEW_ACTION_TYPE.DELETE:
      return deleteFundRequest;
    case EXTERNAL_REVIEW_ACTION_TYPE.TERMINATE:
      return terminateFundRequest;
    case EXTERNAL_REVIEW_ACTION_TYPE.RECALL:
      return recallFundRequest;
    default:
      return;
  }
};

export const changeStatus = (status) => {
  return ChangeStatusFactory(status);
};

export const AttachmentEventFactory = (eventType) => {
  switch (eventType) {
    case FILE_EVENT_TYPE.ATTACH_TO_FUND_REQUEST:
      return attachFileToFundRequest;
    case FILE_EVENT_TYPE.ATTACH_TO_CASH_CLAIM:
      return attachFileToCashClaim;
    case FILE_EVENT_TYPE.DETACH_FROM_FUND_REQUEST:
      return detachFileFromFundRequest;
    case FILE_EVENT_TYPE.DETACH_FROM_CASH_CLAIM:
      return detachFileFromCashClaim;
    default:
      return;
  }
};

export const FileUploadTriggers = {
  getUploadFileUrl: getUploadFileUrl,
  uploadFile: uploadFile,
  getDownloadUrl: getDownloadUrl,
  deleteFile: deleteFile,
  getListOfAttachments: getFundRequestAttachments,
  attachmentEventFactory: AttachmentEventFactory,
};
