import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  filterFields,
  getInternalTemplate,
  schema,
} from "../../../config/schema";
import Loading from "../../../../shared/components/common/Loading";
import { Header } from "@amzn/awsui-components-react";
import Section from "../../../../shared/components/FundRequest/StepsContainer/Section";
import { InputPropsBuilder } from "../../../../shared/components/FundRequest/InputPropsBuilder";
import { InputFactory } from "../../../../shared/components/FundRequest/InputFactory";
import { DisableFactory } from "./DisableFactory";
import { ValueFactory } from "./ValueFactory";
import { useOptionsContext } from "../../../util/context/OptionsContext";
import useFields from "../../../../shared/util/hooks/useFields";
import { getFundRequest } from "../../../util/services/data/InternalDataService";
import { FileUploadTriggers } from "../../../util/services/data/dataFactory";
import { smpMapLite } from "../../../../shared/util/constants/smpMapLite";
import { isFeatureEnabled } from "../../../../shared/util/services/features/FeatureFlagsService";
import { FEATURE_FLAG_ENUMS } from "../../../../shared/util/constants/featureFlagValues";

const DefaultEditSectionContainer = ({
  data,
  errors,
  dataInput,
  dataLoad,
  fieldToIdMap,
  updateErrors,
  setFieldToIdMap,
  stepTitle,
  validators,
  setNotificationItems,
  page,
  showHelperPanel,
}) => {
  const [options] = useOptionsContext();
  const stepTemplate = schema[stepTitle];

  const [{ fieldTemplate, isLoading }] = useFields({
    data,
    stepTemplate,
    fieldToIdMap,
    updateFieldMap: setFieldToIdMap,
    dataLoad,
    dataInput,
    getTemplate: getInternalTemplate,
    filterFields,
    schema,
    getFundRequest,
  });

  if (smpMapLite(data) && Object.keys(fieldTemplate).length !== 0) {
    fieldTemplate.fundRequestInformation.fields.forEach((field) => {
      if (field.name === "awsCalculatorUrl") {
        field.required = false;
      }
    });
  }

  const [attachmentFeatureFlag, setAttachmentFeatureFlag] = useState(false);

  useEffect(async () => {
    const attachmentFlag = await isFeatureEnabled(
      FEATURE_FLAG_ENUMS.CASH_CLAIM_ATTACHMENT_INTERNAL
    );
    setAttachmentFeatureFlag(attachmentFlag);
  });

  const section = isLoading ? (
    <Loading />
  ) : (
    <Section header={<Header variant="h2">{stepTitle}</Header>}>
      <React.Fragment>
        {fieldTemplate &&
          fieldTemplate[stepTemplate.name] &&
          fieldTemplate[stepTemplate.name].fields.map((field, index) => {
            const inputProps = InputPropsBuilder({
              data,
              errors,
              field,
              options,
              fieldToIdMap,
              page,
              sectionName: stepTemplate.name,
              disableFactory: DisableFactory,
              valueFactory: ValueFactory,
            });
            FileUploadTriggers.deleteFile = attachmentFeatureFlag
              ? FileUploadTriggers.deleteFile
              : null;

            return (
              <InputFactory
                key={index}
                dataInput={dataInput}
                fieldToIdMap={fieldToIdMap}
                updateErrors={updateErrors}
                validators={validators}
                setNotificationItems={setNotificationItems}
                showHelperPanel={showHelperPanel}
                fileuploadProp={FileUploadTriggers}
                {...inputProps}
              />
            );
          })}
      </React.Fragment>
    </Section>
  );
  return <React.Fragment>{section}</React.Fragment>;
};

DefaultEditSectionContainer.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object,
  dataInput: PropTypes.func,
  dataLoad: PropTypes.func,
  fieldToIdMap: PropTypes.object,
  updateErrors: PropTypes.func,
  setTemplate: PropTypes.func,
  validators: PropTypes.any,
  setFieldToIdMap: PropTypes.func,
  stepTitle: PropTypes.string,
  setNotificationItems: PropTypes.func,
  page: PropTypes.string,
  showHelperPanel: PropTypes.func,
};

export default React.memo(DefaultEditSectionContainer);
