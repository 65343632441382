import { FileUploadProps } from "@amzn/awsui-components-react";

export const MAX_FILE_SIZE_IN_MB = 10;
export const MAX_FILE_SIZE_IN_BYTES = MAX_FILE_SIZE_IN_MB * 1024 * 1024;
export const EXPIRY_TIME_INTERVAL_IN_MILLI_SECONDS = 1000000000000; // Equal to about 3 decades
export const ACCEPTED_FILE_TYPES = ".pdf,.doc,.docx,.xlsx,.pptx,.csv";
export const GENERIC_ERROR_MESSAGE =
  "A server error has occurred. Choose your selection again.";
export const UPLOAD_ATTACHMENT_ERROR_MESSAGE =
  "Failed to upload the attachment, please try again.";

export const fileUploadI18nStrings: FileUploadProps.I18nStrings = {
  uploadButtonText: (multiple: boolean) =>
    multiple ? "Choose files" : "Choose file",
  dropzoneText: (multiple: boolean) =>
    multiple ? "Drop files to upload" : "Drop file to upload",
  removeFileAriaLabel: (fileIndex: number) => `Remove file ${fileIndex + 1}`,
  limitShowFewer: "Show fewer files",
  limitShowMore: "Show more files",
  errorIconAriaLabel: "Error",
};

export const ADDITIONAL_ARTIFACTS_TYPES_MAP: {
  label: string;
  value: string;
}[] = [
  {
    label: "Project Plan/Checklist",
    value: "Project Plan/Checklist",
  },
  {
    label: "Innovation Plan",
    value: "Innovation Plan",
  },
  {
    label: "Marketing Plan",
    value: "Marketing Plan",
  },
  {
    label: "Proof of Cost",
    value: "Proof of Cost",
  },
  {
    label: "Assess Analysis Report",
    value: "Assess Analysis Report",
  },
  {
    label: "Well Architected Review Report",
    value: "Well Architected Review Report",
  },
  {
    label: "Success Criteria Report",
    value: "Success Criteria Report",
  },
  {
    label: "Customer Sign Off Template",
    value: "Customer Sign Off Template",
  },
  {
    label: "Partner Sign Off Template",
    value: "Partner Sign Off Template",
  },
  {
    label: "Customer Satisfaction Survey (CSAT)",
    value: "Customer Satisfaction Survey (CSAT)",
  },
  {
    label: "Migration Capability Review",
    value: "Migration Capability Review",
  },
  { label: "Other", value: "Other" },
];
