export const formatMigrationARR = (migrationARR) => {
  return migrationARR
    ? `${parseFloat(migrationARR.units).toLocaleString("en-US")} ${
        migrationARR.currency
      }`
    : "-";
};

export const mpeAttributes = {
  recordStatus: "Record Status",
  engagementStatus: "Engagement Status",
  migrationArr: "Migration ARR",
  consultingPartnerEngagement: "Consulting Partner Engagement",
  agreementCategory: "Agreement Category",
  privateEquityBacked: "Private Equity Backed",
  engagementStatusWonStartDate: "Engagement Status Won Start Date",
  workshopCompletionDate: "Workshop Completion Date",
  readinessAssessmentStatus: "Readiness Assessment Status",
  deliveredBy: "Delivered By",
  readoutPresentationDate: "Readout Presentation Date",
  mraMethdology: "MRA Methdology",
  olaCompletionDate: "Ola Completion Date",
  confirmed: "OLA Confirmed",
  olaStatus: "Ola Status",
};
