export const filterItem = ({ searchableColumns, item, filteringText }) => {
  return searchableColumns
    .map((key) => item[key])
    .some(
      (value) =>
        typeof value === "string" &&
        value.toLowerCase().indexOf(filteringText.toLowerCase()) > -1
    );
};

export const formatReasonCodes = (reasonCodes) => {
  return reasonCodes ? reasonCodes.join("; ") : "-";
};

export const getCashClaimCompletedStatus = (cashClaimCompletedStatus) => {
  if (cashClaimCompletedStatus !== null) {
    return cashClaimCompletedStatus ? "Yes" : "No";
  }
  return "-";
};
