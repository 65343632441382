export const matchTemplateItemsWithEligibility = (
  template_items,
  eligible_programs
) => {
  return template_items.map((template) => {
    return {
      ...template,
      items: template.items.map((item) => {
        if (eligible_programs.includes(item.id)) {
          item.disabled = false;
        }
        return item;
      }),
    };
  });
};
