import React from "react";

import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import ConditionalField from "shared/components/common/Conditional/ConditionalField";
import { IGenericObject } from "shared/programs/migration-acceleration-program/2024/fund-request/types/CommonTypes";
import ClaimApproveAction from "internal/programs/migration-acceleration-program/2024/fund-request/components/details/header/claims/ClaimApproveAction";
import ClaimRejectAction from "internal/programs/migration-acceleration-program/2024/fund-request/components/details/header/claims/ClaimRejectAction";
import { Button } from "@amzn/awsui-components-react";
import {
  CASH_CLAIM_STAGE,
  CASH_CLAIM_STATUS,
} from "shared/util/constants/fundRequestStatusType";
import { useHistory } from "react-router-dom";

export interface CashClaimActionsHeaderProps {
  data: IGenericObject;
  claim: IGenericObject;
  loading: boolean;
}

const CashClaimActionsHeader = ({
  data,
  claim,
  loading,
}: CashClaimActionsHeaderProps) => {
  const history = useHistory();
  if (!data || !claim) return <></>;

  const frid = data.fundRequestId;
  const claimId = claim.claimId;
  const isClaimActive = claim.claimStatus === CASH_CLAIM_STATUS.ACTIVE;

  return (
    <>
      <SpaceBetween size="l" direction="horizontal">
        <ConditionalField
          showField={
            isClaimActive &&
            claim.claimStage === CASH_CLAIM_STAGE.CLAIM_APPROVAL
          }
        >
          <ClaimApproveAction claimId={claimId} loading={loading} />
        </ConditionalField>
        <ConditionalField
          showField={
            isClaimActive &&
            claim.claimStage === CASH_CLAIM_STAGE.CLAIM_APPROVAL
          }
        >
          <ClaimRejectAction claimId={claimId} loading={loading} data={data} />
        </ConditionalField>
        <ConditionalField
          showField={
            isClaimActive && claim.claimStage === CASH_CLAIM_STAGE.INVOICE
          }
        >
          <Button
            variant="primary"
            onClick={() =>
              history.push(`/map2024/${frid}/${claimId}/claim-invoice/edit`)
            }
          >
            Submit invoice
          </Button>
        </ConditionalField>
      </SpaceBetween>
    </>
  );
};

export default CashClaimActionsHeader;
