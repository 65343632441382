import { Modal, Select, SpaceBetween } from "@amzn/awsui-components-react";
import React, { useState } from "react";
import PropTypes from "prop-types";
import Upload from "./Upload";
import Submit from "./Submit";
import { useQueries } from "@tanstack/react-query";
import {
  constructBulkActionsFFQueries,
  parseBulkActionValuesFromQueryResults,
} from "../../config";

const NewBulkAction = ({
  setNotificationsItems,
  isNewBulkActionVisible,
  setNewBulkActionVisible,
}) => {
  // State for selection option modal
  const [selectedBulkAction, setSelectedBulkAction] = useState(null);
  // Polaris upload component treats files as an array even if only single file upload is allowed
  const [files, setFiles] = useState([]);
  const [fileTypeErrorText, setFileTypeErrorText] = useState();

  // Fetching bulk action types based on feature flags
  const queryResults = useQueries({
    queries: constructBulkActionsFFQueries(),
  });
  const isError = queryResults.some((q) => q.isError);

  if (isError) {
    for (const q of queryResults) {
      if (q.isError) {
        console.error(q.error);
      }
    }
  }
  const bulkActionTypes = Object.values(
    parseBulkActionValuesFromQueryResults(queryResults)
  );

  const resetNewBulkAction = () => {
    setNewBulkActionVisible(false);
    setSelectedBulkAction(null);
    setFileTypeErrorText();
    setFiles([]);
  };

  return (
    <Modal
      header={"New Bulk Action"}
      onDismiss={resetNewBulkAction}
      visible={isNewBulkActionVisible}
      closeAriaLabel="Close modal"
      size="medium"
      footer={
        <Submit
          file={files[0]}
          resetNewBulkAction={resetNewBulkAction}
          isDisabled={selectedBulkAction === null || files.length != 1}
          selectedBulkAction={selectedBulkAction}
          setNotificationsItems={setNotificationsItems}
        />
      }
    >
      <SpaceBetween size="s">
        <Select
          selectedOption={selectedBulkAction}
          placeholder="Select a Bulk Action"
          onChange={({ detail }) =>
            setSelectedBulkAction(detail.selectedOption)
          }
          options={bulkActionTypes}
        />
        <Upload
          files={files}
          setFiles={setFiles}
          fileTypeErrorText={fileTypeErrorText}
          setFileTypeErrorText={setFileTypeErrorText}
        />
      </SpaceBetween>
    </Modal>
  );
};

NewBulkAction.propTypes = {
  setNotificationsItems: PropTypes.func,
  isNewBulkActionVisible: PropTypes.bool,
  setNewBulkActionVisible: PropTypes.func,
};

export default NewBulkAction;
