import React, { useState } from "react";

import { getBreadcrumbs } from "../../../shared/util/common/getBreadcrumbs";
import withAppLayout from "../../../shared/components/withAppLayout";
import SideNavigation from "../../components/common/SideNavigation";
import SelfServiceTable from "../../components/SelfService/MainPage";

const SelfServicePage = () => {
  const [notifications, setNotifications] = useState([]);

  return withAppLayout({
    component: (
      <React.Fragment>
        <SelfServiceTable setNotifications={setNotifications} />
      </React.Fragment>
    ),
    breadcrumbs: getBreadcrumbs({ text: "Self Service" }),
    navigationHide: false,
    navigation: <SideNavigation />,
    toolsHide: true,
    notificationsItems: notifications,
  })();
};

export default SelfServicePage;
