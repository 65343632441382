import { AWS_ACCOUNT_ID_DEFAULT } from "internal/util/constants/constants";
import { removeCurrencyFormatting } from "shared/programs/migration-acceleration-program/2024/fund-request/util/currency";

export enum ValidationType {
  MaxLength100Chars = "validateLength100Chars",
  MaxLength300Chars = "validateLength300Chars",
  MaxLength5000Chars = "validateLength5000Chars",
  AwsAccountId = "validateAwsAccountId",
  ValidNumber = "validateNumber",
}

const validateLength100Chars = (value: string) => {
  if (value.length > 100) {
    return "Provided input should be less than or equal to 100 characters.";
  }
};

const validateLength300Chars = (value: string) => {
  if (value.length > 300) {
    return "Provided input should be less than or equal to 300 characters.";
  }
};

const validateLength5000Chars = (value: string) => {
  if (value.length > 5000) {
    return "Provided input should be less than or equal to 5,000 characters.";
  }
};

const validateAwsAccountId = (value: string) => {
  const numbersRegex = /^\d*$/;
  if (value && (value.length > 12 || !numbersRegex.test(value))) {
    return "Please provide a valid AWS Account Id.";
  }
};

const validateNumber = (value: string) => {
  const formattedValue = removeCurrencyFormatting(value);
  const numbersRegex = /^\d*$/;
  if (formattedValue && !numbersRegex.test(formattedValue)) {
    return "Please provide a valid number.";
  }
};

const validations: {
  [key in ValidationType]: (value: string) => string | undefined;
} = {
  [ValidationType.MaxLength100Chars]: validateLength100Chars,
  [ValidationType.MaxLength300Chars]: validateLength300Chars,
  [ValidationType.MaxLength5000Chars]: validateLength5000Chars,
  [ValidationType.AwsAccountId]: validateAwsAccountId,
  [ValidationType.ValidNumber]: validateNumber,
};

export const getValidations = (
  providedValidations: ValidationType[]
): ((value: string) => string | undefined)[] => {
  return providedValidations.map(
    (validation: ValidationType) => validations[validation]
  );
};
