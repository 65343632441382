import { STAGE, STATUS } from "shared/util/constants/fundRequestStatusType";

export const showSubmit = (data) => {
  const isDraft = data?.status === STATUS.DRAFT;
  const isActive = data?.status === STATUS.ACTIVE;
  const isInPartnerStage = data?.stage === STAGE.PARTNER;
  return (isDraft || isActive) && isInPartnerStage;
};

export const showRecall = (data) => {
  const isActive = data?.status === STATUS.ACTIVE;
  const recallEligibleStages = [
    STAGE.BUSINESS_APPROVAL,
    STAGE.FINANCE_APPROVAL,
  ];
  return isActive && recallEligibleStages.includes(data?.stage);
};

export const showTerminate = (data) => {
  const isActive = data?.status === STATUS.ACTIVE;
  const isSubmitted = data?.status === STATUS.SUBMITTED;
  return isActive || isSubmitted;
};

export const showExtend = (data) =>
  (data?.stage === STAGE.FINANCE_APPROVAL ||
    data?.stage === STAGE.CASH_CLAIM) &&
  (data?.status === STATUS.ACTIVE || data?.status === STATUS.SUBMITTED) &&
  !(
    data["projectDetails"]["extendedStartDate"] ||
    data["projectDetails"]["extendedEndDate"]
  );
