import { PROGRAM_TYPE } from "../constants/programType";

const MAPshort = "MAP";
const MAPLong = "Migration Acceleration Program";
const SMPLong = "Simplified Migration Program";
const MAPLE = "MAPLE";

export const convertSMPtoMAP = (name) => {
  if (name === PROGRAM_TYPE.SMP) {
    return MAPshort;
  }
  if (name === SMPLong) {
    return MAPLong;
  }

  if (name === MAPLE) {
    return MAPLong;
  }

  return name;
};

export const convertMAPtoSMP = (name) => {
  if (name === MAPshort) {
    return PROGRAM_TYPE.SMP;
  }
  if (name === SMPLong) {
    return MAPLong;
  }
  return name;
};
