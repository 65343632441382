import { useCollection } from "@amzn/awsui-collection-hooks";
import {
  ProgressBar,
  Box,
  Grid,
  Pagination,
  PropertyFilter,
  Table,
} from "@amzn/awsui-components-react/polaris";
import { nanoid } from "nanoid";
import Header from "internal/components/Dashboard/ApprovalList/Header";
import {
  FILTERING_PROPERTIES,
  PROPERTY_FILTERING_I18N_CONSTANTS,
  newApprovalsColumnDefinition,
  visibleColumns,
} from "internal/components/Dashboard/ApprovalList/config";
import {
  getFundingTypeFromFrId,
  getSelectedType,
  calculateDwellTime,
  matchesCountText,
} from "internal/components/Dashboard/ApprovalList/util";
import TableEmptyState from "internal/components/common/TableEmptyState/TableEmptyState";
import TableNoMatchState from "internal/components/common/TableNoMatchState/TableNoMatchState";
import { getFundRequestListing } from "internal/util/services/data/InternalDataService";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { dateComparator, getDate } from "shared/util/common/helper";
import { STAGE, STATUS } from "shared/util/constants/fundRequestStatusType";
import { useQueries, useQueryClient } from "@tanstack/react-query";
import LoadingTable from "shared/components/common/TableLoading";
import { cartesianProduct } from "shared/util/common/util";
import { FormField } from "@amzn/awsui-components-react";
import Select from "@amzn/awsui-components-react/polaris/select";
import ButtonDropdown from "@amzn/awsui-components-react/polaris/button-dropdown";
import useFilterSets from "shared/util/hooks/useFilterSets/useFilterSets";
import { useUserSettingsContext } from "shared/components/UserPersistedSettings/Context";
import { submitSavedFilterSets } from "shared/util/hooks/useFilterSets/Api";
import {
  EntityTypes,
  UserPersistedSettingsV1ComponentAttribute,
  UserPersistedSettingsV1ComponentName,
  UserPersistedSettingsVersions,
} from "shared/components/UserPersistedSettings/Constants";
import { convertSMPtoMAP } from "shared/util/common/convertSMPtoMAP";

export const NewFilterTable = ({
  pageSize,
  assignees,
  setNotificationsItems,
}) => {
  const [selectedType, setSelectedType] = useState("");
  const [apiQuery, setApiQuery] = useState(new Set([STATUS.ACTIVE]));
  const { userSettingsState, userSettingsDispatch } = useUserSettingsContext();

  // TODO: refactor to react-query pagination InfiniteQuery
  const getAllApprovalList = async (user, status) => {
    let nextPageToken = "";
    let tableFrs = [];
    while (nextPageToken != null) {
      try {
        const response = await getFundRequestListing({
          assignedTo: user,
          status: status,
          nextPageToken: nextPageToken,
        });
        const frs = response.assignedRequests;
        const newTableFrs = frs.map((fr) => ({
          fundRequestId: fr.fundRequestId,
          template: convertSMPtoMAP(fr.program),
          program: convertSMPtoMAP(fr.subProgram),
          activityName: fr.activityName,
          partnerName: fr.partnerName,
          assignedTo: fr.assignedTo,
          stage: fr.stage,
          dwellTime: calculateDwellTime(fr.stageTransitionOn),
          status: fr.status,
          opportunityId: fr.opportunityId,
          approvedCashAmount: fr.approvedCashAmount || "-",
          approvedCreditAmount: fr.approvedCreditAmount || "-",
          projectGeo: fr.locationGeo,
          partnerType: fr.partnerType,
          lastUpdatedOn: getDate(fr.lastUpdatedOn),
          createdOn: getDate(fr.createdOn),
          partnerLastInteractionOn: getDate(fr.partnerLastInteractionOn),
          eligibleForPsaAutoApproval: fr.eligibleForPsaAutoApproval,
          poNumber: fr.poNumber || "-",
          publicSector: fr.sector === "WWPS" || false,
          cashClaimStages: fr.cashClaimStages || [],
          strategicCollaborationAgreement:
            fr.strategicCollaborationAgreement === true || false,
        }));
        tableFrs = [...tableFrs, ...newTableFrs];
        nextPageToken = response.nextPageToken;
      } catch {
        throw new Error(`Failed to get FRs for ${user}`);
      }
    }
    return tableFrs;
  };

  const usersAndNoStatus = assignees.map((a) => [a, null]);
  const usersAndStatus = cartesianProduct(assignees, [...apiQuery]);
  if (usersAndStatus.length === 0) {
    usersAndStatus.push(...usersAndNoStatus);
  }
  const queryClient = useQueryClient();
  const queryResults = useQueries({
    queries: usersAndStatus.map((u) => {
      const [user, status] = u;
      return {
        queryKey: ["assignedTo", user, status],
        queryFn: () => getAllApprovalList(user, status),
        refetchInterval: 5000,
      };
    }),
  });

  // NOTE: rather than wait for all response to finish we eagerly load the table
  // any new 200 response trigger an update to the table. This is why we use
  // .every(...)
  const isLoading = queryResults.every((q) => q.isLoading);
  const isError = queryResults.some((q) => q.isError);
  const assignedFrs = queryResults.flatMap((q) => q?.data || []);
  const processed = queryResults.reduce((acc, c) => {
    return c.isSuccess ? acc + 1 : acc;
  }, 0);
  const progress = (processed / queryResults.length) * 100;
  const isAllDone = queryResults.every((q) => q.isSuccess);

  useEffect(() => {
    if (isError) {
      setNotificationsItems([
        {
          type: "error",
          dismissible: true,
          dismissLabel: "Dismiss message",
          onDismiss: () => setNotificationsItems([]),
          header: "Error",
          content: "Failed to load assigned fund requests",
          id: nanoid(10),
        },
      ]);
    }
  }, [isError]);

  const {
    items,
    actions,
    filteredItemsCount,
    collectionProps,
    propertyFilterProps,
    paginationProps,
  } = useCollection(assignedFrs, {
    pagination: { pageSize },
    empty: <TableEmptyState resourceName="resources" />,
    propertyFiltering: {
      defaultQuery: {
        tokens: [
          {
            value: [STATUS.ACTIVE],
            propertyKey: "status",
            operator: "=",
          },
        ],
        operation: "and",
      },
      filteringProperties: FILTERING_PROPERTIES,
      noMatch: (
        <TableNoMatchState
          onClearFilter={() => {
            actions.setPropertyFiltering({ tokens: [], operation: "and" });
          }}
        />
      ),
    },
    sorting: {
      defaultState: {
        sortingColumn: {
          sortingField: "lastUpdatedOn",
          sortingComparator: (e1, e2) =>
            dateComparator(e1.lastUpdatedOn, e2.lastUpdatedOn),
        },
        isDescending: true,
      },
    },
    selection: {
      keepSelection: true,
      trackBy: (item) => item.fundRequestId,
    },
  });

  const { selectedItems } = collectionProps;

  const { selectProps, buttonDropdownProps, actionModal } = useFilterSets({
    filterSets: userSettingsState.v1_NewFilterTable_SavedFilterSets,
    query: propertyFilterProps.query,
    filteringProperties: propertyFilterProps.filteringProperties,
    updateFilters: (query) => {
      actions.setPropertyFiltering(query);
    },
    updateSavedFilterSets: (filterSetAction, newFilterSets) => {
      return submitSavedFilterSets({
        version: UserPersistedSettingsVersions.V1,
        componentName: UserPersistedSettingsV1ComponentName.NEW_FILTER_TABLE,
        componentAttribute:
          UserPersistedSettingsV1ComponentAttribute.SAVED_FILTER_SETS,
        entityType: EntityTypes.TABLE_SETTINGS,
        filterSetAction,
        newFilterSets,
        userSettingsDispatch,
        setNotificationsItems,
      });
    },
  });

  if (isLoading) return <LoadingTable loadingText="Loading..." />;

  if (isError) return <TableEmptyState resourceName="resources" />;

  const matchesSelectedType = ({ fundingType, stage }) => {
    return (
      selectedType === "" ||
      getSelectedType({ fundingType, stage }) === selectedType
    );
  };

  const tableFilter = () => (
    <Grid gridDefinition={[{ colspan: 4 }]}>
      <PropertyFilter
        virtualScroll={assignedFrs.length > 500 ? true : false}
        {...propertyFilterProps}
        asyncProperties
        i18nStrings={PROPERTY_FILTERING_I18N_CONSTANTS}
        countText={matchesCountText(filteredItemsCount)}
        onChange={(ev) => {
          const statusQueries = ev.detail.tokens
            .filter((t) => t.propertyKey === "status")
            .flatMap((f) => f.value);
          setApiQuery(new Set(statusQueries));
          propertyFilterProps.onChange(ev);
        }}
        filteringStatusType={isAllDone ? "finished" : "loading"}
        filteringLoadingText={"Loading Queue..."}
        {...(userSettingsState.isFeatureFlagEnabled && {
          customControl: (
            <FormField label="Saved filter sets">
              {<Select {...selectProps} />}
            </FormField>
          ),
        })}
        {...(userSettingsState.isFeatureFlagEnabled && {
          customFilterActions: <ButtonDropdown {...buttonDropdownProps} />,
        })}
      ></PropertyFilter>
    </Grid>
  );

  const tableHeader = () => (
    <Header
      counter={
        <Box>
          <ProgressBar
            additionalInfo={isAllDone ? "" : "Loading Queue..."}
            value={isAllDone ? 100 : progress}
          />
        </Box>
      }
      refresh={() =>
        assignees.forEach((user) =>
          queryClient.invalidateQueries({
            queryKey: ["assignedTo", user],
          })
        )
      }
      disabled={isLoading || !selectedItems.length > 0}
      setNotificationsItems={setNotificationsItems}
      selectedItems={selectedItems}
      selectedType={selectedType}
    />
  );

  return (
    <>
      <Table
        {...collectionProps}
        loading={isLoading}
        loadingText="Loading pending approvals..."
        selectionType="multi"
        items={items}
        isItemDisabled={({ fundRequestId, stage }) =>
          stage === STAGE.COMPLETED ||
          !matchesSelectedType({
            fundingType: getFundingTypeFromFrId(fundRequestId),
            stage,
          })
        }
        columnDefinitions={newApprovalsColumnDefinition}
        visibleColumns={visibleColumns}
        filter={tableFilter()}
        header={tableHeader()}
        onSelectionChange={({ detail }) => {
          let newSelectedType = "";

          if (detail.selectedItems.length > 0) {
            const { fundRequestId, stage } = detail.selectedItems[0];
            const fundingType = getFundingTypeFromFrId(fundRequestId);
            newSelectedType = getSelectedType({ fundingType, stage });
          }

          setSelectedType(newSelectedType);
          actions.setSelectedItems(detail.selectedItems);
        }}
        selectedItems={selectedItems}
        pagination={<Pagination {...paginationProps} />}
      />
      {userSettingsState.isFeatureFlagEnabled && actionModal}
    </>
  );
};

NewFilterTable.propTypes = {
  pageSize: PropTypes.number,
  assignees: PropTypes.arrayOf(PropTypes.string),
  setNotificationsItems: PropTypes.func,
};

export default NewFilterTable;
