import React, { useEffect, useState } from "react";
import Container from "@amzn/awsui-components-react/polaris/container";
import { Header } from "@amzn/awsui-components-react";
import { FileUploadComponent } from "shared/programs/migration-acceleration-program/2024/fund-request/components/attachments/FileUploadComponent";
import {
  ADDITIONAL_ARTIFACTS_TYPES_MAP,
  MAX_FILE_SIZE_IN_MB,
} from "external/programs/migration-acceleration-program/2024/fund-request/components/create/wizard/Steps/Artifacts/util";
import Select from "@amzn/awsui-components-react/polaris/select";
import { getArtifacts } from "shared/programs/migration-acceleration-program/2024/fund-request/components/attachments/util";
import { IArtifactFileModel } from "shared/programs/migration-acceleration-program/2024/fund-request/components/attachments/types/fileTypes";
import { IGenericObject } from "shared/programs/migration-acceleration-program/2024/fund-request/types/CommonTypes";
import { useTranslation } from "react-i18next";

const DEFAULT_FUND_REQUEST_EXCLUDED_DOCUMENTS: string[] = [
  "Migration Assessment Scope Checklist",
  "AWS Pricing Calculator",
  "Migration Assessment Report",
];

const AdditionalArtifactsSection = ({
  claimId,
  data,
  attach,
  excludedDocumentTypes,
  description,
}: {
  claimId?: string;
  data: IGenericObject;
  attach?: ({
    body,
    fundRequestId,
  }: {
    body: unknown;
    fundRequestId: unknown;
  }) => Promise<IGenericObject>;
  excludedDocumentTypes?: Array<string>;
  description?: string;
}) => {
  const [files, setFiles] = useState<File[]>([]);
  const [fileIdToAttributes, setFileIdToAttributes] =
    useState<IArtifactFileModel>({});
  const [supportingDocumentType, setSupportingDocumentType] = React.useState<
    string | undefined
  >(undefined);

  const { t } = useTranslation();

  useEffect(() => {
    const documents = data && data["documents"];
    if (!documents) return;
    const artifacts = getArtifacts({
      documents,
      filter: (actualType: string) => {
        const excludedTypes =
          excludedDocumentTypes || DEFAULT_FUND_REQUEST_EXCLUDED_DOCUMENTS;
        return !excludedTypes.includes(actualType);
      },
    });
    setFiles(artifacts.files || []);
    setFileIdToAttributes(artifacts.fileIdToAttributes);
  }, [data]);

  return (
    <Container
      header={
        <Header
          variant="h2"
          description={
            description ||
            t(
              "wizard.section.supportingDocumentation.content.additionalDocumentation.header.description"
            )
          }
        >
          {t(
            "wizard.section.supportingDocumentation.content.additionalDocumentation.header.title"
          )}{" "}
          - <i>optional</i>
        </Header>
      }
    >
      <FileUploadComponent
        artifacts={{ files, fileIdToAttributes }}
        updateFiles={setFiles}
        setFileIdToAttributes={setFileIdToAttributes}
        label={t(
          "wizard.section.supportingDocumentation.content.additionalDocumentation.fields.upload.label"
        )}
        supportingDocumentType={supportingDocumentType}
        constraintText={`File size limit: ${MAX_FILE_SIZE_IN_MB} MB`}
        claimId={claimId}
        attach={attach}
      >
        <Select
          selectedOption={getSelectedOption(supportingDocumentType)}
          onChange={({ detail }) => {
            setSupportingDocumentType(detail.selectedOption.value);
          }}
          options={ADDITIONAL_ARTIFACTS_TYPES_MAP}
          placeholder="Choose document type"
          selectedAriaLabel="Selected"
        />
      </FileUploadComponent>
    </Container>
  );
};

const getSelectedOption = (supportingDocumentType: string | undefined) => {
  if (!supportingDocumentType) return null;

  return {
    label: supportingDocumentType,
    value: supportingDocumentType,
  };
};

export default AdditionalArtifactsSection;
