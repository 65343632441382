import React from "react";
import Link from "@amzn/awsui-components-react/polaris/link";

export const setErrorMessage = ({
  setNotificationsItems,
  header,
  content,
  status,
}) => {
  let messages =
    document.location.host.includes("awspartner.com") ||
    document.location.port == 3000 ? (
      <span>
        An error has occurred, please create a ticket with the{" "}
        <Link href="https://partnercentral.awspartner.com/apex/PartnerPortalContactUs">
          APN support team
        </Link>{" "}
        or reach out to your AWS Partner Manager.
      </span>
    ) : (
      <span>
        An error has occurred, please open a{" "}
        <Link href="https://t.corp.amazon.com/create/options?ticket_type=Regular+Ticket&amp;severity=FIVE&amp;category=AWS+Partner+Central&amp;type=Partner+Management&amp;item=APFP&amp;building_id=YVR22&amp;group=Partner+Management&amp;assignee=partner-management-primary&amp;asin=&amp;quantity=&amp;isd=&amp;upc=&amp;binding=&amp;vendor_id=&amp;purchase_order_id=&amp;stock_number=&amp;ship_origin=&amp;invoice_number=&amp;physical_location=&amp;tracking_number=&amp;bol_number=&amp;picture_file_imdex_location=&amp;ampvendor_info_title=">
          SIM
        </Link>{" "}
        with Partner System team for assistance.
      </span>
    );

  if (content && status !== 406) {
    messages = content;
  }

  const notification_contents = [];
  if (Array.isArray(messages)) {
    messages.forEach((message) => {
      notification_contents.push(
        generateNotificationItems(header, message, setNotificationsItems)
      );
    });
  } else {
    notification_contents.push(
      generateNotificationItems(header, messages, setNotificationsItems)
    );
  }

  if (setNotificationsItems) {
    setNotificationsItems(notification_contents);
  }
};

export const setInfoMessage = ({ setNotificationsItems, header, content }) => {
  const notification_contents = [
    {
      header: header || "Info",
      type: "info",
      content: content,
      dismissible: true,
      dismissLabel: "Dismiss message",
      onDismiss: () => setNotificationsItems([]),
    },
  ];
  if (setNotificationsItems) {
    setNotificationsItems(notification_contents);
  }
};

export const matches = (defaultValue, itemValue, selectedValue) =>
  selectedValue === defaultValue || itemValue === selectedValue;

const generateNotificationItems = (header, message, setNotificationsItems) => {
  return {
    header: header || "Error",
    type: "error",
    content: message,
    dismissible: true,
    dismissLabel: "Dismiss message",
    onDismiss: () => setNotificationsItems([]),
  };
};

export const setSuccessMessage = (
  setNotificationsItems,
  infoMessage,
  content
) => {
  setNotificationsItems([
    {
      header: infoMessage,
      type: "success",
      content: content,
      dismissible: true,
      dismissLabel: "Dismiss message",
      onDismiss: () => setNotificationsItems([]),
    },
  ]);
};

export const setBulkErrorMessages = (
  setNotificationsItems,
  failedFrResponses,
  failedFrIds,
  defaultNotificationMessage
) => {
  if (failedFrIds.length === 1) {
    setErrorMessage({
      setNotificationsItems,
      content: JSON.stringify(
        failedFrResponses[0].fields ||
          failedFrResponses[0].message ||
          Object.values(failedFrResponses[0].errors)
      ),
    });
    return;
  }

  setErrorMessage({
    setNotificationsItems,
    content: `${defaultNotificationMessage} ${failedFrIds.join(", ")}`,
  });
};

export const openNewTab = (url) => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

export const formatFileSize = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const isJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const getResponseJson = async (response) => {
  let body;
  try {
    body = await response.json();
  } catch (e) {
    return response;
  }
  return body;
};

export const getYearFromTimeStampString = (date) => {
  if (date === null) {
    return "Not Available";
  }
  return new Date(date).getFullYear();
};

export const getDate = (receivedDate, defaultValue = null) => {
  let date = defaultValue;
  if (receivedDate && Object.keys(receivedDate).includes("timestamp")) {
    date = new Date(Number(receivedDate["timestamp"]));
  }
  return date;
};

export const getISODate = (receivedDate, defaultValue = "-") => {
  const isoString = getISOString(receivedDate, defaultValue);
  return isoString ? isoString.split("T")[0] : defaultValue;
};

export const getISOTime = (receivedDate, defaultValue = "-") => {
  const isoString = getISOString(receivedDate, defaultValue);
  return isoString ? isoString.split(".")[0].replace("T", " ") : defaultValue;
};

export const getISOString = (receivedDate, defaultValue = "-") => {
  const date = getDate(receivedDate);
  return date ? date.toISOString() : defaultValue;
};

export const getDateFromISO = (iso) => {
  return iso.split("T")[0];
};

export const getNumberFromString = (string, noneValue = "Not Available") => {
  let number = string;
  if (number == noneValue) {
    number = "-1";
  }
  return parseInt(number);
};

export const timestampExist = (time) => {
  return time && time.timestamp;
};

export const timestampComparator = (t1, t2) => {
  const t1Exists = timestampExist(t1);
  const t2Exists = timestampExist(t2);
  if (!t1Exists && !t2Exists) {
    return 0;
  }
  return t1Exists ? (t2Exists ? t1.timestamp - t2.timestamp : 1) : -1;
};

export const numberComparator = (n1, n2) => {
  n1 = getNumberFromString(n1);
  n2 = getNumberFromString(n2);

  return n1 - n2;
};

export const currencyComparator = (c1, c2) => {
  c1 == "-" ? (c1 = "-1") : (c1 = c1.split(" ")[0]);
  c2 == "-" ? (c2 = "-1") : (c2 = c2.split(" ")[0]);
  return parseInt(c1) - parseInt(c2);
};

export const dateExist = (date) => {
  return date != null;
};

export const dateComparator = (d1, d2) => {
  const d1Exists = dateExist(d1);
  const d2Exists = dateExist(d2);

  if (!d1Exists && !d2Exists) {
    return 0;
  }

  return d1Exists ? (d2Exists ? d1 - d2 : 1) : -1;
};

/**
 * Converts a polaris date range object to milliseconds.
 * @param {Object} dateRange - This is the date range from polaris
 * @returns {number} milliseconds that represent the relative date range
 */
export const dateRangeToMs = (dateRange) => {
  const units = {
    year: 31536000000,
    month: 2628000000,
    week: 604800000,
    day: 86400000,
    hour: 3600000,
    minute: 60000,
    second: 1000,
  };
  if (dateRange.type === "relative") {
    return units[dateRange.unit] * dateRange.amount;
  } else {
    return Math.abs(
      new Date(dateRange.endDate) - new Date(dateRange.startDate)
    );
  }
};

/** Checks if all elements in an array are all equal
 * @param {any[]} arr input that we will check === equality
 * @returns {bool} true if all are the same else false
 */
export const allEqual = (arr) => arr.every((v) => v === arr[0]);

/** Checks if a fundRequest has an project extension, via making sure both
 * extension timestamp arent null.
 * @param {Object} fundRequest - this is the FR from the BE.
 * @param {Options} opts
 * @returns {bool} true if extended
 */
export const isExtended = (fundRequest, opts = { isActive: false }) => {
  const base =
    timestampExist(fundRequest.projectExtension.extendedStartDate) &&
    timestampExist(fundRequest.projectExtension.extendedEndDate);
  if (base && opts.isActive) {
    const now = Date.now();
    return (
      fundRequest.projectExtension.extendedStartDate.timestamp < now &&
      now <= fundRequest.projectExtension.extendedEndDate.timestamp
    );
  }
  return base;
};

export const isFloat = (value) => {
  return !Number.isInteger(parseFloat(value));
};
