import {
  STAGE,
  STATUS,
} from "../../../../shared/util/constants/fundRequestStatusType";

export const canExtend = ({ data, stage }) => {
  let showExtend = false;

  if (
    (stage === STAGE.PRE_APPROVAL || stage === STAGE.CASH_CLAIM) &&
    !(
      data["projectExtension"]["extendedStartDate"] ||
      data["projectExtension"]["extendedEndDate"]
    )
  ) {
    showExtend = true;
  }

  return showExtend;
};

export const canEdit = ({ stage, status }) =>
  status === STATUS.ACTIVE && stage === STAGE.CREATED;
