import {
  Box,
  Button,
  Modal,
  Select,
  SpaceBetween,
} from "@amzn/awsui-components-react";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useQueries } from "@tanstack/react-query";
import {
  constructBulkActionsFFQueries,
  parseBulkActionValuesFromQueryResults,
} from "../config";

const DownloadTemplate = ({
  isDownloadTemplateComponentVisible,
  setIsDownloadTemplateComponentVisible,
}) => {
  const [selectedBulkAction, setSelectedBulkAction] = useState(null);

  // Fetching bulk action types based on feature flags
  const queryResults = useQueries({
    queries: constructBulkActionsFFQueries(),
  });
  const isError = queryResults.some((q) => q.isError);

  if (isError) {
    for (const q of queryResults) {
      if (q.isError) {
        console.error(q.error);
      }
    }
  }
  const bulkActionTypes = Object.values(
    parseBulkActionValuesFromQueryResults(queryResults)
  );

  const resetDownloadTemplate = () => {
    setIsDownloadTemplateComponentVisible(false);
    setSelectedBulkAction(null);
  };

  return (
    <Modal
      header="Download Template"
      onDismiss={() => resetDownloadTemplate()}
      visible={isDownloadTemplateComponentVisible}
      closeAriaLabel="Close modal"
      size="medium"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="m">
            <Button variant="link" onClick={() => resetDownloadTemplate()}>
              Cancel
            </Button>
            <Button
              variant="primary"
              disabled={selectedBulkAction === null}
              onClick={() => {
                window.location.replace(
                  `/bulk_actions_${selectedBulkAction.value}_template.xlsx`
                );
                resetDownloadTemplate();
              }}
            >
              Download Template
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <SpaceBetween size="s">
        <Select
          selectedOption={selectedBulkAction}
          placeholder="Select a Bulk Action"
          onChange={({ detail }) =>
            setSelectedBulkAction(detail.selectedOption)
          }
          options={bulkActionTypes}
        />
      </SpaceBetween>
    </Modal>
  );
};

DownloadTemplate.propTypes = {
  isDownloadTemplateComponentVisible: PropTypes.bool,
  setIsDownloadTemplateComponentVisible: PropTypes.func,
};

export default DownloadTemplate;
