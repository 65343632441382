import { ValidationError } from "../../../../shared/util/validation/error";

export const grantFieldEditingToPage = (
  page,
  sectionName,
  fieldName,
  fieldTemplate
) => {
  let fieldTemplateUpdated = [];
  for (const section of Object.keys(fieldTemplate)) {
    const copy_of_section = { ...fieldTemplate[section] };
    let copy_of_fields = [];
    for (let field of Object.keys(copy_of_section.fields)) {
      const copy_of_field = { ...copy_of_section.fields[field] };
      copy_of_fields.push(copy_of_field);
    }
    copy_of_section.fields = copy_of_fields;
    fieldTemplateUpdated[section] = copy_of_section;
  }

  const fieldToAddPermission = fieldTemplateUpdated[sectionName].fields.find(
    (field) => field.name === fieldName
  );
  if (!(page in fieldToAddPermission.edit)) {
    fieldToAddPermission.edit.push(page);
  }
  return fieldTemplateUpdated;
};

export const extractCashClaimIndex = (cashClaimId) => {
  const index = cashClaimId.split("-").at(-1);
  if (index <= 0) {
    throw new ValidationError("Not a valid claim Index!");
  }
  return parseInt(index);
};
