import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "@amzn/awsui-components-react/polaris/button";
import {
  Box,
  Header,
  SpaceBetween,
  Spinner,
} from "@amzn/awsui-components-react";
import {
  ERROR_TYPE,
  hasError,
  retryApiCall,
} from "../../../../shared/util/services/data/DataService";
import { updateProjectInformation } from "../../../util/services/data/InternalDataService";
import { setErrorMessage } from "../../../../shared/util/common/helper";
import { useHistory, useParams } from "react-router-dom";

export const MigrationEngagementEditFooter = ({
  isLoadingNextStep,
  setIsLoadingNextStep,
  data,
  fieldToIdMap,
  page,
  setNotificationItems,
}) => {
  const history = useHistory();
  const { id } = useParams();
  const source = page ? page : "fund-request";
  const [inputMPEId, setInputMPEId] = useState(null);

  useEffect(() => {
    setIsLoadingNextStep(false);
    const inputMpeId = data[fieldToIdMap?.migrationProgramEngagements?.mpeId];
    setInputMPEId(inputMpeId === "" ? null : inputMpeId);
  }, [data[fieldToIdMap?.migrationProgramEngagements?.mpeId]]);

  const generate_payload = () => {
    const executedState = (
      data[fieldToIdMap?.projectInformation?.executedState] || []
    ).map((item) => item.value);

    return {
      awsAccountId: data[fieldToIdMap?.projectInformation?.awsAccountId],
      customerConsidering:
        data[fieldToIdMap?.projectInformation?.customerConsidering],
      executedCountry: data[fieldToIdMap?.projectInformation?.executedCountry],
      executedState: executedState,
      migrationBdApproved:
        data[fieldToIdMap?.projectInformation?.migrationBdApproved],
      plannedEndDate: {
        timestamp: new Date(
          data[fieldToIdMap?.projectInformation?.plannedEndDate]
        ).getTime(),
      },
      plannedStartDate: {
        timestamp: new Date(
          data[fieldToIdMap?.projectInformation?.plannedStartDate]
        ).getTime(),
      },
      programEngagementId: inputMPEId,
      projectType: data[fieldToIdMap?.projectInformation?.projectType],
    };
  };

  const submitHandler = async () => {
    try {
      setIsLoadingNextStep(true);
      let apiBody = generate_payload();
      const submissionResponse = await retryApiCall({
        callApi: updateProjectInformation(apiBody),
      });
      if (
        hasError(submissionResponse) &&
        submissionResponse["errorType"] === ERROR_TYPE.BANNER
      ) {
        setIsLoadingNextStep(false);
        setErrorMessage({
          setNotificationsItems: setNotificationItems,
          content: submissionResponse.message,
          status: submissionResponse.status,
        });
      } else if (submissionResponse["errors"]) {
        setIsLoadingNextStep(false);
      } else {
        setIsLoadingNextStep(false);
        history.push(`/${source}/${id}/review`);
      }
    } catch (e) {
      console.error(e);
      setErrorMessage({
        setNotificationItems,
      });
    }
  };

  return (
    <Header
      actions={
        <SpaceBetween direction="horizontal" size="xs">
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                onClick={() => {
                  history.push(`/${source}/${id}/review`);
                }}
              >
                Cancel
              </Button>
              <Button variant="primary" onClick={submitHandler}>
                {isLoadingNextStep ? <Spinner size="normal" /> : "Save"}
              </Button>
            </SpaceBetween>
          </Box>
        </SpaceBetween>
      }
    />
  );
};

MigrationEngagementEditFooter.propTypes = {
  isLoadingNextStep: PropTypes.bool,
  setIsLoadingNextStep: PropTypes.func,
  data: PropTypes.object,
  sectionTitle: PropTypes.string,
  fieldToIdMap: PropTypes.object,
  page: PropTypes.string,
  setNotificationItems: PropTypes.func,
};
