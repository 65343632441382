import {
  getInternalRoles,
  getProfile,
  getUsername,
} from "internal/util/services/auth/AuthService";
import { Assignee } from "internal/programs/migration-acceleration-program/2024/fund-request/types/SchemaType";
import { STAGE, STATUS } from "shared/util/constants/fundRequestStatusType";
import { IGenericObject } from "shared/programs/migration-acceleration-program/2024/fund-request/types/CommonTypes";
import { ROLES } from "internal/util/constants/roles";

export const isAssigned = ({ userId }: Assignee) => {
  return isUserAssigned({ userId }) || isTeamAssigned({ userId });
};

export const isUserAssigned = ({
  username = getUsername(),
  userId,
}: {
  username?: string;
  userId: string;
}) => {
  return username === userId;
};

export const isTeamAssigned = ({
  profile = getProfile(),
  userId,
}: {
  profile?: Array<string>;
  userId: string;
}) => {
  return profile.includes(userId);
};

export const isActive = ({ status }: { status: string }) => {
  const activeStatuses = [STATUS.ACTIVE, STATUS.SUBMITTED];
  return activeStatuses.includes(status);
};

export const isApprovableStage = ({ stage }: { stage: string }) => {
  const approvableStages = [STAGE.BUSINESS_APPROVAL, STAGE.FINANCE_APPROVAL];
  return approvableStages.includes(stage);
};

export const isCompletable = ({ stage }: { stage: string }) => {
  const completableStages = [STAGE.CASH_CLAIM];
  return completableStages.includes(stage);
};

export const isApprovable = ({ data }: { data: IGenericObject }) => {
  const stage = data?.stage;
  const status = data?.status;
  const userId: string = data?.assignee?.userId || "";

  return (
    isAssigned({ userId }) &&
    isActive({ status }) &&
    isApprovableStage({ stage })
  );
};

export const isSubmitted = ({ data }: { data: IGenericObject }) => {
  const stage = data?.stage;
  const PreSubmissionStage = [STAGE.PARTNER];
  return !PreSubmissionStage.includes(stage);
};

export const isAdmin = () => {
  const userRoles = getInternalRoles();
  return userRoles.includes(ROLES.FUNDING_ADMIN);
};

export const showCompleteAction = ({ data }: { data: IGenericObject }) => {
  const stage = data?.stage;
  const status = data?.status;
  const userId: string = data?.assignee?.userId || "";

  return (
    isAssigned({ userId }) && isActive({ status }) && isCompletable({ stage })
  );
};
