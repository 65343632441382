import { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { useParams } from "react-router-dom";
import { ApiToUiSectionDataAdapterFactory } from "../adapter/toUi/ApiToUiSectionDataAdapterFactory";
import { retryApiCall } from "../services/data/DataService";
import { generateMultiSectionKey } from "../common/generateMultiSectionKey";
import { SECTION_NAME } from "../constants/section";

const useFields = ({
  data,
  stepTemplate,
  dataLoad,
  dataInput,
  fieldToIdMap,
  updateFieldMap,
  getTemplate,
  filterFields,
  schema,
  getFundRequest,
}) => {
  const { id } = useParams();
  const template = getTemplate({
    program: data["program"],
    migrationPhase: data["migrationPhase"],
    fundingType: data["fundingType"],
  });
  const stepLayout = template[stepTemplate.name];
  const [isLoading, setIsLoading] = useState(true);
  const [fieldTemplate, setFieldTemplate] = useState({});
  const [multiSection, setMultiSection] = useState(1);
  const [additionalSections, setAdditionalSections] = useState([1]);

  useEffect(async () => {
    setIsLoading(true);
    let response = {};

    try {
      response = await retryApiCall({ callApi: getFundRequest(id) });
    } catch (e) {
      console.error(e);
    }

    let newFieldTemplate = {};
    let newFieldToIdMap = {};
    let newInitializedData = {};
    let sections = 1;
    let additionalSectionList = [1];

    const templateKeys = Object.keys(template);

    for (let sectionName of templateKeys) {
      const sectionTitle = template[sectionName].title;

      const adaptedSection = ApiToUiSectionDataAdapterFactory({
        sectionTitle,
      })({
        sectionTitle,
        program: data["program"],
        data: response,
        schema,
        filterFields,
      });

      newFieldTemplate = {
        ...newFieldTemplate,
        ...adaptedSection["fieldTemplate"],
      };
      newFieldToIdMap = {
        ...newFieldToIdMap,
        ...adaptedSection["fieldToIdMap"],
      };
      newInitializedData = {
        ...newInitializedData,
        ...adaptedSection["initializedData"],
      };

      if (stepTemplate.name === sectionName) {
        if (adaptedSection["numOfAdditionalSections"]) {
          sections = adaptedSection["numOfAdditionalSections"] + 1;
          additionalSectionList = [
            ...additionalSections,
            ...Array.from(Array(sections).keys()).splice(2),
          ];
        }
        if (adaptedSection["cashClaims"]) {
          additionalSectionList = adaptedSection["cashClaims"];
        }
      }
    }
    dataLoad(newInitializedData);
    updateFieldMap({ ...newFieldToIdMap });
    setFieldTemplate(newFieldTemplate);
    setAdditionalSections(additionalSectionList);
    setMultiSection(sections - 1);
    setIsLoading(false);
  }, [stepTemplate.name]);

  /**
   *
   */
  const addSection = () => {
    const { minSections, maxSections, title } = stepLayout.multiSection;

    const nextMultiSection = multiSection + 1;

    if (multiSection >= minSections && nextMultiSection <= maxSections) {
      const filteredFields = filterFields({
        section: schema[title],
        program: data["program"],
      });

      const initializedData = {};

      let newSectionIndex =
        additionalSections[additionalSections.length - 1] + 1;
      if (stepLayout.name === SECTION_NAME.CASH_REQUEST_INFORMATION) {
        const cashClaimSections = Object.keys(fieldToIdMap).filter(
          (sectionFieldsKey) =>
            sectionFieldsKey.includes(SECTION_NAME.CASH_CLAIM_PLANS)
        );
        const previousSection = cashClaimSections[cashClaimSections.length - 1];
        const previousCashClaimId =
          data[fieldToIdMap[previousSection]["fundClaimId"]];
        const previousCashClaimIdSplit = previousCashClaimId.split("-");
        const previousCashClaimIdIndex =
          previousCashClaimIdSplit[previousCashClaimIdSplit.length - 1];
        newSectionIndex = parseInt(previousCashClaimIdIndex) + 1;
      }
      const sectionId = generateMultiSectionKey({
        index: newSectionIndex,
        sectionName: schema[title].name,
      });
      const newFieldToIdMap = {
        ...fieldToIdMap,
        [sectionId]: {},
      };

      const fields = filteredFields.map((field) => {
        const id = uuid();

        newFieldToIdMap[sectionId][field.name] = id;

        const newField = {
          ...field,
          disabled: field.auto,
          id,
        };

        initializedData[id] = "";

        if (field.name === "fundClaimId") {
          initializedData[
            id
          ] = `${data["fundRequestId"]}-claim-${newSectionIndex}`;
        }

        if (newField.Required) {
          newField["required"] = true;
        }
        return newField;
      });

      fieldTemplate[sectionId] = {
        ...stepTemplate,
        fields,
      };

      setMultiSection(nextMultiSection);
      updateFieldMap({ ...newFieldToIdMap });
      dataInput(initializedData);
      setFieldTemplate(fieldTemplate);
      console.log([...additionalSections, newSectionIndex]);
      setAdditionalSections([...additionalSections, newSectionIndex]);
    }
  };

  /**
   *
   * @param index
   */
  const removeSection = (index) => {
    const { minSections, title } = stepLayout.multiSection;

    if (multiSection > minSections) {
      // Generate the key for the section metadata.
      const sectionId = generateMultiSectionKey({
        index,
        sectionName: schema[title].name,
      });

      // Remove the specified key and associated metadata.
      const newFieldTemplate = { ...fieldTemplate };
      delete newFieldTemplate[sectionId];

      // Remove the section index from the array.
      const sectionIndexInArray = additionalSections.indexOf(index);
      if (sectionIndexInArray > -1) {
        additionalSections.splice(sectionIndexInArray, 1);
      }

      const newFieldToIdMap = { ...fieldToIdMap };

      delete newFieldToIdMap[sectionId];

      updateFieldMap({ ...newFieldToIdMap });
      setAdditionalSections(additionalSections);
      setFieldTemplate(newFieldTemplate);
      setMultiSection(multiSection - 1);
    }
  };

  return [
    {
      additionalSections,
      fieldTemplate,
      isLoading,
      addSection,
      removeSection,
      multiSection,
    },
    setFieldTemplate,
  ];
};

export default useFields;
