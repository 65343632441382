import React, { useEffect, useState } from "react";
import {
  CASH_CLAIM_STAGE,
  STAGE,
  STATUS,
} from "../../../../shared/util/constants/fundRequestStatusType";
import Button from "@amzn/awsui-components-react/polaris/button";
import {
  openNewTab,
  setErrorMessage,
} from "../../../../shared/util/common/helper";
import { SpaceBetween } from "@amzn/awsui-components-react";
import { PAGES } from "../../../util/constants/pages";
import { SECTION_NAME } from "../../../../shared/util/constants/section";
import {
  approveCashClaimActual,
  completeCashClaim,
  rejectCashClaimActual,
} from "../../../util/services/data/InternalDataService";
import {
  ERROR_TYPE,
  hasError,
} from "../../../../shared/util/services/data/DataService";
import { setSuccessMessage } from "../../../util/helper";
import { getReviewActionType } from "../../common/CommentModal/util";
import { INTERNAL_REVIEW_ACTION_TYPE } from "../../common/CommentModal/reviewActionType";
import ChooseCommentModal from "../../common/CommentModal/ChooseCommentModal";
import AuditButton from "../Audit/AuditButton";
import { isFeatureEnabled } from "../../../../shared/util/services/features/FeatureFlagsService";
import { FEATURE_FLAG_ENUMS } from "../../../../shared/util/constants/featureFlagValues";

export const CashClaimAction = ({
  cashClaimId,
  cashClaimStage,
  history,
  fundRequestId,
  stage,
  status,
  page,
  setNotificationsItems,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  let action = "";
  const [claimAttachEnabled, setClaimAttachEnabled] = useState(false);
  const [claimAmountUpdateEnabled, setClaimAmountUpdateEnabled] =
    useState(false);

  useEffect(async () => {
    const claimAttachmentFlag = await isFeatureEnabled(
      FEATURE_FLAG_ENUMS.CASH_CLAIM_ATTACHMENT_INTERNAL
    );
    const claimAmountUpdateFlag = await isFeatureEnabled(
      FEATURE_FLAG_ENUMS.UPDATE_CLAIM_AMOUNT_INTERNAL_ENABLE
    );
    setClaimAttachEnabled(claimAttachmentFlag);
    setClaimAmountUpdateEnabled(claimAmountUpdateFlag);
  });

  const activeFundRequest =
    (status === STATUS.ACTIVE || status === STATUS.SUBMITTED) &&
    stage === STAGE.CASH_CLAIM &&
    page === PAGES.CASH_CLAIM_APPROVAL;

  const expiredFundRequest =
    status === STATUS.EXPIRED &&
    stage === STAGE.CASH_CLAIM &&
    page === PAGES.CASH_CLAIM_APPROVAL;

  const completedFundRequest = stage === STAGE.COMPLETED;

  const checkIfEligibleForUploadAttachments = () => {
    const attachmentValidStages = [
      CASH_CLAIM_STAGE.ACTUAL,
      CASH_CLAIM_STAGE.ACTUAL_APPROVAL,
      CASH_CLAIM_STAGE.INVOICE,
      CASH_CLAIM_STAGE.INVOICE_SUBMITTED,
      CASH_CLAIM_STAGE.COMPLETED,
    ];
    return (
      (attachmentValidStages.includes(cashClaimStage) && activeFundRequest) ||
      completedFundRequest
    );
  };

  const checkIfEligibleForClaimAmountUpdate = () => {
    const attachmentValidStages = [
      CASH_CLAIM_STAGE.ACTUAL_APPROVAL,
      CASH_CLAIM_STAGE.INVOICE,
      CASH_CLAIM_STAGE.INVOICE_SUBMITTED,
      CASH_CLAIM_STAGE.COMPLETED,
    ];
    return (
      (attachmentValidStages.includes(cashClaimStage) && activeFundRequest) ||
      completedFundRequest
    );
  };

  const checkIfEligibleForInvoiceUpdate = () => {
    const invoiceUpdateEligibleCashClaimStages = [
      CASH_CLAIM_STAGE.INVOICE,
      CASH_CLAIM_STAGE.INVOICE_SUBMITTED,
      CASH_CLAIM_STAGE.COMPLETED,
    ];
    return (
      completedFundRequest ||
      (invoiceUpdateEligibleCashClaimStages.includes(cashClaimStage) &&
        (activeFundRequest || expiredFundRequest))
    );
  };

  if (
    cashClaimStage === CASH_CLAIM_STAGE.ACTUAL_APPROVAL &&
    activeFundRequest
  ) {
    const [isVisible, setIsVisible] = useState({
      [INTERNAL_REVIEW_ACTION_TYPE.APPROVE]: false,
      [INTERNAL_REVIEW_ACTION_TYPE.REJECT]: false,
    });
    const selectedReviewAction = getReviewActionType({ isVisible });

    const updateVisibility = (actionName, visibility) => {
      setIsVisible({ ...isVisible, [actionName]: visibility });
    };

    const approveCashClaim = async (payload) => {
      console.log(payload);
      try {
        setIsLoading(true);
        const response = await approveCashClaimActual(
          {
            fundRequestId,
            comment: payload["externalComment"],
            internalComment: payload["internalComment"],
          },
          cashClaimId
        );
        if (hasError(response) && response.errorType === ERROR_TYPE.BANNER) {
          setErrorMessage({
            setNotificationsItems,
            content: response.message,
            status: response.status,
          });
          setIsLoading(false);
          return;
        }
        setIsLoading(false);
        setSuccessMessage(
          setNotificationsItems,
          `Cash Claim ${cashClaimId} has been successfully approved.`
        );
      } catch (e) {
        console.error(e);
        setIsLoading(false);
        setErrorMessage({
          setNotificationsItems,
        });
      }
    };

    const rejectCashClaim = async (payload) => {
      try {
        setIsLoading(true);
        const response = await rejectCashClaimActual(
          {
            fundRequestId,
            comment: payload["externalComment"],
            internalComment: payload["internalComment"],
            reasonCodes: payload["reasonCodes"],
          },
          cashClaimId
        );

        if (hasError(response) && response.errorType === ERROR_TYPE.BANNER) {
          setErrorMessage({
            setNotificationsItems,
            content: response.message,
            status: response.status,
          });
          setIsLoading(false);
          return;
        }

        setIsLoading(false);
        setSuccessMessage(
          setNotificationsItems,
          `Cash Claim ${cashClaimId} has been successfully rejected.`
        );
      } catch (e) {
        console.error(e);
        setIsLoading(false);
        setErrorMessage({
          setNotificationsItems,
        });
      }
    };

    const reviewActions = {
      [INTERNAL_REVIEW_ACTION_TYPE.APPROVE]: approveCashClaim,
      [INTERNAL_REVIEW_ACTION_TYPE.REJECT]: rejectCashClaim,
    };

    action = (
      <React.Fragment>
        <SpaceBetween size="l" direction="horizontal">
          <Button
            loading={isLoading}
            onClick={() => {
              updateVisibility(INTERNAL_REVIEW_ACTION_TYPE.REJECT, true);
            }}
          >
            Reject
          </Button>
          <Button
            loading={isLoading}
            variant="primary"
            onClick={() => {
              updateVisibility(INTERNAL_REVIEW_ACTION_TYPE.APPROVE, true);
            }}
          >
            Approve
          </Button>
        </SpaceBetween>
        <ChooseCommentModal
          reviewActionType={selectedReviewAction}
          reviewAction={reviewActions[selectedReviewAction]}
          isLoading={isLoading}
          isVisible={isVisible[selectedReviewAction]}
          updateVisibility={updateVisibility}
          stage={stage}
        />
      </React.Fragment>
    );
  }

  if (checkIfEligibleForInvoiceUpdate()) {
    action = (
      <SpaceBetween size="l" direction="horizontal">
        <Button
          onClick={() => {
            openNewTab("https://payeecentral.amazon.com");
          }}
          iconAlign="right"
          iconName="external"
        >
          Payee Central
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            history.push(
              `/${PAGES.CASH_CLAIM_APPROVAL}/${fundRequestId}/${SECTION_NAME.CASH_CLAIM_INVOICE_PAYMENT}/${cashClaimId}/edit`,
              {
                claimId: cashClaimId,
              }
            );
          }}
        >
          Update Invoice
        </Button>
        {cashClaimStage === CASH_CLAIM_STAGE.INVOICE_SUBMITTED && (
          <Button
            loading={isLoading}
            variant="primary"
            onClick={async () => {
              try {
                setIsLoading(true);
                const response = await completeCashClaim(
                  { fundRequestId },
                  cashClaimId
                );
                if (
                  hasError(response) &&
                  response.errorType === ERROR_TYPE.BANNER
                ) {
                  setErrorMessage({
                    setNotificationsItems,
                    content: response.message,
                    status: response.status,
                  });
                  setIsLoading(false);
                  return;
                }
                setIsLoading(false);
                setSuccessMessage(
                  setNotificationsItems,
                  `Cash Claim ${cashClaimId} has been successfully completed.`
                );
              } catch (e) {
                console.error(e);
                setIsLoading(false);
              }
            }}
          >
            Complete Cash Claim
          </Button>
        )}
      </SpaceBetween>
    );
  }

  if (claimAttachEnabled && checkIfEligibleForUploadAttachments()) {
    action = (
      <SpaceBetween size="l" direction="horizontal">
        {action}
        <Button
          variant="primary"
          onClick={() => {
            history.push(
              `/${PAGES.CASH_CLAIM_APPROVAL}/${fundRequestId}/${SECTION_NAME.ATTACHMENTS}/${cashClaimId}/attachments/edit`,
              { claimId: cashClaimId }
            );
          }}
        >
          Upload Claim Attachments
        </Button>
      </SpaceBetween>
    );
  }

  if (claimAmountUpdateEnabled && checkIfEligibleForClaimAmountUpdate()) {
    action = (
      <SpaceBetween size="l" direction="horizontal">
        {action}
        <Button
          variant="primary"
          onClick={() => {
            history.push(
              `/${PAGES.CASH_CLAIM_APPROVAL}/${fundRequestId}/${SECTION_NAME.CASH_CLAIM_ACTUALS}/${cashClaimId}/edit`,
              { claimId: cashClaimId }
            );
          }}
        >
          Edit
        </Button>
      </SpaceBetween>
    );
  }

  action = (
    <SpaceBetween size="l" direction="horizontal">
      {action}
      {page === PAGES.CASH_CLAIM_APPROVAL ? (
        <AuditButton
          fundRequestId={fundRequestId}
          cashClaimId={cashClaimId}
          page={page}
          setNotificationsItems={setNotificationsItems}
        />
      ) : (
        ""
      )}
    </SpaceBetween>
  );
  return action;
};
