import {
  addPurchaseOrderInformation,
  reviewCashRequestInformation,
  reviewProjectInformation,
  reviewTemplateDetails,
  submitCashClaimInvoice,
  updateAWSCalculatorUrl,
  updateCashClaimInvoice,
} from "internal/util/services/data/InternalDataService";
import { IMAPFundRequest } from "shared/programs/migration-acceleration-program/2024/fund-request/types/SchemaTypes";
import {
  convertAmountToMoney,
  convertDateToTimestamp,
} from "shared/programs/migration-acceleration-program/2024/fund-request/api/onSubmit";
import { hasError, retryApiCall } from "shared/util/services/data/DataService";
import { getFundrequestId } from "shared/util/services/auth/AuthService";
import { STAGE } from "shared/util/constants/fundRequestStatusType";
import {
  FieldError,
  handleApiError,
  Result,
  ResultType,
  Success,
} from "shared/util/api/util";
import { IClaimInvoiceDetails } from "./adapters";
import { IGenericObject } from "shared/programs/migration-acceleration-program/2024/fund-request/types/CommonTypes";
import { isMobilizeMapLarge } from "shared/programs/migration-acceleration-program/2024/fund-request/utils/helper";
import { formatNumberInput } from "../../../../../../external/programs/migration-acceleration-program/2024/fund-request/components/create/wizard/util";

const addPurchaseOrder = async (data: IMAPFundRequest) => {
  const currency = data["currency"];
  const payload = {
    currency: currency,
    number: data["poNumber"],
    requestNumber: data["poRequestNumber"],
    issured: data["poIssued"],
    issuranceDate: convertDateToTimestamp(data["poIssuedDate"]),
    amount: convertAmountToMoney(data["poLocalAmount"], currency),
    amountUsd: convertAmountToMoney(data["poUsdAmount"], "USD"),
    reason: data["reason"],
  };

  const response = await retryApiCall({
    callApi: addPurchaseOrderInformation(payload),
    error: {},
  });

  if (hasError(response)) {
    return handleApiError(response.errorType)(response);
  }

  return new Result<Success>({});
};

const reviewCashRequest = async (data: IMAPFundRequest) => {
  const currency = data["currency"];
  const payload = {
    currency: currency,
    totalCostOfActivity: convertAmountToMoney(
      data["totalCostOfActivity"],
      currency
    ),
    cashFundingAmount: convertAmountToMoney(
      data["cashFundingAmount"],
      currency
    ),
    invoiceEntityName: data["invoiceEntityName"],
    invoiceRemitCountry: data["invoiceRemitCountry"],
    invoiceRemitStreet: data["invoiceRemitStreet"],
    invoiceRemitUnit: data["invoiceRemitUnit"],
    invoiceRemitCity: data["invoiceRemitCity"],
    invoiceRemitState: data["invoiceRemitState"],
    invoiceRemitPostalCode: data["invoiceRemitPostalCode"],
  };
  const response = await retryApiCall({
    callApi: reviewCashRequestInformation(payload),
    error: {},
  });

  if (hasError(response)) {
    return handleApiError(response.errorType)(response);
  }

  return new Result<Success>({});
};

export const editCashRequestDetails = async (
  data: IMAPFundRequest
): Promise<Result<ResultType>> => {
  const stage: string | undefined = data["stage"];
  if (stage && [STAGE.FINANCE_APPROVAL, STAGE.CASH_CLAIM].includes(stage)) {
    return addPurchaseOrder(data);
  }
  return reviewCashRequest(data);
};

export const editMigrationDetails = async (
  data: IMAPFundRequest
): Promise<Result<ResultType>> => {
  const payload: IGenericObject = {
    annualRunRate: convertAmountToMoney(data["arrUsdAmount"]),
    eligibleArrUsdAmount: convertAmountToMoney(data["eligibleArrUsdAmount"]),
    fundRequestId: getFundrequestId(),
    vmwareIncentiveEligible: data?.vmwareAcknowledgement || false,
    vmwareBonusOption: data?.vmwareMSPAcknowledgement || false,
  };
  if (!payload["vmwareIncentiveEligible"]) {
    payload["vmwareBonusOption"] = false;
  }

  const isEligible = data["modernizationIncentiveEligible"];
  if (isMobilizeMapLarge(data) && isEligible) {
    payload.modernizationIncentiveEligible = isEligible;
    payload.modernizationArrUsdAmount = isEligible
      ? convertAmountToMoney(data["modernizationArrUsdAmount"])
      : {};
  }

  const response = await retryApiCall({
    callApi: reviewTemplateDetails(payload),
    error: {},
  });

  if (hasError(response)) {
    return handleApiError(response.errorType)(response);
  }

  return new Result<Success>({});
};

export const editProjectDetails = async (
  data: IMAPFundRequest
): Promise<Result<ResultType>> => {
  const payload: IGenericObject = {
    awsAccountId: data["awsAccountId"],
    plannedStartDate: convertDateToTimestamp(data["plannedStartDate"]),
    plannedEndDate: convertDateToTimestamp(data["plannedEndDate"]),
    executedCountry: data["executedCountry"],
    activityName: data["activityName"],
    description: data["description"],
    customerConsidering: data["customerConsidering"],
    greenfieldIncentiveEligible: data["greenfieldIncentiveEligible"],
    programEngagementId: data["programEngagementId"],
    numberOfVmwareToMigrate: formatNumberInput(data["numberOfVmwareToMigrate"]),
    totalNumberOfWorkloadsInUse: formatNumberInput(
      data["totalNumberOfWorkloadsInUse"]
    ),
  };

  if (data["executedCountry"] === "US") {
    if (
      !data["executedState"] ||
      !Array.isArray((data["executedState"] as string).split(","))
    )
      return new Result<FieldError>({
        success: false,
        payload: new FieldError({
          errors: { executedState: "States must be selected." },
        }),
      });
    payload.executedState = data["executedState"].split(",");
  }

  const response = await retryApiCall({
    callApi: reviewProjectInformation(payload),
    error: {},
  });

  if (hasError(response)) {
    return handleApiError(response.errorType)(response);
  }
  return new Result<Success>({});
};

export const editClaimInvoice = async (
  data: IClaimInvoiceDetails,
  claimId?: string
): Promise<Result<ResultType>> => {
  const payload = {
    fundClaimId: claimId,
    fundRequestId: getFundrequestId(),
    scheduledPaidDate: convertDateToTimestamp(data["scheduledPaymentDate"]),
    amount: convertAmountToMoney(
      data["invoiceLocalAmount"],
      data["localCurrency"]
    ),
    currency: data["localCurrency"],
    description: data["description"],
  };

  const response = await retryApiCall({
    callApi: submitCashClaimInvoice(payload, claimId),
    error: {},
  });

  if (hasError(response)) {
    return handleApiError(response.errorType)(response);
  }

  return new Result<Success>({});
};

export const saveClaimInvoice = async (
  data: IClaimInvoiceDetails,
  claimId?: string
): Promise<Result<ResultType>> => {
  const payload = {
    fundClaimId: claimId,
    fundRequestId: getFundrequestId(),
    scheduledPaidDate: convertDateToTimestamp(data["scheduledPaymentDate"]),
    amount: convertAmountToMoney(
      data["invoiceLocalAmount"],
      data["localCurrency"]
    ),
    currency: data["localCurrency"],
    description: data["description"],
  };

  const response = await retryApiCall({
    callApi: updateCashClaimInvoice(payload, claimId),
    error: {},
  });

  if (hasError(response)) {
    return handleApiError(response.errorType)(response);
  }

  return new Result<Success>({});
};

export const editAttachments = async (
  data: IMAPFundRequest
): Promise<Result<ResultType>> => {
  if (!data || !data["awsCalculatorUrl"]) return new Result<Success>({});

  const payload = {
    awsCalculatorUrl: data["awsCalculatorUrl"],
    activityName: data["activityName"],
    businessDescription: data["businessDescription"],
  };

  const response = await retryApiCall({
    callApi: updateAWSCalculatorUrl(payload),
    error: {},
  });

  if (hasError(response)) {
    return handleApiError(response.errorType)(response);
  }

  return new Result<Success>({});
};
