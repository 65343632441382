import { IFieldSchema } from "../../../../../types/SchemaTypes";
import { FieldType } from "shared/programs/migration-acceleration-program/2024/fund-request/components/fields/FieldComponent";
import { ValidationType } from "shared/programs/migration-acceleration-program/2024/fund-request/components/fields/validations";
import { SelectOptionType } from "shared/programs/migration-acceleration-program/2024/fund-request/hooks/useSelectOptions";

import i18next from "external/i18n";

export const projectDetailsSchema: IFieldSchema[] = [
  {
    label: i18next.t(
      "wizard.section.projectDetails.content.projectDetails.fields.projectName.label"
    ),
    keyName: "activityName",
    placeholder: i18next.t(
      "wizard.section.projectDetails.content.projectDetails.fields.projectName.placeholder"
    ) as string,
    fieldType: FieldType.Input,
    constraintText: (value: string = "") => (
      <>
        Project name must be 1&ndash;300 characters, including spaces and
        special characters. Character count: {value.length}
        /300
      </>
    ),
    validations: [ValidationType.MaxLength300Chars],
  },
  {
    label: i18next.t(
      "wizard.section.projectDetails.content.projectDetails.fields.businessDescription.label"
    ),
    keyName: "description",
    fieldType: FieldType.Textarea,
    placeholder: i18next.t(
      "wizard.section.projectDetails.content.projectDetails.fields.businessDescription.placeholder"
    ) as string,
    description: i18next.t(
      "wizard.section.projectDetails.content.projectDetails.fields.businessDescription.description"
    ) as string,
    constraintText: (value: string = "") => (
      <>
        The business description must be 1&ndash;5000 characters, including
        special characters or spaces. Character count: {value.length}/5000
      </>
    ),
    validations: [ValidationType.MaxLength5000Chars],
  },
  {
    label: i18next.t(
      "wizard.section.projectDetails.content.projectDetails.fields.projectStartDate.label"
    ),
    keyName: "plannedStartDate",
    placeholder: i18next.t(
      "wizard.section.projectDetails.content.projectDetails.fields.projectStartDate.placeholder"
    ) as string,
    fieldType: FieldType.DatePicker,
    constraintText: () =>
      i18next.t(
        "wizard.section.projectDetails.content.projectDetails.fields.projectStartDate.constraintText"
      ),
  },
  {
    label: i18next.t(
      "wizard.section.projectDetails.content.projectDetails.fields.projectEndDate.label"
    ),
    keyName: "plannedEndDate",
    placeholder: i18next.t(
      "wizard.section.projectDetails.content.projectDetails.fields.projectEndDate.placeholder"
    ) as string,
    fieldType: FieldType.DatePicker,
    constraintText: () =>
      i18next.t(
        "wizard.section.projectDetails.content.projectDetails.fields.projectEndDate.constraintText"
      ),
  },
  {
    label: i18next.t(
      "wizard.section.projectDetails.content.projectDetails.fields.awsAccountId.label"
    ),
    keyName: "awsAccountId",
    fieldType: FieldType.Input,
    validations: [ValidationType.AwsAccountId],
  },
  {
    label: i18next.t(
      "wizard.section.projectDetails.content.projectDetails.fields.projectLocation.label"
    ),
    keyName: "executedCountry",
    placeholder: i18next.t(
      "wizard.section.projectDetails.content.projectDetails.fields.projectLocation.placeholder"
    ) as string,
    selectOptionType: SelectOptionType.Countries,
    fieldType: FieldType.Select,
  },
  {
    label: i18next.t(
      "wizard.section.projectDetails.content.projectDetails.fields.projectState.label"
    ),
    keyName: "executedState",
    placeholder: i18next.t(
      "wizard.section.projectDetails.content.projectDetails.fields.projectState.placeholder"
    ) as string,
    selectOptionType: SelectOptionType.States,
    fieldType: FieldType.MultiSelect,
    conditional: ({ data }) =>
      (data && data["executedCountry"] === "US") || false,
  },
];

export const vmwareProjectDetailsSchema: IFieldSchema[] =
  projectDetailsSchema.concat([
    {
      label: i18next.t(
        "wizard.section.projectDetails.content.projectDetails.fields.numberOfVmwareToMigrate.label"
      ),
      keyName: "numberOfVmwareToMigrate",
      fieldType: FieldType.Input,
      validations: [ValidationType.ValidNumber],
      conditional: ({ data }) =>
        (data && data?.vmwareAcknowledgement === true) || false,
    },
    {
      label: i18next.t(
        "wizard.section.projectDetails.content.projectDetails.fields.totalNumberOfWorkloadsInUse.label"
      ),
      keyName: "totalNumberOfWorkloadsInUse",
      fieldType: FieldType.Input,
      validations: [ValidationType.ValidNumber],
      conditional: ({ data }) =>
        (data && data?.vmwareAcknowledgement === true) || false,
    },
  ]);
