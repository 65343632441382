import {
  getISODate,
  timestampComparator,
} from "../../../../../../shared/util/common/helper";

export const fundingHistoryColumnDefinitions = [
  {
    id: "requestId",
    header: "Fund Request or Claim ID",
    cell: (e) => (e.cashClaimId ? e.cashClaimId : e.fundRequestId),
    sortingField: "requestId",
  },
  {
    id: "date",
    header: "Date",
    cell: (e) => getISODate(e.date),
    sortingField: "date",
    sortingComparator: (e1, e2) => timestampComparator(e1.date, e2.date),
  },
  {
    id: "action",
    header: "Action",
    cell: (e) => e.action,
    sortingField: "action",
  },
  {
    id: "fileName",
    header: "File Name",
    cell: (e) => (e.fileItem ? e.fileItem.fileName : "-"),
    sortingField: "fileName",
  },
  {
    id: "fileType",
    header: "File Type",
    cell: (e) => (e.fileItem ? e.fileItem.fileType : "-"),
    sortingField: "fileType",
  },
  {
    id: "fileStatus",
    header: "File Status",
    cell: (e) =>
      e.fileItem && e.fileItem.fileStatus ? e.fileItem.fileStatus : "-",
    sortingField: "fileStatus",
  },
  {
    id: "quarantineReason",
    header: "Quarantine Reason",
    cell: (e) => getQuarantineReason(e.fileItem),
    sortingField: "quarantineReason",
  },
  {
    id: "stage",
    header: "Stage",
    cell: (e) => e.stage,
  },
];

const getQuarantineReason = (fileItem) => {
  if (fileItem && fileItem.quarantineReason) {
    return QuarantineReasons[fileItem.quarantineReason];
  }
  return "-";
};

export const searchableColumns = [
  "requestId",
  "date",
  "action",
  "fileName",
  "fileType",
  "fileStatus",
  "quarantineReason",
  "stage",
];

export const QuarantineReasons = {
  FILE_TYPE_MISMATCH: "File Type Mismatch",
  FILE_SIZE_OVER_ALLOWED_LIMIT: "file Size Over Allowed Limit",
  INFECTED_FILE: "Infected File",
  MALFORMED_CSV: "Malformed Csv",
  MALFORMED_XLSX: "Malformed Xlsx",
  UNKNOWN: "Escaping Error",
};
