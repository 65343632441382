import React from "react";

import { Alert } from "@amzn/awsui-components-react";

import ConditionalField from "shared/components/common/Conditional/ConditionalField";
import { IErrorAlertProps } from "shared/programs/migration-acceleration-program/2024/fund-request/types/ErrorAlert";

const DEFAULT_ERROR_MESSAGE =
  "An error has occurred. An AWS Partner Funding admin has been alerted. Try again later.";

const SystemErrorAlert = ({ isVisible, error }: IErrorAlertProps) => {
  return (
    <ConditionalField showField={isVisible}>
      <Alert type="error">{error || DEFAULT_ERROR_MESSAGE}</Alert>
    </ConditionalField>
  );
};

export default SystemErrorAlert;
