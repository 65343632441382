import React from "react";
import ConditionalField from "shared/components/common/Conditional/ConditionalField";
import { Alert } from "@amzn/awsui-components-react";
import { IGenericObject } from "shared/programs/migration-acceleration-program/2024/fund-request/types/CommonTypes";
import { useTranslation } from "react-i18next";

const IncentiveAlerts = ({ data }: { data: IGenericObject }) => {
  const { t } = useTranslation();
  const greenfieldIncentiveEligible =
    (data && data["greenfieldIncentiveEligible"]) || false;
  const modernizationIncentiveEligible =
    data && data["modernizationIncentiveEligible"];

  return (
    <>
      <ConditionalField
        showField={
          greenfieldIncentiveEligible && !modernizationIncentiveEligible
        }
      >
        <Alert>
          {t(
            "wizard.section.projectDetails.content.projectDetails.alerts.greenfield"
          )}
        </Alert>
      </ConditionalField>
      <ConditionalField
        showField={
          !greenfieldIncentiveEligible && modernizationIncentiveEligible
        }
      >
        <Alert>
          {t(
            "wizard.section.projectDetails.content.projectDetails.alerts.modernization"
          )}
        </Alert>
      </ConditionalField>
      <ConditionalField
        showField={
          greenfieldIncentiveEligible && modernizationIncentiveEligible
        }
      >
        <Alert>
          {t(
            "wizard.section.projectDetails.content.projectDetails.alerts.greenfieldModernization"
          )}
        </Alert>
      </ConditionalField>
    </>
  );
};

export default IncentiveAlerts;
