import { formatMigrationARR, mpeAttributes } from "../util";

export const getSplitPanelColumnDefinitions = (selectedItems) => {
  let columnDefinitions = [
    {
      id: "name",
      header: "",
      cell: (item) => item.name,
      isRowHeader: true,
    },
  ];
  for (let i = 0; i < selectedItems.length; i++) {
    const key = "mpeId" + String(i + 1);
    columnDefinitions.push({
      id: key,
      header: selectedItems[i].mpeId,
      cell: (item) => item[key],
      isRowHeader: false,
    });
  }
  return columnDefinitions;
};

const formatValue = (selectedItem, key) => {
  if (key === "migrationArr") {
    return formatMigrationARR(selectedItem[key]);
  } else {
    return selectedItem[key] || "-";
  }
};

export const getSplitPanelRowEntries = (selectedItems) => {
  let splitPanelRows = [];
  Object.keys(mpeAttributes).map((mpeAttribute) => {
    let splitPanelRow = {
      name: mpeAttributes[mpeAttribute],
    };
    for (let i = 0; i < selectedItems.length; i++) {
      const key = "mpeId" + String(i + 1);
      splitPanelRow[key] = formatValue(selectedItems[i], mpeAttribute);
    }
    splitPanelRows.push(splitPanelRow);
  });
  return splitPanelRows;
};
