import React, { Component } from "react";
import withAppLayout from "../../withAppLayout";
import Config from "config";
import Error from "../../../../external/components/common/Error";
import PropTypes from "prop-types";
import { analytics } from "../../../../external/util/services/metrics/analytics";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    console.log(error);
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    //TODO: Log these errors to the server.
    console.log("Error: " + error + "\n" + "ErrorInfo: " + errorInfo);
    console.log("Hit error boundary");
    if (Config.RUM_APP_ID && Config.RUM_ROLE_ARN && Config.RUM_IDPOOL_ID)
      analytics.recordError(error);
  }

  render() {
    if (this.state.hasError) {
      return withAppLayout({
        component: <Error {...this.props} />,
        toolsHide: true,
      })();
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.any,
};

export default ErrorBoundary;
