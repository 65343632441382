import React from "react";
import Header from "@amzn/awsui-components-react/polaris/header";
import PropTypes from "prop-types";
import { useOptionsContext } from "../../../util/context/OptionsContext";
import {
  schema,
  filterFields,
  getInternalTemplate,
} from "../../../config/schema";
import useFields from "../../../../shared/util/hooks/useFields";
import Loading from "../../../../shared/components/common/Loading";
import { SpaceBetween } from "@amzn/awsui-components-react";
import Section from "../../../../shared/components/FundRequest/StepsContainer/Section";
import RemoveButton from "../../../../shared/components/FundRequest/StepsContainer/RemoveButton";
import { InputPropsBuilder } from "../../../../shared/components/FundRequest/InputPropsBuilder";
import { DisableFactory } from "./DisableFactory";
import { ValueFactory } from "./ValueFactory";
import { InputFactory } from "../../../../shared/components/FundRequest/InputFactory";
import AddButton from "../../../../shared/components/FundRequest/StepsContainer/AddButton";
import { getFundRequest } from "../../../util/services/data/InternalDataService";
import {
  SECTION,
  SECTION_NAME,
} from "../../../../shared/util/constants/section";
import { PAGES } from "../../../util/constants/pages";
import { grantFieldEditingToPage } from "./util.js";
import { PROGRAM_TYPE } from "../../../../shared/util/constants/programType";

const MigrationDetailsEditSectionContainer = ({
  data,
  errors,
  dataInput,
  dataLoad,
  fieldToIdMap,
  updateErrors,
  setFieldToIdMap,
  stepTitle,
  validators,
  page,
  showHelperPanel,
}) => {
  const [options] = useOptionsContext();
  const stepTemplate = schema[stepTitle];
  const [
    { additionalSections, fieldTemplate, isLoading, addSection, removeSection },
  ] = useFields({
    data,
    stepTemplate,
    fieldToIdMap,
    updateFieldMap: setFieldToIdMap,
    dataLoad,
    dataInput,
    getTemplate: getInternalTemplate,
    filterFields,
    schema,
    getFundRequest,
  });
  const template = getInternalTemplate({
    program: data["program"],
    fundingType: data["fundingType"],
  });
  const stepLayout = template[stepTemplate.name];

  let updatedFieldTemplate;

  if (
    data["program"] === PROGRAM_TYPE.SMP &&
    Object.keys(fieldTemplate).length !== 0
  ) {
    updatedFieldTemplate = grantFieldEditingToPage(
      PAGES.BUSINESS_APPROVAL,
      SECTION_NAME.MIGRATION_DETAILS,
      "peCustomer",
      fieldTemplate
    );
    updatedFieldTemplate = grantFieldEditingToPage(
      PAGES.BUSINESS_APPROVAL,
      SECTION_NAME.MIGRATION_DETAILS,
      "mraCompleted",
      updatedFieldTemplate
    );
  }

  const section = isLoading ? (
    <Loading />
  ) : (
    <SpaceBetween size="l">
      <React.Fragment>
        <Section header={<Header>{SECTION.MIGRATION_DETAILS}</Header>}>
          <React.Fragment>
            {updatedFieldTemplate &&
              updatedFieldTemplate[SECTION_NAME.MIGRATION_DETAILS] &&
              updatedFieldTemplate[SECTION_NAME.MIGRATION_DETAILS].fields.map(
                (field, index) => {
                  const inputProps = InputPropsBuilder({
                    data,
                    errors,
                    field,
                    options,
                    index,
                    fieldToIdMap,
                    disableFactory: DisableFactory,
                    valueFactory: ValueFactory,
                    page,
                  });

                  return (
                    <InputFactory
                      key={`${SECTION_NAME.MIGRATION_DETAILS}-${index}`}
                      dataInput={dataInput}
                      fieldToIdMap={fieldToIdMap}
                      updateErrors={updateErrors}
                      validators={validators}
                      showHelperPanel={showHelperPanel}
                      {...inputProps}
                    />
                  );
                }
              )}
          </React.Fragment>
        </Section>
      </React.Fragment>
      {additionalSections.map((sectionIndex) => {
        const templateName =
          sectionIndex > 1
            ? `${SECTION_NAME.MIGRATION_WORKLOADS}-${sectionIndex}`
            : `${SECTION_NAME.MIGRATION_WORKLOADS}`;
        return (
          <React.Fragment key={`workloadContainer-${sectionIndex}`}>
            <Section
              header={
                <SpaceBetween size="l">
                  <Header
                    variant="h2"
                    actions={
                      additionalSections.length > 1 ? (
                        <RemoveButton
                          index={sectionIndex}
                          removeSection={removeSection}
                        />
                      ) : (
                        ""
                      )
                    }
                  >
                    {`${updatedFieldTemplate[templateName].title} ${
                      sectionIndex > 1 ? sectionIndex : ""
                    }`}
                  </Header>
                </SpaceBetween>
              }
            >
              <React.Fragment>
                {updatedFieldTemplate &&
                  updatedFieldTemplate[templateName] &&
                  updatedFieldTemplate[templateName].fields.map(
                    (field, index) => {
                      const inputProps = InputPropsBuilder({
                        data,
                        errors,
                        field,
                        options,
                        index: sectionIndex,
                        fieldToIdMap,
                        disableFactory: DisableFactory,
                        valueFactory: ValueFactory,
                        page,
                      });

                      return (
                        <InputFactory
                          key={`${templateName}-${index}`}
                          dataInput={dataInput}
                          fieldToIdMap={fieldToIdMap}
                          updateErrors={updateErrors}
                          validators={validators}
                          showHelperPanel={showHelperPanel}
                          {...inputProps}
                        />
                      );
                    }
                  )}
              </React.Fragment>
            </Section>
          </React.Fragment>
        );
      })}
      <AddButton
        addSection={addSection}
        multiSectionDetails={stepLayout.multiSection}
        isDisabled={
          !(additionalSections.length < stepLayout.multiSection.maxSections)
        }
      />
    </SpaceBetween>
  );
  return <React.Fragment>{section}</React.Fragment>;
};

MigrationDetailsEditSectionContainer.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object,
  dataInput: PropTypes.func,
  dataLoad: PropTypes.func,
  fieldToIdMap: PropTypes.object,
  updateErrors: PropTypes.func,
  setTemplate: PropTypes.func,
  validators: PropTypes.any,
  setFieldToIdMap: PropTypes.func,
  stepTitle: PropTypes.string,
  page: PropTypes.string,
  showHelperPanel: PropTypes.func,
};

export default React.memo(MigrationDetailsEditSectionContainer);
