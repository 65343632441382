import { FormField } from "@amzn/awsui-components-react";
import React from "react";
import Checkbox from "@amzn/awsui-components-react/polaris/checkbox";
import { IMigrationEligibilityStateModel } from "external/programs/migration-acceleration-program/2024/fund-request/views/create/FundRequestCreationView";
import { IGenericObject } from "shared/programs/migration-acceleration-program/2024/fund-request/types/CommonTypes";
import { useTranslation } from "react-i18next";
import { VMWARE_MSP_ACKNOWLEDGEMENT_KEY } from "../../../../../../../../components/FundRequest/ProgramSelectMAP2024/util";

interface VMwareMSPAcknowledgementCheckboxParams {
  formData: IMigrationEligibilityStateModel;
  updateField: (props: IGenericObject) => void;
  loading: boolean;
}

export const VMwareMSPAcknowledgementCheckbox = ({
  formData,
  updateField,
  loading,
}: VMwareMSPAcknowledgementCheckboxParams) => {
  const { t } = useTranslation();
  return (
    <FormField stretch>
      <Checkbox
        onChange={({ detail }) => {
          updateField({
            key: VMWARE_MSP_ACKNOWLEDGEMENT_KEY,
            value: detail.checked,
          });
        }}
        disabled={loading}
        description={t(
          "eligibility.container.content.migrationEligibility.fields.vmwareMSPAcknowledgement.description"
        )}
        checked={formData["vmwareMSPAcknowledgement"] || false}
      >
        <span>
          {t(
            "eligibility.container.content.migrationEligibility.fields.vmwareMSPAcknowledgement.label"
          )}
        </span>
      </Checkbox>
    </FormField>
  );
};
