import React from "react";
import { Container, Header } from "@amzn/awsui-components-react";
import { CashClaimActualsSchema, VmwareCashClaimActualsSchema } from "./schema";
import SOWRadioGroup from "./SOWRadioGroup";
import { SpaceBetween } from "@amzn/awsui-components-react/polaris";
import InputSectionFieldsFactory from "external/programs/migration-acceleration-program/2024/fund-request/components/create/wizard/Steps/InputSectionFieldsFactory";
import { IClaimActualsContainerProps } from "../../../../views/MAPCashClaimActualsSubmission";
import { HelpPanelContentContext } from "shared/util/context/help/HelpContext";
import InfoLink from "shared/components/InfoLink";
import { FEATURE_FLAG_ENUMS } from "shared/util/constants/featureFlagValues";
import ConditionalField from "shared/components/common/Conditional/ConditionalField";
import useFeatureFlag from "shared/util/hooks/useFeatureFlag";
import VMwareMSPRadioGroup from "./VMwareMSPRadioGroup";

const CashClaimActuals = (props: IClaimActualsContainerProps) => {
  const { setHelpPanelContent } = React.useContext(HelpPanelContentContext);
  const vmwareClaim = props?.data?.vmwareClaim;
  const vmwareMSPAcknowledgement = props?.data?.vmwareMSPAcknowledgement;
  const vmwareEnabledFlag = useFeatureFlag(
    FEATURE_FLAG_ENUMS.VMWARE_MAP_ENABLED
  );
  const vmwareMSPEnabledFlag = useFeatureFlag(
    FEATURE_FLAG_ENUMS.VMWARE_BONUS_MSP_OPTION_ENABLED
  );
  return (
    <SpaceBetween size="l">
      <Container
        header={
          <Header
            variant="h2"
            info={
              <InfoLink
                onFollow={() => {
                  setHelpPanelContent("claim.container.actual");
                }}
              />
            }
          >
            Actuals
          </Header>
        }
      >
        <SpaceBetween size="l">
          <InputSectionFieldsFactory
            schema={
              vmwareEnabledFlag && vmwareClaim
                ? VmwareCashClaimActualsSchema
                : CashClaimActualsSchema
            }
            {...props}
          />
          <ConditionalField showField={!(vmwareEnabledFlag && vmwareClaim)}>
            <SOWRadioGroup {...props} />
          </ConditionalField>
          <ConditionalField
            showField={
              vmwareMSPEnabledFlag && vmwareClaim && vmwareMSPAcknowledgement
            }
          >
            <VMwareMSPRadioGroup {...props} />
          </ConditionalField>
        </SpaceBetween>
      </Container>
    </SpaceBetween>
  );
};

export default CashClaimActuals;
