import React from "react";
import { ColumnLayout } from "@amzn/awsui-components-react";
import PropTypes from "prop-types";

export const MigrationEngagementsSectionContent = ({
  overviewFields,
  RAFields,
  OLAFields,
}) => {
  return (
    <ColumnLayout columns={4} borders="vertical">
      {overviewFields}
      {RAFields}
      {OLAFields}
    </ColumnLayout>
  );
};

MigrationEngagementsSectionContent.propTypes = {
  overviewFields: PropTypes.array,
  RAFields: PropTypes.array,
  OLAFields: PropTypes.array,
};
