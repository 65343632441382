import {
  defaultStatus,
  defaultRecordTypeLabel,
  searchableColumns,
} from "./tableConfig";

const matchesStatus = (item, selectedStatus) => {
  return (
    selectedStatus.value === defaultStatus.value ||
    item.status === selectedStatus.value
  );
};

const matchesRecordTypeLabel = (item, selectedRecordTypeLabel) => {
  return (
    selectedRecordTypeLabel.value === defaultRecordTypeLabel.value ||
    item.recordTypeLabel === selectedRecordTypeLabel.value
  );
};

export const prepareSelectOptions = (data, field, defaultOption) => {
  const optionSet = [];

  data.forEach((item) => {
    if (optionSet.indexOf(item[field]) === -1) {
      optionSet.push(item[field]);
    }
  });

  optionSet.sort();

  const options = [defaultOption];

  optionSet.forEach((item) => options.push({ label: item, value: item }));
  return options;
};

export const filterItem = ({
  item,
  filteringText,
  status,
  recordTypeLabel,
}) => {
  if (!matchesStatus(item, status)) {
    return false;
  }

  if (!matchesRecordTypeLabel(item, recordTypeLabel)) {
    return false;
  }

  return searchableColumns
    .map((key) => item[key])
    .some(
      (value) =>
        typeof value === "string" &&
        value.toLowerCase().indexOf(filteringText.toLowerCase()) > -1
    );
};

export const clearFilter = (actions, setStatus, setRecordTypeLabel) => {
  actions.setFiltering("");
  setStatus(defaultStatus);
  setRecordTypeLabel(defaultRecordTypeLabel);
};
