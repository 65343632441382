export const buildMetadataList = (spmsId, userId, userRoles) => {
  // both `userId` and `userID` are needed as this field was named slightly different in each env
  // TODO: fill in pathStage and primaryPath
  return [
    { key: "spmd", value: spmsId },
    { key: "userId", value: userId },
    { key: "userID", value: userId },
    { key: "userRole", value: userRoles.join() },
  ];
};
