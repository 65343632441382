import React from "react";
import Container from "@amzn/awsui-components-react/polaris/container";
import Header from "@amzn/awsui-components-react/polaris/header";
import {
  Box,
  ColumnLayout,
  CopyToClipboard,
} from "@amzn/awsui-components-react";
import InfoLink from "shared/components/InfoLink";
import { IReadOnlyComponentProps } from "external/programs/migration-acceleration-program/2024/fund-request/types/SchemaTypes";
import ValueWithLabel from "shared/programs/migration-acceleration-program/2024/fund-request/components/fields/ValueWithLabel";
import { longLocalDateFormat } from "shared/util/common/date";
import ConditionalField from "shared/components/common/Conditional/ConditionalField";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import FundRequestStatus from "external/programs/migration-acceleration-program/2024/fund-request/components/shared/common/status/FundRequestStatus";
import { capitalizeFirstLetter } from "external/programs/migration-acceleration-program/2024/fund-request/utils/string";
import Link from "@amzn/awsui-components-react/polaris/link";
import { display } from "shared/util/common/display";
import { IGenericObject } from "shared/programs/migration-acceleration-program/2024/fund-request/types/CommonTypes";
import { MAP2024_MIGRATION_PHASE } from "external/programs/migration-acceleration-program/2024/fund-request/components/create/eligibility/util";
import useFeatureFlag from "shared/util/hooks/useFeatureFlag";
import { FEATURE_FLAG_ENUMS } from "shared/util/constants/featureFlagValues";

const ProjectDetailsDetailContainer = ({
  data,
  actions = <></>,
}: IReadOnlyComponentProps) => {
  const { fundRequestId, stage, status, owner, migrationPhase } = data;
  const projectDetails = data["projectDetails"];
  const vmwareAcknowledgement = projectDetails?.vmwareAcknowledgement;
  const executedState = (
    display({
      data: projectDetails,
      key: "executedState",
    }) as string
  )
    .split(",")
    .join(", ");
  const isVMwareMapEnabled = useFeatureFlag(
    FEATURE_FLAG_ENUMS.VMWARE_MAP_ENABLED
  );

  return (
    <Container
      header={
        <Header
          variant="h2"
          info={
            <InfoLink
              onFollow={() => {
                //TODO: Implement help panel
              }}
            />
          }
          actions={actions}
        >
          Project details
        </Header>
      }
    >
      <SpaceBetween size="xs">
        <ColumnLayout columns={3} variant="text-grid">
          <SpaceBetween size="l">
            <ValueWithLabel label="Fund request ID">
              <CopyToClipboard
                textToCopy={fundRequestId || "-"}
                copySuccessText="Copied to clipboard."
                copyErrorText="Failed to copy to clipboard."
                variant="inline"
              />
            </ValueWithLabel>
            <ValueWithLabel label="AWS account ID">
              {display({
                data: projectDetails,
                key: "awsAccountId",
                format: (value) => {
                  return (
                    <Link
                      external
                      href={`https://${value}.signin.aws.amazon.com/console/`}
                    >
                      {value as string}
                    </Link>
                  );
                },
              })}
            </ValueWithLabel>
            <ValueWithLabel label="Owner">{owner}</ValueWithLabel>
            <ValueWithLabel label="Assignee">
              {display({
                data,
                key: "assignee",
                format: (value) => {
                  const userId = (value as IGenericObject)?.userId;
                  let assignee = userId;

                  if (
                    (value as IGenericObject)?.userType === "Person" &&
                    userId !== "nobody"
                  ) {
                    assignee = (
                      <Link
                        href={`https://phonetool.amazon.com/users/${userId}`}
                      >
                        {userId}
                      </Link>
                    );
                  }

                  return assignee;
                },
              })}
            </ValueWithLabel>
            <ValueWithLabel label="Program engagement ID">
              {display({ data, key: "programEngagementId" })}
            </ValueWithLabel>
            <ValueWithLabel label="Reason for extension">
              {
                display({
                  data: projectDetails,
                  key: "extensionReason",
                }) as string
              }
            </ValueWithLabel>
            <ConditionalField
              showField={
                isVMwareMapEnabled &&
                vmwareAcknowledgement &&
                migrationPhase === MAP2024_MIGRATION_PHASE.MOBILIZE
              }
            >
              <ValueWithLabel label="Total number of servers in scope for migration to AWS">
                {display({
                  data: projectDetails,
                  key: "totalNumberOfWorkloadsInUse",
                })}
              </ValueWithLabel>
            </ConditionalField>
          </SpaceBetween>
          <SpaceBetween size="l">
            <ValueWithLabel label="Current approval stage">
              {capitalizeFirstLetter(stage)}
            </ValueWithLabel>
            <ValueWithLabel label="Project start date">
              {
                display({
                  data: projectDetails,
                  key: "plannedStartDate",
                  format: (value) => longLocalDateFormat(value),
                }) as string
              }
            </ValueWithLabel>
            <ValueWithLabel label="Extended project start date">
              {
                display({
                  data: projectDetails,
                  key: "extendedStartDate",
                  format: (value) => longLocalDateFormat(value),
                }) as string
              }
            </ValueWithLabel>
            <ValueWithLabel label="Project country">
              {display({ data: projectDetails, key: "executedCountry" })}
            </ValueWithLabel>
            <ValueWithLabel label="Project Location GEO">
              {
                display({
                  data: projectDetails,
                  key: "locationGeo",
                }) as string
              }
            </ValueWithLabel>
            <ConditionalField
              showField={migrationPhase === MAP2024_MIGRATION_PHASE.MOBILIZE}
            >
              <ValueWithLabel label="Greenfield incentive eligible">
                {display({
                  data: projectDetails,
                  key: "greenfieldIncentiveEligible",
                })}
              </ValueWithLabel>
            </ConditionalField>
          </SpaceBetween>
          <SpaceBetween size="l">
            <ValueWithLabel label="Status">
              <FundRequestStatus status={status} />
            </ValueWithLabel>
            <ValueWithLabel label="Project end date">
              {
                display({
                  data: projectDetails,
                  key: "plannedEndDate",
                  format: (value) => longLocalDateFormat(value),
                }) as string
              }
            </ValueWithLabel>
            <ValueWithLabel label="Extended project end date">
              {
                display({
                  data: projectDetails,
                  key: "extendedEndDate",
                  format: (value) => longLocalDateFormat(value),
                }) as string
              }
            </ValueWithLabel>
            <ConditionalField showField={!!executedState}>
              <ValueWithLabel label="Project state">
                {executedState}
              </ValueWithLabel>
            </ConditionalField>
            <ValueWithLabel label="Funding expiration date">
              {
                display({
                  data: projectDetails,
                  key: "fundingExpirationDate",
                  format: (value) => longLocalDateFormat(value),
                }) as string
              }
            </ValueWithLabel>
            <ConditionalField
              showField={
                isVMwareMapEnabled &&
                vmwareAcknowledgement &&
                migrationPhase === MAP2024_MIGRATION_PHASE.MOBILIZE
              }
            >
              <ValueWithLabel label="Number of VMs hosted on VMware in scope for migration to AWS">
                {display({
                  data: projectDetails,
                  key: "numberOfVmwareToMigrate",
                })}
              </ValueWithLabel>
            </ConditionalField>
          </SpaceBetween>
        </ColumnLayout>
        <ValueWithLabel label="Project name">
          <Box variant="p">
            {display({ data: projectDetails, key: "activityName" })}
          </Box>
        </ValueWithLabel>
        <ValueWithLabel label="Business description">
          <Box variant="p">
            {display({ data: projectDetails, key: "description" })}
          </Box>
        </ValueWithLabel>
      </SpaceBetween>
    </Container>
  );
};

export default ProjectDetailsDetailContainer;
