import {
  ErrorDisplayType,
  ValidationResult,
} from "external/util/common/validationHelper";
import {
  ACCURATE_WORKLOAD_ACKNOWLEDGEMENT_KEY,
  ANNUAL_RUN_RATE_KEY,
  MAP_ARR_THRESHOLD,
  MODERNIZATION_ANNUAL_RUN_RATE_KEY,
} from "external/components/FundRequest/ProgramSelectMAP2024/util";
import {
  MAP2024_MIGRATION_PHASE,
  showModernizationCheck,
} from "external/programs/migration-acceleration-program/2024/fund-request/components/create/eligibility/util";
import i18next from "external/i18n";

export const isValidAnnualRunRate = ({
  annualRunRate,
}: {
  annualRunRate?: number;
}): ValidationResult<string> => {
  if (!annualRunRate || Number(annualRunRate) < MAP_ARR_THRESHOLD) {
    return new ValidationResult<string>({
      isValid: false,
      error: {
        type: ErrorDisplayType.FIELD,
        fieldKey: ANNUAL_RUN_RATE_KEY,
        reason: i18next.t(
          "eligibility.container.content.migrationEligibility.fields.annualRunRate.error.invalidAnnualRunRate"
        ),
      },
    });
  }

  return new ValidationResult({});
};

export const isValidModernizationAnnualRunRate = ({
  annualRunRate,
  modernizationArrUsdAmount,
  modernizationIncentiveEligible,
  migrationPhase,
}: {
  annualRunRate?: number;
  modernizationArrUsdAmount?: number;
  modernizationIncentiveEligible?: boolean;
  migrationPhase?: string;
}): ValidationResult<string> => {
  if (migrationPhase === MAP2024_MIGRATION_PHASE.ASSESS)
    return new ValidationResult({});

  if (modernizationIncentiveEligible && !modernizationArrUsdAmount) {
    return new ValidationResult<string>({
      isValid: false,
      error: {
        type: ErrorDisplayType.FIELD,
        fieldKey: MODERNIZATION_ANNUAL_RUN_RATE_KEY,
        reason: "Modernization annual run rate is required.",
      },
    });
  }

  if (
    modernizationIncentiveEligible &&
    modernizationArrUsdAmount &&
    annualRunRate &&
    (Number(modernizationArrUsdAmount) < Number(annualRunRate) * 0.4 ||
      Number(modernizationArrUsdAmount) > Number(annualRunRate))
  ) {
    return new ValidationResult<string>({
      isValid: false,
      error: {
        type: ErrorDisplayType.FIELD,
        fieldKey: MODERNIZATION_ANNUAL_RUN_RATE_KEY,
        reason: i18next.t(
          "eligibility.container.content.migrationEligibility.fields.modernizationArr.error.invalidPercentage"
        ),
      },
    });
  }

  return new ValidationResult({});
};

export const isAccurateWorkloadAcknowledgedForm = ({
  annualRunRate,
  accurateWorkloadAcknowledgement,
  migrationPhase,
}: {
  annualRunRate?: number;
  accurateWorkloadAcknowledgement?: boolean;
  migrationPhase?: string;
}): ValidationResult<string> => {
  if (
    showModernizationCheck({ annualRunRate, migrationPhase }) &&
    !accurateWorkloadAcknowledgement
  ) {
    return new ValidationResult<string>({
      isValid: false,
      error: {
        type: ErrorDisplayType.FORM,
        reason:
          "Acknowledgement of accurate workload tagging as an AWS partner is required in order to proceed.",
      },
    });
  }

  return new ValidationResult({});
};

export const isAccurateWorkloadAcknowledgedField = ({
  annualRunRate,
  accurateWorkloadAcknowledgement,
  migrationPhase,
}: {
  annualRunRate?: number;
  accurateWorkloadAcknowledgement?: boolean;
  migrationPhase?: string;
}): ValidationResult<string> => {
  if (
    showModernizationCheck({ annualRunRate, migrationPhase }) &&
    !accurateWorkloadAcknowledgement
  ) {
    return new ValidationResult<string>({
      isValid: false,
      error: {
        type: ErrorDisplayType.FIELD,
        fieldKey: ACCURATE_WORKLOAD_ACKNOWLEDGEMENT_KEY,
        reason: "Acknowledgement is required to begin creating a fund request.",
      },
    });
  }

  return new ValidationResult({});
};
