import * as React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import Config from "config";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { appMonitor } from "../shared/util/services/metrics/analytics";
import App from "./App";
import "./i18n";

import "@amzn/awsui-global-styles/polaris.css";
import "./index.css";
import { APFPv1QueryClient } from "shared/components/QueryClient";
import { UserSettingsProvider } from "shared/components/UserPersistedSettings/Context";
import { UserPersistedSettingsVersions } from "shared/components/UserPersistedSettings/Constants";

appMonitor({
  appId: Config.RUM_APP_ID,
  guestRoleArn: Config.RUM_ROLE_ARN,
  identityPoolId: Config.RUM_IDPOOL_ID,
  canCollectAnalytics: true,
});

const ReactQueryDevtoolsProduction = React.lazy(() =>
  import("@tanstack/react-query-devtools/build/lib/index.prod.js").then(
    (d) => ({
      default: d.ReactQueryDevtools,
    })
  )
);

function Index() {
  const [showDevtools, setShowDevtools] = React.useState(false);

  React.useEffect(() => {
    // @ts-expect-error
    window.toggleDevtools = () => setShowDevtools((old) => !old);
  }, []);

  return (
    <React.StrictMode>
      <QueryClientProvider client={APFPv1QueryClient}>
        <ReactQueryDevtools initialIsOpen />
        {showDevtools && (
          <React.Suspense fallback={null}>
            <ReactQueryDevtoolsProduction />
          </React.Suspense>
        )}
        <Router>
          <UserSettingsProvider version={UserPersistedSettingsVersions.V1}>
            <App />
          </UserSettingsProvider>
        </Router>
      </QueryClientProvider>
    </React.StrictMode>
  );
}

ReactDOM.render(<Index />, document.getElementById("root"));
