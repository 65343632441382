import React from "react";
import PropTypes from "prop-types";
import Table from "@amzn/awsui-components-react/polaris/table";
import Pagination from "@amzn/awsui-components-react/polaris/pagination";
import { useCollection } from "@amzn/awsui-collection-hooks";
import { Box } from "@amzn/awsui-components-react";
import Grid from "@amzn/awsui-components-react/polaris/grid";
import Input from "@amzn/awsui-components-react/polaris/input";
import Header from "@amzn/awsui-components-react/polaris/header";
import TableNoMatchState from "../../../common/TableNoMatchState";
import {
  entityComparator,
  getVisibleColumns,
  searchableColumns,
  selfServiceEntityHistoryColumnDefinitions,
  sortingField,
} from "./tableConfig";
import { ENTITY_HISTORY_TABLE_SIZE } from "../../constant";
import { useParams } from "react-router-dom";

const SelfServiceEntityHistoryTable = ({
  isLoading,
  history,
  isValidEntity,
  entityDetails,
}) => {
  const { entity } = useParams();
  const { items, actions, collectionProps, filterProps, paginationProps } =
    useCollection(history || [], {
      pagination: { pageSize: ENTITY_HISTORY_TABLE_SIZE },
      filtering: {
        noMatch: (
          <TableNoMatchState onClearFilter={() => actions.setFiltering("")} />
        ),
        filteringFunction: (item, filteringText) => {
          return searchableColumns
            .map((key) => item[key])
            .some(
              (value) =>
                typeof value === "string" &&
                value.toLowerCase().indexOf(filteringText.toLowerCase()) > -1
            );
        },
      },
      sorting: {
        defaultState: {
          sortingColumn: {
            sortingComparator: (i1, i2) => entityComparator(i1, i2),
            sortingField: sortingField,
          },
          isDescending: true,
        },
      },
      selection: {},
    });

  return (
    <Table
      {...collectionProps}
      loading={isLoading}
      loadingText="Loading self service entity history..."
      items={items}
      columnDefinitions={selfServiceEntityHistoryColumnDefinitions}
      visibleColumns={getVisibleColumns(
        entityDetails.entityType,
        entityDetails.selfServiceAuthorizerFlag
      )}
      resizableColumns
      empty={
        <Box textAlign="center" color="inherit">
          <Box padding={{ bottom: "s" }} variant="p" color="inherit">
            No History to Display.
          </Box>
        </Box>
      }
      filter={
        <Grid gridDefinition={[{ colspan: 4 }]}>
          <Input
            type="search"
            value={filterProps.filteringText}
            onChange={(event) => {
              actions.setFiltering(event.detail.value);
            }}
            placeholder="Search..."
            label="Search history"
          />
        </Grid>
      }
      header={
        <Header
          counter={history.length ? `(${items.length}/${history.length})` : ""}
        >
          {isValidEntity ? entity : ""} History
        </Header>
      }
      pagination={<Pagination {...paginationProps} />}
    />
  );
};

SelfServiceEntityHistoryTable.propTypes = {
  isLoading: PropTypes.bool,
  history: PropTypes.array,
  isValidEntity: PropTypes.bool,
  entityDetails: PropTypes.object,
};

export default SelfServiceEntityHistoryTable;
