import MigrationEligibilityContainer from "external/programs/migration-acceleration-program/2024/fund-request/components/create/eligibility/MigrationEligibilityContainer";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Button from "@amzn/awsui-components-react/polaris/button";
import React, { useEffect, useState } from "react";
import { IMigrationEligibilityStateModel } from "external/programs/migration-acceleration-program/2024/fund-request/views/create/FundRequestCreationView";
import { MIGRATION_PHASE_KEY } from "external/components/FundRequest/ProgramSelectMAP2024/util";
import {
  ErrorDisplayType,
  IErrorDisplay,
  ValidationResult,
} from "external/util/common/validationHelper";
import {
  isAccurateWorkloadAcknowledgedField,
  isAccurateWorkloadAcknowledgedForm,
  isValidAnnualRunRate,
  isValidModernizationAnnualRunRate,
} from "external/programs/migration-acceleration-program/2024/fund-request/views/create/validations";
import { useHistory, useParams } from "react-router-dom";
import {
  CreateMAPFundRequestRequestBuilder,
  ICreateMAPFundRequestRequest,
} from "external/util/services/data/model/createFundRequest";
import { ERROR_TYPE, hasError } from "shared/util/services/data/DataService";
import {
  getFundRequest,
  updateEligibility,
} from "external/util/services/data/FundRequestService";
import { IGenericObject } from "shared/programs/migration-acceleration-program/2024/fund-request/types/CommonTypes";
import { useFundRequest } from "shared/programs/migration-acceleration-program/2024/fund-request/hooks/useFundRequest";
import { migrationEligibilityAdapter } from "shared/programs/migration-acceleration-program/2024/fund-request/api/adapters";
import DOMPurify from "dompurify";
import { TITLE } from "shared/util/constants/title";
import { HelpPanelContentContext } from "shared/util/context/help/HelpContext";
import { GENERIC_ERROR_MESSAGE } from "external/programs/migration-acceleration-program/2024/fund-request/components/create/wizard/Steps/Artifacts/util";
import UpdateFundRequestTemplateAdapter from "external/util/services/data/model/fund-request/adapters/UpdateFundRequestTemplateAdapter";

const INITIAL_STATE = {
  [MIGRATION_PHASE_KEY]: "",
};

export interface IMigrationEligibilityDisabledFields {
  [MIGRATION_PHASE_KEY]?: boolean;
}

const EditMigrationEligibilityContainer = () => {
  const { id } = useParams<IGenericObject>();
  const history = useHistory();
  const { setKey } = React.useContext(HelpPanelContentContext);

  const [formData, setFormData] =
    useState<IMigrationEligibilityStateModel>(INITIAL_STATE);

  const [fieldErrors, setFieldErrors] =
    useState<IMigrationEligibilityStateModel>(INITIAL_STATE);
  const [formError, setFormError] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { isLoading: loading } = useFundRequest({
    id,
    adapt: migrationEligibilityAdapter,
    setData: setFormData,
    fetch: getFundRequest,
  });

  useEffect(() => {
    document.title = DOMPurify.sanitize(`${TITLE} - Edit Fund Request`);
    setKey("eligibility.page");
  }, []);

  const updateField = ({ key, value }: { [key: string]: string }) => {
    setFieldErrors(INITIAL_STATE);
    setFormError("");
    setFormData((prevState) => ({ ...prevState, [key]: value }));
  };

  const updateFieldErrors = ({ key, value }: { [key: string]: string }) => {
    setFieldErrors((prevState) => ({ ...prevState, [key]: value }));
  };

  const updateFormError = (value: string) => {
    setFormError(value);
  };

  const disabledFields: IMigrationEligibilityDisabledFields = {
    [MIGRATION_PHASE_KEY]: true,
  };

  const editProps = {
    disabled: disabledFields,
    formData,
    fieldErrors,
    formError,
    loading: isLoading || loading,
    updateField,
    updateFieldErrors,
    updateFormError,
    setFormData,
    setFieldErrors,
    setFormError,
    setIsLoading,
  };

  const onSubmit = async () => {
    try {
      setIsLoading(true);
      const validationResults: ValidationResult<string>[] = validate(formData);

      const hasErrors = handleValidationErrors({
        validationResults,
        setFieldErrors,
        setFormError,
      });

      if (hasErrors) throw new Error("Error while validating user input.");

      const fundRequestPayload: ICreateMAPFundRequestRequest | undefined =
        createPayload(formData);
      if (!fundRequestPayload)
        throw new Error("Error while creating 'update' payload");

      const response: IGenericObject = await updateEligibility(
        UpdateFundRequestTemplateAdapter(fundRequestPayload)
      );
      if (hasError(response)) {
        handleApiError(response.errorType)({
          response,
          setFieldErrors,
          setFormError,
        });
        return;
      }

      if (id) {
        console.info(`Fund request ${id} successfully edited.`);
        history.push(`/fund-requests/${id}/new`);
      }
    } catch (error: unknown) {
      setIsLoading(false);
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const validate = (
    eligibility: IMigrationEligibilityStateModel
  ): ValidationResult<string>[] => {
    const {
      migrationPhase,
      annualRunRate,
      modernizationArrUsdAmount,
      modernizationIncentiveEligible,
      accurateWorkloadAcknowledgement,
    } = eligibility;

    const validations = [
      isValidAnnualRunRate,
      isValidModernizationAnnualRunRate,
      isAccurateWorkloadAcknowledgedForm,
      isAccurateWorkloadAcknowledgedField,
    ];

    return validations.map((validate) =>
      validate({
        migrationPhase,
        annualRunRate,
        modernizationArrUsdAmount,
        modernizationIncentiveEligible,
        accurateWorkloadAcknowledgement,
      })
    );
  };

  const handleValidationErrors = ({
    validationResults,
    setFieldErrors,
    setFormError,
  }: {
    validationResults: ValidationResult<string>[];
    setFieldErrors: React.Dispatch<
      React.SetStateAction<IMigrationEligibilityStateModel>
    >;
    setFormError: React.Dispatch<React.SetStateAction<string>>;
  }) => {
    let hasErrors: boolean = false;
    if (!Array.isArray(validationResults) || validationResults.length === 0)
      return;

    const validationErrors = validationResults.filter(
      (result: ValidationResult<string>) => !result.isValid
    );
    const fieldErrors: { [key: string]: string } = {};
    let formError = "";

    validationErrors.map(({ error }: ValidationResult<string>) => {
      if (!error) return;
      const { type, fieldKey, reason }: IErrorDisplay<string> = error;
      if (type === ErrorDisplayType.FORM) {
        formError = reason;
      }

      if (type === ErrorDisplayType.FIELD && fieldKey) {
        fieldErrors[fieldKey] = reason;
      }
    });

    if (Object.keys(fieldErrors).length > 0) {
      hasErrors = true;
      setFieldErrors(fieldErrors);
    }

    if (formError) {
      hasErrors = true;
      setFormError(formError);
    }

    return hasErrors;
  };

  const createPayload = (
    state: IMigrationEligibilityStateModel
  ): ICreateMAPFundRequestRequest | undefined => {
    const {
      migrationPhase,
      annualRunRate,
      accurateWorkloadAcknowledgement,
      modernizationIncentiveEligible,
      modernizationArrUsdAmount,
      vmwareAcknowledgement,
      vmwareMSPAcknowledgement,
    }: IMigrationEligibilityStateModel = state;

    if (!migrationPhase || !annualRunRate) return;

    const payload = new CreateMAPFundRequestRequestBuilder(
      migrationPhase,
      annualRunRate
    )
      .setAccurateWorkloadAcknowledgement(accurateWorkloadAcknowledgement)
      .setModernizationIncentiveEligible(modernizationIncentiveEligible)
      .setModernizationArrUsdAmount(modernizationArrUsdAmount)
      .setVmwareAcknowledgement(vmwareAcknowledgement)
      .setVmwareMSPAcknowledgement(vmwareMSPAcknowledgement)
      .build();
    return payload.toJson();
  };

  const handleApiError = (errorType: string) => {
    const errorHandlers = {
      [ERROR_TYPE.FIELD]: handleFieldError,
      [ERROR_TYPE.BANNER]: handleFormError,
    };

    return errorHandlers[errorType];
  };

  const handleFieldError = ({
    response,
    setFieldErrors,
  }: {
    response: IGenericObject;
    setFieldErrors: React.Dispatch<
      React.SetStateAction<IMigrationEligibilityStateModel>
    >;
  }) => {
    setFieldErrors(response.errors);
  };

  const handleFormError = ({
    response,
    setFormError,
  }: {
    response: IGenericObject;
    setFormError: React.Dispatch<React.SetStateAction<string>>;
  }) => {
    setFormError(response?.message ?? GENERIC_ERROR_MESSAGE);
  };

  return (
    <MigrationEligibilityContainer
      {...editProps}
      actions={
        <SpaceBetween direction="horizontal" size="xs">
          <Button
            loading={isLoading || loading}
            variant="link"
            onClick={() => {
              history.goBack();
            }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            loading={isLoading || loading}
            onClick={async () => {
              await onSubmit();
            }}
          >
            Save and continue
          </Button>
        </SpaceBetween>
      }
    />
  );
};

export default EditMigrationEligibilityContainer;
