import qs from "querystring";
import { decode } from "jsonwebtoken";
import Config from "config";
import SessionManager from "./SessionManager";

export const COGNITO_ENDPOINT = Config.COGNITO_ENDPOINT;

export const CLIENT_ID = Config.CLIENT_ID;

export const REDIRECT_URI = `${document.location.origin}/auth`;
export const IDENTITY_PROVIDER = `${Config.IDENTITY_PROVIDER}`;
export const COGNITO_OAUTH_ENDPOINT = `${COGNITO_ENDPOINT}/oauth2`;
export const COGNITO_AUTH_URL = `${COGNITO_OAUTH_ENDPOINT}/authorize?response_type=code&client_id=${CLIENT_ID}&redirect_uri=${document.location.origin}/auth&scope=openid&identity_provider=${IDENTITY_PROVIDER}`;

export const checkAuthTokenExists = () => {
  const accessTokenExists = !!SessionManager.getAccessToken();
  const idTokenExists = !!SessionManager.getIdToken();
  const refreshTokenExists = !!SessionManager.getRefreshToken();
  return accessTokenExists && idTokenExists && refreshTokenExists;
};

/**
 * Get the subject id for a particular user from the Id Token. "sub" is unique to an individual user.
 * return "sub" claim for a particular user.
 */
export const getSubjectId = () => {
  if (checkAuthTokenExists()) {
    const decodedIdToken = decode(SessionManager.getIdToken(), {
      json: true,
    });

    const { sub } = decodedIdToken;
    return sub;
  }

  return "";
};

export const getRoles = () => {
  let roles = [];

  if (checkAuthTokenExists()) {
    const decodedIdToken = decode(SessionManager.getIdToken(), {
      json: true,
    });
    const { profile } = decodedIdToken;

    if (profile && isJson(profile)) {
      const role = JSON.parse(profile).role;

      if (typeof role === "string") {
        roles.push(role);
      } else {
        roles = role;
      }
    } else {
      roles.push(profile);
    }
  }

  return roles;
};

export const getSpmsId = () => {
  let spmsId = "";

  if (checkAuthTokenExists()) {
    const decodedIdToken = decode(SessionManager.getIdToken(), {
      json: true,
    });

    const { profile } = decodedIdToken;

    if (profile) {
      spmsId = JSON.parse(profile).spms_id;
    }
  }

  return spmsId;
};

export const getExternalUserId = () => {
  if (checkAuthTokenExists()) {
    const decodedIdToken = decode(SessionManager.getIdToken(), {
      json: true,
    });
    const { profile } = decodedIdToken;
    try {
      if (profile) {
        return JSON.parse(profile).userId;
      }
    } catch (e) {
      return null;
    }
  }
};

export const getUserCognitoId = () => {
  if (checkAuthTokenExists()) {
    const decodedIdToken = decode(SessionManager.getIdToken(), {
      json: true,
    });
    if (decodedIdToken.sub) {
      return decodedIdToken.sub;
    } else {
      return null;
    }
  }
};

export const getPartnerAccountId = () => {
  let partnerAccountId = "";

  if (checkAuthTokenExists()) {
    const decodedIdToken = decode(SessionManager.getIdToken(), {
      json: true,
    });

    const { profile } = decodedIdToken;

    if (profile) {
      partnerAccountId = JSON.parse(profile).accountId;
    }
  }

  return partnerAccountId;
};

export const isJson = (input) => {
  try {
    JSON.parse(input);
  } catch (e) {
    return false;
  }
  return true;
};

export const login = (
  id_token,
  access_token,
  refresh_token,
  tokenExpiration
) => {
  SessionManager.setIdToken(id_token);
  SessionManager.setAccessToken(access_token);
  SessionManager.setRefreshToken(refresh_token);
  SessionManager.setTokenExpiration(tokenExpiration);
};

export const logout = () => {
  SessionManager.removeIdToken();
  SessionManager.removeAccessToken();
  SessionManager.removeRefreshToken();
  SessionManager.removeTokenExpiration();
};

export const tokenExpired = () => {
  const tokenExpiration = SessionManager.getTokenExpiration();
  const expirationTime = new Date(tokenExpiration);
  const today = new Date();
  return today > expirationTime;
};

export const isAuthTokenValid = async () => {
  let valid = false;

  if (checkAuthTokenExists() && !tokenExpired()) {
    valid = true;
  } else {
    try {
      valid = await refreshToken();
    } catch (e) {
      console.error(e);
    }
  }
  return valid;
};

export const checkTokenExpiration = async () => {
  if (checkAuthTokenExists()) {
    if (tokenExpired()) {
      try {
        await refreshToken();
      } catch (err) {
        logout();
      }
    } else {
      return true;
    }
  } else {
    console.error("No authentication found, redirecting user...");

    logout();
    window.location.replace(COGNITO_AUTH_URL);
  }
};

export const refreshToken = async () => {
  const refresh_token = SessionManager.getRefreshToken();

  const authReqBody = {
    grant_type: "refresh_token",
    client_id: CLIENT_ID,
    refresh_token: refresh_token,
  };

  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
  };

  const response = await fetch(`${COGNITO_OAUTH_ENDPOINT}/token`, {
    method: "POST",
    headers,
    body: qs.stringify(authReqBody),
  });

  if (response.ok) {
    const authPayload = await response.json();
    const expires_in = authPayload.expires_in
      ? parseInt(authPayload.expires_in) / 60
      : 60;
    let expireDate = new Date();
    expireDate.setTime(
      expireDate.setMinutes(expireDate.getMinutes() + expires_in)
    );

    const id_token = authPayload.id_token;
    const access_token = authPayload.access_token;

    // Set the tokens and expiration in local storage.
    login(id_token, access_token, refresh_token, expireDate.toUTCString());
    return true;
  } else {
    logout();
  }
};

export function getFundrequestId() {
  let frid;
  const pattern = new RegExp("^FR-");
  let pathStrings = window.location.href.split("/");
  for (let i = 0; i < pathStrings.length; i++)
    if (pattern.test(pathStrings[i]) === true) frid = pathStrings[i];
  return frid;
}
