import React, { createContext, useContext, useEffect, useReducer } from "react";
import { useQuery } from "@tanstack/react-query";
import PropTypes from "prop-types";
import { FEATURE_FLAG_ENUMS } from "shared/util/constants/featureFlagValues";
import { hasError } from "shared/util/services/data/DataService";
import { getUserPersistedSettingsPerVersion } from "./Api";
import { userSettingsReducer } from "./Reducer";
import { initialData, StateEnums } from "./Constants";
import { convertComponentVersionToReducerType } from "./util";

const UserSettingsContext = createContext(initialData);

const useUserSettingsContext = () => useContext(UserSettingsContext);

const UserSettingsProvider = ({ children, version }) => {
  const [userSettingsState, userSettingsDispatch] = useReducer(
    userSettingsReducer,
    initialData
  );

  const { isLoading: isUserPersistedSettingsFlagLoading } = useQuery({
    queryKey: ["features", FEATURE_FLAG_ENUMS.USER_PERSISTED_SETTINGS_ENABLED],
    onSuccess: (data) => {
      userSettingsDispatch({
        type: StateEnums.SET_FEATURE_FLAG,
        data: hasError(data) ? false : data.enabled,
      });
    },
    onError: (error) => {
      console.error(error);
      userSettingsDispatch({
        type: StateEnums.SET_FEATURE_FLAG,
        data: false,
      });
    },
  });

  const { isInitialLoading: isUserPersistedSettingsLoading } = useQuery({
    queryKey: ["userPersistedSettings", version],
    queryFn: () => getUserPersistedSettingsPerVersion(version),
    enabled: !!userSettingsState.isFeatureFlagEnabled,
    onSuccess: (settings) => {
      settings?.forEach((componentAttribute) => {
        userSettingsDispatch({
          type: convertComponentVersionToReducerType(
            componentAttribute?.componentVersion
          ),
          data: componentAttribute?.settings,
        });
      });
    },
    onError: (error) => {
      console.error(error);
    },
  });

  useEffect(() => {
    userSettingsDispatch({
      type: StateEnums.SET_LOADING,
      data:
        isUserPersistedSettingsFlagLoading || isUserPersistedSettingsLoading,
    });
  }, [isUserPersistedSettingsFlagLoading, isUserPersistedSettingsLoading]);

  const providerState = {
    userSettingsState,
    userSettingsDispatch,
  };

  return (
    <UserSettingsContext.Provider value={providerState}>
      {children}
    </UserSettingsContext.Provider>
  );
};

UserSettingsProvider.propTypes = {
  children: PropTypes.any.isRequired,
  version: PropTypes.string,
};

export { useUserSettingsContext, UserSettingsProvider };
