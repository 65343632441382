import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  collectionPreferencesProps,
  columnDefinitions,
  defaultColumnsToDisplay,
  filterItem,
  MIGRATION_ENGAGEMENT_TABLE_DEFAULT_PAGE_SIZE,
} from "./config";
import { useCollection } from "@amzn/awsui-collection-hooks";
import TableEmptyState from "../../../../common/TableEmptyState";
import TableNoMatchState from "../../../../common/TableNoMatchState";
import Grid from "@amzn/awsui-components-react/polaris/grid";
import TextFilter from "@amzn/awsui-components-react/polaris/text-filter";
import {
  CollectionPreferences,
  Header,
  Pagination,
} from "@amzn/awsui-components-react";
import Link from "@amzn/awsui-components-react/polaris/link";
import { getHeaderCounterText } from "./util";
import Table from "@amzn/awsui-components-react/polaris/table";
import { MigrationEngagementsSplitPanelContainer } from "../MigrationEngagementSplitPanel/MigrationEngagementsSplitPanelContainer";
import { MigrationEngagementHeaderAction } from "../MigrationEngagementHeaderAction";

const InternalMigrationEngagementReviewSectionTable = ({
  fundingData,
  page,
  migrationEngagementProps,
}) => {
  const migrationEngagements = fundingData?.migrationEngagements;
  const [preferences, setPreferences] = useState({
    pageSize: MIGRATION_ENGAGEMENT_TABLE_DEFAULT_PAGE_SIZE,
    visibleContent: defaultColumnsToDisplay,
  });

  const { items, actions, collectionProps, filterProps, paginationProps } =
    useCollection(migrationEngagements, {
      filtering: {
        empty: <TableEmptyState resourceName="Program migration engagements" />,
        noMatch: (
          <TableNoMatchState
            onClearFilter={() => actions.setFiltering("")}
            resourceName="Program migration engagements"
          />
        ),
        filteringFunction: (item, filteringText) => {
          return filterItem({
            item,
            filteringText,
          });
        },
      },
      pagination: { pageSize: preferences.pageSize },
      sorting: {},
      selection: {},
    });

  const onChangeHandler = (selectedEngagements) => {
    migrationEngagementProps.setSelectedMigrationEngagementItems(
      selectedEngagements
    );
    if (selectedEngagements.length > 0 && selectedEngagements.length < 3) {
      migrationEngagementProps.setSplitPanelData(
        <MigrationEngagementsSplitPanelContainer
          selectedItems={selectedEngagements}
        ></MigrationEngagementsSplitPanelContainer>
      );
    } else {
      migrationEngagementProps.setSplitPanelData(null);
    }
  };

  return (
    <Table
      {...collectionProps}
      columnDefinitions={columnDefinitions}
      visibleColumns={preferences.visibleContent}
      items={items}
      selectionType="multi"
      filter={
        <Grid gridDefinition={[{ colspan: 10 }]}>
          <TextFilter
            {...filterProps}
            filteringPlaceholder="Find program migration engagements"
          />
        </Grid>
      }
      header={
        <Header
          counter={getHeaderCounterText(
            migrationEngagements,
            migrationEngagementProps.selectedMigrationEngagementItems
          )}
          info={<Link variant="info">Info</Link>}
          actions={
            <MigrationEngagementHeaderAction
              fundingData={fundingData}
              page={page}
            ></MigrationEngagementHeaderAction>
          }
        >
          Migration program engagements
        </Header>
      }
      onSelectionChange={({ detail }) => {
        onChangeHandler(detail.selectedItems);
      }}
      preferences={
        <CollectionPreferences
          {...collectionPreferencesProps}
          preferences={preferences}
          onConfirm={({ detail }) => setPreferences(detail)}
        />
      }
      selectedItems={migrationEngagementProps.selectedMigrationEngagementItems}
      pagination={<Pagination {...paginationProps} />}
    />
  );
};

InternalMigrationEngagementReviewSectionTable.propTypes = {
  fundingData: PropTypes.object,
  page: PropTypes.string,
  migrationEngagementProps: PropTypes.object,
};

export default InternalMigrationEngagementReviewSectionTable;
