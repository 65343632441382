import React, { useState } from "react";
import PropTypes from "prop-types";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import { Box, SpaceBetween } from "@amzn/awsui-components-react";
import Button from "@amzn/awsui-components-react/polaris/button";
import {
  ERROR_TYPE,
  hasError,
  retryApiCall,
} from "../../../../../../../shared/util/services/data/DataService";
import { addSelfServiceEntityEvent } from "../../../../../../util/services/data/InternalDataService";
import {
  setErrorMessage,
  setSuccessMessage,
} from "../../../../../../../shared/util/common/helper";
import DeployModalForm from "./ModalForms/DeployModalForm";
import { ConstructRollbackEvents } from "../../../../JsonPatch/jsonpatch";
import { Action } from "../../../../constant";
import {
  itemInputValidation,
  modalErrorMessage,
  getItemSuccessNotificationContent,
} from "./constant";

const DeployModal = ({
  refresh,
  isModalVisible,
  updateModalVisibility,
  entityDetails,
  entityEvents,
  currentEntityVersion,
  setNotifications,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errors, updateErrors] = useState({});
  const [item, setItem] = useState({});

  const validateEntityDeployment = () => {
    return validateDeployConfirmation() && validateReason();
  };

  const validateDeployConfirmation = () => {
    if (item.deploy !== Action.DEPLOY) {
      updateErrors({
        ...errors,
        deploy: modalErrorMessage.deploy,
      });
      return false;
    }
    return true;
  };

  const validateReason = () => {
    // reason is an optional field. if reason is entered, validates if it's characters are within the limit
    return (
      !item.reason || item.reason?.length <= itemInputValidation.reason.limit
    );
  };

  const deploy = async () => {
    const isValidEntityDeployment = validateEntityDeployment();
    if (isValidEntityDeployment) {
      setIsLoading(true);

      try {
        const payload = ConstructRollbackEvents(
          entityDetails,
          entityEvents,
          currentEntityVersion,
          item.reason
        );
        const response = await retryApiCall({
          callApi: addSelfServiceEntityEvent({
            entityType: entityDetails.entityType,
            body: payload,
          }),
        });

        if (
          response &&
          hasError(response) &&
          response.errorType === ERROR_TYPE.BANNER
        ) {
          setErrorMessage({
            setNotificationsItems: setNotifications,
            content: response.message,
            status: response.status,
          });
          setIsLoading(false);
          updateModalVisibility(false);
          return;
        }
        setIsLoading(false);
        updateModalVisibility(false);
      } catch (e) {
        console.error(e);
        setErrorMessage({
          setNotificationsItems: setNotifications,
        });
        setIsLoading(false);
        updateModalVisibility(false);
      }
      setSuccessMessage(
        setNotifications,
        "Submitted",
        getItemSuccessNotificationContent["deploy"]
      );
      refresh();
    }
  };

  return (
    <Modal
      onDismiss={() => {
        setItem({});
        updateErrors({});
        updateModalVisibility(false);
      }}
      visible={isModalVisible}
      closeAriaLabel="Close modal"
      size="large"
      header="Deploy Entity"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              onClick={() => {
                setItem({});
                updateErrors({});
                updateModalVisibility(false);
              }}
            >
              Cancel
            </Button>
            <Button
              loading={isLoading}
              variant="primary"
              onClick={async () => {
                await deploy();
              }}
            >
              Deploy
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <DeployModalForm
        entityDetails={entityDetails}
        currentEntityVersion={currentEntityVersion}
        item={item}
        setItem={setItem}
        errors={errors}
        updateErrors={updateErrors}
      />
    </Modal>
  );
};

DeployModal.propTypes = {
  refresh: PropTypes.func,
  isModalVisible: PropTypes.bool,
  updateModalVisibility: PropTypes.func,
  entityDetails: PropTypes.object,
  entityEvents: PropTypes.array,
  currentEntityVersion: PropTypes.number,
  setNotifications: PropTypes.func,
};

export default DeployModal;
