import { generateFundingLinkForExternal } from "../../../../shared/util/common/util";

export const columnDefinitions = [
  {
    id: "fundRequestId",
    header: "Fund Request ID",
    cell: (e) =>
      generateFundingLinkForExternal({
        fundRequestId: e.fundRequestId,
        stage: e.stage,
        status: e.status,
      }),
    sortingField: "fundRequestId",
  },
  {
    id: "awsPromoCode",
    header: "AWS Promo Code",
    cell: (e) => e.awsPromoCode,
    sortingField: "awsPromoCode",
  },
  {
    id: "amount",
    header: "Amount",
    cell: (e) => e.amount,
    sortingField: "amount",
  },
  {
    id: "codeRedeemed",
    header: "Code Redeemed",
    cell: (e) => e.codeRedeemed,
    sortingField: "codeRedeemed",
  },
  {
    id: "awsAccountId",
    header: "AWS Account ID",
    cell: (e) => e.awsAccountId,
    sortingField: "awsAccountId",
  },
  {
    id: "issuanceDate",
    header: "Issuance Date",
    cell: (e) => e.issuanceDate,
    sortingField: "issuanceDate",
  },
  {
    id: "expirationDate",
    header: "Expiration Date",
    cell: (e) => e.expirationDate,
    sortingField: "expirationDate",
  },
  {
    id: "template",
    header: "Template",
    cell: (e) => e.template,
    sortingField: "template",
  },
  {
    id: "program",
    header: "Program",
    cell: (e) => e.program,
    sortingField: "program",
  },
];

export const searchableColumns = [
  "fundRequestId",
  "program",
  "activityName",
  "stage",
  "status",
  "documents",
  "actionRequired",
];

export const defaultTemplate = {
  label: "Any Template",
  value: "Any Template",
};

export const defaultProgram = {
  label: "Any Program",
  value: "Any Program",
};
