import React from "react";

import { IErrorAlertProps } from "shared/programs/migration-acceleration-program/2024/fund-request/types/ErrorAlert";
import SystemErrorAlert from "shared/programs/migration-acceleration-program/2024/fund-request/components/common/SystemErrorAlert";

const MIGRATION_SCOPE_CHECKLIST_ERROR: string =
  "missing: Migration Assessment Scope Checklist.";
const AWS_CALCULATOR_ERROR: string = "missing: AWS Pricing Calculator.";
const VMWARE_ASSESSMENT_ERROR: string = "missing: Migration Assessment Report.";
const PARTNER_SCOPE_CHECKLIST_ERROR_MESSAGE =
  "Partner scope checklist must be included in supporting documents.";
const AWS_CALCULATOR_ERROR_MESSAGE: string =
  "At least one AWS Pricing Calculator must be included in supporting documents.";
const VMWARE_ASSESSMENT_ERROR_MESSAGE: string =
  "Migration Assessment Report must be included in supporting documents.";

const SYSTEM_ERROR_MAPPING = {
  [MIGRATION_SCOPE_CHECKLIST_ERROR]: PARTNER_SCOPE_CHECKLIST_ERROR_MESSAGE,
  [AWS_CALCULATOR_ERROR]: AWS_CALCULATOR_ERROR_MESSAGE,
  [VMWARE_ASSESSMENT_ERROR]: VMWARE_ASSESSMENT_ERROR_MESSAGE,
};

const ReviewErrorAlert = ({ isVisible, error }: IErrorAlertProps) => {
  let errorMessage = error;
  if (error && Object.keys(SYSTEM_ERROR_MAPPING).includes(error)) {
    errorMessage = SYSTEM_ERROR_MAPPING[error];
  }
  return <SystemErrorAlert isVisible={isVisible} error={errorMessage} />;
};

export default ReviewErrorAlert;
