import { MIGRATION_PHASE } from "./migrationPhaseType";
import { isFeatureEnabled } from "../services/features/FeatureFlagsService";
import { FEATURE_FLAG_ENUMS } from "./featureFlagValues";

export const mdfActivityType = [
  {
    label: "APN AWS Led Joint Campaign (Invite Only)",
    value: "APN AWS Led Joint Campaign (Invite Only)",
    disabled: false,
  },
  {
    label: "APN Customer Focused Webinars",
    value: "APN Customer Focused Webinars",
    disabled: false,
  },
  {
    label: "APN Display Advertising and Search Marketing",
    value: "APN Display Advertising and Search Marketing",
    disabled: false,
  },
  {
    label: "APN Email Campaign",
    value: "APN Email Campaign",
    disabled: false,
  },
  {
    label: "APN Industry Conference Event",
    value: "APN Industry Conference Event",
    disabled: false,
  },
  {
    label: "APN List Purchase",
    value: "APN List Purchase",
    disabled: false,
  },
  {
    label: "APN Partner Led Customer Event",
    value: "APN Partner Led Customer Event",
    disabled: false,
  },
  {
    label: "APN Partner Produced Case Study(Written/Video)",
    value: "APN Partner Produced Case Study(Written/Video)",
    disabled: false,
  },
  {
    label: "APN Partner Sales Content/Literature",
    value: "APN Partner Sales Content/Literature",
    disabled: false,
  },
  {
    label: "APN Partner Sales Kick Off Event(SKO)",
    value: "APN Partner Sales Kick Off Event(SKO)",
    disabled: false,
  },
  {
    label: "APN Telemarketing Campaigns",
    value: "APN Telemarketing Campaigns",
    disabled: false,
  },
  {
    label: "APN Other",
    value: "APN Other",
    disabled: false,
  },
  {
    label: "AWS Led Joint Campaign (Invite Only)",
    value: "AWS Led Joint Campaign (Invite Only)",
    disabled: true,
  },
  {
    label: "Customer Focused Webinars",
    value: "Customer Focused Webinars",
    disabled: true,
  },
  {
    label: "Display Advertising and Search Marketing",
    value: "Display Advertising and Search Marketing",
    disabled: true,
  },
  { label: "Email Campaign", value: "Email Campaign", disabled: true },
  {
    label: "Industry Conference Event",
    value: "Industry Conference Event",
    disabled: true,
  },
  { label: "List Purchase", value: "List Purchase", disabled: true },
  {
    label: "Marketplace Content Development",
    value: "Marketplace Content Development",
    disabled: false,
  },
  {
    label: "Marketplace Paid Media",
    value: "Marketplace Paid Media",
    disabled: false,
  },
  {
    label: "Marketplace Email",
    value: "Marketplace Email",
    disabled: false,
  },
  {
    label: "Marketplace Webinar/Online Events",
    value: "Marketplace Webinar/Online Events",
    disabled: false,
  },
  {
    label: "Marketplace Industry Conference(Non AWS)",
    value: "Marketplace Industry Conference(Non AWS)",
    disabled: false,
  },
  {
    label: "Marketplace In Person Events",
    value: "Marketplace In Person Events",
    disabled: false,
  },
  {
    label: "Marketplace Partner Led Customer Event",
    value: "Marketplace Partner Led Customer Event",
    disabled: false,
  },
  {
    label: "Marketplace Social Media",
    value: "Marketplace Social Media",
    disabled: false,
  },
  {
    label: "Marketplace Telemarketing",
    value: "Marketplace Telemarketing",
    disabled: false,
  },
  {
    label: "Marketplace Demo/Workshops",
    value: "Marketplace Demo/Workshops",
    disabled: false,
  },
  {
    label: "Marketplace Video",
    value: "Marketplace Video",
    disabled: false,
  },
  {
    label: "Marketplace Content Syndication",
    value: "Marketplace Content Syndication",
    disabled: false,
  },
  {
    label: "Marketplace Web/Landing Page",
    value: "Marketplace Web/Landing Page",
    disabled: false,
  },
  {
    label: "Marketplace List Purchase",
    value: "Marketplace List Purchase",
    disabled: false,
  },
  {
    label: "Marketplace Multi-Channel(multiple activities)",
    value: "Marketplace Multi-Channel(multiple activities)",
    disabled: false,
  },
  {
    label: "Marketplace Other",
    value: "Marketplace Other",
    disabled: false,
  },
  {
    label: "Partner Led Customer Event",
    value: "Partner Led Customer Event",
    disabled: true,
  },
  {
    label: "Partner Produced Case Study (Written and/or Video)",
    value: "Partner Produced Case Study (Written and/or Video)",
    disabled: true,
  },
  {
    label: "Partner Sales Content / Literature",
    value: "Partner Sales Content / Literature",
    disabled: true,
  },
  {
    label: "Partner Sales Kick Off Event (SKO)",
    value: "Partner Sales Kick Off Event (SKO)",
    disabled: true,
  },
  {
    label: "Telemarketing Campaigns",
    value: "Telemarketing Campaigns",
    disabled: true,
  },
];

export const mdfCreditActivityType = [
  {
    label: "APN Credits",
    value: "APN Credits",
    disabled: false,
  },
  {
    label: "Customer Focused Webinars",
    value: "Customer Focused Webinars",
    disabled: true,
  },
  {
    label: "Industry Conference Event",
    value: "Industry Conference Event",
    disabled: true,
  },
  {
    label: "Marketplace Credits",
    value: "Marketplace Credits",
    disabled: false,
  },
  {
    label: "Partner Led Customer Event",
    value: "Partner Led Customer Event",
    disabled: true,
  },
  { label: "Free Trial", value: "Free Trial", disabled: true },
  { label: "No Credit Activity", value: "No Credit Activity", disabled: false },
];

export const sandboxDesignWinActivity = [
  { label: "New product/platform", value: "New product/platform" },
  {
    label: "New solution/application",
    value: "New solution/application",
  },
  {
    label: "AWS solution integration",
    value: "AWS solution integration",
  },
  {
    label: "Solution upgrade/improvement",
    value: "Solution upgrade/improvement",
  },
  { label: "AWS service testing", value: "AWS service testing" },
];

export const USStates = [
  { label: "AK", value: "AK" },
  { label: "AL", value: "AL" },
  { label: "AR", value: "AR" },
  { label: "AZ", value: "AZ" },
  { label: "CA", value: "CA" },
  { label: "CO", value: "CO" },
  { label: "CT", value: "CT" },
  { label: "DC", value: "DC" },
  { label: "DE", value: "DE" },
  { label: "FL", value: "FL" },
  { label: "GA", value: "GA" },
  { label: "HI", value: "HI" },
  { label: "ID", value: "ID" },
  { label: "IL", value: "IL" },
  { label: "IN", value: "IN" },
  { label: "KS", value: "KS" },
  { label: "KY", value: "KY" },
  { label: "LA", value: "LA" },
  { label: "MA", value: "MA" },
  { label: "MD", value: "MD" },
  { label: "ME", value: "ME" },
  { label: "MI", value: "MI" },
  { label: "MN", value: "MN" },
  { label: "MO", value: "MO" },
  { label: "MS", value: "MS" },
  { label: "MT", value: "MT" },
  { label: "NC", value: "NC" },
  { label: "ND", value: "ND" },
  { label: "NE", value: "NE" },
  { label: "NH", value: "NH" },
  { label: "NJ", value: "NJ" },
  { label: "NM", value: "NM" },
  { label: "NV", value: "NV" },
  { label: "NY", value: "NY" },
  { label: "OH", value: "OH" },
  { label: "OK", value: "OK" },
  { label: "OR", value: "OR" },
  { label: "PA", value: "PA" },
  { label: "RI", value: "RI" },
  { label: "SC", value: "SC" },
  { label: "SD", value: "SD" },
  { label: "TN", value: "TN" },
  { label: "TX", value: "TX" },
  { label: "UT", value: "UT" },
  { label: "VA", value: "VA" },
  { label: "VT", value: "VT" },
  { label: "WA", value: "WA" },
  { label: "WI", value: "WI" },
  { label: "WV", value: "WV" },
  { label: "WY", value: "WY" },
];

export const ToolsUsedForGeneratingMigrationReport = [
  {
    label: "Cloudamize",
    value: "Cloudamize",
  },
  {
    label: "Rivermeadow",
    value: "Rivermeadow",
  },
  {
    label: "Matilda",
    value: "Matilda",
  },
  {
    label: "Monty cloud",
    value: "Monty cloud",
  },
  {
    label: "Trianz",
    value: "Trianz",
  },
  {
    label: "CAST",
    value: "CAST",
  },
  {
    label: "CloudHedge",
    value: "CloudHedge",
  },
  {
    label: "Other",
    value: "Other",
  },
];

export const locationSlashStateActivityExecuted = [
  {
    label: "All Approved US States",
    value: "All Approved US States",
  },
  ...USStates,
];

export const projectType = [{ label: "Migration", value: "Migration" }];

export const customerAlsoConsidering = [
  { label: "No Competition", value: "No Competition" },
  { label: "AliCloud", value: "AliCloud" },
  { label: "Google Cloud Platform", value: "Google Cloud Platform" },
  { label: "IBM Softlayer", value: "IBM Softlayer" },
  { label: "Microsoft Azure", value: "Microsoft Azure" },
  { label: "Rackspace", value: "Rackspace" },
  { label: "VMWare", value: "VMWare" },
  { label: "Co-Location", value: "Co-Location" },
  { label: "On-Premise", value: "On-Premise" },
  { label: "Other", value: "Other" },
];

export const migrationPhase = [
  {
    label: MIGRATION_PHASE.ASSES_BUSINESS_CASE,
    value: MIGRATION_PHASE.ASSES_BUSINESS_CASE,
  },
  { label: MIGRATION_PHASE.MOBILIZE, value: MIGRATION_PHASE.MOBILIZE },
  {
    label: MIGRATION_PHASE.MIGRATE_MODERNIZE,
    value: MIGRATION_PHASE.MIGRATE_MODERNIZE,
  },
];

export const map2024MigrationPhase = [
  { label: "ASSESS", value: "Assess" },
  { label: "MOBILIZE", value: "Mobilize" },
];

const expandSpecializedWorkloadsFlag = isFeatureEnabled(
  FEATURE_FLAG_ENUMS.EXPAND_SPECIALIZED_WORKLOADS_ENABLED
);

export const specializedWorkloads = [
  {
    label: "Windows",
    value: "Windows",
    disabled: false,
    validOnlyForMapLite: false,
  },
  {
    label: "SAP Applications",
    value: "SAP",
    disabled: false,
    validOnlyForMapLite: false,
  },
  {
    label: "Oracle Applications",
    value: "Oracle",
    disabled: false,
    validOnlyForMapLite: false,
  },
  {
    label: "Commercial Database",
    value: "Commercial Database",
    disabled: false,
    validOnlyForMapLite: false,
  },
  {
    label: "Commercial Analytics",
    value: "Commercial Analytics",
    disabled: false,
    validOnlyForMapLite: false,
  },
  {
    label: "Non-Commercial Database",
    value: "Non-Commercial Database",
    disabled: false,
    validOnlyForMapLite: false,
  },
  {
    label: "Non-Commercial Analytics",
    value: "Non-Commercial Analytics",
    disabled: false,
    validOnlyForMapLite: false,
  },
  {
    label: "DB/Analytics",
    value: "DB/Analytics",
    disabled: true,
    validOnlyForMapLite: false,
  },
  {
    label: "Commercial DB/Analytics",
    value: "Commercial DB/Analytics",
    disabled: true,
    validOnlyForMapLite: false,
  },
  {
    label: "Connect",
    value: "Connect",
    disabled: false,
    validOnlyForMapLite: false,
  },
  {
    label: "VMC",
    value: "VMC",
    disabled: !expandSpecializedWorkloadsFlag,
    validOnlyForMapLite: true,
  },
  {
    label: "Storage",
    value: "Storage",
    disabled: !expandSpecializedWorkloadsFlag,
    validOnlyForMapLite: true,
  },
  {
    label: "Mainframe",
    value: "Mainframe",
    disabled: !expandSpecializedWorkloadsFlag,
    validOnlyForMapLite: true,
  },
];

export const thirdPartyFunding = [
  { label: "Intel co-fund", value: "Intel co-fund" },
  { label: "NVIDIA co-fund", value: "NVIDIA co-fund" },
  { label: "AMD co-fund", value: "AMD co-fund" },
];

export const pifWorkloads = [
  { label: "Amazon connect", value: "Amazon connect" },
  {
    label: "Database and Analytics Migration and Modernization",
    value: "Database and Analytics Migration and Modernization",
  },
  { label: "DC Divest", value: "DC Divest" },
  { label: "Mainframe MAP", value: "Mainframe MAP" },
  {
    label: "SAP Migration and Modernization",
    value: "SAP Migration and Modernization",
  },
  {
    label: "Oracle Application Migration and Modernization",
    value: "Oracle Application Migration and Modernization",
  },
  { label: "Storage Migrations", value: "Storage Migrations" },
  { label: "VMware Migrations", value: "VMware Migrations" },
  {
    label: "Windows Migration and Modernization",
    value: "Windows Migration and Modernization",
  },
  { label: "Other", value: "Other" },
];
