import React, { useState } from "react";

import withAppLayout from "../../../shared/components/withAppLayout";
import SideNavigation from "../../components/common/SideNavigation";
import SelfServiceEntity from "../../components/SelfService/EntityPage/SelfServiceEntity";
import BreadcrumbGroup from "@amzn/awsui-components-react/polaris/breadcrumb-group";
import { useParams } from "react-router-dom";

const SelfServiceEntityPage = () => {
  const [notifications, setNotifications] = useState([]);
  const { entity } = useParams();

  const getBreadcrumbs = () => {
    const items = [
      { text: "Dashboard", href: "/dashboard" },
      { text: "Self Service", href: "/self-service" },
      { text: `${entity}` },
    ];
    return <BreadcrumbGroup items={items} />;
  };

  return withAppLayout({
    component: (
      <React.Fragment>
        <SelfServiceEntity setNotifications={setNotifications} />
      </React.Fragment>
    ),
    breadcrumbs: getBreadcrumbs(),
    navigationHide: false,
    navigation: <SideNavigation />,
    toolsHide: true,
    notificationsItems: notifications,
  })();
};

export default SelfServiceEntityPage;
