import React, { useEffect, useState } from "react";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import { Box, FormField, SpaceBetween } from "@amzn/awsui-components-react";
import Button from "@amzn/awsui-components-react/polaris/button";
import PropTypes from "prop-types";
import Select from "@amzn/awsui-components-react/polaris/select";
import Textarea from "@amzn/awsui-components-react/polaris/textarea";
import { AUDIT_CODE } from "../Header/util";
import { isFeatureEnabled } from "../../../../shared/util/services/features/FeatureFlagsService";
import { FEATURE_FLAG_ENUMS } from "../../../../shared/util/constants/featureFlagValues";
import { getSelfServiceOptions } from "../../../../shared/util/common/selfServiceUtil";

const AuditModal = ({
  isLoading,
  isVisible,
  submitAudit,
  updateVisibility,
}) => {
  const [auditCode, setAuditCode] = useState("");
  const [comment, setComment] = useState("");
  const [error, setNewError] = useState("");
  const [auditOptions, setAuditOptions] = useState([]);
  const [optionsStatus, setOptionsStatus] = useState("loading");

  useEffect(() => {
    const featureFlag = async () => {
      const selfServiceAuditCodesFlag = await isFeatureEnabled(
        FEATURE_FLAG_ENUMS.SELF_SERVICE_AUDIT_CODE
      );
      if (!ignore) {
        let options;
        if (selfServiceAuditCodesFlag) {
          options = await getSelfServiceOptions("AuditCode");
        } else {
          options = Object.values(AUDIT_CODE)?.map((code) => {
            return { label: code, value: code };
          });
        }
        setAuditOptions(options);
        options?.length
          ? setOptionsStatus("finished")
          : setOptionsStatus("error");
      }
    };
    let ignore = false;
    featureFlag();
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <Modal
      id="audit-modal"
      onDismiss={() => updateVisibility("audit", false)}
      visible={isVisible}
      closeAriaLabel="Close modal"
      size="large"
      header="Audit Fund Request"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              onClick={() => {
                updateVisibility("audit", false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              disabled={!auditCode || error}
              loading={isLoading}
              onClick={async () => {
                await submitAudit({
                  auditCode,
                  comment,
                });
              }}
            >
              Submit
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <SpaceBetween size="l">
        <FormField label="Please select outcome of audit.">
          <Select
            id="auditCode"
            selectedOption={{
              label: auditCode,
              value: auditCode,
            }}
            className="step-details-field"
            placeholder="Choose an option"
            filteringType="auto"
            onChange={({ detail }) => {
              console.log(detail.selectedOption.value);
              setAuditCode(detail.selectedOption.value);
              setNewError("");
            }}
            options={auditOptions}
            errorText="Error fetching Audit Codes"
            loadingText="Loading Audit Codes"
            statusType={optionsStatus}
          />
        </FormField>

        <FormField label="Please enter an internal comment.">
          <Textarea
            id="internal-comment"
            value={comment}
            onChange={({ detail }) => {
              setComment(detail.value);
              setNewError("");
            }}
          />
        </FormField>
      </SpaceBetween>
    </Modal>
  );
};

AuditModal.propTypes = {
  isLoading: PropTypes.bool,
  isVisible: PropTypes.bool,
  submitAudit: PropTypes.func,
  updateVisibility: PropTypes.func,
};

export default AuditModal;
