import React from "react";
import { IGenericObject } from "shared/programs/migration-acceleration-program/2024/fund-request/types/CommonTypes";
import Container from "@amzn/awsui-components-react/polaris/container";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import { ColumnLayout } from "@amzn/awsui-components-react";
import ValueWithLabel from "shared/programs/migration-acceleration-program/2024/fund-request/components/fields/ValueWithLabel";
import { display } from "shared/util/common/display";
import Header from "@amzn/awsui-components-react/polaris/header";

interface PartnerDetailsProps {
  data: IGenericObject;
  loading: boolean;
}

const PartnerDetails = ({ data }: PartnerDetailsProps) => {
  const partnerDetails = data?.partnerDetails;
  return (
    <Container header={<Header variant="h2">Partner details</Header>}>
      <ColumnLayout columns={3} variant="text-grid">
        <SpaceBetween size="l">
          <ValueWithLabel label="APN partner">
            {display({ data: partnerDetails, key: "apnPartner" })}
          </ValueWithLabel>
          <ValueWithLabel label="Partner vendor ID">
            {display({ data: partnerDetails, key: "vendorId" })}
          </ValueWithLabel>
          <ValueWithLabel label="Strategic collaboration agreement">
            {display({
              data: partnerDetails,
              key: "strategicCollaborationAgreement",
            })}
          </ValueWithLabel>
          <ValueWithLabel label="Partner contact email">
            {display({ data: partnerDetails, key: "contactEmail" })}
          </ValueWithLabel>
        </SpaceBetween>

        <SpaceBetween size="l">
          <ValueWithLabel label="APN account GEO">
            {display({
              data: partnerDetails,
              key: "accountGeo",
            })}
          </ValueWithLabel>
          <ValueWithLabel label="SPMS ID">
            {display({ data: partnerDetails, key: "spmsId" })}
          </ValueWithLabel>
          <ValueWithLabel label="Funding agreement type">
            {display({ data: partnerDetails, key: "fundingAgreementType" })}
          </ValueWithLabel>
          <ValueWithLabel label="Partner contact title">
            {display({ data: partnerDetails, key: "contactTitle" })}
          </ValueWithLabel>
        </SpaceBetween>

        <SpaceBetween size="l">
          <ValueWithLabel label="APN account sub-region">
            {display({
              data: partnerDetails,
              key: "accountSubRegion",
            })}
          </ValueWithLabel>
          <ValueWithLabel label="GSI account">
            {display({
              data: partnerDetails,
              key: "gsiAccount",
            })}
          </ValueWithLabel>
          <ValueWithLabel label="APN partner contact">
            {display({
              data: partnerDetails,
              key: "contact",
            })}
          </ValueWithLabel>
          <ValueWithLabel label="Partner contact phone">
            {display({
              data: partnerDetails,
              key: "contactPhone",
            })}
          </ValueWithLabel>
        </SpaceBetween>
      </ColumnLayout>
    </Container>
  );
};

export default PartnerDetails;
