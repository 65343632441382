import { statusBadge } from "../../../../shared/components/common/StatusBadge";
import { generateFundingLinkForExternal } from "../../../../shared/util/common/util";

export const columnDefinitions = [
  {
    id: "fundRequestId",
    header: "Fund Request ID",
    cell: (e) =>
      generateFundingLinkForExternal({
        fundRequestId: e.fundRequestId,
        stage: e.stage,
        status: e.status,
      }),
    sortingField: "fundRequestId",
  },
  {
    id: "template",
    header: "Template",
    cell: (e) => e.template,
    sortingField: "template",
  },
  {
    id: "program",
    header: "Program",
    cell: (e) => e.program,
    sortingField: "program",
  },
  {
    id: "activityName",
    header: "Activity Name",
    cell: (e) => e.activityName,
    sortingField: "activityName",
  },
  {
    id: "stage",
    header: "Stage",
    cell: (e) => e.stage,
    sortingField: "stage",
  },
  {
    id: "status",
    header: "Status",
    cell: (e) => statusBadge(e.status),
    sortingField: "status",
  },
  {
    id: "poNumber",
    header: "PO Number",
    cell: (e) => e.poNumber,
    sortingField: "poNumber",
  },
  {
    id: "budgetYear",
    header: "Budget Year",
    cell: (e) => e.budgetYear,
    sortingField: "budgetYear",
  },
  {
    id: "fundingExpirationDate",
    header: "Funding Expiration Date",
    cell: (e) => e.fundingExpirationDate,
    sortingField: "fundingExpirationDate",
  },
  {
    id: "opportunityId",
    header: "Opportunity Id",
    cell: (e) => e.opportunityId,
    sortingField: "opportunityId",
  },
];

export const searchableColumns = [
  "program",
  "activityName",
  "stage",
  "status",
  "documents",
  "fundRequestId",
  "opportunityId",
];

export const defaultTemplate = {
  label: "Any Template",
  value: "Any Template",
};

export const defaultProgram = {
  label: "Any Program",
  value: "Any Program",
};

export const defaultStage = {
  value: "Any Stage",
  label: "Any Stage",
};

export const defaultStatus = {
  value: "Any Status",
  label: "Any Status",
};

export const defaultBudgetYear = {
  label: "Any Budget Year",
  value: "Any Budget Year",
};
