import { FEATURE_FLAG_ENUMS } from "shared/util/constants/featureFlagValues";

const BULK_ACTION_TYPES = Object.freeze({
  EXPIRE_FR: {
    label: "Expiration",
    value: "expire_fund_request",
    flag: FEATURE_FLAG_ENUMS.BULK_ACTIONS_EXPIRE,
  },
  UPDATE_CASH_CLAIM_INVOICE: {
    label: "Update Cash Claim Invoice",
    value: "update_cash_claim_invoice",
    flag: FEATURE_FLAG_ENUMS.BULK_ACTIONS_UPDATE_INVOICE,
  },
  MAP_UNLOCK: {
    label: "Unlock Claim",
    value: "unlock_claim",
    flag: FEATURE_FLAG_ENUMS.BULK_ACTIONS_MAP_UNLOCK,
  },
});

export const constructBulkActionsFFQueries = () => {
  const flags = Object.values(BULK_ACTION_TYPES).map((obj) => obj.flag);
  const queries = flags.map((ff) => {
    return { queryKey: ["features", ff] };
  });
  return queries;
};

export const parseBulkActionValuesFromQueryResults = (queryResults) => {
  const bulkActionTypes = {};
  const bulkActionValues = Object.entries(BULK_ACTION_TYPES);
  for (let [i, queryResult] of queryResults.entries()) {
    if (
      queryResult.isLoading ||
      queryResult.data == null ||
      !queryResult.data.enabled
    ) {
      continue;
    }
    const [key, obj] = bulkActionValues[i];
    bulkActionTypes[key] = {
      label: obj.label,
      value: obj.value,
    };
  }

  return Object.freeze(bulkActionTypes);
};

export const getBulkActionLabelByValue = (value) => {
  let label = "";
  for (const type_dict of Object.values(BULK_ACTION_TYPES)) {
    if (type_dict.value === value) {
      label = type_dict.label;
      break;
    }
  }
  return label;
};

export const BULK_ACTION_TYPES_TO_COLUMNS = {
  expire_fund_request: ["Fund Request ID"],
  update_cash_claim_invoice: [
    "Fund Request ID",
    "Fund Claim ID",
    "Invoice Currency",
    "Invoice Amount (Local Currency)",
    "Scheduled Paid Date (month/day/year)",
    "Description",
  ],
  unlock_claim: [
    "Fund Request ID",
    "Fund Claim ID",
    "Realized Revenue Amount (USD)",
    "Comment",
  ],
};
