import React from "react";
import PropTypes from "prop-types";
import { useCollection } from "@amzn/awsui-collection-hooks";
import Grid from "@amzn/awsui-components-react/polaris/grid";
import Table from "@amzn/awsui-components-react/polaris/table";
import Pagination from "@amzn/awsui-components-react/polaris/pagination";
import Input from "@amzn/awsui-components-react/polaris/input";
import { columnDefinitions } from "./config";
import { filterItem, clearFilter } from "./util";
import TableNoMatchState from "../../../common/TableNoMatchState";
import TableEmptyState from "../../../common/TableEmptyState";
import TableHeader from "./TableHeader";

const TableComponent = ({
  data,
  setUploadComponentVisible,
  isOpenEnd,
  isTableLoading,
  refresh,
}) => {
  const pageSize = 10;
  const { items, actions, collectionProps, filterProps, paginationProps } =
    useCollection(data, {
      filtering: {
        empty: <TableEmptyState resourceName="resources" />,
        noMatch: (
          <TableNoMatchState onClearFilter={() => clearFilter(actions)} />
        ),
        filteringFunction: (item, filteringText) => {
          return filterItem(item, filteringText);
        },
      },
      pagination: { pageSize },
      sorting: {},
    });

  return (
    <Table
      {...collectionProps}
      columnDefinitions={columnDefinitions}
      items={items}
      loadingText="Loading resources"
      filter={
        <Grid gridDefinition={[{ colspan: 4 }]}>
          <Input
            type="search"
            value={filterProps.filteringText}
            onChange={(event) => {
              actions.setFiltering(event.detail.value);
            }}
            placeholder="Find credits"
            ariaDescribedby={null}
          />
        </Grid>
      }
      header={
        <TableHeader
          itemsLength={items.length}
          allItemsLength={data.length}
          setUploadComponentVisible={setUploadComponentVisible}
          isTableLoading={isTableLoading}
          refresh={refresh}
        />
      }
      loading={isTableLoading}
      pagination={<Pagination {...paginationProps} openEnd={isOpenEnd} />}
    />
  );
};

TableComponent.propTypes = {
  data: PropTypes.array,
  setUploadComponentVisible: PropTypes.func,
  isTableLoading: PropTypes.bool,
  isOpenEnd: PropTypes.bool,
  refresh: PropTypes.func,
};

export default TableComponent;
