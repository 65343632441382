import { PatchOperations } from "./constant";
import { Item, ROOT_ENTITY_NAME } from "../constant";

const splitJsonPatchPath = (patch) => patch.path.split("/");
const defaultValue = "-";

const getName = (patch) => {
  const [, , , itemAttribute] = splitJsonPatchPath(patch);
  if (
    patch.op === PatchOperations.REPLACE ||
    (patch.op === PatchOperations.ADD && itemAttribute)
  ) {
    if (itemAttribute === Item.NAME) return patch.value;
    else return defaultValue;
  } else {
    if (patch.value) return patch.value.name;
    else return defaultValue;
  }
};

const getDescription = (patch) => {
  const [, , , itemAttribute] = splitJsonPatchPath(patch);
  if (
    patch.op === PatchOperations.REPLACE ||
    (patch.op === PatchOperations.ADD && itemAttribute)
  ) {
    if (itemAttribute === Item.DESCRIPTION) return patch.value;
    else return defaultValue;
  } else {
    if (patch.value) return patch.value.description;
    else return defaultValue;
  }
};

const getPermissions = (patch) => {
  const [, , , itemAttribute] = splitJsonPatchPath(patch);
  if (
    patch.op === PatchOperations.REPLACE ||
    (patch.op === PatchOperations.ADD && itemAttribute)
  ) {
    if (itemAttribute === Item.PERMISSIONS) return patch.value;
    else return defaultValue;
  } else {
    if (patch.value) return patch.value.permissions;
    else return defaultValue;
  }
};

export const getHistoryData = (entityType, event) => {
  let historyData = [];
  event.patch.patches.forEach((patch) => {
    const [, , pathIndex] = splitJsonPatchPath(patch);
    let data = {};
    if (entityType === ROOT_ENTITY_NAME) {
      data = {
        sequenceNumber: event.sequenceNumber,
        operation: patch.op,
        index: patch.path,
        name: getName(patch),
        value: getDescription(patch),
        permissions: getPermissions(patch),
        date: event.createdAt,
        editor: event.editor,
        reason: event.reason,
      };
    } else {
      data = {
        sequenceNumber: event.sequenceNumber,
        operation: patch.op,
        index: pathIndex,
        name: getName(patch),
        value: getDescription(patch),
        date: event.createdAt,
        editor: event.editor,
        reason: event.reason,
      };
    }
    historyData.push(data);
  });

  return historyData;
};
