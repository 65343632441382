import React, { ReactElement, ReactNode, useState } from "react";

export type SplitPanelContextType = {
  panel?: ReactElement | null;
  isOpen: boolean;
  setPanel: (panel: ReactElement | null) => void;
  setIsOpen: (value: boolean) => void;
};

export const SplitPanelContext = React.createContext<SplitPanelContextType>(
  {} as SplitPanelContextType
);

const SplitPanelProvider = ({ children }: { children: ReactNode }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [panel, setPanel] = useState<ReactElement | null>(null);

  const updatePanel = (children: unknown) => {
    if (!children) setPanel(null);

    setIsOpen(true);
    setPanel(children as ReactElement);
  };

  return (
    <SplitPanelContext.Provider
      value={{ isOpen, setIsOpen, panel, setPanel: updatePanel }}
    >
      {children}
    </SplitPanelContext.Provider>
  );
};

export default SplitPanelProvider;
