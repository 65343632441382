import React, { ReactNode, useEffect, useState } from "react";
import Input from "@amzn/awsui-components-react/polaris/input";
import Textarea from "@amzn/awsui-components-react/polaris/textarea";
import Select from "@amzn/awsui-components-react/polaris/select";
import DatePicker from "@amzn/awsui-components-react/polaris/date-picker";
import { CopyToClipboard } from "@amzn/awsui-components-react/polaris";
import {
  SelectOptionType,
  useSelectOptions,
} from "shared/programs/migration-acceleration-program/2024/fund-request/hooks/useSelectOptions";
import Multiselect from "@amzn/awsui-components-react/polaris/multiselect";
import { OptionDefinition } from "@amzn/awsui-components-react/polaris/internal/components/option/interfaces";
import Checkbox from "@amzn/awsui-components-react/polaris/checkbox";

export enum FieldType {
  Input = "Input",
  Textarea = "Textarea",
  DatePicker = "DatePicker",
  Select = "Select",
  MultiSelect = "MultiSelect",
  ReadOnly = "ReadOnly",
  CopyToClipboard = "CopyToClipboard",
  Checkbox = "Checkbox",
}

interface ISelectOption {
  label: string;
  value: string;
}

export interface IOptions {
  [key: string]: ISelectOption[];
}

const SelectWrapperComponent = ({
  value,
  placeholder,
  onChange,
  selectOptionType,
  disabled,
}: {
  value: string;
  keyName: string;
  placeholder?: string;
  selectOptionType?: SelectOptionType;
  disabled: boolean;
  onChange: ({
    detail,
  }: {
    detail: { [key: string]: string | undefined };
  }) => void;
}) => {
  const { selectOptions, isOptionsLoading } = useSelectOptions({
    type: selectOptionType,
  });

  const selectedOption =
    selectOptions.find((option) => option.value === value) || null;

  return (
    <Select
      options={selectOptions}
      selectedOption={selectedOption}
      placeholder={placeholder}
      loadingText={"Loading selection options"}
      statusType={isOptionsLoading ? "loading" : "finished"}
      disabled={disabled}
      onChange={({ detail }) => {
        const { selectedOption } = detail;
        const value = selectedOption.value;

        onChange({ detail: { value } });
      }}
      filteringType="auto"
    />
  );
};
const MultiselectWrapperComponent = ({
  value,
  disabled,
  placeholder,
  onChange,
  selectOptionType,
}: {
  value: string;
  disabled: boolean;
  keyName: string;
  placeholder?: string;
  selectOptionType?: SelectOptionType;
  onChange: ({
    detail,
  }: {
    detail: { [key: string]: string | undefined };
  }) => void;
}) => {
  let selectedOption: OptionDefinition[] = [];

  if (value) {
    selectedOption = value.split(",").map((option) => ({
      label: option,
      value: option,
    }));
  }

  const { selectOptions, isOptionsLoading } = useSelectOptions({
    type: selectOptionType,
  });

  return (
    <Multiselect
      options={selectOptions}
      selectedOptions={selectedOption}
      placeholder={placeholder}
      loadingText={"Loading selection options"}
      statusType={isOptionsLoading ? "loading" : "finished"}
      disabled={disabled}
      onChange={({ detail }) => {
        const value = detail.selectedOptions
          .map((option) => option.value)
          .join(",");

        onChange({ detail: { value } });
      }}
      filteringType="auto"
    />
  );
};

export const ReadOnlyComponent = ({ value }: { value: string | ReactNode }) => {
  return <div>{value}</div>;
};

export const CopyToClipboardComponent = ({
  label,
  value,
}: {
  label: string;
  value: string;
}) => {
  return (
    <div>
      <CopyToClipboard
        copyErrorText={`${value} failed to copy`}
        copySuccessText={`${label} Copied`}
        textToCopy={value}
        variant="inline"
      />
    </div>
  );
};

export const CheckboxComponent = ({
  disabled,
  value,
  onChange,
}: {
  disabled: boolean;
  value: boolean;
  onChange: ({
    detail,
  }: {
    detail: { [key: string]: string | boolean | undefined };
  }) => void;
}) => {
  const [checked, setChecked] = useState(false);
  useEffect(() => {
    setChecked(value);
  }, [value]);

  return (
    <div>
      <Checkbox
        onChange={({ detail }) => {
          setChecked(detail.checked);

          const value = detail.checked;
          onChange({ detail: { value } });
        }}
        checked={checked}
        disabled={disabled}
      />
    </div>
  );
};

const FieldComponent = {
  [FieldType.Input]: Input,
  [FieldType.Textarea]: Textarea,
  [FieldType.Select]: SelectWrapperComponent,
  [FieldType.DatePicker]: DatePicker,
  [FieldType.ReadOnly]: ReadOnlyComponent,
  [FieldType.CopyToClipboard]: CopyToClipboardComponent,
  [FieldType.MultiSelect]: MultiselectWrapperComponent,
  [FieldType.Checkbox]: CheckboxComponent,
};

export const getFieldComponent = (fieldType: FieldType) => {
  return FieldComponent[fieldType] || Input;
};
