import { searchableColumns, defaultTemplate, defaultProgram } from "./config";

const matchesTemplate = (item, selectedTemplate) => {
  return (
    selectedTemplate.value === defaultTemplate.value ||
    item.template === selectedTemplate.value
  );
};

const matchesProgram = (item, selectedProgram) => {
  return (
    selectedProgram.value === defaultProgram.value ||
    item.program === selectedProgram.value
  );
};

export const prepareSelectOptions = (data, field, defaultOption) => {
  if (!data) {
    return [defaultOption];
  }
  const optionSet = [];

  data.forEach((item) => {
    if (optionSet.indexOf(item[field]) === -1) {
      optionSet.push(item[field]);
    }
  });

  optionSet.sort();

  const options = [defaultOption];

  optionSet.forEach((item) => options.push({ label: item, value: item }));
  return options;
};

export const filterItem = (item, filteringText, template, program) => {
  if (!matchesTemplate(item, template)) {
    return false;
  }

  if (!matchesProgram(item, program)) {
    return false;
  }

  return searchableColumns
    .map((key) => item[key])
    .some(
      (value) =>
        typeof value === "string" &&
        value.toLowerCase().indexOf(filteringText.toLowerCase()) > -1
    );
};

export const clearFilter = (actions, setTemplate, setProgram) => {
  actions.setFiltering("");
  setTemplate(defaultTemplate);
  setProgram(defaultProgram);
};
