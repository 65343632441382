import React, { ReactNode, useEffect, useState } from "react";
import Table from "@amzn/awsui-components-react/polaris/table";
import {
  columnDefinitions,
  DEFAULT_OPPORTUNITY_TABLE_PAGE_SIZE,
  defaultColumnsToDisplay,
  filterItem,
  getVisibleContentPreference,
  pageSizePreference,
  GreenfieldColumnDefinitions,
} from "./config";
import {
  CollectionPreferences,
  Pagination,
  SpaceBetween,
} from "@amzn/awsui-components-react";
import { loadOpportunities } from "external/util/services/data/FundRequestService";
import Header from "@amzn/awsui-components-react/polaris/header";
import Grid from "@amzn/awsui-components-react/polaris/grid";
import TextFilter from "@amzn/awsui-components-react/polaris/text-filter";
import { useCollection } from "@amzn/awsui-collection-hooks";
import TableEmptyState from "../../../../../../../../../components/common/TableEmptyState";
import TableNoMatchState from "../../../../../../../../../components/common/TableNoMatchState";
import { getHeaderCounterText, getUserFriendOpportunityId } from "./util";
import OpportunityQualificationAlert from "external/programs/migration-acceleration-program/2024/fund-request/components/create/wizard/Steps/OpportunityDetails/alerts/OpportunityQualificationAlert";
import { IStepStateProps } from "../../../../../types/WizardTypes";
import { fetchFundRequest } from "external/programs/migration-acceleration-program/2024/fund-request/components/create/wizard/util";
import { useParams } from "react-router-dom";
import { TableProps } from "@amzn/awsui-components-react/polaris/table/interfaces";
import { IGenericObject } from "shared/programs/migration-acceleration-program/2024/fund-request/types/CommonTypes";
import OpportunityErrorAlert from "external/programs/migration-acceleration-program/2024/fund-request/components/create/wizard/Steps/OpportunityDetails/alerts/errors/OpportunityErrorAlert";
import { useTranslation } from "react-i18next";
import { HelpPanelContentContext } from "shared/util/context/help/HelpContext";
import { isMapLarge2024 } from "shared/util/common/util";
import { isFeatureEnabled } from "shared/util/services/features/FeatureFlagsService";
import { FEATURE_FLAG_ENUMS } from "shared/util/constants/featureFlagValues";

const OPPORTUNITY_RANGE_ALL = "ALL";

interface IOpportunityDetails extends IStepStateProps {
  children?: ReactNode;
}

const OpportunityDetails = ({
  adapt,
  data,
  onChange,
  formError,
  setData,
}: IOpportunityDetails) => {
  const { id } = useParams<IGenericObject>();
  const { t } = useTranslation();
  const { setKey } = React.useContext(HelpPanelContentContext);
  const [opportunities, setOpportunities] = useState<Array<IGenericObject>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const migrationPhase: string = data?.migrationPhase;
  const fundingProgram = data?.program;
  const arrString = data?.arrUsdAmount;
  const fundingSubProgram = data?.subProgram;
  const aceOpportunityId = data?.aceOpportunityId;
  const isMapLite = !isMapLarge2024(data);
  const [
    columnDefinitionsWithVMWareAndGreenfield,
    setColumnDefinitionsWithVMWareAndGreenfield,
  ] = useState(columnDefinitions);

  const collectionPreferencesProps = {
    pageSizePreference: pageSizePreference,
    visibleContentPreference: getVisibleContentPreference(
      columnDefinitionsWithVMWareAndGreenfield
    ),
    cancelLabel: "Cancel",
    confirmLabel: "Confirm",
    title: "Preferences",
  };

  const selectedItem: Array<IGenericObject> = aceOpportunityId
    ? [{ opportunityId: aceOpportunityId }]
    : [];

  const userFriendlyOpportunityId = getUserFriendOpportunityId({
    aceOpportunityId,
    opportunities,
  });

  // @ts-expect-error Promise not being returned
  useEffect(async () => {
    setKey("wizard.opportunity.page");
    const vmwareEnabledFlag = await isFeatureEnabled(
      FEATURE_FLAG_ENUMS.VMWARE_MAP_ENABLED
    );
    if (vmwareEnabledFlag) {
      setColumnDefinitionsWithVMWareAndGreenfield([
        ...columnDefinitions,
        ...GreenfieldColumnDefinitions,
      ]);
    }
    const fetchOpportunities = async () => {
      setIsLoading(true);
      try {
        const opportunitiesData = await loadOpportunities(
          OPPORTUNITY_RANGE_ALL,
          fundingProgram,
          migrationPhase,
          fundingSubProgram,

          // @ts-expect-error Legacy opportunity function
          isMapLite
        );
        setOpportunities(opportunitiesData || []);
      } catch (error) {
        console.error("Error fetching opportunities:", error);
      } finally {
        setIsLoading(false);
      }
    };
    if (fundingProgram && migrationPhase && fundingSubProgram) {
      await fetchOpportunities();
    }
    await fetchFundRequest({ id, adapt, setData });
  }, [fundingProgram, migrationPhase, fundingSubProgram]);

  interface IPreferences {
    pageSize: number;
    visibleContent: string[];
  }

  const [preferences, setPreferences] = useState<IPreferences>({
    pageSize: DEFAULT_OPPORTUNITY_TABLE_PAGE_SIZE,
    visibleContent: defaultColumnsToDisplay,
  });

  const { items, actions, collectionProps, filterProps, paginationProps } =
    useCollection(opportunities || [], {
      filtering: {
        empty: <TableEmptyState resourceName="opportunities" />,
        noMatch: (
          <TableNoMatchState
            onClearFilter={() => actions.setFiltering("")}
            resourceName="opportunities"
          />
        ),
        filteringFunction: (item, filteringText) => {
          return filterItem({
            item,
            filteringText,
          });
        },
      },
      pagination: { pageSize: preferences.pageSize },
      sorting: {
        defaultState: {
          sortingColumn: { sortingField: "targetCloseDate" },
          isDescending: true,
        },
      },
      selection: {},
    });

  const onChangeHandler = ({
    detail,
  }: {
    detail: TableProps.SelectionChangeDetail<IGenericObject>;
  }) => {
    const [opportunity] = detail.selectedItems;
    onChange({
      key: "aceOpportunityId",
      value: opportunity.opportunityId,
    });
  };

  return (
    <SpaceBetween size="s">
      <OpportunityQualificationAlert
        migrationPhase={migrationPhase}
        arrString={arrString}
      />
      <Table
        {...collectionProps}
        columnDefinitions={columnDefinitionsWithVMWareAndGreenfield}
        visibleColumns={preferences.visibleContent}
        items={items || []}
        loading={isLoading || !data}
        loadingText="Loading opportunities"
        selectionType="single"
        onSelectionChange={onChangeHandler}
        selectedItems={selectedItem}
        trackBy="opportunityId"
        filter={
          <Grid gridDefinition={[{ colspan: 10 }]}>
            <TextFilter
              {...filterProps}
              filteringPlaceholder="Find opportunities"
            />
          </Grid>
        }
        header={
          <Header
            counter={getHeaderCounterText(opportunities, selectedItem)}
            description={t(
              "wizard.section.opportunity.page.container.content.opportunity.header.description"
            )}
          >
            {t(
              "wizard.section.opportunity.page.container.content.opportunity.header.title"
            )}
          </Header>
        }
        preferences={
          // @ts-expect-error not valid props being passed in
          // TODO: not valid props being passed in
          <CollectionPreferences
            {...collectionPreferencesProps}
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail as IPreferences)}
          />
        }
        pagination={<Pagination {...paginationProps} />}
      />
      <OpportunityErrorAlert
        userFriendlyId={userFriendlyOpportunityId}
        error={formError}
      />
    </SpaceBetween>
  );
};

export default OpportunityDetails;
