import {
  searchableColumns,
  defaultStatus,
  defaultBizUnit,
  defaultStage,
  defaultCreatedYear,
} from "./config";
import {
  getYearFromTimeStampString,
  matches,
} from "../../../../../../shared/util/common/helper";

export const filterItem = ({
  item,
  filteringText,
  status,
  stage,
  customerBizUnit,
  createdDate,
}) => {
  if (!matches(defaultStatus.value, item.status, status.value)) {
    return false;
  }
  if (!matches(defaultStage.value, item.aceValidationStage, stage.value)) {
    return false;
  }
  if (
    !matches(defaultBizUnit.value, item.customerBizUnit, customerBizUnit.value)
  ) {
    return false;
  }
  if (
    !matches(
      defaultCreatedYear.value,
      getYearFromTimeStampString(item.createdDate),
      createdDate.value
    )
  ) {
    return false;
  }

  return searchableColumns
    .map((key) => item[key])
    .some(
      (value) =>
        typeof value === "string" &&
        value.toLowerCase().indexOf(filteringText.toLowerCase()) > -1
    );
};

export const clearFilter = ({
  actions,
  setStatus,
  setStage,
  setCustomerBizUnit,
  setCreatedDate,
}) => {
  actions.setFiltering("");
  setStatus(defaultStatus);
  setStage(defaultStage);
  setCustomerBizUnit(defaultBizUnit);
  setCreatedDate(defaultCreatedYear);
};

export const prepareCreatedDateSelectOptions = (data, field, defaultOption) => {
  if (!data) {
    return [defaultOption];
  }
  const optionsSet = new Set([defaultOption.value]);
  data.forEach((item) => {
    optionsSet.add(getYearFromTimeStampString(item[field]));
  });
  const optionsList = [...optionsSet].sort((a, b) => parseInt(b) - parseInt(a));
  const options = [];
  optionsList.forEach((item) => options.push({ label: item, value: item }));
  return options;
};
