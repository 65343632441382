import React, { ReactElement } from "react";
import { IGenericObject } from "shared/programs/migration-acceleration-program/2024/fund-request/types/CommonTypes";
import { useHistory } from "react-router-dom";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import ReadOnlyCashClaim from "external/programs/migration-acceleration-program/2024/fund-request/components/create/wizard/Steps/Review/sections/claims/ReadOnlyCashClaim";
import CashClaimActionsHeader from "external/programs/migration-acceleration-program/2024/fund-request/components/details/FundRequestDetailsContainer/header/claims/CashClaimActionsHeader";
import { getFundRequestId } from "external/util/common/helper";
import CashClaimPlan from "external/programs/migration-acceleration-program/2024/fund-request/components/shared/common/claims/CashClaimPlan";
import { claimStagesKeys } from "shared/programs/migration-acceleration-program/2024/fund-request/util/claims";

const CashClaimsContainer = ({
  data,
}: {
  data: IGenericObject;
  actions?: ReactElement;
}) => {
  const history = useHistory();
  const claims: Array<IGenericObject> = data?.claims;

  if (!claims || claims.length === 0) return <></>;

  return (
    <SpaceBetween size="l">
      {claims.map((claim, index) => {
        const claimId = claim.claimId;
        const currentStage = claim?.claimStage;
        const currentStatus = claim?.claimStatus;
        const priority = claim?.claimPriority;

        const headerTitle = `Cash claim ${index + 1}`;
        const key = `${claimId}-${index}`;

        if (!currentStage || currentStage === claimStagesKeys.partner) {
          return (
            <CashClaimPlan
              key={key}
              headerTitle={headerTitle}
              data={data}
              claim={claim}
              actions={
                <CashClaimActionsHeader
                  data={data}
                  claimId={claimId}
                  priority={priority}
                  claimStatus={currentStatus}
                  onClick={() => {
                    history.push(
                      `/fund-requests/${getFundRequestId()}/${claimId}/new`
                    );
                  }}
                />
              }
            />
          );
        }
        return (
          <ReadOnlyCashClaim
            key={key}
            headerTitle={headerTitle}
            data={data}
            claim={claim}
            actions={
              <CashClaimActionsHeader
                data={data}
                claimId={claimId}
                claimStatus={currentStatus}
                priority={priority}
                onClick={() => {
                  history.push(
                    `/fund-requests/${getFundRequestId()}/${claimId}/new`
                  );
                }}
              />
            }
          />
        );
      })}
    </SpaceBetween>
  );
};

export default CashClaimsContainer;
