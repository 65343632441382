import i18n from "../../../../i18n";

export const generateI18nStrings = (cancelButton) => {
  const i18nStrings = {
    stepNumberLabel: (stepNumber) =>
      i18n.t("wizard.common.step_number_label", { stepNumber }),
    collapsedStepsLabel: (stepNumber, stepsCount) =>
      i18n.t("wizard.common.collapsed_steps_label", {
        stepNumber,
        stepsCount,
      }),
    previousButton: "Hidden",
    nextButton: i18n.t("wizard.common.next"),
    submitButton: i18n.t("wizard.common.submit"),
  };

  if (cancelButton) {
    i18nStrings.cancelButton = cancelButton;
  }
  return i18nStrings;
};
