import { getReducerType } from "./util";

export const EntityTypes = {
  TABLE_SETTINGS: "TableSettings",
};

export const UserPersistedSettingsVersions = {
  V1: "v1",
};
export const UserPersistedSettingsV1ComponentName = {
  NEW_FILTER_TABLE: "NewFilterTable",
};

export const UserPersistedSettingsV1ComponentAttribute = {
  SAVED_FILTER_SETS: "SavedFilterSets",
};

export const initialData = {
  isFeatureFlagEnabled: false,
  isLoading: false,
  [getReducerType(
    UserPersistedSettingsVersions.V1,
    UserPersistedSettingsV1ComponentName.NEW_FILTER_TABLE,
    UserPersistedSettingsV1ComponentAttribute.SAVED_FILTER_SETS
  )]: [],
};

export const StateEnums = {
  SET_FEATURE_FLAG: "SET_FEATURE_FLAG",
  SET_LOADING: "SET_LOADING",
  [getReducerType(
    UserPersistedSettingsVersions.V1,
    UserPersistedSettingsV1ComponentName.NEW_FILTER_TABLE,
    UserPersistedSettingsV1ComponentAttribute.SAVED_FILTER_SETS
  )]: getReducerType(
    UserPersistedSettingsVersions.V1,
    UserPersistedSettingsV1ComponentName.NEW_FILTER_TABLE,
    UserPersistedSettingsV1ComponentAttribute.SAVED_FILTER_SETS
  ),
};
