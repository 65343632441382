import React from "react";
import PropTypes from "prop-types";
import HelpPanel from "@amzn/awsui-components-react/polaris/help-panel";

const ActionItems = ({ context }) => {
  const quarantineSuggestions = context.helperPanelContent.content;
  return (
    <div>
      <HelpPanel header={<h2>Action Items</h2>}>
        <div>
          <h2>
            Please review the partner submission for funding. You can edit and
            update partner submitted form on behalf of your partners upon
            agreement to expedite the approval.
          </h2>

          <h2>
            For Migration Acceleration Program, you will also need to fill in
            the additional information on this fund request form, before you can
            approve the fund request to further approvers for review.
          </h2>

          <h4>Mandatory fields:</h4>
          <p>
            Program Engagement ID - This is a mandatory fields. For small
            migration, please fill in all-zeros.
          </p>
          <p>
            Partner Solution Architect - Please identify the PSA who should be
            responsible to review and approve this fund request, and input
            his/her alias in this field.
          </p>

          <h4>Optional fields:</h4>
          <p>3rd Party Funding</p>
          <p>Migration BD Approved</p>
          <p>Eligible for Special Customer Incentive</p>
        </div>
      </HelpPanel>
      {quarantineSuggestions && (
        <HelpPanel header={<h2>Suggestions</h2>}>
          <div>
            {quarantineSuggestions.map((paragraph) => {
              return <p key={paragraph.id}>{paragraph}</p>;
            })}
          </div>
        </HelpPanel>
      )}
    </div>
  );
};
ActionItems.propTypes = {
  context: PropTypes.string,
};
export default ActionItems;
