import { CollectionPreferencesProps } from "@amzn/awsui-components-react/polaris/collection-preferences/interfaces";
import { ReactNode } from "react";
import { IGenericObject } from "shared/programs/migration-acceleration-program/2024/fund-request/types/CommonTypes";

const getPageSizePreferences = ({
  name,
}: {
  name: string;
}): CollectionPreferencesProps.PageSizePreference => {
  return {
    title: "Page size",
    options: [
      { value: 10, label: `10 ${name}` },
      { value: 20, label: `20 ${name}` },
    ],
  };
};

const getVisibleContentPreference = ({
  columnDefinitions,
  editable,
}: {
  columnDefinitions: Array<IGenericObject>;
  editable?: ({
    id,
    header,
  }: {
    id: string;
    header: string | ReactNode;
  }) => boolean;
}): Array<CollectionPreferencesProps.VisibleContentOption> => {
  return columnDefinitions
    .filter(({ header }) => typeof header === "string")
    .map(({ id, header }: IGenericObject) => {
      return {
        id: id || "",
        label: header as string,
        editable: editable && editable({ id, header }),
      };
    });
};

export const getTableCollectionPreferences = ({
  name,
  columnDefinitions,
  editable,
}: {
  name: string;
  columnDefinitions: Array<IGenericObject>;
  editable?: ({
    id,
    header,
  }: {
    id?: string;
    header?: string | ReactNode;
  }) => boolean;
}): CollectionPreferencesProps => {
  const pageSizePreference = getPageSizePreferences({ name });
  const visibleContentPreference: CollectionPreferencesProps.VisibleContentPreference =
    {
      title: "Select visible columns",
      options: [
        {
          label: "Main properties",
          options: getVisibleContentPreference({ columnDefinitions, editable }),
        },
      ],
    };

  const preferenceLabels = {
    cancelLabel: "Cancel",
    confirmLabel: "Confirm",
    title: "Preferences",
  };

  return {
    pageSizePreference,
    visibleContentPreference,
    ...preferenceLabels,
  };
};

export const getColumnsAsVisible = ({
  columnDefinitions,
}: {
  columnDefinitions: Array<IGenericObject>;
}): Array<string> => {
  return columnDefinitions.map((definition) => definition.id);
};

export const getCount = ({
  collection,
}: {
  collection: Array<IGenericObject>;
}) => {
  return collection && collection.length > 0 ? `(${collection.length})` : "(0)";
};

export const filter = ({
  item,
  filteringText,
  columnDefinitions,
}: {
  item: IGenericObject;
  filteringText: string;
  columnDefinitions: Array<IGenericObject>;
}): boolean => {
  return columnDefinitions
    .map(({ id }: { id?: string }) => item[id as string])
    .some(
      (value) =>
        typeof value === "string" &&
        value.toLowerCase().indexOf(filteringText.toLowerCase()) > -1
    );
};
