import { generateEngagementIdLink } from "internal/util/constants/generateEngagementIdLink";
import { formatMigrationARR, mpeAttributes } from "../util";

export const MIGRATION_ENGAGEMENT_TABLE_DEFAULT_PAGE_SIZE = 5;

export const columnDefinitions = [
  {
    id: "mpeId",
    header: "Migration program engagement Id",
    cell: (e) => (e.mpeId ? generateEngagementIdLink(e.mpeId) : "-"),
    sortingField: "mpeId",
  },
  {
    id: "recordStatus",
    header: mpeAttributes.recordStatus,
    cell: (e) => e.recordStatus || "-",
    sortingField: "recordStatus",
  },
  {
    id: "engagementStatus",
    header: mpeAttributes.engagementStatus,
    cell: (e) => e.engagementStatus || "-",
    sortingField: "engagementStatus",
  },
  {
    id: "migrationArr",
    header: mpeAttributes.migrationArr,
    cell: (e) => formatMigrationARR(e.migrationArr),
    sortingField: "migrationArr.units",
  },
  {
    id: "engagementStatusWonStartDate",
    header: mpeAttributes.engagementStatusWonStartDate,
    cell: (e) => e.engagementStatusWonStartDate || "-",
    sortingField: "engagementStatusWonStartDate",
  },
  {
    id: "privateEquityBacked",
    header: mpeAttributes.privateEquityBacked,
    cell: (e) => e.privateEquityBacked || "-",
    sortingField: "privateEquityBacked",
  },
  {
    id: "consultingPartnerEngagement",
    header: mpeAttributes.consultingPartnerEngagement,
    cell: (e) => e.consultingPartnerEngagement || "-",
    sortingField: "consultingPartnerEngagement",
  },
  {
    id: "agreementCategory",
    header: mpeAttributes.agreementCategory,
    cell: (e) => e.agreementCategory || "-",
    sortingField: "agreementCategory",
  },
  {
    id: "workshopCompletionDate",
    header: mpeAttributes.workshopCompletionDate,
    cell: (e) => e.workshopCompletionDate || "-",
    sortingField: "workshopCompletionDate",
  },
  {
    id: "readinessAssessmentStatus",
    header: mpeAttributes.readinessAssessmentStatus,
    cell: (e) => e.readinessAssessmentStatus || "-",
    sortingField: "readinessAssessmentStatus",
  },
  {
    id: "deliveredBy",
    header: mpeAttributes.deliveredBy,
    cell: (e) => e.deliveredBy || "-",
    sortingField: "deliveredBy",
  },
  {
    id: "readoutPresentationDate",
    header: mpeAttributes.readoutPresentationDate,
    cell: (e) => e.readoutPresentationDate || "-",
    sortingField: "readoutPresentationDate",
  },
  {
    id: "mraMethdology",
    header: mpeAttributes.mraMethdology,
    cell: (e) => e.mraMethdology || "-",
    sortingField: "mraMethdology",
  },
  {
    id: "olaCompletionDate",
    header: mpeAttributes.olaCompletionDate,
    cell: (e) => e.olaCompletionDate || "-",
    sortingField: "olaCompletionDate",
  },
  {
    id: "confirmed",
    header: mpeAttributes.confirmed,
    cell: (e) => e.confirmed || "-",
    sortingField: "confirmed",
  },
  {
    id: "olaStatus",
    header: mpeAttributes.olaStatus,
    cell: (e) => e.olaStatus || "-",
    sortingField: "olaStatus",
  },
];

const pageSizePreference = {
  title: "Select number of rows per table page",
  options: [
    { value: 10, label: "10 rows" },
    { value: 20, label: "20 rows" },
  ],
};

const visibleContentPreference = {
  title: "Select visible columns",
  options: [
    {
      label: "",
      options: columnDefinitions.map(({ id, header }) => ({
        id,
        label: header,
        editable: id !== "id",
      })),
    },
  ],
};

export const filterItem = ({ item, filteringText }) => {
  return defaultColumnsToDisplay
    .map((key) => item[key])
    .some(
      (value) =>
        typeof value === "string" &&
        value.toLowerCase().indexOf(filteringText.toLowerCase()) > -1
    );
};

export const defaultColumnsToDisplay = [
  "mpeId",
  "recordStatus",
  "engagementStatus",
  "migrationArr",
  "engagementStatusWonStartDate",
  "privateEquityBacked",
];

export const collectionPreferencesProps = {
  pageSizePreference,
  visibleContentPreference,
  cancelLabel: "Cancel",
  confirmLabel: "Confirm",
  title: "Preferences",
};
