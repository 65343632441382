import React from "react";
import Header from "@amzn/awsui-components-react/polaris/header";
import PropTypes from "prop-types";

import Loading from "../../../../../../shared/components/common/Loading";

import {
  filterFields,
  getTemplate,
  schema,
} from "../../../../../config/schema";
import useFields from "../../../../../../shared/util/hooks/useFields";
import { SpaceBetween } from "@amzn/awsui-components-react";
import AddButton from "../../../../../../shared/components/FundRequest/StepsContainer/AddButton";
import RemoveButton from "../../../../../../shared/components/FundRequest/StepsContainer/RemoveButton";
import { useOptionsContext } from "../../../../../util/context/OptionsContext";
import Section from "../../../../../../shared/components/FundRequest/StepsContainer/Section";
import { DisableFactory } from "../../Inputs/PropBuilder/DisableFactory";
import { ValueFactory } from "../../Inputs/PropBuilder/ValueFactory";
import { InputPropsBuilder } from "../../../../../../shared/components/FundRequest/InputPropsBuilder";
import { InputFactory } from "../../../../../../shared/components/FundRequest/InputFactory";
import { getFundRequest } from "../../../../../util/services/data/FundRequestService";
import { FileUploadTriggers } from "../../../../../util/services/data/dataFactory";

const MigrationWorkloadsSectionContainer = ({
  data,
  errors,
  dataInput,
  dataLoad,
  fieldToIdMap,
  updateErrors,
  setFieldToIdMap,
  stepTitle,
  validators,
  showHelperPanel,
}) => {
  const [options] = useOptionsContext();
  const stepTemplate = schema[stepTitle];
  const [
    { additionalSections, fieldTemplate, isLoading, addSection, removeSection },
  ] = useFields({
    data,
    stepTemplate,
    fieldToIdMap,
    updateFieldMap: setFieldToIdMap,
    dataLoad,
    dataInput,
    getTemplate: getTemplate,
    filterFields,
    schema,
    getFundRequest,
  });

  const template = getTemplate({
    program: data["program"],
    fundingType: data["fundingType"],
  });
  const stepLayout = template[stepTemplate.name];

  const section = isLoading ? (
    <Loading />
  ) : (
    <SpaceBetween size="l">
      {additionalSections.map((sectionIndex) => {
        const templateName =
          sectionIndex > 1
            ? `migrationWorkloads-${sectionIndex}`
            : "migrationWorkloads";
        return (
          <React.Fragment key={sectionIndex}>
            <Section
              header={
                <SpaceBetween size="l">
                  <Header
                    variant="h2"
                    actions={
                      additionalSections.length > 1 ? (
                        <RemoveButton
                          index={sectionIndex}
                          removeSection={removeSection}
                        />
                      ) : (
                        ""
                      )
                    }
                  >
                    {`${fieldTemplate[templateName].title} ${
                      sectionIndex > 1 ? sectionIndex : ""
                    }`}
                  </Header>
                </SpaceBetween>
              }
            >
              <React.Fragment>
                {fieldTemplate &&
                  fieldTemplate[templateName] &&
                  fieldTemplate[templateName].fields.map((field, index) => {
                    const inputProps = InputPropsBuilder({
                      data,
                      errors,
                      field,
                      options,
                      index: sectionIndex,
                      fieldToIdMap,
                      disableFactory: DisableFactory,
                      valueFactory: ValueFactory,
                    });

                    return (
                      <InputFactory
                        key={index}
                        dataInput={dataInput}
                        fieldToIdMap={fieldToIdMap}
                        updateErrors={updateErrors}
                        validators={validators}
                        showHelperPanel={showHelperPanel}
                        fileuploadProp={FileUploadTriggers}
                        {...inputProps}
                      />
                    );
                  })}
              </React.Fragment>
            </Section>
          </React.Fragment>
        );
      })}
      <AddButton
        addSection={addSection}
        multiSectionDetails={stepLayout.multiSection}
        isDisabled={
          !(additionalSections.length < stepLayout.multiSection.maxSections)
        }
      />
    </SpaceBetween>
  );
  return <React.Fragment>{section}</React.Fragment>;
};

MigrationWorkloadsSectionContainer.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object,
  dataInput: PropTypes.func,
  dataLoad: PropTypes.func,
  fieldToIdMap: PropTypes.object,
  updateErrors: PropTypes.func,
  setTemplate: PropTypes.func,
  validators: PropTypes.any,
  setFieldToIdMap: PropTypes.func,
  stepTitle: PropTypes.string,
  showHelperPanel: PropTypes.func,
};

export default React.memo(MigrationWorkloadsSectionContainer);
