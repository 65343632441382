import { SECTION_NAME } from "../../../../shared/util/constants/section";

const defaultDisable = ({ disabled, auto, edit, page }) =>
  disabled || auto || (edit && !edit.includes(page));

const executedStateDisable = ({ data, fieldToIdMap }) => {
  let disabled = true;
  const executedCountryId =
    fieldToIdMap[SECTION_NAME.PROJECT_INFORMATION]["executedCountry"];
  const executedCountry = data[executedCountryId];
  if (executedCountry === "US") {
    disabled = false;
  }
  return disabled;
};

export const DisableFactory = (name) => {
  switch (name) {
    case "executedState":
      return executedStateDisable;
    default:
      return defaultDisable;
  }
};
