import { DefaultEditFooter } from "../../../../shared/components/FundRequest/Edit/Footer/DefaultEditFooter";
import { CashClaimInvoicePayment } from "./CashClaimInvoicePayment";
import { AttachmentsEditFooter } from "../../../../shared/components/FundRequest/Edit/Footer/AttachmentsEditFooter";
import { SECTION } from "../../../../shared/util/constants/section";
import { MigrationEngagementEditFooter } from "./MigrationEngagementEditFooter";
import { CashClaimEditFooter } from "./CashClaimEditFooter";

export const FooterFactory = ({ sectionTitle }) => {
  switch (sectionTitle) {
    case SECTION.CASH_CLAIM_INVOICE_PAYMENT:
      return CashClaimInvoicePayment;
    case SECTION.MIGRATION_PROGRAM_ENGAGEMENTS:
      return MigrationEngagementEditFooter;
    case SECTION.CASH_CLAIM_ACTUALS:
      return CashClaimEditFooter;
    case SECTION.ATTACHMENTS:
      return AttachmentsEditFooter;
    default:
      return DefaultEditFooter;
  }
};
