import Calendar from "@amzn/awsui-components-react/polaris/calendar";
import DateInput from "@amzn/awsui-components-react/polaris/date-input";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import PropTypes from "prop-types";
import React from "react";
import { statusBadge } from "../../../../shared/components/common/StatusBadge";
import {
  currencyComparator,
  dateComparator,
  getDateFromISO,
  getISODate,
  getNumberFromString,
  numberComparator,
  timestampComparator,
} from "../../../../shared/util/common/helper";
import {
  generateFundingLink,
  tableOperators,
  CustomCheckboxForm,
} from "./util";
import {
  STAGE,
  CASH_CLAIM_STAGE,
  STATUS,
} from "../../../../shared/util/constants/fundRequestStatusType";

export const newApprovalsColumnDefinition = [
  {
    id: "fundRequestId",
    header: "Fund Request ID",
    cell: (e) => generateFundingLink(e.fundRequestId, e.stage),
    sortingField: "fundRequestId",
  },
  {
    id: "template",
    header: "Template",
    cell: (e) => e.template,
    sortingField: "template",
  },
  {
    id: "program",
    header: "Program",
    cell: (e) => e.program,
    sortingField: "program",
  },
  {
    id: "activityName",
    header: "Activity Name",
    cell: (e) => e.activityName,
    sortingField: "activityName",
  },
  {
    id: "partnerName",
    header: "Partner Name",
    cell: (e) => e.partnerName,
    sortingField: "partnerName",
  },
  {
    id: "assignedTo",
    header: "Assigned To",
    cell: (e) => e.assignedTo,
    sortingField: "assignedTo",
  },
  {
    id: "stage",
    header: "Stage",
    cell: (e) => e.stage,
    sortingField: "stage",
  },
  {
    id: "dwellTime",
    header: "Dwell Time (days)",
    cell: (e) => e.dwellTime,
    sortingField: "dwellTime",
    sortingComparator: (e1, e2) => numberComparator(e1.dwellTime, e2.dwellTime),
  },
  {
    id: "status",
    header: "Status",
    cell: (e) => statusBadge(e.status),
    sortingField: "status",
  },
  {
    id: "opportunityId",
    header: "Opportunity Id",
    cell: (e) => e.opportunityId,
    sortingField: "opportunityId",
  },
  {
    id: "approvedCashAmount",
    header: "Approved Cash Amount",
    cell: (e) => e.approvedCashAmount,
    sortingField: "approvedCashAmount",
    sortingComparator: (e1, e2) =>
      currencyComparator(e1.approvedCashAmount, e2.approvedCashAmount),
  },
  {
    id: "approvedCreditAmount",
    header: "Approved Credit Amount",
    cell: (e) => e.approvedCreditAmount,
    sortingField: "approvedCreditAmount",
    sortingComparator: (e1, e2) =>
      currencyComparator(e1.approvedCreditAmount, e2.approvedCreditAmount),
  },
  {
    id: "projectGeo",
    header: "Project Geo",
    cell: (e) => e.projectGeo,
    sortingField: "projectGeo",
  },
  {
    id: "partnerType",
    header: "Partner Type",
    cell: (e) => e.partnerType,
    sortingField: "partnerType",
  },
  {
    id: "lastUpdatedOn",
    header: "Last Updated On",
    cell: (e) =>
      e.lastUpdatedOn ? getDateFromISO(e.lastUpdatedOn.toISOString()) : "",
    sortingField: "lastUpdatedOn",
    sortingComparator: (e1, e2) =>
      dateComparator(e1.lastUpdatedOn, e2.lastUpdatedOn),
  },
  {
    id: "createdOn",
    header: "Created On",
    cell: (e) => (e.createdOn ? getDateFromISO(e.createdOn.toISOString()) : ""),
    sortingField: "createdOn",
    sortingComparator: (e1, e2) => dateComparator(e1.createdOn, e2.createdOn),
  },
  {
    id: "partnerLastInteractionOn",
    header: "Partner Last Interaction On",
    cell: (e) =>
      e.partnerLastInteractionOn
        ? getDateFromISO(e.partnerLastInteractionOn.toISOString())
        : "",
    sortingField: "partnerLastInteractionOn",
    sortingComparator: (e1, e2) =>
      dateComparator(e1.partnerLastInteractionOn, e2.partnerLastInteractionOn),
  },
  {
    id: "eligibleForPsaAutoApproval",
    header: "Eligible for PSA Auto Approval",
    cell: (e) => (e.eligibleForPsaAutoApproval ? "Yes" : "No"),
    sortingField: "eligibleForPsaAutoApproval",
  },
  {
    id: "cashClaimStages",
    header: "Cash Claim Stages",
    cell: (e) => `${e.cashClaimStages.join(", ")}`,
    sortingField: "cashClaimStages",
  },
  {
    id: "strategicCollaborationAgreement",
    header: "Strategic Collaboration Agreement",
    cell: (e) => `${e.strategicCollaborationAgreement}`,
    sortingField: "strategicCollaborationAgreement",
  },
  {
    id: "publicSector",
    header: "Public Sector",
    cell: (e) => `${e.publicSector}`,
    sortingField: "publicSector",
  },
];

export const oldApprovalsColumnDefinition = [
  {
    id: "fundRequestId",
    header: "Fund Request ID",
    cell: (e) => generateFundingLink(e.fundRequestId, e.stage),
    sortingField: "fundRequestId",
  },
  {
    id: "template",
    header: "Template",
    cell: (e) => e.template,
    sortingField: "template",
  },
  {
    id: "program",
    header: "Program",
    cell: (e) => e.program,
    sortingField: "program",
  },
  {
    id: "activityName",
    header: "Activity Name",
    cell: (e) => e.activityName,
    sortingField: "activityName",
  },
  {
    id: "partnerName",
    header: "Partner Name",
    cell: (e) => e.partnerName,
    sortingField: "partnerName",
  },
  {
    id: "assignedTo",
    header: "Assigned To",
    cell: (e) => e.assignedTo,
    sortingField: "assignedTo",
  },
  {
    id: "stage",
    header: "Stage",
    cell: (e) => e.stage,
    sortingField: "stage",
  },
  {
    id: "dwellTime",
    header: "Dwell Time (days)",
    cell: (e) => e.dwellTime,
    sortingField: "dwellTime",
    sortingComparator: (e1, e2) => numberComparator(e1.dwellTime, e2.dwellTime),
  },
  {
    id: "status",
    header: "Status",
    cell: (e) => statusBadge(e.status),
    sortingField: "status",
  },
  {
    id: "opportunityId",
    header: "Opportunity Id",
    cell: (e) => e.opportunityId,
    sortingField: "opportunityId",
  },
  {
    id: "approvedCashAmount",
    header: "Approved Cash Amount",
    cell: (e) => e.approvedCashAmount,
    sortingField: "approvedCashAmount",
    sortingComparator: (e1, e2) =>
      currencyComparator(e1.approvedCashAmount, e2.approvedCashAmount),
  },
  {
    id: "approvedCreditAmount",
    header: "Approved Credit Amount",
    cell: (e) => e.approvedCreditAmount,
    sortingField: "approvedCreditAmount",
    sortingComparator: (e1, e2) =>
      currencyComparator(e1.approvedCreditAmount, e2.approvedCreditAmount),
  },
  {
    id: "projectGeo",
    header: "Project Geo",
    cell: (e) => e.projectGeo,
    sortingField: "projectGeo",
  },
  {
    id: "partnerType",
    header: "Partner Type",
    cell: (e) => e.partnerType,
    sortingField: "partnerType",
  },
  {
    id: "lastUpdatedOn",
    header: "Last Updated On",
    cell: (e) => getISODate(e.lastUpdatedOn, ""),
    sortingField: "lastUpdatedOn",
    sortingComparator: (e1, e2) =>
      timestampComparator(e1.lastUpdatedOn, e2.lastUpdatedOn),
  },
  {
    id: "createdOn",
    header: "Created On",
    cell: (e) => getISODate(e.createdOn, ""),
    sortingField: "createdOn",
    sortingComparator: (e1, e2) =>
      timestampComparator(e1.createdOn, e2.createdOn),
  },
  {
    id: "eligibleForPsaAutoApproval",
    header: "Eligible for PSA Auto Approval",
    cell: (e) => (e.eligibleForPsaAutoApproval ? "Yes" : "No"),
    sortingField: "eligibleForPsaAutoApproval",
  },
];

export const searchableColumns = [
  "fundRequestId",
  "program",
  "activityName",
  "partnerName",
  "assignedTo",
  "stage",
  "dwellTime",
  "status",
  "documents",
  "actionRequired",
  "fundRequestId",
  "opportunityId",
  "approvedCashAmount",
  "approvedCreditAmount",
  "projectGeo",
  "partnerType",
  "cashClaimStages",
  "strategicCollaborationAgreement",
  "publicSector",
];

export const visibleColumns = [
  "fundRequestId",
  "program",
  "activityName",
  "partnerName",
  "assignedTo",
  "stage",
  "lastUpdatedOn",
  "dwellTime",
  "status",
  "documents",
  "actionRequired",
  "opportunityId",
  "approvedCashAmount",
  "approvedCreditAmount",
  "createdOn",
  "eligibleForPsaAutoApproval",
  "cashClaimStages",
  "strategicCollaborationAgreement",
  "publicSector",
  "partnerLastInteractionOn",
];

export const defaultTemplate = {
  label: "Any Template",
  value: "Any Template",
};
export const defaultProgram = {
  label: "Any Program",
  value: "Any Program",
};
export const defaultStage = {
  value: "Any Stage",
  label: "Any Stage",
};
export const defaultStatus = {
  value: "Any Status",
  label: "Any Status",
};
export const defaultPartnerType = {
  value: "Any Partner Type",
  label: "Any Partner Type",
};
export const defaultPartnerName = {
  value: "Any Partner Name",
  label: "Any Partner Name",
};
export const defaultProjectGeo = {
  value: "Any Project Geo",
  label: "Any Project Geo",
};

function DateForm({ value, onChange }) {
  return (
    <div className="date-form">
      <FormField>
        <DateInput
          value={value || ""}
          onChange={(event) => onChange(event.detail.value)}
          placeholder="YYYY/MM/DD"
        />
      </FormField>
      <Calendar
        value={value || ""}
        onChange={(event) => onChange(event.detail.value)}
        locale="en-EN"
        todayAriaLabel="Today"
        nextMonthAriaLabel="Next month"
        previousMonthAriaLabel="Previous month"
      />
    </div>
  );
}

DateForm.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

const stageCheckboxOptions = Object.values(STAGE).map((value) => ({
  value,
  label: value,
}));

const cashClaimStageCheckboxOptions = Object.values(CASH_CLAIM_STAGE).map(
  (value) => ({
    value,
    label: value,
  })
);

const statusCheckboxOptions = Object.values(STATUS).flatMap((value) => {
  if (value !== "deleted") {
    return {
      value,
      label: value,
    };
  } else return [];
});

export const FILTERING_PROPERTIES = [
  {
    propertyLabel: "Template",
    key: "template",
    groupValuesLabel: "Template values",
    operators: ["=", "!="],
  },
  {
    propertyLabel: "Program",
    key: "program",
    groupValuesLabel: "Program values",
    operators: ["=", "!="],
  },
  {
    propertyLabel: "Stage",
    key: "stage",
    groupValuesLabel: "Stage values",
    operators: [
      {
        operator: "=",
        form: CustomCheckboxForm(stageCheckboxOptions),
        format: (value) => (value || []).join(", "),
        match: (itemValue, tokenValue) =>
          tokenValue != null && tokenValue.includes(itemValue),
      },
    ],
  },
  {
    propertyLabel: "Status",
    key: "status",
    groupValuesLabel: "Status values",
    operators: [
      {
        operator: "=",
        form: CustomCheckboxForm(statusCheckboxOptions),
        format: (value) => (value || []).join(", "),
        match: (itemValue, tokenValue) =>
          tokenValue != null && tokenValue.includes(itemValue),
      },
    ],
  },
  {
    propertyLabel: "Partner Type",
    key: "partnerType",
    groupValuesLabel: "Partner Type values",
    operators: ["=", "!="],
  },
  {
    propertyLabel: "Partner Name",
    key: "partnerName",
    groupValuesLabel: "Partner Name values",
    operators: ["=", "!="],
  },
  {
    propertyLabel: "Project Geo",
    key: "projectGeo",
    groupValuesLabel: "Project Geo values",
    operators: ["=", "!="],
  },
  {
    propertyLabel: "Fund Request ID",
    key: "fundRequestId",
    groupValuesLabel: "Fund Request ID values",
    operators: ["=", "!=", ":", "!:"],
  },
  {
    propertyLabel: "Activity Name",
    key: "activityName",
    groupValuesLabel: "Activity Name values",
    operators: ["=", "!=", ":", "!:"],
  },
  {
    propertyLabel: "Assigned To",
    key: "assignedTo",
    groupValuesLabel: "Assigned To values",
    operators: ["=", "!="],
  },
  {
    propertyLabel: "Opportunity ID",
    key: "opportunityId",
    groupValuesLabel: "Opportunity ID values",
    operators: ["=", "!="],
  },
  {
    propertyLabel: "Dwell Time",
    key: "dwellTime",
    groupValuesLabel: "Dwell Time values",
    operators: [
      {
        operator: "=",
        match: (itemValue, tokenValue) =>
          tableOperators(
            itemValue,
            tokenValue,
            "=",
            getNumberFromString,
            "Not Available"
          ),
      },
      {
        operator: "!=",
        match: (itemValue, tokenValue) =>
          tableOperators(
            itemValue,
            tokenValue,
            "!=",
            getNumberFromString,
            "Not Available"
          ),
      },
      {
        operator: ">=",
        match: (itemValue, tokenValue) =>
          tableOperators(
            itemValue,
            tokenValue,
            ">=",
            getNumberFromString,
            "Not Available"
          ),
      },
      {
        operator: "<=",
        match: (itemValue, tokenValue) =>
          tableOperators(
            itemValue,
            tokenValue,
            "<=",
            getNumberFromString,
            "Not Available"
          ),
      },
      {
        operator: ">",
        match: (itemValue, tokenValue) =>
          tableOperators(
            itemValue,
            tokenValue,
            ">",
            getNumberFromString,
            "Not Available"
          ),
      },
      {
        operator: "<",
        match: (itemValue, tokenValue) =>
          tableOperators(
            itemValue,
            tokenValue,
            "<",
            getNumberFromString,
            "Not Available"
          ),
      },
    ],
  },
  {
    propertyLabel: "Approved Cash Amount",
    key: "approvedCashAmount",
    groupValuesLabel: "Approved Cash Amount values",
    operators: ["=", "!="],
  },
  {
    propertyLabel: "Approved Credit Amount",
    key: "approvedCreditAmount",
    groupValuesLabel: "Approved Credit Amount values",
    operators: ["=", "!="],
  },
  {
    propertyLabel: "Public Sector",
    key: "publicSector",
    groupValuesLabel: "Public Sector values",
    operators: ["=", "!="],
  },
  {
    propertyLabel: "PO Number",
    key: "poNumber",
    groupValuesLabel: "PO Number values",
    operators: ["=", "!="],
  },
  {
    key: "createdOn",
    propertyLabel: "Created on",
    groupValuesLabel: "Created on value",
    operators: ["=", "!=", "<", "<=", ">", ">="].map((operator) => ({
      operator,
      form: DateForm,
      match: "date",
    })),
  },
  {
    key: "lastUpdatedOn",
    propertyLabel: "Last Updated on",
    groupValuesLabel: "Last Updated on value",
    operators: ["=", "!=", "<", "<=", ">", ">="].map((operator) => ({
      operator,
      form: DateForm,
      match: "date",
    })),
  },
  {
    key: "partnerLastInteractionOn",
    propertyLabel: "Partner Last Interaction on",
    groupValuesLabel: "Partner Last Interaction on value",
    operators: ["=", "!=", "<", "<=", ">", ">="].map((operator) => ({
      operator,
      form: DateForm,
      match: "date",
    })),
  },
  {
    key: "cashClaimStages",
    propertyLabel: "Cash Claim Stages",
    groupValuesLabel: "Cash Claim Stages values",
    operators: [
      {
        operator: "=",
        form: CustomCheckboxForm(cashClaimStageCheckboxOptions),
        format: (value) => (value || []).join(", "),
        match: (itemValue, tokenValue) =>
          tokenValue &&
          itemValue &&
          itemValue.length > 0 &&
          itemValue.some((v) => tokenValue.includes(v)),
      },
    ],
  },
  {
    key: "strategicCollaborationAgreement",
    propertyLabel: "Strategic Collaboration Agreement",
    groupValuesLabel: "Strategic Collaboration Agreement value",
    operators: ["=", "!="],
  },
];

export const PROPERTY_FILTERING_I18N_CONSTANTS = {
  filteringAriaLabel: "FR search bar",
  dismissAriaLabel: "Dismiss",
  filteringPlaceholder: "Find resources",
  groupValuesText: "Values",
  groupPropertiesText: "Properties",
  operatorsText: "Operators",
  operationAndText: "and",
  operationOrText: "or",
  operatorLessText: "Less than",
  operatorLessOrEqualText: "Less than or equal",
  operatorGreaterText: "Greater than",
  operatorGreaterOrEqualText: "Greater than or equal",
  operatorContainsText: "Contains",
  operatorDoesNotContainText: "Does not contain",
  operatorEqualsText: "Equals",
  operatorDoesNotEqualText: "Does not equal",
  editTokenHeader: "Edit filter",
  propertyText: "Property",
  operatorText: "Operator",
  valueText: "Value",
  cancelActionText: "Cancel",
  applyActionText: "Apply",
  allPropertiesLabel: "All properties",
  tokenLimitShowMore: "Show more",
  tokenLimitShowFewer: "Show fewer",
  clearFiltersText: "Clear filters",
  removeTokenButtonAriaLabel: () => "Remove token",
};
