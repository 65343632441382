import { Action } from "../../../../constant";
import { ROLES } from "../../../../../../util/constants/roles";

export const rootEntityListLengthValidation = {
  limit: 1000,
  message: "maximum number of root entity items allowed is 1000.",
};

export const entityListLengthValidation = {
  limit: 50,
  message: "maximum number of entity items allowed is 50.",
};

export const itemInputValidation = {
  name: {
    limit: 50,
    message: "maximum characters limit is 50",
  },
  value: {
    limit: 1000,
    message: "maximum characters limit is 1000",
  },
  reason: {
    limit: 150,
    message: "maximum characters limit is 150",
  },
};

export const modalErrorMessage = {
  delete: "*please enter 'delete' to delete this entity.",
  deploy: "*please enter 'deploy' to confirm deployment of new entity.",
  required: "*required.",
  invalidEntity:
    "*please enter valid entity name in PascalCasing and alphanumeric characters.",
};

export const getModalHeaderName = {
  [Action.CREATE]: "Create Entity Item",
  [Action.EDIT]: "Edit Entity Item",
  [Action.DELETE]: "Delete Entity Item",
};

export const getSubmitButtonName = {
  [Action.CREATE]: "Create",
  [Action.EDIT]: "Update",
  [Action.DELETE]: "Delete",
};

export const getItemSuccessNotificationContent = {
  [Action.CREATE]: "Your Item has been successfully Created.",
  [Action.EDIT]: "Your Item has been successfully Updated.",
  [Action.DELETE]: "Your Item has been successfully Deleted.",
  [Action.DEPLOY]: "Your Entity has been successfully Deployed.",
};

export const getUserRoles = () => {
  return Object.values(ROLES)?.map((role) => ({
    label: role,
    value: role,
  }));
};
