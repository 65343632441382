import React, { useRef } from "react";
import {
  ERROR_TYPE,
  hasError,
  retryApiCall,
} from "../../../../../shared/util/services/data/DataService";
import { setErrorMessage } from "../../../../../shared/util/common/helper";
import PropTypes from "prop-types";
import { FundRequestDuplicatesTable } from "./DuplicateFundRequestsTable";
import { STAGE } from "../../../../../shared/util/constants/fundRequestStatusType";
import { PROGRAM_TYPE } from "../../../../../shared/util/constants/programType";
import Link from "@amzn/awsui-components-react/polaris/link";

const FundRequestsDuplicatesSection = ({
  setNotificationsItems,
  stage,
  program,
}) => {
  const Ref = useRef();

  const scrollToTable = () => {
    Ref.current = document.getElementById("duplicates_section");
    Ref.current?.scrollIntoView({ behavior: "smooth" });
  };
  const createdStage = STAGE.CREATED;
  const duplicatePrograms = [
    PROGRAM_TYPE.SMP,
    PROGRAM_TYPE.MAP2024,
    PROGRAM_TYPE.POC,
    PROGRAM_TYPE.MISC,
  ];
  const getDuplicateFundRequests = async ({
    getFundRequestDuplicates,
    setIsLoading,
  }) => {
    const data = [];
    try {
      const response = await retryApiCall({
        callApi: getFundRequestDuplicates({}),
      });
      console.log(response);
      if (hasError(response) && response.errorType === ERROR_TYPE.BANNER) {
        setErrorMessage({
          setNotificationsItems,
          content: response.message,
          status: response.status,
        });
        setIsLoading(false);
      }

      data.push(...response.duplicates);
    } catch (e) {
      console.error(e);
      setIsLoading(false);
    }

    if (data.length > 0) {
      setNotificationsItems([
        {
          type: "warning",
          content: (
            <React.Fragment>
              We identified possible duplicates with this fund request. Refer to
              the{" "}
              <Link onFollow={scrollToTable}>
                Fund Request Duplicates Table
              </Link>{" "}
              for additional details.
            </React.Fragment>
          ),
          dismissible: true,
          dimissLabel: "Dismiss message",
          onDismiss: () => setNotificationsItems([]),
          id: "duplicates_warning",
        },
      ]);
    }
    return data;
  };

  return duplicatePrograms.includes(program) &&
    !stage.includes(createdStage) ? (
    <div id={"duplicates_section"}>
      <FundRequestDuplicatesTable
        getDuplicateFundRequests={getDuplicateFundRequests}
        ref={Ref}
      />
    </div>
  ) : (
    <></>
  );
};

FundRequestsDuplicatesSection.propTypes = {
  setNotificationsItems: PropTypes.func,
  stage: PropTypes.string,
  program: PropTypes.string,
};

export default FundRequestsDuplicatesSection;
