import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useCollection } from "@amzn/awsui-collection-hooks";
import Grid from "@amzn/awsui-components-react/polaris/grid";
import Select from "@amzn/awsui-components-react/polaris/select";
import Input from "@amzn/awsui-components-react/polaris/input";
import Table from "@amzn/awsui-components-react/polaris/table";
import Pagination from "@amzn/awsui-components-react/polaris/pagination";
import { columnDefinitions } from "./config";
import TableNoMatchState from "../../common/TableNoMatchState";
import TableEmptyState from "../../common/TableEmptyState";
import TableHeader from "../common/TableHeader";
import { defaultTemplate, defaultProgram } from "./config";
import { clearFilter, filterItem, prepareSelectOptions } from "./util";
import {
  ERROR_TYPE,
  hasError,
  retryApiCall,
} from "../../../../shared/util/services/data/DataService";
import { getCredits } from "../../../util/services/data/FundRequestService";
import { setErrorMessage } from "../../../../shared/util/common/helper";
import { convertSMPtoMAP } from "../../../../shared/util/common/convertSMPtoMAP";

const CreditOverview = ({ setNotificationsItems }) => {
  const pageSize = 10;
  const [creditOverviewData, setCreditOverviewData] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  useEffect(async () => {
    await getCreditOverviewData();
  }, []);

  const [template, setTemplate] = useState(defaultTemplate);
  const [program, setProgram] = useState(defaultProgram);

  const getCreditOverviewData = async () => {
    setIsLoading(true);
    setCreditOverviewData([]);
    const data = [];
    let token = "";
    while (token != null) {
      try {
        let response = await retryApiCall({
          callApi: getCredits(token),
        });

        if (hasError(response) && response.errorType === ERROR_TYPE.BANNER) {
          setErrorMessage({
            setNotificationsItems,
            content: response.message,
            status: response.status,
          });
          setIsLoading(false);
          return;
        }

        console.log(response);

        for (let item of response.items) {
          data.push({
            fundRequestId: item.fundRequestId,
            awsPromoCode: item.awsPromoCode,
            amount: item.amount,
            awsAccountId: item.awsAccountId,
            codeRedeemed: item.codeRedeemed,
            expirationDate: item.expirationDate,
            issuanceDate: item.issuanceDate,
            fundRequestStage: item.fundRequestStage,
            template: convertSMPtoMAP(item.template),
            program: convertSMPtoMAP(item.program),
            status: item.status,
            stage: item.stage,
          });
        }

        token = response.nextPageToken;
      } catch (err) {
        console.error(err);
        setErrorMessage({
          setNotificationsItems,
        });
      }
    }
    console.log(data);
    setCreditOverviewData(data);
    setIsLoading(false);
  };

  const {
    items,
    allPageItems,
    actions,
    collectionProps,
    filterProps,
    paginationProps,
  } = useCollection(creditOverviewData, {
    filtering: {
      empty: <TableEmptyState resourceName="credit" />,
      noMatch: (
        <TableNoMatchState
          onClearFilter={() => clearFilter(actions, setTemplate, setProgram)}
        />
      ),
      filteringFunction: (item, filteringText) => {
        return filterItem(item, filteringText, template, program);
      },
    },
    pagination: { pageSize },
    sorting: {},
  });

  const selectTemplateOptions = prepareSelectOptions(
    creditOverviewData,
    "template",
    defaultTemplate
  );

  const selectProgramOptions = prepareSelectOptions(
    creditOverviewData,
    "program",
    defaultProgram
  );

  return (
    <Table
      {...collectionProps}
      columnDefinitions={columnDefinitions}
      items={items}
      allPageItems={allPageItems}
      loading={isLoading}
      loadingText="Loading credits..."
      filter={
        <Grid gridDefinition={[{ colspan: 4 }, { colspan: 1 }, { colspan: 1 }]}>
          <Input
            type="search"
            value={filterProps.filteringText}
            onChange={(event) => {
              actions.setFiltering(event.detail.value);
            }}
            placeholder="Find resources"
            label="Find resources"
            ariaDescribedby={null}
          />
          <Select
            selectedOption={template}
            onChange={(event) => {
              setTemplate(event.detail.selectedOption);
            }}
            options={selectTemplateOptions}
          />
          <Select
            selectedOption={program}
            onChange={(event) => {
              setProgram(event.detail.selectedOption);
            }}
            options={selectProgramOptions}
          />
        </Grid>
      }
      header={
        <TableHeader
          headerName="Credit Overview"
          itemsLength={items.length}
          allItemsLength={creditOverviewData.length}
          refresh={getCreditOverviewData}
          data={allPageItems}
          setNotificationsItems={setNotificationsItems}
        />
      }
      pagination={<Pagination {...paginationProps} />}
    />
  );
};

CreditOverview.propTypes = {
  setNotificationsItems: PropTypes.func,
};

export default CreditOverview;
