import { useState, useEffect } from "react";
import { isFeatureEnabled } from "../services/features/FeatureFlagsService";

// Use this function when there is no useEffect in the components for feature flags.
const useFeatureFlag = (flagName) => {
  const [enabled, setEnabled] = useState(false);

  useEffect(async () => {
    const featureFlag = await isFeatureEnabled(flagName);
    setEnabled(featureFlag);
  }, []);

  return enabled;
};

export default useFeatureFlag;
