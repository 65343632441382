import Dashboard from "../components/Dashboard";
import Credit from "../components/Admin/Credit";
import { ROLES } from "../util/constants/roles";
import Review from "../components/Review";
import { PAGES } from "../util/constants/pages";
import { AWSApprovalEditContainer } from "../components/Edit/Pages/AWSApprovalEditContainer";
import { BusinessApprovalEditContainer } from "../components/Edit/Pages/BusinessApprovalEditContainer";
import { FinanceApprovalEditContainer } from "../components/Edit/Pages/FinanceApprovalEditContainer";
import { PreApprovalEditContainer } from "../components/Edit/Pages/PreApprovalEditContainer";
import { CashClaimApprovalEditContainer } from "../components/Edit/Pages/CashClaimApprovalEditContainer";
import DelegationsPage from "../pages/delegations/DelegationsPage";
import ProgramSelectEditPage from "../../internal/pages/ProgramSelect/Edit";
import AuditsPage from "../pages/audits/AuditsPage";
import SelfServicePage from "../pages/SelfService/SelfServicePage";
import SelfServiceEntityPage from "../pages/SelfService/SelfServiceEntityPage";
import BulkActionsPage from "../pages/BulkActions/BulkActionsPage";
import BulkActionsReviewPage from "../pages/BulkActions/BulkActionsReviewPage";
import TestComponent from "../components/Test/Test";
import MAPProjectDetailsEditPage from "../programs/migration-acceleration-program/2024/fund-request/pages/MAPProjectDetailsEditPage";
import MAPCashRequestDetailsEditPage from "../programs/migration-acceleration-program/2024/fund-request/pages/MAPCashRequestDetailsEditPage";
import MAPAttachmentsEditPage from "../programs/migration-acceleration-program/2024/fund-request/pages/MAPAttachmentsEditPage";
import MAPMigrationDetailsEditPage from "../programs/migration-acceleration-program/2024/fund-request/pages/MAPMigrationDetailsEditPage";
import FundRequestDetailsPage from "../pages/details/FundRequestDetailsPage";
import MAPClaimInvoiceEditPage from "../programs/migration-acceleration-program/2024/fund-request/pages/MAPClaimInvoiceEditPage"; // Order matters here. The first item that shows up will be the default page that gets loaded for users.

// Order matters here. The first item that shows up will be the default page that gets loaded for users.
const possibleRoutes = [
  {
    name: "Test Page",
    permitted: [ROLES.FUNDING_ADMIN],
    path: "/test",
    main: false,
    component: TestComponent,
  },
  {
    name: "Self Service",
    permitted: [ROLES.SELF_SERVICE_USER],
    path: "/self-service",
    main: false,
    component: SelfServicePage,
  },
  {
    name: "Self Service Entity",
    permitted: [ROLES.SELF_SERVICE_USER],
    path: "/self-service/:entity/",
    main: false,
    component: SelfServiceEntityPage,
  },
  {
    name: "Bulk Actions",
    permitted: [ROLES.FUNDING_OPS],
    path: "/bulk-actions",
    main: false,
    component: BulkActionsPage,
  },
  {
    name: "Bulk Action Submission Review",
    permitted: [ROLES.FUNDING_OPS],
    path: "/bulk-actions/review",
    main: false,
    component: BulkActionsReviewPage,
  },
  {
    name: "Audit",
    permitted: [
      ROLES.PARTNER_MANAGER,
      ROLES.FUNDING_APPROVER,
      ROLES.FUNDING_OPS,
      ROLES.TECH_AUDITOR,
      ROLES.CASH_CLAIM_AUDITOR,
    ],
    path: "/audits",
    main: false,
    component: AuditsPage,
  },
  {
    name: "Delegation",
    permitted: [
      ROLES.PARTNER_MANAGER,
      ROLES.FUNDING_APPROVER,
      ROLES.FUNDING_OPS,
    ],
    path: "/delegations",
    main: false,
    component: DelegationsPage,
  },
  {
    name: "Edit Template",
    permitted: [
      ROLES.PARTNER_MANAGER,
      ROLES.FUNDING_APPROVER,
      ROLES.FUNDING_OPS,
    ],
    path: "/fund-requests/:id/select-program/edit",
    main: false,
    component: ProgramSelectEditPage,
  },
  {
    name: "Review",
    permitted: [
      ROLES.PARTNER_MANAGER,
      ROLES.FUNDING_APPROVER,
      ROLES.FUNDING_OPS,
    ],
    path: "/:page/:id/review",
    main: false,
    component: Review,
  },
  {
    name: "Edit",
    permitted: [ROLES.PARTNER_MANAGER],
    path: `/${PAGES.AWS_APPROVAL}/:id/:section/edit`,
    main: false,
    component: AWSApprovalEditContainer,
  },

  {
    name: "Edit",
    permitted: [ROLES.FUNDING_APPROVER],
    path: `/${PAGES.TECH_APPROVAL}/:id/:section/edit`,
    main: false,
    component: AWSApprovalEditContainer,
  },
  {
    name: "Edit",
    permitted: [ROLES.FUNDING_APPROVER],
    path: `/${PAGES.BUSINESS_APPROVAL}/:id/:section/edit`,
    main: false,
    component: BusinessApprovalEditContainer,
  },
  {
    name: "Edit",
    permitted: [ROLES.FUNDING_OPS],
    path: `/${PAGES.FINANCE_APPROVAL}/:id/:section/edit`,
    main: false,
    component: FinanceApprovalEditContainer,
  },
  {
    name: "Edit",
    permitted: [ROLES.FUNDING_OPS],
    path: `/${PAGES.PRE_APPROVAL}/:id/:section/edit`,
    main: false,
    component: PreApprovalEditContainer,
  },
  {
    name: "Edit Cash Claim",
    permitted: [ROLES.FUNDING_OPS],
    path: `/${PAGES.CASH_CLAIM_APPROVAL}/:id/:section/:cashClaimId/edit`,
    main: false,
    component: CashClaimApprovalEditContainer,
  },
  {
    name: "Edit",
    permitted: [ROLES.FUNDING_OPS],
    path: `/${PAGES.CASH_CLAIM_APPROVAL}/:id/:section/edit`,
    main: false,
    component: CashClaimApprovalEditContainer,
  },
  {
    name: "Edit Fund Claim Attachments",
    permitted: [ROLES.FUNDING_OPS],
    path: `/${PAGES.CASH_CLAIM_APPROVAL}/:id/:section/:cashClaimId/attachments/edit`,
    main: false,
    component: CashClaimApprovalEditContainer,
  },
  {
    name: "Credit",
    permitted: [ROLES.FUNDING_OPS],
    path: "/credit",
    main: false,
    component: Credit,
  },
  {
    name: "Edit Fund Request Details",
    permitted: [
      ROLES.FUNDING_ADMIN,
      ROLES.PARTNER_MANAGER,
      ROLES.FUNDING_APPROVER,
      ROLES.FUNDING_OPS,
    ],
    path: `/map2024/:id/project-details/edit`,
    main: false,
    component: MAPProjectDetailsEditPage,
  },
  {
    name: "Edit Cash Request Details",
    permitted: [
      ROLES.FUNDING_ADMIN,
      ROLES.PARTNER_MANAGER,
      ROLES.FUNDING_APPROVER,
      ROLES.FUNDING_OPS,
    ],
    path: `/map2024/:id/cash-request-details/edit`,
    main: false,
    component: MAPCashRequestDetailsEditPage,
  },
  {
    name: "Edit Claim Invoice",
    permitted: [
      ROLES.FUNDING_ADMIN,
      ROLES.PARTNER_MANAGER,
      ROLES.FUNDING_APPROVER,
      ROLES.FUNDING_OPS,
    ],
    path: `/map2024/:id/:cashClaimId/claim-invoice/edit`,
    main: false,
    component: MAPClaimInvoiceEditPage,
  },
  {
    name: "Edit Migration Details",
    permitted: [
      ROLES.FUNDING_ADMIN,
      ROLES.PARTNER_MANAGER,
      ROLES.FUNDING_APPROVER,
      ROLES.FUNDING_OPS,
    ],
    path: `/map2024/:id/migration-details/edit`,
    main: false,
    component: MAPMigrationDetailsEditPage,
  },
  {
    name: "Edit Attachments",
    permitted: [
      ROLES.FUNDING_ADMIN,
      ROLES.PARTNER_MANAGER,
      ROLES.FUNDING_APPROVER,
      ROLES.FUNDING_OPS,
    ],
    path: `/map2024/:id/attachments/edit`,
    main: false,
    component: MAPAttachmentsEditPage,
  },
  {
    name: "Fund Request Details",
    permitted: [
      ROLES.FUNDING_ADMIN,
      ROLES.PARTNER_MANAGER,
      ROLES.FUNDING_APPROVER,
      ROLES.FUNDING_OPS,
    ],
    path: `/fund-requests/:id/details`,
    main: false,
    component: FundRequestDetailsPage,
  },
];

export const routeFactory = (roles) => {
  let routes = [
    {
      name: "Dashboard",
      permitted: [
        ROLES.PARTNER_MANAGER,
        ROLES.FUNDING_APPROVER,
        ROLES.FUNDING_OPS,
      ],
      path: "/dashboard",
      main: true,
      component: Dashboard,
    },
  ];
  const addedRoutes = new Set();
  if (roles.includes(ROLES.FUNDING_ADMIN)) {
    routes = routes.concat(possibleRoutes);
  } else {
    for (let role of roles) {
      routes = routes.concat(
        possibleRoutes.filter((route) => {
          const permitted =
            !addedRoutes.has(route.path) && route.permitted.includes(role);
          if (!addedRoutes.has(route.path) && route.permitted.includes(role)) {
            addedRoutes.add(route.path);
          }
          return permitted;
        })
      );
    }
  }

  return routes;
};
