import { Badge } from "@amzn/awsui-components-react";
import React from "react";

export const DELEGATION_STATUS = {
  ACTIVE: "ACTIVE",
  EXPIRED: "EXPIRED",
  SCHEDULED: "SCHEDULED",
  CANCELLED: "CANCELLED",
};

export const statusBadge = (status) => {
  switch (status) {
    case DELEGATION_STATUS.ACTIVE:
      return <Badge color="green">ACTIVE</Badge>;
    case DELEGATION_STATUS.EXPIRED:
    case DELEGATION_STATUS.CANCELLED:
      return <Badge color="red">{status}</Badge>;
    case DELEGATION_STATUS.SCHEDULED:
      return <Badge color="blue">SCHEDULED</Badge>;
    default:
      return <Badge color="blue">{status}</Badge>;
  }
};
