import React, { useEffect, useState } from "react";
import {
  NonCancelableCustomEvent,
  RadioGroup,
} from "@amzn/awsui-components-react";
import {
  FormField,
  RadioGroupProps,
  SpaceBetween,
} from "@amzn/awsui-components-react/polaris";
import { FileUploadComponent } from "shared/programs/migration-acceleration-program/2024/fund-request/components/attachments/FileUploadComponent";
import { MAX_FILE_SIZE_IN_MB } from "external/programs/migration-acceleration-program/2024/fund-request/components/create/wizard/Steps/Artifacts/util";
import { IArtifactFileModel } from "shared/programs/migration-acceleration-program/2024/fund-request/components/attachments/types/fileTypes";
import {
  equals,
  getArtifacts,
} from "shared/programs/migration-acceleration-program/2024/fund-request/components/attachments/util";
import { IGenericObject } from "shared/programs/migration-acceleration-program/2024/fund-request/types/CommonTypes";
import { attachFileToCashClaim } from "external/util/services/data/FundRequestService";
import { useParams } from "react-router-dom";
import { IClaimActualsContainerProps } from "external/programs/migration-acceleration-program/2024/fund-request/views/MAPCashClaimActualsSubmission";

const SOWRadioGroup = ({
  data,
  setData,
  radioGroupErrorText,
  setRadioGroupErrorText,
}: IClaimActualsContainerProps) => {
  const { claimId } = useParams<IGenericObject>();
  const [enableUpload, setEnableUpload] = useState<boolean>(false);

  const [files, setFiles] = React.useState<File[]>([]);
  const [fileIdToAttributes, setFileIdToAttributes] =
    useState<IArtifactFileModel>({});

  const extractHasSowChangedValueFromData = () => {
    if (data?.hasSowChanged === true) {
      return "yes";
    }
    if (data?.hasSowChanged === false) {
      return "no";
    }
    return data?.hasSowChanged;
  };

  useEffect(() => {
    const documents = data?.artifacts?.documents;
    if (!documents) return;
    const artifacts = getArtifacts({
      documents,
      filter: (actualType) => equals(actualType, "Statement of Work"),
    });
    if (data?.hasSowChanged === true) {
      setEnableUpload(true);
    }
    setFiles(artifacts.files || []);
    setFileIdToAttributes(artifacts.fileIdToAttributes);
  }, [data]);

  const radioItems: RadioGroupProps.RadioButtonDefinition[] = [
    { value: "yes", label: "Yes, the scope of work changed." },
    { value: "no", label: "No, the scope of work did not change." },
  ];

  const claimAttach = async ({
    body,
    fundRequestId,
  }: {
    body: unknown;
    fundRequestId: unknown;
  }) => {
    const newBody = {
      ...(body as IGenericObject),
      fundClaimId: claimId,
    };
    const response = await attachFileToCashClaim({
      body: newBody,
      fundRequestId,
      fundClaimId: claimId,
    });
    return response;
  };

  return (
    <FormField
      label="Scope of work (SoW) modification"
      description="If deliverables differ from what was originally shared with the customer a new SoW needs to be uploaded."
      errorText={radioGroupErrorText}
    >
      <SpaceBetween size="l">
        <RadioGroup
          onChange={(
            event: NonCancelableCustomEvent<RadioGroupProps.ChangeDetail>
          ) => {
            setRadioGroupErrorText("");
            const selectedValue = event.detail.value;
            setData((prev) => ({
              ...prev,
              hasSowChanged: event.detail.value,
            }));
            setEnableUpload(selectedValue == "yes");
          }}
          value={extractHasSowChangedValueFromData()}
          items={radioItems}
        />

        {enableUpload && (
          <FileUploadComponent
            artifacts={{ files, fileIdToAttributes }}
            updateFiles={setFiles}
            setData={setData}
            setFileIdToAttributes={setFileIdToAttributes}
            supportingDocumentType={"Statement of Work"}
            label={"Upload the latest statement of work (SOW)"}
            description={""}
            constraintText={`File size limit: ${MAX_FILE_SIZE_IN_MB} MB`}
            attach={claimAttach}
            claimId={claimId}
          />
        )}
      </SpaceBetween>
    </FormField>
  );
};

export default SOWRadioGroup;
