export const getFundRequestId = () => {
  return document.location.pathname.split("/")[2];
};

export const getClaimId = () => {
  return document.location.pathname.split("/")[3];
};

export const setErrorMessage = (setNotificationsItems, body) => {
  let errorMessage;
  if (body.message) {
    errorMessage = body.message;
  } else {
    errorMessage =
      "Error has occurred, please try again or contact your AWS Partner Manager";
  }

  setNotificationsItems([
    {
      header: errorMessage,
      type: "error",
      dismissible: true,
      dismissLabel: "Dismiss message",
      onDismiss: () => setNotificationsItems([]),
    },
  ]);
};

export const setSuccessMessage = (setNotificationsItems, infoMessage) => {
  setNotificationsItems([
    {
      header: infoMessage,
      type: "success",
      dismissible: true,
      dismissLabel: "Dismiss message",
      onDismiss: () => setNotificationsItems([]),
    },
  ]);
};

export const capitalize = (string) =>
  string && string[0].toUpperCase() + string.slice(1);

export const ConvertStringToPascalCase = (str) => {
  return (" " + str).replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) =>
    chr.toUpperCase()
  );
};
