import { InMemoryStorage } from "./InMemoryStorage";

export const isSessionStorageSupported = () => {
  let supported = true;
  try {
    sessionStorage.setItem("support", "true");
    sessionStorage.removeItem("support");
  } catch (e) {
    console.warn(
      "SessionStorage is not supported. Defaulting to memory storage."
    );
    supported = false;
  }
  return supported;
};

const SessionManager = () => {
  const ID_TOKEN_KEY = "id_token";
  const ACCESS_TOKEN_KEY = "access_token";
  const REFRESH_TOKEN_KEY = "refresh_token";
  const TOKEN_EXPIRATION_KEY = "token_expiration";
  const ORIGIN_URL = "origin-url";

  const _storage = isSessionStorageSupported()
    ? sessionStorage
    : InMemoryStorage();

  const setOriginUrl = (url) => {
    _storage.setItem(ORIGIN_URL, url);
  };

  const setIdToken = (id_token) => {
    _storage.setItem(ID_TOKEN_KEY, id_token);
  };

  const setAccessToken = (access_token) => {
    _storage.setItem(ACCESS_TOKEN_KEY, access_token);
  };

  const setRefreshToken = (refresh_token) => {
    _storage.setItem(REFRESH_TOKEN_KEY, refresh_token);
  };

  const setTokenExpiration = (expiration) => {
    _storage.setItem(TOKEN_EXPIRATION_KEY, expiration);
  };

  const getOriginUrl = () => {
    return _storage.getItem(ORIGIN_URL);
  };

  const getAccessToken = () => {
    return _storage.getItem(ACCESS_TOKEN_KEY);
  };

  const getRefreshToken = () => {
    return _storage.getItem(REFRESH_TOKEN_KEY);
  };

  const getIdToken = () => {
    return _storage.getItem(ID_TOKEN_KEY);
  };

  const getTokenExpiration = () => {
    return _storage.getItem(TOKEN_EXPIRATION_KEY);
  };

  const removeOriginUrl = () => {
    _storage.removeItem(ORIGIN_URL);
  };

  const removeIdToken = () => {
    _storage.removeItem(ID_TOKEN_KEY);
  };

  const removeAccessToken = () => {
    _storage.removeItem(ACCESS_TOKEN_KEY);
  };

  const removeRefreshToken = () => {
    _storage.removeItem(REFRESH_TOKEN_KEY);
  };

  const removeTokenExpiration = () => {
    _storage.removeItem(TOKEN_EXPIRATION_KEY);
  };

  return {
    setOriginUrl,
    setIdToken,
    setAccessToken,
    setRefreshToken,
    setTokenExpiration,
    getOriginUrl,
    getAccessToken,
    getRefreshToken,
    getIdToken,
    getTokenExpiration,
    removeOriginUrl,
    removeIdToken,
    removeAccessToken,
    removeRefreshToken,
    removeTokenExpiration,
  };
};

export default SessionManager();
