import React, { useState } from "react";
import PropTypes from "prop-types";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Button from "@amzn/awsui-components-react/polaris/button";
import Box from "@amzn/awsui-components-react/polaris/box";
import Icon from "@amzn/awsui-components-react/polaris/icon";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import {
  ERROR_TYPE,
  hasError,
  retryApiCall,
} from "../../../../../shared/util/services/data/DataService";
import {
  getUploadUrl,
  uploadCredits,
  // checkStatusSuccess,
} from "../../../../util/services/data/InternalDataService";
import { setSuccessMessage } from "../../../../util/helper";
import { formatFileSize } from "../../../../../shared/util/common/helper";
import { setErrorMessage } from "../../../../../shared/util/common/helper";

const UploadComponent = ({
  uploadComponentVisible,
  setUploadComponentVisible,
  setNotificationsItems,
}) => {
  const [file, setFile] = useState();
  const [loading, setLoading] = useState(false);

  const resetUploadStatus = () => {
    setFile();
    setLoading(false);
    setUploadComponentVisible(false);
  };

  const uploadFile = async () => {
    setLoading(true);
    const nameArr = file.name.split(".");

    try {
      let response = await retryApiCall({
        callApi: getUploadUrl({
          fileType: `text/${nameArr[nameArr.length - 1]}`,
        }),
      });

      if (hasError(response) && response.errorType === ERROR_TYPE.BANNER) {
        setErrorMessage({
          setNotificationsItems,
          content: response.message,
          status: response.status,
        });
        resetUploadStatus();
        return;
      }

      response = await retryApiCall({
        callApi: uploadCredits(response.url, file),
      });

      if (hasError(response) && response.errorType === ERROR_TYPE.BANNER) {
        setErrorMessage({
          setNotificationsItems,
          content: response.message,
          status: response.status,
        });
        resetUploadStatus();
        return;
      }

      setSuccessMessage(
        setNotificationsItems,
        "Attachment was successfully and securely uploaded."
      );
    } catch (err) {
      console.error(err);
    }

    resetUploadStatus();
  };

  return (
    <Modal
      header="Upload Credits"
      onDismiss={() => setUploadComponentVisible(false)}
      visible={uploadComponentVisible}
      closeAriaLabel="Close modal"
      size="medium"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              variant="link"
              onClick={() => setUploadComponentVisible(false)}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              disabled={!file}
              loading={loading}
              onClick={async () => await uploadFile()}
            >
              Upload
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <SpaceBetween size="m">
        <FormField label="Choose Credits file to upload">
          <Button
            type="submit"
            onClick={() => document.getElementById("file-upload-input").click()}
          >
            <input
              id="file-upload-input"
              type="file"
              onChange={(event) => {
                setFile(event.target.files[0]);
              }}
              hidden
            />
            Choose File
          </Button>
        </FormField>
        {file && (
          <SpaceBetween
            direction="horizontal"
            size="xs"
            className="file-upload-container"
          >
            <Icon name="status-positive" size="normal" variant="success" />
            <Box>
              <Box className="file-name" variant="p">
                {file.name}
              </Box>
              <Box color="text-body-secondary">
                File Size is {formatFileSize(file.size)}
              </Box>
              <Box color="text-body-secondary">
                Last date modified:{" "}
                {new Date(file.lastModified).toLocaleDateString()}
              </Box>
            </Box>
          </SpaceBetween>
        )}
      </SpaceBetween>
    </Modal>
  );
};

UploadComponent.propTypes = {
  uploadComponentVisible: PropTypes.bool,
  setUploadComponentVisible: PropTypes.func,
  setNotificationsItems: PropTypes.func,
};

export default UploadComponent;
