import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Header from "@amzn/awsui-components-react/polaris/header";
import Button from "@amzn/awsui-components-react/polaris/button";
import ApprovalList from "./ApprovalList";
import FundRequestsQSReview from "./FundRequestsQSReview";
import { setSuccessMessage } from "../../../shared/util/common/helper";
import withAppLayout from "../../../shared/components/withAppLayout";
import { getInternalRoles } from "../../util/services/auth/AuthService";
import { ROLES } from "../../util/constants/roles";
import "./style.css";
import SideNavigation from "../common/SideNavigation";

const Dashboard = () => {
  const [notificationsItems, setNotificationsItems] = useState([]);
  const history = useHistory();
  const { state } = useLocation();

  useEffect(() => {
    if (state && state.message) {
      setSuccessMessage(
        setNotificationsItems,
        state.action || "",
        state.message
      );
    }
  }, []);

  const showAdminPortal = () => {
    const internalRoles = getInternalRoles();

    return internalRoles.includes(ROLES.FUNDING_OPS);
  };

  return withAppLayout({
    component: (
      <SpaceBetween size="s">
        <Header
          variant="h1"
          actions={
            <SpaceBetween size="s" direction="horizontal">
              {showAdminPortal() && (
                <Button
                  iconAlign="right"
                  iconName="external"
                  onClick={() => {
                    history.push("/credit");
                  }}
                >
                  Admin Portal
                </Button>
              )}
            </SpaceBetween>
          }
        >
          My Action Items
        </Header>

        <ApprovalList setNotificationsItems={setNotificationsItems} />
        <span className="table-info">
          <q>Dwell time include weekends.</q>
        </span>
        <FundRequestsQSReview></FundRequestsQSReview>
      </SpaceBetween>
    ),
    navigationHide: false,
    navigation: <SideNavigation />,
    toolsHide: true,
    notificationsItems: notificationsItems,
  })();
};

export default Dashboard;
