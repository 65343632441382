import React from "react";
import { Button, Header, SpaceBetween } from "@amzn/awsui-components-react";
import PropTypes from "prop-types";

const BulkActionsTableHeader = ({
  itemsLength,
  allItemsLength,
  refresh,
  setIsDownloadTemplateComponentVisible,
  setNewBulkActionVisible,
}) => {
  return (
    <React.Fragment>
      <Header
        counter={"(" + itemsLength + "/" + allItemsLength + ")"}
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              iconName="refresh"
              variant="normal"
              onClick={async () => await refresh()}
            />
            <Button onClick={() => setIsDownloadTemplateComponentVisible(true)}>
              Download Template
            </Button>
            <Button
              variant="primary"
              onClick={() => setNewBulkActionVisible(true)}
            >
              New Bulk Action
            </Button>
          </SpaceBetween>
        }
      >
        Bulk Actions
      </Header>
    </React.Fragment>
  );
};

BulkActionsTableHeader.propTypes = {
  itemsLength: PropTypes.number,
  allItemsLength: PropTypes.number,
  refresh: PropTypes.func,
  setIsDownloadTemplateComponentVisible: PropTypes.func,
  setNewBulkActionVisible: PropTypes.func,
};

export default BulkActionsTableHeader;
