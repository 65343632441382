import { IFieldSchema } from "external/programs/migration-acceleration-program/2024/fund-request/types/SchemaTypes";
import { FieldType } from "shared/programs/migration-acceleration-program/2024/fund-request/components/fields/FieldComponent";
import { STAGE } from "shared/util/constants/fundRequestStatusType";
import { ValidationType } from "shared/programs/migration-acceleration-program/2024/fund-request/components/fields/validations";
import React from "react";

export const ClaimInvoiceEditSchema: IFieldSchema[] = [
  {
    label: "Cash claim ID",
    keyName: "fundClaimId",
    placeholder: "-",
    fieldType: FieldType.CopyToClipboard,
  },
  {
    label: "Project start date",
    keyName: "projectStartDate",
    fieldType: FieldType.DatePicker,
    placeholder: "YYYY/MM/DD",
    editableStages: [],
  },
  {
    label: "Claim due date",
    keyName: "dueDate",
    placeholder: "YYYY/MM/DD",
    description:
      "All claims must be submitted before this date with the required Proof of Performance attached. The claim due date is calculated as 30 days after the activity planned end date and prior to December 15 of each year.",
    fieldType: FieldType.DatePicker,
    editableStages: [],
  },
  {
    label: "Claim amount (Local Currency)",
    keyName: "claimLocalAmountCap",
    placeholder: "-",
    fieldType: FieldType.Input,
    validations: [ValidationType.ValidNumber],
  },
  {
    label: "Cash claim stage",
    keyName: "stage",
    placeholder: "-",
    fieldType: FieldType.Input,
    editableStages: [],
  },
  {
    label: "AWS Account ID for actual delivery",
    keyName: "awsAccountId",
    placeholder: "-",
    fieldType: FieldType.Input,
    editableStages: [],
    validations: [ValidationType.AwsAccountId],
  },
  {
    label: "Actual project start date",
    keyName: "startDate",
    placeholder: "YYYY/MM/DD",
    fieldType: FieldType.DatePicker,
    editableStages: [],
  },
  {
    label: "Actual project end date",
    keyName: "endDate",
    fieldType: FieldType.DatePicker,
    placeholder: "YYYY/MM/DD",
    editableStages: [],
  },
  {
    label: "Actual production date",
    keyName: "productionDate",
    fieldType: FieldType.DatePicker,
    placeholder: "YYYY/MM/DD",
    editableStages: [],
  },
  {
    label: "Actual claim amount (Local Currency)",
    keyName: "claimLocalAmount",
    placeholder: "-",
    fieldType: FieldType.Input,
    editableStages: [],
    validations: [ValidationType.ValidNumber],
  },
  {
    label: "Invoice amount (Local Currency)",
    keyName: "invoiceLocalAmount",
    placeholder: "-",
    fieldType: FieldType.Input,
    editableStages: [STAGE.INVOICE],
    validations: [ValidationType.ValidNumber],
  },
  {
    label: "Scheduled payment date",
    keyName: "scheduledPaymentDate",
    fieldType: FieldType.DatePicker,
    placeholder: "YYYY/MM/DD",
    editableStages: [STAGE.INVOICE],
  },
  {
    label: "Description",
    keyName: "description",
    placeholder: "Enter description",
    fieldType: FieldType.Textarea,
    constraintText: (value: string = "") => (
      <>
        Max of 5000 characters including special characters (e.g. space, comma,
        etc.) Character count: {value.length}/5000
      </>
    ),
    validations: [ValidationType.MaxLength5000Chars],
    editableStages: [STAGE.INVOICE],
  },
  // TODO: verify below field
  {
    label: "Proof of performance",
    keyName: "",
    fieldType: FieldType.Checkbox,
    editableStages: [],
  },
];
