import React from "react";
import Icon from "@amzn/awsui-components-react/polaris/icon";
import { FUNDING_TYPE, PROGRAM_TYPE } from "../../util/constants/programType";
import { STAGE } from "../../util/constants/fundRequestStatusType";

export const STAGE_NAME = {
  created: "Created",
  review: "AWS Review",
  tech_approval: "Tech Approval",
  business_approval: "Business Approval",
  finance_approval: "Finance Approval",
  pre_approval: "Pre-Approval",
  cash_claim: "Cash Claim",
  completed: "Completed",
};

//TODO: Unit Test
export const getStatusNameList = (program, fundingType) => {
  const reviewStagePrograms = [
    PROGRAM_TYPE.SMP,
    PROGRAM_TYPE.MISC,
    PROGRAM_TYPE.POC,
  ];

  return Object.keys(STAGE_NAME).filter((key) => {
    if (
      fundingType !== FUNDING_TYPE.CASH &&
      fundingType !== FUNDING_TYPE.COMBO &&
      key === STAGE.CASH_CLAIM
    ) {
      return false;
    }

    if (
      program !== PROGRAM_TYPE.SMP &&
      program !== PROGRAM_TYPE.POC &&
      key === STAGE.TECH_APPROVAL
    ) {
      return false;
    }

    return !(!reviewStagePrograms.includes(program) && key === STAGE.REVIEW);
  });
};

export const showApprovedAmount = ({
  approvedCashAmount,
  approvedCreditAmount,
  stage,
}) =>
  ((approvedCashAmount && !isNaN(approvedCashAmount)) ||
    (approvedCreditAmount && !isNaN(approvedCreditAmount))) &&
  (stage === STAGE.PRE_APPROVAL ||
    stage === STAGE.CASH_CLAIM ||
    stage === STAGE.COMPLETED);

export const generateStatus = (stage, program, fundingType) => {
  const statusNameList = getStatusNameList(program, fundingType);
  const statusComponentList = [];
  const statusNameIndex =
    statusNameList.indexOf(stage) === -1 ? 0 : statusNameList.indexOf(stage);

  statusNameList.forEach((stageName, index) => {
    let component;
    if (
      index < statusNameIndex ||
      (index === statusNameIndex && stageName === "completed")
    ) {
      component = (
        <div
          key={index}
          className="review-status-text review-status-text-complete"
        >
          <Icon name="status-positive" size="small" variant="success" />{" "}
          {STAGE_NAME[stageName]}
        </div>
      );
    } else if (index > statusNameIndex) {
      component = (
        <div key={index} className="review-status-text">
          {STAGE_NAME[stageName]}
        </div>
      );
    } else {
      component = (
        <div
          key={index}
          className="review-status-text review-status-text-process"
        >
          <Icon name="status-in-progress" size="small" variant="normal" />{" "}
          {STAGE_NAME[stageName]}
        </div>
      );
    }

    statusComponentList.push(component);
  });

  return <div className="review-status">{statusComponentList}</div>;
};

export const getProgressValue = (status, program, fundingType) => {
  const statusNameList = getStatusNameList(program, fundingType);
  if (!statusNameList.includes(status)) {
    return 0;
  }

  if (status === statusNameList[statusNameList.length - 1]) {
    return 100;
  }

  return (
    Math.floor(
      ((statusNameList.indexOf(status) + 1) / statusNameList.length) * 100
    ) - 4
  );
};
