import React from "react";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Button from "@amzn/awsui-components-react/polaris/button";
import TextContent from "@amzn/awsui-components-react/polaris/text-content";
import { Header } from "@amzn/awsui-components-react";
import Box from "@amzn/awsui-components-react/polaris/box";
import Container from "@amzn/awsui-components-react/polaris/container";

export const FundRequestsQSReview = () => {
  return (
    <React.Fragment>
      <Container
        header={
          <Header
            variant="h2"
            actions={
              <SpaceBetween direction="horizontal" size="l">
                <Button
                  href="https://us-east-1.quicksight.aws.amazon.com/sn/dashboards/517a3556-eb8d-4578-85ab-6437efcbaa6b" // Temporary solution to display dashboard
                  target="_blank"
                  iconAlign="right"
                  iconName="external"
                >
                  QuickSight dashboard
                </Button>
              </SpaceBetween>
            }
          >
            Historical and Previously Approved Fund Requests
          </Header>
        }
      >
        <TextContent>
          <SpaceBetween size="l">
            <div>
              <p>
                To review the historical and previously actioned fund
                request(s), click on the QuickSight dashboard link on the right.
                Follow the instructions below to access the dashboard.
              </p>
            </div>
            <div>
              <h2>Instructions</h2>
              <Box>
                <ol>
                  <li>Make sure you are on VPN or the Amazon network</li>
                  <li>Click the QuickSight dashboard link</li>
                  <li>
                    Enter the account name = amazonbi and click continue to
                    launch the dashboard
                  </li>
                </ol>
              </Box>
            </div>
            <p>
              <b>Note:</b> The data in QuickSight is refreshed once every 4
              hours, so any new fund requests submitted or approved may not
              reflect immediately in the dashboard.
            </p>
          </SpaceBetween>
        </TextContent>
      </Container>
    </React.Fragment>
  );
};

export default FundRequestsQSReview;
