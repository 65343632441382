import React, { useState } from "react";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Button from "@amzn/awsui-components-react/polaris/button";

import { INTERNAL_REVIEW_ACTION_TYPE } from "../../../common/CommentModal/reviewActionType";
import {
  hasError,
  retryApiCall,
} from "../../../../../shared/util/services/data/DataService";
import {
  RejectFactory,
  SubmitFactory,
} from "../../../../util/services/data/dataFactory";
import {
  setBulkErrorMessages,
  setSuccessMessage,
} from "../../../../../shared/util/common/helper";
import PropTypes from "prop-types";
import CommentModal from "../../../common/CommentModal/CommentModal";
import {
  checkLoading,
  getReviewActionType,
} from "../../../common/CommentModal/util";
import { Header } from "@amzn/awsui-components-react";
import { STAGE } from "../../../../../shared/util/constants/fundRequestStatusType";
import { changeAssignee } from "../../../../util/services/data/InternalDataService";
import ChangeAssignee from "../../../Review/ChangeAssignee";
import { Extend } from "../../../Review/Extend";

export const ApprovalListHeader = ({
  counter,
  refresh,
  disabled,
  setNotificationsItems,
  selectedItems,
  selectedType,
}) => {
  const [isLoading, setIsLoading] = useState({
    [INTERNAL_REVIEW_ACTION_TYPE.APPROVE]: false,
    [INTERNAL_REVIEW_ACTION_TYPE.REJECT]: false,
    [INTERNAL_REVIEW_ACTION_TYPE.COMPLETE]: false,
    [INTERNAL_REVIEW_ACTION_TYPE.CHANGE_ASSIGNEE]: false,
  });

  const [isVisible, setIsVisible] = useState({
    [INTERNAL_REVIEW_ACTION_TYPE.APPROVE]: false,
    [INTERNAL_REVIEW_ACTION_TYPE.REJECT]: false,
    [INTERNAL_REVIEW_ACTION_TYPE.COMPLETE]: false,
    [INTERNAL_REVIEW_ACTION_TYPE.CHANGE_ASSIGNEE]: false,
  });

  const updateLoading = (actionName, loading) => {
    setIsLoading({ ...isLoading, [actionName]: loading });
  };

  const updateVisibility = (actionName, visibility) => {
    setIsVisible({ ...isVisible, [actionName]: visibility });
  };

  const handleBulkApproval = async (payload) => {
    updateLoading(INTERNAL_REVIEW_ACTION_TYPE.APPROVE, true);
    let failedFrIds = [];
    let failedFrResponse = [];
    for (const fr of selectedItems) {
      try {
        const response = await retryApiCall({
          callApi: SubmitFactory(fr.stage)({
            payload,
            fundRequestId: fr.fundRequestId,
          }),
        });
        if (hasError(response)) {
          failedFrResponse.push(response);
          failedFrIds.push(fr.fundRequestId);
        }
      } catch (e) {
        console.log(e);
        failedFrIds.push(fr.fundRequestId);
      }
    }

    if (failedFrResponse.length !== 0) {
      setBulkErrorMessages(
        setNotificationsItems,
        failedFrResponse,
        failedFrIds,
        "Failed to approve the following fund requests, please review and try again: "
      );
    } else {
      setSuccessMessage(
        setNotificationsItems,
        "Updated",
        `Successfully approved all fund requests.`
      );
    }

    updateLoading(INTERNAL_REVIEW_ACTION_TYPE.APPROVE, false);
  };

  const handleBulkReject = async (payload) => {
    updateLoading(INTERNAL_REVIEW_ACTION_TYPE.REJECT, true);
    let failedFrIds = [];
    let failedFrResponse = [];
    for (const fr of selectedItems) {
      try {
        const response = await retryApiCall({
          callApi: RejectFactory(fr.stage)({
            payload,
            fundRequestId: fr.fundRequestId,
          }),
        });
        if (hasError(response)) {
          failedFrResponse.push(response);
          failedFrIds.push(fr.fundRequestId);
        }
      } catch (e) {
        console.log(e);
        failedFrIds.push(fr.fundRequestId);
      }
    }

    if (failedFrResponse.length !== 0) {
      setBulkErrorMessages(
        setNotificationsItems,
        failedFrResponse,
        failedFrIds,
        "Failed to reject the following fund requests, please review and try again: "
      );
    } else {
      setSuccessMessage(
        setNotificationsItems,
        "Updated",
        `Successfully rejected all fund requests.`
      );
    }

    updateLoading(INTERNAL_REVIEW_ACTION_TYPE.REJECT, false);
  };

  const handleBulkComplete = async (payload) => {
    updateLoading(INTERNAL_REVIEW_ACTION_TYPE.COMPLETE, true);
    let failedFrIds = [];
    let failedFrResponse = [];
    for (const fr of selectedItems) {
      try {
        const response = await retryApiCall({
          callApi: SubmitFactory(STAGE.COMPLETED)({
            payload,
            fundRequestId: fr.fundRequestId,
          }),
        });
        if (hasError(response)) {
          failedFrResponse.push(response);
          failedFrIds.push(fr.fundRequestId);
        }
      } catch (e) {
        console.log(e);
        failedFrIds.push(fr.fundRequestId);
      }
    }

    if (failedFrResponse.length !== 0) {
      setBulkErrorMessages(
        setNotificationsItems,
        failedFrResponse,
        failedFrIds,
        "Failed to complete the following fund requests, please review and try again: "
      );
    } else {
      setSuccessMessage(
        setNotificationsItems,
        "Updated",
        `Successfully completed all fund requests.`
      );
    }

    updateLoading(INTERNAL_REVIEW_ACTION_TYPE.COMPLETE, false);
  };

  const submitChangeAssignee = async (
    newAssignee,
    assigneeType,
    internalComment
  ) => {
    updateLoading(INTERNAL_REVIEW_ACTION_TYPE.CHANGE_ASSIGNEE, true);
    let failedFrIds = [];
    let failedFrResponse = [];
    for (const fr of selectedItems) {
      try {
        const response = await retryApiCall({
          callApi: changeAssignee({
            payload: {
              assignee: { assigneeId: newAssignee, assigneeType },
              internalComment: internalComment,
            },
            fundRequestId: fr.fundRequestId,
          }),
        });
        if (hasError(response)) {
          failedFrResponse.push(response);
          failedFrIds.push(fr.fundRequestId);
        }
      } catch (e) {
        console.log(e);
      }
    }

    if (failedFrIds.length !== 0) {
      setBulkErrorMessages(
        setNotificationsItems,
        failedFrResponse,
        failedFrIds,
        "Failed to change assignees for the following fund requests, please review and try again: "
      );
    } else {
      setSuccessMessage(
        setNotificationsItems,
        "Updated",
        `Successfully changed assignees for all fund requests.`
      );
    }

    updateLoading(INTERNAL_REVIEW_ACTION_TYPE.CHANGE_ASSIGNEE, false);
    updateVisibility(INTERNAL_REVIEW_ACTION_TYPE.CHANGE_ASSIGNEE, false);
  };

  const reviewActions = {
    [INTERNAL_REVIEW_ACTION_TYPE.APPROVE]: handleBulkApproval,
    [INTERNAL_REVIEW_ACTION_TYPE.REJECT]: handleBulkReject,
    [INTERNAL_REVIEW_ACTION_TYPE.COMPLETE]: handleBulkComplete,
  };

  const selectedReviewAction = getReviewActionType({ isVisible });

  return (
    <React.Fragment>
      <Header
        variant="h1"
        counter={counter}
        actions={
          <SpaceBetween direction="horizontal" size="l">
            <Button
              iconName="refresh"
              variant="normal"
              onClick={async () => await refresh()}
            />
            <Button
              // disabled={selectedItems.length < 1}
              disabled={
                disabled || selectedType !== INTERNAL_REVIEW_ACTION_TYPE.APPROVE
              }
              loading={checkLoading({ isLoading })}
              onClick={() => {
                updateVisibility(INTERNAL_REVIEW_ACTION_TYPE.APPROVE, true);
              }}
            >
              Approve
            </Button>
            <Button
              disabled={
                disabled || selectedType !== INTERNAL_REVIEW_ACTION_TYPE.APPROVE
              }
              loading={checkLoading({ isLoading })}
              onClick={() => {
                updateVisibility(INTERNAL_REVIEW_ACTION_TYPE.REJECT, true);
              }}
            >
              Reject
            </Button>
            <Button
              disabled={disabled}
              onClick={() => {
                updateVisibility(
                  INTERNAL_REVIEW_ACTION_TYPE.CHANGE_ASSIGNEE,
                  true
                );
              }}
            >
              Reassign
            </Button>
            <Button
              disabled={
                disabled ||
                selectedType !== INTERNAL_REVIEW_ACTION_TYPE.COMPLETE
              }
              loading={checkLoading({ isLoading })}
              onClick={() => {
                updateVisibility(INTERNAL_REVIEW_ACTION_TYPE.COMPLETE, true);
              }}
            >
              Complete
            </Button>
            <Extend
              fundRequests={selectedItems}
              setNotificationsItems={setNotificationsItems}
            />
          </SpaceBetween>
        }
      >
        Pending Approvals
      </Header>
      <ChangeAssignee
        isLoading={isLoading[INTERNAL_REVIEW_ACTION_TYPE.CHANGE_ASSIGNEE]}
        isVisible={isVisible[INTERNAL_REVIEW_ACTION_TYPE.CHANGE_ASSIGNEE]}
        updateVisibility={updateVisibility}
        submitChangeAssignee={submitChangeAssignee}
      />
      <CommentModal
        reviewActionType={selectedReviewAction}
        reviewAction={reviewActions[selectedReviewAction]}
        isLoading={isLoading[selectedReviewAction]}
        isVisible={isVisible[selectedReviewAction]}
        updateVisibility={updateVisibility}
      />
    </React.Fragment>
  );
};

ApprovalListHeader.propTypes = {
  counter: PropTypes.object,
  refresh: PropTypes.func,
  disabled: PropTypes.bool,
  isDataLoading: PropTypes.bool,
  setIsDataLoading: PropTypes.func,
  setNotificationsItems: PropTypes.func,
  selectedItems: PropTypes.array,
  selectedType: PropTypes.string,
};
