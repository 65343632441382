import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { checkTokenExpiration } from "../../../util/services/auth/AuthService";
import Loading from "../../common/Loading";

export const AuthWrapper = ({ children }) => {
  const location = useLocation();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  useEffect(async () => {
    const { pathname } = location;

    if (pathname !== "/auth" && pathname !== "/login") {
      if (await checkTokenExpiration()) {
        setIsLoggedIn(true);
      }
    }
  }, [location]);
  return isLoggedIn ? <React.Fragment>{children}</React.Fragment> : <Loading />;
};

AuthWrapper.propTypes = {
  children: PropTypes.element,
};
