import React, { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Error from "../Error";
import { routeFactory } from "../../../config/roles";
import Auth from "../../../../shared/components/Authentication/Auth/Auth";
import Login from "../../../../shared/components/Authentication/Login";
import withAppLayout from "../../../../shared/components/withAppLayout";
import { getInternalRoles } from "../../../util/services/auth/AuthService";
import AuthWrapper from "../../../../shared/components/Authentication/AuthWrapper";
import SessionManager from "../../../../shared/util/services/auth/SessionManager";
import ErrorBoundary from "../../../../shared/components/common/ErrorBoundary";

const Routes = () => {
  const userRoles = getInternalRoles();
  const routes = routeFactory(userRoles);
  let rootRedirect = "/dashboard";

  useEffect(() => {
    const { pathname, search } = window.location;
    if (pathname !== "/auth" && pathname !== "/login") {
      SessionManager.setOriginUrl(`${pathname}${search}`);
    }
  }, []);

  return (
    <Switch>
      <Route exact path="/auth">
        <ErrorBoundary>
          <Auth />
        </ErrorBoundary>
      </Route>
      <Route exact path="/login">
        <ErrorBoundary>
          <Login />
        </ErrorBoundary>
      </Route>
      {routes.map((route, index) => {
        const Component = route.component;
        return (
          <Route exact path={route.path} key={index}>
            <ErrorBoundary>
              <AuthWrapper>
                <Component />
              </AuthWrapper>
            </ErrorBoundary>
          </Route>
        );
      })}
      <Route
        exact
        path="/error"
        render={(props) => withAppLayout({ component: <Error {...props} /> })()}
      />
      <Route
        path="*"
        component={() => (
          <AuthWrapper>
            <Redirect to={rootRedirect} />
          </AuthWrapper>
        )}
      />
    </Switch>
  );
};

export default Routes;
