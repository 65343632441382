import { getFundRequest } from "external/util/services/data/FundRequestService";
import React from "react";
import { IGenericObject } from "../../../../../../shared/programs/migration-acceleration-program/2024/fund-request/types/CommonTypes";
import {
  IClaim,
  IMAPFundRequest,
} from "../../../../../../shared/programs/migration-acceleration-program/2024/fund-request/types/SchemaTypes";

export interface IFetchEntityProps {
  setData: React.Dispatch<React.SetStateAction<IGenericObject>>;
  adapt?: (
    response: IGenericObject,
    claimId?: string
  ) => IMAPFundRequest | IClaim;
  id: string;
  claimId?: string;
}

export const fetchFundRequest = async ({
  id,
  adapt,
  // data,
  setData,
}: IFetchEntityProps) => {
  const response = await getFundRequest(id);
  const state = adapt && adapt(response);
  if (state) setData(state);
};

export const fetchCashClaim = async ({
  id,
  claimId,
  adapt,
  // data,
  setData,
}: IFetchEntityProps) => {
  const response = await getFundRequest(id);
  const state = adapt && adapt(response, claimId);
  if (state) setData(state);
};
