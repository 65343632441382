import React, { useState } from "react";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import PropTypes from "prop-types";
import {
  Box,
  ColumnLayout,
  DatePicker,
  FormField,
  SpaceBetween,
  Textarea,
} from "@amzn/awsui-components-react";
import Button from "@amzn/awsui-components-react/polaris/button";
import {
  ERROR_TYPE,
  hasError,
  retryApiCall,
} from "../../../../../shared/util/services/data/DataService";
import { submitFundRequestExtension } from "../../../../util/services/data/FundRequestService";
import {
  setErrorMessage,
  setSuccessMessage,
} from "../../../../../shared/util/common/helper";

const ExtendFundRequest = ({
  visible,
  setNotificationsItems,
  setModalVisibility,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({
    extendedReason: "",
    extendedStartDate: "",
    extendedEndDate: "",
  });
  const [error, setNewError] = useState("");

  const extendFundRequest = async () => {
    setIsLoading(true);
    try {
      const response = await retryApiCall({
        callApi: submitFundRequestExtension({
          extendedStartDate: {
            timestamp: new Date(data["extendedStartDate"]).getTime(),
          },
          extendedEndDate: {
            timestamp: new Date(data["extendedEndDate"]).getTime(),
          },
          extendedReason: data["extendedReason"],
        }),
      });

      console.log(response);
      //TODO: Add failure condition
      if (hasError(response)) {
        if (response.errorType === ERROR_TYPE.BANNER) {
          setErrorMessage({
            setNotificationsItems,
            content: response.message,
            status: response.status,
          });
          setIsLoading(false);
          setModalVisibility("extension", false);
        }

        if (response.errorType === ERROR_TYPE.FIELD && response.errors) {
          const responseErrors = {};
          const errorKeys = Object.keys(response.errors);
          errorKeys.map((key) => {
            responseErrors[key] = response.errors[key];
          });
          setIsLoading(false);
          setNewError(responseErrors);
        }
      } else {
        setIsLoading(false);
        setModalVisibility("extension", false);
        setSuccessMessage(
          setNotificationsItems,
          "Updated",
          `Fund request has been extended. Start Date: ${data["extendedStartDate"]}; End Date: ${data["extendedEndDate"]}.`
        );
      }
    } catch (e) {
      console.error(e);
      setIsLoading(false);
    }
  };

  const updateData = (field, value) => {
    setData({ ...data, [field]: value });
  };

  const updateError = (field, errorValue) => {
    setNewError({ ...error, [field]: errorValue });
  };

  return (
    <Modal
      id="extend-fund-request"
      onDismiss={() => setModalVisibility("extension", false)}
      visible={visible}
      closeAriaLabel="Close modal"
      size="large"
      header="Extend Fund Request"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              onClick={() => {
                setModalVisibility("extension", false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              disabled={
                !data["extendedReason"] &&
                !data["extendedStartDate"] &&
                !data["extendedEndDate"]
              }
              loading={isLoading}
              onClick={async () => {
                await extendFundRequest();
              }}
            >
              Submit
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <SpaceBetween size="l">
        <ColumnLayout columns={2}>
          <FormField
            label="Please select extension start date."
            errorText={error["extendedStartDate"]}
          >
            <DatePicker
              id="extension-start-date"
              value={data["extendedStartDate"]}
              placeholder="YYYY/MM/DD"
              onChange={({ detail }) => {
                updateError("extendedStartDate", "");
                updateData("extendedStartDate", detail.value);
              }}
            />
          </FormField>
          <FormField
            label="Please select extension end date."
            errorText={error["extendedEndDate"]}
          >
            <DatePicker
              id="extension-end-date"
              value={data["extendedEndDate"]}
              placeholder="YYYY/MM/DD"
              onChange={({ detail }) => {
                updateError("extendedEndDate", "");
                updateData("extendedEndDate", detail.value);
              }}
            />
          </FormField>
        </ColumnLayout>
        <FormField
          label="Please enter the reason for extension."
          errorText={error["extendedReason"]}
        >
          <Textarea
            id="extension-reason"
            value={data["extendedReason"]}
            onChange={({ detail }) => {
              updateError("extendedReason", "");
              updateData("extendedReason", detail.value);
            }}
          />
        </FormField>
      </SpaceBetween>
    </Modal>
  );
};

ExtendFundRequest.propTypes = {
  visible: PropTypes.bool,
  setNotificationsItems: PropTypes.func,
  setModalVisibility: PropTypes.func,
};

export default ExtendFundRequest;
