// @ts-nocheck
import React from "react";
import { getValidations, ValidationType } from "../validations";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import { IFieldSchema } from "external/programs/migration-acceleration-program/2024/fund-request/types/SchemaTypes";
import { getFieldComponent } from "shared/programs/migration-acceleration-program/2024/fund-request/components/fields/FieldComponent";
import { generateCustomComponentProps } from "shared/programs/migration-acceleration-program/2024/fund-request/components/fields/CustomComponentProps";
import { FormField } from "@amzn/awsui-components-react";
import ConditionalField from "shared/components/common/Conditional/ConditionalField";

export const formatLabel = ({
  label,
  optional,
}: {
  label: string;
  optional?: boolean;
}) => {
  if (!label) return "";
  if (!optional) return label;

  return (
    <>
      {label} - <i>optional</i>
    </>
  );
};

export interface ISectionFieldsFactory {
  data: { [key: string]: string };
  errors: { [key: string]: string };
  onChange: ({
    key,
    value,
  }: {
    key: string;
    value: string | undefined;
  }) => void;
  onError: ({ key, value }: { key: string; value: string }) => void;
  schema: IFieldSchema[];
}

const InputSectionFieldsFactory = ({
  data,
  errors,
  onChange,
  onError,
  schema,
}: ISectionFieldsFactory) => {
  const currentStage = data.stage;
  const fields = schema.map(
    ({
      label,
      keyName,
      placeholder,
      description,
      fieldType,
      constraintText,
      validations,
      selectOptionType,
      editableStages = [],
      conditional = () => true,
      editableCondition = () => true,
    }: IFieldSchema) => {
      const value = (data && data[keyName]) || "";
      const FieldComponent = getFieldComponent(fieldType);
      const customComponentProps = generateCustomComponentProps({ keyName });

      let editable = false;
      if (editableStages) {
        editable =
          editableStages.includes(currentStage) &&
          !!editableCondition({ data });
      }
      return (
        <ConditionalField
          key={`${keyName}-conditional`}
          showField={!!conditional({ data })}
        >
          <FormField
            label={label}
            key={`${keyName}-form`}
            description={description}
            errorText={errors[keyName]}
            constraintText={constraintText && constraintText(value)}
          >
            <FieldComponent
              label={label}
              value={value}
              key={`${keyName}-input`}
              keyName={keyName}
              placeholder={placeholder}
              selectOptionType={selectOptionType}
              disabled={!editable}
              onChange={({ detail }) => {
                if (editable) {
                  const value = detail["value"];
                  const errors = applyValidations(validations, value);

                  if (errors.length) {
                    console.error(`Errors: ${errors.join(",")}`);
                    onError({ key: keyName, value: errors.join(",") });
                    return;
                  }

                  onChange({ key: keyName, value });
                }
              }}
              {...customComponentProps}
            />
          </FormField>
        </ConditionalField>
      );
    }
  );
  return <SpaceBetween size="l">{fields}</SpaceBetween>;
};

const applyValidations = (
  validations: undefined | ValidationType[],
  value: string | undefined
): string[] => {
  const error: string[] = [];

  if (!validations || !validations.length || !value) return error;

  const inputValidations: ((value: string) => string | undefined)[] =
    getValidations(validations);

  for (const validate of inputValidations) {
    const validated = validate(value);
    if (validated) error.push(validated);
  }

  return error;
};

export default InputSectionFieldsFactory;
