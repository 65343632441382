import { fetchFundRequest } from "external/programs/migration-acceleration-program/2024/fund-request/components/create/wizard/util";
import React, { useEffect } from "react";
import { ContentLayout, SpaceBetween } from "@amzn/awsui-components-react";
import AWSPricingCalculatorSection from "external/programs/migration-acceleration-program/2024/fund-request/components/create/wizard/Steps/Artifacts/Sections/AWSPricingCalculatorSection";
import MigrationScopeChecklistSection from "external/programs/migration-acceleration-program/2024/fund-request/components/create/wizard/Steps/Artifacts/Sections/MigrationScopeChecklistSection";
import AdditionalArtifactsSection from "external/programs/migration-acceleration-program/2024/fund-request/components/create/wizard/Steps/Artifacts/Sections/AdditionalArtifactsSection";
import SystemErrorAlert from "shared/programs/migration-acceleration-program/2024/fund-request/components/common/SystemErrorAlert";
import { IContainerProps } from "shared/programs/migration-acceleration-program/2024/fund-request/types/ContainerTypes";
import { getFundRequestId } from "internal/util/helper";
import VmwareAssessmentReport from "external/programs/migration-acceleration-program/2024/fund-request/components/create/wizard/Steps/Artifacts/Sections/VmwareAssessmentReport";
import { FEATURE_FLAG_ENUMS } from "shared/util/constants/featureFlagValues";
import ConditionalField from "shared/components/common/Conditional/ConditionalField";
import useFeatureFlag from "shared/util/hooks/useFeatureFlag";

const Artifacts = ({
  adapt,
  data,
  formError,
  onChange,
  setData,
}: IContainerProps) => {
  const vmwareEnabledFlag = useFeatureFlag(
    FEATURE_FLAG_ENUMS.VMWARE_MAP_ENABLED
  );
  const vmwareAcknowledgement = data?.vmwareAcknowledgement;

  useEffect(() => {
    const id = getFundRequestId();
    fetchFundRequest({ id, adapt, setData });
  }, []);

  return (
    <ContentLayout>
      <SpaceBetween size="l">
        <AWSPricingCalculatorSection data={data} onChange={onChange} />
        <MigrationScopeChecklistSection data={data && data["artifacts"]} />
        <ConditionalField
          showField={vmwareEnabledFlag && vmwareAcknowledgement}
        >
          <VmwareAssessmentReport data={data && data["artifacts"]} />
        </ConditionalField>
        <AdditionalArtifactsSection data={data && data["artifacts"]} />
        <SystemErrorAlert isVisible={!!formError} error={formError} />
      </SpaceBetween>
    </ContentLayout>
  );
};

export default Artifacts;
