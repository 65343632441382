import { FileUpload, FormField } from "@amzn/awsui-components-react";
import React from "react";
import PropTypes from "prop-types";

const Upload = ({
  files,
  setFiles,
  fileTypeErrorText,
  setFileTypeErrorText,
}) => {
  const isFileExtensionValid = (file) => {
    const fname = file.name;
    const fextension = fname.split(".").pop();
    return fextension === "csv";
  };

  return (
    <FormField label="Upload a bulk actions input csv file">
      <FileUpload
        onChange={({ detail }) => {
          let file = detail.value[0];
          if (file !== undefined && isFileExtensionValid(file)) {
            setFiles(detail.value);
            setFileTypeErrorText();
            return;
          }
          setFileTypeErrorText("Only csv files can be uploaded");
        }}
        value={files}
        i18nStrings={{
          uploadButtonText: (e) => (e ? "Choose files" : "Choose file"),
          dropzoneText: (e) =>
            e ? "Drop files to upload" : "Drop file to upload",
          removeFileAriaLabel: (e) => `Remove file ${e + 1}`,
          errorIconAriaLabel: "Error",
        }}
        errorText={fileTypeErrorText}
        showFileLastModified
        showFileSize
        showFileThumbnail
        tokenLimit={3}
      ></FileUpload>
    </FormField>
  );
};

Upload.propTypes = {
  files: PropTypes.array,
  setFiles: PropTypes.func,
  fileTypeErrorText: PropTypes.string,
  setFileTypeErrorText: PropTypes.func,
};

export default Upload;
