import React, { useState } from "react";
import { SpaceBetween } from "@amzn/awsui-components-react";
import Button from "@amzn/awsui-components-react/polaris/button";
import PropTypes from "prop-types";
import { Action } from "../../../constant.js";
import Select from "@amzn/awsui-components-react/polaris/select";

const TableActions = ({
  entityDetails,
  currentEntityVersion,
  changeEntityVersion,
  isValidEntity,
  showModal,
  selectedItems,
}) => {
  const isCurrentVersionLatest = currentEntityVersion === entityDetails.version;
  const [selectedOption, setSelectedOption] = useState({
    label: `Version ${entityDetails.version}`,
    value: entityDetails.version,
  });

  const entityVersionList = () => {
    let items = [];
    for (let version = entityDetails.version; version > 0; version--) {
      items.push({
        label: `Version ${version}`,
        value: version,
      });
    }
    return items;
  };

  return isValidEntity ? (
    <SpaceBetween size="s" direction="horizontal">
      <Select
        selectedOption={selectedOption}
        onChange={({ detail }) => {
          setSelectedOption(detail.selectedOption);
          changeEntityVersion(detail.selectedOption.value);
        }}
        options={entityVersionList()}
        loadingText="loading..."
        triggerVariant="option"
      />
      <Button
        variant="normal"
        onClick={() => {
          showModal(Action.DELETE);
        }}
        disabled={!selectedItems.length > 0 || !isCurrentVersionLatest}
      >
        Delete
      </Button>
      <Button
        variant="normal"
        onClick={() => {
          showModal(Action.EDIT);
        }}
        disabled={!selectedItems.length > 0 || !isCurrentVersionLatest}
      >
        Edit
      </Button>
      <Button
        variant="primary"
        onClick={() => {
          showModal(Action.CREATE);
        }}
        disabled={!isCurrentVersionLatest}
      >
        Create
      </Button>
    </SpaceBetween>
  ) : (
    ""
  );
};

TableActions.propTypes = {
  entityDetails: PropTypes.object,
  currentEntityVersion: PropTypes.number,
  changeEntityVersion: PropTypes.func,
  isValidEntity: PropTypes.bool,
  showModal: PropTypes.func,
  selectedItems: PropTypes.array,
};

export default TableActions;
