import { STATUS } from "../../util/constants/fundRequestStatusType";
import { Badge, Box, Icon } from "@amzn/awsui-components-react";
import React from "react";

export const statusBadge = (status) => {
  switch (status) {
    case STATUS.DRAFT:
      return <Badge color="red">Draft</Badge>;
    case STATUS.ACTIVE:
      return <Badge color="green">Active</Badge>;
    case STATUS.SUBMITTED:
      return (
        <React.Fragment>
          <Box color="text-status-info" variant="p">
            Pending <Icon name="status-pending" />
          </Box>
        </React.Fragment>
      );
    case STATUS.COMPLETED:
      return <Badge color="green">Completed</Badge>;
    case STATUS.EXPIRED:
      return <Badge color="grey">Expired</Badge>;
    case STATUS.TERMINATED:
      return <Badge color="red">Terminated</Badge>;
    case STATUS.CANCELLED:
      return <Badge color="red">Cancelled</Badge>;
    case STATUS.DELETED:
      return <Badge color="red">Deleted</Badge>;
    default:
      return <Badge color="green">Active</Badge>;
  }
};
