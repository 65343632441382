import React from "react";
import { STATUS } from "shared/util/constants/fundRequestStatusType";
import StatusIndicator from "@amzn/awsui-components-react/polaris/status-indicator";
import { StatusIndicatorProps } from "@amzn/awsui-components-react/polaris/status-indicator/internal";
import { capitalizeFirstLetter } from "external/programs/migration-acceleration-program/2024/fund-request/utils/string";

const SUCCESS_STATUSES = [STATUS.ACTIVE, STATUS.SUBMITTED, STATUS.COMPLETED];

const FundRequestStatus = ({ status }: { status: string }) => {
  if (!status) return <></>;

  let type: StatusIndicatorProps.Type = "success";

  if (!SUCCESS_STATUSES.includes(status)) {
    type = "error";
  }

  return (
    <StatusIndicator type={type}>
      {capitalizeFirstLetter(status)}
    </StatusIndicator>
  );
};

export default FundRequestStatus;
