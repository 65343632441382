import React, { useEffect, useContext } from "react";
import {
  Box,
  SpaceBetween,
  FormField,
  DateRangePicker,
  Input,
  Modal,
  Button,
} from "@amzn/awsui-components-react";
import PropTypes from "prop-types";
import { ValidationError } from "shared/util/validation/error";
import {
  activeExtensionWithAbsoluteRange,
  negativeRangeInput,
  mustBeAfterProjectStartDateWithAbsoluteRange,
  notValidRangeInput,
} from "./Validators";
import { Enums } from "./Constants";
import { preloadFundRequests, submitExtends } from "./Api";
import { ExtendContext } from "./Context";

const ExtendModal = ({ fundRequests, setNotificationsItems }) => {
  const { extendState, extendDispatch } = useContext(ExtendContext);

  useEffect(
    () => preloadFundRequests(fundRequests, extendDispatch),
    [fundRequests]
  );

  return (
    <Modal
      id="extend-modal"
      onDismiss={() =>
        extendDispatch({ type: Enums.SET_MODAL, payload: false })
      }
      visible={extendState.isModalVisible}
      closeAriaLabel="Close modal"
      size="large"
      header="Extend Fund Request"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              onClick={() =>
                extendDispatch({ type: Enums.SET_MODAL, payload: false })
              }
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              disabled={false}
              loading={extendState.isLoading}
              onClick={async () => {
                let hasError = false;
                if (extendState.reason === "") {
                  hasError = true;
                  extendDispatch({
                    type: Enums.SET_REASON_ERROR,
                    payload: "A reason is required!",
                  });
                }
                if (extendState.dateRange === undefined) {
                  hasError = true;
                  extendDispatch({
                    type: Enums.SET_DATE_RANGE_ERROR,
                    payload: "Please input a valid date range!",
                  });
                }
                if (!hasError) {
                  await submitExtends(
                    fundRequests,
                    extendState.fundRequests,
                    extendState.dateRange,
                    extendState.reason,
                    extendDispatch,
                    setNotificationsItems
                  );
                }
              }}
            >
              Submit
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <SpaceBetween size="l">
        <FormField
          label="Date Range Extension"
          errorText={extendState.dateRangeError}
        >
          <DateRangePicker
            disabled={extendState.isLoading}
            onChange={({ detail }) =>
              extendDispatch({
                type: Enums.SET_DATE_RANGE,
                payload: detail.value,
              })
            }
            value={extendState.dateRange}
            isValidRange={(range) => {
              const first = fundRequests[0];
              const onlyOne = fundRequests.length === 1;
              const fundRequest = extendState.fundRequests[first.fundRequestId];
              const checks = [
                () => notValidRangeInput(range),
                () => negativeRangeInput(range),
                () =>
                  !onlyOne ||
                  mustBeAfterProjectStartDateWithAbsoluteRange(
                    fundRequest,
                    range
                  ),
                () =>
                  !onlyOne ||
                  activeExtensionWithAbsoluteRange(fundRequest, range),
              ];
              try {
                checks.forEach((f) => f());
              } catch (err) {
                if (err instanceof ValidationError) {
                  return { valid: false, errorMessage: err.message };
                } else {
                  console.error(err);
                }
              }
              return { valid: true };
            }}
            rangeSelectorMode={
              fundRequests.length === 1 ? "default" : "relative-only"
            }
            relativeOptions={[
              {
                key: "1 week",
                amount: 1,
                unit: "week",
                type: "relative",
              },
              {
                key: "2-week",
                amount: 2,
                unit: "week",
                type: "relative",
              },
              {
                key: "4-week",
                amount: 4,
                unit: "week",
                type: "relative",
              },
              {
                key: "8-week",
                amount: 8,
                unit: "week",
                type: "relative",
              },
              {
                key: "12-week",
                amount: 12,
                unit: "week",
                type: "relative",
              },
            ]}
            dateOnly={true}
            i18nStrings={{
              todayAriaLabel: "Today",
              nextMonthAriaLabel: "Next month",
              previousMonthAriaLabel: "Previous month",
              customRelativeRangeDurationLabel: "Duration",
              customRelativeRangeDurationPlaceholder: "Enter duration",
              customRelativeRangeOptionLabel: "Custom range",
              customRelativeRangeOptionDescription:
                "Set a custom range for the Extension.",
              customRelativeRangeUnitLabel: "Unit of time",
              formatRelativeRange: (e) => {
                const t = 1 === e.amount ? e.unit : `${e.unit}s`;
                return `${e.amount} ${t} Extension`;
              },
              formatUnit: (e, t) => (1 === t ? e : `${e}s`),
              dateTimeConstraintText:
                "Range is 6 to 30 days. For date, use YYYY/MM/DD.",
              relativeModeTitle: "Relative range",
              absoluteModeTitle: "Absolute range",
              relativeRangeSelectionHeading: "Choose a range",
              startDateLabel: "Start date",
              endDateLabel: "End date",
              startTimeLabel: "Start time",
              endTimeLabel: "End time",
              clearButtonLabel: "Clear and dismiss",
              cancelButtonLabel: "Cancel",
              applyButtonLabel: "Apply",
            }}
            placeholder="Filter by a date and time range"
          />
        </FormField>
        <FormField
          description="Provide a reason on why you are extending these FRs"
          label="Reason"
          errorText={extendState.reasonError}
        >
          <Input
            disabled={extendState.isLoading}
            value={extendState.reason}
            onChange={(event) =>
              extendDispatch({
                type: Enums.SET_REASON,
                payload: event.detail.value,
              })
            }
          />
        </FormField>
      </SpaceBetween>
    </Modal>
  );
};

ExtendModal.propTypes = {
  fundRequests: PropTypes.array,
  setNotificationsItems: PropTypes.func,
};

export default ExtendModal;
