import { isAuthTokenValid } from "../auth/AuthService";
import SessionManager from "../auth/SessionManager";
import { getResponseJson, isJsonString } from "../../common/helper";
import React from "react";

export const getHeader = () => {
  return {
    "Content-Type": "application/json",
    Authorization: SessionManager.getIdToken(),
  };
};

export const hasError = (response) => {
  const responseKeys = Object.keys(response);
  return responseKeys.includes("errorType");
};

export const checkStatusSuccess = (status) => status > 100 && status < 400;

const isValidUrl = (object) => {
  const pattern = new RegExp("^((https)://)[-a-zA-Z0-9@:%._+?&~#=]{1,256}");
  return pattern.test(object);
};

export const ERROR_TYPE = {
  FIELD: "field",
  BANNER: "banner",
};

/**
 * Handle the error based on response data.
 *
 * @param payload - json payload
 */
export const createErrorResponse = (payload) => {
  if (isJsonString(payload)) {
    payload = JSON.parse(payload);
  }

  const payloadKeys = Object.keys(payload);
  let error = {
    errorType: ERROR_TYPE.BANNER,
  };

  if (payloadKeys.includes("errors") && isObject(payload["errors"])) {
    const errorsPayload = payload["errors"];

    if (Object.keys(errorsPayload).includes("message")) {
      error["message"] = errorsPayload["message"];
    } else {
      error["errorType"] = ERROR_TYPE.FIELD;
      error["errors"] = payload["errors"];
    }
  }

  if (payloadKeys.includes("message") && isString(payload["message"])) {
    error["message"] = payload["message"];
    if (payloadKeys.includes("url") && isValidUrl(payload["url"])) {
      error["message"] = (
        <p>
          {payload["message"]} If the user is not configured correctly, Submit a
          ticket using this
          <a href={payload["url"]}> link </a>
          to request access.
        </p>
      );
    }
  }

  if (isString(payload)) {
    error["message"] = payload;
  }

  return error;
};

export const isObject = (object) => {
  return typeof object === "object";
};

export const isString = (object) => {
  return typeof object === "string";
};

export const postData = async ({ endpoint, headers = getHeader(), body }) => {
  let payload = {};
  try {
    if (await isAuthTokenValid()) {
      const response = await fetch(endpoint, {
        method: "POST",
        headers,
        body,
      });

      if (!response.ok) {
        payload = createErrorResponse(await response.json());
        payload["status"] = response.status;
      } else {
        const jsonResponse = await response.json();

        payload = isJsonString(jsonResponse)
          ? JSON.parse(jsonResponse)
          : jsonResponse;
      }
    }
  } catch (err) {
    console.error(err);
  }
  return payload;
};

export const putData = async ({ endpoint, headers, body }) => {
  let payload = {};
  if (!headers) {
    headers = getHeader();
  }

  try {
    if (await isAuthTokenValid()) {
      const response = await fetch(endpoint, {
        method: "PUT",
        headers,
        body,
      });
      if (!response.ok) {
        const jsonResponse = await getResponseJson(response);
        payload = createErrorResponse(jsonResponse);
        payload["status"] = response.status;
      } else {
        const jsonResponse = await getResponseJson(response);

        if (!jsonResponse) {
          return {};
        }

        payload = isJsonString(jsonResponse)
          ? JSON.parse(jsonResponse)
          : jsonResponse;
      }
    }
  } catch (err) {
    console.error(err);
  }
  return payload;
};

export const getData = async ({
  endpoint,
  headers = getHeader(),
  routeParam,
  queryParam,
}) => {
  let payload = {};
  try {
    if (await isAuthTokenValid()) {
      let apiEndpoint = `${endpoint}`;

      if (routeParam) {
        apiEndpoint += `/${routeParam}`;
      }

      if (queryParam && queryParam.length > 0) {
        apiEndpoint += `?${queryParam.join("&")}`;
      }

      const response = await fetch(apiEndpoint, {
        method: "GET",
        headers,
      });

      if (!response.ok) {
        payload = createErrorResponse(await response.json());
        payload["status"] = response.status;
      } else {
        const jsonResponse = await response.json();

        payload = isJsonString(jsonResponse)
          ? JSON.parse(jsonResponse)
          : jsonResponse;
      }
    }
  } catch (err) {
    console.error(err);
  }
  return payload;
};

export const deleteData = async ({
  endpoint,
  headers = getHeader(),
  routeParam,
  queryParam,
}) => {
  let payload = {};

  try {
    if (await isAuthTokenValid()) {
      let apiEndpoint = `${endpoint}`;

      if (routeParam) {
        apiEndpoint += `/${routeParam}`;
      }

      if (queryParam && queryParam.length > 0) {
        apiEndpoint += `?${queryParam.join("&")}`;
      }

      const response = await fetch(apiEndpoint, {
        method: "DELETE",
        headers,
      });

      if (!response.ok) {
        payload = createErrorResponse(await response.json());
        payload["status"] = response.status;
      } else {
        const jsonResponse = await response.json();

        payload = isJsonString(jsonResponse)
          ? JSON.parse(jsonResponse)
          : jsonResponse;
      }
    }
  } catch (err) {
    console.error(err);
  }
  return payload;
};

export const delay = (seconds) => {
  const milliseconds = seconds * 1000;
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
};

// NOTE: the way this function completely negates a 4xx and continues to retry
// for like validation errors needs improvement...we should be throwing to use
// builtin promise chaining provided by js...this will not get caught with a
// .catch(err => ...).
export const retryApiCall = async ({
  retryAttempt = 0,
  maxAttempts = 3,
  callApi,
  error,
}) => {
  let response = {};

  try {
    response = await callApi;
    if (!hasError(response)) return response;
    if (retryAttempt > maxAttempts)
      throw new Error(`FAILURE: ${response.message}`);
    //TODO: check for validation error and throw
    if (response.status >= 500) throw new Error(error);
    if (response.status >= 406) throw new Error(`FAILURE: ${response.message}`);

    return response;
  } catch (e) {
    if (e.message.includes("FAILURE:")) {
      throw new Error(e);
    }
    await delay(retryAttempt);
    return retryApiCall({ retryAttempt: retryAttempt + 1, callApi, error: e });
  }
};

/**
 * first we use encodeURIComponent to get percent-encoded UTF-8,
 * then we convert the percent encodings into raw bytes which
 * can be fed into btoa.
 * @param str
 * @returns {string}
 */
export const b64EncodeUnicode = (str) =>
  btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match, p1) =>
      String.fromCharCode("0x" + p1)
    )
  );
