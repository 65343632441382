import React from "react";
import Table from "@amzn/awsui-components-react/polaris/table";
import Box from "@amzn/awsui-components-react/polaris/box";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import PropTypes from "prop-types";

export const LoadingTable = ({ loadingText = "Loading..." }) => {
  return (
    <Table
      loading
      loadingText={loadingText}
      columnDefinitions={[]}
      items={[]}
      empty={
        <Box margin={{ vertical: "xs" }} textAlign="center" color="inherit">
          <SpaceBetween size="m">
            <b>No resources</b>
          </SpaceBetween>
        </Box>
      }
    />
  );
};

LoadingTable.propTypes = {
  loadingText: PropTypes.string,
};

export default LoadingTable;
