import React from "react";
import { Button, Header, SpaceBetween } from "@amzn/awsui-components-react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import Submit from "../Submit";

const BulkActionReviewTableHeader = ({
  itemsLength,
  bulkActionType,
  records,
  fileName,
}) => {
  const history = useHistory();
  const header = `${bulkActionType.label} Submission Review`;

  return (
    <React.Fragment>
      <Header
        counter={"(" + itemsLength + "/" + records.length + ")"}
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              onClick={() => {
                history.replace({
                  pathname: "/bulk-actions",
                });
              }}
            >
              Cancel
            </Button>
            <Submit
              bulkActionType={bulkActionType}
              records={records}
              fileName={fileName}
            />
          </SpaceBetween>
        }
      >
        {header}
      </Header>
    </React.Fragment>
  );
};

BulkActionReviewTableHeader.propTypes = {
  itemsLength: PropTypes.number,
  bulkActionType: PropTypes.object,
  records: PropTypes.array,
  fileName: PropTypes.string,
  setNotificationsItems: PropTypes.func,
};

export default BulkActionReviewTableHeader;
