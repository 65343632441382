import React from "react";
import PropTypes from "prop-types";
import Button from "@amzn/awsui-components-react/polaris/button";
import { PAGE_TO_STAGE } from "../../../../util/constants/pages";
import { useHistory } from "react-router-dom";
import { getInternalTemplate } from "../../../../config/schema";
import { SECTION_NAME } from "shared/util/constants/section";

export const MigrationEngagementHeaderAction = ({ fundingData, page }) => {
  const history = useHistory();
  const template = getInternalTemplate({
    program: fundingData["program"],
    fundingType: fundingData["fundingType"],
  });
  const mpeIdEditButtonEnabled =
    template[SECTION_NAME.MIGRATION_PROGRAM_ENGAGEMENTS].edit.includes(page) &&
    PAGE_TO_STAGE[page] === fundingData["stage"];
  return (
    mpeIdEditButtonEnabled && (
      <Button
        onClick={() =>
          history.push(
            `/${page}/${fundingData.fundRequestId}/migrationProgramEngagements/edit`
          )
        }
      >
        Update Program Engagement Id
      </Button>
    )
  );
};

MigrationEngagementHeaderAction.propTypes = {
  fundingData: PropTypes.object,
  page: PropTypes.string,
};
