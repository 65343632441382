import React, { useEffect, useState } from "react";
import { FileScanStatus } from "shared/components/common/file-status/FileScanStatus";
import { STAGE, STATUS } from "shared/util/constants/fundRequestStatusType";
import { useHistory } from "react-router-dom";
import { IDetailsProps } from "shared/programs/migration-acceleration-program/2024/fund-request/types/SchemaTypes";
import { IGenericObject } from "shared/programs/migration-acceleration-program/2024/fund-request/types/CommonTypes";
import Link from "@amzn/awsui-components-react/polaris/link";
import { yyyymmddLocalDateFormat } from "shared/util/common/date";
import {
  getDownloadUrl,
  getFundRequestAttachments,
} from "internal/util/services/data/InternalDataService";
import { hasError, retryApiCall } from "shared/util/services/data/DataService";

import { handleApiError } from "shared/util/api/util";
import ReadOnlyArtifacts from "external/programs/migration-acceleration-program/2024/fund-request/components/shared/sections/read-only/Artifacts/ReadOnlyArtifacts";
import ArtifactsDetailsActions from "internal/programs/migration-acceleration-program/2024/fund-request/components/details/sections/artifacts/header/ArtifactsDetailsActions";
import { getAssociatedStep } from "shared/programs/migration-acceleration-program/2024/fund-request/util/attachments";

const ArtifactsContainer = ({ ...props }: IDetailsProps) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [artifacts, setArtifacts] = useState<IGenericObject[]>([]);
  const [selectedItems, setSelectedItems] = useState<IGenericObject[]>([]);

  const claimPriorities = props.data?.claimPriority;

  useEffect(() => {
    retrieveFiles();
  }, [props.data]);

  //TODO: This is duplicated in ArtifactsReview, pull this into a common function....
  const retrieveFiles = async () => {
    setIsLoading(true);
    let retrievedFiles: IGenericObject[] = [];
    let nextPageToken = "";
    while (nextPageToken != null) {
      try {
        // @ts-expect-error Still in javascript, so the implementation details don't matter
        const response = await retryApiCall({
          callApi: getFundRequestAttachments(nextPageToken),
        });

        if (hasError(response)) {
          return handleApiError(response.errorType)(response);
        }

        const newFiles = response.items.map((file: IGenericObject) => {
          const associatedStep = getAssociatedStep({
            claimId: file.fundClaimId,
            claimPriorities,
          });
          const fileScanStatus = <FileScanStatus status={file.fileStatus} />;
          const uploadDate = yyyymmddLocalDateFormat(
            new Date(file.lastUpdatedOn.timestamp)
          );
          // const uploadDate = absoluteHumanReadableDateFormat(
          //   file.lastUpdatedOn.timestamp
          // );

          return {
            id: file.fileId,
            artifact: file.fileName,
            artifactType: file.fileType,
            fileType: file.fileName.split(".").slice(-1),
            associatedStep: associatedStep,
            scanStatus: fileScanStatus,
            uploadDate: uploadDate,
          };
        });
        retrievedFiles = retrievedFiles.concat(newFiles);
        nextPageToken = response.nextPageToken;
      } catch (err) {
        console.error(err);
        break;
      } finally {
        setIsLoading(false);
      }
    }
    const { data } = props;
    const artifactsData = data && data["artifacts"];
    const urlsData = artifactsData && artifactsData["pricingCalculatorLinks"];
    let urls = [];

    if (urlsData) {
      urls = urlsData.map((url: string) => {
        return {
          artifact: (
            <Link external href={url}>
              {url}
            </Link>
          ),
          artifactType: "AWS Pricing Calculator",
          fileType: "-",
          associatedStep: "Fund Request",
          scanStatus: "-",
          uploadDate: "-",
        };
      });
    }
    const newArtifacts = retrievedFiles.concat(urls);
    setArtifacts(newArtifacts);
  };

  const download = () => {
    // For each selected item download file.
    selectedItems.forEach(async (file) => {
      try {
        const response = await getDownloadUrl(file.id);
        if (hasError(response)) {
          //TODO.... need to handle error
          return;
        }
        // @ts-expect-error Typescript does not recognize that url exists
        // on the response due to legacy js code
        window.open(response.url);
        // window.location.replace(response.url);
      } catch (error) {
        console.error(error);
      }
    });
  };

  const remove = () => {
    //   // For each selected item detach.
    //   const fundRequestId = props.data["fundRequestId"];
    //   console.log(selectedItems);
    //   selectedItems.forEach(async (file) => {
    //     try {
    //       console.log(file);
    //       const response = await deleteFile(file.id);
    //       console.log({ response });
    //       if (hasError(response)) {
    //         //TODO.... need to handle error
    //         return;
    //       }
    //
    //       await retrieveFiles();
    //
    //       //TODO... we're only detaching fund request, need to handle claims too
    //       const detachResponse = await detachFileFromFundRequest({
    //         body: {
    //           fileId: file.id,
    //           fundRequestId: fundRequestId,
    //           fundClaimId: "",
    //         },
    //         fundRequestId,
    //       });
    //
    //       if (hasError(detachResponse)) {
    //         //TODO... handle error
    //       }
    //     } catch (error) {
    //       console.error(error);
    //     } finally {
    //       console.log("removed");
    //     }
    //   });
  };

  const artifactProps = {
    artifacts,
    isLoading,
    selectedItems,
    setSelectedItems,
  };

  const isEnabled = ({ selectedItems }: { selectedItems: number }) => {
    return itemsSelected({ selectedItems });
  };

  const itemsSelected = ({ selectedItems }: { selectedItems: number }) => {
    return selectedItems > 0;
  };

  const isActive = ({ status }: { status: string }) => {
    const activeStatuses = [STATUS.ACTIVE, STATUS.SUBMITTED];
    return activeStatuses.includes(status);
  };

  const isPendingPartner = ({ stage }: { stage: string }) => {
    const partnerStages = [STAGE.PARTNER, STAGE.CREATED];
    return partnerStages.includes(stage);
  };

  const areFilesMutable = ({
    status,
    stage,
  }: {
    status: string;
    stage: string;
  }) => {
    return isActive({ status }) && isPendingPartner({ stage });
  };

  const fundRequest = props.data;
  const status = fundRequest?.status || "";
  const stage: string = fundRequest?.stage || "";

  return (
    <ReadOnlyArtifacts
      {...props}
      {...artifactProps}
      actions={
        <ArtifactsDetailsActions
          isLoading={isLoading}
          isEnabled={isEnabled({ selectedItems: selectedItems.length })}
          areFilesMutable={areFilesMutable({ status, stage })}
          download={download}
          remove={remove}
          refresh={retrieveFiles}
          addAttachment={() => {
            //TODO: To be implemented
            history.push(`/map2024/`);
          }}
        />
      }
    />
  );
};

export default React.memo(ArtifactsContainer);
