import React from "react";
import {
  Container,
  ContentLayout,
  Header,
  SpaceBetween,
} from "@amzn/awsui-components-react";
import InputSectionFieldsFactory from "shared/programs/migration-acceleration-program/2024/fund-request/components/fields/input/InputSectionFieldsFactory";
import {
  ProjectDetailsEditSchema,
  vmwareProjectDetailsEditSchema,
} from "./schema";
import SystemErrorAlert from "shared/programs/migration-acceleration-program/2024/fund-request/components/common/SystemErrorAlert";
import { IEditContainerProps } from "internal/programs/migration-acceleration-program/2024/fund-request/types/SchemaType";
import { useFundRequest } from "shared/programs/migration-acceleration-program/2024/fund-request/hooks/useFundRequest";
import { useParams } from "react-router-dom";
import { IGenericObject } from "shared/programs/migration-acceleration-program/2024/fund-request/types/CommonTypes";
import { projectDetailsAdapter } from "../../../api/adapters";
import { getFundRequest } from "internal/util/services/data/InternalDataService";
import useFeatureFlag from "shared/util/hooks/useFeatureFlag";
import { FEATURE_FLAG_ENUMS } from "shared/util/constants/featureFlagValues";
import { MAP2024_MIGRATION_PHASE } from "external/programs/migration-acceleration-program/2024/fund-request/components/create/eligibility/util";

const ProjectDetailsEditContainer = ({
  title,
  data,
  errors,
  onError,
  onChange,
  formError,
  setData,
}: IEditContainerProps) => {
  const { id } = useParams<IGenericObject>();
  const isVMwareMapEnabled = useFeatureFlag(
    FEATURE_FLAG_ENUMS.VMWARE_MAP_ENABLED
  );
  const { migrationPhase } = data;
  const vmwareAcknowledgement = data.vmwareAcknowledgement;

  useFundRequest({
    id,
    adapt: projectDetailsAdapter,
    setData,
    fetch: getFundRequest,
  });

  return (
    <ContentLayout>
      <SpaceBetween size="l">
        <Container header={<Header variant="h2">{title}</Header>}>
          <InputSectionFieldsFactory
            data={data}
            errors={errors}
            onChange={onChange}
            onError={onError}
            schema={
              isVMwareMapEnabled &&
              vmwareAcknowledgement &&
              migrationPhase === MAP2024_MIGRATION_PHASE.MOBILIZE
                ? vmwareProjectDetailsEditSchema
                : ProjectDetailsEditSchema
            }
          />
        </Container>
        <SystemErrorAlert isVisible={!!formError} error={formError} />
      </SpaceBetween>
    </ContentLayout>
  );
};

export default ProjectDetailsEditContainer;
