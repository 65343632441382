import React, { ReactNode, useState } from "react";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import DetailsActionWithModal, {
  IDetailsActionWithModalLabels,
} from "shared/programs/migration-acceleration-program/2024/fund-request/components/modal/DetailsActionWithModal";
import { FormField } from "@amzn/awsui-components-react";
import Textarea from "@amzn/awsui-components-react/polaris/textarea";

import { IGenericObject } from "shared/programs/migration-acceleration-program/2024/fund-request/types/CommonTypes";
import { FormError, Result, ResultType, Success } from "shared/util/api/util";

export interface IPartnerCommentDetailsActionWithModalLabels
  extends IDetailsActionWithModalLabels {
  description: ReactNode;
  placeholder?: string;
  label: string;
}

export interface ICommentPayload {
  comment: string;
}

export interface ISubmitPayload {
  payload: ICommentPayload;
}

export const handlePartnerCommentErrorResponse = ({
  response,
}: {
  response: IGenericObject;
}) => {
  return new Result<FormError>({
    success: false,
    payload: new FormError(response?.message ?? ""),
  });
};

const PartnerCommentDetailsActionWithModal = ({
  isLoading,
  submit,
  labels,
  children,
}: {
  isLoading: boolean;
  submit: ({ payload }: ISubmitPayload) => Promise<Result<ResultType>>;
  labels: IPartnerCommentDetailsActionWithModalLabels;
  children?: ReactNode;
}) => {
  const [isModalLoading, setIsModalLoading] = useState<boolean>(false);
  const [comment, setComment] = useState<string>("");
  const [error, setError] = useState<string>("");

  const onSubmit = async (): Promise<Result<ResultType>> => {
    setIsModalLoading(true);
    let result: Result<ResultType> = new Result<Success>({});
    try {
      const response = await submit({ payload: { comment } });

      if (!response.success) {
        result = response;
        setError("An error has occurred, try again.");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsModalLoading(false);
    }

    return result;
  };

  const onCancel = () => {
    setComment("");
    setError("");
  };

  const { actionButton, title, placeholder, description, submitButton, label } =
    labels;

  return (
    <>
      <DetailsActionWithModal
        isLoading={isModalLoading || isLoading}
        submit={onSubmit}
        cancel={onCancel}
        labels={{ actionButton, title, submitButton }}
      >
        <SpaceBetween size="s">
          {children}
          <FormField
            label={label}
            description={description}
            errorText={error}
            constraintText={`Maximum of 5000 characters including special characters. Character count: ${comment.length}/5000.`}
          >
            <Textarea
              value={comment}
              placeholder={placeholder}
              onChange={({ detail }) => {
                if (detail.value.length > 5000) {
                  setError("Reason must be 5000 characters or less.");
                  return;
                }
                setComment(detail.value);
                setError("");
              }}
              spellcheck
              autoFocus
            />
          </FormField>
        </SpaceBetween>
      </DetailsActionWithModal>
    </>
  );
};

export default PartnerCommentDetailsActionWithModal;
