import { hasError, retryApiCall } from "../services/data/DataService";
import { GetPatchedEvents } from "../../../internal/components/SelfService/JsonPatch/jsonpatch";
import { getSelfServiceEntityEvents } from "../../../internal/util/services/data/InternalDataService";

export const getSelfServiceEntityValues = async (entityType) => {
  let nextPageToken = "";
  let doc = {};
  while (nextPageToken != null) {
    try {
      const response = await retryApiCall({
        callApi: await getSelfServiceEntityEvents({
          entityType: entityType,
          nextPageToken: nextPageToken,
        }),
      });
      if (response && hasError(response)) {
        console.error(response);
        return {};
      }
      doc = GetPatchedEvents(entityType, response.events, doc);
      nextPageToken = response.nextPageToken;
    } catch (e) {
      console.error(e);
      return {};
    }
  }
  return doc;
};

export const getSelfServiceOptions = async (entityType) => {
  try {
    const doc = await getSelfServiceEntityValues(entityType);
    if (Object.keys(doc)?.length !== 0) {
      return doc[entityType]?.map((item) => ({
        label: item.name,
        value: item.name,
      }));
    }
  } catch (e) {
    console.error(e);
    return [];
  }
  return [];
};
