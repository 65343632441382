import React from "react";
import Header from "@amzn/awsui-components-react/polaris/header";
import PropTypes from "prop-types";

import { useOptionsContext } from "../../../../util/context/OptionsContext";
import { filterFields, getTemplate, schema } from "../../../../config/schema";
import useFields from "../../../../../shared/util/hooks/useFields";

import { useParams } from "react-router-dom";
import { SECTION_NAME } from "../../../../../shared/util/constants/section";
import { ColumnLayout, SpaceBetween } from "@amzn/awsui-components-react";

import "./style.css";
import Loading from "../../../../../shared/components/common/Loading";
import Section from "../../../../../shared/components/FundRequest/StepsContainer/Section";
import { DisableFactory } from "../Inputs/PropBuilder/DisableFactory";
import { ValueFactory } from "../Inputs/PropBuilder/ValueFactory";
import { InputPropsBuilder } from "../../../../../shared/components/FundRequest/InputPropsBuilder";
import { InputFactory } from "../../../../../shared/components/FundRequest/InputFactory";
import { getFundRequest } from "../../../../util/services/data/FundRequestService";
import { FileUploadTriggers } from "../../../../util/services/data/dataFactory";
import { extractCashClaimIndex } from "../../../../../internal/components/Edit/Sections/util";

const CashClaimSectionContainer = ({
  data,
  errors,
  dataInput,
  dataLoad,
  fieldToIdMap,
  updateErrors,
  setFieldToIdMap,
  stepTitle,
  validators,
  setNotificationItems,
  showHelperPanel,
}) => {
  const [options] = useOptionsContext();
  const { cashClaimId } = useParams();
  const stepTemplate = schema[stepTitle];

  const [{ fieldTemplate, isLoading }] = useFields({
    data,
    stepTemplate,
    fieldToIdMap,
    updateFieldMap: setFieldToIdMap,
    dataLoad,
    dataInput,
    getTemplate: getTemplate,
    filterFields,
    schema,
    getFundRequest,
  });

  const claimIndex = extractCashClaimIndex(cashClaimId);

  const createTemplateKey = (sectionName) =>
    `${sectionName}${claimIndex > 1 ? `-${claimIndex}` : ""}`;

  const generateSection = ({
    templateKey,
    defaultDisable = false,
    attachments = false,
  }) => {
    return (
      <React.Fragment>
        {attachments ? (
          <React.Fragment>
            {fieldTemplate &&
              fieldTemplate[templateKey] &&
              fieldTemplate[templateKey].fields.map((field, index) => {
                const inputProps = InputPropsBuilder({
                  data,
                  errors,
                  index: claimIndex,
                  field,
                  options,
                  fieldToIdMap,
                  defaultDisable,
                  disableFactory: DisableFactory,
                  valueFactory: ValueFactory,
                });

                return (
                  <InputFactory
                    key={index}
                    dataInput={dataInput}
                    fieldToIdMap={fieldToIdMap}
                    updateErrors={updateErrors}
                    validators={validators}
                    setNotificationItems={setNotificationItems}
                    showHelperPanel={showHelperPanel}
                    fileuploadProp={FileUploadTriggers}
                    {...inputProps}
                  />
                );
              })}
          </React.Fragment>
        ) : (
          <ColumnLayout columns={2}>
            {fieldTemplate &&
              fieldTemplate[templateKey] &&
              fieldTemplate[templateKey].fields.map((field, index) => {
                const inputProps = InputPropsBuilder({
                  data,
                  errors,
                  index: claimIndex,
                  field,
                  options,
                  fieldToIdMap,
                  defaultDisable,
                  disableFactory: DisableFactory,
                  valueFactory: ValueFactory,
                });

                return (
                  <InputFactory
                    key={index}
                    dataInput={dataInput}
                    fieldToIdMap={fieldToIdMap}
                    updateErrors={updateErrors}
                    validators={validators}
                    setNotificationItems={setNotificationItems}
                    showHelperPanel={showHelperPanel}
                    fileuploadProp={FileUploadTriggers}
                    {...inputProps}
                  />
                );
              })}
          </ColumnLayout>
        )}
      </React.Fragment>
    );
  };

  const section = isLoading ? (
    <Loading />
  ) : (
    <SpaceBetween size="xl">
      <Section header={<Header variant="h2">{stepTitle}</Header>}>
        <SpaceBetween size="xl">
          <div className="claim-section">
            <h4>Plans</h4>
            {generateSection({
              templateKey: createTemplateKey(SECTION_NAME.CASH_CLAIM_PLANS),
              defaultDisable: true,
            })}
          </div>
          <div className="claim-section">
            <h4>Actuals</h4>
            {generateSection({
              templateKey: createTemplateKey(SECTION_NAME.CASH_CLAIM_ACTUALS),
            })}
          </div>
          <div>
            <h4>Invoice & Payments</h4>
            {generateSection({
              templateKey: createTemplateKey(
                SECTION_NAME.CASH_CLAIM_INVOICE_PAYMENT
              ),
            })}
          </div>
        </SpaceBetween>
      </Section>
      <Section header={<Header variant="h2">Cash Claim Attachments</Header>}>
        {generateSection({
          templateKey: SECTION_NAME.ATTACHMENTS,
          attachments: true,
        })}
      </Section>
    </SpaceBetween>
  );
  return <React.Fragment>{section}</React.Fragment>;
};

CashClaimSectionContainer.propTypes = {
  claimid: PropTypes.string,
  data: PropTypes.object,
  errors: PropTypes.object,
  dataInput: PropTypes.func,
  dataLoad: PropTypes.func,
  fieldToIdMap: PropTypes.object,
  updateErrors: PropTypes.func,
  setTemplate: PropTypes.func,
  validators: PropTypes.any,
  setFieldToIdMap: PropTypes.func,
  stepTitle: PropTypes.string,
  setNotificationItems: PropTypes.func,
  showHelperPanel: PropTypes.func,
};

export default React.memo(CashClaimSectionContainer);
