export const grantSectionEditingToPage = (
  page,
  sectionName,
  fundRequestTemplate
) => {
  const fundRequestTemplateUpdated = [];
  for (let section of fundRequestTemplate) {
    const copy_of_section = { ...section };
    if (section.name === sectionName) {
      const copy_of_edit = [...copy_of_section.edit];
      if (!copy_of_edit.includes(page)) {
        copy_of_edit.push(page);
      }
      copy_of_section.edit = copy_of_edit;
    }
    fundRequestTemplateUpdated.push(copy_of_section);
  }
  return fundRequestTemplateUpdated;
};
