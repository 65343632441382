/* eslint react/prop-types: 0 */
import { COMPONENT_TYPE } from "../../../../../shared/util/constants/componentType";
import { statusBadge } from "../../../../../shared/components/common/StatusBadge";
import { SECTION } from "../../../../../shared/util/constants/section";
import { isString } from "../../../../../shared/util/services/data/DataService";
import { generatePricingCalculatorLink } from "../../../../../internal/util/constants/generatePricingCalculatorLink";
import React from "react";
import CopyToClipboardDataField from "../../../../../shared/components/common/CopytoClipboard/DataField";
import { getIsvWmpSecondTrancheClaimConsentStatus } from "../../../../../shared/components/FundRequest/Review/Sections/dataValueFactory";

export const ValueFactory = ({ apiKey, data, field, name, sectionTitle }) => {
  switch (name) {
    case "fundRequestOwner":
      return data["owner"]["userName"];
    case "fundRequestId":
      return (
        <CopyToClipboardDataField
          copyValue={data && data["fundRequestId"]}
          popoverText="Fund Request Id Copied"
        />
      );
    case "awsCalculatorUrl":
      return generatePricingCalculatorLink(data);
    case "extendedStartDate":
    case "extendedEndDate":
    case "extendedReason": {
      let value = data["projectExtension"][name] || "-";

      if (Object.keys(value).includes("timestamp")) {
        value = new Date(value.timestamp).toLocaleDateString();
      }

      return value;
    }
    case "status": {
      let status = "-";

      if (Object.keys(data).includes("status")) {
        status = data["status"];
      }

      if (data[apiKey] && Object.keys(data[apiKey]).includes("status")) {
        status = data[apiKey]["status"];
      }

      if (sectionTitle === SECTION.FUND_REQUEST_INFORMATION) {
        status = statusBadge(data["status"]);
      }

      return status;
    }
    case "numberOfClaims": {
      const keys =
        data && data["cashClaims"] && Object.keys(data["cashClaims"]);
      const claims = keys.filter((key) => key.includes("claim"));
      return claims.length;
    }
    case "mdfFundingPercentage": {
      const value = data && data["plan"]["mdfFundingPercentage"];
      return value && Math.floor(value);
    }
    case "requestedCreditAmountEditable": {
      const value =
        data && data["creditRequest"]["requestedCreditAmount"]["units"];
      return `${parseFloat(value).toLocaleString("en-US")}`;
    }
    case "secondTrancheClaimConsent":
      return getIsvWmpSecondTrancheClaimConsentStatus(data);
    default: {
      let value = "-";

      if (data && (data[name] || (data[apiKey] && data[apiKey][name]))) {
        value = data[name] || data[apiKey][name];

        if (field.component === COMPONENT_TYPE.CHECKBOX) {
          value = isString(value)
            ? value === "true"
              ? "Yes"
              : "-"
            : value
            ? "Yes"
            : "-";
        } else if (field.component === COMPONENT_TYPE.DATEPICKER && value) {
          value = new Date(value.timestamp).toLocaleDateString();
        } else if (field.type === "money" && value) {
          if (!value.units || isNaN(value.units)) {
            value = "-";
          } else {
            value = `${parseFloat(value.units).toLocaleString("en-US")}`;
          }
        } else if (Object.keys(value).includes("units")) {
          value = value.units;
        }
      }

      return value;
    }
  }
};
