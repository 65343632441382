import { SECTION_NAME } from "../../../../shared/util/constants/section";
import { DefaultReviewSectionContainer } from "./DefaultReviewSectionContainer";
import { MigrationDetailsReviewSectionContainer } from "./MigrationDetailsReviewSectionContainer";
import { InternalProjectInformationReviewSectionContainer } from "./InternalProjectInformationReviewSectionContainer";
import { InternalCashRequestReviewSectionContainer } from "./InternalCashRequestReviewSectionContainer";
import { InternalAttachmentsReviewSectionContainer } from "./InternalAttachmentsReviewSectionContainer";
import { OpportunityReviewSectionContainer } from "../../../../shared/components/FundRequest/Review/Sections/OpportunityReviewSectionContainer";
import InternalMigrationEngagementSectionContainer from "./InternalMigrationEngagementSectionContainer";

export const ReviewSectionsFactory = (name) => {
  switch (name) {
    case SECTION_NAME.PROJECT_INFORMATION:
      return InternalProjectInformationReviewSectionContainer;
    case SECTION_NAME.CASH_REQUEST_INFORMATION:
      return InternalCashRequestReviewSectionContainer;
    case SECTION_NAME.MIGRATION_DETAILS:
      return MigrationDetailsReviewSectionContainer;
    case SECTION_NAME.OPPORTUNITY_INFORMATION:
      return OpportunityReviewSectionContainer;
    case SECTION_NAME.ATTACHMENTS:
      return InternalAttachmentsReviewSectionContainer;
    case SECTION_NAME.MIGRATION_PROGRAM_ENGAGEMENTS:
      return InternalMigrationEngagementSectionContainer;
    default:
      return DefaultReviewSectionContainer;
  }
};
