import React, { useEffect, useState } from "react";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Header from "@amzn/awsui-components-react/polaris/header";
import withAppLayout from "../../../shared/components/withAppLayout";
import FundingActivities from "./FundingActivities";
import CreditOverview from "./CreditOverview";
import "./style.css";
import { useLocation } from "react-router-dom";
import { setSuccessMessage } from "../../../shared/util/common/helper";
import { getBreadcrumbs } from "../../../shared/util/common/getBreadcrumbs";
import Wallet from "./Wallet";

const Dashboard = () => {
  const { state } = useLocation();

  const [notificationsItems, setNotificationsItems] = useState([]);

  useEffect(() => {
    if (state && state.origin === "Terminate") {
      setSuccessMessage(
        setNotificationsItems,
        "Submitted",
        state.successMessage
      );
    }
  }, []);

  useEffect(() => {
    if (state && state.origin == "Delete" && state.deletedFundRequests) {
      const deletedFRs = state.deletedFundRequests;
      const message =
        deletedFRs.size > 1
          ? `${deletedFRs.size} Fund Requests`
          : `Fund Request ${[...deletedFRs]}`;
      setSuccessMessage(
        setNotificationsItems,
        `Sucessfully deleted ${message}`,
        state.successMessage
      );
    }
  }, [state]);

  return withAppLayout({
    component: (
      <SpaceBetween size="l">
        <Header variant="h1">APN Partner Funding Dashboard</Header>
        <FundingActivities setNotificationsItems={setNotificationsItems} />
        <Wallet setNotificationsItems={setNotificationsItems} />
        <CreditOverview setNotificationsItems={setNotificationsItems} />
      </SpaceBetween>
    ),
    breadcrumbs: getBreadcrumbs(),
    notificationsItems: notificationsItems,
    toolsHide: true,
  })();
};

export default Dashboard;
