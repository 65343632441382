import { StateEnums } from "./Constants";

export function userSettingsReducer(state, action) {
  const { type, data } = action;

  switch (type) {
    case StateEnums.SET_FEATURE_FLAG:
      return {
        ...state,
        isFeatureFlagEnabled: data,
      };
    case StateEnums.SET_LOADING:
      return {
        ...state,
        isLoading: data,
      };
    case StateEnums.v1_NewFilterTable_SavedFilterSets:
      return {
        ...state,
        [StateEnums.v1_NewFilterTable_SavedFilterSets]: data,
      };
    default:
      return state;
  }
}
