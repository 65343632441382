import ReadOnlySectionActions from "../../../../components/shared/sections/read-only/ReadOnlySectionActions";
import React from "react";
import ReadOnlyFundingDetails from "../../../../components/shared/sections/read-only/FundingDetails/ReadOnlyFundingDetails";
import { IDetailsProps } from "shared/programs/migration-acceleration-program/2024/fund-request/types/SchemaTypes";

const FundingDetailsContainer = ({ ...props }: IDetailsProps) => (
  <ReadOnlyFundingDetails
    {...props}
    actions={
      <ReadOnlySectionActions
        isVisible={
          props.data &&
          props.data["stage"] === "partner" &&
          props.data["status"] === "active"
        }
        sectionNumber={2}
      />
    }
  />
);

export default FundingDetailsContainer;
