import React from "react";

export const generatePricingCalculatorLink = (data) => {
  const hyperlink =
    data && data["details"] && data["details"]["awsCalculatorUrl"];

  if (hyperlink) {
    const urlList = hyperlink.split(",");

    return urlList.map((url, index) => {
      if (url) {
        return (
          <React.Fragment key={index}>
            <a href={url} target="_blank" rel="noopener noreferrer">
              Calculator {index + 1}
            </a>
            <br />
          </React.Fragment>
        );
      }
    });
  }
  return "-";
};
