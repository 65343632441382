import { useHistory } from "react-router-dom";
import { Header } from "@amzn/awsui-components-react";
import Button from "@amzn/awsui-components-react/polaris/button";
import React from "react";
import PropTypes from "prop-types";
export const AttachmentsEditFooter = ({ returnPath }) => {
  const history = useHistory();

  return (
    <Header
      actions={
        <Button
          variant="primary"
          onClick={() => {
            history.push(returnPath);
          }}
        >
          Done
        </Button>
      }
    />
  );
};

AttachmentsEditFooter.propTypes = {
  returnPath: PropTypes.string.isRequired,
};
