import React from "react";
import {
  Container,
  ContentLayout,
  Header,
  SpaceBetween,
} from "@amzn/awsui-components-react";
import { CashRequestDetailsEditSchema } from "internal/programs/migration-acceleration-program/2024/fund-request/components/edit/CashRequestDetailsEditContainer/schema";
import InputSectionFieldsFactory from "shared/programs/migration-acceleration-program/2024/fund-request/components/fields/input/InputSectionFieldsFactory";
import SystemErrorAlert from "shared/programs/migration-acceleration-program/2024/fund-request/components/common/SystemErrorAlert";
import { IContainerProps } from "shared/programs/migration-acceleration-program/2024/fund-request/types/ContainerTypes";
import { useParams } from "react-router-dom";
import { IGenericObject } from "shared/programs/migration-acceleration-program/2024/fund-request/types/CommonTypes";
import { useFundRequest } from "shared/programs/migration-acceleration-program/2024/fund-request/hooks/useFundRequest";
import { cashRequestDetailsAdapter } from "../../../api/adapters";
import { getFundRequest } from "internal/util/services/data/InternalDataService";

const CashRequestDetailsEditContainer = ({
  title,
  data,
  errors,
  onError,
  onChange,
  formError,
  setData,
}: IContainerProps) => {
  const { id } = useParams<IGenericObject>();

  useFundRequest({
    id,
    adapt: cashRequestDetailsAdapter,
    setData,
    fetch: getFundRequest,
  });

  return (
    <ContentLayout>
      <SpaceBetween size="l">
        <Container header={<Header variant="h2">{title}</Header>}>
          <InputSectionFieldsFactory
            data={data}
            errors={errors}
            onChange={onChange}
            onError={onError}
            schema={CashRequestDetailsEditSchema}
          />
        </Container>
        <SystemErrorAlert isVisible={!!formError} error={formError} />
      </SpaceBetween>
    </ContentLayout>
  );
};

export default CashRequestDetailsEditContainer;
