import React from "react";
import { CashRequestReviewSectionContainer } from "../../../../shared/components/Review/CashRequestReviewSectionContainer";
import PropTypes from "prop-types";
import { ValueFactory } from "./ValueFactory";
import {
  filterFields,
  getInternalTemplate,
  schema,
} from "../../../config/schema";
import { getDownloadUrl } from "../../../util/services/data/InternalDataService";
import { CashClaimReviewSectionContainer } from "./CashClaimReviewSectionContainer";
import { SECTION_NAME } from "../../../../shared/util/constants/section";
import { getFundRequestAttachments } from "../../../../external/util/services/data/FundRequestService";
import { PAGE_TO_STAGE } from "../../../util/constants/pages";

export const InternalCashRequestReviewSectionContainer = ({
  fundingData,
  index,
  setNotificationsItems,
  page,
}) => {
  const template = getInternalTemplate({
    program: fundingData["program"],
    fundingType: fundingData["fundingType"],
  });
  const isEditable =
    template[SECTION_NAME.CASH_REQUEST_INFORMATION].edit.includes(page) &&
    PAGE_TO_STAGE[page] === fundingData["stage"];

  return (
    <CashRequestReviewSectionContainer
      fundingData={fundingData}
      index={index}
      setNotificationsItems={setNotificationsItems}
      valueFactory={ValueFactory}
      filterFields={filterFields}
      schema={schema}
      getFundRequestAttachments={getFundRequestAttachments}
      getDownloadUrl={getDownloadUrl}
      CashClaimReviewSectionContainer={CashClaimReviewSectionContainer}
      baseUrl={`/${page}`}
      showHeader={isEditable}
    />
  );
};

InternalCashRequestReviewSectionContainer.propTypes = {
  fundingData: PropTypes.object,
  index: PropTypes.number,
  setNotificationsItems: PropTypes.func,
  page: PropTypes.string,
};
