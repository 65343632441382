import { removeCurrencyFormatting } from "shared/programs/migration-acceleration-program/2024/fund-request/util/currency";

export const convertAmountToMoney = (
  units: string,
  currency: string = "USD"
) => {
  if (!units) {
    return null;
  }
  return {
    units: parseInt(removeCurrencyFormatting(units)),
    nanos: 0,
    currency: currency,
  };
};

export const convertDateToTimestamp = (date: string) => {
  if (!date) {
    return null;
  }
  return { timestamp: new Date(date).getTime() };
};

export const formatMoneyInput = (value: string) => {
  if (!value) return "";
  return parseFloat(value).toLocaleString("en-US");
};
