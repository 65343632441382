import React from "react";
import { Popover } from "@amzn/awsui-components-react";
import { StatusIndicator } from "@amzn/awsui-components-react/uxdg";
import Button from "@amzn/awsui-components-react/polaris/button";
import PropTypes from "prop-types";

const CopyToClipboardDataField = ({ popoverText, copyValue }) => {
  return (
    <React.Fragment>
      <Popover
        dismissButton={false}
        position="top"
        size="small"
        triggerType="custom"
        content={
          <StatusIndicator type="success">{popoverText}</StatusIndicator>
        }
      >
        <Button
          iconName="copy"
          variant="inline-icon"
          onClick={() => {
            navigator.clipboard.writeText(copyValue);
            console.log(`${copyValue} copied to clipboard.`);
          }}
        />
      </Popover>{" "}
      {copyValue}
    </React.Fragment>
  );
};

CopyToClipboardDataField.propTypes = {
  popoverText: PropTypes.string,
  copyValue: PropTypes.string,
};
export default CopyToClipboardDataField;
