import React from "react";
import { Header, SpaceBetween } from "@amzn/awsui-components-react";
import Button from "@amzn/awsui-components-react/polaris/button";
import PropTypes from "prop-types";
import { useHistory, useParams } from "react-router-dom";
import { PAGE_TO_STAGE, PAGES } from "../../../../util/constants/pages";
import { PROGRAM_TYPE } from "../../../../../shared/util/constants/programType";
import { SECTION } from "../../../../../shared/util/constants/section";
import { STATUS } from "../../../../../shared/util/constants/fundRequestStatusType";

export const DefaultSectionHeader = ({
  name,
  program,
  title,
  template,
  stage,
  status,
  isAutoApproved,
}) => {
  const { id, page } = useParams();
  const history = useHistory();

  let isEditable =
    template[name].edit.includes(page) &&
    PAGE_TO_STAGE[page] === stage &&
    status === STATUS.ACTIVE;

  if (
    [PROGRAM_TYPE.SMP, PROGRAM_TYPE.POC].includes(program) &&
    title === SECTION.APPROVAL_DETAILS &&
    (isAutoApproved === null || isAutoApproved)
  ) {
    isEditable = false;
  }

  if (
    program === PROGRAM_TYPE.MDF &&
    title === SECTION.CREDIT_REQUEST_DETAILS
  ) {
    isEditable = false;
  }

  if (
    [PROGRAM_TYPE.SMP].includes(program) &&
    title === SECTION.CREDIT_REQUEST_DETAILS &&
    page === PAGES.AWS_APPROVAL
  ) {
    isEditable = false;
  }

  return (
    <Header
      actions={
        <SpaceBetween direction="horizontal" size="xs">
          {isEditable &&
          [PROGRAM_TYPE.SMP, PROGRAM_TYPE.POC].includes(program) &&
          title === SECTION.PROJECT_INFORMATION ? (
            <Button
              onClick={() =>
                history.push(`/fund-requests/${id}/select-program/edit`, {
                  origin: page,
                })
              }
            >
              Update ARR
            </Button>
          ) : (
            ""
          )}
          {isEditable ? (
            <Button onClick={() => history.push(`/${page}/${id}/${name}/edit`)}>
              Edit
            </Button>
          ) : (
            ""
          )}
        </SpaceBetween>
      }
    >
      {title}
    </Header>
  );
};

DefaultSectionHeader.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  template: PropTypes.object,
  stage: PropTypes.string,
  status: PropTypes.string,
  program: PropTypes.string,
  isAutoApproved: PropTypes.bool,
};
