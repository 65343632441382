import React, { useState, useEffect, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import PropTypes from "prop-types";
import Container from "@amzn/awsui-components-react/polaris/container";
import {
  Box,
  Button,
  ProgressBar,
  SpaceBetween,
} from "@amzn/awsui-components-react/polaris";
import Header from "@amzn/awsui-components-react/polaris/header";
import Textarea from "@amzn/awsui-components-react/polaris/textarea";
import { FormField } from "@amzn/awsui-components-react";
import { FEATURE_FLAG_ENUMS } from "shared/util/constants/featureFlagValues";
import useFeatureFlag from "shared/util/hooks/useFeatureFlag";
import { hasError } from "shared/util/services/data/DataService";
import { NotificationsContext } from "shared/util/context/notifications/NotificationsContext";
import { useContext } from "react";
import { IGenericObject } from "shared/programs/migration-acceleration-program/2024/fund-request/types/CommonTypes";
import { askQuestionToAIBA } from "internal/util/services/aiba/AIBAService";
import "internal/programs/migration-acceleration-program/2024/fund-request/components/details/sections/aiba/aibaStyle.css";
import { GENERIC_ERROR_MESSAGE } from "external/programs/migration-acceleration-program/2024/fund-request/components/create/wizard/Steps/Artifacts/util";

const ChatBubble: React.FC<{ message: string; isUser: boolean }> = ({
  message,
  isUser,
}) => {
  return (
    <div className={`chat-bubble ${isUser ? "user-bubble" : "bot-bubble"}`}>
      {message}
    </div>
  );
};

ChatBubble.propTypes = {
  message: PropTypes.string.isRequired,
  isUser: PropTypes.bool.isRequired,
};

const AskQuestionToAIBA = () => {
  const [question, setQuestion] = useState("");
  const [messages, setMessages] = useState<
    { message: string; isUser: boolean; id: string }[]
  >([]);
  const chatHistoryRef = useRef<HTMLDivElement>(null);
  const { addNotification } = useContext(NotificationsContext);
  const [isLoading, setIsLoading] = useState(false);
  const handleSubmit = async () => {
    const newUserMessage = { message: question, isUser: true, id: uuidv4() };
    setMessages([...messages, newUserMessage]);
    setQuestion("");
    setIsLoading(true);

    try {
      const response: IGenericObject = await askQuestionToAIBA({
        payload: {
          question: question,
        },
      });

      setIsLoading(false);
      if (hasError(response)) {
        addNotification({
          content: response.message,
          type: "error",
        });
        return;
      }

      if (response?.message?.output?.message?.content) {
        const newResponseMessage = {
          message: response.message.output.message.content[0].text,
          isUser: false,
          id: uuidv4(),
        };
        setMessages([...messages, newUserMessage, newResponseMessage]);
      } else if (response?.message) {
        const newResponseMessage = {
          message: response.message,
          isUser: false,
          id: uuidv4(),
        };
        setMessages([...messages, newUserMessage, newResponseMessage]);
      }
    } catch (e) {
      setIsLoading(false);
      addNotification({
        content: GENERIC_ERROR_MESSAGE,
        type: "error",
      });
    }
  };

  useEffect(() => {
    const chatHistory = chatHistoryRef.current;
    if (chatHistory) {
      chatHistory.scrollTop = chatHistory.scrollHeight;
    }
  }, [messages]);

  const aibaFrontendFeatureFlag = useFeatureFlag(
    FEATURE_FLAG_ENUMS.AIBA_FRONTEND_ENABLED
  );
  if (!aibaFrontendFeatureFlag) {
    return null; // Do not render the component if feature flag is false
  }

  return (
    <Container header={<Header variant="h2">Funding advisor</Header>}>
      <SpaceBetween direction="vertical" size="l">
        <Box>
          <div ref={chatHistoryRef} className="chat-history">
            {messages.map((message, index) => (
              <ChatBubble
                key={index}
                message={message.message}
                isUser={message.isUser}
              />
            ))}
            {isLoading && (
              <Box>
                Generating Response...
                <ProgressBar
                  value={100}
                  className="animated-progress-bar"
                  aria-label="Loading"
                />
              </Box>
            )}
          </div>
        </Box>
        <Box>
          <SpaceBetween direction="vertical" size="s">
            <FormField stretch>
              <Textarea
                id="question"
                value={question}
                onChange={({ detail }) => {
                  setQuestion(detail.value);
                }}
              />
            </FormField>
            <Box float="right">
              <Button variant="primary" onClick={handleSubmit}>
                Submit
              </Button>
            </Box>
          </SpaceBetween>
        </Box>
      </SpaceBetween>
    </Container>
  );
};

export default AskQuestionToAIBA;
