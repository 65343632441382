import { SECTION } from "../../../../../shared/util/constants/section";
import { CashRequestActualsEditFooter } from "./CashRequestActualsEditFooter";
import { AttachmentsEditFooter } from "../../../../../shared/components/FundRequest/Edit/Footer/AttachmentsEditFooter";
import { DefaultEditFooter } from "../../../../../shared/components/FundRequest/Edit/Footer/DefaultEditFooter";
import { OpportunityInformationEditFooter } from "./OpportunityInformationEditFooter";

export const FooterFactory = ({ sectionTitle }) => {
  switch (sectionTitle) {
    case SECTION.CASH_CLAIM_ACTUALS:
      return CashRequestActualsEditFooter;
    case SECTION.ATTACHMENTS:
      return AttachmentsEditFooter;
    case SECTION.OPPORTUNITY_INFORMATION:
      return OpportunityInformationEditFooter;
    default:
      return DefaultEditFooter;
  }
};
