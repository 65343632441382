import React from "react";
import withAppLayout from "../../../shared/components/withAppLayout";
const TestComponent = () => {
  // @ts-ignore
  //  TODO: idk whats going on here...someone needs to fix this
  return withAppLayout({
    component: <TestNestedComponent testString="Hello World!!" />,
    toolsContent: "",
    breadcrumbs: "",
    isToolsOpen: false,
    onToolsChange: () => {},
    notificationsItems: [],
    navigation: "",
    toolsHide: true,
  })();
};

interface TestInterface {
  testString: string;
}

const TestNestedComponent = (test: TestInterface) => {
  return <div>{test.testString}</div>;
};

export default TestComponent;
