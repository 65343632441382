import React from "react";
import { Form, FormField } from "@amzn/awsui-components-react";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Header from "@amzn/awsui-components-react/polaris/header";
import InfoLink from "shared/components/InfoLink";
import Container from "@amzn/awsui-components-react/polaris/container";
import {
  ACCURATE_WORKLOAD_ACKNOWLEDGEMENT_KEY,
  ANNUAL_RUN_RATE_KEY,
  formatAnnualRunRate,
  MIGRATION_PHASE_KEY,
  MODERNIZATION_ACKNOWLEDGEMENT_KEY,
  MODERNIZATION_ANNUAL_RUN_RATE_KEY,
  VMWARE_ACKNOWLEDGEMENT_KEY,
} from "external/components/FundRequest/ProgramSelectMAP2024/util";
import Tiles from "@amzn/awsui-components-react/polaris/tiles";
import Input from "@amzn/awsui-components-react/polaris/input";
import Checkbox from "@amzn/awsui-components-react/polaris/checkbox";
import Link from "@amzn/awsui-components-react/polaris/link";
import { IMigrationEligibilityProps } from "external/programs/migration-acceleration-program/2024/fund-request/views/create/FundRequestCreationView";
import ConditionalField from "shared/components/common/Conditional/ConditionalField";
import {
  MAP2024_MIGRATION_PHASE,
  migrationPhaseOptionsForMAP,
  showModernizationCheck,
} from "external/programs/migration-acceleration-program/2024/fund-request/components/create/eligibility/util";
import { HelpPanelContentContext } from "shared/util/context/help/HelpContext";
import { useTranslation } from "react-i18next";
import { MAP_LITE_THRESHOLD } from "shared/programs/migration-acceleration-program/2024/fund-request/utils/constants";
import { VMwareAcknowledgementCheckbox } from "./acknowledgement/VMwareAcknowledgementCheckbox";
import { VMwareMSPAcknowledgementCheckbox } from "./acknowledgement/VMwareMSPAcknowledgementCheckbox";
import useFeatureFlag from "shared/util/hooks/useFeatureFlag";
import { FEATURE_FLAG_ENUMS } from "shared/util/constants/featureFlagValues";

interface IFieldChangeHandler {
  fieldKey: string;
  value: string;
}

const MigrationEligibilityContainer = ({
  disabled,
  formData,
  fieldErrors,
  formError,
  loading = false,
  updateField,
  updateFieldErrors,
  setFormData,
  setFormError,
  setFieldErrors,
  actions,
}: IMigrationEligibilityProps) => {
  const { t } = useTranslation();
  const { setHelpPanelContent } = React.useContext(HelpPanelContentContext);
  const vmwareEnabledFlag = useFeatureFlag(
    FEATURE_FLAG_ENUMS.VMWARE_MAP_ENABLED
  );
  const vmwareMSPEnabledFlag = useFeatureFlag(
    FEATURE_FLAG_ENUMS.VMWARE_BONUS_MSP_OPTION_ENABLED
  );

  const onArrChangeHandler = ({ fieldKey, value }: IFieldChangeHandler) => {
    const formattedValue = value.replace(/,/g, "");
    const arrPattern = /^\d+$/;

    if (arrPattern.test(formattedValue)) {
      updateFieldErrors({ key: fieldKey, value: "" });
      updateField({ key: fieldKey, value: formattedValue });
    } else {
      updateField({ key: fieldKey, value: formattedValue });
      updateFieldErrors({
        key: fieldKey,
        value: `Enter a valid number.`,
      });
    }
  };

  const showModernizationArrField = (): boolean => {
    if (!formData[ANNUAL_RUN_RATE_KEY]) return false;

    return (
      formData[ANNUAL_RUN_RATE_KEY] >= MAP_LITE_THRESHOLD &&
      !!formData[MODERNIZATION_ACKNOWLEDGEMENT_KEY] &&
      formData[MIGRATION_PHASE_KEY] === MAP2024_MIGRATION_PHASE.MOBILIZE
    );
  };

  return (
    <Form
      header={
        <Header
          variant="h1"
          info={
            <InfoLink
              onFollow={() => {
                setHelpPanelContent("eligibility.page");
              }}
            />
          }
        >
          {t("eligibility.page.title")}
        </Header>
      }
      errorText={formError}
      actions={actions}
    >
      <Container
        header={
          <Header
            variant="h2"
            description={t("eligibility.container.header.description")}
          >
            {t("eligibility.container.header.title")}
          </Header>
        }
      >
        <SpaceBetween size="l">
          <FormField
            label={t(
              "eligibility.container.content.migrationEligibility.fields.migrationPhase.label"
            )}
            errorText={fieldErrors[MIGRATION_PHASE_KEY]}
            stretch={true}
            info={
              <InfoLink
                onFollow={() => {
                  setHelpPanelContent("eligibility.migrationPhase");
                }}
              />
            }
          >
            <Tiles
              value={formData[MIGRATION_PHASE_KEY] || null}
              onChange={({ detail }) => {
                setFormData({ [MIGRATION_PHASE_KEY]: detail.value });
                setFieldErrors({});
                setFormError("");
              }}
              items={migrationPhaseOptionsForMAP.map((phase) => {
                if ((disabled && disabled[MIGRATION_PHASE_KEY]) || loading) {
                  return {
                    ...phase,
                    disabled: true,
                  };
                }

                return phase;
              })}
            ></Tiles>
          </FormField>
          <FormField
            label={t(
              "eligibility.container.content.migrationEligibility.fields.annualRunRate.label"
            )}
            errorText={fieldErrors[ANNUAL_RUN_RATE_KEY]}
            description={t(
              "eligibility.container.content.migrationEligibility.fields.annualRunRate.description"
            )}
          >
            <Input
              id="annualRunRate"
              value={formatAnnualRunRate(formData[ANNUAL_RUN_RATE_KEY])}
              placeholder={t(
                "eligibility.container.content.migrationEligibility.fields.annualRunRate.placeholder"
              )}
              disabled={loading}
              onChange={({ detail }) =>
                onArrChangeHandler({
                  fieldKey: ANNUAL_RUN_RATE_KEY,
                  value: detail.value,
                })
              }
            />
          </FormField>
          <ConditionalField
            showField={showModernizationCheck({
              annualRunRate: formData[ANNUAL_RUN_RATE_KEY],
              migrationPhase: formData[MIGRATION_PHASE_KEY],
            })}
          >
            <FormField
              errorText={fieldErrors[ACCURATE_WORKLOAD_ACKNOWLEDGEMENT_KEY]}
              stretch
            >
              <Checkbox
                onChange={({ detail }) => {
                  updateField({
                    key: ACCURATE_WORKLOAD_ACKNOWLEDGEMENT_KEY,
                    value: detail.checked,
                  });
                }}
                disabled={loading}
                description={t(
                  "eligibility.container.content.migrationEligibility.fields.accurateWorkloadAcknowledgement.description"
                )}
                checked={!!formData[ACCURATE_WORKLOAD_ACKNOWLEDGEMENT_KEY]}
              >
                {t(
                  "eligibility.container.content.migrationEligibility.fields.accurateWorkloadAcknowledgement.label"
                )}
              </Checkbox>
            </FormField>
          </ConditionalField>
          <ConditionalField
            showField={
              vmwareEnabledFlag &&
              formData[MIGRATION_PHASE_KEY] === MAP2024_MIGRATION_PHASE.MOBILIZE
            }
          >
            <VMwareAcknowledgementCheckbox
              formData={formData}
              updateField={updateField}
              loading={loading}
            />
          </ConditionalField>
          <ConditionalField
            showField={
              vmwareMSPEnabledFlag && !!formData[VMWARE_ACKNOWLEDGEMENT_KEY]
            }
          >
            <VMwareMSPAcknowledgementCheckbox
              formData={formData}
              updateField={updateField}
              loading={loading}
            />
          </ConditionalField>
          <ConditionalField
            showField={showModernizationCheck({
              annualRunRate: formData[ANNUAL_RUN_RATE_KEY],
              migrationPhase: formData[MIGRATION_PHASE_KEY],
            })}
          >
            <FormField stretch>
              <Checkbox
                onChange={({ detail }) => {
                  updateField({
                    key: MODERNIZATION_ACKNOWLEDGEMENT_KEY,
                    value: detail.checked,
                  });
                }}
                disabled={loading}
                description={t(
                  "eligibility.container.content.migrationEligibility.fields.modernizationAcknowledgement.description"
                )}
                checked={!!formData[MODERNIZATION_ACKNOWLEDGEMENT_KEY]}
              >
                <span>
                  {t(
                    "eligibility.container.content.migrationEligibility.fields.modernizationAcknowledgement.label"
                  )}{" "}
                  - <i>optional</i>{" "}
                  <InfoLink
                    onFollow={() => {
                      setHelpPanelContent("eligibility.modernizationServices");
                    }}
                  />
                </span>
              </Checkbox>
            </FormField>
          </ConditionalField>
          <ConditionalField showField={showModernizationArrField()}>
            <FormField
              label={t(
                "eligibility.container.content.migrationEligibility.fields.modernizationArr.label"
              )}
              errorText={fieldErrors[MODERNIZATION_ANNUAL_RUN_RATE_KEY]}
              //TODO: Update link
              description={
                <>
                  {`The ARR value should match the AWS pricing calculator ARR value unless using your own calculator for `}
                  <Link external variant="primary" fontSize="inherit">
                    modernization pathway services
                  </Link>
                  .
                </>
              }
            >
              <Input
                id="ModernizationAnnualRunRate"
                value={formatAnnualRunRate(
                  formData[MODERNIZATION_ANNUAL_RUN_RATE_KEY]
                )}
                placeholder={t(
                  "eligibility.container.content.migrationEligibility.fields.modernizationArr.placeholder"
                )}
                disabled={loading}
                onChange={({ detail }) =>
                  onArrChangeHandler({
                    fieldKey: MODERNIZATION_ANNUAL_RUN_RATE_KEY,
                    value: detail.value,
                  })
                }
              />
            </FormField>
          </ConditionalField>
        </SpaceBetween>
      </Container>
    </Form>
  );
};

export default MigrationEligibilityContainer;
