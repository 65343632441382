import React from "react";
import { generatePhonetoolLink } from "../../../util/constants/generatePhonetoolLink";
import { isoFormatDate } from "../../../util/constants/isoFormatDate";
import { statusBadge } from "../../Delegation/TableConfiguration/badgeStatus";
import Link from "@amzn/awsui-components-react/polaris/link";
import {
  PAGE_TITLE,
  STAGE_MAPPING,
  STAGE_TO_PAGE,
} from "../../../util/constants/pages";
import { getUrlCashClaimId } from "./util";

export const searchableColumns = ["frId", "stage", "auditCode", "status"];

export const visibleColumns = [
  "frId",
  "stage",
  "spmsId",
  "requestedTimestamp",
  "auditTimestamp",
  "auditor",
  "auditCode",
  "comments",
  "status",
];

export const auditsColumnDefinition = [
  {
    id: "frId",
    header: "Fund Request Id",
    cell: (e) => {
      const fundRequestId = e.frId;
      const cashClaimId = e.cashClaimId;
      const link = `${
        STAGE_MAPPING[e.stage]
      }/${fundRequestId}/review${getUrlCashClaimId(cashClaimId)}`;
      return <Link href={link}>{fundRequestId}</Link>;
    },
    sortingField: "frId",
  },
  {
    id: "stage",
    header: "Stage",
    cell: (e) => PAGE_TITLE[STAGE_TO_PAGE[e.stage]],
    sortingField: "stage",
  },
  {
    id: "cashClaimId",
    header: "Cash Claim Id",
    cell: (e) => e.cashClaimId,
    sortingField: "cashClaimId",
  },
  {
    id: "spmsId",
    header: "SPMS Id",
    cell: (e) => e.spmsId,
    sortingField: "spmsId",
  },
  {
    id: "auditingAudience",
    header: "Auditing Audience",
    cell: (e) => e.auditingAudience,
    sortingField: "auditingAudience",
  },
  {
    id: "requestedTimestamp",
    header: "Requested",
    cell: (e) => isoFormatDate(e.requestedTimestamp),
    sortingField: "requestedTimestamp",
  },
  {
    id: "auditTimestamp",
    header: "Audited",
    cell: (e) => isoFormatDate(e.auditTimestamp),
    sortingField: "auditTimestamp",
  },
  {
    id: "auditor",
    header: "Auditor",
    cell: (e) => generatePhonetoolLink(e.auditor),
    sortingField: "auditor",
  },
  {
    id: "auditCode",
    header: "Audit Code",
    cell: (e) => (e.auditCode ? e.auditCode : "-"),
    sortingField: "auditCode",
  },
  {
    id: "comments",
    header: "Comments",
    cell: (e) => (e.comments ? e.comments : "-"),
    sortingField: "comments",
  },
  {
    id: "status",
    header: "Status",
    cell: (e) => statusBadge(e.status),
    sortingField: "status",
  },
];
