import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useCollection } from "@amzn/awsui-collection-hooks";
import Grid from "@amzn/awsui-components-react/polaris/grid";
import Table from "@amzn/awsui-components-react/polaris/table";
import Pagination from "@amzn/awsui-components-react/polaris/pagination";
import Input from "@amzn/awsui-components-react/polaris/input";
import Select from "@amzn/awsui-components-react/polaris/select";
import {
  columnDefinitions,
  defaultTemplate,
  defaultProgram,
  defaultStage,
  defaultStatus,
  defaultBudgetYear,
} from "./config";
import { prepareSelectOptions, filterItem, clearFilter } from "./util";
import TableNoMatchState from "../../common/TableNoMatchState";
import TableEmptyState from "../../common/TableEmptyState";
import {
  ERROR_TYPE,
  hasError,
  retryApiCall,
} from "../../../../shared/util/services/data/DataService";
import { getFundRequests } from "../../../util/services/data/FundRequestService";
import {
  getISODate,
  setErrorMessage,
} from "../../../../shared/util/common/helper";
import { SpaceBetween } from "@amzn/awsui-components-react";
import Button from "@amzn/awsui-components-react/polaris/button";
import Header from "@amzn/awsui-components-react/polaris/header";
import { useLocation } from "react-router-dom";
import CreateFundRequestDropDown from "../../Dashboard/common/CreateFundRequestDropDown";
import DeleteModal from "../common/Delete/DeleteModal";
import { exportDataToCSV, CSV_TYPE } from "../common/util";
import { isFeatureEnabled } from "../../../../shared/util/services/features/FeatureFlagsService";
import { FEATURE_FLAG_ENUMS } from "../../../../shared/util/constants/featureFlagValues";
import { mdfBanner } from "../../common/mdfBanner";
import { convertSMPtoMAP } from "../../../../shared/util/common/convertSMPtoMAP";

const FundingActivities = ({ setNotificationsItems }) => {
  const { state } = useLocation();

  const pageSize = 10;
  const [fundingActivitiesData, setFundingActivitiesData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isDownloading, setIsDownloading] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [disableDelete, setDisableDelete] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showWarning, setShowWarning] = useState(true);

  useEffect(async () => {
    if (state && state.deletedFundRequests) {
      await getFundRequestsList(state.deletedFundRequests);
    } else {
      await getFundRequestsList();
    }
    setIsLoading(false);
    const banner = await isFeatureEnabled(
      FEATURE_FLAG_ENUMS.DISPLAY_MDF_BANNER
    );
    if (banner) {
      mdfBanner(setNotificationsItems);
    }
  }, [state]);

  const FundingActivitiesAction = () => {
    const handleExport = () => {
      const exportProps = {
        data: allPageItems,
        csvType: CSV_TYPE.FUNDING_ACTIVITIES,
        setNotificationsItems,
      };
      setIsDownloading(true);
      exportDataToCSV(exportProps);
      setIsDownloading(false);
    };
    return (
      <SpaceBetween direction="horizontal" size="s">
        <Button
          iconName="refresh"
          variant="normal"
          onClick={async () => await getFundRequestsList()}
        />
        <Button
          loading={isDownloading}
          text="Export"
          variant="primary"
          onClick={handleExport}
        >
          Export
        </Button>
        <CreateFundRequestDropDown
          setNotificationsItems={setNotificationsItems}
        />
        <Button
          disabled={disableDelete}
          variant="primary"
          onClick={() => setShowDeleteModal(true)}
        >
          Delete Fund Requests
        </Button>
      </SpaceBetween>
    );
  };

  const getFundRequestsList = async (recentlyDeleted = new Set()) => {
    setIsLoading(true);
    setFundingActivitiesData([]);
    const data = [];
    let token = "";
    while (token != null) {
      try {
        const response = await retryApiCall({
          callApi: getFundRequests(token),
        });

        if (hasError(response) && response.errorType === ERROR_TYPE.BANNER) {
          setErrorMessage({
            setNotificationsItems,
            content: response.message,
            status: response.status,
          });
          setIsLoading(false);
          return;
        }

        for (let fundRequest of response.fundRequestList) {
          const parsedFundRequest = JSON.parse(fundRequest);
          const fundRequestId = parsedFundRequest["fundRequestId"];
          // NOTE: we filter out the most recent delete since in the BE
          // update ops on models are async and there can be a small chance
          // the most recent pull is too soon and has incorrect state.
          if (!recentlyDeleted.has(fundRequestId)) {
            data.push({
              fundRequestId: fundRequestId,
              template: convertSMPtoMAP(parsedFundRequest["program"]),
              program: convertSMPtoMAP(parsedFundRequest["subProgram"]),
              stage: parsedFundRequest["stage"],
              status: parsedFundRequest["status"],
              poNumber: parsedFundRequest["poNumber"] || "-",
              budgetYear: parsedFundRequest["budgetYear"] || "-",
              fundingExpirationDate:
                (parsedFundRequest["fundingExpirationDate"] &&
                  getISODate(parsedFundRequest["fundingExpirationDate"])) ||
                "-",
              activityName: `${parsedFundRequest["activityName"]}`,
              opportunityId: `${parsedFundRequest["opportunityId"]}`,
            });
          }
        }

        token = response.nextPageToken;
      } catch (e) {
        console.error(e);
        setErrorMessage({
          setNotificationsItems,
        });
      }
    }
    setFundingActivitiesData(data);
    setIsLoading(false);
  };

  const [template, setTemplate] = useState(defaultTemplate);
  const [program, setProgram] = useState(defaultProgram);
  const [stage, setStage] = useState(defaultStage);
  const [status, setStatus] = useState(defaultStatus);
  const [budgetYear, setBudgetYear] = useState(defaultBudgetYear);

  const {
    items,
    allPageItems,
    actions,
    collectionProps,
    filterProps,
    paginationProps,
  } = useCollection(fundingActivitiesData, {
    filtering: {
      empty: <TableEmptyState resourceName="resources" />,
      noMatch: (
        <TableNoMatchState
          onClearFilter={() =>
            clearFilter(
              actions,
              setTemplate,
              setProgram,
              setStage,
              setStatus,
              setBudgetYear
            )
          }
        />
      ),
      filteringFunction: (item, filteringText) => {
        return filterItem(
          item,
          filteringText,
          template,
          program,
          stage,
          status,
          budgetYear
        );
      },
    },
    pagination: { pageSize },
    sorting: {},
  });

  const selectTemplateOptions = prepareSelectOptions(
    fundingActivitiesData,
    "template",
    defaultTemplate
  );

  const selectProgramOptions = prepareSelectOptions(
    fundingActivitiesData,
    "program",
    defaultProgram
  );

  const selectStageOptions = prepareSelectOptions(
    fundingActivitiesData,
    "stage",
    defaultStage
  );

  const selectStatusOptions = prepareSelectOptions(
    fundingActivitiesData,
    "status",
    defaultStatus
  );

  const selectBudgetYearOptions = prepareSelectOptions(
    fundingActivitiesData,
    "budgetYear",
    defaultBudgetYear
  );

  useEffect(() => {
    const allCreatedOrTerminated = selectedItems.every(
      (val) =>
        val.stage === "created" ||
        val.status === "terminated" ||
        val.status === "draft"
    );
    if (allCreatedOrTerminated && selectedItems.length > 0) {
      setDisableDelete(false);
    } else if (
      selectedItems.find(
        (s) => s.stage !== "created" || s.status !== "terminated"
      ) &&
      showWarning
    ) {
      setNotificationsItems([
        {
          type: "warning",
          content:
            "You can only delete fund requests in the created stage or terminated status.",
          dismissible: true,
          dimisssLabel: "Dismiss message",
          onDismiss: () => setNotificationsItems([]),
          id: "warn_delete_fr",
        },
      ]);
      setShowWarning(false);
      setDisableDelete(true);
    } else {
      setDisableDelete(true);
    }
  }, [selectedItems]);

  return (
    <React.Fragment>
      <Table
        {...collectionProps}
        onSelectionChange={({ detail }) =>
          setSelectedItems(detail.selectedItems)
        }
        selectedItems={selectedItems}
        selectionType="multi"
        trackBy="fundRequestId"
        columnDefinitions={columnDefinitions}
        items={items}
        allPageItems={allPageItems}
        loading={isLoading}
        loadingText="Loading Funding Activities..."
        filter={
          <Grid
            gridDefinition={[
              { colspan: 4 },
              { colspan: 2 },
              { colspan: 2 },
              { colspan: 2 },
              { colspan: 2 },
              { colspan: 2 },
            ]}
          >
            <Input
              type="search"
              value={filterProps.filteringText}
              onChange={(event) => {
                actions.setFiltering(event.detail.value);
              }}
              placeholder="Find fund request"
              label="Find fund request"
              ariaDescribedby={null}
            />
            <Select
              selectedOption={template}
              onChange={(event) => {
                setTemplate(event.detail.selectedOption);
              }}
              options={selectTemplateOptions}
            />
            <Select
              selectedOption={program}
              onChange={(event) => {
                setProgram(event.detail.selectedOption);
              }}
              options={selectProgramOptions}
            />
            <Select
              className="dashboard-list-table-select"
              selectedOption={stage}
              onChange={(event) => {
                setStage(event.detail.selectedOption);
              }}
              options={selectStageOptions}
            />
            <Select
              className="dashboard-list-table-select"
              selectedOption={status}
              onChange={(event) => {
                setStatus(event.detail.selectedOption);
              }}
              options={selectStatusOptions}
              selectedAriaLabel="Selected"
            />
            <Select
              selectedOption={budgetYear}
              onChange={(event) => {
                setBudgetYear(event.detail.selectedOption);
              }}
              options={selectBudgetYearOptions}
            />
          </Grid>
        }
        header={
          <Header
            counter={items.length + "/" + fundingActivitiesData.length}
            actions={<FundingActivitiesAction allPageItems={allPageItems} />}
          >
            Funding Activities
          </Header>
        }
        pagination={<Pagination {...paginationProps} />}
      />
      <DeleteModal
        fundRequesIds={selectedItems.map((f) => f.fundRequestId)}
        showDeleteModal={showDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
        setNotificationsItems={setNotificationsItems}
        setSelectedItems={setSelectedItems}
      />
    </React.Fragment>
  );
};

FundingActivities.propTypes = {
  setNotificationsItems: PropTypes.func,
};

export default FundingActivities;
