import React from "react";
import { ValueFactory } from "./ValueFactory";

import PropTypes from "prop-types";
import { ProjectInformationReviewSectionContainer } from "../../../../../shared/components/Review/ProjectInformationReviewSectionContainer";
import { filterFields, schema } from "../../../../config/schema";
import { Header } from "@amzn/awsui-components-react";
import Button from "@amzn/awsui-components-react/polaris/button";
import { canEdit } from "../util";

export const ExternalProjectInformationReviewSectionContainer = ({
  fundingData,
  index,
  step,
  template,
  id,
  history,
}) => {
  return (
    <ProjectInformationReviewSectionContainer
      fundingData={fundingData}
      index={index}
      step={step}
      template={template}
      valueFactory={ValueFactory}
      schema={schema}
      filterFields={filterFields}
      header={
        <Header
          actions={
            canEdit({
              status: fundingData["status"],
              stage: fundingData["stage"],
            }) ? (
              <Button
                onClick={() =>
                  history.push(
                    `/fund-request/${id}/${template[step].name}/edit`
                  )
                }
              >
                Edit
              </Button>
            ) : (
              ""
            )
          }
        >
          {template[step].title}
        </Header>
      }
    />
  );
};

ExternalProjectInformationReviewSectionContainer.propTypes = {
  fundingData: PropTypes.object,
  index: PropTypes.number,
  step: PropTypes.array,
  template: PropTypes.object,
  id: PropTypes.string,
  history: PropTypes.object,
};
