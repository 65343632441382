import React from "react";
import { Link } from "@amzn/awsui-components-react";

interface IGenerateOpportunityLink {
  aceOpportunityId: string;
  friendlyOpportunityId: string;
  baseUrl: string;
}

export const generateOpportunityIdLink = ({
  aceOpportunityId,
  friendlyOpportunityId,
  baseUrl,
}: IGenerateOpportunityLink) => {
  return (
    <Link
      href={`${baseUrl}${aceOpportunityId}`}
      target="_blank"
      variant="secondary"
    >
      {friendlyOpportunityId}
    </Link>
  );
};
