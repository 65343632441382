import { ROOT_ENTITY_NAME } from "../../../constant";

export const selfServiceColumnDefinition = [
  {
    id: "index",
    header: "Item Index",
    cell: (e) => e.index,
    sortingField: "index",
  },
  {
    id: "name",
    header: "Item Name",
    cell: (e) => e.name,
    sortingField: "name",
  },
  {
    id: "value",
    header: "Item Description",
    cell: (e) => e.value,
    sortingField: "value",
  },
  {
    id: "permissions",
    header: "Permissions",
    cell: (e) => e.permissions?.toString(),
    sortingField: "permissions",
  },
];

export const getVisibleColumns = (entity, selfServiceAuthorizerFlag) =>
  entity === ROOT_ENTITY_NAME && selfServiceAuthorizerFlag
    ? ["index", "name", "value", "permissions"]
    : ["index", "name", "value"];
export const searchableColumns = ["name", "value"];
export const sortingField = "index";
