import "./style.css";
import PropTypes from "prop-types";
import React from "react";

/** Mimics the Polaris Progress Bar component. All the classes are the same as the Polaris ones except for the randomly generated part and _apfp at the end.*/
const APFPProgressBar = ({ value, additionalInfo, label }) => {
  return (
    <div className="awsui_root_11huc_1mnuo_221_apfp">
      <div>
        <div
          className="awsui_word-wrap_11huc_1mnuo_251_apfp"
          id="awsui-progress-bar-20-1691797344971-1182-label"
        >
          {label}
        </div>
        <div>
          <div className="awsui_progress-container_11huc_1mnuo_273_apfp">
            <progress
              className="awsui_progress_11huc_1mnuo_185_apfp"
              max="100"
              value={value}
              aria-labelledby="awsui-progress-bar-20-1691797344971-1182-label"
            ></progress>
            <span
              aria-hidden="true"
              className="awsui_percentage-container_11huc_1mnuo_280_apfp"
            >
              <small className="awsui_percentage_11huc_1mnuo_280_apfp awsui_root_18wu0_15enz_93_apfp awsui_box_18wu0_15enz_207_apfp awsui_small-variant_18wu0_15enz_215_apfp awsui_color-default_18wu0_15enz_207_apfp awsui_font-size-default_18wu0_15enz_223_apfp awsui_font-weight-default_18wu0_15enz_263_apfp">
                {value}%
              </small>
            </span>
          </div>
          <span className="awsui_root_xttbq_1i4dh_93_apfp awsui_root_3bgfn_im8v7_5_apfp">
            <span aria-hidden="true">
              {label}: {value}%
            </span>
            <span aria-atomic="true" aria-live="polite">
              {label}: {value}%
            </span>
          </span>
        </div>
      </div>
      <small className="awsui_word-wrap_11huc_1mnuo_251_apfp awsui_root_18wu0_15enz_93_apfp awsui_box_18wu0_15enz_207_apfp awsui_small-variant_18wu0_15enz_215_apfp awsui_d-block_18wu0_15enz_989_apfp awsui_color-default_18wu0_15enz_207_apfp awsui_font-size-default_18wu0_15enz_223_apfp awsui_font-weight-default_18wu0_15enz_263_apfp">
        {additionalInfo}
      </small>
    </div>
  );
};

APFPProgressBar.propTypes = {
  value: PropTypes.number,
  additionalInfo: PropTypes.node,
  label: PropTypes.node,
};

export default APFPProgressBar;
