import React from "react";
import { INTERNAL_REVIEW_ACTION_TYPE } from "./reviewActionType";
import { selfServiceRejectionReasonCodeEntityMap } from "../../../util/constants/rejections";
import RejectCommentModal from "./RejectCommentModal";
import CommentModal from "./CommentModal";
import PropTypes from "prop-types";

const ChooseCommentModal = ({
  reviewActionType,
  isLoading,
  isVisible,
  reviewAction,
  updateVisibility,
  stage,
}) => {
  return reviewActionType === INTERNAL_REVIEW_ACTION_TYPE.REJECT &&
    stage in selfServiceRejectionReasonCodeEntityMap ? (
    <RejectCommentModal
      reviewActionType={reviewActionType}
      reviewAction={reviewAction}
      isLoading={isLoading}
      isVisible={isVisible}
      updateVisibility={updateVisibility}
      stage={stage}
    />
  ) : (
    <CommentModal
      reviewActionType={reviewActionType}
      reviewAction={reviewAction}
      isLoading={isLoading}
      isVisible={isVisible}
      updateVisibility={updateVisibility}
    />
  );
};

ChooseCommentModal.propTypes = {
  isLoading: PropTypes.bool,
  isVisible: PropTypes.bool,
  reviewActionType: PropTypes.string,
  reviewAction: PropTypes.func,
  updateVisibility: PropTypes.func,
  stage: PropTypes.string,
};

export default ChooseCommentModal;
