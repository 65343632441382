import React from "react";
import Table from "@amzn/awsui-components-react/polaris/table";
import { IGenericObject } from "shared/programs/migration-acceleration-program/2024/fund-request/types/CommonTypes";
import {
  generateColumnDefinitions,
  generateItems,
} from "internal/programs/migration-acceleration-program/2024/fund-request/components/details/sections/migration/split/util";
import { SplitPanel } from "@amzn/awsui-components-react";
import Header from "@amzn/awsui-components-react/polaris/header";

const MigrationProgramEngagementSplitPanel = ({
  selectedEngagements,
}: {
  selectedEngagements: Array<unknown>;
}) => {
  const engagementIds = (selectedEngagements as Array<IGenericObject>).map(
    (engagement) => engagement.engagementId
  );

  const columnDefinitions = generateColumnDefinitions({ engagementIds });

  const items = generateItems({ engagements: selectedEngagements });

  return (
    <SplitPanel
      header={`MPEs selected (${selectedEngagements.length})`}
      hidePreferencesButton={true}
      closeBehavior="collapse"
    >
      <Table
        header={<Header variant="h2">Compare details</Header>}
        items={items}
        columnDefinitions={columnDefinitions}
      />
    </SplitPanel>
  );
};

export default MigrationProgramEngagementSplitPanel;
