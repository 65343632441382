import React, { useState } from "react";
import { queryToString } from "../util";
import { Alert, Box, SpaceBetween } from "@amzn/awsui-components-react";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import Button from "@amzn/awsui-components-react/polaris/button";
import PropTypes from "prop-types";

const DeleteFilterSetModal = ({
  currentFilterSet,
  filteringProperties,
  onCancel,
  onSubmit,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(true);
  const onDismiss = () => {
    onCancel();
    setIsModalVisible(false);
  };

  const submitModal = () => {
    onSubmit();
    setIsModalVisible(false);
  };

  return (
    <Modal
      header="Delete filter set"
      visible={isModalVisible}
      onDismiss={onDismiss}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={onDismiss}>
              Cancel
            </Button>
            <Button
              variant="primary"
              data-testid="submit"
              onClick={submitModal}
            >
              Delete
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <SpaceBetween size="m">
        <Box variant="span">
          Permanently delete{" "}
          <Box variant="span" fontWeight="bold">
            {currentFilterSet.name}
          </Box>
          ? You can’t undo this action.
        </Box>
        <Alert type="info" statusIconAriaLabel="Info">
          Proceeding with this action will delete the saved filter set with the
          updated configuration.
        </Alert>
        <div>
          <Box variant="awsui-key-label">Filter set name</Box>
          <div>{currentFilterSet.name}</div>
        </div>
        <div>
          <Box variant="awsui-key-label">Filter set description</Box>
          <div>{currentFilterSet.description ?? "-"}</div>
        </div>
        <div>
          <Box variant="awsui-key-label">Filter values</Box>
          <div>
            {queryToString(currentFilterSet.query, filteringProperties)}
          </div>
        </div>
      </SpaceBetween>
    </Modal>
  );
};

DeleteFilterSetModal.propTypes = {
  currentFilterSet: PropTypes.object,
  filteringProperties: PropTypes.array,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default DeleteFilterSetModal;
