import React, { useEffect, useState } from "react";
import { IStepStateProps } from "../types/WizardTypes";
import FundRequestWizardContainer from "external/programs/migration-acceleration-program/2024/fund-request/components/create/wizard/FundRequestWizardContainer";
import ProjectDetails from "external/programs/migration-acceleration-program/2024/fund-request/components/create/wizard/Steps/ProjectDetails";
import FundingDetails from "external/programs/migration-acceleration-program/2024/fund-request/components/create/wizard/Steps/FundingDetails";
import OpportunityDetails from "external/programs/migration-acceleration-program/2024/fund-request/components/create/wizard/Steps/OpportunityDetails/OpportunityDetails";
import Review from "external/programs/migration-acceleration-program/2024/fund-request/components/create/wizard/Steps/Review";
import Artifacts from "external/programs/migration-acceleration-program/2024/fund-request/components/create/wizard/Steps/Artifacts";
import {
  artifactsAdapter,
  fundingDetailsAdapter,
  opportunityAdapter,
  projectDetailsAdapter,
  reviewAdapter,
} from "external/programs/migration-acceleration-program/2024/fund-request/components/create/wizard/api/adapters";
import { FundRequestSteps } from "external/programs/migration-acceleration-program/2024/fund-request/types/SchemaTypes";
import {
  updateArtifacts,
  updateFundingDetails,
  updateOpportunityDetails,
  updateProjectDetails,
} from "external/programs/migration-acceleration-program/2024/fund-request/components/create/wizard/api/onSubmit";
import { IMAPFundRequest } from "shared/programs/migration-acceleration-program/2024/fund-request/types/SchemaTypes";
import { IGenericObject } from "shared/programs/migration-acceleration-program/2024/fund-request/types/CommonTypes";
import InfoLink from "shared/components/InfoLink";
import { HelpPanelContentContext } from "shared/util/context/help/HelpContext";
import { useTranslation } from "react-i18next";

const MAPFundRequestCreationWizard = ({ ...props }) => {
  const { t } = useTranslation();

  const { setHelpPanelContent } = React.useContext(HelpPanelContentContext);

  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [data, setData] = useState<IMAPFundRequest>({});
  const [notificationsItems, setNotificationsItems] = useState<
    IGenericObject[]
  >([]);
  const [fieldErrors, setFieldErrors] = useState<IGenericObject>({});
  const [formError, setFormError] = useState<string>("");

  useEffect(() => {
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    const activeSection = params.get("activeSection");

    try {
      if (activeSection && !Number.isNaN(activeSection)) {
        const sectionNumber = Number(activeSection);
        if (sectionNumber < 0 || sectionNumber > 3) return;
        setActiveStepIndex(sectionNumber);
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  const onChange = ({
    key,
    value,
  }: {
    key: string;
    value: string | boolean | undefined;
  }) => {
    if (formError) setFormError("");
    if (Object.keys(fieldErrors).length > 0) setFieldErrors({});

    setData((prevData) => ({ ...prevData, [key]: value }));
  };

  const onError = ({ key, value }: { key: string; value: string }) => {
    setFieldErrors((prevErrors) => ({ ...prevErrors, [key]: value }));
  };

  const wizardProps: IStepStateProps = {
    activeStepIndex,
    data,
    notificationsItems,
    errors: fieldErrors,
    formError,
    setActiveStepIndex,
    onChange,
    onError,
    setData,
    setErrors: setFieldErrors,
    setFormError,
    setNotificationsItems,
  };

  const steps: FundRequestSteps[] = [
    {
      title: t("wizard.section.opportunity.page.header.title"),
      info: (
        <InfoLink
          onFollow={() => {
            setHelpPanelContent("wizard.opportunity.page");
          }}
        />
      ),
      content: (
        <OpportunityDetails {...wizardProps} adapt={opportunityAdapter} />
      ),
      onSubmit: updateOpportunityDetails,
    },
    {
      title: t("wizard.section.projectDetails.page.header.title"),
      info: (
        <InfoLink
          onFollow={() => {
            setHelpPanelContent("wizard.projectDetails.page");
          }}
        />
      ),
      content: (
        <ProjectDetails {...wizardProps} adapt={projectDetailsAdapter} />
      ),
      onSubmit: updateProjectDetails,
    },
    {
      title: t("wizard.section.fundingDetails.page.header.title"),
      info: (
        <InfoLink
          onFollow={() => setHelpPanelContent("wizard.fundingDetails.page")}
        />
      ),
      content: (
        <FundingDetails {...wizardProps} adapt={fundingDetailsAdapter} />
      ),
      onSubmit: updateFundingDetails,
    },
    {
      title: t("wizard.section.supportingDocumentation.page.header.title"),
      description: t(
        "wizard.section.supportingDocumentation.page.header.description"
      ),
      info: (
        <InfoLink
          onFollow={() =>
            setHelpPanelContent("wizard.supportingDocumentation.page")
          }
        />
      ),
      content: <Artifacts {...wizardProps} adapt={artifactsAdapter} />,
      onSubmit: updateArtifacts,
    },
    {
      title: "Review and submit",
      content: <Review {...wizardProps} adapt={reviewAdapter} />,
    },
  ];

  return (
    <FundRequestWizardContainer
      activeStepIndex={activeStepIndex}
      data={data}
      setActiveStepIndex={setActiveStepIndex}
      steps={steps}
      formError={formError}
      setFormError={setFormError}
      errors={fieldErrors}
      setErrors={setFieldErrors}
      {...props}
    />
  );
};

export default MAPFundRequestCreationWizard;
