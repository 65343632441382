import { MAP2024_MIGRATION_PHASE } from "external/programs/migration-acceleration-program/2024/fund-request/components/create/eligibility/util";

export enum AceOpportunityStage {
  Prospect = "Prospect",
  Qualified = "Qualified",
  TechnicalValidation = "Technical Validation",
  BusinessValidation = "Business Validation",
  Committed = "Committed",
  Launched = "Launched",
}

const getValidOpportunityStages = (
  migrationPhase: string
): Array<AceOpportunityStage> => {
  const validOpportunityStages = {
    [MAP2024_MIGRATION_PHASE.ASSESS]: [
      AceOpportunityStage.TechnicalValidation,
      AceOpportunityStage.BusinessValidation,
      AceOpportunityStage.Committed,
      AceOpportunityStage.Launched,
    ],
    [MAP2024_MIGRATION_PHASE.MOBILIZE]: [
      AceOpportunityStage.Committed,
      AceOpportunityStage.Launched,
    ],
  };

  return (
    validOpportunityStages[migrationPhase] || [
      AceOpportunityStage.Prospect,
      AceOpportunityStage.Qualified,
      AceOpportunityStage.TechnicalValidation,
      AceOpportunityStage.BusinessValidation,
      AceOpportunityStage.Committed,
      AceOpportunityStage.Launched,
    ]
  );
};

export default getValidOpportunityStages;
