import React from "react";
import Icon from "@amzn/awsui-components-react/polaris/icon";
import Box from "@amzn/awsui-components-react/polaris/box";

const ERROR_STATUS = ["Unsuccessful", "FileEncodingError"];

const getStatus = (status) => {
  if (ERROR_STATUS.includes(status)) {
    return (
      <div>
        <Icon name="status-negative" size="normal" variant="error" />{" "}
        <Box color="text-status-error" display="inline-block">
          {status}
        </Box>
      </div>
    );
  }

  if (status === "Successful") {
    return (
      <div>
        <Icon name="status-positive" size="normal" variant="success" />{" "}
        <Box color="text-status-success" display="inline-block">
          {status}
        </Box>
      </div>
    );
  }

  if (status === "Partial Upload") {
    return (
      <div>
        <Icon name="status-stopped" size="normal" variant="subtle" />{" "}
        <Box color="text-status-inactive" display="inline-block">
          {status}
        </Box>
      </div>
    );
  }

  if (status === "Processing") {
    return (
      <div>
        <Icon name="status-pending" size="normal" variant="link" />{" "}
        <Box color="text-status-info" display="inline-block">
          {status}
        </Box>
      </div>
    );
  }

  if (status === "FileEncodingError") {
    return (
      <div>
        <Icon name="status-pending" size="normal" variant="link" />{" "}
        <Box color="text-status-info" display="inline-block">
          {status}
        </Box>
      </div>
    );
  }

  return status;
};

export const columnDefinitions = [
  {
    id: "actions",
    header: "Actions",
    cell: (e) => e.actions,
    sortingField: "actions",
  },
  {
    id: "status",
    header: "Status",
    cell: (e) => getStatus(e.status),
    sortingField: "status",
  },
  {
    id: "startTime",
    header: "Start Time",
    cell: (e) => getStatus(e.startTime),
    sortingField: "startTime",
  },
  {
    id: "endTime",
    header: "End Time",
    cell: (e) => getStatus(e.endTime),
    sortingField: "endTime",
  },
  {
    id: "successful",
    header: "Successful",
    cell: (e) => e.successful || 0,
    sortingField: "successful",
  },
  {
    id: "unsuccessful",
    header: "Unsuccessful",
    cell: (e) => e.unsuccessful || 0,
    sortingField: "unsuccessful",
  },
  {
    id: "totalProcessed",
    header: "Total Processed",
    cell: (e) => e.totalProcessed || 0,
    sortingField: "totalProcessed",
  },
];

export const searchableColumns = [
  "status",
  "successful",
  "unsuccessful",
  "totalProcessed",
];
