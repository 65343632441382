import React, { useEffect, useState } from "react";
import withAppLayout from "../../../shared/components/withAppLayout";
import BulkActionsTable from "../../components/BulkActions/Table";
import SideNavigation from "../../components/common/SideNavigation";
import { getBreadcrumbs } from "../../../shared/util/common/getBreadcrumbs";
import DownloadTemplate from "../../components/BulkActions/DownloadTemplate";
import NewBulkAction from "../../components/BulkActions/SubmitBulkAction/NewBulkAction";
import { useHistory } from "react-router-dom";
import { nanoid } from "nanoid";

const BulkActionsPage = () => {
  const [notificationsItems, setNotificationsItems] = useState([]);
  const [
    isDownloadTemplateComponentVisible,
    setIsDownloadTemplateComponentVisible,
  ] = useState(false);
  const [isNewBulkActionVisible, setNewBulkActionVisible] = useState(false);

  const history = useHistory();
  const state = history.location.state;

  useEffect(() => {
    if (notificationsItems.length != 0) {
      history.replace({ state: {} });
    }
  }, [notificationsItems]);

  if (
    state &&
    "errorMsg" in state &&
    state["errorMsg"] &&
    notificationsItems.length == 0
  ) {
    setNotificationsItems([
      {
        type: "error",
        dismissible: true,
        onDismiss: () => setNotificationsItems([]),
        content: state["errorMsg"],
        id: nanoid(10),
      },
    ]);
  }

  return withAppLayout({
    component: (
      <React.Fragment>
        <BulkActionsTable
          setNotificationsItems={setNotificationsItems}
          setIsDownloadTemplateComponentVisible={
            setIsDownloadTemplateComponentVisible
          }
          setNewBulkActionVisible={setNewBulkActionVisible}
        />
        <DownloadTemplate
          isDownloadTemplateComponentVisible={
            isDownloadTemplateComponentVisible
          }
          setIsDownloadTemplateComponentVisible={
            setIsDownloadTemplateComponentVisible
          }
        />
        <NewBulkAction
          setNotificationsItems={setNotificationsItems}
          isNewBulkActionVisible={isNewBulkActionVisible}
          setNewBulkActionVisible={setNewBulkActionVisible}
        />
      </React.Fragment>
    ),
    breadcrumbs: getBreadcrumbs({ text: "Bulk Actions" }),
    navigationHide: false,
    navigation: <SideNavigation />,
    toolsHide: true,
    notificationsItems: notificationsItems,
  })();
};

export default BulkActionsPage;
