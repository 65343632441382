import React from "react";
import BreadcrumbGroup from "@amzn/awsui-components-react/polaris/breadcrumb-group";

export const getBreadcrumbs = (currentItem) => {
  const items = [{ text: "Dashboard", href: "/dashboard" }];
  if (currentItem) {
    items.push(currentItem);
  }

  return <BreadcrumbGroup items={items} />;
};
