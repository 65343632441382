import { convertSMPtoMAP } from "../../../../shared/util/common/convertSMPtoMAP";

export const getTemplate = (window) => {
  return window.location.search.split("=")[1];
};

export const getProgramOptions = (programs) => {
  const items = [];
  for (let programName in programs) {
    items.push({
      label: convertSMPtoMAP(programName),
      description: programs[programName].description,
      value: programName,
    });
  }
  return items;
};

export const getFundingTypeOptions = (fundingTypes) => {
  const items = [];
  fundingTypes.forEach((type) => {
    items.push({
      label: type,
      value: type,
    });
  });

  return items;
};
