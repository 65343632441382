import { getAssigneeCandidates } from "../../../util/services/data/InternalDataService";
import React, { useRef, useState } from "react";
import { Autosuggest } from "@amzn/awsui-components-react";
import { ASSIGNEE_TYPE } from "../Header/util";
import PropTypes from "prop-types";
import { retryApiCall } from "../../../../shared/util/services/data/DataService";

const Status = Object.freeze({
  LOADING: "loading",
  PENDING: "pending",
  FINISHED: "finished",
  ERROR: "error",
});

const AssigneeSelection = ({ assigneeType, setAssignee, setError }) => {
  const userInput = useRef("");
  const [value, setValue] = useState("");
  const [options, setOptions] = useState([]);
  const [status, setStatus] = useState(Status.PENDING);
  const [isInputInvalid, setIsInputInvalid] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const fetchPersonData = async () => {
    const userInputCurrent = userInput.current;
    const responseOptions = [];
    if (userInputCurrent.length > 2) {
      const response = await retryApiCall({
        callApi: getAssigneeCandidates(userInputCurrent, assigneeType),
      });

      if (userInputCurrent !== userInput.current) {
        // there is another request in progress, discard the result of this one
        return;
      }

      if ("candidates" in response) {
        for (const candidate of response["candidates"]) {
          responseOptions.push({
            value: `${candidate["alias"]}`,
            label: `${candidate["alias"]} (${candidate["name"]})`,
          });
        }
        setStatus(Status.FINISHED);
      } else {
        console.error("No candidates field in response dict");
        setErrorMessage(response["message"]);
        setStatus(Status.ERROR);
      }
    } else {
      setStatus(Status.PENDING);
    }
    setOptions(responseOptions);
  };

  const fetchTeamData = async () => {
    const userInputCurrent = userInput.current;
    const responseOptions = [];
    if (userInputCurrent.length > 0) {
      const response = await retryApiCall({
        callApi: getAssigneeCandidates(userInputCurrent, assigneeType),
      });

      if (userInputCurrent !== userInput.current) {
        // there is another request in progress, discard the result of this one
        return;
      }

      if ("candidates" in response) {
        for (const candidate of response["candidates"]) {
          responseOptions.push({ value: `${candidate["alias"]}` });
        }
        setStatus(Status.FINISHED);
      } else {
        console.error("No candidates field in response dict");
        setStatus(Status.ERROR);
      }
    }
    setOptions(responseOptions);
  };

  const fetchData = async () => {
    try {
      switch (assigneeType) {
        case "Person":
          fetchPersonData();
          break;

        case "Team":
          fetchTeamData();
          break;

        default:
          console.error(`Unexpected assignee type: ${assigneeType}`);
          setOptions([]);
      }
    } catch (error) {
      setStatus(Status.ERROR);
      setOptions([]);
    }
  };

  const isUserInputValid = () => {
    const aliasRegex =
      assigneeType === ASSIGNEE_TYPE.person
        ? /^[A-Za-z ]*$/
        : /^[A-Za-z0-9-]*$/;
    if (value == null || value === "" || aliasRegex.test(value)) {
      setError("");
      setIsInputInvalid(false);
      return true;
    } else {
      setError("Please enter a valid Amazon alias or name.");
      setIsInputInvalid(true);
      setOptions([]);
      return false;
    }
  };

  const handleLoadItems = ({ detail: { filteringText } }) => {
    userInput.current = filteringText;
    setStatus(Status.LOADING);
    if (isUserInputValid(filteringText)) {
      fetchData();
    } else {
      setStatus(Status.PENDING);
    }
  };

  const handleChange = (event) => {
    setValue(event.detail.value);
    setAssignee(event.detail.value);
  };

  const enteredTextLabel = (value) => {
    return isInputInvalid
      ? `Please enter a valid Amazon alias or name.`
      : `Use: "${value}"`;
  };

  return (
    <Autosuggest
      value={value}
      placeholder=""
      loadingText=""
      errorText={errorMessage}
      recoveryText="Retry"
      finishedText={
        userInput.current
          ? `End of "${userInput.current}" results`
          : "End of all results"
      }
      empty="No aliases found"
      statusType={status}
      options={options}
      filteringType="manual"
      enteredTextLabel={enteredTextLabel}
      onChange={handleChange}
      onLoadItems={handleLoadItems}
      onBlur={isUserInputValid}
      disableBrowserAutocorrect={true}
    />
  );
};

AssigneeSelection.propTypes = {
  assigneeType: PropTypes.string,
  setAssignee: PropTypes.func,
  setError: PropTypes.func,
};

export default AssigneeSelection;
