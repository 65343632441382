export const DELEGATION_KEYS = {
  FROM_KEY: "delegator",
  TO_KEY: "delegatee",
  DELEGATION_PERIOD: "delegationPeriod",
  START_KEY: "startTimestamp",
  END_KEY: "endTimestamp",
  REASON_KEY: "reason",
};

export const DELEGATION_MODAL_TYPE = {
  CREATE: "Create",
  EDIT: "Edit",
};
