import React from "react";

import PropTypes from "prop-types";
import { CashRequestReviewSectionContainer } from "../../../../../shared/components/Review/CashRequestReviewSectionContainer";
import { ValueFactory } from "./ValueFactory";
import { filterFields, schema } from "../../../../config/schema";
import {
  getDownloadUrl,
  getFundRequestAttachments,
} from "../../../../util/services/data/FundRequestService";
import { CashClaimReviewSectionContainer } from "./CashClaimReviewSectionContainer";
import { canEdit } from "../util";

export const ExternalCashRequestReviewSectionContainer = ({
  fundingData,
  index,
  setNotificationsItems,
}) => (
  <CashRequestReviewSectionContainer
    fundingData={fundingData}
    index={index}
    setNotificationsItems={setNotificationsItems}
    valueFactory={ValueFactory}
    filterFields={filterFields}
    schema={schema}
    getFundRequestAttachments={getFundRequestAttachments}
    getDownloadUrl={getDownloadUrl}
    CashClaimReviewSectionContainer={CashClaimReviewSectionContainer}
    baseUrl={`/fund-request`}
    showHeader={canEdit({
      status: fundingData["status"],
      stage: fundingData["stage"],
    })}
  />
);

ExternalCashRequestReviewSectionContainer.propTypes = {
  fundingData: PropTypes.object,
  index: PropTypes.number,
  setNotificationsItems: PropTypes.func,
};
