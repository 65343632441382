import React, { useEffect, useState } from "react";
import { useCollection } from "@amzn/awsui-collection-hooks";
import TableEmptyState from "../common/TableEmptyState";
import TableNoMatchState from "../common/TableNoMatchState";
import Table from "@amzn/awsui-components-react/polaris/table";
import {
  delegatesColumnDefinition,
  searchableColumns,
  visibleColumns,
} from "./TableConfiguration/tableConfig";
import DelegationsTableHeader from "./TableConfiguration/DelegationsTableHeader";
import { Pagination } from "@amzn/awsui-components-react";

import PropTypes from "prop-types";

import {
  ERROR_TYPE,
  hasError,
  retryApiCall,
} from "../../../shared/util/services/data/DataService";
import { getDelegations } from "../../util/services/data/InternalDataService";
import { setErrorMessage } from "../../../shared/util/common/helper";
import TableFilter from "../common/TableFilter";

const DelegationsTable = ({ setNotifications }) => {
  //TODO: get delegations for an individual user (that's not the default user)
  //const { } = useParams();
  const PAGE_SIZE = 10;
  const RESOURCE_NAME = "delegates";
  const [delegations, setDelegations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedItems, setSelectedItems] = useState([]);

  const loadDelegations = async () => {
    setIsLoading(true);
    setDelegations([]);
    const data = [];

    let nextPageToken = "";
    while (nextPageToken != null) {
      try {
        const response = await retryApiCall({
          callApi: getDelegations({ nextPageToken }),
        });

        console.log(response);

        if (hasError(response) && response.errorType === ERROR_TYPE.BANNER) {
          setErrorMessage({
            setNotificationsItems: setNotifications,
            content: response.message,
            status: response.status,
          });
          setIsLoading(false);
          return;
        }

        for (let request of response["delegations"]) {
          data.push({
            delegationId: request.delegationId,
            delegator: request.delegator,
            delegatee: request.delegatee,
            startTimestamp: request.startTimestamp["timestamp"],
            endTimestamp: request.endTimestamp["timestamp"],
            reason: request.reason,
            status: request.status,
            state: request.state,
          });
        }

        nextPageToken = response.nextPageToken;
      } catch (e) {
        console.error(e);
        setErrorMessage({
          setNotificationsItems: setNotifications,
        });
      }
    }

    setDelegations(data);
    setIsLoading(false);
  };

  useEffect(async () => {
    await loadDelegations();
  }, []);

  const { items, actions, collectionProps, filterProps, paginationProps } =
    useCollection(delegations || [], {
      pagination: { pageSize: PAGE_SIZE },
      empty: <TableEmptyState resourceName={RESOURCE_NAME} />,
      filtering: {
        noMatch: (
          <TableNoMatchState
            onClearFilter={() => {
              actions.setFiltering("");
            }}
          />
        ),
        filteringFunction: (item, filterText) => {
          return searchableColumns
            .map((key) => item[key])
            .some(
              (value) =>
                typeof value === "string" &&
                value.toLowerCase().includes(filterText.toLowerCase())
            );
        },
      },
      sorting: {
        defaultState: {
          sortingColumn: { sortingField: "startTimestamp" },
          isDescending: true,
        },
      },
      selection: {},
    });

  return (
    <Table
      {...collectionProps}
      loading={isLoading}
      loadingText="Loading delegations"
      items={items}
      selectionType="single"
      columnDefinitions={delegatesColumnDefinition}
      filter={
        <TableFilter
          filterProps={filterProps}
          actions={actions}
          label={"Find Delegation..."}
        />
      }
      header={
        <DelegationsTableHeader
          counter={`${items.length} / ${delegations.length}` || 0}
          refresh={loadDelegations}
          selectedItems={selectedItems}
          setNotifications={setNotifications}
        />
      }
      onSelectionChange={({ detail }) => {
        setSelectedItems(detail.selectedItems);
      }}
      selectedItems={selectedItems}
      pagination={<Pagination {...paginationProps} />}
      visibleColumns={visibleColumns}
    />
  );
};

DelegationsTable.propTypes = {
  setNotifications: PropTypes.func,
};

export default DelegationsTable;
