import React, { useEffect, useState } from "react";
import Container from "@amzn/awsui-components-react/polaris/container";
import { Header, Link } from "@amzn/awsui-components-react";
import { MAX_FILE_SIZE_IN_MB } from "../util";
import { FileUploadComponent } from "shared/programs/migration-acceleration-program/2024/fund-request/components/attachments/FileUploadComponent";
import {
  equals,
  getArtifacts,
} from "shared/programs/migration-acceleration-program/2024/fund-request/components/attachments/util";
import { IArtifactFileModel } from "shared/programs/migration-acceleration-program/2024/fund-request/components/attachments/types/fileTypes";
import { IGenericObject } from "shared/programs/migration-acceleration-program/2024/fund-request/types/CommonTypes";
import InfoLink from "shared/components/InfoLink";
import { HelpPanelContentContext } from "shared/util/context/help/HelpContext";
import { useTranslation } from "react-i18next";
import { PARTNER_SCOPE_CHECKLIST_LINK } from "shared/util/constants/constantValues";

const MigrationScopeChecklistSection = ({ data }: { data: IGenericObject }) => {
  const [files, setFiles] = useState<File[]>([]);
  const [fileIdToAttributes, setFileIdToAttributes] =
    useState<IArtifactFileModel>({});
  const { setHelpPanelContent } = React.useContext(HelpPanelContentContext);
  const { t } = useTranslation();

  useEffect(() => {
    const documents = data && data["documents"];
    if (!documents) return;
    const artifacts = getArtifacts({
      documents,
      filter: (actualType) =>
        equals(actualType, "Migration Assessment Scope Checklist"),
    });
    setFiles(artifacts.files || []);
    setFileIdToAttributes(artifacts.fileIdToAttributes);
  }, [data]);

  return (
    <Container
      header={
        <Header
          variant="h2"
          info={
            <InfoLink
              onFollow={() => {
                setHelpPanelContent(
                  "wizard.supportingDocumentation.container.scopeChecklist"
                );
              }}
            />
          }
        >
          {t(
            "wizard.section.supportingDocumentation.content.partnerScopeChecklist.header.title"
          )}
        </Header>
      }
    >
      <FileUploadComponent
        artifacts={{ files, fileIdToAttributes }}
        updateFiles={setFiles}
        setFileIdToAttributes={setFileIdToAttributes}
        label={t(
          "wizard.section.supportingDocumentation.content.partnerScopeChecklist.fields.upload.label"
        )}
        supportingDocumentType="Migration Assessment Scope Checklist"
        description={
          <>
            For a checklist template, navigate to the{" "}
            <Link
              external
              variant="primary"
              fontSize="inherit"
              href={PARTNER_SCOPE_CHECKLIST_LINK}
            >
              partner scope checklist template
            </Link>
            .
          </>
        }
        constraintText={`File size limit: ${MAX_FILE_SIZE_IN_MB} MB`}
      />
    </Container>
  );
};

export default MigrationScopeChecklistSection;
