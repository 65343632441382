import React from "react";
import { TopNavigation } from "@amzn/awsui-components-react";
import PropTypes from "prop-types";

import "./styles.css";

export const TopNavBar = ({ utilities }) => {
  return (
    <div id="h" className="navbar">
      <TopNavigation
        identity={{ title: "AWS Partner Funding Portal", href: "/" }}
        i18nStrings={{}}
        utilities={utilities}
      />
    </div>
  );
};

TopNavBar.propTypes = {
  utilities: PropTypes.array,
};
