import React, { useState } from "react";
import { Box, SpaceBetween } from "@amzn/awsui-components-react";
import Button from "@amzn/awsui-components-react/polaris/button";
import PropTypes from "prop-types";
import {
  createDelegations,
  updateDelegation,
} from "../../../../util/services/data/InternalDataService";
import { datePickerAdapter } from "../../../../../shared/util/adapter/toApi/adapter";
import {
  ERROR_TYPE,
  hasError,
} from "../../../../../shared/util/services/data/DataService";
import { setErrorMessage } from "../../../../../shared/util/common/helper";
import {
  DELEGATION_KEYS,
  DELEGATION_MODAL_TYPE,
} from "../../DelegationConstants";

const ModalFooter = ({
  payload,
  refresh,
  resetModalState,
  setError,
  setNotifications,
  showModal,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Box float="right">
      <SpaceBetween direction="horizontal" size="xs">
        <Button
          loading={isLoading}
          onClick={() => {
            showModal(false);
          }}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          loading={isLoading}
          onClick={async () => {
            setIsLoading(true);

            const {
              modalType,
              delegationId,
              delegator,
              delegatee,
              delegationPeriod,
              reason,
            } = payload;

            const body = {
              delegator,
              delegatee,
              startTimestamp: datePickerAdapter({
                value: new Date(delegationPeriod.startDate).getTime(),
              }),
              endTimestamp: datePickerAdapter({
                value: new Date(delegationPeriod.endDate).getTime(),
              }),
              reason,
            };

            console.log(body);
            try {
              let response;
              if (
                modalType &&
                modalType === DELEGATION_MODAL_TYPE.EDIT &&
                delegationId
              ) {
                response = await updateDelegation({ delegationId, body });
              } else {
                response = await createDelegations({
                  body,
                });
              }

              console.log(response);

              if (
                hasError(response) &&
                response.errorType === ERROR_TYPE.BANNER
              ) {
                setErrorMessage({
                  setNotificationsItems: setNotifications,
                  content: response.message,
                  status: response.status,
                });
                setIsLoading(false);
                return;
              } else if (response.errorType === ERROR_TYPE.FIELD) {
                let delegationPeriodErrors = [];

                if (
                  Object.keys(response["errors"]).includes(
                    DELEGATION_KEYS.START_KEY
                  )
                ) {
                  delegationPeriodErrors.push(
                    response["errors"][DELEGATION_KEYS.START_KEY]
                  );
                }

                if (
                  Object.keys(response["errors"]).includes(
                    DELEGATION_KEYS.END_KEY
                  )
                ) {
                  delegationPeriodErrors.push(
                    response["errors"][DELEGATION_KEYS.END_KEY]
                  );
                }

                response["errors"]["delegationPeriod"] =
                  delegationPeriodErrors.join(", ");
                setError({ ...response["errors"] });
                setIsLoading(false);
                return;
              }

              await refresh();
              resetModalState();
            } catch (err) {
              console.log(err);
              return;
            }
            setIsLoading(false);
            showModal(false);
          }}
        >
          Submit
        </Button>
      </SpaceBetween>
    </Box>
  );
};

ModalFooter.propTypes = {
  payload: PropTypes.object,
  refresh: PropTypes.func,
  resetModalState: PropTypes.func,
  setError: PropTypes.func,
  setNotifications: PropTypes.func,
  showModal: PropTypes.func,
};

export default ModalFooter;
