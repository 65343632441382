import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { getTemplateEligibility } from "../../../../util/services/data/FundRequestService";
import Select from "@amzn/awsui-components-react/polaris/select";
import { getFundingTypeOptions } from "../util";

const PIFSubProgramContainer = ({
  programValue,
  setFundingTypeOptions,
  setFundingTypeValue,
  setProgramValue,
  template,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [programs, setPrograms] = useState([]);

  useEffect(async () => {
    setProgramValue("");
    const response = await getTemplateEligibility("PIF");
    console.log(response);

    let retrievedPrograms = {};

    response.forEach(
      ({ accountPifProgramId, programName, programPaymentType }) => {
        retrievedPrograms = {
          ...retrievedPrograms,
          [accountPifProgramId]: {
            programName,
            programPaymentType,
          },
        };
      }
    );

    setPrograms(retrievedPrograms);
    console.log(response);
    setIsLoading(false);
  }, [template]);

  const selectedProgramLabel =
    programs && programValue ? programs[programValue].programName : "";

  return (
    <Select
      selectedOption={{ label: selectedProgramLabel, value: programValue }}
      onChange={({ detail }) => {
        const programId = detail.selectedOption.value;
        setFundingTypeValue();
        setProgramValue(programId);
        setFundingTypeOptions(
          getFundingTypeOptions(programs[programId].programPaymentType)
        );
      }}
      loadingText="Loading your eligible programs..."
      options={Object.keys(programs).map((programId) => {
        const label = programId ? programs[programId].programName : "";
        return { label, value: programId };
      })}
      statusType={isLoading ? "loading" : "finished"}
    />
  );
};

PIFSubProgramContainer.propTypes = {
  programOptions: PropTypes.string,
  programValue: PropTypes.string,
  setFundingTypeOptions: PropTypes.func,
  setFundingTypeValue: PropTypes.func,
  setProgramValue: PropTypes.func,
  template: PropTypes.string,
};
export default PIFSubProgramContainer;
