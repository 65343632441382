import { ReactNode } from "react";

export const display = ({
  data,
  key,
  defaultValue = "-",
  format,
}: {
  data: { [key: string]: string };
  key: string;
  defaultValue?: unknown;
  format?: (value: any) => string | ReactNode;
}): string | ReactNode => {
  let formattedValue: string | ReactNode = defaultValue as string;
  const value = data && data[key];

  if (!value) return formattedValue;

  formattedValue = value;

  if (format && typeof format === "function") {
    formattedValue = format(value);
  }

  return formattedValue;
};
