import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import {
  hasError,
  retryApiCall,
} from "../../../../shared/util/services/data/DataService";
import { setErrorMessage } from "../../../../shared/util/common/helper";
import { ConvertStringToPascalCase } from "../../../util/helper";
import Loading from "../../../../shared/components/common/Loading";
import { getSelfServiceEntityEvents } from "../../../util/services/data/InternalDataService";
import SelfServiceEntityTable from "./SelfServiceEntityTable";
import SelfServiceEntityHistoryTable from "./SelfServiceEntityHistoryTable";
import { GetPatchedEvents } from "../JsonPatch/jsonpatch";
import { ROOT_ENTITY_NAME } from "../constant";
import { getHistoryData } from "../JsonPatch/util";
import { isFeatureEnabled } from "../../../../shared/util/services/features/FeatureFlagsService";
import { FEATURE_FLAG_ENUMS } from "../../../../shared/util/constants/featureFlagValues";

const SelfServiceEntity = ({ setNotifications }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [entityDetails, setEntityDetails] = useState({});
  const [entityEvents, setEntityEvents] = useState([]);
  const [history, setHistory] = useState([]);
  const [isValidEntity, setIsValidEntity] = useState(false);
  const { entity } = useParams();

  const GetSelfServiceEntityEvents = async () => {
    let events = [];
    let historyData = [];
    let entityInfo = {};
    let nextPageToken = "";

    while (nextPageToken != null) {
      try {
        const response = await retryApiCall({
          callApi: getSelfServiceEntityEvents({
            entityType: ConvertStringToPascalCase(entity),
            nextPageToken: nextPageToken,
          }),
        });
        if (response && hasError(response)) {
          console.log(response);
          setErrorMessage({
            setNotificationsItems: setNotifications,
            content: JSON.stringify(response.errors),
            status: response.status,
          });
          setIsLoading(false);
          return;
        }

        entityInfo = {
          entityType: response.entityType,
          version: response.version,
        };

        for (let event of response.events) {
          if (!event.patch || !event.patch.patches) {
            console.error(
              `Invalid patch for entityType: ${event.entityType}, sequenceNumber: ${event.sequenceNumber}`
            );
            setErrorMessage({
              setNotificationsItems: setNotifications,
            });
            setIsLoading(false);
            return;
          }

          events.push(event);
          historyData = [
            ...historyData,
            ...getHistoryData(entityInfo.entityType, event),
          ];
        }

        nextPageToken = response.nextPageToken;
      } catch (e) {
        console.error(e);
        setErrorMessage({
          setNotificationsItems: setNotifications,
        });
        setIsLoading(false);
      }
    }

    const selfServiceAuthorizerFlag = await isFeatureEnabled(
      FEATURE_FLAG_ENUMS.SELF_SERVICE_AUTHORIZER
    );

    entityInfo = {
      ...entityInfo,
      selfServiceAuthorizerFlag,
    };

    setEntityDetails(entityInfo);
    setEntityEvents(events);
    setHistory(historyData);
  };

  const GetSelfServiceEntity = async () => {
    setIsLoading(true);
    setIsValidEntity(false);

    if (entity === ROOT_ENTITY_NAME) {
      setIsValidEntity(true);
      await GetSelfServiceEntityEvents();
      setIsLoading(false);
      return;
    }

    let doc = {};
    let entityExists = false;
    let nextPageToken = "";

    while (nextPageToken != null) {
      try {
        const response = await retryApiCall({
          callApi: getSelfServiceEntityEvents({
            entityType: ROOT_ENTITY_NAME,
            nextPageToken: nextPageToken,
          }),
        });
        if (response && hasError(response)) {
          setErrorMessage({
            setNotificationsItems: setNotifications,
            content: JSON.stringify(response.errors),
            status: response.status,
          });
          setIsLoading(false);
          return;
        }

        doc = GetPatchedEvents(response.entityType, response.events, doc);
        if (Object.keys(doc).length !== 0) {
          entityExists = doc[ROOT_ENTITY_NAME]?.some((e) => e.name === entity);
        }

        nextPageToken = response.nextPageToken;
      } catch (e) {
        console.error(e);
        setErrorMessage({
          setNotificationsItems: setNotifications,
        });
        setIsLoading(false);
      }
    }

    if (entityExists) {
      setIsValidEntity(true);
      await GetSelfServiceEntityEvents();
    } else {
      setErrorMessage({
        setNotificationsItems: setNotifications,
        content: `Entity '${entity}' does not exist in self service.`,
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      await GetSelfServiceEntity();
    };

    fetchData().catch(console.error);
  }, []);

  return isLoading ? (
    <Loading />
  ) : (
    <SpaceBetween size="l">
      <SelfServiceEntityTable
        isLoading={isLoading}
        refresh={GetSelfServiceEntity}
        isValidEntity={isValidEntity}
        entityEvents={entityEvents}
        entityDetails={entityDetails}
        setEntityDetails={setEntityDetails}
        setNotifications={setNotifications}
      />
      <SelfServiceEntityHistoryTable
        isLoading={isLoading}
        history={history}
        isValidEntity={isValidEntity}
        entityDetails={entityDetails}
      />
    </SpaceBetween>
  );
};

SelfServiceEntity.propTypes = {
  setNotifications: PropTypes.func,
};

export default SelfServiceEntity;
