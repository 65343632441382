import React from "react";
import DetailsSectionHeaderActions from "internal/programs/migration-acceleration-program/2024/fund-request/components/details/sections/header/DetailsSectionHeaderActions";
import { getFundRequestId } from "internal/util/helper";
import { IDetailsProps } from "shared/programs/migration-acceleration-program/2024/fund-request/types/SchemaTypes";
import ReadOnlyFundingDetails from "internal/programs/migration-acceleration-program/2024/fund-request/components/details/sections/funding-details/FundingDetails";

const FundingDetailsContainer = ({ ...props }: IDetailsProps) => {
  const visible = true;

  return (
    <ReadOnlyFundingDetails
      {...props}
      actions={
        <DetailsSectionHeaderActions
          visible={visible}
          editPath={`/map2024/${getFundRequestId()}/cash-request-details/edit`}
        />
      }
    />
  );
};
export default FundingDetailsContainer;
