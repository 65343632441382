import React, { useEffect, useState } from "react";
import {
  ColumnLayout,
  Container,
  Header,
  SpaceBetween,
} from "@amzn/awsui-components-react";
import {
  ERROR_TYPE,
  hasError,
  retryApiCall,
} from "../../../../shared/util/services/data/DataService";
import { getPartnerScorecard } from "../../../util/services/data/FundRequestService";
import { AvailableFunds } from "./AvailableFunds";
import { setErrorMessage } from "../../../../shared/util/common/helper";
import PropTypes from "prop-types";
const MdfWallet = ({ setNotificationsItems }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [mdf, setMdf] = useState({
    mdf: {
      cash: {
        budget: 0,
        utilization: 0,
      },
      credit: {
        budget: 0,
        utilization: 0,
      },
    },
    wwps: {
      cash: {
        budget: 0,
        utilization: 0,
      },
      credit: {
        budget: 0,
        utilization: 0,
      },
    },
    training: {
      cash: {
        budget: 0,
        utilization: 0,
      },
      credit: {
        budget: 0,
        utilization: 0,
      },
    },
  });
  useEffect(async () => {
    try {
      const response = await retryApiCall({ callApi: getPartnerScorecard() });

      //TODO: Add a failure condition
      if (hasError(response) && response.errorType === ERROR_TYPE.BANNER) {
        setErrorMessage({
          setNotificationsItems,
          content: response.message,
          status: response.status,
        });
        setIsLoading(false);
        return;
      }

      if (response && response.items.length > 0) {
        const responseData = response.items[0];

        setMdf({
          mdf: {
            cash: {
              budget:
                parseFloat(responseData["customMdfCashBudget"]) +
                  parseFloat(responseData["prescriptiveMdfCashBudget"]) || 0,
              utilization:
                parseFloat(responseData["customMdfCashUtilization"]) +
                  parseFloat(responseData["prescriptiveMdfCashUtilization"]) ||
                0,
            },
            credit: {
              budget:
                parseFloat(responseData["customMdfCreditBudget"]) +
                  parseFloat(responseData["prescriptiveMdfCreditBudget"]) || 0,
              utilization:
                parseFloat(responseData["customMdfCreditUtilization"]) +
                  parseFloat(
                    responseData["prescriptiveMdfCreditUtilization"]
                  ) || 0,
            },
          },
          wwps: {
            cash: {
              budget: responseData["wwpsMdfCashBudget"] || 0,
              utilization: responseData["wwpsMdfCashUtilization"] || 0,
            },
            credit: {
              budget: responseData["wwpsMdfCreditBudget"] || 0,
              utilization: responseData["wwpsMdfCreditUtilization"] || 0,
            },
          },
          training: {
            cash: {
              budget: responseData["trainingPartnerMdfCashBudget"] || 0,
              utilization:
                responseData["trainingPartnerMdfCashUtilization"] || 0,
            },
            credit: {
              budget: responseData["trainingPartnerMdfCreditBudget"] || 0,
              utilization:
                responseData["trainingPartnerMdfCreditUtilization"] || 0,
            },
          },
        });
      }
      setIsLoading(false);
    } catch (e) {
      console.error(e);
      setIsLoading(false);
    }
  }, []);

  return (
    <React.Fragment>
      <SpaceBetween size="l">
        <ColumnLayout columns={3}>
          <Container
            header={<Header>MDF Commercial Sector Wallet (USD)</Header>}
          >
            <ColumnLayout variant="text-grid" columns={2}>
              <AvailableFunds
                utilization={mdf.mdf.cash.utilization}
                budget={mdf.mdf.cash.budget}
                fundType="Cash"
                isLoading={isLoading}
              />
              <AvailableFunds
                utilization={mdf.mdf.credit.utilization}
                budget={mdf.mdf.credit.budget}
                fundType="Credit"
                isLoading={isLoading}
              />
            </ColumnLayout>
          </Container>
          <Container header={<Header>MDF Public Sector Wallet (USD)</Header>}>
            <ColumnLayout variant="text-grid" columns={2}>
              <AvailableFunds
                utilization={mdf.wwps.cash.utilization}
                budget={mdf.wwps.cash.budget}
                fundType="Cash"
                isLoading={isLoading}
              />
              <AvailableFunds
                utilization={mdf.wwps.credit.utilization}
                budget={mdf.wwps.credit.budget}
                fundType="Credit"
                isLoading={isLoading}
              />
            </ColumnLayout>
          </Container>
          <Container header={<Header>MDF Training Wallet (USD)</Header>}>
            <ColumnLayout variant="text-grid" columns={2}>
              <AvailableFunds
                utilization={mdf.training.cash.utilization}
                budget={mdf.training.cash.budget}
                fundType="Cash"
                isLoading={isLoading}
              />
            </ColumnLayout>
          </Container>
        </ColumnLayout>
      </SpaceBetween>
    </React.Fragment>
  );
};

MdfWallet.propTypes = {
  setNotificationsItems: PropTypes.func,
};

export default MdfWallet;
