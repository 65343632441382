import React from "react";
import DetailsSectionHeaderActions from "internal/programs/migration-acceleration-program/2024/fund-request/components/details/sections/header/DetailsSectionHeaderActions";
import { getFundRequestId } from "internal/util/helper";
import ReadOnlyMigrationEligibility from "shared/programs/migration-acceleration-program/2024/fund-request/components/read-only/MigrationEligibility";
import { IDetailsProps } from "shared/programs/migration-acceleration-program/2024/fund-request/types/SchemaTypes";

const MigrationDetailsContainer = ({ ...props }: IDetailsProps) => {
  const visible = true;

  return (
    <ReadOnlyMigrationEligibility
      {...props}
      actions={
        <DetailsSectionHeaderActions
          visible={visible}
          editPath={`/map2024/${getFundRequestId()}/migration-details/edit`}
        />
      }
    />
  );
};
export default MigrationDetailsContainer;
