import React, { useEffect, useState } from "react";

import { getBreadcrumbs } from "../../../shared/util/common/getBreadcrumbs";
import withAppLayout from "../../../shared/components/withAppLayout";
import DOMPurify from "dompurify";
import { TITLE } from "../../../shared/util/constants/title";
import DelegationsTable from "../../components/Delegation/DelegationsTable";
import SideNavigation from "../../components/common/SideNavigation";

const DelegationsPage = () => {
  const [notifications, setNotifications] = useState([]);
  useEffect(() => {
    document.title = DOMPurify.sanitize(`${TITLE} - Delegations`);
  });

  return withAppLayout({
    component: (
      <React.Fragment>
        <DelegationsTable setNotifications={setNotifications} />
      </React.Fragment>
    ),
    breadcrumbs: getBreadcrumbs({ text: "Delegations" }),
    navigationHide: false,
    navigation: <SideNavigation />,
    toolsHide: true,
    notificationsItems: notifications,
  })();
};

export default DelegationsPage;
