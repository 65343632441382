import ReadOnlySectionActions from "../../../../components/shared/sections/read-only/ReadOnlySectionActions";
import React from "react";
import ProjectDetailsDetailContainer from "external/programs/migration-acceleration-program/2024/fund-request/components/details/FundRequestDetailsContainer/sections/ProjectDetails";
import { IDetailsProps } from "shared/programs/migration-acceleration-program/2024/fund-request/types/SchemaTypes";

const ProjectDetailsContainer = ({ ...props }: IDetailsProps) => (
  <ProjectDetailsDetailContainer
    {...props}
    actions={
      <ReadOnlySectionActions
        isVisible={
          props.data &&
          props.data["stage"] === "partner" &&
          props.data["status"] === "active"
        }
        sectionNumber={1}
      />
    }
  />
);

export default ProjectDetailsContainer;
