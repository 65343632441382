import { SECTION, SECTION_NAME } from "../../../util/constants/section";
import { PROGRAM_TYPE } from "../../../util/constants/programType";
import { getValidationErrors } from "../../../../external/components/FundRequest/StepsContainer/error";
import { URL_UPLOAD_PATTERN } from "../../../util/constants/awsCalculatorPattern";

export const ValidationFactory = (title) => {
  switch (title) {
    case SECTION.CASH_REQUEST_INFORMATION:
      return cashRequestValidator;
    case SECTION.PUBLIC_SECTOR_DETAILS:
      return publicSectorDetailsValidator;
    case SECTION.OPPORTUNITY_INFORMATION:
      return opportunityInformationValidator;
    default:
      return defaultValidator;
  }
};

export const opportunityInformationValidator = ({
  data,
  program,
  sectionName,
}) => {
  let valid = false;
  let errors = {};
  const opportunityOptionalPrograms = [PROGRAM_TYPE.PIF];
  if (
    data["aceOpportunityId"] ||
    opportunityOptionalPrograms.includes(program)
  ) {
    valid = true;
  } else {
    errors = {
      ...errors,
      [sectionName]:
        "Select a qualified opportunity from the table below before continuing.",
    };
  }

  return {
    errors,
    valid,
  };
};

export const cashRequestValidator = ({ validators, data }) => {
  let errors = {};
  let valid = true;

  const cashRequestValidation = defaultValidator({
    data,
    sectionName: SECTION_NAME.CASH_REQUEST_INFORMATION,
    validators,
  });

  errors = {
    ...errors,
    ...cashRequestValidation["errors"],
  };

  valid = valid && cashRequestValidation["valid"];

  for (let cashClaimKey of Object.keys(data["cashClaims"])) {
    const cashClaim = data["cashClaims"][cashClaimKey];
    const validate = validators.getSchema(SECTION_NAME.CASH_CLAIM_PLANS);
    valid = validate(cashClaim["plan"]) && valid;

    const splitCashClaimKey = cashClaimKey.split("-");
    const cashClaimIndex = splitCashClaimKey[splitCashClaimKey.length - 1];
    const sectionName = `${SECTION_NAME.CASH_CLAIM_PLANS}${
      cashClaimIndex > 1 ? `-${cashClaimIndex}` : ""
    }`;

    errors = {
      ...errors,
      [sectionName]: getValidationErrors(validate.errors),
    };
  }

  return {
    errors,
    valid,
  };
};

export const publicSectorDetailsValidator = ({ data, sectionName }) => {
  const errors = {
    [sectionName]: {},
  };
  const wwpsKey = "wwpsConsent";
  const consent = data[wwpsKey];

  if (!consent) {
    errors[sectionName][wwpsKey] = "Consent must be provided.";
  }

  return {
    errors,
    valid: consent,
  };
};

export const validatePricingCalculator = ({ data, sectionName }) => {
  let errors = {};

  let valid = true;
  const pricingCalculatorData = data && data["awsCalculatorUrl"];
  if (pricingCalculatorData) {
    const nonWhiteSpaceCalcUrl = pricingCalculatorData.replaceAll(/\s/g, "");
    const trimmedCalcUrl = nonWhiteSpaceCalcUrl.trim();
    const splitList = trimmedCalcUrl.split(",");
    const filteredList = splitList.filter((item) => item !== "");

    let matchesPattern = true;

    filteredList.forEach((item) => {
      if (!URL_UPLOAD_PATTERN.test(item.trim())) {
        matchesPattern = false;
      }
    });

    if (!matchesPattern) {
      errors = {
        [sectionName]: {
          awsCalculatorUrl:
            "AWS pricing calculator URL(s) entered is invalid." +
            "Please verify the instructions and provide the AWS pricing calculator URL in the correct format.",
        },
      };
      valid = false;
    }
  }

  return {
    errors,
    valid,
  };
};
export const defaultValidator = ({
  data,
  sectionName,
  validators,
  program,
}) => {
  const validate = validators.getSchema(sectionName);
  let valid;
  let errors;

  valid = validate(data);
  errors = { [sectionName]: getValidationErrors(validate.errors) };

  if (
    program === "Misc" &&
    (data["annualRunRateForMisc"] === undefined ||
      data["annualRunRateForMisc"]["units"] === 0) &&
    sectionName === "projectInformation"
  ) {
    valid = false;
    errors[sectionName] = {
      ...errors[sectionName],
      annualRunRateForMisc: "Field is required and must be greater than 0 USD.",
    };
  }

  if (
    sectionName === "fundRequestInformation" &&
    data["businessDescription"] &&
    data["businessDescription"].length > 5000
  ) {
    valid = false;
    errors[sectionName] = {
      ...errors[sectionName],
      businessDescription: `Max character limit reached. Current description length is 
        ${data["businessDescription"].length}. 
        Reduce the number of characters to or under 5000 and try again.`,
    };
  }

  if (data && data["awsCalculatorUrl"] !== undefined) {
    const calcValidationResult = validatePricingCalculator({
      data,
      sectionName,
    });
    valid = calcValidationResult["valid"];
    errors = {
      [sectionName]: {
        ...errors[sectionName],
        ...calcValidationResult.errors[sectionName],
      },
    };
  }

  return {
    errors,
    valid,
  };
};
