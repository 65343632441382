import React from "react";
import UtilizationDonutChart from "./UtilizationDonutChart";
import {
  getWalletLabel,
  formatWalletValue,
  getUserFriendlyAmount,
} from "./util";

export const CARD_DEFINITIONS = {
  header: (item) => <div>{getWalletLabel(item)} </div>,
  sections: [
    {
      id: "chart",
      content: (item) => (
        <UtilizationDonutChart
          availableAmount={item.balance}
          totalAmount={item.allocation}
          utilizedAmount={item.utilized}
        />
      ),
      width: 100,
    },
    {
      id: "allocated",
      header: `Allocated Budget`,
      content: (item) =>
        getUserFriendlyAmount(formatWalletValue(item.allocation)),
      width: 100,
    },
    {
      id: "available",
      header: `Available Budget`,
      content: (item) => getUserFriendlyAmount(formatWalletValue(item.balance)),
      width: 100,
    },
    {
      id: "utilized",
      header: `Utilized Budget`,
      content: (item) =>
        getUserFriendlyAmount(formatWalletValue(item.utilized)),
      width: 100,
    },
  ],
};
