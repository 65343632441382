import React from "react";
import {
  getYearFromTimeStampString,
  numberComparator,
} from "shared/util/common/helper";
import { Icon } from "@amzn/awsui-components-react";
import { TableProps } from "@amzn/awsui-components-react/polaris/table/interfaces";
import { IGenericObject } from "shared/programs/migration-acceleration-program/2024/fund-request/types/CommonTypes";
import { generateOpportunityIdLink } from "shared/util/common/generateOpportunityIdLink";
import Config from "config";
import { getGreenFieldIndicator } from "./util";
import { isWwps } from "shared/programs/migration-acceleration-program/2024/fund-request/utils/helper";

export const columnDefinitions: Array<
  TableProps.ColumnDefinition<IGenericObject>
> = [
  {
    id: "aceOpportunityId",
    header: "Opportunity ID",
    cell: (e) => e.opportunityId,
  },
  {
    id: "userFriendlyId",
    header: (
      <span>
        Opportunity ID <Icon name="external" size="inherit" variant="normal" />
      </span>
    ),
    cell: (e) => {
      const aceOpportunityId = e.opportunityId;
      const friendlyOpportunityId = e.userFriendlyId;
      const baseUrl = Config.opportunityBaseUrl;
      return (
        generateOpportunityIdLink({
          aceOpportunityId,
          friendlyOpportunityId,
          baseUrl,
        }) || "-"
      );
    },
    sortingField: "userFriendlyId",
  },
  {
    id: "customerCompanyName",
    header: "Customer company name",
    cell: (e) => e.customerCompanyName || "-",
    sortingField: "customerCompanyName",
  },
  {
    id: "aceValidationStage",
    header: "ACE validation stage",
    cell: (e) => e.aceValidationStage || "-",
    sortingField: "aceValidationStage",
  },
  {
    id: "targetCloseDate",
    header: "Target close date",
    cell: (e) => e.targetCloseDate || "-",
    sortingField: "targetCloseDate",
  },
  {
    id: "opportunityOwnerName",
    header: "Opportunity owner name",
    cell: (e) => e.opportunityOwnerName || "-",
    sortingField: "opportunityOwnerName",
  },
  {
    id: "opportunityOwnership",
    header: "Opportunity ownership",
    cell: (e) => e.opportunityOwnership || "-",
    sortingField: "opportunityOwnership",
  },
  {
    id: "Created Year",
    header: "Created year",
    cell: (e) => getYearFromTimeStampString(e.createdDate) || "-",
    sortingField: "createdDate",
    sortingComparator: (e1, e2) =>
      numberComparator(e1.createdDate, e2.createdDate),
  },
  {
    id: "projectDescription",
    header: "Project description",
    cell: (e) => e.projectDescription.slice(0, 200) || "-",
    sortingField: "projectDescription",
  },
  {
    id: "status",
    header: "Status",
    cell: (e) => e.status || "-",
    sortingField: "status",
  },
  {
    id: "partnerSuccessManager",
    header: "Partner success manager",
    cell: (e) => e.partnerSuccessManager || "-",
    sortingField: "partnerSuccessManager",
  },
  {
    id: "partnerDevelopmentManager",
    header: "Partner development manager",
    cell: (e) => e.partnerDevelopmentManager || "-",
    sortingField: "partnerDeveloperManager",
  },
  {
    id: "wwpsPartnerDeveloperManager",
    header: "WWPS Partner developer manager",
    cell: (e) => e.wwpsPartnerDevelopmentManager || "-",
    sortingField: "wwpsPartnerDeveloperManager",
  },
  {
    id: "customerBizUnit",
    header: "Customer business unit",
    cell: (e) => e.customerBizUnit || "-",
    sortingField: "customerBizUnit",
  },
  {
    id: "isWwps",
    header: "WWPS",
    cell: (e) => (isWwps(e) ? "Yes" : "No"),
    sortingField: "isWwps",
  },
];

export const GreenfieldColumnDefinitions: Array<
  TableProps.ColumnDefinition<IGenericObject>
> = [
  {
    id: "gtmGreenfieldFlag",
    header: "Greenfield SPI Eligible",
    cell: (e) => getGreenFieldIndicator(e),
    sortingField: "gtmGreenfieldFlag",
  },
  // TODO: Add later when incentive is tagged with vmware eligible opportunities in future
  // {
  //   id: "apnPrograms",
  //   header: "VMware SPI Eligible",
  //   cell: (e) => getVMWareIndicator(e),
  //   sortingField: "apnPrograms",
  // },
];

export const pageSizePreference = {
  title: "Page size",
  options: [
    { value: 10, label: "10 opportunities" },
    { value: 20, label: "20 opportunities" },
  ],
};

export const DEFAULT_OPPORTUNITY_TABLE_PAGE_SIZE = 10;

export const filterItem = ({
  item,
  filteringText,
}: {
  item: IGenericObject;
  filteringText: string;
}) => {
  return defaultColumnsToDisplay
    .map((key) => item[key])
    .some(
      (value) =>
        typeof value === "string" &&
        value.toLowerCase().indexOf(filteringText.toLowerCase()) > -1
    );
};

export const defaultColumnsToDisplay = [
  "userFriendlyId",
  "customerCompanyName",
  "aceValidationStage",
  "targetCloseDate",
  "opportunityOwnerName",
];

export const getVisibleContentPreference = (
  columnDefinition: Array<TableProps.ColumnDefinition<IGenericObject>>
) => {
  const visibleContentOptions: Array<IGenericObject> = columnDefinition.map(
    ({ id, header }: TableProps.ColumnDefinition<IGenericObject>) => ({
      id: id || "",
      label: header,
      editable: id !== "id",
    })
  );
  return {
    title: "Select visible columns",
    options: [
      {
        label: "",
        options: visibleContentOptions,
      },
    ],
  };
};
