import {
  getFundRequest,
  submitFundRequest,
  updateCashRequestInformation,
  updateFundRequestInformation,
  updateOpportunityInformation,
  updateProjectInformation,
  updatePublicSectorDetails,
} from "external/util/services/data/FundRequestService";
import { hasError, retryApiCall } from "shared/util/services/data/DataService";
import { IMAPFundRequest } from "shared/programs/migration-acceleration-program/2024/fund-request/types/SchemaTypes";
import {
  FieldError,
  FormError,
  handleApiError,
  Result,
  ResultType,
  Success,
} from "shared/util/api/util";
import { getFundRequestId } from "external/util/common/helper";
import { IGenericObject } from "shared/programs/migration-acceleration-program/2024/fund-request/types/CommonTypes";
import { removeCurrencyFormatting } from "shared/programs/migration-acceleration-program/2024/fund-request/util/currency";
import { formatNumberInput } from "../util";
import { isFeatureEnabled } from "shared/util/services/features/FeatureFlagsService";
import { FEATURE_FLAG_ENUMS } from "shared/util/constants/featureFlagValues";

export const updateOpportunityDetails = async (
  data: IMAPFundRequest
): Promise<Result<ResultType>> => {
  const payload = {
    aceOpportunityId: data["aceOpportunityId"],
  };

  // @ts-expect-error Still in javascript, so the implementation details don't matter
  const response = await retryApiCall({
    callApi: updateOpportunityInformation(payload),
  });

  if (hasError(response)) {
    return handleApiError(response.errorType)(response);
  }

  return new Result<Success>({});
};

export const updateProjectDetails = async (
  data: IMAPFundRequest
): Promise<Result<ResultType>> => {
  const payload: { [key: string]: string | object | null } = {
    awsAccountId: data["awsAccountId"],
    plannedStartDate: {
      timestamp: new Date(data["plannedStartDate"]).getTime(),
    },
    plannedEndDate: { timestamp: new Date(data["plannedEndDate"]).getTime() },
    executedCountry: data["executedCountry"],
    activityName: data["activityName"],
    description: data["description"],
    customerConsidering: "No Competition",
    numberOfVmwareToMigrate: formatNumberInput(data["numberOfVmwareToMigrate"]),
    totalNumberOfWorkloadsInUse: formatNumberInput(
      data["totalNumberOfWorkloadsInUse"]
    ),
  };

  if (data["executedCountry"] === "US") {
    if (
      !data["executedState"] ||
      !Array.isArray((data["executedState"] as string).split(","))
    )
      return new Result<FieldError>({
        success: false,
        payload: new FieldError({
          errors: { executedState: "States must be selected." },
        }),
      });
    payload["executedState"] = data["executedState"].split(",");
  }
  //TODO: https://code.amazon.com/packages/AWSPartnerFundingServiceModel/blobs/03db3ff3ec01cd57d85a1e5acb158a5c7e9424d7/--/model/external/types/fundrequest.smithy#L130-L162

  // @ts-expect-error Still in javascript, so the implementation details don't matter
  const response = await retryApiCall({
    callApi: updateProjectInformation(payload),
  });

  if (hasError(response)) {
    return handleApiError(response.errorType)(response);
  }

  const opportunity = data && data["opportunity"];

  if (
    opportunity &&
    (opportunity["customerBizUnit"] === "PS" ||
      opportunity["customerType"] === "PS") &&
    Object.keys(data).includes("wwpsConsent")
  ) {
    const consentPayload = {
      wwpsConsent: data["wwpsConsent"],
      customerRfx: !!data["customerRfx"],
    };

    const consentResponse = await retryApiCall({
      callApi: updatePublicSectorDetails(consentPayload),
      error: {},
    });
    if (hasError(consentResponse)) {
      return handleApiError(consentResponse.errorType)(consentResponse);
    }
  }

  return new Result<Success>({});
};

export const updateFundingDetails = async (
  data: IMAPFundRequest
): Promise<Result<ResultType>> => {
  const currency = data["currency"];
  const payload: { [key: string]: string | object } = {
    totalCostOfActivity: {
      units: removeCurrencyFormatting(data["totalCostOfActivity"]),
      nanos: 0,
      currency,
    },
    cashFundingAmount: {
      units: removeCurrencyFormatting(data["cashFundingAmount"]),
      nanos: 0,
      currency,
    },
    invoiceEntityName: data["invoiceEntityName"],
    invoiceRemitStreet: data["invoiceRemitStreet"],
    invoiceRemitUnit: data["invoiceRemitUnit"],
    invoiceRemitCity: data["invoiceRemitCity"],
    invoiceRemitState: data["invoiceRemitState"],
    invoiceRemitPostalCode: data["invoiceRemitPostalCode"],
    currency: currency,
    invoiceRemitCountry: data["invoiceRemitCountry"],
  };
  // TODO: https://code.amazon.com/packages/AWSPartnerFundingServiceModel/blobs/mainline/--/model/external/operations.smithy

  // @ts-expect-error Still in javascript, so the implementation details don't matter
  const response = await retryApiCall({
    callApi: updateCashRequestInformation(payload),
  });

  if (hasError(response)) {
    return handleApiError(response.errorType)(response);
  }

  return new Result<Success>({});
};

export const updateArtifacts = async (
  data: IMAPFundRequest
): Promise<Result<ResultType>> => {
  const fundRequestResponse: IGenericObject = await getFundRequest(
    getFundRequestId()
  );
  if (hasError(fundRequestResponse))
    return new Result<FormError>({
      success: false,
      payload: new FormError("There was error. Try again later."),
    });

  const { requiredFieldErrors, requiredAttachmentErrors } =
    await validateArtifacts(fundRequestResponse, data);

  let formError = "";
  if (requiredFieldErrors.length > 0 || requiredAttachmentErrors.length > 0) {
    if (requiredFieldErrors.length > 0) {
      formError = formError.concat(
        `Missing Required field: ${requiredFieldErrors.join(", ")}. `
      );
    }
    if (requiredAttachmentErrors.length > 0) {
      formError = formError.concat(
        `Missing Required attachment(s): ${requiredAttachmentErrors.join(
          ", "
        )}.`
      );
    }
    return new Result<FormError>({
      success: false,
      payload: new FormError(formError),
    });
  }

  if (!data || !data["awsCalculatorUrl"]) return new Result<Success>({});

  const payload = { awsCalculatorUrl: data["awsCalculatorUrl"] };
  // @ts-expect-error Still in javascript, so the implementation details don't matter
  const response = await retryApiCall({
    callApi: updateFundRequestInformation(payload),
  });
  if (hasError(response)) {
    return handleApiError(response.errorType)(response);
  }

  return new Result<Success>({});
};

export const submit = async (): Promise<Result<ResultType>> => {
  // @ts-expect-error Still in javascript, so the implementation details don't matter
  const response = await retryApiCall({
    callApi: submitFundRequest(),
  });

  if (hasError(response)) {
    return handleApiError(response.errorType)(response);
  }

  return new Result<Success>({});
};

export const validateArtifacts = async (
  fund_request: IGenericObject,
  data: IGenericObject
) => {
  const vmwareEnabledFlag = await isFeatureEnabled(
    FEATURE_FLAG_ENUMS.VMWARE_MAP_ENABLED
  );
  const vmwareAcknowledgement =
    fund_request?.fundRequest?.incentives?.vmware?.eligible || false;
  const artifacts = fund_request?.fundRequest?.artifacts;
  const documents = artifacts?.documents;
  const calculatorUrls = artifacts?.pricingCalculatorLinks;
  const requiredFieldErrors: string[] = [];
  const requiredAttachmentErrors: string[] = [];

  const hasScopeChecklist = Object.keys(documents).some(
    (key) => documents[key]?.fileType === "Migration Assessment Scope Checklist"
  );
  const hasVmwareAssessmentReport = Object.keys(documents).some(
    (key) => documents[key]?.fileType === "Migration Assessment Report"
  );
  const hasPricingCalculator =
    Object.keys(documents).some(
      (key) => documents[key]?.fileType === "AWS Pricing Calculator"
    ) ||
    calculatorUrls.length > 0 ||
    data["awsCalculatorUrl"];

  if (!hasPricingCalculator) {
    requiredFieldErrors.push("Pricing Calculator estimate(s)");
  }
  if (!hasScopeChecklist) {
    requiredAttachmentErrors.push("Partner scope checklist");
  }
  if (
    vmwareEnabledFlag &&
    vmwareAcknowledgement &&
    !hasVmwareAssessmentReport
  ) {
    requiredAttachmentErrors.push("Migration Assessment Report");
  }
  return { requiredFieldErrors, requiredAttachmentErrors };
};
