export const SECTION = {
  FUND_REQUEST_INFORMATION: "Fund Request Information",
  FUND_REQUEST_INFORMATION_V2: "Fund Request Details V2",
  OPPORTUNITY_INFORMATION: "Opportunity Information",
  OPPORTUNITY_INFORMATION_V2: "Opportunity Details V2",
  PROJECT_INFORMATION: "Project Information",
  PROJECT_INFORMATION_V2: "Project Details V2",
  MIGRATION_DETAILS: "Migration Details",
  MIGRATION_WORKLOADS: "Migration Workload",
  PUBLIC_SECTOR_DETAILS: "Public Sector Details",
  CASH_REQUEST_INFORMATION: "Cash Request Information",
  CASH_CLAIM_PLANS: "Cash Claim Plans",
  CASH_CLAIM_ACTUALS: "Cash Claim Actuals",
  CASH_CLAIM_INVOICE_PAYMENT: "Cash Claim Invoice & Payment",
  CASH_REQUEST_INFORMATION_V2: "Cash Request Details V2",
  CASH_CLAIM_PLANS_V2: "Cash Claim Plans V2",
  CASH_CLAIM_ACTUALS_V2: "Cash Claim Actuals V2",
  CASH_CLAIM_INVOICE_PAYMENT_V2: "Cash Claim Invoice & Payment V2",
  CREDIT_REQUEST_DETAILS: "Credit Request Details",
  ATTACHMENTS: "Attachments",
  ATTACHMENTS_V2: "Attachments V2",
  APN_PARTNER_DETAILS: "APN Partner Details",
  APPROVAL_DETAILS: "Approval Details",
  MIGRATION_PROGRAM_ENGAGEMENTS: "Migration Program Engagements",
  MIGRATION_ENGAGEMENT_OVERVIEW: "Migration Engagement Overview",
  MIGRATION_ENGAGEMENT_RA: "Migration Engagement Readiness Assessment",
  MIGRATION_ENGAGEMENT_OLA:
    "Migration Engagement Optimization And Licensing Agreement",
};

export const SECTION_NAME = {
  FUND_REQUEST_INFORMATION: "fundRequestInformation",
  FUND_REQUEST_INFORMATION_V2: "fundRequestDetails",
  OPPORTUNITY_INFORMATION: "opportunityInformation",
  OPPORTUNITY_INFORMATION_V2: "opportunityDetails",
  PROJECT_INFORMATION: "projectInformation",
  PROJECT_INFORMATION_V2: "projectDetails",
  MIGRATION_DETAILS: "migrationDetails",
  MIGRATION_WORKLOADS: "migrationWorkloads",
  PUBLIC_SECTOR_DETAILS: "publicSectorDetails",
  CASH_REQUEST_INFORMATION: "cashRequestInformation",
  CASH_CLAIM_PLANS: "cashClaimPlans",
  CASH_CLAIM_ACTUALS: "cashClaimActuals",
  CASH_CLAIM_INVOICE_PAYMENT: "cashClaimInvoicePayment",
  CASH_REQUEST_INFORMATION_V2: "cashRequestDetails",
  CASH_CLAIM_PLANS_V2: "cashClaimPlans",
  CASH_CLAIM_ACTUALS_V2: "cashClaimActuals",
  CASH_CLAIM_INVOICE_PAYMENT_V2: "cashClaimInvoicePayment",
  CREDIT_REQUEST_DETAILS: "creditRequestDetails",
  ATTACHMENTS: "attachments",
  ATTACHMENTS_V2: "attachments",
  APN_PARTNER_DETAILS: "apnPartnerDetails",
  APPROVAL_DETAILS: "approvalDetails",
  MIGRATION_PROGRAM_ENGAGEMENTS: "migrationProgramEngagements",
  MIGRATION_ENGAGEMENT_OVERVIEW: "migrationEngagementOverview",
  MIGRATION_ENGAGEMENT_RA: "migrationEngagementReadinessAssessment",
  MIGRATION_ENGAGEMENT_OLA:
    "migrationEngagementOptimizationAndLicensingAgreement",
};

export const SECTION_CONSTANT_TYPE = {
  NAME: "name",
  TITLE: "title",
};

//TODO: Add unit test
/**
 * Converts between section title and section name.
 *
 * If type provided is TITLE, then value must be SECTION_NAME.
 * If type provided is NAME, then value must be SECTION.
 *
 * If value is not one of the above, then an empty string is returned.
 *
 * @param type @SECTION_CONSTANT_TYPE
 * @param value @SECTION or @SECTION_NAME
 * @returns {string}
 */
export const getSectionConstant = ({ type, value }) => {
  let constant = "";
  if (type === SECTION_CONSTANT_TYPE.NAME) {
    const [entry] = Object.entries(SECTION).filter((entry) => {
      return entry[1] === value;
    });
    constant = SECTION_NAME[entry[0]];
  }

  if (type === SECTION_CONSTANT_TYPE.TITLE) {
    const [entry] = Object.entries(SECTION_NAME).filter((entry) => {
      return entry[1] === value;
    });
    constant = SECTION[entry[0]];
  }

  return constant;
};
