import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Table from "@amzn/awsui-components-react/polaris/table";
import Grid from "@amzn/awsui-components-react/polaris/grid";
import Input from "@amzn/awsui-components-react/polaris/input";
import Header from "@amzn/awsui-components-react/polaris/header";
import Pagination from "@amzn/awsui-components-react/polaris/pagination";
import { useCollection } from "@amzn/awsui-collection-hooks";
import TableEmptyState from "../../../../common/TableEmptyState";
import TableNoMatchState from "../../../../common/TableNoMatchState";
import {
  fundingHistoryColumnDefinitions,
  searchableColumns,
} from "./tableConfig";
import { filterItem } from "../util";
import { getAttachmentHistory } from "../../../../../util/services/data/InternalDataService";
import { Box } from "@amzn/awsui-components-react";
import { isJson } from "../../../../../../shared/util/services/auth/AuthService";
import { timestampComparator } from "../../../../../../shared/util/common/helper";

export const AttachmentHistoryTable = ({
  setHasQuarantinedFile,
  getFundingHistory,
  showHelperPanel,
}) => {
  const pageSize = 10;
  const [isLoading, setIsLoading] = useState(true);
  const [history, setHistory] = useState([]);

  useEffect(async () => {
    let data = await getFundingHistory({
      getFundRequestHistory: getAttachmentHistory,
      setIsLoading,
    });
    parseFileItem(data);
    setHistory(data);

    const reasons = getQuarantineReasons(data);
    const hasQuarantinedFile = reasons.length > 0;
    showHelperPanel({
      title: "Suggestions",
      keys: reasons,
      open: hasQuarantinedFile,
    });
    setHasQuarantinedFile(hasQuarantinedFile);
    setIsLoading(false);
  }, []);

  const getQuarantineReasons = (data) => {
    let reasons = new Set();
    for (let item of data) {
      if (item.fileItem && item.fileItem.quarantineReason) {
        reasons.add(item.fileItem.quarantineReason);
      }
    }
    return [...reasons];
  };

  const parseFileItem = (data) => {
    for (let item of data) {
      if (item.fileItem && isJson(item.fileItem)) {
        item.fileItem = JSON.parse(item.fileItem);
      }
    }
  };

  const { items, actions, collectionProps, filterProps, paginationProps } =
    useCollection(history || [], {
      pagination: { pageSize },
      empty: <TableEmptyState resourceName="history" />,
      filtering: {
        noMatch: (
          <TableNoMatchState onClearFilter={() => actions.setFiltering("")} />
        ),
        filteringFunction: (item, filteringText) => {
          return filterItem({ searchableColumns, item, filteringText });
        },
      },
      sorting: {
        defaultState: {
          sortingColumn: {
            sortingField: "date",
            sortingComparator: (e1, e2) =>
              timestampComparator(e1.date, e2.date),
          },
          isDescending: true,
        },
      },
      selection: {},
    });

  return (
    <React.Fragment>
      <Table
        {...collectionProps}
        loading={isLoading}
        loadingText="Loading attachment history..."
        items={items}
        columnDefinitions={fundingHistoryColumnDefinitions}
        sortingColumn={{ sortingField: "date" }}
        wrapLines={true}
        empty={
          <Box textAlign="center" color="inherit">
            <Box padding={{ bottom: "s" }} variant="p" color="inherit">
              No attachment history available.
            </Box>
          </Box>
        }
        filter={
          <Grid
            gridDefinition={[
              { colspan: 4 },
              { colspan: 1 },
              { colspan: 1 },
              { colspan: 1 },
              { colspan: 1 },
            ]}
          >
            <Input
              type="search"
              value={filterProps.filteringText}
              onChange={(event) => {
                actions.setFiltering(event.detail.value);
              }}
              placeholder="Enter search criteria..."
              label="Search history"
              ariaDescribedby={null}
            />
          </Grid>
        }
        header={
          <Header counter={items.length + "/" + history.length}>
            Attachment History
          </Header>
        }
        pagination={<Pagination {...paginationProps} />}
      />
    </React.Fragment>
  );
};

AttachmentHistoryTable.propTypes = {
  setHasQuarantinedFile: PropTypes.func,
  getFundingHistory: PropTypes.func,
  showHelperPanel: PropTypes.func,
};
