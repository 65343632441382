import React from "react";
import PieChart from "@amzn/awsui-components-react/polaris/pie-chart";
import PropTypes from "prop-types";
import {
  formatWalletValue,
  getUtilizationPercentage,
  getUserFriendlyAmount,
} from "../util";

const UtilizationDonutChart = ({
  availableAmount,
  totalAmount,
  utilizedAmount,
}) => {
  let { innerMetricValue, innerMetricDescription } = getUtilizationPercentage(
    totalAmount,
    utilizedAmount
  );
  return (
    <PieChart
      size="medium"
      variant="donut"
      hideDescriptions
      hideFilter
      hideLegend
      hideTitles
      innerMetricValue={innerMetricValue}
      innerMetricDescription={innerMetricDescription}
      data={[
        {
          title: "Utilized Budget",
          value: formatWalletValue(utilizedAmount),
        },
        {
          title: "Available Budget",
          value: formatWalletValue(availableAmount),
        },
      ]}
      detailPopoverContent={(datum) => [
        {
          key: "Amount",
          value: getUserFriendlyAmount(formatWalletValue(datum.value)),
        },
      ]}
    />
  );
};
UtilizationDonutChart.propTypes = {
  totalAmount: PropTypes.number,
  availableAmount: PropTypes.number,
  utilizedAmount: PropTypes.number,
};
export default UtilizationDonutChart;
