import { generatePhonetoolLink } from "../../../util/constants/generatePhonetoolLink";
import { localDatetime } from "../../../util/constants/isoFormatDate";
import { statusBadge } from "./badgeStatus";

export const delegatesColumnDefinition = [
  {
    id: "delegationId",
    header: "Delegation Id",
    cell: (e) => e.delegationId,
    sortingField: "delegationId",
  },
  {
    id: "delegator",
    header: "Delegator",
    cell: (e) => generatePhonetoolLink(e.delegator),
    sortingField: "delegator",
  },
  {
    id: "delegatee",
    header: "Delegatee",
    cell: (e) => generatePhonetoolLink(e.delegatee),
    sortingField: "delegatee",
  },
  {
    id: "startTimestamp",
    header: "Start",
    cell: (e) => localDatetime(e.startTimestamp),
    sortingField: "startTimestamp",
  },
  {
    id: "endTimestamp",
    header: "End",
    cell: (e) => localDatetime(e.endTimestamp),
    sortingField: "endTimestamp",
  },
  {
    id: "reason",
    header: "Reason",
    cell: (e) => e.reason,
    sortingField: "reason",
  },
  {
    id: "status",
    header: "Status",
    cell: (e) => statusBadge(e.status),
    sortingField: "status",
  },
];

export const visibleColumns = [
  "delegator",
  "delegatee",
  "startTimestamp",
  "endTimestamp",
  "reason",
  "status",
];

export const searchableColumns = ["delegator", "delegatee", "reason", "status"];
