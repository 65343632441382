import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Table from "@amzn/awsui-components-react/polaris/table";
import Pagination from "@amzn/awsui-components-react/polaris/pagination";
import Grid from "@amzn/awsui-components-react/polaris/grid";
import Input from "@amzn/awsui-components-react/polaris/input";
import {
  hasError,
  retryApiCall,
} from "../../../../shared/util/services/data/DataService";
import { getSelfServiceEntityEvents } from "../../../util/services/data/InternalDataService";
import { setErrorMessage } from "../../../../shared/util/common/helper";
import SelfServiceTableHeader from "./SelfServiceTableHeader";
import { useCollection } from "@amzn/awsui-collection-hooks";
import TableEmptyState from "../../common/TableEmptyState";
import { GetPatchedEvents } from "../JsonPatch/jsonpatch";
import TableNoMatchState from "../../common/TableNoMatchState";
import {
  sortingField,
  searchableColumns,
  selfServiceColumnDefinition,
  visibleColumns,
} from "./tableConfig";
import {
  MAIN_TABLE_SIZE,
  MAIN_RESOURCE_NAME,
  ROOT_ENTITY_NAME,
} from "../constant";

const SelfServiceTable = ({ setNotifications }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [entityList, setEntityList] = useState([]);

  const GetSelfServiceRootEntityEvents = async () => {
    setIsLoading(true);
    setSelectedItems([]);
    const data = [];
    let nextPageToken = "";
    let doc = {};
    while (nextPageToken != null) {
      try {
        const response = await retryApiCall({
          callApi: getSelfServiceEntityEvents({
            entityType: ROOT_ENTITY_NAME,
            nextPageToken: nextPageToken,
          }),
        });
        if (response && hasError(response)) {
          console.log(response);
          setErrorMessage({
            setNotificationsItems: setNotifications,
            content: JSON.stringify(response.errors),
            status: response.status,
          });
          setIsLoading(false);
          return;
        }

        doc = GetPatchedEvents(response.entityType, response.events, doc);

        if (Object.keys(doc).length !== 0) {
          doc[ROOT_ENTITY_NAME]?.forEach((item, index) => {
            data.push({
              index: index,
              name: item.name,
              value: item.description,
            });
          });
        }

        nextPageToken = response.nextPageToken;
      } catch (e) {
        console.error(e);
        setErrorMessage({
          setNotificationsItems: setNotifications,
        });
        setIsLoading(false);
      }
    }

    setEntityList(data);
    setIsLoading(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      await GetSelfServiceRootEntityEvents();
    };

    fetchData().catch(console.error);
  }, []);

  const { items, actions, collectionProps, filterProps, paginationProps } =
    useCollection(entityList || [], {
      pagination: { pageSize: MAIN_TABLE_SIZE },
      empty: <TableEmptyState resourceName={MAIN_RESOURCE_NAME} />,
      filtering: {
        noMatch: (
          <TableNoMatchState onClearFilter={() => actions.setFiltering("")} />
        ),
        filteringFunction: (item, filteringText) => {
          return searchableColumns
            .map((key) => item[key])
            .some(
              (value) =>
                typeof value === "string" &&
                value.toLowerCase().indexOf(filteringText.toLowerCase()) > -1
            );
        },
      },
      sorting: {
        defaultState: {
          sortingColumn: {
            sortingField: sortingField,
          },
          isDescending: true,
        },
      },
      selection: {},
    });

  return (
    <Table
      {...collectionProps}
      loading={isLoading}
      loadingText="Loading Self Service"
      items={items}
      selectionType="single"
      columnDefinitions={selfServiceColumnDefinition}
      visibleColumns={visibleColumns}
      resizableColumns
      header={
        <SelfServiceTableHeader
          counter={
            entityList.length ? `(${items.length}/${entityList.length})` : ""
          }
          selectedItems={selectedItems}
          refresh={GetSelfServiceRootEntityEvents}
        />
      }
      selectedItems={selectedItems}
      onSelectionChange={({ detail }) => setSelectedItems(detail.selectedItems)}
      pagination={<Pagination {...paginationProps} />}
      filter={
        <Grid gridDefinition={[{ colspan: 4 }]}>
          <Input
            type="search"
            value={filterProps.filteringText}
            onChange={(event) => {
              actions.setFiltering(event.detail.value);
            }}
            placeholder="Search..."
            label="Search..."
          />
        </Grid>
      }
    />
  );
};

SelfServiceTable.propTypes = {
  setNotifications: PropTypes.func,
};
export default SelfServiceTable;
