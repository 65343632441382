import React from "react";
import PropTypes from "prop-types";
import Box from "@amzn/awsui-components-react/polaris/box";

const TableEmptyState = ({ resourceName }) => {
  return (
    <Box textAlign="center" color="inherit">
      <b>No {resourceName}</b>
      <Box padding={{ bottom: "s" }} variant="p" color="inherit">
        No {resourceName} to display.
      </Box>
    </Box>
  );
};

TableEmptyState.propTypes = {
  resourceName: PropTypes.string,
};

export default TableEmptyState;
