import React from "react";
import { PieChart } from "@amzn/awsui-components-react";
import PropTypes from "prop-types";

const calculateUtilizationPercentage = (budget, utilization) => {
  if (budget === 0) return budget.toFixed(2);
  return (100 - ((budget - utilization) / budget) * 100).toFixed(2);
};

export const UtilizationChart = ({ budget, isLoading, utilization }) => {
  return (
    <PieChart
      data={[
        { title: "Budget Available", value: budget - utilization },
        { title: "Utilization", value: utilization },
      ]}
      detailPopoverContent={(datum, sum) => [
        {
          key: "Amount",
          value: datum.value.toLocaleString("en-US"),
        },
        {
          key: "Percentage",
          value: `${((datum.value / sum) * 100).toFixed(2)}%`,
        },
      ]}
      size="medium"
      variant="donut"
      statusType={isLoading ? "loading" : "finished"}
      loadingText="Loading utilization information..."
      innerMetricValue={`${calculateUtilizationPercentage(
        budget,
        utilization
      )}%`}
      innerMetricDescription="% Utilization"
      hideDescriptions
      hideFilter
      hideTitles
    />
  );
};

UtilizationChart.propTypes = {
  budget: PropTypes.number,
  isLoading: PropTypes.bool,
  utilization: PropTypes.number,
};
