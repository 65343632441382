import React from "react";
import Header from "@amzn/awsui-components-react/polaris/header";
import PropTypes from "prop-types";

import { filterFields, getTemplate, schema } from "../../../../config/schema";
import useFields from "../../../../../shared/util/hooks/useFields";
import Loading from "../../../../../shared/components/common/Loading";
import { useOptionsContext } from "../../../../util/context/OptionsContext";
import Section from "../../../../../shared/components/FundRequest/StepsContainer/Section";
import { DisableFactory } from "../Inputs/PropBuilder/DisableFactory";
import { ValueFactory } from "../Inputs/PropBuilder/ValueFactory";
import { InputPropsBuilder } from "../../../../../shared/components/FundRequest/InputPropsBuilder";
import { InputFactory } from "../../../../../shared/components/FundRequest/InputFactory";
import { getFundRequest } from "../../../../util/services/data/FundRequestService";
import { FileUploadTriggers } from "../../../../util/services/data/dataFactory";
import { smpMapLite } from "../../../../../shared/util/constants/smpMapLite";

const DefaultSectionContainer = ({
  data,
  errors,
  dataInput,
  dataLoad,
  fieldToIdMap,
  updateErrors,
  setFieldToIdMap,
  stepTitle,
  validators,
  setNotificationItems,
  showHelperPanel,
}) => {
  const [options] = useOptionsContext();
  const stepTemplate = schema[stepTitle];

  const [{ fieldTemplate, isLoading }] = useFields({
    data,
    stepTemplate,
    fieldToIdMap,
    updateFieldMap: setFieldToIdMap,
    dataLoad,
    dataInput,
    getTemplate: getTemplate,
    filterFields,
    schema,
    getFundRequest,
  });

  if (smpMapLite(data) && Object.keys(fieldTemplate).length !== 0) {
    fieldTemplate.fundRequestInformation.fields.forEach((field) => {
      if (field.name === "awsCalculatorUrl") {
        field.required = false;
      }
    });
  }

  const section = isLoading ? (
    <Loading />
  ) : (
    <Section header={<Header variant="h2">{stepTitle}</Header>}>
      <React.Fragment>
        {fieldTemplate &&
          fieldTemplate[stepTemplate.name] &&
          fieldTemplate[stepTemplate.name].fields.map((field, index) => {
            const inputProps = InputPropsBuilder({
              data,
              errors,
              field,
              options,
              fieldToIdMap,
              sectionName: stepTemplate.name,
              disableFactory: DisableFactory,
              valueFactory: ValueFactory,
            });

            return (
              <InputFactory
                key={index}
                dataInput={dataInput}
                fieldToIdMap={fieldToIdMap}
                updateErrors={updateErrors}
                validators={validators}
                setNotificationItems={setNotificationItems}
                showHelperPanel={showHelperPanel}
                fileuploadProp={FileUploadTriggers}
                {...inputProps}
              />
            );
          })}
      </React.Fragment>
    </Section>
  );
  return <React.Fragment>{section}</React.Fragment>;
};

DefaultSectionContainer.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object,
  dataInput: PropTypes.func,
  dataLoad: PropTypes.func,
  fieldToIdMap: PropTypes.object,
  updateErrors: PropTypes.func,
  setTemplate: PropTypes.func,
  validators: PropTypes.any,
  setFieldToIdMap: PropTypes.func,
  stepTitle: PropTypes.string,
  setNotificationItems: PropTypes.func,
  showHelperPanel: PropTypes.func,
};

export default React.memo(DefaultSectionContainer);
