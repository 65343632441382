import React from "react";
import ConditionalField from "shared/components/common/Conditional/ConditionalField";
import { Alert } from "@amzn/awsui-components-react";
import { IGenericObject } from "shared/programs/migration-acceleration-program/2024/fund-request/types/CommonTypes";

const IncentiveAlertsWithVMware = ({ data }: { data: IGenericObject }) => {
  const greenfieldIncentiveEligible =
    (data && data["greenfieldIncentiveEligible"]) || false;
  const modernizationIncentiveEligible =
    (data && data["modernizationIncentiveEligible"]) || false;
  const vmwareAcknowledgement =
    (data && data["vmwareAcknowledgement"]) || false;
  const vmwareMSPAcknowledgement =
    (data && data["vmwareMSPAcknowledgement"]) || false;

  return (
    <>
      <ConditionalField
        showField={
          vmwareAcknowledgement &&
          !vmwareMSPAcknowledgement &&
          !greenfieldIncentiveEligible &&
          !modernizationIncentiveEligible
        }
      >
        <Alert>
          {
            "Eligible VMware-driven migrations are eligible for additional 10% of the ARR, up to a maximum of $200,000. Upon successful migration they are also eligible for an added bonus of $200 per migrated VM up to maximum of $1,000,000."
          }
        </Alert>
      </ConditionalField>
      <ConditionalField
        showField={
          vmwareAcknowledgement &&
          vmwareMSPAcknowledgement &&
          !greenfieldIncentiveEligible &&
          !modernizationIncentiveEligible
        }
      >
        <Alert>
          {
            "Eligible VMware-driven migrations are eligible for additional 10% of the ARR, up to a maximum of $200,000. If Managed Services is also included as part of the customer offering, upon successful migration they are also eligible for an added bonus of $300 per migrated VM up to $2,000,000."
          }
        </Alert>
      </ConditionalField>
      <ConditionalField
        showField={
          !vmwareAcknowledgement &&
          greenfieldIncentiveEligible &&
          !modernizationIncentiveEligible
        }
      >
        <Alert>
          {
            "Greenfield opportunities are eligible for additional funding up to 10% of the ARR, maximum of $100,000."
          }
        </Alert>
      </ConditionalField>
      <ConditionalField
        showField={
          !vmwareAcknowledgement &&
          !greenfieldIncentiveEligible &&
          modernizationIncentiveEligible
        }
      >
        <Alert>
          {
            "Modernization pathway services driven migration are eligible for additional funding up to 10% of the ARR, maximum of $100,000."
          }
        </Alert>
      </ConditionalField>
      <ConditionalField
        showField={
          vmwareAcknowledgement &&
          !vmwareMSPAcknowledgement &&
          greenfieldIncentiveEligible &&
          !modernizationIncentiveEligible
        }
      >
        <Alert>
          {
            "Greenfield opportunities combined with VMware-driven migrations are eligible for additional funding up to 10% of the ARR, maximum of $100,000 for Greenfield SPI and up to 10% of the ARR, maximum of $200,000 for VMware SPI. Upon successful migration they are also eligible for an added bonus of $200 per migrated VM, maximum of $1,000,000."
          }
        </Alert>
      </ConditionalField>
      <ConditionalField
        showField={
          vmwareAcknowledgement &&
          vmwareMSPAcknowledgement &&
          greenfieldIncentiveEligible &&
          !modernizationIncentiveEligible
        }
      >
        <Alert>
          {
            "Greenfield opportunities combined with VMware-driven migrations are eligible for additional funding up to 10% of the ARR, maximum of $100,000 for Greenfield SPI and up to 10% of the ARR, maximum of $200,000 for VMware SPI. If Managed Services is also included as part of the customer offering, upon successful migration they are also eligible for an added bonus of $300 per migrated VM up to $2,000,000."
          }
        </Alert>
      </ConditionalField>
      <ConditionalField
        showField={
          !vmwareAcknowledgement &&
          greenfieldIncentiveEligible &&
          modernizationIncentiveEligible
        }
      >
        <Alert>
          {
            "Modernization pathway services driven migration with a Greenfield opportunity are eligible for additional funding up to 20% of the ARR, maximum of $200,000."
          }
        </Alert>
      </ConditionalField>
      <ConditionalField
        showField={
          vmwareAcknowledgement &&
          !vmwareMSPAcknowledgement &&
          !greenfieldIncentiveEligible &&
          modernizationIncentiveEligible
        }
      >
        <Alert>
          {
            "VMware-driven migrations with modernization pathway services are eligible for additional funding up to 10% of the ARR, maximum of $100,000 for Modernization SPI and up to 10% of the ARR, maximum of $200,000 for VMware SPI. Upon successful migration they are also eligible for an added bonus of $200 per migrated VM, up to maximum of $1,000,000."
          }
        </Alert>
      </ConditionalField>
      <ConditionalField
        showField={
          vmwareAcknowledgement &&
          vmwareMSPAcknowledgement &&
          !greenfieldIncentiveEligible &&
          modernizationIncentiveEligible
        }
      >
        <Alert>
          {
            "VMware-driven migrations with modernization pathway services are eligible for additional funding up to 10% of the ARR, maximum of $100,000 for Modernization SPI and up to 10% of the ARR, maximum of $200,000 for VMware SPI. If Managed Services is also included as part of the customer offering, upon successful migration they are also eligible for an added bonus of $300 per migrated VM up to $2,000,000."
          }
        </Alert>
      </ConditionalField>
      <ConditionalField
        showField={
          vmwareAcknowledgement &&
          !vmwareMSPAcknowledgement &&
          greenfieldIncentiveEligible &&
          modernizationIncentiveEligible
        }
      >
        <Alert>
          {
            "Greenfield opportunities combined with VMware-driven migrations with modernization pathway services are eligible for additional funding up to 10% of the ARR, maximum of $100,000 for Greenfield SPI, 10% of the ARR, maximum of $100,000 for Modernization SPI, and up to 10% of the ARR, maximum of $200,000 for VMware SPI. Upon successful migration they are also eligible for an added bonus of $200 per migrated VM, up to maximum of $1,000,000."
          }
        </Alert>
      </ConditionalField>
      <ConditionalField
        showField={
          vmwareAcknowledgement &&
          vmwareMSPAcknowledgement &&
          greenfieldIncentiveEligible &&
          modernizationIncentiveEligible
        }
      >
        <Alert>
          {
            "Greenfield opportunities combined with VMware-driven migrations with modernization pathway services are eligible for additional funding up to 10% of the ARR, maximum of $100,000 for Greenfield SPI, 10% of the ARR, maximum of $100,000 for Modernization SPI, and up to 10% of the ARR, maximum of $200,000 for VMware SPI. If Managed Services is also included as part of the customer offering, upon successful migration they are also eligible for an added bonus of $300 per migrated VM up to $2,000,000."
          }
        </Alert>
      </ConditionalField>
    </>
  );
};

export default IncentiveAlertsWithVMware;
