import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  FormField,
  SpaceBetween,
  RadioGroup,
} from "@amzn/awsui-components-react";
import Input from "@amzn/awsui-components-react/polaris/input";
import Textarea from "@amzn/awsui-components-react/polaris/textarea";
import { getUserRoles, itemInputValidation } from "../constant";
import { generateLengthConstraintText } from "../util";
import Multiselect from "@amzn/awsui-components-react/polaris/multiselect";
import { Item, ROOT_ENTITY_NAME } from "../../../../../constant";

const EditModalForm = ({
  entityDetails,
  item,
  setItem,
  errors,
  updateErrors,
}) => {
  useEffect(() => {
    try {
      if (
        entityDetails.entityType !== ROOT_ENTITY_NAME &&
        entityDetails.entityType.startsWith("RiskProfile") &&
        entityDetails.entityType !== "RiskProfileSubRegionRatings" &&
        item?.name
      ) {
        const [sector, programName] = item.name.split("-");
        setItem({ ...item, sector, programName });
      }
    } catch (error) {
      console.error("Error splitting item name:", error);
    }
  }, [item?.name, entityDetails.entityType, setItem]);

  const validateName = (value) => {
    try {
      if (value.length > itemInputValidation.name.limit) {
        updateErrors({
          ...errors,
          name: itemInputValidation.name.message,
        });
      } else {
        updateErrors({ ...errors, name: "" });
        setItem({ ...item, name: value, hasNameChanged: true });
      }
    } catch (error) {
      console.error("Error validating name:", error);
    }
  };

  const validateValue = (value) => {
    try {
      if (value.length > itemInputValidation.value.limit) {
        updateErrors({
          ...errors,
          value: itemInputValidation.value.message,
        });
      } else {
        updateErrors({ ...errors, value: "" });
        setItem({ ...item, value: value, hasValueChanged: true });
      }
    } catch (error) {
      console.error("Error validating value:", error);
    }
  };

  const validateReason = (value) => {
    try {
      if (value.length > itemInputValidation.reason.limit) {
        updateErrors({
          ...errors,
          reason: itemInputValidation.reason.message,
        });
      } else {
        updateErrors({ ...errors, reason: "" });
        setItem({ ...item, reason: value });
      }
    } catch (error) {
      console.error("Error validating reason:", error);
    }
  };

  const handleSectorChange = (value) => {
    try {
      const newName = `${value}-${item.programName || ""}`;
      validateName(newName);
    } catch (error) {
      console.error("Error handling sector change:", error);
    }
  };

  const handleProgramNameChange = (value) => {
    try {
      const newName = `${item.sector || ""}-${value}`;
      validateName(newName);
    } catch (error) {
      console.error("Error handling program name change:", error);
    }
  };

  return (
    <SpaceBetween size="l">
      {entityDetails.entityType !== ROOT_ENTITY_NAME &&
      entityDetails.entityType.startsWith("RiskProfile") ? (
        entityDetails.entityType === "RiskProfileSubRegionRatings" ? (
          <>
            <FormField
              label="Region Name"
              constraintText={generateLengthConstraintText(
                item.name,
                itemInputValidation.name.limit
              )}
              errorText={errors.name}
            >
              <Input
                id="Name"
                value={item.name}
                onChange={({ detail }) => validateName(detail.value)}
              />
            </FormField>
            <FormField label="Risk Rating" errorText={errors.value}>
              <RadioGroup
                items={[
                  { label: "Red", value: "red" },
                  { label: "Green", value: "green" },
                  { label: "Yellow", value: "yellow" },
                ]}
                value={item.value}
                onChange={({ detail }) => validateValue(detail.value)}
              />
            </FormField>
          </>
        ) : (
          <>
            <FormField label="Sector" errorText={errors.sector}>
              <RadioGroup
                items={[
                  { label: "CS", value: "CS" },
                  { label: "PS", value: "PS" },
                ]}
                value={item.sector}
                onChange={({ detail }) => handleSectorChange(detail.value)}
              />
            </FormField>
            <FormField label="Program Name" errorText={errors.programName}>
              <RadioGroup
                items={[
                  { label: "MDF", value: "MDF" },
                  { label: "POC", value: "POC" },
                ]}
                value={item.programName}
                onChange={({ detail }) => handleProgramNameChange(detail.value)}
              />
            </FormField>
            <FormField
              label="Auto Approval Limit"
              constraintText={generateLengthConstraintText(
                item.value,
                itemInputValidation.value.limit
              )}
              errorText={errors.value}
            >
              <Textarea
                id="description"
                value={item.value}
                onChange={({ detail }) => validateValue(detail.value)}
              />
            </FormField>
          </>
        )
      ) : (
        <>
          <FormField
            label="Item Name"
            constraintText={generateLengthConstraintText(
              item.name,
              itemInputValidation.name.limit
            )}
            errorText={errors.name}
          >
            <Input
              id="Name"
              value={item.name}
              onChange={({ detail }) => validateName(detail.value)}
            />
          </FormField>
          <FormField
            label="Item Description"
            constraintText={generateLengthConstraintText(
              item.value,
              itemInputValidation.value.limit
            )}
            errorText={errors.value}
          >
            <Textarea
              id="description"
              value={item.value}
              onChange={({ detail }) => validateValue(detail.value)}
            />
          </FormField>
        </>
      )}

      {entityDetails.selfServiceAuthorizerFlag &&
        entityDetails.entityType === ROOT_ENTITY_NAME && (
          <FormField label={<span>Permissions</span>}>
            <Multiselect
              selectedOptions={item.permissions?.map((role) => ({
                label: role,
                value: role,
              }))}
              onChange={({ detail }) => {
                setItem({
                  ...item,
                  [Item.PERMISSIONS]: detail.selectedOptions?.map(
                    (option) => option.value
                  ),
                  hasPermissionsChanged: true,
                });
              }}
              options={getUserRoles()}
              placeholder="Choose UserRoles"
            />
          </FormField>
        )}
      <FormField
        label={
          <span>
            Reason <i>- optional</i>
          </span>
        }
        constraintText={generateLengthConstraintText(
          item.reason,
          itemInputValidation.reason.limit
        )}
        errorText={errors.reason}
      >
        <Textarea
          id="reason"
          value={item.reason}
          onChange={({ detail }) => validateReason(detail.value)}
        />
      </FormField>
    </SpaceBetween>
  );
};

EditModalForm.propTypes = {
  entityDetails: PropTypes.object,
  item: PropTypes.object,
  setItem: PropTypes.func,
  errors: PropTypes.object,
  updateErrors: PropTypes.func,
};

export default EditModalForm;
