import getValidOpportunityStages, {
  AceOpportunityStage,
} from "./OpportunityStageFactory";
import { IGenericObject } from "shared/programs/migration-acceleration-program/2024/fund-request/types/CommonTypes";

export const getHeaderCounterText = (
  opportunities: Array<IGenericObject>,
  selectedItems: Array<IGenericObject>
) => {
  if (opportunities) {
    return selectedItems.length > 0
      ? `(1/${opportunities.length})`
      : `(${opportunities.length})`;
  } else {
    return `(0)`;
  }
};

export const isValidOpportunity = ({
  opportunityStage,
  migrationPhase,
}: {
  opportunityStage: AceOpportunityStage;
  migrationPhase: string;
}) => {
  return getValidOpportunityStages(migrationPhase).includes(opportunityStage);
};

export const getUserFriendOpportunityId = ({
  aceOpportunityId,
  opportunities,
}: {
  aceOpportunityId: string;
  opportunities: Array<IGenericObject>;
}) => {
  if (!aceOpportunityId) return "";
  if (!opportunities || opportunities.length <= 0) return "";

  const [opportunity] =
    opportunities &&
    opportunities.filter(
      (opportunity) => opportunity.opportunityId === aceOpportunityId
    );

  if (!opportunity) return "";
  return opportunity.userFriendlyId;
};

export const getGreenFieldIndicator = (e: IGenericObject) => {
  if (!e?.gtmGreenfieldFlag) {
    return "-";
  }
  return e?.gtmGreenfieldFlag.includes("GFDM1") ||
    e.gtmGreenfieldFlag.includes("GFDM2")
    ? "Yes"
    : "No";
};

// export const getVMWareIndicator = (e: IGenericObject) => {
//   if (!e?.apnPrograms) {
//     return "-";
//   }
//   return e?.apnPrograms.includes("VMware Cloud on AWS") ? "Yes" : "No";
// };
