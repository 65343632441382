import { Button } from "@amzn/awsui-components-react";
import React from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { submitBulkAction } from "../../../../../util/services/data/InternalDataService";
import { ERROR_TYPE, hasError } from "shared/util/services/data/DataService";
import { BULK_ACTION_TYPE_TO_TRANSFORM_FN } from "../config";

const Submit = ({ records, fileName, bulkActionType }) => {
  const history = useHistory();
  const submitOnClick = async () => {
    let transformedRecords;
    try {
      transformedRecords =
        BULK_ACTION_TYPE_TO_TRANSFORM_FN[bulkActionType.value](records);
    } catch (error) {
      history.replace({
        pathname: "/bulk-actions",
        state: {
          errorMsg: "Failed to parse the input data. Please validate the data.",
        },
      });
    }
    const response = await submitBulkAction({
      actionType: bulkActionType.value,
      body: {
        bulkActionEntries: transformedRecords,
        fileName: fileName,
      },
    });

    let errorMsg = undefined;

    if (
      response &&
      hasError(response) &&
      response.errorType === ERROR_TYPE.BANNER
    ) {
      errorMsg = response.message;
      // No way around this in the case of payload validation errors
      errorMsg = errorMsg.replace("Bad Request: 400 Bad Request: ", "");
    }
    history.replace({
      pathname: "/bulk-actions",
      state: {
        errorMsg: errorMsg,
      },
    });
  };

  return (
    <Button variant="primary" onClick={submitOnClick}>
      Submit
    </Button>
  );
};

Submit.propTypes = {
  records: PropTypes.array,
  fileName: PropTypes.string,
  bulkActionType: PropTypes.object,
  setNotificationsItems: PropTypes.func,
};

export default Submit;
