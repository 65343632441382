import React, { useEffect, useState } from "react";
import { useCollection } from "@amzn/awsui-collection-hooks";
import TableEmptyState from "../common/TableEmptyState";
import TableNoMatchState from "../common/TableNoMatchState";
import Table from "@amzn/awsui-components-react/polaris/table";

import { Pagination } from "@amzn/awsui-components-react";

import PropTypes from "prop-types";

import {
  ERROR_TYPE,
  hasError,
  retryApiCall,
} from "../../../shared/util/services/data/DataService";
import { getAudits } from "../../util/services/data/InternalDataService";
import { setErrorMessage } from "../../../shared/util/common/helper";
import {
  auditsColumnDefinition,
  searchableColumns,
  visibleColumns,
} from "./TableConfiguration/tableConfig";
import TableFilter from "../common/TableFilter/TableFilter";

import TableHeader from "./TableConfiguration/TableHeader";
import { getInternalRoles } from "../../util/services/auth/AuthService";

const AuditsTable = ({ setNotifications }) => {
  const PAGE_SIZE = 10;
  const RESOURCE_NAME = "audits";
  const [audits, setAudits] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedItems, setSelectedItems] = useState([]);

  const loadAudits = async () => {
    setIsLoading(true);
    setAudits([]);
    const data = [];

    const roles = [...getInternalRoles()];
    const auditIds = [];
    let cashClaimExists = false;
    for (let role of roles) {
      let nextPageToken = "";
      while (nextPageToken != null) {
        try {
          const response = await retryApiCall({
            callApi: getAudits({ audience: role, nextPageToken }),
          });

          if (hasError(response) && response.errorType === ERROR_TYPE.BANNER) {
            setErrorMessage({
              setNotificationsItems: setNotifications,
              content: response.message,
              status: response.status,
            });
            setIsLoading(false);
            return;
          }

          for (let request of response["audits"]) {
            if (!auditIds.includes(request.auditId)) {
              data.push({
                frId: request.frId,
                stage: request.stage,
                cashClaimId: request.cashClaimId,
                spmsId: request.spmsId,
                auditingAudience: request.auditingAudience,
                requestedTimestamp: request.requestedTimestamp["timestamp"],
                auditTimestamp: request.auditTimestamp["timestamp"],
                auditor: request.auditor,
                auditCode: request.auditCode,
                comments: request.comments,
                status: request.status,
              });
              auditIds.push(request.auditId);
              if (!cashClaimExists) cashClaimExists = !!request.cashClaimId;
            }
          }

          nextPageToken = response.nextPageToken;
        } catch (e) {
          console.error(e);
          setErrorMessage({
            setNotificationsItems: setNotifications,
          });
        }
      }
    }

    setAudits(data);
    if (cashClaimExists) {
      visibleColumns.push("cashClaimId");
      searchableColumns.push("cashClaimId");
    }
    setIsLoading(false);
  };

  useEffect(async () => {
    await loadAudits();
  }, []);

  const { items, actions, collectionProps, filterProps, paginationProps } =
    useCollection(audits || [], {
      pagination: { pageSize: PAGE_SIZE },
      filtering: {
        noMatch: (
          <TableNoMatchState
            onClearFilter={() => {
              actions.setFiltering("");
            }}
          />
        ),
        filteringFunction: (item, filterText) => {
          return searchableColumns
            .map((key) => item[key])
            .some(
              (value) =>
                typeof value === "string" &&
                value.toLowerCase().includes(filterText.toLowerCase())
            );
        },
      },
      sorting: {
        defaultState: {
          sortingColumn: { sortingField: "requestedTimestamp" },
          isDescending: true,
        },
      },
      selection: {},
    });

  return (
    <Table
      {...collectionProps}
      loading={isLoading}
      loadingText="Loading Audits"
      empty={<TableEmptyState resourceName={RESOURCE_NAME} />}
      items={items}
      selectionType="single"
      columnDefinitions={auditsColumnDefinition}
      filter={
        <TableFilter
          filterProps={filterProps}
          actions={actions}
          label={"Find Audit..."}
        />
      }
      header={
        <TableHeader
          counter={`${items.length} / ${audits.length}` || 0}
          refresh={loadAudits}
          selectedItems={selectedItems}
          setNotifications={setNotifications}
        />
      }
      onSelectionChange={({ detail }) => {
        setSelectedItems(detail.selectedItems);
      }}
      selectedItems={selectedItems}
      pagination={<Pagination {...paginationProps} />}
      visibleColumns={visibleColumns}
    />
  );
};

AuditsTable.propTypes = {
  setNotifications: PropTypes.func,
};

export default AuditsTable;
