import React from "react";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import Box from "@amzn/awsui-components-react/polaris/box";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Button from "@amzn/awsui-components-react/polaris/button";
import Checkbox from "@amzn/awsui-components-react/polaris/checkbox";
import PropTypes from "prop-types";
import {
  ERROR_TYPE,
  hasError,
  retryApiCall,
} from "../../../../../shared/util/services/data/DataService";
import { updateConsentInformation } from "../../../../util/services/data/FundRequestService";
import { ISV_WMP_CONSENT } from "../../../../../shared/util/constants/consentName";
import {
  setErrorMessage,
  setSuccessMessage,
} from "../../../../../shared/util/common/helper";

const WMPConsent = ({
  setButtonDisabled,
  consentVisible,
  setConsentVisible,
  consentChecked,
  setConsentChecked,
  setNotificationsItems,
  fundingData,
  setFundingData,
}) => {
  const reset = () => {
    setConsentVisible(false);
    setConsentChecked(false);
  };

  const submitConsent = async () => {
    try {
      const response = await retryApiCall({
        callApi: updateConsentInformation({
          consent: {
            consentName: ISV_WMP_CONSENT,
            consentGiven: true,
          },
        }),
      });

      if (response) {
        if (hasError(response) && response.errorType === ERROR_TYPE.BANNER) {
          setErrorMessage({
            setNotificationsItems: setNotificationsItems,
            content: response.message,
            status: response.status,
          });
          reset();
          return;
        }

        setConsentVisible(false);
        setButtonDisabled(true);

        setFundingData({
          ...fundingData,
          fundingTemplate: {
            ...fundingData.fundingTemplate,
            isvWmpSecondTrancheConsentCaptured: true,
          },
        });

        setSuccessMessage(
          setNotificationsItems,
          "Your second tranche consent has been saved successfully."
        );
      }
    } catch (e) {
      console.error(e);
      setErrorMessage({
        setNotificationsItems,
      });
      reset();
    }
  };

  return (
    <Modal
      onDismiss={reset}
      visible={consentVisible}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button onClick={reset}>Cancel</Button>
            <Button
              variant="primary"
              onClick={submitConsent}
              disabled={!consentChecked}
            >
              Submit
            </Button>
          </SpaceBetween>
        </Box>
      }
      header="Second Tranche Claim Consent"
    >
      <span>
        <p>
          You hereby acknowledge that you have successfully completed the
          “Project” as defined in the AWS Partner Network Terms & Conditions (as
          may be updated by AWS from time to time), in accordance with (a) your
          proposal to AWS, (b) the guidance, and (c) the AWS Partner Network
          Terms & Conditions. If this Project is by or for a customer that is a
          part of (or is fully or partially owned, managed, or controlled by)
          any government at any level, then you represent and warrant that you
          will disclose and pass on the full value of the funding to the
          customer as a discount or rebate for the work performed under the
          Project, and that you will not retain or use any portion of the
          funding as additional compensation or margin.
        </p>
      </span>
      <Checkbox
        checked={consentChecked}
        onChange={({ detail }) => setConsentChecked(detail.checked)}
      >
        I, (the AWS Partner), acknowledge AWS Partner Network Terms & Conditions
        and confirm that we have completed this Project.
      </Checkbox>
    </Modal>
  );
};

WMPConsent.propTypes = {
  setButtonDisabled: PropTypes.func,
  consentVisible: PropTypes.bool,
  setConsentVisible: PropTypes.func,
  consentChecked: PropTypes.bool,
  setConsentChecked: PropTypes.func,
  setNotificationsItems: PropTypes.func,
  fundingData: PropTypes.object,
  setFundingData: PropTypes.func,
};
export default WMPConsent;
