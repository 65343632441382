import Config from "config";
import {
  b64EncodeUnicode,
  deleteData,
  getData,
  postData,
  putData,
  retryApiCall,
} from "../../../../shared/util/services/data/DataService";
import { getFundRequestId } from "../../../../external/util/common/helper";
import { generateIdempotencyToken } from "../../../../shared/util/services/data/GenerateIdempotencyToken";
import { FundRequestHistoryCategory } from "../../../../shared/util/constants/fundRequestStatusType";

export const PING_ENDPOINT = `${Config.FUNDING_ENDPOINT}/ping`;

export const API_V1_END_POINT = `${Config.FUNDING_ENDPOINT}/v1`;

export const FILE_END_POINT = `${API_V1_END_POINT}/files`;

export const FUND_REQUEST_ENDPOINT = `${API_V1_END_POINT}/fund-requests`;

export const CASH_CLAIM_ENDPOINT = `${API_V1_END_POINT}/cash-claims`;

export const CREDIT_ENDPOINT = `${API_V1_END_POINT}/credit-codes`;

export const DELEGATIONS_ENDPOINT = `${API_V1_END_POINT}/delegations`;

export const AUDITS_ENDPOINT = `${API_V1_END_POINT}/audits`;

export const SELF_SERVICE_ENDPOINT = `${API_V1_END_POINT}/self-service`;

export const BULK_ACTIONS_ENDPOINT = `${API_V1_END_POINT}/bulk-actions`;

export const checkStatusSuccess = (status) => {
  return status > 100 && status < 400;
};

export const updateTemplateInformation = async (body) => {
  const data = {
    annualRunRate: body["arr"],
  };

  return await putData({
    endpoint: `${FUND_REQUEST_ENDPOINT}/${getFundRequestId()}/template/review`,
    body: b64EncodeUnicode(
      JSON.stringify({
        clientToken: generateIdempotencyToken({
          action: "update-arr",
        }),
        ...data,
      })
    ),
  });
};

export const reviewTemplateDetails = async (body) => {
  return await putData({
    endpoint: `${FUND_REQUEST_ENDPOINT}/${getFundRequestId()}/template/review`,
    body: b64EncodeUnicode(
      JSON.stringify({
        clientToken: generateIdempotencyToken({
          action: "update-arr",
        }),
        ...body,
      })
    ),
  });
};

// Update fund request
export const updateFundRequestInformation = async (body) => {
  const payload = {
    activityName: body["activityName"],
    businessDescription: body["businessDescription"],
    awsCalculatorUrl: body["awsCalculatorUrl"],
    sandboxDesignWinActivity: body["sandboxDesignWinActivity"],
    majorityPublicSector: body["majorityPublicSector"],
    thirdPartyFunding: body["thirdPartyFunding"],
    arn: body["arn"],
  };
  return await putData({
    endpoint: `${FUND_REQUEST_ENDPOINT}/${getFundRequestId()}/details/review`,
    body: b64EncodeUnicode(
      JSON.stringify({
        clientToken: generateIdempotencyToken({ action: "details" }),
        ...payload,
      })
    ),
  });
};

export const updateAWSCalculatorUrl = async (body) => {
  return await putData({
    endpoint: `${FUND_REQUEST_ENDPOINT}/${getFundRequestId()}/details/review`,
    body: b64EncodeUnicode(
      JSON.stringify({
        clientToken: generateIdempotencyToken({ action: "details" }),
        ...body,
      })
    ),
  });
};

export const updateThirdPartyFundingId = async (body) => {
  const payload = {
    thirdPartyFundingId: body["thirdPartyFundingId"],
  };
  return await putData({
    endpoint: `${FUND_REQUEST_ENDPOINT}/${getFundRequestId()}/third-party-id`,
    body: b64EncodeUnicode(
      JSON.stringify({
        clientToken: generateIdempotencyToken({ action: "third-party-id" }),
        ...payload,
      })
    ),
  });
};

export const updateProjectInformation = async (body) => {
  const payload = {
    awsAccountId: body["awsAccountId"],
    plannedStartDate: body["plannedStartDate"],
    plannedEndDate: body["plannedEndDate"],
    executedCountry: body["executedCountry"],
    executedState: body["executedState"],
    projectType: body["projectType"],
    customerConsidering: body["customerConsidering"],
    annualRunRateForMisc: body["annualRunRateForMisc"],
    programEngagementId: body["programEngagementId"],
    migrationBdApproved: body["migrationBdApproved"],
    workloadName: body["workloadName"],
    newServiceDeployed: body["newServiceDeployed"],
    projectRoi: body["projectRoi"],
    otherNotes: body["otherNotes"],
  };

  return await putData({
    endpoint: `${FUND_REQUEST_ENDPOINT}/${getFundRequestId()}/project/review`,
    body: b64EncodeUnicode(
      JSON.stringify({
        clientToken: generateIdempotencyToken({ action: "project" }),
        ...payload,
      })
    ),
  });
};

export const reviewProjectInformation = async (body) => {
  return await putData({
    endpoint: `${FUND_REQUEST_ENDPOINT}/${getFundRequestId()}/project/review`,
    body: b64EncodeUnicode(
      JSON.stringify({
        clientToken: generateIdempotencyToken({ action: "project" }),
        ...body,
      })
    ),
  });
};

export const updateMigrationDetails = async (body) => {
  const payload = {
    peCustomer: body["peCustomer"],
    startupCustomer: body["startupCustomer"],
    mraCompleted: body["mraCompleted"],
    workloads: body["workloads"],
  };
  return await putData({
    endpoint: `${FUND_REQUEST_ENDPOINT}/${getFundRequestId()}/migrations/review`,
    body: b64EncodeUnicode(
      JSON.stringify({
        clientToken: generateIdempotencyToken({ action: "migrations" }),
        ...payload,
      })
    ),
  });
};

export const updatePublicSectorDetails = async (body) => {
  const payload = {
    customerRfx: body["customerRfx"],
    wwpsConsent: body["wwpsConsent"],
  };
  return await putData({
    endpoint: `${FUND_REQUEST_ENDPOINT}/${getFundRequestId()}/public-sector/review`,
    body: b64EncodeUnicode(
      JSON.stringify({
        clientToken: generateIdempotencyToken({
          action: "public-sector-details",
        }),
        ...payload,
      })
    ),
  });
};

export const updateCashRequestInformation = async (body) => {
  const payload = {
    currency: body["currency"],
    totalCostOfActivity: body["totalCostOfActivity"],
    partnerContribution: body["partnerContribution"],
    otherPartyContribution: body["otherPartyContribution"],
    cashFundingAmount: body["cashFundingAmount"],
    invoiceEntityName: body["invoiceEntityName"],
    invoiceRemitAddress: body["invoiceRemitAddress"],
    invoiceRemitCountry: body["invoiceRemitCountry"],
    cashClaims: body["cashClaims"],
  };
  return await putData({
    endpoint: `${FUND_REQUEST_ENDPOINT}/${getFundRequestId()}/cash-request/review`,
    body: b64EncodeUnicode(
      JSON.stringify({
        clientToken: generateIdempotencyToken({ action: "cash-request" }),
        ...payload,
      })
    ),
  });
};

export const reviewCashRequestInformation = async (body) => {
  return await putData({
    endpoint: `${FUND_REQUEST_ENDPOINT}/${getFundRequestId()}/cash-request/review`,
    body: b64EncodeUnicode(
      JSON.stringify({
        clientToken: generateIdempotencyToken({ action: "cash-request" }),
        ...body,
      })
    ),
  });
};

export const updatePurchaseOrder = async (body) => {
  const payload = {
    requestNumber: body["requestNumber"],
    currency: body["currency"],
    amount: body["amount"],
    amountUsd: body["amountUsd"],
    number: body["number"],
    issuranceDate: body["issuranceDate"],
    issured: body["issured"],
  };

  return await putData({
    endpoint: `${FUND_REQUEST_ENDPOINT}/${getFundRequestId()}/purchase-order`,
    body: b64EncodeUnicode(
      JSON.stringify({
        clientToken: generateIdempotencyToken({ action: "purchase-order" }),
        ...payload,
      })
    ),
  });
};

export const addPurchaseOrderInformation = async (body) => {
  return await putData({
    endpoint: `${FUND_REQUEST_ENDPOINT}/${getFundRequestId()}/purchase-order`,
    body: b64EncodeUnicode(
      JSON.stringify({
        clientToken: generateIdempotencyToken({ action: "purchase-order" }),
        ...body,
      })
    ),
  });
};
export const updateAccountManagerApproval = async (body) => {
  const payload = {
    accountManagerApproved: body["accountManagerApproved"],
  };
  return await putData({
    endpoint: `${FUND_REQUEST_ENDPOINT}/${getFundRequestId()}/account-manager-approved`,
    body: b64EncodeUnicode(
      JSON.stringify({
        clientToken: generateIdempotencyToken({
          action: "account-manager-approved",
        }),
        ...payload,
      })
    ),
  });
};

export const updateInformationalLetterId = async (body) => {
  const payload = {
    informationalLetterId: body["informationalLetterId"],
  };

  return await putData({
    endpoint: `${FUND_REQUEST_ENDPOINT}/${getFundRequestId()}/informational-letter-id`,
    body: b64EncodeUnicode(
      JSON.stringify({
        clientToken: generateIdempotencyToken({
          action: "informational-letter-id",
        }),
        ...payload,
      })
    ),
  });
};

export const updateCreditRequestDetails = async (body) => {
  const payload = {
    valuePerCode: body["valuePerCode"],
    numberOfCodes: body["numberOfCodes"],
    currency: body["currency"],
    requestedCreditAmount: body["requestedCreditAmountEditable"],
    totalCostOfActivity: body["totalCostOfActivity"],
  };
  return await putData({
    endpoint: `${FUND_REQUEST_ENDPOINT}/${getFundRequestId()}/credit-request/review`,
    body: b64EncodeUnicode(
      JSON.stringify({
        clientToken: generateIdempotencyToken({ action: "credit-request" }),
        ...payload,
      })
    ),
  });
};

export const updateApprovalDetails = async (body) => {
  const payload = {
    partnerSolutionArchitect: body["partnerSolutionArchitect"],
  };
  return await putData({
    endpoint: `${FUND_REQUEST_ENDPOINT}/${getFundRequestId()}/approval-details/review`,
    body: b64EncodeUnicode(
      JSON.stringify({
        clientToken: generateIdempotencyToken({ action: "approval-details" }),
        ...payload,
      })
    ),
  });
};

export const updateExceptionApproval = async (body) => {
  const payload = {
    exceptionApproval: body["exceptionApproval"],
    exceptionComment: body["exceptionComment"],
    externalApprovalSystemId: body["externalApprovalSystemId"],
  };
  return await putData({
    endpoint: `${FUND_REQUEST_ENDPOINT}/${getFundRequestId()}/approval-exception`,
    body: b64EncodeUnicode(
      JSON.stringify({
        clientToken: generateIdempotencyToken({ action: "approval-exception" }),
        ...payload,
      })
    ),
  });
};

export const updateCashClaimInvoice = async (body, cashClaimId) => {
  const payload = {
    fundRequestId: body["fundRequestId"],
    scheduledPaidDate: body["scheduledPaidDate"],
    currency: body["currency"],
    amount: body["amount"],
    description: body["description"],
  };
  return await putData({
    endpoint: `${CASH_CLAIM_ENDPOINT}/${cashClaimId}/invoice`,
    body: b64EncodeUnicode(
      JSON.stringify({
        clientToken: generateIdempotencyToken({ action: "invoice-cash-claim" }),
        ...payload,
      })
    ),
  });
};

export const reviewClaimInvoice = async (body, claimId) => {
  return await putData({
    endpoint: `${CASH_CLAIM_ENDPOINT}/${claimId}/invoice`,
    body: b64EncodeUnicode(
      JSON.stringify({
        clientToken: generateIdempotencyToken({ action: "invoice-cash-claim" }),
        ...body,
      })
    ),
  });
};

export const submitCashClaimInvoice = async (body, claimId) => {
  const payload = {
    fundRequestId: body["fundRequestId"],
    scheduledPaidDate: body["scheduledPaidDate"],
    currency: body["currency"],
    amount: body["amount"],
    description: body["description"],
  };
  return await putData({
    endpoint: `${CASH_CLAIM_ENDPOINT}/${claimId}/invoice/submit`,
    body: b64EncodeUnicode(
      JSON.stringify({
        clientToken: generateIdempotencyToken({
          action: "invoice-submit-cash-claim",
        }),
        ...payload,
      })
    ),
  });
};

export const approveCashClaimActual = async (body, cashClaimId) => {
  return await putData({
    endpoint: `${CASH_CLAIM_ENDPOINT}/${cashClaimId}/actual/approve`,
    body: b64EncodeUnicode(
      JSON.stringify({
        clientToken: generateIdempotencyToken({
          action: "actual-approve-cash-claim",
        }),
        ...body,
      })
    ),
  });
};

export const rejectCashClaimActual = async (body, cashClaimId) => {
  return await putData({
    endpoint: `${CASH_CLAIM_ENDPOINT}/${cashClaimId}/actual/reject`,
    body: b64EncodeUnicode(
      JSON.stringify({
        clientToken: generateIdempotencyToken({
          action: "actual-reject-cash-claim",
        }),
        ...body,
      })
    ),
  });
};

export const completeCashClaim = async (body, cashClaimId) => {
  return await putData({
    endpoint: `${CASH_CLAIM_ENDPOINT}/${cashClaimId}/complete`,
    body: b64EncodeUnicode(
      JSON.stringify({
        clientToken: generateIdempotencyToken({
          action: "complete-cash-claim",
        }),
        ...body,
      })
    ),
  });
};

// Get API
export const getPing = async () => {
  return await getData({ endpoint: PING_ENDPOINT });
};

export const getFundRequest = async (fundRequestId) => {
  return await getData({
    endpoint: FUND_REQUEST_ENDPOINT,
    routeParam: fundRequestId,
  });
};

export const getCountries = async () => {
  return await getData({ endpoint: API_V1_END_POINT, routeParam: "countries" });
};

export const getCurrencies = async () => {
  return await getData({
    endpoint: API_V1_END_POINT,
    routeParam: "currencies",
  });
};

export const getUploadUrl = async (body) => {
  return await putData({
    endpoint: `${CREDIT_ENDPOINT}/upload/url`,
    body: b64EncodeUnicode(JSON.stringify(body)),
  });
};

export const uploadCredits = async (endpoint, body) => {
  return await putData({ headers: {}, endpoint, body });
};

export const getFundRequestAttachments = async (nextPageToken) => {
  const queryParam = [
    `nextPageToken=${nextPageToken && btoa(JSON.stringify(nextPageToken))}`,
  ];
  return await getData({
    endpoint: `${FILE_END_POINT}/file-list/fund-request`,
    routeParam: getFundRequestId(),
    queryParam,
  });
};

export const getDownloadUrl = async (fileId) => {
  const queryParam = [`fileId=${fileId}`];
  return await getData({
    endpoint: FILE_END_POINT,
    routeParam: "download/url",
    queryParam,
  });
};

export const getFileList = async (nextPageToken) => {
  const queryParam = [
    `nextPageToken=${nextPageToken && btoa(JSON.stringify(nextPageToken))}`,
  ];
  return await getData({
    endpoint: CREDIT_ENDPOINT,
    routeParam: "file-list",
    queryParam,
  });
};

export const getCreditDownloadUrl = async (fileId) => {
  const queryParam = [`fileId=${fileId}`];
  return await getData({
    endpoint: CREDIT_ENDPOINT,
    routeParam: "download/url",
    queryParam,
  });
};

export const getPartnerApplicationDetails = async ({
  fundRequestId = getFundRequestId(),
  nextPageToken,
}) => {
  const queryParam = [
    `nextPageToken=${nextPageToken && btoa(JSON.stringify(nextPageToken))}`,
  ];
  return await getData({
    endpoint: `${FUND_REQUEST_ENDPOINT}/${fundRequestId}/application`,
    queryParam,
  });
};

export const getFundRequestDuplicates = async ({
  fundRequestId = getFundRequestId(),
}) => {
  return await getData({
    endpoint: `${FUND_REQUEST_ENDPOINT}/${fundRequestId}/duplicates`,
  });
};

export const getFundRequestHistory = async ({
  fundRequestId = getFundRequestId(),
  nextPageToken,
}) => {
  const queryParam = [
    `nextPageToken=${nextPageToken && btoa(JSON.stringify(nextPageToken))}`,
    `category=${FundRequestHistoryCategory.FR_HISTORY}`,
  ];
  return await getData({
    endpoint: `${FUND_REQUEST_ENDPOINT}/${fundRequestId}/history`,
    queryParam,
  });
};

export const getApprovalHistory = async ({
  fundRequestId = getFundRequestId(),
  nextPageToken,
}) => {
  const queryParam = [
    `nextPageToken=${nextPageToken && btoa(JSON.stringify(nextPageToken))}`,
    `category=${FundRequestHistoryCategory.INTERNAL_APPROVAL}`,
  ];
  return await getData({
    endpoint: `${FUND_REQUEST_ENDPOINT}/${fundRequestId}/history`,
    queryParam,
  });
};

export const getAttachmentHistory = async ({
  fundRequestId = getFundRequestId(),
  nextPageToken,
}) => {
  const queryParam = [
    `nextPageToken=${nextPageToken && btoa(JSON.stringify(nextPageToken))}`,
    `category=${FundRequestHistoryCategory.ATTACHMENT}`,
  ];
  return await getData({
    endpoint: `${FUND_REQUEST_ENDPOINT}/${fundRequestId}/history`,
    queryParam,
  });
};

export const getAssigneeCandidates = async (aliasPrefix, assigneeType) => {
  const queryParam = [`aliasPrefix=${aliasPrefix}`, `type=${assigneeType}`];
  return await getData({
    endpoint: API_V1_END_POINT,
    routeParam: "assignee/candidates",
    queryParam: queryParam,
  });
};

// PUT
export const submitReivew = async ({
  payload,
  fundRequestId = getFundRequestId(),
}) => {
  return await putData({
    endpoint: `${FUND_REQUEST_ENDPOINT}/${fundRequestId}/submit/review`,
    body: b64EncodeUnicode(
      JSON.stringify({
        clientToken: generateIdempotencyToken({
          fundRequestId,
          action: "review-submit",
        }),
        fundRequestId,
        ...payload,
      })
    ),
  });
};

export const rejectReivew = async ({
  payload,
  fundRequestId = getFundRequestId(),
}) => {
  return await putData({
    endpoint: `${FUND_REQUEST_ENDPOINT}/${fundRequestId}/reject/review`,
    body: b64EncodeUnicode(
      JSON.stringify({
        clientToken: generateIdempotencyToken({ action: "review-reject" }),
        fundRequestId,
        ...payload,
      })
    ),
  });
};

export const submitBusinessApproval = async ({
  payload,
  fundRequestId = getFundRequestId(),
}) => {
  return await putData({
    endpoint: `${FUND_REQUEST_ENDPOINT}/${fundRequestId}/submit/business-approval`,
    body: b64EncodeUnicode(
      JSON.stringify({
        clientToken: generateIdempotencyToken({
          fundRequestId,
          action: "business-approval-submit",
        }),
        fundRequestId,
        ...payload,
      })
    ),
  });
};

export const rejectBusinessApproval = async ({
  payload,
  fundRequestId = getFundRequestId(),
}) => {
  return await putData({
    endpoint: `${FUND_REQUEST_ENDPOINT}/${fundRequestId}/reject/business-approval`,
    body: b64EncodeUnicode(
      JSON.stringify({
        clientToken: generateIdempotencyToken({
          action: "business-approval-reject",
        }),
        fundRequestId,
        ...payload,
      })
    ),
  });
};

export const submitFinanceApproval = async ({
  payload,
  fundRequestId = getFundRequestId(),
}) => {
  return await putData({
    endpoint: `${FUND_REQUEST_ENDPOINT}/${fundRequestId}/submit/finance-approval`,
    body: b64EncodeUnicode(
      JSON.stringify({
        clientToken: generateIdempotencyToken({
          fundRequestId,
          action: "finance-approval-submit",
        }),
        fundRequestId,
        ...payload,
      })
    ),
  });
};

export const rejectFinanceApproval = async ({
  payload,
  fundRequestId = getFundRequestId(),
}) => {
  return await putData({
    endpoint: `${FUND_REQUEST_ENDPOINT}/${fundRequestId}/reject/finance-approval`,
    body: b64EncodeUnicode(
      JSON.stringify({
        clientToken: generateIdempotencyToken({
          action: "finance-approval-reject",
        }),
        fundRequestId,
        ...payload,
      })
    ),
  });
};

export const submitPreApproval = async ({
  payload,
  fundRequestId = getFundRequestId(),
}) => {
  return await putData({
    endpoint: `${FUND_REQUEST_ENDPOINT}/${fundRequestId}/submit/pre-approval`,
    body: b64EncodeUnicode(
      JSON.stringify({
        clientToken: generateIdempotencyToken({
          fundRequestId,
          action: "pre-approval-submit",
        }),
        fundRequestId,
        ...payload,
      })
    ),
  });
};

export const rejectPreApproval = async ({
  payload,
  fundRequestId = getFundRequestId(),
}) => {
  return await putData({
    endpoint: `${FUND_REQUEST_ENDPOINT}/${fundRequestId}/reject/pre-approval`,
    body: b64EncodeUnicode(
      JSON.stringify({
        clientToken: generateIdempotencyToken({
          action: "pre-approval-reject",
        }),
        fundRequestId,
        ...payload,
      })
    ),
  });
};

export const submitCashClaimApproval = async ({
  fundRequestId = getFundRequestId(),
  cashClaimId,
}) => {
  return await putData({
    endpoint: `${CASH_CLAIM_ENDPOINT}/${cashClaimId}/submit/approval`,
    body: b64EncodeUnicode(
      JSON.stringify({
        clientToken: generateIdempotencyToken({
          fundRequestId,
          action: "cash-claim-submit",
        }),
        fundRequestId,
      })
    ),
  });
};

export const rejectCashClaimApproval = async ({ cashClaimId }) => {
  return await putData({
    endpoint: `${CASH_CLAIM_ENDPOINT}/${cashClaimId}/reject/approval`,
    body: b64EncodeUnicode(
      JSON.stringify({
        clientToken: generateIdempotencyToken({ action: "cash-claim-reject" }),
        fundRequestId: getFundRequestId(),
      })
    ),
  });
};

export const submitCompletion = async ({
  payload,
  fundRequestId = getFundRequestId(),
}) => {
  return await putData({
    endpoint: `${FUND_REQUEST_ENDPOINT}/${fundRequestId}/submit/completion`,
    body: b64EncodeUnicode(
      JSON.stringify({
        clientToken: generateIdempotencyToken({
          fundRequestId,
          action: "completion-submit",
        }),
        ...payload,
      })
    ),
  });
};

export const getFundRequestListing = async ({
  assignedTo,
  status,
  nextPageToken,
}) => {
  const queryParam = [`assignedTo=${assignedTo}`];
  if (status) {
    queryParam.push(`status=${status}`);
  }
  if (nextPageToken) {
    queryParam.push(
      `nextPageToken=${nextPageToken && btoa(JSON.stringify(nextPageToken))}`
    );
  }
  return await getData({ endpoint: FUND_REQUEST_ENDPOINT, queryParam });
};

export const changeAssignee = async ({
  payload,
  fundRequestId = getFundRequestId(),
}) => {
  return await putData({
    endpoint: `${FUND_REQUEST_ENDPOINT}/${fundRequestId}/assignee/change`,
    body: b64EncodeUnicode(
      JSON.stringify({
        clientToken: generateIdempotencyToken({
          fundRequestId,
          action: "change-assignee",
        }),
        fundRequestId,
        ...payload,
      })
    ),
  });
};

export const extendFundRequest = async ({ fundRequestId, payload }) => {
  return await putData({
    endpoint: `${FUND_REQUEST_ENDPOINT}/${fundRequestId}/extend`,
    body: b64EncodeUnicode(
      JSON.stringify({
        clientToken: generateIdempotencyToken({
          fundRequestId,
          action: "extend",
        }),
        ...payload,
      })
    ),
  });
};

export const loadCountries = async () => {
  let countries = {};
  try {
    const response = await retryApiCall({ callApi: getCountries() });
    response.items.sort((a, b) => a.name > b.name);
    countries = response.items.map((item) => {
      const countryName = item.name;
      const countryCode = item.countryISO2Code;
      return { label: countryName, value: countryCode };
    });
  } catch (err) {
    console.error(err);
  }
  return countries;
};

export const loadCurrencies = async () => {
  let currencies = {};
  try {
    const response = await retryApiCall({ callApi: getCurrencies() });

    response.items.sort((a, b) => a.name > b.name);
    currencies = response.items.map((item) => {
      return { label: item.name, value: item.value };
    });
  } catch (err) {
    console.error(err);
  }
  return currencies;
};

export const uploadFile = async (endpoint, body, fileId) => {
  return await putData({
    endpoint: endpoint,
    headers: { "x-amz-tagging": `FileId=${fileId}` },
    body,
  });
};

export const getUploadFileUrl = async (body, fund_claim_id) => {
  if (fund_claim_id) {
    return getUploadFileForCashClaimUrl(body, fund_claim_id);
  }
  return getUploadFileForFundRequestUrl(body);
};

const getUploadFileForFundRequestUrl = async (body) => {
  return await putData({
    endpoint: `${FILE_END_POINT}/upload/${getFundRequestId()}/url`,
    body: b64EncodeUnicode(JSON.stringify(body)),
  });
};

const getUploadFileForCashClaimUrl = async (body, fund_claim_id) => {
  return await putData({
    endpoint: `${FILE_END_POINT}/upload/${getFundRequestId()}/url?fund_claim_id=${
      fund_claim_id || ""
    }`,
    body: b64EncodeUnicode(JSON.stringify(body)),
  });
};

export const deleteFile = async (fildId) => {
  return await deleteData({ endpoint: `${FILE_END_POINT}/${fildId}/delete` });
};

export const attachFileToFundRequest = async ({ body, fundRequestId }) => {
  return await putData({
    endpoint: `${FUND_REQUEST_ENDPOINT}/${fundRequestId}/attach`,
    body: b64EncodeUnicode(
      JSON.stringify({
        clientToken: generateIdempotencyToken({
          action: "attach-to-fund-request",
        }),
        ...body,
      })
    ),
  });
};

export const createDelegations = async ({ body }) => {
  return await postData({
    endpoint: `${DELEGATIONS_ENDPOINT}`,
    body: b64EncodeUnicode(
      JSON.stringify({
        ...body,
      })
    ),
  });
};

export const getDelegations = async ({ nextPageToken }) => {
  const queryParam = [`nextPageToken=${nextPageToken}`];
  return await getData({
    endpoint: `${DELEGATIONS_ENDPOINT}`,
    queryParam,
  });
};

export const updateDelegation = async ({ delegationId, body }) => {
  return await putData({
    endpoint: `${DELEGATIONS_ENDPOINT}/${delegationId}`,
    body: b64EncodeUnicode(
      JSON.stringify({
        ...body,
      })
    ),
  });
};

export const deleteDelegation = async ({ delegationId }) => {
  return deleteData({
    endpoint: `${DELEGATIONS_ENDPOINT}/${delegationId}`,
  });
};

export const getAudits = async ({ audience, nextPageToken }) => {
  const queryParam = [
    `nextPageToken=${nextPageToken}`,
    `assignedTo=${audience}`,
  ];
  return await getData({
    endpoint: `${AUDITS_ENDPOINT}`,
    queryParam,
  });
};

export const getAuditsByFrId = async ({ fundRequestId }) => {
  return await getData({
    endpoint: `${AUDITS_ENDPOINT}/fund-requests/${fundRequestId}`,
  });
};

export const conductAudit = async ({ body }) => {
  return await putData({
    endpoint: `${AUDITS_ENDPOINT}`,
    body: b64EncodeUnicode(
      JSON.stringify({
        clientToken: generateIdempotencyToken({
          action: "conduct-audit",
        }),
        ...body,
      })
    ),
  });
};

export const getSelfServiceEntityEvents = async ({
  entityType,
  nextPageToken,
}) => {
  const queryParam = [`nextPageToken=${nextPageToken}`];
  return await getData({
    endpoint: `${SELF_SERVICE_ENDPOINT}/${entityType}`,
    queryParam,
  });
};

export const addSelfServiceEntityEvent = async ({ entityType, body }) => {
  return await postData({
    endpoint: `${SELF_SERVICE_ENDPOINT}/${entityType}`,
    body: b64EncodeUnicode(
      JSON.stringify({
        ...body,
      })
    ),
  });
};

export const getBulkActions = async ({ nextPageToken }) => {
  const queryParam = [`nextPageToken=${nextPageToken}`];
  return await getData({
    endpoint: `${BULK_ACTIONS_ENDPOINT}`,
    queryParam,
  });
};

export const getBulkActionResult = async ({ taskId }) => {
  return await getData({
    endpoint: `${BULK_ACTIONS_ENDPOINT}/${taskId}`,
  });
};

export const submitBulkAction = async ({ actionType, body }) => {
  return await postData({
    endpoint: `${BULK_ACTIONS_ENDPOINT}/${actionType}`,
    body: b64EncodeUnicode(
      JSON.stringify({
        ...body,
      })
    ),
  });
};
