export const partnerApplicationColumnDefinitions = [
  {
    id: "name",
    header: "Name",
    cell: (e) => e.name,
    sortingField: "name",
  },
  {
    id: "status",
    header: "Status",
    cell: (e) => e.status,
    sortingField: "Status",
  },
  {
    id: "awardDate",
    header: "Award Date",
    cell: (e) => e.awardDate || "-",
    sortingField: "awardDate",
  },
  {
    id: "designationName",
    header: "Designation",
    cell: (e) => e.designationName,
    sortingField: "designationName",
  },
  {
    id: "recordTypeLabel",
    header: "Record Type",
    cell: (e) => e.recordTypeLabel,
    sortingField: "recordTypeLabel",
  },
  {
    id: "accountName",
    header: "Partner Account",
    cell: (e) => e.accountName,
    sortingField: "accountName",
  },
];

export const searchableColumns = [
  "name",
  "status",
  "awardDate",
  "designationName",
  "recordTypeLabel",
  "accountName",
];

export const defaultStatus = {
  label: "Any Status",
  value: "Any Status",
};

export const defaultRecordTypeLabel = {
  label: "Any Record Type",
  value: "Any Record Type",
};
