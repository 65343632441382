export const getValidationErrors = (errors) => {
  const errorsMap = {};
  if (errors && errors.length > 0) {
    errors.map((error) => {
      let key = "";
      let message = "";

      if (error.keyword === "required") {
        key = error.params.missingProperty;
        message = "Field is required.";
      } else {
        key = error.instancePath.split("/")[1];
        message = error.message;
      }

      errorsMap[key] = message;
    });
  }

  return errorsMap;
};
