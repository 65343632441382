import React from "react";
import "./style.css";
import PropTypes from "prop-types";
import { SurveyBanner } from "@amzn/node-js-apn-feedback-common-assets";
import {
  getUserCognitoId,
  getRoles,
  getSpmsId,
} from "../../util/services/auth/AuthService";
import { buildMetadataList } from "shared/util/common/feedback";

const SurveyBannerContainer = ({
  apertureForm,
  nonConsoleOptions,
  question,
  hideBanner,
}) => {
  const onError = (error) => {
    console.error(error);
  };
  const userId = getUserCognitoId() || "";
  const userRoles = getRoles() || [];
  const spmsId = getSpmsId() || "";

  const metadataList = buildMetadataList(spmsId, userId, userRoles);

  const showBanner = !hideBanner && apertureForm && nonConsoleOptions;
  return showBanner ? (
    <div className="survey-banner">
      <SurveyBanner
        formInfo={apertureForm}
        nonConsoleOptions={nonConsoleOptions}
        userId={userId}
        question={question}
        buttonText={"Take the Survey"}
        metadataList={metadataList}
        onError={onError}
      />
    </div>
  ) : (
    <React.Fragment />
  );
};

SurveyBannerContainer.propTypes = {
  apertureForm: PropTypes.object,
  nonConsoleOptions: PropTypes.object,
  question: PropTypes.object,
  hideBanner: PropTypes.bool,
};

export default SurveyBannerContainer;
