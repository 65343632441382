import { uiToApiMap } from "../../../../shared/util/adapter/toUi/adapter";
import { SECTION_NAME } from "../../../../shared/util/constants/section";

export const getCashClaimStage = (fundingData, cashClaimId) => {
  try {
    const cashRequestApiKey = uiToApiMap[SECTION_NAME.CASH_REQUEST_INFORMATION];
    const cashClaimStage =
      fundingData &&
      fundingData[cashRequestApiKey] &&
      fundingData[cashRequestApiKey]["cashClaims"][cashClaimId] &&
      fundingData[cashRequestApiKey]["cashClaims"][cashClaimId]["stage"];
    return cashClaimStage;
  } catch (err) {
    console.log("error in retrieving the cash claim stage: " + err);
  }
};
