import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DOMPurify from "dompurify";
import { filterFields, getInternalTemplate, schema } from "../../config/schema";
import {
  getSectionConstant,
  SECTION,
  SECTION_CONSTANT_TYPE,
  SECTION_NAME,
} from "../../../shared/util/constants/section";
import {
  createFieldSchemaObject,
  createJsonSchemaObject,
} from "../../../shared/util/schema/util";
import { retryApiCall } from "../../../shared/util/services/data/DataService";
import { getFundRequest } from "../../util/services/data/InternalDataService";
import { createValidator } from "../../../shared/util/validation/validator";
import { EditSectionContainerFactory } from "./Sections/EditSectionContainerFactory";
import withAppLayout from "../../../shared/components/withAppLayout";
import { SpaceBetween } from "@amzn/awsui-components-react";
import { getBreadcrumbs } from "../../../shared/util/common/getBreadcrumbs";
import Loading from "../../../shared/components/common/Loading";
import { OptionsProvider } from "../../util/context/OptionsContext";
import { DataSubmissionFactory } from "../../util/services/data/dataFactory";
import { TITLE } from "../../../shared/util/constants/title";
import { PAGE_TITLE } from "../../util/constants/pages";
import PropTypes from "prop-types";
import useHelpText from "../../../shared/util/hooks/useHelpText";
import { HelperPanel } from "../../../shared/components/FundRequest/Help/HelperPanel";
import { FooterFactory } from "./Footer/FooterFactory";

const EditContainer = ({ page }) => {
  const { id, section } = useParams();
  const sectionTitle = getSectionConstant({
    type: SECTION_CONSTANT_TYPE.TITLE,
    value: section,
  });

  const [data, setData] = useState({});
  const [fieldToIdMap, setFieldToIdMap] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingNextStep, setIsLoadingNextStep] = useState(false);
  const [errors, setErrors] = useState({});
  const [validators, setValidators] = useState({});
  const [notificationsItems, setNotificationItems] = useState([]);

  const {
    isHelperPanelOpen,
    helperPanelContent,
    setIsHelperPanelOpen,
    showHelperPanel,
  } = useHelpText();

  useEffect(async () => {
    document.title = DOMPurify.sanitize(
      `${TITLE} - ${id} - ${PAGE_TITLE[page]} - ${sectionTitle} - Edit`
    );
    try {
      const response = await retryApiCall({ callApi: getFundRequest(id) });
      //TODO: Add failure condition

      const {
        fundingTemplate: { fundingType, migrationPhase, program, subProgram },
      } = response;

      const newTemplate = getInternalTemplate({
        fundingType,
        migrationPhase,
        program,
      });

      const sections = Object.keys(newTemplate).reduce(
        (previous, templateName) => {
          if (templateName === SECTION_NAME.CASH_REQUEST_INFORMATION) {
            newTemplate[templateName].sections.push(SECTION.CASH_CLAIM_ACTUALS);
          }
          const sections = newTemplate[templateName].sections.map(
            (sectionName) => {
              return {
                ...schema[sectionName],
                fields: filterFields({
                  section: schema[sectionName],
                  program,
                }),
              };
            }
          );
          return [...previous, ...sections];
        },
        []
      );

      const fieldsSchemas = [];
      const createdSchemas = [];
      for (let section of sections) {
        for (let field of section.fields) {
          if (
            !field.auto &&
            !createdSchemas.includes(field.name) &&
            field.edit &&
            field.edit.includes(page)
          ) {
            const attributes = {
              component: field.component,
              fieldName: field.name,
              type: field.type || undefined,
              pattern: field.pattern || undefined,
              errorMessage: field.errorMessage || undefined,
            };
            createdSchemas.push(field.name);
            fieldsSchemas.push(createFieldSchemaObject(attributes));
          }
        }
      }

      const sectionSchemas = sections.map((section) =>
        createJsonSchemaObject({
          sectionName: section.name,
          fields: section.fields,
          program: program,
          page,
        })
      );

      setData({
        fundingType,
        program,
        subProgram,
        migrationPhase,
        fundRequestId: id,
        ...response,
      });
      setValidators(createValidator([...sectionSchemas, ...fieldsSchemas]));
      showHelperPanel({
        title: sectionTitle,
        keys: [section],
        open: false,
      });
      setIsLoading(false);
    } catch (e) {
      console.error(e);
    }
  }, []);

  const dataLoad = (data) => {
    setData((originalData) => {
      const fundRequestInfo = {
        fundRequestId: originalData["fundRequestId"],
        fundingType: originalData["fundingType"],
        program: originalData["program"],
        subProgram: originalData["subProgram"],
        migrationPhase: originalData["migrationPhase"],
        annualRunRateUnits: originalData?.fundingTemplate?.annualRunRate?.units,
      };
      return { ...fundRequestInfo, ...data };
    });
  };

  const dataInput = (inputData) => {
    setData((originalData) => ({ ...originalData, ...inputData }));
  };

  const updateErrors = (key, value) => {
    setErrors({
      ...errors,
      [key]: value,
    });
  };

  const Section = EditSectionContainerFactory({
    sectionTitle,
  });

  const Footer = FooterFactory({
    sectionTitle: sectionTitle,
  });

  return isLoading ? (
    <Loading />
  ) : (
    withAppLayout({
      component: (
        <OptionsProvider data={data}>
          <SpaceBetween size="l">
            <Section
              data={data}
              errors={errors}
              dataLoad={dataLoad}
              dataInput={dataInput}
              updateErrors={updateErrors}
              fieldToIdMap={fieldToIdMap}
              setFieldToIdMap={setFieldToIdMap}
              stepTitle={sectionTitle}
              validators={validators}
              setNotificationItems={setNotificationItems}
              page={page}
              showHelperPanel={showHelperPanel}
            />
            <Footer
              isLoadingNextStep={isLoadingNextStep}
              setIsLoadingNextStep={setIsLoadingNextStep}
              data={data}
              sectionTitle={sectionTitle}
              fieldToIdMap={fieldToIdMap}
              validators={validators}
              setNotificationItems={setNotificationItems}
              setErrors={setErrors}
              errors={errors}
              schema={schema}
              filterFields={filterFields}
              getTemplate={getInternalTemplate}
              dataSubmissionFactory={DataSubmissionFactory}
              page={page}
              returnPath={`/${page}/${id}/review`}
            />
          </SpaceBetween>
        </OptionsProvider>
      ),
      breadcrumbs: getBreadcrumbs({ text: `Edit` }),
      toolsContent: <HelperPanel content={helperPanelContent} />,
      isToolsOpen: isHelperPanelOpen,
      onToolsChange: ({ detail }) => {
        setIsHelperPanelOpen(detail.open);
      },
      notificationsItems: notificationsItems,
    })()
  );
};

EditContainer.propTypes = {
  page: PropTypes.string,
};

export default EditContainer;
