import React, { useEffect } from "react";
import {
  Container,
  ContentLayout,
  Header,
  SpaceBetween,
} from "@amzn/awsui-components-react";
import InputSectionFieldsFactory from "shared/programs/migration-acceleration-program/2024/fund-request/components/fields/input/InputSectionFieldsFactory";
import {
  MigrationDetailsEditSchema,
  MigrationDetailsEditSchemaWithVMware,
  MigrationDetailsEditSchemaWithVMwareAndVMwareMSP,
} from "internal/programs/migration-acceleration-program/2024/fund-request/components/edit/MigrationDetailsEditContainer/schema";
import { fetchFundRequest } from "internal/programs/migration-acceleration-program/2024/fund-request/utils/util";
import SystemErrorAlert from "shared/programs/migration-acceleration-program/2024/fund-request/components/common/SystemErrorAlert";
import { IEditContainerProps } from "internal/programs/migration-acceleration-program/2024/fund-request/types/SchemaType";
import { useParams } from "react-router-dom";
import { IGenericObject } from "shared/programs/migration-acceleration-program/2024/fund-request/types/CommonTypes";
import useFeatureFlag from "shared/util/hooks/useFeatureFlag";
import { FEATURE_FLAG_ENUMS } from "shared/util/constants/featureFlagValues";

const MigrationDetailsEditContainer = ({
  title,
  adapt,
  data,
  errors,
  onError,
  onChange,
  setData,
  formError,
}: IEditContainerProps) => {
  const { id } = useParams<IGenericObject>();
  const vmwareEnabledFlag = useFeatureFlag(
    FEATURE_FLAG_ENUMS.VMWARE_MAP_ENABLED
  );
  const vmwareMSPEnabledFlag = useFeatureFlag(
    FEATURE_FLAG_ENUMS.VMWARE_BONUS_MSP_OPTION_ENABLED
  );

  const getSchema = () => {
    if (vmwareMSPEnabledFlag && vmwareEnabledFlag) {
      return MigrationDetailsEditSchemaWithVMwareAndVMwareMSP;
    } else if (vmwareEnabledFlag) {
      return MigrationDetailsEditSchemaWithVMware;
    } else {
      return MigrationDetailsEditSchema;
    }
  };

  useEffect(() => {
    fetchFundRequest({ id, adapt, setData });
  }, []);

  return (
    <ContentLayout>
      <SpaceBetween size="l">
        <Container header={<Header variant="h2">{title}</Header>}>
          <InputSectionFieldsFactory
            data={data}
            errors={errors}
            onChange={onChange}
            onError={onError}
            schema={getSchema()}
          />
        </Container>
        <SystemErrorAlert isVisible={!!formError} error={formError} />
      </SpaceBetween>
    </ContentLayout>
  );
};

export default MigrationDetailsEditContainer;
