import React from "react";

import AppLayout from "@amzn/awsui-components-react/polaris/app-layout";
import BreadcrumbGroup from "@amzn/awsui-components-react/polaris/breadcrumb-group";
import MAPFundRequestDetails from "../../programs/migration-acceleration-program/2024/fund-request/views/details/MAPFundRequestDetails";
import { getFundRequestId } from "external/util/common/helper";
import NotificationsProvider from "shared/util/context/notifications/NotificationsContext";
import NotificationsFlashbar from "shared/components/common/notifications/NotificationsFlashbar";
import {
  HelpPanelContentContext,
  HelpPanelContext,
} from "shared/util/context/help/HelpContext";

const FundRequestDetailsPage = () => {
  const { isOpen, toggle } = React.useContext(HelpPanelContext);
  const { getHelpContent } = React.useContext(HelpPanelContentContext);
  const Panel = getHelpContent();

  return (
    <NotificationsProvider>
      <AppLayout
        breadcrumbs={
          <BreadcrumbGroup
            items={[
              { text: "Dashboard", href: "/dashboard" },
              { text: `${getFundRequestId()}`, href: "#" },
            ]}
          />
        }
        toolsOpen={isOpen}
        onToolsChange={toggle}
        tools={Panel}
        headerSelector=".navbar"
        content={<MAPFundRequestDetails />}
        notifications={<NotificationsFlashbar />}
        navigationHide
      />
    </NotificationsProvider>
  );
};

export default FundRequestDetailsPage;
