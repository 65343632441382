import React from "react";
import PropTypes from "prop-types";
import { FormField, SpaceBetween } from "@amzn/awsui-components-react";
import TextContent from "@amzn/awsui-components-react/polaris/text-content";
import Input from "@amzn/awsui-components-react/polaris/input";
import Textarea from "@amzn/awsui-components-react/polaris/textarea";
import { itemInputValidation, modalErrorMessage } from "../constant";
import { useParams } from "react-router-dom";
import { generateLengthConstraintText } from "../util";

const DeployModalForm = ({
  entityDetails,
  currentEntityVersion,
  item,
  setItem,
  errors,
  updateErrors,
}) => {
  const { entity } = useParams();

  const validateDeploy = (value) => {
    if (value.length > "deploy".length) {
      updateErrors({
        ...errors,
        deploy: modalErrorMessage.deploy,
      });
    } else {
      updateErrors({ ...errors, deploy: "" });
      setItem({ ...item, deploy: value });
    }
  };

  const validateReason = (value) => {
    if (value.length > itemInputValidation.reason.limit) {
      updateErrors({
        ...errors,
        reason: itemInputValidation.reason.message,
      });
    } else {
      updateErrors({ ...errors, reason: "" });
      setItem({ ...item, reason: value });
    }
  };

  return (
    <SpaceBetween size="l">
      <TextContent>
        <p>Are you sure you want to deploy the below Entity?</p>
        <p>
          Entity Name: {entity}
          <br></br>
          Current Version: {entityDetails.version}
          <br></br>
          Rollback to Version: {currentEntityVersion}
        </p>
      </TextContent>
      <FormField
        label={`Please type 'deploy' to confirm deployment of entity version '${currentEntityVersion}'`}
        errorText={errors.deploy}
      >
        <Input
          id="deploy"
          value={item.deploy}
          onChange={({ detail }) => validateDeploy(detail.value)}
        />
      </FormField>
      <FormField
        label={
          <span>
            Reason <i>- optional</i>
          </span>
        }
        constraintText={generateLengthConstraintText(
          item.reason,
          itemInputValidation.reason.limit
        )}
        errorText={errors.reason}
      >
        <Textarea
          id="reason"
          value={item.reason}
          onChange={({ detail }) => validateReason(detail.value)}
        />
      </FormField>
      <TextContent>
        <p>{`NOTE: Rollback can be performed on only latest '3' previous versions.`}</p>
      </TextContent>
    </SpaceBetween>
  );
};

DeployModalForm.propTypes = {
  entityDetails: PropTypes.object,
  currentEntityVersion: PropTypes.number,
  item: PropTypes.object,
  setItem: PropTypes.func,
  errors: PropTypes.object,
  updateErrors: PropTypes.func,
};

export default DeployModalForm;
