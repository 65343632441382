export const ROOT_ENTITY_NAME = "Configurations";
export const MAIN_TABLE_SIZE = 10;
export const MAIN_RESOURCE_NAME = "Self Service Entities";
export const ENTITY_TABLE_SIZE = 10;
export const ENTITY_HISTORY_TABLE_SIZE = 10;

export const Action = {
  CREATE: "create",
  EDIT: "edit",
  DELETE: "delete",
  DEPLOY: "deploy",
};

export const Item = {
  ID: "id",
  NAME: "name",
  DESCRIPTION: "description",
  PERMISSIONS: "permissions",
};
