import { useEffect, useState } from "react";
import { SelectProps } from "@amzn/awsui-components-react";
import {
  getCountries,
  loadCurrencies,
} from "external/util/services/data/FundRequestService";
import {
  USStates,
  locationSlashStateActivityExecuted,
  map2024MigrationPhase,
  ToolsUsedForGeneratingMigrationReport,
} from "shared/util/constants/staticOptions";
import { retryApiCall } from "shared/util/services/data/DataService";

export enum SelectOptionType {
  Countries = "Countries",
  States = "States",
  Currencies = "Currencies",
  MigrationPhase = "MigrationPhase",
  USStates = "UsStates",
  ToolsUsedForGeneratingMigrationReport = "ToolsUsedForGeneratingMigrationReport",
}

export const useSelectOptions = ({ type }: { type?: SelectOptionType }) => {
  const [isOptionsLoading, setIsOptionsLoading] = useState<boolean>(true);
  const [selectOptions, setSelectOptions] = useState<SelectProps.Option[]>([]);

  const optionHandlers = {
    [SelectOptionType.Countries]: loadCountries,
    [SelectOptionType.Currencies]: loadCurrencies,
    [SelectOptionType.States]: locationSlashStateActivityExecuted,
    [SelectOptionType.MigrationPhase]: map2024MigrationPhase,
    [SelectOptionType.USStates]: USStates,
    [SelectOptionType.ToolsUsedForGeneratingMigrationReport]:
      ToolsUsedForGeneratingMigrationReport,
  };

  const handleOptionType = async (type: SelectOptionType) => {
    const handle = optionHandlers[type];
    let options = [];
    options = typeof handle === "function" ? await handle() : handle;
    return options;
  };

  useEffect(() => {
    const getOption = async () => {
      try {
        if (!type) return;
        const options = await handleOptionType(type);
        setSelectOptions(options);
        setIsOptionsLoading(false);
      } catch (error) {
        console.error(error);
      } finally {
        setIsOptionsLoading(false);
      }
    };

    getOption();
  }, [type]);

  return { isOptionsLoading, selectOptions };
};

export const loadCountries = async () => {
  let countries = [];
  try {
    // @ts-expect-error Ignoring due to legacy JS code.
    const response = await retryApiCall({ callApi: getCountries() });
    response.items.sort(
      (a: { name: string }, b: { name: string }) => a.name > b.name
    );
    countries = response.items.map(
      (item: { name: string; countryISO2Code: string }) => {
        const countryName = item.name;
        const countryCode = item.countryISO2Code;
        return { label: countryName, value: countryCode };
      }
    );
  } catch (err) {
    console.error(err);
  }
  return countries;
};
