import {
  IClaim,
  IFundRequest,
  IMAPFundRequest,
} from "shared/programs/migration-acceleration-program/2024/fund-request/types/SchemaTypes";
import {
  extractClaimActualDetails,
  extractClaimBaseDetails,
  extractClaimIdToClaim,
  extractClaimInvoiceDetails,
  extractFundingDetails,
  extractFundRequestBaseDetails,
  extractInvoiceAddressDetails,
  extractMigrationEligibility,
  extractProjectDetailsFields,
  extractPurchaseOrderDetails,
  formatCurrency,
} from "shared/programs/migration-acceleration-program/2024/fund-request/api/adapters";
import { IGenericObject } from "shared/programs/migration-acceleration-program/2024/fund-request/types/CommonTypes";
import { IReview } from "shared/programs/migration-acceleration-program/2024/fund-request/types/FundRequestAPIModelTypes";
import { isWwps } from "shared/programs/migration-acceleration-program/2024/fund-request/utils/helper";

export const cashRequestDetailsAdapter = (
  apiResponse: IGenericObject
): ICashRequestDetails => {
  const fundRequest = apiResponse["fundRequest"];

  return {
    ...extractFundRequestBaseDetails(fundRequest),
    ...extractFundingDetails(fundRequest),
    ...extractPurchaseOrderDetails(fundRequest),
    ...extractInvoiceAddressDetails(fundRequest),
  };
};

export const migrationDetailsAdapter = (
  apiResponse: IGenericObject
): IMigrationDetails => {
  const fundRequest = apiResponse["fundRequest"];

  const modernizationIncentiveEligible =
    fundRequest["modernizationIncentiveEligible"];
  const migrationDetails = { modernizationIncentiveEligible };
  const vmwareAcknowledgement =
    fundRequest?.incentives?.vmware?.eligible || false;
  const vmwareMSPAcknowledgement =
    fundRequest?.incentives?.vmware?.bonusOption === "vmware_manage";

  return {
    ...extractFundRequestBaseDetails(fundRequest),
    ...extractMigrationEligibility(fundRequest),
    ...migrationDetails,
    vmwareAcknowledgement,
    vmwareMSPAcknowledgement,
  };
};

export const projectDetailsAdapter = (
  apiResponse: IGenericObject
): IProjectDetails => {
  const fundRequest = apiResponse["fundRequest"];

  if (!fundRequest) return {};

  let projectDetails = {};
  const greenfieldIncentiveEligible =
    fundRequest?.incentives?.greenfield?.eligible || false;
  const vmwareAcknowledgement =
    fundRequest?.incentives?.vmware?.eligible || false;
  const numberOfVmwareToMigrate =
    fundRequest?.incentives?.vmware?.numberOfVmwareToMigrate;
  const totalNumberOfWorkloadsInUse =
    fundRequest?.incentives?.vmware?.totalNumberOfWorkloadsInUse;

  projectDetails = {
    greenfieldIncentiveEligible,
    vmwareAcknowledgement,
    numberOfVmwareToMigrate,
    totalNumberOfWorkloadsInUse,
  };
  const owner = fundRequest["owner"];

  if (owner) {
    const fundRequestOwner = owner["partnerName"];
    projectDetails = {
      ...projectDetails,
      fundRequestOwner,
    };
  }

  const { opportunity } = getOpportunity(fundRequest);
  const programEngagementId = opportunity?.primaryMigrationEngagementId;

  return {
    ...extractFundRequestBaseDetails(fundRequest),
    ...extractProjectDetailsFields(fundRequest),
    ...projectDetails,
    programEngagementId,
  };
};

export const claimInvoiceAdapter = (
  apiResponse: IGenericObject,
  claimId?: string
): IClaimInvoiceDetails => {
  let claimInvoiceDetails = {};
  const fundRequest = apiResponse["fundRequest"];
  if (!fundRequest) return {};

  const project = fundRequest["project"];
  if (project) {
    const projectStartDate = project["startDate"];
    claimInvoiceDetails = {
      ...claimInvoiceDetails,
      projectStartDate,
    };
  }

  const claims = apiResponse["claims"];
  if (claims && claimId) {
    const currentClaim = claims[claimId];

    if (currentClaim) {
      claimInvoiceDetails = {
        ...extractFundRequestBaseDetails(fundRequest),
        ...claimInvoiceDetails,
        ...extractClaimBaseDetails(currentClaim),
        ...extractClaimInvoiceDetails(currentClaim),
        ...extractClaimActualDetails(currentClaim),
        ...extractClaims(fundRequest, claims),
      };
    }
  }

  return claimInvoiceDetails;
};

export const attachmentsAdapter = (
  apiResponse: IGenericObject
): IAttachments => {
  const fundRequest: IMAPFundRequest = apiResponse["fundRequest"];

  const attachments = { artifacts: fundRequest.artifacts };
  const activity_name =
    fundRequest["project"] && fundRequest["project"]["activityName"];
  const business_description =
    fundRequest["project"] && fundRequest["project"]["description"];
  return {
    ...extractFundRequestBaseDetails(fundRequest),
    ...attachments,
    activityName: activity_name,
    businessDescription: business_description,
  };
};

const extractProjectDetails = (fundRequest: IFundRequest) => {
  const project = fundRequest["project"];

  let projectDetails: IGenericObject = {};
  if (project) {
    const activityName = project["activityName"];
    const description = project["description"];
    const plannedStartDate = project["startDate"];
    const plannedEndDate = project["endDate"];
    const fundingExpirationDate = project["expirationDate"];
    const awsAccountId = project["awsAccountId"];

    const location = project["location"];
    const executedCountry = location["country"];
    const locationGeo = location["locationGeo"];

    const states = location["states"];
    const executedState = Array.isArray(states) ? states.join(",") : states;

    const artifacts = fundRequest && fundRequest["artifacts"];
    const consents = artifacts && artifacts["consents"];
    const extensions = project["extensions"];
    if (consents && consents.length > 0) {
      const [wwpsConsent] = consents.filter(
        (consent: IGenericObject) => consent.name === "WWPS"
      );
      projectDetails["wwpsConsent"] = !!wwpsConsent;
    }

    const greenfieldIncentiveEligible = fundRequest?.incentives?.greenfield
      ?.eligible
      ? "Yes"
      : "No";

    const vmwareAcknowledgement =
      fundRequest?.incentives?.vmware?.eligible || false;

    const numberOfVmwareToMigrate =
      fundRequest?.incentives?.vmware?.numberOfVmwareToMigrate;
    const totalNumberOfWorkloadsInUse =
      fundRequest?.incentives?.vmware?.totalNumberOfWorkloadsInUse;

    let extendedStartDate;
    let extendedEndDate;
    let extensionReason;
    if (extensions && extensions.length > 0) {
      const latestExtension = extensions.at(-1);
      extendedStartDate = latestExtension?.startDate;
      extendedEndDate = latestExtension?.endDate;
      extensionReason = latestExtension["reason"];
    }

    projectDetails = {
      ...projectDetails,
      activityName,
      description,
      plannedStartDate,
      plannedEndDate,
      extendedStartDate,
      fundingExpirationDate,
      extendedEndDate,
      awsAccountId,
      executedCountry,
      extensionReason,
      locationGeo,
      executedState,
      greenfieldIncentiveEligible,
      vmwareAcknowledgement,
      numberOfVmwareToMigrate,
      totalNumberOfWorkloadsInUse,
    };
  }
  return {
    projectDetails,
  };
};

const getOpportunity = (fundRequest: IFundRequest) => {
  const opportunities = fundRequest["opportunities"];
  const aceOpportunityId = opportunities && Object.keys(opportunities)[0];
  const opportunity =
    opportunities && aceOpportunityId && opportunities[aceOpportunityId];
  return {
    aceOpportunityId,
    opportunity,
  };
};

const extractOpportunity = (fundRequest: IFundRequest) => {
  const { aceOpportunityId, opportunity } = getOpportunity(fundRequest);

  const record = opportunity?.record;
  record["isWwps"] = isWwps(record) ? "Yes" : "No";

  const programEngagementId = opportunity?.primaryMigrationEngagementId;

  const migrationProgramEngagements = opportunity?.migrationEngagements.map(
    (engagement: IGenericObject) => ({
      engagementId: engagement?.engagementId,
      estimatedMigrationArr: formatCurrency(
        engagement?.estimatedMigrationArrUsdAmount
      ),
      privateEquityBacked: engagement?.sfdcPrivateEquityBacking,
      deliveredBy: engagement?.migrationWorkflowDeliveredBy,
      olaCompletionDate: engagement?.migrationWorkflowOlaCompletionDate,
      //TODO: Verify
      recordStatus: engagement?.awsinvRequestStatus,
      consultingPartnerEngagement: engagement?.partnerPartnerEngagementType,
      workshopCompletionDate:
        engagement?.migrationWorkflowWorkshopCompletionDate,
      readoutPresentationDate:
        engagement?.migrationWorkflowReadoutPresentationDate,
      olaConfirmed: engagement?.migratrionWorkflowOlaCompletionConfirmed,
      engagementStatus: engagement?.status,
      agreementCategory: engagement?.migrationEngagementAgreementCategory,
      //TODO: Verify
      readinessAssessmentStatus: engagement?.readiness,
      mraMethodology: engagement?.migrationWorkflowMraMethodology,
      olaStatus: engagement?.migrationWorkflowOlaStatus,
    })
  );
  const vmwareAcknowledgement =
    fundRequest?.incentives?.vmware?.eligible || false;

  return {
    aceOpportunityId,
    opportunity: record,
    migrationProgramEngagements,
    programEngagementId,
    vmwareAcknowledgement,
  };
};

const extractClaims = (
  fundRequest: IFundRequest,
  claimsList: IGenericObject
) => {
  let claims: IClaim[] = [];
  const fundingDetails = fundRequest && fundRequest["fundingDetails"];
  const projectDetails = fundRequest && fundRequest["project"];
  const claimPlans = fundingDetails && fundingDetails["claimPlans"];

  if (!claimPlans) return { claims };

  Object.keys(claimPlans).forEach((claimId: string) => {
    const plan = claimPlans[claimId];
    const claim = claimsList[claimId];
    const projectStartDate = projectDetails && projectDetails?.startDate;
    const claimDueDate = plan && plan?.dueDate;
    const claimAmount = plan && plan?.localAmount;
    const claimPriority = claim && claim?.priority;
    const claimStatus = claim && claim?.status;
    const claimStage = claim && claim?.stage;
    const vmwareClaim = plan?.properties?.vmwareBonus !== undefined;
    const numberOfVmwareToMigrateFromPlan =
      plan?.properties?.vmwareBonus?.numberOfVmwareToMigrateCap;
    const totalNumberOfWorkloadsInUseFromPlan =
      plan?.properties?.vmwareBonus?.totalNumberOfWorkloadsInUseCap;
    const numberOfVmwareToMigrate =
      claim?.properties?.vmwareBonus?.numberOfVmwareToMigrate ||
      numberOfVmwareToMigrateFromPlan;
    const totalNumberOfWorkloadsInUse =
      claim?.properties?.vmwareBonus?.totalNumberOfWorkloadsInUse ||
      totalNumberOfWorkloadsInUseFromPlan;
    const toolsUsedForGeneratingMigrationReport =
      claim?.properties?.vmwareBonus?.toolsUsedForGeneratingMigrationReport &&
      claim?.properties?.vmwareBonus?.toolsUsedForGeneratingMigrationReport.join(
        ","
      );

    claims.push({
      ...extractClaimBaseDetails(claimsList[claimId]),
      ...extractClaimActualDetails(claimsList[claimId]),
      invoice: extractClaimInvoiceDetails(claimsList[claimId]),
      claimId,
      claimPriority,
      claimStatus,
      claimStage,
      projectStartDate,
      claimDueDate,
      claimAmount,
      vmwareClaim,
      numberOfVmwareToMigrate,
      totalNumberOfWorkloadsInUse,
      toolsUsedForGeneratingMigrationReport,
    });
  });
  claims = [...claims].sort(
    (a: IClaim, b: IClaim) => a.claimPriority - b.claimPriority
  );
  return {
    claims,
  };
};

const extractArtifacts = (fundRequest: IFundRequest) => {
  const artifacts = fundRequest["artifacts"];
  const pricingCalculatorLinks = artifacts["pricingCalculatorLinks"];
  let awsCalculatorUrl = "";

  if (pricingCalculatorLinks.length > 0) {
    awsCalculatorUrl = pricingCalculatorLinks.join(",");
  }
  return {
    artifacts,
    awsCalculatorUrl,
  };
};

const extractInternalFundRequestBaseDetails = (fundRequest: IFundRequest) => {
  const assignee = fundRequest?.assignee;

  return {
    assignee,
  };
};

const extractApprovalDetails = (fundRequest: IFundRequest) => {
  const approvals = fundRequest?.approvalDetails;

  const approvalDetails = {
    globalFundingOps: approvals?.globalFundingOps,
    approvalSubmissionDate: approvals?.submissionDate,
    approvalDate: approvals?.approvalDate,
    exceptionApproval: approvals?.exceptionApproval,
    exceptionComment: approvals?.exceptionComment,
    externalApprovalSystemId: approvals?.externalApprovalSystemId,
  };

  return {
    approvalDetails,
  };
};

const extractPartnerDetails = (fundRequest: IFundRequest) => {
  const partner = fundRequest?.partner;

  const partnerDetails = {
    apnPartner: partner?.name,
    accountGeo: partner?.accountGeo,
    accountSubRegion: partner?.accountSubRegion,
    vendorId: partner?.vendorId,
    spmsId: partner?.spmsId,
    gsiAccount: partner?.gsiAccount,
    strategicCollaborationAgreement:
      partner?.hasStrategicCollaborationAgreement,
    fundingAgreementType: partner?.fundingAgreementType,
    contact: partner?.contact,
    contactEmail: partner?.email,
    contactTitle: partner?.title,
    contactPhone: partner?.phone,
  };

  return {
    partnerDetails,
  };
};

export const reviewAdapter = (apiResponse: IGenericObject): IReview => {
  const fundRequest = apiResponse["fundRequest"];
  const claims = apiResponse["claims"];
  if (!fundRequest) return {};
  if (!claims) return {};

  return {
    ...extractFundRequestBaseDetails(fundRequest),
    ...extractInternalFundRequestBaseDetails(fundRequest),
    ...extractOpportunity(fundRequest),
    ...extractProjectDetails(fundRequest),
    ...extractClaims(fundRequest, claims),
    ...extractMigrationEligibility(fundRequest),
    ...extractFundingDetails(fundRequest),
    ...extractPurchaseOrderDetails(fundRequest),
    ...extractArtifacts(fundRequest),
    ...extractApprovalDetails(fundRequest),
    ...extractPartnerDetails(fundRequest),
    ...extractClaimIdToClaim(apiResponse),
  };
};

export interface ICashRequestDetails extends IMAPFundRequest {}

export interface IProjectDetails extends IMAPFundRequest {}

export interface IAttachments extends IMAPFundRequest {}

export interface IMigrationDetails extends IMAPFundRequest {}

export interface IClaimInvoiceDetails extends IMAPFundRequest {}
