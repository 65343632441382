import React from "react";
import { ProjectInformationReviewSectionContainer } from "../../../../shared/components/Review/ProjectInformationReviewSectionContainer";
import { ValueFactory } from "./ValueFactory";
import { filterFields, schema } from "../../../config/schema";

import PropTypes from "prop-types";
import { DefaultSectionHeader } from "./SectionHeader/DefaultSectionHeader";

export const InternalProjectInformationReviewSectionContainer = ({
  fundingData,
  index,
  step,
  template,
}) => (
  <ProjectInformationReviewSectionContainer
    fundingData={fundingData}
    index={index}
    step={step}
    template={template}
    valueFactory={ValueFactory}
    schema={schema}
    filterFields={filterFields}
    header={
      <DefaultSectionHeader
        title={template[step].title}
        name={template[step].name}
        template={template}
        stage={fundingData.stage}
        status={fundingData.status}
        program={fundingData.program}
      />
    }
  />
);

InternalProjectInformationReviewSectionContainer.propTypes = {
  fundingData: PropTypes.object,
  index: PropTypes.number,
  step: PropTypes.array,
  template: PropTypes.object,
};
