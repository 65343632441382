import { decode } from "jsonwebtoken";
import { checkAuthTokenExists } from "../../../../shared/util/services/auth/AuthService";
import SessionManager from "../../../../shared/util/services/auth/SessionManager";
import { isJsonString } from "../../../../shared/util/common/helper";

export const getInternalRoles = () => {
  let role = [];

  if (checkAuthTokenExists()) {
    const decodedIdToken = decode(SessionManager.getIdToken(), { json: true });
    const tokenRole = decodedIdToken["custom:Role"];
    const splitTokenRole = tokenRole.split(",");
    role = [...splitTokenRole];
  }
  return role;
};

export const getUsername = () => {
  let username = "";
  try {
    if (SessionManager.getIdToken()) {
      const decodedIdToken = decode(SessionManager.getIdToken(), {
        json: true,
      });
      const { identities } = decodedIdToken;
      username = identities[0].userId;
    }
  } catch (e) {
    console.log(e);
  }
  return username;
};

export const getProfile = () => {
  let profiles = [];
  const decodedIdToken = decode(SessionManager.getIdToken(), { json: true });
  const { profile } = decodedIdToken;

  if (profile && isJsonString(profile)) {
    profiles = JSON.parse(profile);
  }
  // de-duplicate profiles array
  let uniqueProfiles = [...new Set(profiles)];
  return uniqueProfiles;
};
