import ReadOnlyArtifacts from "external/programs/migration-acceleration-program/2024/fund-request/components/shared/sections/read-only/Artifacts/ReadOnlyArtifacts";
import React, { useEffect, useState } from "react";
import Button from "@amzn/awsui-components-react/polaris/button";
import { hasError, retryApiCall } from "shared/util/services/data/DataService";
import { getFundRequestAttachments } from "external/util/services/data/FundRequestService";
import { FileScanStatus } from "shared/components/common/file-status/FileScanStatus";
import { longLocalDateFormat } from "shared/util/common/date";

import Link from "@amzn/awsui-components-react/polaris/link";
import { IDetailsProps } from "shared/programs/migration-acceleration-program/2024/fund-request/types/SchemaTypes";
import { IGenericObject } from "shared/programs/migration-acceleration-program/2024/fund-request/types/CommonTypes";
import { handleApiError } from "shared/util/api/util";

const ArtifactsReview = ({ ...props }: IDetailsProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [artifacts, setArtifacts] = useState<IGenericObject[]>([]);

  useEffect(() => {
    retrieveFiles();
  }, [props.data]);
  const retrieveFiles = async () => {
    setIsLoading(true);
    let retrievedFiles: IGenericObject[] = [];
    let nextPageToken = "";
    while (nextPageToken != null) {
      try {
        // @ts-expect-error Still in javascript, so the implementation details don't matter
        const response = await retryApiCall({
          callApi: getFundRequestAttachments(nextPageToken),
        });
        if (hasError(response)) {
          return handleApiError(response.errorType)(response);
        }

        const newFiles = response.items.map((file: IGenericObject) => {
          const associatedStep = file.fundClaimId
            ? "Fund Claim"
            : "Fund Request";
          const fileScanStatus = <FileScanStatus status={file.fileStatus} />;
          const uploadDate = longLocalDateFormat(file.lastUpdatedOn.timestamp);

          return {
            id: file.fileId,
            artifact: file.fileName,
            artifactType: file.fileType,
            fileType: file.fileName.split(".").slice(-1),
            associatedStep: associatedStep,
            scanStatus: fileScanStatus,
            uploadDate: uploadDate,
          };
        });
        retrievedFiles = retrievedFiles.concat(newFiles);
        nextPageToken = response.nextPageToken;
      } catch (err) {
        console.error(err);
        break;
      } finally {
        setIsLoading(false);
      }
    }
    const { data } = props;
    const artifactsData = data && data["artifacts"];
    const urlsData = artifactsData && artifactsData["pricingCalculatorLinks"];
    let urls = [];

    if (urlsData) {
      urls = urlsData.map((url: string) => {
        return {
          artifact: (
            <Link external href={url}>
              {url}
            </Link>
          ),
          artifactType: "AWS Pricing Calculator",
          fileType: "-",
          associatedStep: "Fund Request",
          scanStatus: "-",
          uploadDate: "-",
        };
      });
    }
    const newArtifacts = retrievedFiles.concat(urls);
    setArtifacts(newArtifacts);
  };

  const artifactProps = {
    artifacts,
    isLoading,
  };

  return (
    <ReadOnlyArtifacts
      {...props}
      {...artifactProps}
      actions={
        <Button
          iconName={"refresh"}
          loading={isLoading}
          onClick={retrieveFiles}
        >
          Refresh scan status
        </Button>
      }
    />
  );
};

export default ArtifactsReview;
