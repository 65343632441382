import React from "react";

import "./style.css";

const Error = () => {
  return (
    <div className="error--message">
      An error has occurred! An administrator has been notified.
    </div>
  );
};

export default Error;
