import React from "react";
import { Header, SpaceBetween } from "@amzn/awsui-components-react";
import PropTypes from "prop-types";
import Button from "@amzn/awsui-components-react/polaris/button";
import AuditButton from "../Audit/AuditButton";
import { PAGES } from "../../../util/constants/pages";
import { Extend } from "../Extend";

export const DefaultReviewHeader = ({
  fundRequestId,
  refresh,
  stage,
  page,
  program,
  setNotificationsItems,
}) => {
  return (
    <React.Fragment>
      <Header
        variant="h1"
        description={`Fund Request Id: ${fundRequestId}`}
        actions={
          <React.Fragment>
            <SpaceBetween size="s" direction="horizontal">
              <Button
                iconName="refresh"
                variant="normal"
                onClick={async () => await refresh()}
              />
              {page !== PAGES.CASH_CLAIM_APPROVAL ? (
                <AuditButton
                  fundRequestId={fundRequestId}
                  page={page}
                  setNotificationsItems={setNotificationsItems}
                />
              ) : (
                ""
              )}
              <Extend
                fundRequests={[{ fundRequestId: fundRequestId, stage: stage }]}
                setNotificationsItems={setNotificationsItems}
              />
            </SpaceBetween>
          </React.Fragment>
        }
      >
        {program}
      </Header>
    </React.Fragment>
  );
};

DefaultReviewHeader.propTypes = {
  fundRequestId: PropTypes.string,
  refresh: PropTypes.func,
  stage: PropTypes.string,
  page: PropTypes.string,
  program: PropTypes.string,
  setNotificationsItems: PropTypes.func,
};
