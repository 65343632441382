import React, { useState } from "react";
import Wizard from "@amzn/awsui-components-react/polaris/wizard";
import { FundRequestSteps } from "external/programs/migration-acceleration-program/2024/fund-request/types/SchemaTypes";
import { submit } from "external/programs/migration-acceleration-program/2024/fund-request/components/create/wizard/api/onSubmit";
import { useHistory } from "react-router-dom";
import { IMAPFundRequest } from "shared/programs/migration-acceleration-program/2024/fund-request/types/SchemaTypes";
import { handleResultError } from "external/programs/migration-acceleration-program/2024/fund-request/components/create/wizard/util";
import { RouteOrigin } from "shared/util/constants/RouteState";
import {
  Error as ResultError,
  Result,
  handleErrors,
  ResultErrorType,
} from "shared/util/api/util";
import { IGenericObject } from "shared/programs/migration-acceleration-program/2024/fund-request/types/CommonTypes";

const FundRequestWizardContainer = ({
  activeStepIndex,
  setActiveStepIndex,
  steps,
  data,
  formError,
  setFormError,
  errors,
  setErrors,
}: {
  activeStepIndex: number;
  setActiveStepIndex: (index: number) => void;
  steps: FundRequestSteps[];
  data: IMAPFundRequest;
  formError: string;
  setFormError: React.Dispatch<React.SetStateAction<string>>;
  errors: IGenericObject;
  setErrors: React.Dispatch<React.SetStateAction<IGenericObject>>;
}) => {
  const [isLoadingNextStep, setIsLoadingNextStep] = useState<boolean>(false);

  const history = useHistory();
  return (
    <Wizard
      i18nStrings={{
        stepNumberLabel: (stepNumber) => `Step ${stepNumber}`,
        optional: "optional",
        cancelButton: "Cancel",
        previousButton: "Previous",
        nextButton: "Next",
        submitButton: "Submit fund request",
      }}
      isLoadingNextStep={isLoadingNextStep}
      onNavigate={async ({ detail }) => {
        try {
          setIsLoadingNextStep(true);
          if (errors) setErrors({});
          if (formError) setFormError("");

          const step = steps[activeStepIndex];
          if (detail.reason !== "previous") {
            const submit = step.onSubmit;
            const result = submit && (await submit(data));
            if (result && !result.success) {
              const errorResult = result as Result<
                ResultError<ResultErrorType>
              >;
              handleErrors({
                result: errorResult,
                setErrors: setErrors,
                setFormError: setFormError,
              });
              return;
            }
          }
          const newStepIndex = detail.requestedStepIndex;
          setActiveStepIndex(newStepIndex);
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoadingNextStep(false);
        }
      }}
      activeStepIndex={activeStepIndex}
      steps={steps}
      onSubmit={async () => {
        try {
          setIsLoadingNextStep(true);
          if (formError) setFormError("");

          const result = await submit();

          if (!result.success) {
            handleResultError({ result })({ result, setError: setFormError });

            throw new Error(
              "There was an issue attempting to submit the fund request."
            );
          }

          history.push(`/fund-requests/${data.fundRequestId}/details`, {
            origin: RouteOrigin.Wizard,
          });
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoadingNextStep(false);
        }
      }}
      onCancel={() => {
        history.push(`/fund-request/select-program?template=MAP`);
      }}
    />
  );
};

export default FundRequestWizardContainer;
