import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import { Box, SpaceBetween } from "@amzn/awsui-components-react";
import Button from "@amzn/awsui-components-react/polaris/button";
import {
  hasError,
  retryApiCall,
} from "../../../../../../../shared/util/services/data/DataService";
import { addSelfServiceEntityEvent } from "../../../../../../util/services/data/InternalDataService";
import {
  setErrorMessage,
  setSuccessMessage,
} from "../../../../../../../shared/util/common/helper";
import { Action, ROOT_ENTITY_NAME } from "../../../../constant";
import DeleteModalForm from "./ModalForms/DeleteModalForm";
import EditModalForm from "./ModalForms/EditModalForm";
import { ConstructPayload } from "../../../../JsonPatch/jsonpatch";
import {
  itemInputValidation,
  modalErrorMessage,
  getItemSuccessNotificationContent,
  getModalHeaderName,
  getSubmitButtonName,
} from "./constant";
import { ConvertStringToPascalCase } from "../../../../../../util/helper";

const ItemsModal = ({
  refresh,
  isModalVisible,
  updateModalVisibility,
  action,
  item,
  setItem,
  entityDetails,
  setNotifications,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errors, updateErrors] = useState({});

  useEffect(() => {
    return () => {
      // Cleanup function to reset state when the component unmounts
      setItem({});
      updateErrors({});
    };
  }, [setItem, updateErrors]);

  const validateItem = () => {
    if (action === Action.CREATE || action === Action.EDIT) {
      return validateItemCreateEdit(item);
    }

    if (action === Action.DELETE) {
      return validateItemDelete(item);
    }
  };

  const validateItemCreateEdit = (item) => {
    if (!item.name && !item.value) {
      updateErrors({
        ...errors,
        name: modalErrorMessage.required,
        value: modalErrorMessage.required,
      });
      return false;
    }
    if (!item.name) {
      updateErrors({
        ...errors,
        name: modalErrorMessage.required,
      });
      return false;
    }
    if (!item.value) {
      updateErrors({
        ...errors,
        value: modalErrorMessage.required,
      });
      return false;
    }

    if (
      entityDetails.entityType === ROOT_ENTITY_NAME &&
      item.name !== ConvertStringToPascalCase(item.name)
    ) {
      updateErrors({
        ...errors,
        name: modalErrorMessage.invalidEntity,
      });
      return false;
    }

    return !(
      item.name?.length > itemInputValidation.name.limit ||
      item.value?.length > itemInputValidation.value.limit ||
      item.reason?.length > itemInputValidation.reason.limit
    );
  };

  const validateItemDelete = (item) => {
    if (item.delete !== Action.DELETE) {
      updateErrors({
        ...errors,
        delete: modalErrorMessage.delete,
      });
      return false;
    }
    return (
      !item.reason || item.reason?.length <= itemInputValidation.reason.limit
    );
  };

  const submitModal = async () => {
    const isValidItem = validateItem();
    if (isValidItem) {
      setIsLoading(true);
      try {
        const payload = ConstructPayload(entityDetails, action, item);
        const response = await retryApiCall({
          callApi: addSelfServiceEntityEvent({
            entityType: entityDetails.entityType,
            body: payload,
          }),
        });

        if (response && hasError(response)) {
          console.log(response);
          setErrorMessage({
            setNotificationsItems: setNotifications,
            content: JSON.stringify(response.errors),
            status: response.status,
          });
          setIsLoading(false);
          updateModalVisibility(false);
          return;
        }
        setIsLoading(false);
        updateModalVisibility(false);
      } catch (e) {
        console.error(e);
        setErrorMessage({
          setNotificationsItems: setNotifications,
        });
        setIsLoading(false);
        updateModalVisibility(false);
      }
      setSuccessMessage(
        setNotifications,
        "Submitted",
        getItemSuccessNotificationContent[action]
      );
      refresh();
    }
  };

  const getForm = () => {
    if (action === Action.CREATE || action === Action.EDIT) {
      return (
        <EditModalForm
          entityDetails={entityDetails}
          item={item}
          setItem={setItem}
          errors={errors}
          updateErrors={updateErrors}
        />
      );
    }
    if (action === Action.DELETE) {
      return (
        <DeleteModalForm
          item={item}
          setItem={setItem}
          errors={errors}
          updateErrors={updateErrors}
        />
      );
    }
  };

  return (
    <Modal
      onDismiss={() => {
        setItem({});
        updateErrors({});
        updateModalVisibility(false);
      }}
      visible={isModalVisible}
      closeAriaLabel="Close modal"
      size="large"
      header={getModalHeaderName[action]}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              onClick={() => {
                setItem({});
                updateErrors({});
                updateModalVisibility(false);
              }}
            >
              Cancel
            </Button>
            <Button
              loading={isLoading}
              variant="primary"
              onClick={async () => {
                await submitModal();
              }}
            >
              {getSubmitButtonName[action]}
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      {getForm()}
    </Modal>
  );
};

ItemsModal.propTypes = {
  refresh: PropTypes.func,
  isModalVisible: PropTypes.bool,
  updateModalVisibility: PropTypes.func,
  action: PropTypes.string,
  entityDetails: PropTypes.object,
  item: PropTypes.object,
  setItem: PropTypes.func,
  setNotifications: PropTypes.func,
};

export default ItemsModal;
