import React, { useState } from "react";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import { Box, FormField, SpaceBetween } from "@amzn/awsui-components-react";
import Button from "@amzn/awsui-components-react/polaris/button";
import PropTypes from "prop-types";
import Select from "@amzn/awsui-components-react/polaris/select";
import { ASSIGNEE_TYPE } from "../Header/util";
import Textarea from "@amzn/awsui-components-react/polaris/textarea";
import AssigneeSelection from "../AssigneeSelection";

const ChangeAssignee = ({
  isLoading,
  isVisible,
  submitChangeAssignee,
  updateVisibility,
}) => {
  const [newAssignee, setNewAssignee] = useState("");
  const [assigneeType, setAssigneeType] = useState("Person");
  const [internalComment, setInternalComment] = useState("");
  const [error, setNewError] = useState("");

  return (
    <Modal
      id="change-assignee-modal"
      onDismiss={() => updateVisibility("changeAssignee", false)}
      visible={isVisible}
      closeAriaLabel="Close modal"
      size="large"
      header="Change Assignee"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              onClick={() => {
                updateVisibility("changeAssignee", false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              disabled={!newAssignee || error}
              loading={isLoading}
              onClick={async () => {
                await submitChangeAssignee(
                  newAssignee,
                  assigneeType,
                  internalComment
                );
              }}
            >
              Submit
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <SpaceBetween size="l">
        <FormField
          label="Please enter an alias, employee name, or team."
          errorText={error}
          description="Note: The search will only show full time Amazon employees. If a user does not show up, please enter their alias and submit."
        >
          <AssigneeSelection
            assigneeType={assigneeType}
            setAssignee={setNewAssignee}
            setError={setNewError}
          />
        </FormField>

        <FormField label="Please select assignee type.">
          <Select
            id="assigneeType"
            selectedOption={{
              label: assigneeType,
              value: assigneeType,
            }}
            className="step-details-field"
            placeholder="Choose an option"
            filteringType="auto"
            onChange={({ detail }) => {
              console.log(detail.selectedOption.value);
              setAssigneeType(detail.selectedOption.value);
            }}
            options={[
              {
                label: ASSIGNEE_TYPE.person,
                value: ASSIGNEE_TYPE.person,
              },
              {
                label: ASSIGNEE_TYPE.team,
                value: ASSIGNEE_TYPE.team,
              },
            ]}
          />
        </FormField>

        <FormField label="Please enter an internal comment.">
          <Textarea
            id="internal-comment"
            value={internalComment}
            onChange={({ detail }) => {
              setInternalComment(detail.value);
            }}
          />
        </FormField>
      </SpaceBetween>
    </Modal>
  );
};

ChangeAssignee.propTypes = {
  isLoading: PropTypes.bool,
  isVisible: PropTypes.bool,
  submitChangeAssignee: PropTypes.func,
  updateVisibility: PropTypes.func,
};

export default ChangeAssignee;
