import React, { useEffect } from "react";
import { ContentLayout, SpaceBetween } from "@amzn/awsui-components-react";
import AWSPricingCalculatorSection from "./Sections/AWSPricingCalculatorSection";
import MigrationScopeChecklistSection from "./Sections/MigrationScopeChecklistSection";
import AdditionalArtifactsSection from "./Sections/AdditionalArtifactsSection";
import { fetchFundRequest } from "external/programs/migration-acceleration-program/2024/fund-request/components/create/wizard/util";
import { useParams } from "react-router-dom";
import SystemErrorAlert from "shared/programs/migration-acceleration-program/2024/fund-request/components/common/SystemErrorAlert";
import { IStepStateProps } from "external/programs/migration-acceleration-program/2024/fund-request/types/WizardTypes";
import { IGenericObject } from "shared/programs/migration-acceleration-program/2024/fund-request/types/CommonTypes";
import { HelpPanelContentContext } from "shared/util/context/help/HelpContext";
import VmwareAssessmentReport from "./Sections/VmwareAssessmentReport";
import useFeatureFlag from "shared/util/hooks/useFeatureFlag";
import { FEATURE_FLAG_ENUMS } from "shared/util/constants/featureFlagValues";
import ConditionalField from "shared/components/common/Conditional/ConditionalField";

const Artifacts = ({
  adapt,
  data,
  formError,
  onChange,
  setData,
}: IStepStateProps) => {
  const { id } = useParams<IGenericObject>();
  const vmwareEnabledFlag = useFeatureFlag(
    FEATURE_FLAG_ENUMS.VMWARE_MAP_ENABLED
  );
  const vmwareAcknowledgement = data?.vmwareAcknowledgement;
  const { setKey } = React.useContext(HelpPanelContentContext);

  useEffect(() => {
    setKey("wizard.supportingDocumentation.page");
    refresh();
  }, []);

  const refresh = async () => {
    await fetchFundRequest({ id, adapt, setData });
  };

  return (
    <ContentLayout>
      <SpaceBetween size="l">
        <AWSPricingCalculatorSection data={data} onChange={onChange} />
        <MigrationScopeChecklistSection data={data && data["artifacts"]} />
        <ConditionalField
          showField={vmwareEnabledFlag && vmwareAcknowledgement}
        >
          <VmwareAssessmentReport data={data && data["artifacts"]} />
        </ConditionalField>
        <AdditionalArtifactsSection data={data && data["artifacts"]} />
        <SystemErrorAlert isVisible={!!formError} error={formError} />
      </SpaceBetween>
    </ContentLayout>
  );
};

export default Artifacts;
