import { CASH_CLAIM_STAGE } from "../../../../../util/constants/fundRequestStatusType";

export const claimStagesKeys = {
  partner: "partner",
  claim_approval: "claim_approval",
  invoice: "invoice",
  completed: "completed",
};

export const claimStages = {
  [claimStagesKeys.partner]: "Partner",
  [claimStagesKeys.claim_approval]: "Claim approval",
  [claimStagesKeys.invoice]: "Invoice",
  [claimStagesKeys.completed]: "Completed",
};

export const showFieldByStage = ({
  stage,
  visibleStage,
}: {
  stage: string;
  visibleStage: string;
}) => {
  if (!stage) return false;

  const stages = Object.keys(claimStages);
  const currentStageIndex = stages.indexOf(stage);
  const visibleStageIndex = stages.indexOf(visibleStage);

  return currentStageIndex >= visibleStageIndex;
};

export const POST_CLAIM_SUBMITTED_STAGES = [
  CASH_CLAIM_STAGE.CLAIM_APPROVAL,
  CASH_CLAIM_STAGE.INVOICE,
  CASH_CLAIM_STAGE.COMPLETED,
];
