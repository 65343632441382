import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useCollection } from "@amzn/awsui-collection-hooks";
import Table from "@amzn/awsui-components-react/polaris/table";
import Pagination from "@amzn/awsui-components-react/polaris/pagination";
import Grid from "@amzn/awsui-components-react/polaris/grid";
import Input from "@amzn/awsui-components-react/polaris/input";
import { Box } from "@amzn/awsui-components-react";

import TableNoMatchState from "../../../common/TableNoMatchState";
import {
  selfServiceColumnDefinition,
  searchableColumns,
  getVisibleColumns,
} from "./SelfServiceEntityTableHeader/tableConfig";
import SelfServiceEntityTableHeader from "./SelfServiceEntityTableHeader";
import { GetPatchedEvents } from "../../JsonPatch/jsonpatch";
import SelfServiceEntityMainHeader from "./SelfServiceEntityMainHeader";
import { ENTITY_TABLE_SIZE, ROOT_ENTITY_NAME } from "../../constant";
import { sortingField } from "./SelfServiceEntityTableHeader/tableConfig";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";

const SelfServiceEntityTable = ({
  isLoading,
  refresh,
  isValidEntity,
  entityEvents,
  entityDetails,
  setEntityDetails,
  setNotifications,
}) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [entityList, setEntityList] = useState([]);
  const [currentEntityVersion, setCurrentEntityVersion] = useState(0);

  const changeEntityVersion = (Id) => {
    setCurrentEntityVersion(Id);
    setSelectedItems([]);
    loadEntityItems(entityDetails.entityType, entityEvents.slice(0, Id));
  };

  const loadEntityItems = (entityType, events) => {
    const doc = GetPatchedEvents(entityType, events);
    let data = [];
    if (Object.keys(doc).length !== 0) {
      doc[entityType]?.forEach((item, index) => {
        const entityData =
          entityType === ROOT_ENTITY_NAME
            ? {
                index: index,
                name: item.name,
                value: item.description,
                permissions: item.permissions,
              }
            : {
                index: index,
                name: item.name,
                value: item.description,
              };
        data.push(entityData);
      });
    }
    setEntityList(data);
    setEntityDetails({ ...entityDetails, entityListLength: data.length });
  };

  const { items, actions, collectionProps, filterProps, paginationProps } =
    useCollection(entityList || [], {
      pagination: { pageSize: ENTITY_TABLE_SIZE },
      filtering: {
        noMatch: (
          <TableNoMatchState onClearFilter={() => actions.setFiltering("")} />
        ),
        filteringFunction: (item, filteringText) => {
          return searchableColumns
            .map((key) => item[key])
            .some(
              (value) =>
                typeof value === "string" &&
                value.toLowerCase().indexOf(filteringText.toLowerCase()) > -1
            );
        },
      },
      sorting: {
        defaultState: {
          sortingColumn: {
            sortingField: sortingField,
          },
          isDescending: true,
        },
      },
      selection: {},
    });

  useEffect(() => {
    loadEntityItems(entityDetails.entityType, entityEvents, {});
    setCurrentEntityVersion(entityDetails.version);
  }, []);

  return (
    <SpaceBetween size="s">
      <SelfServiceEntityMainHeader
        entityDetails={entityDetails}
        refresh={refresh}
        isValidEntity={isValidEntity}
        entityEvents={entityEvents}
        currentEntityVersion={currentEntityVersion}
        setNotifications={setNotifications}
      />
      <Table
        variant="stacked"
        {...collectionProps}
        loading={isLoading}
        loadingText="Loading Self Service"
        items={items}
        {...(currentEntityVersion === entityDetails.version && {
          selectionType: "single",
        })}
        columnDefinitions={selfServiceColumnDefinition}
        visibleColumns={getVisibleColumns(
          entityDetails.entityType,
          entityDetails.selfServiceAuthorizerFlag
        )}
        resizableColumns
        empty={
          <Box textAlign="center" color="inherit">
            <Box padding={{ bottom: "s" }} variant="p" color="inherit">
              No Items to Display.
            </Box>
          </Box>
        }
        header={
          <SelfServiceEntityTableHeader
            refresh={refresh}
            counter={
              entityList.length ? `(${items.length}/${entityList.length})` : ""
            }
            currentEntityVersion={currentEntityVersion}
            changeEntityVersion={changeEntityVersion}
            isValidEntity={isValidEntity}
            entityDetails={entityDetails}
            selectedItems={selectedItems}
            setNotifications={setNotifications}
          />
        }
        selectedItems={selectedItems}
        onSelectionChange={({ detail }) =>
          setSelectedItems(detail.selectedItems)
        }
        filter={
          <Grid gridDefinition={[{ colspan: 4 }]}>
            <Input
              type="search"
              value={filterProps.filteringText}
              onChange={(event) => {
                actions.setFiltering(event.detail.value);
              }}
              placeholder="Search..."
              label="Search Items"
            />
          </Grid>
        }
        pagination={<Pagination {...paginationProps} />}
      />
    </SpaceBetween>
  );
};

SelfServiceEntityTable.propTypes = {
  isLoading: PropTypes.bool,
  refresh: PropTypes.func,
  isValidEntity: PropTypes.bool,
  entityEvents: PropTypes.array,
  entityDetails: PropTypes.object,
  setEntityDetails: PropTypes.func,
  setNotifications: PropTypes.func,
};
export default SelfServiceEntityTable;
