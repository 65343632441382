import React from "react";
import NewFilterTable from "internal/components/Dashboard/ApprovalList/NewFilterTable";
import OldFilterTable from "internal/components/Dashboard/ApprovalList/OldFilterTable";
import {
  getProfile,
  getUsername,
} from "internal/util/services/auth/AuthService";
import PropTypes from "prop-types";
import LoadingTable from "shared/components/common/TableLoading";
import { useQuery } from "@tanstack/react-query";
import { FEATURE_FLAG_ENUMS } from "shared/util/constants/featureFlagValues";

export const ApprovalList = ({ setNotificationsItems }) => {
  // NOTE: useQuery is using defaultQueryFn
  const { isLoading, error, data } = useQuery({
    queryKey: [
      "features",
      `${FEATURE_FLAG_ENUMS.NEW_SEARCH_BAR_INTERNAL_DASHBOARD}`,
    ],
  });

  const assignees = [getUsername()].concat(getProfile());
  const pageSize = 10;

  if (isLoading) return <LoadingTable loadingText={"Loading..."} />;

  if (error) {
    console.error(error);
    return <OldFilterTable setNotificationsItems={setNotificationsItems} />;
  }

  return (
    <>
      {data.enabled ? (
        <NewFilterTable
          pageSize={pageSize}
          assignees={assignees}
          setNotificationsItems={setNotificationsItems}
        />
      ) : (
        <OldFilterTable setNotificationsItems={setNotificationsItems} />
      )}
    </>
  );
};

ApprovalList.propTypes = {
  setNotificationsItems: PropTypes.func,
};

export default ApprovalList;
