import React from "react";

import { IGenericObject } from "shared/programs/migration-acceleration-program/2024/fund-request/types/CommonTypes";
import Container from "@amzn/awsui-components-react/polaris/container";
import { ColumnLayout } from "@amzn/awsui-components-react";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import ValueWithLabel from "shared/programs/migration-acceleration-program/2024/fund-request/components/fields/ValueWithLabel";
import { display } from "shared/util/common/display";
import { longLocalDateFormat } from "shared/util/common/date";
import Header from "@amzn/awsui-components-react/polaris/header";
import { generateEngagementIdLink } from "../../../../../../../../util/constants/generateEngagementIdLink";

const MigrationProgramEngagementsDetails = ({
  data,
}: {
  data: IGenericObject;
}) => {
  const migrationProgramEngagements = data?.migrationProgramEngagements;
  const [migrationProgramEngagement] = migrationProgramEngagements;

  return (
    <Container header={<Header>Migration program engagement (MPE)</Header>}>
      <ColumnLayout columns={3} variant="text-grid">
        <SpaceBetween size="l">
          <ValueWithLabel label="Program engagement ID">
            {display({
              data: migrationProgramEngagement,
              key: "engagementId",
              format: (value) => generateEngagementIdLink(value),
            })}
          </ValueWithLabel>
          <ValueWithLabel label="Estimated migration ARR (USD)">
            {display({
              data: migrationProgramEngagement,
              key: "estimatedMigrationArr",
            })}
          </ValueWithLabel>
          <ValueWithLabel label="Private equity backed">
            {display({
              data: migrationProgramEngagement,
              key: "privateEquityBacked",
            })}
          </ValueWithLabel>
          <ValueWithLabel label="Delivered by">
            {display({ data: migrationProgramEngagement, key: "deliveredBy" })}
          </ValueWithLabel>
          <ValueWithLabel label="OLA completion date">
            {display({
              data: migrationProgramEngagement,
              key: "olaCompletionDate",
              format: (value) => longLocalDateFormat(value),
            })}
          </ValueWithLabel>
        </SpaceBetween>

        <SpaceBetween size="l">
          <ValueWithLabel label="Record status">
            {display({ data: migrationProgramEngagement, key: "recordStatus" })}
          </ValueWithLabel>
          <ValueWithLabel label="Consulting partner engagement">
            {display({
              data: migrationProgramEngagement,
              key: "consultingPartnerEngagement",
            })}
          </ValueWithLabel>
          <ValueWithLabel label="Workshop completion date">
            {display({
              data: migrationProgramEngagement,
              key: "workshopCompletionDate",
              format: (value) => longLocalDateFormat(value),
            })}
          </ValueWithLabel>
          <ValueWithLabel label="Readout presentation date">
            {display({
              data: migrationProgramEngagement,
              key: "readoutPresentationDate",
              format: (value) => longLocalDateFormat(value),
            })}
          </ValueWithLabel>
          <ValueWithLabel label="OLA confirmed">
            {display({ data: migrationProgramEngagement, key: "olaConfirmed" })}
          </ValueWithLabel>
        </SpaceBetween>

        <SpaceBetween size="l">
          <ValueWithLabel label="Engagement status">
            {display({
              data: migrationProgramEngagement,
              key: "engagementStatus",
            })}
          </ValueWithLabel>
          <ValueWithLabel label="Agreement category">
            {display({
              data: migrationProgramEngagement,
              key: "agreementCategory",
            })}
          </ValueWithLabel>
          <ValueWithLabel label="Readiness assessment status">
            {display({
              data: migrationProgramEngagement,
              key: "readinessAssessmentStatus",
            })}
          </ValueWithLabel>
          <ValueWithLabel label="MRA methodology">
            {display({
              data: migrationProgramEngagement,
              key: "mraMethodology",
            })}
          </ValueWithLabel>
          <ValueWithLabel label="OLA status">
            {display({ data: migrationProgramEngagement, key: "olaStatus" })}
          </ValueWithLabel>
        </SpaceBetween>
      </ColumnLayout>
    </Container>
  );
};

export default MigrationProgramEngagementsDetails;
