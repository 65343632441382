import React, { useEffect, useState } from "react";
import {
  ERROR_TYPE,
  hasError,
  retryApiCall,
} from "../../../../shared/util/services/data/DataService";
import {
  conductAudit,
  getAuditsByFrId,
} from "../../../util/services/data/InternalDataService";
import { AUDIT_STATUS } from "../../../util/constants/audit";
import {
  setErrorMessage,
  setSuccessMessage,
} from "../../../../shared/util/common/helper";
import Audit from "./index";
import Button from "@amzn/awsui-components-react/polaris/button";
import PropTypes from "prop-types";
import { PAGE_TO_STAGE, PAGES } from "../../../util/constants/pages";

const AuditButton = ({
  fundRequestId,
  cashClaimId = "",
  page,
  setNotificationsItems,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [audit, setAudit] = useState({});

  useEffect(async () => {
    await isAuditRequired();
  }, []);

  const isAuditRequired = async () => {
    setIsLoading(true);
    const data = [];

    let nextPageToken = "";
    while (nextPageToken != null) {
      try {
        const response = await retryApiCall({
          callApi: getAuditsByFrId({ fundRequestId }),
        });

        console.log(response);

        if (hasError(response) && response.errorType === ERROR_TYPE.BANNER) {
          console.error("An error has occurred..");
          setIsLoading(false);
          return;
        }

        for (let request of response["audits"]) {
          data.push({
            auditId: request.auditId,
            frId: request.frId,
            stage: request.stage,
            cashClaimId: request.cashClaimId,
            spmsId: request.spmsId,
            auditingAudience: request.auditingAudience,
            requestedTimestamp: request.requestedTimestamp["timestamp"],
            auditTimestamp: request.auditTimestamp["timestamp"],
            auditor: request.auditor,
            auditCode: request.auditCode,
            comments: request.comments,
            status: request.status,
          });
        }
        nextPageToken = response.nextPageToken;
      } catch (e) {
        console.error(e);
      }
    }

    const [matchingAudit] = data.filter((audit) => {
      if (PAGE_TO_STAGE[page] !== audit.stage) return false;
      let hasMatchingAudit = audit.status === AUDIT_STATUS.REQUIRES_AUDIT;
      if (page === PAGES.CASH_CLAIM_APPROVAL) {
        hasMatchingAudit =
          hasMatchingAudit && audit.cashClaimId === cashClaimId;
      }
      return hasMatchingAudit;
    });
    setAudit(matchingAudit);
    setIsLoading(false);
  };

  const submitAudit = async (payload) => {
    setIsLoading(true);
    try {
      const response = await retryApiCall({
        callApi: conductAudit({ body: { ...payload, auditId: audit.auditId } }),
      });
      console.log(response);

      if (
        response &&
        hasError(response) &&
        response.errorType === ERROR_TYPE.BANNER
      ) {
        setErrorMessage({
          setNotificationsItems,
          content: response.message,
          status: response.status,
        });
        setIsLoading(false);
        setIsModalVisible(false);
        return;
      }
      setSuccessMessage(
        setNotificationsItems,
        "Submitted",
        `Your Audit has been successfully submitted!`
      );
      setIsLoading(false);
      setIsModalVisible(false);
    } catch (e) {
      console.error(e);
      setErrorMessage({
        setNotificationsItems,
      });
      setIsLoading(false);
      setIsModalVisible(false);
    }
  };

  const updateVisibility = (actionName, visibility) => {
    setIsModalVisible(visibility);
  };

  return (
    <React.Fragment>
      <Button
        loading={isLoading}
        disabled={!audit || audit.status !== AUDIT_STATUS.REQUIRES_AUDIT}
        onClick={() => {
          updateVisibility("audit", true);
        }}
      >
        Audit
      </Button>
      <Audit
        isLoading={isLoading}
        isVisible={isModalVisible}
        submitAudit={submitAudit}
        updateVisibility={updateVisibility}
      />
    </React.Fragment>
  );
};

AuditButton.propTypes = {
  fundRequestId: PropTypes.string,
  cashClaimId: PropTypes.string,
  page: PropTypes.string,
  setNotificationsItems: PropTypes.func,
};

export default AuditButton;
