import React from "react";
import { SplitPanelContext } from "shared/util/context/split-panel/SplitPanelContext";

const SplitPanelContainer = () => {
  const { panel } = React.useContext(SplitPanelContext);

  if (!panel) return null;

  return <>{panel}</>;
};

export default SplitPanelContainer;
