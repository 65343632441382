import { getSubjectId } from "shared/util/services/auth/AuthService";
import { createSecondGranularityTimestamp } from "external/util/common/helper";

export interface MoneyType {
  units: number;
  nanos: number;
  currency: string;
}

export interface ICreateFundRequestRequest {
  clientToken: string;
  program: string;
  subProgram: string;
  fundingType: string;
}

export interface ICreateMAPFundRequestRequest
  extends ICreateFundRequestRequest {
  migrationPhase: string;
  annualRunRate: MoneyType;
  accurateWorkloadAcknowledgement?: boolean;
  modernizationIncentiveEligible?: boolean;
  modernizationArrUsdAmount?: MoneyType;
  vmwareIncentiveEligible?: boolean;
  vmwareBonusOption?: boolean;
}

export class CreateMAPFundRequestRequest
  implements ICreateMAPFundRequestRequest
{
  clientToken: string;
  program: string;
  subProgram: string;
  fundingType: string;
  migrationPhase: string;
  annualRunRate: MoneyType;
  accurateWorkloadAcknowledgement?: boolean;
  modernizationIncentiveEligible?: boolean;
  modernizationArrUsdAmount?: MoneyType;
  vmwareAcknowledgement?: boolean;
  vmwareMSPAcknowledgement?: boolean;

  constructor(migrationPhase: string, annualRunRate: number) {
    this.migrationPhase = migrationPhase;
    this.annualRunRate = {
      units: Number(annualRunRate),
      nanos: 0,
      currency: "USD",
    };

    this.program = "MAP";
    //TODO: subProgram...?
    this.subProgram = "Migration Acceleration Program";
    this.fundingType = "Cash";

    this.clientToken = `create-${this.program}-${
      this.fundingType
    }-${getSubjectId()}-${createSecondGranularityTimestamp()}`;
  }

  public setModernizationIncentiveEligible = (value: boolean) => {
    this.modernizationIncentiveEligible = value;
  };

  public setAccurateWorkloadAcknowledgement = (value: boolean) => {
    this.accurateWorkloadAcknowledgement = value;
  };

  public setModernizationArrUsdAmount = (value: number) => {
    this.modernizationArrUsdAmount = {
      units: Number(value),
      nanos: 0,
      currency: "USD",
    };
  };

  public setVmwareAcknowledgement = (value: boolean) => {
    this.vmwareAcknowledgement = value;
  };

  public setVmwareMSPAcknowledgement = (value: boolean) => {
    this.vmwareMSPAcknowledgement = value;
  };

  public toJson = (): ICreateMAPFundRequestRequest => {
    const payload: ICreateMAPFundRequestRequest = {
      clientToken: this.clientToken,
      program: this.program,
      subProgram: this.subProgram,
      fundingType: this.fundingType,
      migrationPhase: this.migrationPhase,
      annualRunRate: this.annualRunRate,
    };

    if (this.modernizationIncentiveEligible) {
      payload["modernizationIncentiveEligible"] =
        this.modernizationIncentiveEligible;
      payload["modernizationArrUsdAmount"] = this.modernizationArrUsdAmount;
    }

    if (this.accurateWorkloadAcknowledgement) {
      payload["accurateWorkloadAcknowledgement"] =
        this.accurateWorkloadAcknowledgement;
    }

    payload["vmwareIncentiveEligible"] = this.vmwareAcknowledgement || false;
    payload["vmwareBonusOption"] = this.vmwareMSPAcknowledgement || false;
    if (!payload["vmwareIncentiveEligible"]) {
      payload["vmwareBonusOption"] = false;
    }

    return payload;
  };
}

export class CreateMAPFundRequestRequestBuilder {
  private readonly request: CreateMAPFundRequestRequest;

  constructor(migrationPhase: string, annualRunRate: number) {
    this.request = new CreateMAPFundRequestRequest(
      migrationPhase,
      annualRunRate
    );
  }

  public setModernizationIncentiveEligible = (value?: boolean) => {
    if (value) this.request.setModernizationIncentiveEligible(value);
    return this;
  };

  public setModernizationArrUsdAmount = (value?: number) => {
    if (value) this.request.setModernizationArrUsdAmount(value);
    return this;
  };

  public setAccurateWorkloadAcknowledgement = (value?: boolean) => {
    if (value) this.request.setAccurateWorkloadAcknowledgement(value);
    return this;
  };

  public setVmwareAcknowledgement = (value?: boolean) => {
    if (value !== undefined) this.request.setVmwareAcknowledgement(value);
    return this;
  };

  public setVmwareMSPAcknowledgement = (value?: boolean) => {
    if (value !== undefined) this.request.setVmwareMSPAcknowledgement(value);
    return this;
  };

  public build = (): CreateMAPFundRequestRequest => {
    return this.request;
  };
}
