import React, { useEffect, useState } from "react";
import Container from "@amzn/awsui-components-react/polaris/container";
import { FormField, Header, SpaceBetween } from "@amzn/awsui-components-react";
import Textarea from "@amzn/awsui-components-react/polaris/textarea";
import { IChangeState } from "../../../../../../types/WizardTypes";
import { MAX_FILE_SIZE_IN_MB } from "../util";
import { FileUploadComponent } from "shared/programs/migration-acceleration-program/2024/fund-request/components/attachments/FileUploadComponent";
import { BaseChangeDetail } from "@amzn/awsui-components-react/polaris/input/interfaces";
import {
  equals,
  getArtifacts,
} from "shared/programs/migration-acceleration-program/2024/fund-request/components/attachments/util";
import { IArtifactFileModel } from "shared/programs/migration-acceleration-program/2024/fund-request/components/attachments/types/fileTypes";
import InfoLink from "shared/components/InfoLink";
import { IGenericObject } from "shared/programs/migration-acceleration-program/2024/fund-request/types/CommonTypes";
import { URL_UPLOAD_PATTERN } from "shared/util/constants/awsCalculatorPattern";
import { HelpPanelContentContext } from "shared/util/context/help/HelpContext";
import { useTranslation } from "react-i18next";
import Link from "@amzn/awsui-components-react/polaris/link";

const AWS_PRICING_CALCULATOR_ERROR_TEXT =
  "AWS pricing calculator URL(s) entered is invalid. Please verify the instructions and provide the AWS pricing calculator URL in the correct format.";

const AWSPricingCalculatorSection = ({
  data,
  onChange,
}: {
  data: IGenericObject;
  onChange: IChangeState;
}) => {
  const [files, setFiles] = useState<File[]>([]);
  const [fileIdToAttributes, setFileIdToAttributes] =
    useState<IArtifactFileModel>({});
  const [urlError, setUrlError] = useState<string>("");
  const { setHelpPanelContent } = React.useContext(HelpPanelContentContext);
  const { t } = useTranslation();

  useEffect(() => {
    const originalArtifacts = data && data["artifacts"];
    const documents = originalArtifacts && originalArtifacts["documents"];
    const artifacts = getArtifacts({
      documents,
      filter: (actualType: string) =>
        equals(actualType, "AWS Pricing Calculator"),
    });
    setFiles(artifacts.files || []);
    setFileIdToAttributes(artifacts.fileIdToAttributes);
  }, [data]);

  const changeUrls = ({ detail }: { detail: BaseChangeDetail }) => {
    const { value } = detail;
    setUrlError("");

    const urls: string[] = value.split(",");

    if (urls.length > 3) {
      setUrlError(AWS_PRICING_CALCULATOR_ERROR_TEXT);
      return;
    }

    for (const url of urls) {
      if (!URL_UPLOAD_PATTERN.test(url.trim())) {
        setUrlError(AWS_PRICING_CALCULATOR_ERROR_TEXT);
        break;
      }

      setUrlError("");
    }
    onChange({ key: "awsCalculatorUrl", value });
  };

  return (
    <Container
      header={
        <Header
          variant="h2"
          description={t(
            "wizard.section.supportingDocumentation.content.estimates.header.description"
          )}
          info={
            <InfoLink
              onFollow={() => {
                setHelpPanelContent(
                  "wizard.supportingDocumentation.container.estimates"
                );
              }}
            />
          }
        >
          {t(
            "wizard.section.supportingDocumentation.content.estimates.header.title"
          )}
        </Header>
      }
    >
      <SpaceBetween size="l">
        <FormField
          label={t(
            "wizard.section.supportingDocumentation.content.estimates.fields.awsCalculatorUrl.label"
          )}
          description={
            <>
              Enter the unique, public link for each estimate that you created
              using{" "}
              <Link
                external
                fontSize="inherit"
                variant="primary"
                href="https://calculator.aws/#/"
              >
                AWS Pricing Calculator
              </Link>
              . For multiple estimate entries, separate each link using a comma
              (,) with no extra spaces. At least one estimate is required.
            </>
          }
          errorText={urlError}
        >
          <Textarea
            onChange={changeUrls}
            value={data && data["awsCalculatorUrl"]}
            placeholder={t(
              "wizard.section.supportingDocumentation.content.estimates.fields.awsCalculatorUrl.placeholder"
            )}
            rows={5}
          />
        </FormField>
        <FileUploadComponent
          artifacts={{ files, fileIdToAttributes }}
          updateFiles={setFiles}
          setFileIdToAttributes={setFileIdToAttributes}
          label={t(
            "wizard.section.supportingDocumentation.content.estimates.fields.upload.label"
          )}
          supportingDocumentType={"AWS Pricing Calculator"}
          description={t(
            "wizard.section.supportingDocumentation.content.estimates.fields.upload.description"
          )}
          constraintText={`File size limit: ${MAX_FILE_SIZE_IN_MB} MB`}
        />
      </SpaceBetween>
    </Container>
  );
};

export default AWSPricingCalculatorSection;
