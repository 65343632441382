import { ISV_WORKLOAD_MIGRATION_PROGRAM_NAME } from "../../../../util/constants/subProgram";
import useFeatureFlag from "../../../../util/hooks/useFeatureFlag";
import { FEATURE_FLAG_ENUMS } from "../../../../util/constants/featureFlagValues";

export const getIsvWmpSecondTrancheClaimConsentStatus = (data) => {
  const defaultConsentStatus = "-";
  if (useFeatureFlag(FEATURE_FLAG_ENUMS.ISV_WMP_CONSENT_ENABLED)) {
    if (
      data &&
      data["fundingTemplate"] &&
      data["fundingTemplate"]["subProgram"] ===
        ISV_WORKLOAD_MIGRATION_PROGRAM_NAME
    ) {
      return data["fundingTemplate"]["isvWmpSecondTrancheConsentCaptured"]
        ? "Yes"
        : "No";
    }
  }
  return defaultConsentStatus;
};
