import React, { useEffect, useState } from "react";
import ProgramSelect from "./ProgramSelect";
import { getTemplate } from "./ProgramSelect/util";
import { PROGRAM_TYPE } from "../../../shared/util/constants/programType";
import templateMappingData from "./ProgramSelect/templateMappingData";
import FundRequestCreationPage from "../../pages/ProgramSelect/FundRequestCreationPage";
import { isFeatureEnabled } from "../../../shared/util/services/features/FeatureFlagsService";
import { FEATURE_FLAG_ENUMS } from "../../../shared/util/constants/featureFlagValues";
import Dashboard from "../Dashboard";

const ProgramSelectSwitcher = () => {
  const template = getTemplate(window);

  if (!templateMappingData[template]) {
    return <Dashboard />;
  }
  const [isMap2024Enabled, setIsMap2024Enabled] = useState(false);
  const [isOldMapDisabled, setIsOldMapDisabled] = useState(false);

  useEffect(async () => {
    setIsMap2024Enabled(
      await isFeatureEnabled(FEATURE_FLAG_ENUMS.MAP_2024_ENABLED)
    );
    setIsOldMapDisabled(
      await isFeatureEnabled(FEATURE_FLAG_ENUMS.OLD_MAP_DISABLED)
    );
  }, []);

  if (template === PROGRAM_TYPE.MAP2024) {
    if (isMap2024Enabled) {
      return <FundRequestCreationPage />;
    }
    return <Dashboard />;
  }
  if (template === PROGRAM_TYPE.SMP && isOldMapDisabled) {
    return <Dashboard />;
  }

  return <ProgramSelect />;
};

export default ProgramSelectSwitcher;
