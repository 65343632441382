import React, { ReactNode, useState } from "react";
import { FlashbarProps } from "@amzn/awsui-components-react/polaris/flashbar/interfaces";

import { v4 as uuid } from "uuid";

export type NotificationsContextType = {
  notifications: Array<FlashbarProps.MessageDefinition>;
  addNotification: (notification: FlashbarProps.MessageDefinition) => void;
  setNotifications: React.Dispatch<
    React.SetStateAction<FlashbarProps.MessageDefinition[]>
  >;
  removeNotification: (id: string) => void;
};

export const NotificationsContext =
  React.createContext<NotificationsContextType>({} as NotificationsContextType);

const NotificationsProvider = ({ children }: { children: ReactNode }) => {
  const [notifications, setNotifications] = useState<
    Array<FlashbarProps.MessageDefinition>
  >([]);

  const addNotification = ({
    content,
    type,
    header = "",
  }: FlashbarProps.MessageDefinition) => {
    const id = uuid();
    const newNotification = {
      header,
      id,
      content,
      type,
      dismissible: true,
      onDismiss: () => {
        removeNotification(id);
      },
    };

    setNotifications([...notifications, newNotification]);
  };

  const removeNotification = (id: string) => {
    const filteredNotifications = notifications.filter(
      ({ id: notificationId }: FlashbarProps.MessageDefinition) =>
        notificationId !== id
    );

    setNotifications(filteredNotifications);
  };

  return (
    <NotificationsContext.Provider
      value={{
        notifications,
        addNotification,
        setNotifications,
        removeNotification,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

export default NotificationsProvider;
