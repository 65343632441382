const templateMappingData = {
  Sandbox: {
    name: "Sandbox",
    programs: {
      "Innovation Sandbox": {
        typesOfFunding: ["Credit"],
      },
    },
  },
  MDF: {
    name: "Marketing Development Fund",
    programs: {
      "Marketing Development Fund (MDF)": {
        description:
          "The Marketing Development Funds (MDF) helps offset your marketing costs to drive demand generation and build your sales pipeline. Receive cash or AWS Promotional Credits to support your marketing efforts to promote your services and solutions on AWS.",
        typesOfFunding: ["Combo"],
      },
      "Training MDF": {
        description:
          "The Training Partner MDF helps drive demand generation and go-to-market strategies, to promote your services and solutions on AWS. Tier or Select Training Partners are eligible to request cash to support marketing efforts.",
        typesOfFunding: ["Cash"],
      },
    },
  },
  SMP: {
    name: "Simplified Migration Program",
    programs: {
      "Simplified Migration Program": {
        description: "",
        typesOfFunding: ["Credit", "Cash"],
      },
    },
  },
  POC: {
    name: "Proof Of Concept",
    programs: {
      "Proof Of Concept": {
        description: "",
        typesOfFunding: ["Credit", "Cash"],
      },
    },
  },
  Misc: {
    name: "Miscellaneous",
    programs: {
      "AWS Jumpstart Program": {
        description:
          "The AWS Startup Jumpstart program supports startups that have an identified need for strategic service adoption and can benefit from hands-on technical resources. The program is meant to help startups that already see the value in adopting a specific service(s), but have technical knowledge or resource shortfalls. Through the Amazon Partner Network (APN), we offer startups hands-on support to the customer, and accelerate their implementation and knowledge transfer.",
        typesOfFunding: ["Cash"],
      },
      "ISV Workload Migration Program": {
        description:
          "The ISV Workload Migration Program (WMP) provides a prescriptive and repeatable migration approach as well as funding to accelerate migrations of standard ISV workloads to AWS. Learn more (https://aws.amazon.com/partners/programs/isv-workload-migration/)",
        typesOfFunding: ["Credit"],
      },
      // Comment this out in case we need to add this program in the future
      /*
      "Partner Transformation Program": {
        description:
          "AWS Partner Transformation Program (PTP)  is a personalized engagement led by third party consultants who guide partners in accelerating their existing cloud practice on AWS. PTP offers AWS Promotional Credits or cash to offset the cost of eligible transformation activities for currently enrolled partners. Read more: https://aws.amazon.com/partners/programs/partner-transformation/",
        typesOfFunding: ["Credit", "Cash"],
      },
      */
    },
  },
  PIF: {
    name: "Partner Initiative Funding",
    programs: {},
  },
  MAP: {
    name: "Migration Acceleration Program",
    programs: {
      "Migration Acceleration Program": {
        description: "Migration Acceleration Program",
        typesOfFunding: ["Cash"],
      },
    },
  },
};

export default templateMappingData;
