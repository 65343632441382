import React from "react";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import { Button } from "@amzn/awsui-components-react";
import ConditionalField from "shared/components/common/Conditional/ConditionalField";
import { IGenericObject } from "shared/programs/migration-acceleration-program/2024/fund-request/types/CommonTypes";
import {
  CASH_CLAIM_STATUS,
  STAGE,
  STATUS,
} from "shared/util/constants/fundRequestStatusType";
import { IClaim } from "shared/programs/migration-acceleration-program/2024/fund-request/types/SchemaTypes";
import { openNewTab } from "shared/util/common/helper";
import { POST_CLAIM_SUBMITTED_STAGES } from "shared/programs/migration-acceleration-program/2024/fund-request/util/claims";

export interface CashClaimActionsHeaderProps {
  data: IGenericObject;
  claimId: string;
  claimStatus: string;
  priority: number;
  onClick: () => void;
}

const CashClaimActionsHeader = ({
  data,
  claimId,
  claimStatus,
  priority,
  onClick,
}: CashClaimActionsHeaderProps) => {
  const fundRequest = data;
  const claims = fundRequest.claims;
  const isActive = fundRequest.status == STATUS.ACTIVE;
  const inCashClaimStage = fundRequest.stage === STAGE.CASH_CLAIM;

  const showSubmitClaim = () => {
    if (!(isActive && inCashClaimStage)) {
      return false;
    }
    if (priority == 0 && claimStatus == CASH_CLAIM_STATUS.UNLOCKED) {
      return true;
    }
    const firstClaim = claims[0];
    if (
      priority !== 0 &&
      claimStatus == CASH_CLAIM_STATUS.UNLOCKED &&
      POST_CLAIM_SUBMITTED_STAGES.includes(firstClaim["claimStage"])
    ) {
      return true;
    }

    return false;
  };

  const showPayeeCentral = () => {
    const [claim] = claims.filter((claim: IClaim) => claim.claimId === claimId);
    return claim?.claimStage === "invoice";
  };

  return (
    <>
      <SpaceBetween size="l" direction="horizontal">
        <ConditionalField showField={showSubmitClaim()}>
          <Button variant="primary" onClick={onClick}>
            Submit claim
          </Button>
        </ConditionalField>
        <ConditionalField showField={showPayeeCentral()}>
          <Button
            onClick={() => {
              openNewTab("https://payeecentral.amazon.com");
            }}
            iconAlign="right"
            iconName="external"
          >
            Payee Central
          </Button>
        </ConditionalField>
      </SpaceBetween>
    </>
  );
};

export default CashClaimActionsHeader;
