import React from "react";
import { Alert } from "@amzn/awsui-components-react";
import ConditionalField from "shared/components/common/Conditional/ConditionalField";

interface OpportunityErrorAlertProps {
  isVisible: boolean;
  userFriendlyOpportunityId?: string;
}

const NonQualifiedOpportunityAlert = ({
  userFriendlyOpportunityId,
  isVisible,
}: OpportunityErrorAlertProps) => {
  return (
    <ConditionalField showField={isVisible}>
      <Alert
        type="error"
        header={`Opportunity ${userFriendlyOpportunityId} does not qualify`}
      >
        Choose another opportunity or work with your partner success manager
        (PSM) or partner development manager (PDM) to qualify the currently
        selected opportunity. AWS has notified your PSM that the selected
        opportunity does not qualify.
      </Alert>
    </ConditionalField>
  );
  return null;
};

export default NonQualifiedOpportunityAlert;
