import React from "react";
import { COMPONENT_TYPE } from "../../shared/util/constants/componentType";
import {
  FUNDING_TYPE,
  PROGRAM_TYPE,
} from "../../shared/util/constants/programType";
import { SECTION, SECTION_NAME } from "../../shared/util/constants/section";
import { AWS_ARN_FOR_WELL_ARCHITECTED } from "../../shared/util/constants/awsArn";
import { FINANCIAL_APPROVAL_GUIDE_LINK } from "../../shared/util/constants/constantValues";
import {
  WELL_ARCHITECTED_PROGRAM_NAME,
  FIELDS_REQUIRED_FOR_WELL_ARCHITECTED,
} from "shared/util/constants/subProgram";
import {
  attachmentsSectionV2,
  cashClaimActualsSectionV2,
  cashClaimInvoicePaymentSectionV2,
  cashClaimPlansSectionV2,
  cashRequestInformationSectionV2,
  fundRequestInformationSectionV2,
  opportunityInformationSectionV2,
  projectInformationSectionV2,
} from "external/config/schemaV2";

export const fundRequestInformationSection = {
  title: SECTION.FUND_REQUEST_INFORMATION,
  name: SECTION_NAME.FUND_REQUEST_INFORMATION,
  supportedProgram: [
    PROGRAM_TYPE.SANDBOX,
    PROGRAM_TYPE.MISC,
    PROGRAM_TYPE.MDF,
    PROGRAM_TYPE.SMP,
    PROGRAM_TYPE.POC,
    PROGRAM_TYPE.PIF,
  ],
  fields: [
    {
      name: "activityName",
      label: "Activity/Project Name",
      auto: false,
      component: COMPONENT_TYPE.INPUT,
      type: "string",
      Required: [
        PROGRAM_TYPE.SANDBOX,
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "pifProgramName",
      label: "Program Name",
      auto: true,
      component: COMPONENT_TYPE.INPUT,
      type: "string",
      Required: [PROGRAM_TYPE.PIF],
    },
    {
      name: "pifFundingMotion",
      label: "Funding Motion",
      auto: true,
      component: COMPONENT_TYPE.INPUT,
      type: "string",
      Required: [PROGRAM_TYPE.PIF],
    },
    {
      name: "businessDescription",
      label: "Business Description",
      placeholder:
        "Include the reason for requesting funding, including business justification, project summary, objectives, success criteria, MDF usage reason for cash and credit, etc." +
        "\nThe description should be a max of 5000 characters including special characters such as space, comma, etc.",
      auto: false,
      component: COMPONENT_TYPE.TEXTAREA,
      type: "string",
      Required: [
        PROGRAM_TYPE.SANDBOX,
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "awsCalculatorUrl",
      label: "AWS Pricing Calculator",
      placeholder:
        "Enter the URL for AWS Pricing Calculator." +
        "For fund requests that require multiple AWS Pricing Calculators, " +
        "enter each hyperlink/URL string separated by a comma (,) and ensure there are no " +
        "empty spaces in between each entry",
      auto: false,
      component: COMPONENT_TYPE.TEXTAREA,
      type: "string",
      Optional: [
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.SANDBOX,
      ],
    },
    {
      name: "sandboxDesignWinActivity",
      label: "Sandbox Design Win Activity",
      auto: false,
      key: "sandboxDesignWinActivity",
      component: COMPONENT_TYPE.SELECT,
      type: "string",
      Required: [PROGRAM_TYPE.SANDBOX],
    },
    {
      name: "fundRequestOwner",
      label: "Fund Request Owner",
      auto: true,
      component: COMPONENT_TYPE.INPUT,
      type: "string",
      Required: [
        PROGRAM_TYPE.SANDBOX,
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "status",
      label: "Active Status",
      auto: true,
      component: COMPONENT_TYPE.INPUT,
      Required: [
        PROGRAM_TYPE.SANDBOX,
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "fundRequestId",
      label: "Fund Request Id",
      auto: true,
      component: COMPONENT_TYPE.INPUT,
      Required: [
        PROGRAM_TYPE.SANDBOX,
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "arn",
      label: "Amazon Resource Name (ARN)",
      description:
        "Please provide the character alphanumeric ARN string for Well-Architected Partner Program request.",
      auto: false,
      pattern: `${AWS_ARN_FOR_WELL_ARCHITECTED}`,
      errorMessage:
        "Please provide the character alphanumeric ARN string for Well-Architected Partner Program request.",
      component: COMPONENT_TYPE.INPUT,
      type: "string",
      Optional: [PROGRAM_TYPE.PIF],
    },
    {
      name: "majorityPublicSector",
      label: "Will this activity be targeting 50%+ public sector customers?",
      auto: false,
      component: COMPONENT_TYPE.CHECKBOX,
      type: "boolean",
      Required: [PROGRAM_TYPE.SANDBOX, PROGRAM_TYPE.MISC, PROGRAM_TYPE.MDF],
      Optional: [PROGRAM_TYPE.PIF],
    },
  ],
};

export const opportunityInformationSection = {
  title: SECTION.OPPORTUNITY_INFORMATION,
  name: SECTION_NAME.OPPORTUNITY_INFORMATION,
  supportedProgram: [PROGRAM_TYPE.MISC, PROGRAM_TYPE.PIF],
  fields: [
    {
      name: "opportunityId",
      label: "Opportunity Id",
      auto: false,
      component: COMPONENT_TYPE.OPPORTUNITY_SELECT,
      errorMessage: "Please select a valid opportunity.",
      type: "string",
      Required: [PROGRAM_TYPE.MISC, PROGRAM_TYPE.SMP, PROGRAM_TYPE.POC],
      Optional: [PROGRAM_TYPE.PIF],
    },
    {
      name: "aceOpportunityId",
      label: "ACE Opportunity",
      auto: true,
      hidden: true,
      component: COMPONENT_TYPE.INPUT,
      type: "string",
      Required: [
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "awsPartnerManager",
      label: "AWS Partner Manager",
      auto: true,
      component: COMPONENT_TYPE.INPUT,
      type: "textstring",
      Required: [
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "customerCompanyName",
      label: "Customer Company Name",
      auto: true,
      component: COMPONENT_TYPE.INPUT,
      type: "string",
      Required: [
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "opportunityOwner",
      label: "Opportunity Owner",
      auto: true,
      component: COMPONENT_TYPE.INPUT,
      type: "string",
      Required: [
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "opportunityOwnership",
      label: "Opportunity Ownership",
      auto: true,
      component: COMPONENT_TYPE.INPUT,
      type: "string",
      Required: [
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "targetCloseDate",
      label: "Target Close Date",
      auto: true,
      component: COMPONENT_TYPE.INPUT,
      type: "string",
      Required: [
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "projectDescription",
      label: "Project Description",
      auto: true,
      component: COMPONENT_TYPE.INPUT,
      type: "string",
      Required: [
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "status",
      label: "Status",
      auto: true,
      component: COMPONENT_TYPE.INPUT,
      type: "string",
      Required: [
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "aceValidationStage",
      label: "Ace Validation Stage",
      auto: true,
      component: COMPONENT_TYPE.INPUT,
      type: "string",
      Required: [
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
  ],
};

export const projectInformationSection = {
  title: SECTION.PROJECT_INFORMATION,
  name: SECTION_NAME.PROJECT_INFORMATION,
  supportedProgram: [
    PROGRAM_TYPE.SANDBOX,
    PROGRAM_TYPE.MISC,
    PROGRAM_TYPE.MDF,
    PROGRAM_TYPE.SMP,
    PROGRAM_TYPE.POC,
    PROGRAM_TYPE.PIF,
  ],
  fields: [
    {
      name: "awsAccountId",
      label: "AWS Account Id for this Project",
      auto: false,
      component: COMPONENT_TYPE.INPUT,
      type: "string",
      pattern: "^[0-9]{12}$",
      errorMessage: "must be a 12 digit number.",
      Required: [
        PROGRAM_TYPE.SANDBOX,
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
      ],
      Optional: [PROGRAM_TYPE.PIF],
    },
    {
      name: "annualRunRateForMisc",
      label: "Annual Recurring Revenue (ARR) Once In Production (USD)",
      component: COMPONENT_TYPE.INPUT,
      type: "money",
      Required: [PROGRAM_TYPE.MISC],
    },
    {
      name: "plannedStartDate",
      label: "Planned Delivery Start Date",
      description:
        "This is the earliest start date of your activity. Work may begin 14 business days after the fund request submission date.",
      auto: false,
      component: COMPONENT_TYPE.DATEPICKER,
      Required: [
        PROGRAM_TYPE.SANDBOX,
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "plannedEndDate",
      label: "Planned Delivery End Date",
      description:
        "This is the date when your activity ends. Cash claims are typically due on 30 days after the activity end date.",
      auto: false,
      component: COMPONENT_TYPE.DATEPICKER,
      Required: [
        PROGRAM_TYPE.SANDBOX,
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "extendedStartDate",
      label: "Extended Start Date",
      auto: true,
      hidden: true,
      component: COMPONENT_TYPE.DATEPICKER,
      Optional: [
        PROGRAM_TYPE.SANDBOX,
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "extendedReason",
      label: "Reason for Extension",
      auto: true,
      hidden: true,
      component: COMPONENT_TYPE.TEXTAREA,
      edit: [],
      Optional: [
        PROGRAM_TYPE.SANDBOX,
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "extendedEndDate",
      label: "Extended End Date",
      auto: true,
      hidden: true,
      component: COMPONENT_TYPE.DATEPICKER,
      Optional: [
        PROGRAM_TYPE.SANDBOX,
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "executedCountry",
      label: "Country Activity is Executed",
      auto: false,
      key: "countries",
      component: COMPONENT_TYPE.SELECT,
      Required: [
        PROGRAM_TYPE.SANDBOX,
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "executedState",
      label: "Location/State Activity is Executed (Select All That Apply)",
      auto: false,
      key: "locationSlashStateActivityExecuted",
      component: COMPONENT_TYPE.MULTI_SELECT,
      Required: [
        PROGRAM_TYPE.SANDBOX,
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "projectType",
      label: "Project Type",
      auto: false,
      key: "projectType",
      component: COMPONENT_TYPE.SELECT,
      Required: [PROGRAM_TYPE.SMP],
    },
    {
      name: "customerConsidering",
      label: "Customer Also Considering",
      auto: false,
      component: COMPONENT_TYPE.SELECT,
      key: "customerAlsoConsidering",
      Required: [PROGRAM_TYPE.SMP, PROGRAM_TYPE.POC],
      Optional: [PROGRAM_TYPE.MISC, PROGRAM_TYPE.PIF],
    },
    {
      name: "workloadName",
      label: "Workload Name",
      description: "Please select the workload relating to this project",
      component: COMPONENT_TYPE.SELECT,
      auto: false,
      key: "pifWorkloads",
      Optional: [PROGRAM_TYPE.PIF],
    },
    {
      name: "newServiceDeployed",
      label: "New Service Deployed",
      description:
        "Please check the box if new service deployed with this project.",
      component: COMPONENT_TYPE.CHECKBOX,
      auto: false,
      Optional: [PROGRAM_TYPE.PIF],
    },
    {
      name: "otherNotes",
      label: "Other Notes",
      placeholder: "Additional information relating to this project.",
      component: COMPONENT_TYPE.TEXTAREA,
      auto: false,
      Optional: [PROGRAM_TYPE.PIF],
    },
  ],
};

//TODO: This does not match internal. Internal splits migration details and workloads,
// but most of the internal migration details fields are not available for external.
export const migrationWorkloads = {
  title: SECTION.MIGRATION_WORKLOADS,
  name: SECTION_NAME.MIGRATION_WORKLOADS,
  supportedProgram: [PROGRAM_TYPE.SMP],
  fields: [
    {
      name: "specializedWorkload",
      label: "Specialized Workloads",
      component: COMPONENT_TYPE.SELECT,
      auto: false,
      key: "specializedWorkloads",
      Optional: [PROGRAM_TYPE.SMP],
    },
    {
      name: "postMigrationAnnualRunRate",
      label: "Post-Migration ARR of this Workload (USD)",
      component: COMPONENT_TYPE.INPUT,
      type: "money",
      auto: false,
      Optional: [PROGRAM_TYPE.SMP],
    },
    {
      name: "additionalIncentive",
      label: "Additional Incentives for Specialized Workloads",
      component: COMPONENT_TYPE.INPUT,
      type: "money",
      auto: true,
      Optional: [PROGRAM_TYPE.SMP],
    },
  ],
};

export const publicSectorDetailsSection = {
  title: SECTION.PUBLIC_SECTOR_DETAILS,
  name: SECTION_NAME.PUBLIC_SECTOR_DETAILS,
  supportedProgram: [
    PROGRAM_TYPE.SANDBOX,
    PROGRAM_TYPE.MISC,
    PROGRAM_TYPE.MDF,
    PROGRAM_TYPE.SMP,
    PROGRAM_TYPE.POC,
    PROGRAM_TYPE.POC,
  ],
  fields: [
    {
      name: "customerRfx",
      label: "Responding to Customer RFX",
      auto: false,
      component: COMPONENT_TYPE.CHECKBOX,
      Required: [
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "wwpsConsent",
      label: "WWPS Consent",
      description: (
        <div>
          By checking this box, you represent and warrant that you have the
          legal authority to bind the APN Partner applying for funding Benefits
          (“Partner”) and that Partner agrees to the following:
          <br />
          <br />
          Partner certifies that the project for which Partner is applying for
          funding Benefits (“Project”) meets the requirements for the applicable
          project type under the current APN Funding Guide, and does not relate
          to any individual Government customer (as defined in the
          <a href="https://aws.amazon.com/partners/terms-and-conditions/">
            {" "}
            APN Terms & Conditions{" "}
          </a>
          ) or directly support any Government contract (including pending
          solicitations for Government contracts). Partner certifies that all
          information and materials provided to AWS in connection with this
          request are true, complete, and accurate. Any funding Benefits issued
          in response to this request must be used only to support the Project,
          and any AWS Promotional Credit (“Credits”) may be redeemed exclusively
          on the AWS Account ID you provide to AWS in connection with this
          application (if applicable). Partner may not use the funding Benefits
          in any way to benefit Government personnel (including travel, lodging,
          gifts, or other contributions).
        </div>
      ),
      auto: false,
      component: COMPONENT_TYPE.CHECKBOX,
      Required: [
        PROGRAM_TYPE.SANDBOX,
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
  ],
};

export const cashRequestInformationSection = {
  title: SECTION.CASH_REQUEST_INFORMATION,
  name: SECTION_NAME.CASH_REQUEST_INFORMATION,
  description: (
    <React.Fragment>
      The{" "}
      <a
        href={FINANCIAL_APPROVAL_GUIDE_LINK}
        target="_blank"
        rel="noopener noreferrer"
      >
        Finance Approval Guide
      </a>{" "}
      provides guidance how to accurately complete cash request information
      using your company’s accounts in Payee Central.
    </React.Fragment>
  ),
  supportedProgram: [
    PROGRAM_TYPE.MISC,
    PROGRAM_TYPE.SMP,
    PROGRAM_TYPE.MDF,
    PROGRAM_TYPE.POC,
    PROGRAM_TYPE.PIF,
  ],
  fields: [
    {
      name: "currency",
      label: "Currency",
      description:
        "The currency you select must match the preferred currency selected in your company's Amazon Payee Central.",
      component: COMPONENT_TYPE.SELECT,
      key: "currencies",
      auto: false,
      Required: [
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "totalRequestedMdfCashAmount",
      label: "Total Requested MDF Cash Amount (Local Currency)",
      description:
        "Sum of Requested MDF Cash Funding from all activities below.",
      component: COMPONENT_TYPE.INPUT,
      type: "money",
      auto: true,
      Required: [PROGRAM_TYPE.MDF],
    },
    {
      name: "totalCostOfActivity",
      label: "Total Cost of Activity (Local Currency)",
      description: "Total cost on SOW or Project Plan.",
      placeholder:
        "This is the total cash expense you expect to pay at the end of the activity",
      component: COMPONENT_TYPE.INPUT,
      type: "money",
      auto: false,
      Required: [PROGRAM_TYPE.MISC, PROGRAM_TYPE.SMP, PROGRAM_TYPE.POC],
      Optional: [PROGRAM_TYPE.PIF],
    },
    {
      name: "partnerContribution",
      label: "Partner Contribution (Local Currency)",
      description: "Project cost shared by AWS Partner.",
      component: COMPONENT_TYPE.INPUT,
      type: "money",
      auto: false,
      Required: [PROGRAM_TYPE.MISC, PROGRAM_TYPE.SMP, PROGRAM_TYPE.POC],
      Optional: [PROGRAM_TYPE.PIF],
    },
    {
      name: "otherPartyContribution",
      label: "Customer Contribution (Local Currency)",
      description: "Project cost shared by customer or other parties.",
      component: COMPONENT_TYPE.INPUT,
      type: "money",
      auto: false,
      Required: [PROGRAM_TYPE.MISC, PROGRAM_TYPE.SMP, PROGRAM_TYPE.POC],
      Optional: [PROGRAM_TYPE.PIF],
    },
    {
      name: "cashFundingAmount",
      label: "Requested Cash Funding Amount (Local Currency)",
      component: COMPONENT_TYPE.INPUT,
      type: "money",
      auto: false,
      Required: [PROGRAM_TYPE.MISC, PROGRAM_TYPE.PIF],
    },
    {
      name: "estimatedRequestedAmountUsd",
      label: "Estimated requested amount (USD)",
      component: COMPONENT_TYPE.TEXTAREA,
      type: "string",
      auto: true,
      hidden: true,
      Optional: [PROGRAM_TYPE.PIF],
    },
    {
      name: "invoiceEntityName",
      label: "Invoice Entity Name",
      component: COMPONENT_TYPE.INPUT,
      type: "string",
      auto: false,
      Required: [
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "invoiceRemitAddress",
      label: "Invoice Remit Address",
      component: COMPONENT_TYPE.TEXTAREA,
      type: "string",
      auto: false,
      Required: [
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "invoiceRemitCountry",
      label: "Invoice Remit Address Country",
      component: COMPONENT_TYPE.SELECT,
      key: "countries",
      auto: false,
      Required: [
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "numberOfClaims",
      label: "Number of Claims",
      component: COMPONENT_TYPE.INPUT,
      auto: true,
      Required: [
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "number",
      label: "PO Number",
      component: COMPONENT_TYPE.INPUT,
      auto: true,
      hidden: true,
      edit: [],
      Required: [
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "approvedCashAmount",
      label: "Approved Cash Amount (Local Currency)",
      component: COMPONENT_TYPE.INPUT,
      type: "money",
      auto: true,
      hidden: true,
      Required: [
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
  ],
};

export const cashClaimPlansSection = {
  title: SECTION.CASH_CLAIM_PLANS,
  name: SECTION_NAME.CASH_CLAIM_PLANS,
  supportedProgram: [
    PROGRAM_TYPE.MISC,
    PROGRAM_TYPE.MDF,
    PROGRAM_TYPE.SMP,
    PROGRAM_TYPE.POC,
    PROGRAM_TYPE.PIF,
  ],
  fields: [
    {
      name: "fundClaimId",
      label: "Fund Claim Id",
      component: COMPONENT_TYPE.INPUT,
      auto: true,
      Required: [
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "mdfActivityType",
      label: "MDF Cash Activity Type",
      key: "mdfActivityType",
      component: COMPONENT_TYPE.SELECT,
      auto: false,
      Required: [PROGRAM_TYPE.MDF],
    },
    {
      name: "startDate",
      label: "Planned Milestone/Activity Start Date",
      component: COMPONENT_TYPE.DATEPICKER,
      auto: false,
      Required: [
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "endDate",
      label: "Planned Milestone/Activity End Date",
      component: COMPONENT_TYPE.DATEPICKER,
      auto: false,
      Required: [
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "dueDate",
      label: "Claim Due Date",
      description:
        "All claims must be submitted before this date with the required Proof of Performance attached. The claim due date is calculated as 30 days after the activity planned end date and prior to December 15 of each year.",
      component: COMPONENT_TYPE.DATEPICKER,
      auto: true,
      Required: [
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "totalCostOfMdfActivity",
      label: "Total Cost of MDF Activity (Local Currency)",
      component: COMPONENT_TYPE.INPUT,
      type: "money",
      auto: false,
      Required: [PROGRAM_TYPE.MDF],
    },
    {
      name: "requestedMdfCashFunding",
      label: "Requested MDF Cash Funding (Local Currency)",
      component: COMPONENT_TYPE.INPUT,
      type: "money",
      auto: false,
      Required: [PROGRAM_TYPE.MDF],
    },
    {
      name: "mdfFundingPercentage",
      label: "MDF Funding %",
      description:
        "AWS will reimburse up to 50% of the total cash you spend on your activity.",
      component: COMPONENT_TYPE.INPUT,
      auto: true,
      Required: [PROGRAM_TYPE.MDF],
    },
    {
      name: "mdfNumberOfProjectedLeads",
      label: "MDF Campaign # of projected leads",
      component: COMPONENT_TYPE.INPUT,
      auto: false,
      type: "string",
      pattern: "^[0-9]*$",
      errorMessage: "must be a number.",
      Required: [PROGRAM_TYPE.MDF],
    },
    {
      name: "percentageCompletion",
      label: "% of Project Complete at this Milestone",
      description:
        "Input the percentage of work completed at time of milestone claim.",
      component: COMPONENT_TYPE.INPUT,
      auto: false,
      type: "string",
      pattern: "^[0-9][0-9]?$|^100$",
      errorMessage: "must be a whole number less than 100.",
      Required: [PROGRAM_TYPE.SMP],
      Optional: [PROGRAM_TYPE.PIF],
    },
    {
      name: "expectedRevenueRamp",
      label: "Expected Revenue Ramp for the Milestone",
      description:
        "Please input the expected quarterly revenue ramp, which will be validated at time of claim for payment.",
      placeholder: "Quarterly revenue ramp should be shown in USD",
      component: COMPONENT_TYPE.INPUT,
      auto: false,
      type: "money",
      pattern: "^[0-9]*$",
      errorMessage: "must be a number.",
      Required: [PROGRAM_TYPE.SMP],
      Optional: [PROGRAM_TYPE.PIF],
    },
  ],
};

export const cashClaimActualsSection = {
  title: SECTION.CASH_CLAIM_ACTUALS,
  name: SECTION_NAME.CASH_CLAIM_ACTUALS,
  supportedProgram: [
    PROGRAM_TYPE.MISC,
    PROGRAM_TYPE.MDF,
    PROGRAM_TYPE.SMP,
    PROGRAM_TYPE.POC,
    PROGRAM_TYPE.PIF,
  ],
  fields: [
    {
      name: "claimAmount",
      label: "Claim Amount (Local Currency)",
      placeholder: "Enter the amount you would like to claim.",
      component: COMPONENT_TYPE.INPUT,
      type: "money",
      Required: [
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "awsAccountId",
      label: "AWS Account ID for Actual Delivery",
      description:
        "Please provide the AWS Account ID associated with this activity or project, and if it's different from the AWS Account ID provided earlier.",
      component: COMPONENT_TYPE.INPUT,
      type: "string",
      pattern: "^[0-9]{12}$",
      errorMessage: "must be a 12 digit number.",
      Optional: [
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "startDate",
      label: "Actual Milestone/Activity Start Date",
      component: COMPONENT_TYPE.DATEPICKER,
      Required: [
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "endDate",
      label: "Actual Milestone/Activity End Date",
      component: COMPONENT_TYPE.DATEPICKER,
      Required: [
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "productionDate",
      label: "Actual Production Date",
      component: COMPONENT_TYPE.DATEPICKER,
      Optional: [
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "customerSignOffAttached",
      label: "Proof-Of-Performance Attached",
      component: COMPONENT_TYPE.CHECKBOX,
      Required: [
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
      ],
    },
    {
      name: "completed",
      label: "Claim Completed",
      auto: true,
      component: COMPONENT_TYPE.CHECKBOX,
      Required: [
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
  ],
};

export const cashClaimInvoicePaymentSection = {
  title: SECTION.CASH_CLAIM_INVOICE_PAYMENT,
  name: SECTION_NAME.CASH_CLAIM_INVOICE_PAYMENT,
  supportedProgram: [
    PROGRAM_TYPE.MISC,
    PROGRAM_TYPE.MDF,
    PROGRAM_TYPE.SMP,
    PROGRAM_TYPE.POC,
    PROGRAM_TYPE.PIF,
  ],
  fields: [
    {
      name: "currency",
      label: "Invoice Currency",
      component: COMPONENT_TYPE.INPUT,
      key: "currencies",
      auto: true,
      Required: [
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "amount",
      label: "Invoice Amount (Local Currency)",
      component: COMPONENT_TYPE.INPUT,
      type: "money",
      auto: true,
      Required: [
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "scheduledPaidDate",
      label: "Scheduled Paid Date",
      component: COMPONENT_TYPE.DATEPICKER,
      auto: true,
      Required: [
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
  ],
};

export const creditRequestDetailsSection = {
  title: SECTION.CREDIT_REQUEST_DETAILS,
  name: SECTION_NAME.CREDIT_REQUEST_DETAILS,
  supportedProgram: [
    PROGRAM_TYPE.SANDBOX,
    PROGRAM_TYPE.MISC,
    PROGRAM_TYPE.MDF,
    PROGRAM_TYPE.POC,
    PROGRAM_TYPE.PIF,
  ],
  fields: [
    {
      name: "valuePerCode",
      label: "Value per Credit Code (USD)",
      auto: false,
      component: COMPONENT_TYPE.INPUT,
      type: "money",
      Required: [
        PROGRAM_TYPE.SANDBOX,
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "numberOfCodes",
      label: "Number of Codes",
      auto: false,
      component: COMPONENT_TYPE.INPUT,
      type: "string",
      pattern: "^[0-9]*$",
      errorMessage: "must be a number.",
      Required: [
        PROGRAM_TYPE.SANDBOX,
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "requestedCreditAmount",
      label: "Requested Credit Amount (USD)",
      auto: true,
      type: "money",
      component: COMPONENT_TYPE.INPUT,
      Required: [
        PROGRAM_TYPE.SANDBOX,
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "requestedCreditAmountEditable",
      label: "Requested Credit Amount (USD)",
      auto: false,
      type: "money",
      component: COMPONENT_TYPE.INPUT,
      Optional: [],
    },
    {
      name: "totalCostOfActivity",
      label: "Total Cost of Activity (Local Currency)",
      description: "Total cost on SOW or Project Plan.",
      placeholder:
        "This is the total cash expense you expect to pay at the end of the activity",
      component: COMPONENT_TYPE.INPUT,
      type: "money",
      auto: false,
      Required: [PROGRAM_TYPE.POC],
    },
    {
      name: "currency",
      label: "Currency",
      description:
        "The currency you select must match the preferred currency selected in your company's Amazon Payee Central.",
      component: COMPONENT_TYPE.SELECT,
      key: "currencies",
      auto: false,
      Required: [PROGRAM_TYPE.POC],
    },
    {
      name: "awsAccountId",
      label: "AWS Account ID",
      description:
        'Credits will be auto-redeemed to the AWS Account ID used for this project. If you need to update the ID, please go back to the "Project Information" step.',
      auto: true,
      component: COMPONENT_TYPE.INPUT,
      type: "string",
      pattern: "^[0-9]{12}$",
      errorMessage: "must be a 12 digit number.",
      Required: [PROGRAM_TYPE.SANDBOX, PROGRAM_TYPE.MISC, PROGRAM_TYPE.SMP],
      Optional: [PROGRAM_TYPE.PIF],
    },
    {
      name: "mdfCreditActivityType",
      label: "MDF Credit Activity Type",
      key: "mdfCreditActivityType",
      component: COMPONENT_TYPE.SELECT,
      auto: false,
      Required: [PROGRAM_TYPE.MDF],
    },
    {
      name: "approvedCreditAmount",
      label: "Approved Credit Amount (USD)",
      auto: true,
      hidden: true,
      type: "money",
      component: COMPONENT_TYPE.INPUT,
      Required: [
        PROGRAM_TYPE.SANDBOX,
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "issuedCreditAmount",
      label: "Issued Credit Amount (USD)",
      auto: true,
      hidden: true,
      type: "money",
      component: COMPONENT_TYPE.INPUT,
      Required: [
        PROGRAM_TYPE.SANDBOX,
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "secondTrancheClaimConsent",
      label: "Second Tranche Claim Consent",
      auto: true,
      component: COMPONENT_TYPE.INPUT,
      Required: [PROGRAM_TYPE.MISC],
    },
  ],
};

export const attachmentsSection = {
  title: SECTION.ATTACHMENTS,
  name: SECTION_NAME.ATTACHMENTS,
  supportedProgram: [
    PROGRAM_TYPE.SANDBOX,
    PROGRAM_TYPE.MISC,
    PROGRAM_TYPE.MDF,
    PROGRAM_TYPE.SMP,
    PROGRAM_TYPE.POC,
    PROGRAM_TYPE.PIF,
  ],
  fields: [
    {
      name: "attachment",
      label:
        "Please attach all supporting documents as required by each funding type, e.g., Project Plan/Checklist, AWS Pricing Calculator, Migration Capability Review, etc.",
      Required: [
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.SANDBOX,
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
      component: COMPONENT_TYPE.FILE_UPLOAD,
    },
  ],
};

export const LAYOUT = [
  {
    title: SECTION.FUND_REQUEST_INFORMATION,
    name: SECTION_NAME.FUND_REQUEST_INFORMATION,
    supportedProgram: [
      PROGRAM_TYPE.SANDBOX,
      PROGRAM_TYPE.MISC,
      PROGRAM_TYPE.MDF,
      PROGRAM_TYPE.SMP,
      PROGRAM_TYPE.POC,
      PROGRAM_TYPE.PIF,
    ],
    sections: [SECTION.FUND_REQUEST_INFORMATION],
  },
  {
    title: SECTION.OPPORTUNITY_INFORMATION,
    name: SECTION_NAME.OPPORTUNITY_INFORMATION,
    supportedProgram: [
      PROGRAM_TYPE.MISC,
      PROGRAM_TYPE.SMP,
      PROGRAM_TYPE.POC,
      PROGRAM_TYPE.PIF,
    ],
    sections: [SECTION.OPPORTUNITY_INFORMATION],
  },
  {
    title: SECTION.PROJECT_INFORMATION,
    name: SECTION_NAME.PROJECT_INFORMATION,
    supportedProgram: [
      PROGRAM_TYPE.SANDBOX,
      PROGRAM_TYPE.MISC,
      PROGRAM_TYPE.MDF,
      PROGRAM_TYPE.SMP,
      PROGRAM_TYPE.POC,
      PROGRAM_TYPE.PIF,
    ],
    sections: [SECTION.PROJECT_INFORMATION],
  },
  {
    title: SECTION.MIGRATION_WORKLOADS,
    name: SECTION_NAME.MIGRATION_WORKLOADS,
    supportedProgram: [PROGRAM_TYPE.SMP],
    multiSection: {
      title: SECTION.MIGRATION_WORKLOADS,
      minSections: 1,
      maxSections: 7,
      addButtonText: "Add Another Workload",
      addButtonMessage:
        'Click the "Add Another Workload" button to add more workloads.',
      addButtonDisabledMessage: `Max number of workloads is 7.`,
    },
    sections: [SECTION.MIGRATION_WORKLOADS],
  },
  {
    title: SECTION.CASH_REQUEST_INFORMATION,
    name: SECTION_NAME.CASH_REQUEST_INFORMATION,
    supportedProgram: [
      PROGRAM_TYPE.MISC,
      PROGRAM_TYPE.MDF,
      PROGRAM_TYPE.SMP,
      PROGRAM_TYPE.POC,
      PROGRAM_TYPE.PIF,
    ],
    supportedFundingType: [FUNDING_TYPE.CASH, FUNDING_TYPE.COMBO],
    multiSection: {
      title: SECTION.CASH_CLAIM_PLANS,
      minSections: 1,
      maxSections: 10,
      addButtonText: "Add Another Activity/Milestone",
      addButtonMessage:
        'Click the "Add Another Activity/Milestone" button to add more claims.',
      addButtonDisabledMessage: `Max number of cash claims is 10.`,
    },
    sections: [SECTION.CASH_REQUEST_INFORMATION, SECTION.CASH_CLAIM_PLANS],
  },
  {
    title: SECTION.CREDIT_REQUEST_DETAILS,
    name: SECTION_NAME.CREDIT_REQUEST_DETAILS,
    supportedProgram: [
      PROGRAM_TYPE.SANDBOX,
      PROGRAM_TYPE.MISC,
      PROGRAM_TYPE.MDF,
      PROGRAM_TYPE.POC,
      PROGRAM_TYPE.PIF,
    ],
    supportedFundingType: [FUNDING_TYPE.CREDIT, FUNDING_TYPE.COMBO],
    sections: [SECTION.CREDIT_REQUEST_DETAILS],
  },
  {
    title: SECTION.ATTACHMENTS,
    name: SECTION_NAME.ATTACHMENTS,
    supportedProgram: [
      PROGRAM_TYPE.SANDBOX,
      PROGRAM_TYPE.MISC,
      PROGRAM_TYPE.MDF,
      PROGRAM_TYPE.SMP,
      PROGRAM_TYPE.POC,
      PROGRAM_TYPE.PIF,
    ],
    sections: [SECTION.ATTACHMENTS],
  },
  {
    title: SECTION.FUND_REQUEST_INFORMATION_V2,
    name: SECTION_NAME.FUND_REQUEST_INFORMATION_V2,
    supportedProgram: [PROGRAM_TYPE.MAP2024],
    sections: [SECTION.FUND_REQUEST_INFORMATION_V2],
  },
  {
    title: SECTION.OPPORTUNITY_INFORMATION_V2,
    name: SECTION_NAME.OPPORTUNITY_INFORMATION_V2,
    supportedProgram: [PROGRAM_TYPE.MAP2024],
    sections: [SECTION.OPPORTUNITY_INFORMATION_V2],
  },
  {
    title: SECTION.PROJECT_INFORMATION_V2,
    name: SECTION_NAME.PROJECT_INFORMATION_V2,
    supportedProgram: [PROGRAM_TYPE.MAP2024],
    sections: [SECTION.PROJECT_INFORMATION_V2],
  },
  {
    title: SECTION.CASH_REQUEST_INFORMATION_V2,
    name: SECTION_NAME.CASH_REQUEST_INFORMATION_V2,
    supportedProgram: [PROGRAM_TYPE.MAP2024],
    supportedFundingType: [FUNDING_TYPE.CASH],
    sections: [SECTION.CASH_REQUEST_INFORMATION_V2],
  },
  {
    title: SECTION.CASH_CLAIM_PLANS_V2,
    name: SECTION.CASH_CLAIM_PLANS_V2,
    supportedProgram: [PROGRAM_TYPE.MAP2024],
    supportedFundingType: [FUNDING_TYPE.CASH],
    sections: [SECTION.CASH_CLAIM_PLANS_V2],
  },
  {
    title: SECTION.ATTACHMENTS_V2,
    name: SECTION_NAME.ATTACHMENTS_V2,
    supportedProgram: [PROGRAM_TYPE.MAP2024],
    sections: [SECTION.ATTACHMENTS_V2],
  },
];

export const filterFields = ({
  section,
  program,
  subProgram,
  hide,
  flag = false,
  fieldName = null,
}) => {
  let fields = section.fields.filter(
    (field) =>
      (field.Required && field.Required.includes(program)) ||
      (field.Optional && field.Optional.includes(program))
  );

  if (hide) {
    fields = fields.filter((field) => !field.hidden);
  }
  if (flag) {
    fields.forEach((field) => {
      if (fieldName && fieldName === field.name) {
        field.hidden = false;
      }
    });
  } else {
    fields = fields.filter((field) => fieldName !== field.name);
  }

  fields = requireFieldsForWellArchitected(program, subProgram, fields);
  return fields;
};

export const createTemplate = (program) => {
  const templateLayout = LAYOUT.filter((mainSection) =>
    mainSection.supportedProgram.includes(program)
  );

  const template = {};
  for (let sectionTemplate of templateLayout) {
    template[sectionTemplate.name] = sectionTemplate;
  }

  return template;
};

export const schema = {
  [PROGRAM_TYPE.SANDBOX]: createTemplate(PROGRAM_TYPE.SANDBOX),
  [PROGRAM_TYPE.MDF]: createTemplate(PROGRAM_TYPE.MDF),
  [PROGRAM_TYPE.MISC]: createTemplate(PROGRAM_TYPE.MISC),
  [PROGRAM_TYPE.SMP]: createTemplate(PROGRAM_TYPE.SMP),
  [PROGRAM_TYPE.POC]: createTemplate(PROGRAM_TYPE.POC),
  [PROGRAM_TYPE.PIF]: createTemplate(PROGRAM_TYPE.PIF),
  [PROGRAM_TYPE.MAP2024]: createTemplate(PROGRAM_TYPE.MAP2024),
  [SECTION.FUND_REQUEST_INFORMATION]: fundRequestInformationSection,
  [SECTION.OPPORTUNITY_INFORMATION]: opportunityInformationSection,
  [SECTION.PROJECT_INFORMATION]: projectInformationSection,
  [SECTION.PUBLIC_SECTOR_DETAILS]: publicSectorDetailsSection,
  [SECTION.MIGRATION_WORKLOADS]: migrationWorkloads,
  [SECTION.CASH_REQUEST_INFORMATION]: cashRequestInformationSection,
  [SECTION.CASH_CLAIM_PLANS]: cashClaimPlansSection,
  [SECTION.CASH_CLAIM_ACTUALS]: cashClaimActualsSection,
  [SECTION.CASH_CLAIM_INVOICE_PAYMENT]: cashClaimInvoicePaymentSection,
  [SECTION.CREDIT_REQUEST_DETAILS]: creditRequestDetailsSection,
  [SECTION.ATTACHMENTS]: attachmentsSection,
  [SECTION.FUND_REQUEST_INFORMATION_V2]: fundRequestInformationSectionV2,
  [SECTION.OPPORTUNITY_INFORMATION_V2]: opportunityInformationSectionV2,
  [SECTION.PROJECT_INFORMATION_V2]: projectInformationSectionV2,
  [SECTION.CASH_REQUEST_INFORMATION_V2]: cashRequestInformationSectionV2,
  [SECTION.CASH_CLAIM_PLANS_V2]: cashClaimPlansSectionV2,
  [SECTION.CASH_CLAIM_ACTUALS_V2]: cashClaimActualsSectionV2,
  [SECTION.CASH_CLAIM_INVOICE_PAYMENT_V2]: cashClaimInvoicePaymentSectionV2,
  [SECTION.ATTACHMENTS_V2]: attachmentsSectionV2,
};

//TODO: Finish unit test
export const getTemplate = ({ fundingType, migrationPhase, program }) => {
  const fundRequestTemplate = LAYOUT.filter((section) =>
    section.supportedFundingType
      ? section.supportedFundingType.includes(fundingType) &&
        section.supportedProgram.includes(program)
      : section.supportedProgram.includes(program)
  );

  const template = {};
  for (let sectionTemplate of fundRequestTemplate) {
    if (
      program === PROGRAM_TYPE.SMP &&
      migrationPhase &&
      migrationPhase !== "Migrate and Modernize Phase" &&
      sectionTemplate.name === SECTION_NAME.MIGRATION_WORKLOADS
    ) {
      continue;
    }

    template[sectionTemplate.name] = sectionTemplate;
  }

  return template;
};

const requireFieldsForWellArchitected = (program, subProgram, fields) => {
  return fields.map((field) => {
    if (FIELDS_REQUIRED_FOR_WELL_ARCHITECTED.includes(field.name)) {
      if (subProgram === WELL_ARCHITECTED_PROGRAM_NAME) {
        if (field.Required) {
          field.Required.push(program);
        } else {
          field.Required = [program];
        }
      } else {
        if (field.Required) {
          field.Required = field.Required.filter(
            (program) => program !== PROGRAM_TYPE.PIF
          );
        }
      }
      return field;
    }
    return field;
  });
};
