import React, { useState } from "react";
import { Header, SpaceBetween } from "@amzn/awsui-components-react";
import Button from "@amzn/awsui-components-react/polaris/button";
import {
  ERROR_TYPE,
  hasError,
  retryApiCall,
} from "../../../../shared/util/services/data/DataService";
import { changeAssignee } from "../../../util/services/data/InternalDataService";
import {
  SubmitFactory,
  RejectFactory,
} from "../../../util/services/data/dataFactory";
import { setErrorMessage } from "../../../../shared/util/common/helper";
import ChangeAssignee from "../ChangeAssignee";
import PropTypes from "prop-types";
import { canComplete, disableReviewButtons } from "./util";
import { STAGE } from "../../../../shared/util/constants/fundRequestStatusType";
import ChooseCommentModal from "../../common/CommentModal/ChooseCommentModal";
import { INTERNAL_REVIEW_ACTION_TYPE } from "../../common/CommentModal/reviewActionType";
import {
  checkLoading,
  getReviewActionType,
} from "../../common/CommentModal/util";
import AuditButton from "../Audit/AuditButton";
import { PAGES } from "../../../util/constants/pages";
import { Extend } from "../Extend";
import MigrationEngagementAlert from "./MigrationEngagementAlert";

export const FundingReviewHeader = ({
  history,
  fundRequestId,
  program,
  setNotificationsItems,
  status,
  stage,
  page,
  assignee,
  fundingType,
  refresh,
  fundingData,
}) => {
  const [isLoading, setIsLoading] = useState({
    reject: false,
    approve: false,
    completed: false,
    changeAssignee: false,
    extend: false,
  });

  const [isVisible, setIsVisible] = useState({
    reject: false,
    approve: false,
    completed: false,
    changeAssignee: false,
    extend: false,
  });

  const updateLoading = (actionName, loading) => {
    setIsLoading({ ...isLoading, [actionName]: loading });
  };

  const updateVisibility = (actionName, visibility) => {
    setIsVisible({ ...isVisible, [actionName]: visibility });
  };

  const submitChangeAssignee = async (
    newAssignee,
    assigneeType,
    internalComment
  ) => {
    updateLoading(INTERNAL_REVIEW_ACTION_TYPE.CHANGE_ASSIGNEE, true);
    try {
      const response = await changeAssignee({
        payload: {
          assignee: { assigneeId: newAssignee, assigneeType },
          internalComment: internalComment,
        },
        fundRequestId,
      });

      if (hasError(response) && response.errorType === ERROR_TYPE.BANNER) {
        setErrorMessage({
          setNotificationsItems,
          content: response.message,
          status: response.status,
        });
        updateLoading(INTERNAL_REVIEW_ACTION_TYPE.CHANGE_ASSIGNEE, false);
        updateVisibility(INTERNAL_REVIEW_ACTION_TYPE.CHANGE_ASSIGNEE, false);
        return;
      }

      history.push("/dashboard", {
        message: "Assignee has been successfully changed.",
      });
    } catch (e) {
      console.error(e);
      setErrorMessage({
        setNotificationsItems,
      });
      updateLoading(INTERNAL_REVIEW_ACTION_TYPE.CHANGE_ASSIGNEE, false);
      updateVisibility(INTERNAL_REVIEW_ACTION_TYPE.CHANGE_ASSIGNEE, false);
    }
  };

  const approveFundRequest = async (payload) => {
    updateLoading(INTERNAL_REVIEW_ACTION_TYPE.APPROVE, true);
    try {
      const response = await retryApiCall({
        callApi: SubmitFactory(stage)({ payload }),
      });

      if (hasError(response) && response.errorType === ERROR_TYPE.BANNER) {
        setErrorMessage({
          setNotificationsItems,
          content: response.message,
          status: response.status,
        });
        updateLoading(INTERNAL_REVIEW_ACTION_TYPE.APPROVE, false);
        updateVisibility(INTERNAL_REVIEW_ACTION_TYPE.APPROVE, false);
        return;
      }

      history.push("/dashboard", {
        action: "Update",
        message: "The fund request has been successfully approved.",
      });
    } catch (e) {
      console.error(e);
      setErrorMessage({
        setNotificationsItems,
      });
      updateLoading(INTERNAL_REVIEW_ACTION_TYPE.APPROVE, false);
      updateVisibility(INTERNAL_REVIEW_ACTION_TYPE.APPROVE, false);
    }
  };

  const rejectFundRequest = async (payload) => {
    updateLoading(INTERNAL_REVIEW_ACTION_TYPE.REJECT, true);
    try {
      const response = await retryApiCall({
        callApi: RejectFactory(stage)({ payload }),
      });

      if (hasError(response) && response.errorType === ERROR_TYPE.BANNER) {
        setErrorMessage({
          setNotificationsItems,
          content: response.message,
          status: response.status,
        });
        updateLoading(INTERNAL_REVIEW_ACTION_TYPE.REJECT, false);
        updateVisibility(INTERNAL_REVIEW_ACTION_TYPE.REJECT, false);
        return;
      }

      history.push("/dashboard", {
        action: "Update",
        message: "The fund request has been successfully rejected.",
      });
    } catch (e) {
      console.error(e);
      setErrorMessage({
        setNotificationsItems,
      });
      updateLoading(INTERNAL_REVIEW_ACTION_TYPE.REJECT, false);
      updateVisibility(INTERNAL_REVIEW_ACTION_TYPE.REJECT, false);
    }
  };

  const completeFundRequest = async (payload) => {
    updateLoading(INTERNAL_REVIEW_ACTION_TYPE.COMPLETE, true);
    try {
      const response = await retryApiCall({
        callApi: SubmitFactory(STAGE.COMPLETED)({ payload }),
      });

      if (hasError(response) && response.errorType === ERROR_TYPE.BANNER) {
        setErrorMessage({
          setNotificationsItems,
          content: response.message,
          status: response.status,
        });
        updateLoading(INTERNAL_REVIEW_ACTION_TYPE.COMPLETE, false);
        updateVisibility(INTERNAL_REVIEW_ACTION_TYPE.COMPLETE, false);
        return;
      }

      history.push("/dashboard", {
        action: "Update",
        message: "The fund request has been successfully completed.",
      });
    } catch (e) {
      console.error(e);
      setErrorMessage({
        setNotificationsItems,
      });
      updateLoading(INTERNAL_REVIEW_ACTION_TYPE.COMPLETE, false);
      updateVisibility(INTERNAL_REVIEW_ACTION_TYPE.COMPLETE, false);
    }
  };

  const reviewActions = {
    [INTERNAL_REVIEW_ACTION_TYPE.APPROVE]: approveFundRequest,
    [INTERNAL_REVIEW_ACTION_TYPE.REJECT]: rejectFundRequest,
    [INTERNAL_REVIEW_ACTION_TYPE.COMPLETE]: completeFundRequest,
  };

  const selectedReviewAction = getReviewActionType({ isVisible });
  const isCurrentlyLoading = checkLoading({ isLoading });

  return (
    <React.Fragment>
      <MigrationEngagementAlert
        fundingData={fundingData}
        page={page}
      ></MigrationEngagementAlert>
      <br />
      <Header
        variant="h1"
        description={`Fund Request Id: ${fundRequestId}`}
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              iconName="refresh"
              variant="normal"
              loading={isCurrentlyLoading}
              onClick={async () => await refresh()}
            />
            <Button
              disabled={
                disableReviewButtons({
                  status,
                  stage,
                  page,
                  assignee,
                }) || canComplete({ fundingType, page, stage })
              }
              loading={isCurrentlyLoading}
              onClick={() => {
                updateVisibility(INTERNAL_REVIEW_ACTION_TYPE.APPROVE, true);
              }}
            >
              Approve
            </Button>
            <Button
              disabled={
                disableReviewButtons({
                  status,
                  stage,
                  page,
                  assignee,
                }) || canComplete({ fundingType, page, stage })
              }
              loading={isCurrentlyLoading}
              onClick={() => {
                updateVisibility(INTERNAL_REVIEW_ACTION_TYPE.REJECT, true);
              }}
            >
              Reject
            </Button>
            <Button
              onClick={() => {
                updateVisibility(
                  INTERNAL_REVIEW_ACTION_TYPE.CHANGE_ASSIGNEE,
                  true
                );
              }}
            >
              Reassign
            </Button>
            {canComplete({ fundingType, page, stage }) ? (
              <Button
                variant="primary"
                loading={isCurrentlyLoading}
                onClick={() =>
                  updateVisibility(INTERNAL_REVIEW_ACTION_TYPE.COMPLETE, true)
                }
              >
                Complete
              </Button>
            ) : (
              ""
            )}
            {page !== PAGES.CASH_CLAIM_APPROVAL ? (
              <AuditButton
                fundRequestId={fundRequestId}
                page={page}
                setNotificationsItems={setNotificationsItems}
              />
            ) : (
              ""
            )}
            <Extend
              fundRequests={[{ fundRequestId: fundRequestId, stage: stage }]}
              setNotificationsItems={setNotificationsItems}
            />
          </SpaceBetween>
        }
      >
        {program}
      </Header>
      <ChangeAssignee
        isLoading={isLoading[INTERNAL_REVIEW_ACTION_TYPE.CHANGE_ASSIGNEE]}
        isVisible={isVisible[INTERNAL_REVIEW_ACTION_TYPE.CHANGE_ASSIGNEE]}
        updateVisibility={updateVisibility}
        submitChangeAssignee={submitChangeAssignee}
      />
      <ChooseCommentModal
        reviewActionType={selectedReviewAction}
        reviewAction={reviewActions[selectedReviewAction]}
        isLoading={isLoading[selectedReviewAction]}
        isVisible={isVisible[selectedReviewAction]}
        updateVisibility={updateVisibility}
        stage={stage}
      />
    </React.Fragment>
  );
};

FundingReviewHeader.propTypes = {
  history: PropTypes.any,
  isLoading: PropTypes.object,
  setNotificationsItems: PropTypes.func,
  fundRequestId: PropTypes.string,
  program: PropTypes.string,
  visible: PropTypes.bool,
  status: PropTypes.string,
  stage: PropTypes.string,
  page: PropTypes.string,
  assignee: PropTypes.object,
  fundingType: PropTypes.string,
  refresh: PropTypes.func,
  fundingData: PropTypes.object,
};
