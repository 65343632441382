import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useCollection } from "@amzn/awsui-collection-hooks";
import Table from "@amzn/awsui-components-react/polaris/table";
import Pagination from "@amzn/awsui-components-react/polaris/pagination";
import TextFilter from "@amzn/awsui-components-react/polaris/text-filter";
import Grid from "@amzn/awsui-components-react/polaris/grid";
import Select from "@amzn/awsui-components-react/polaris/select";
import {
  columnDefinitions,
  defaultBizUnit,
  defaultCreatedYear,
  defaultStage,
  defaultStatus,
} from "../Inputs/OpportunitySelect/config";
import {
  clearFilter,
  filterItem,
  prepareCreatedDateSelectOptions,
} from "../Inputs/OpportunitySelect/util";
import { Alert } from "@amzn/awsui-components-react";
import TableHeader from "../../../../../external/components/FundRequest/StepsContainer/Inputs/OpportunitySelect/TableHeader";
import TableEmptyState from "../../../common/TableEmptyState";
import TableNoMatchState from "../../../common/TableNoMatchState";
import {
  getFundRequest,
  loadOpportunities,
} from "../../../../util/services/data/FundRequestService";
import { prepareSelectOptions } from "../../../Dashboard/CreditOverview/util";
import { filterFields, getTemplate, schema } from "../../../../config/schema";
import useFields from "../../../../../shared/util/hooks/useFields";
import { SECTION } from "../../../../../shared/util/constants/section";

const OpportunityInformationSectionContainer = ({
  data,
  setData,
  dataLoad,
  dataInput,
  updateErrors,
  fieldToIdMap,
  setFieldToIdMap,
  stepTitle,
}) => {
  const pageSize = 10;
  const [opportunities, setOpportunities] = useState([]);
  const [status, setStatus] = useState(defaultStatus);
  const [stage, setStage] = useState(defaultStage);
  const [customerBizUnit, setCustomerBizUnit] = useState(defaultBizUnit);
  const [createdDate, setCreatedDate] = useState(defaultCreatedYear);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedItems, setSelectedItems] = useState([]);
  let selectedOpportunity = "";
  const OPPORTUNITY_RANGE_DEFAULT_MONTHS = "24";
  const migrationPhase = data["migrationPhase"];
  const fundingProgram = data["program"];
  const fundingSubProgram = data["subProgram"];
  const stepTemplate = schema[stepTitle];
  const [{ fieldTemplate }] = useFields({
    data,
    stepTemplate,
    fieldToIdMap,
    updateFieldMap: setFieldToIdMap,
    dataLoad,
    dataInput,
    getTemplate: getTemplate,
    filterFields,
    schema,
    getFundRequest,
  });

  if (Object.keys(fieldTemplate).length > 0) {
    selectedOpportunity =
      data[
        fieldTemplate.opportunityInformation.fields.find(
          (field) => field.name === "opportunityId"
        ).id
      ];
  }

  const gatherOpportunities = async (
    timeRange,
    fundingProgram,
    migrationPhase,
    fundingSubProgram
  ) => {
    setIsLoading(true);
    let opportunities = await loadOpportunities(
      timeRange,
      fundingProgram,
      migrationPhase,
      fundingSubProgram
    );
    setOpportunities(opportunities);
    setIsLoading(false);
  };

  useEffect(async () => {
    await gatherOpportunities(
      OPPORTUNITY_RANGE_DEFAULT_MONTHS,
      fundingProgram,
      migrationPhase,
      fundingSubProgram
    );
  }, []);

  const { items, actions, collectionProps, filterProps, paginationProps } =
    useCollection(opportunities || [], {
      filtering: {
        empty: <TableEmptyState resourceName="opportunities" />,
        noMatch: (
          <TableNoMatchState
            onClearFilter={() =>
              clearFilter({
                actions,
                setStatus,
                setStage,
                setCustomerBizUnit,
                setCreatedDate,
              })
            }
            resourceName="opportunities"
          />
        ),
        filteringFunction: (item, filteringText) => {
          return filterItem({
            item,
            filteringText,
            status,
            stage,
            customerBizUnit,
            createdDate,
          });
        },
      },
      pagination: { pageSize },
      sorting: {
        defaultState: {
          sortingColumn: { sortingField: "createdDate" },
          isDescending: true,
        },
      },
      selection: {},
    });

  const selectStatusOptions = prepareSelectOptions(
    opportunities || [],
    "status",
    defaultStatus
  );

  const selectStageOptions = prepareSelectOptions(
    opportunities || [],
    "aceValidationStage",
    defaultStage
  );

  const selectSectorOptions = prepareSelectOptions(
    opportunities || [],
    "customerBizUnit",
    defaultBizUnit
  );

  const selectCreatedDateOptions = prepareCreatedDateSelectOptions(
    opportunities || [],
    "createdDate",
    defaultCreatedYear
  );

  const opportunityStep = schema[SECTION.OPPORTUNITY_INFORMATION];
  const updateOpportunity = (opportunity) => {
    let currentValues = {};
    if (opportunity) {
      for (let fieldName in opportunity) {
        const fieldId = fieldToIdMap[opportunityStep.name][fieldName];
        if (fieldId) {
          currentValues[fieldId] = opportunity[fieldName];
        }
      }

      currentValues["aceOpportunityId"] = opportunity["opportunityId"];

      const opportunityId = fieldToIdMap[opportunityStep.name]["opportunityId"];

      currentValues[opportunityId] = opportunity["userFriendlyId"];

      const wwps = opportunity["customerBizUnit"] === "PS";
      const wwpsId = fieldToIdMap[opportunityStep.name]["wwps"];
      currentValues[wwpsId] = wwps;

      const awsPartnerManagerId =
        fieldToIdMap[opportunityStep.name]["awsPartnerManager"];
      const wwpsPdm = opportunity["wwpsPartnerDevelopmentManager"];

      currentValues[awsPartnerManagerId] =
        wwps && wwpsPdm ? wwpsPdm : opportunity["partnerDevelopmentManager"];

      const opportunityOwnerId =
        fieldToIdMap[opportunityStep.name]["opportunityOwner"];
      currentValues[opportunityOwnerId] = opportunity["opportunityOwnerName"];
      dataInput(currentValues);
      updateErrors(opportunityId, "");
    } else {
      const opportunityAttributes = [
        "aceValidationStage",
        "arn",
        "awsPartnerManager",
        "customerCompanyName",
        "opportunityId",
        "opportunityOwner",
        "opportunityOwnership",
        "projectDescription",
        "status",
        "targetCloseDate",
      ];
      for (let opportunityAttribute in opportunityAttributes) {
        const id =
          fieldToIdMap[opportunityStep.name][
            opportunityAttributes[opportunityAttribute]
          ];
        currentValues[id] = null;
      }
      dataInput(currentValues);
    }
  };

  return (
    <div>
      <div hidden={!data["aceOpportunityId"]}>
        <Alert statusIconAriaLabel="" header="Opportunity selected">
          Opportunity Id - {selectedOpportunity}
        </Alert>
        <br />
      </div>

      <Table
        {...collectionProps}
        columnDefinitions={columnDefinitions}
        loading={isLoading || items === undefined}
        loadingText="Loading opportunities"
        items={items}
        selectionType="single"
        filter={
          <Grid
            gridDefinition={[
              { colspan: 4 },
              { colspan: 8 },
              { colspan: 2 },
              { colspan: 2 },
              { colspan: 2 },
              { colspan: 2 },
            ]}
          >
            <TextFilter
              {...filterProps}
              filteringPlaceholder="Find opportunities"
            />

            <br></br>
            <Select
              selectedOption={status}
              onChange={(event) => {
                setStatus(event.detail.selectedOption);
              }}
              options={selectStatusOptions}
            />
            <Select
              selectedOption={stage}
              onChange={(event) => {
                setStage(event.detail.selectedOption);
              }}
              options={selectStageOptions}
            />
            <Select
              selectedOption={customerBizUnit}
              onChange={(event) => {
                setCustomerBizUnit(event.detail.selectedOption);
              }}
              options={selectSectorOptions}
            />
            <Select
              selectedOption={createdDate}
              onChange={(event) => {
                setCreatedDate(event.detail.selectedOption);
              }}
              options={selectCreatedDateOptions}
            />
          </Grid>
        }
        header={
          <TableHeader
            totalItemsCount={opportunities ? opportunities.length : 0}
            fundingProgram={fundingProgram}
            migrationPhase={migrationPhase}
            fundingSubProgram={fundingSubProgram}
            gatherOpportunities={gatherOpportunities}
            data={data}
            setData={setData}
            setSelectedItems={setSelectedItems}
            updateOpportunity={updateOpportunity}
          />
        }
        onSelectionChange={({ detail }) => {
          setSelectedItems(detail.selectedItems);
          updateOpportunity(detail.selectedItems[0]);
        }}
        selectedItems={selectedItems}
        totalItemsCount={opportunities.length} //Option for screen readers
        pagination={<Pagination {...paginationProps} />}
      />
    </div>
  );
};

OpportunityInformationSectionContainer.propTypes = {
  fieldToIdMap: PropTypes.object,
  dataInput: PropTypes.func,
  dataLoad: PropTypes.func,
  updateErrors: PropTypes.func,
  data: PropTypes.object,
  setData: PropTypes.func,
  setFieldToIdMap: PropTypes.func,
  stepTitle: PropTypes.string,
};

export default OpportunityInformationSectionContainer;
