import { generateFundingLinkForDuplicates } from "../../../../util/constants/generateFundingLinkForDuplicates";

export const fundRequestDuplicatesColumnDefinitions = [
  {
    id: "fundRequestId",
    header: "Potential Duplicate Fund Request Id",
    cell: (e) => generateFundingLinkForDuplicates(e.fundRequestId),
  },
  {
    id: "opportunityId",
    header: "Ace Opportunity Id",
    cell: (e) => e.opportunityId,
  },
  {
    id: "programEngagementId",
    header: "Program Engagement Id",
    cell: (e) => (e.programEngagementId ? e.programEngagementId : "-"),
  },
  {
    id: "migrationPhase",
    header: "Migration Phase",
    cell: (e) => (e.migrationPhase ? e.migrationPhase : "-"),
  },
  {
    id: "activityName",
    header: "Activity Name",
    cell: (e) => (e.activityName ? e.activityName : "-"),
  },
  {
    id: "partnerName",
    header: "Partner Name",
    cell: (e) => (e.partnerName ? e.partnerName : "-"),
  },
  {
    id: "approvedAmount",
    header: "Approved Amount",
    cell: (e) => (e.approvedAmount ? e.approvedAmount : "-"),
  },
  {
    id: "stage",
    header: "Stage",
    cell: (e) => e.stage,
  },
];

export const searchableColumns = [
  "fundRequestId",
  "opportunityId",
  "programEngagementId",
  "migrationPhase",
  "activityName",
  "partnerName",
  "approvedAmount",
  "stage",
];
