import React, { useState } from "react";
import PropTypes from "prop-types";
import Header from "@amzn/awsui-components-react/polaris/header";
import SegmentedControl from "@amzn/awsui-components-react/polaris/segmented-control";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import { Button } from "@amzn/awsui-components-react";

const TableHeader = ({
  totalItemsCount,
  fundingProgram,
  migrationPhase,
  fundingSubProgram,
  gatherOpportunities,
  data,
  setSelectedItems,
  updateOpportunity,
}) => {
  const [tableName, setTableName] = useState("Opportunities from past 2 years");
  const [selectedId, setSelectedId] = React.useState(
    "pastTwoYearOpportunities"
  );
  const OPPORTUNITY_RANGE_DEFAULT_MONTHS = "24";
  const OPPORTUNITY_RANGE_ALL = "ALL";

  let OpportunityRangeOptions = [
    {
      text: "View opportunities (past 2 years)",
      id: "pastTwoYearOpportunities",
    },
    {
      text: "View all opportunities",
      id: "allOpportunities",
    },
  ];

  return (
    <Header
      counter={`(${totalItemsCount})`}
      actions={
        <SpaceBetween direction="horizontal" size="xs">
          <Button
            onClick={() => {
              setSelectedItems([]);
              data["aceOpportunityId"] = null;
              updateOpportunity(null);
            }}
          >
            Clear Selection
          </Button>
          <SegmentedControl
            selectedId={selectedId}
            onChange={async ({ detail }) => {
              setSelectedId(detail.selectedId);
              if (detail.selectedId === "pastTwoYearOpportunities") {
                await gatherOpportunities(
                  OPPORTUNITY_RANGE_DEFAULT_MONTHS,
                  fundingProgram,
                  migrationPhase,
                  fundingSubProgram
                );
                setTableName("Opportunities from past 2 years");
              } else {
                await gatherOpportunities(
                  OPPORTUNITY_RANGE_ALL,
                  fundingProgram,
                  migrationPhase,
                  fundingSubProgram
                );
                setTableName("All Opportunities");
              }
            }}
            options={OpportunityRangeOptions}
          />
        </SpaceBetween>
      }
    >
      {tableName}
    </Header>
  );
};

TableHeader.propTypes = {
  totalItemsCount: PropTypes.number,
  fundingProgram: PropTypes.string,
  migrationPhase: PropTypes.string,
  fundingSubProgram: PropTypes.string,
  gatherOpportunities: PropTypes.func,
  data: PropTypes.object,
  setSelectedItems: PropTypes.func,
  updateOpportunity: PropTypes.func,
};

export default TableHeader;
