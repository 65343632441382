import React, { useState } from "react";
import {
  ERROR_TYPE,
  hasError,
  retryApiCall,
} from "../../../../../shared/util/services/data/DataService";
import { setErrorMessage } from "../../../../../shared/util/common/helper";
import { Tabs } from "@amzn/awsui-components-react";
import FundingHistoryTable from "./FundingHistoryTable";
import PropTypes from "prop-types";
import ApprovalHistoryTable from "./ApprovalHistoryTable";
import AttachmentHistoryTable from "./AttachmentHistoryTable";

export const FundingHistorySection = ({
  setNotificationsItems,
  showHelperPanel,
}) => {
  const [hasQuarantinedFile, setHasQuarantinedFile] = useState(false);
  const getFundingHistory = async ({ getFundRequestHistory, setIsLoading }) => {
    const data = [];
    let nextPageToken = "";
    while (nextPageToken != null) {
      try {
        const response = await retryApiCall({
          callApi: getFundRequestHistory({ nextPageToken }),
        });
        //TODO: Add a failure condition
        if (hasError(response) && response.errorType === ERROR_TYPE.BANNER) {
          setErrorMessage({
            setNotificationsItems,
            content: response.message,
            status: response.status,
          });
          setIsLoading(false);
          break;
        }

        data.push(...response.history);
        nextPageToken = response["nextPageToken"];
      } catch (e) {
        console.error(e);
        setIsLoading(false);
        break;
      }
    }
    return data;
  };

  return (
    <React.Fragment>
      <Tabs
        tabs={[
          {
            label: "Approval History",
            id: "approvalHistory",
            content: (
              <ApprovalHistoryTable getFundingHistory={getFundingHistory} />
            ),
          },
          {
            label: "Fund Request History",
            id: "fundRequestHistory",
            content: (
              <FundingHistoryTable getFundingHistory={getFundingHistory} />
            ),
          },
          {
            label: "Attachment History",
            id: "attachmentHistory",
            content: (
              <AttachmentHistoryTable
                setHasQuarantinedFile={setHasQuarantinedFile}
                getFundingHistory={getFundingHistory}
                showHelperPanel={showHelperPanel}
              />
            ),
          },
        ]}
      />
      {hasQuarantinedFile && (
        <span className="table-info">
          *Please refer to helper panel for suggestions on quarantined files
        </span>
      )}
    </React.Fragment>
  );
};

FundingHistorySection.propTypes = {
  setNotificationsItems: PropTypes.func,
  showHelperPanel: PropTypes.func,
};
