import React from "react";

import AppLayout from "@amzn/awsui-components-react/polaris/app-layout";
import BreadcrumbGroup from "@amzn/awsui-components-react/polaris/breadcrumb-group";
import MAPFundRequestCreationWizard from "../../programs/migration-acceleration-program/2024/fund-request/views/MAPFundRequestCreationWizard";
import {
  HelpPanelContentContext,
  HelpPanelContext,
} from "shared/util/context/help/HelpContext";

const CreateFundRequestWizardPage = () => {
  const { isOpen, toggle } = React.useContext(HelpPanelContext);
  const { getHelpContent } = React.useContext(HelpPanelContentContext);
  const Panel = getHelpContent();
  return (
    <AppLayout
      breadcrumbs={
        <BreadcrumbGroup
          items={[
            { text: "Dashboard", href: "/dashboard" },
            {
              text: "Migration Acceleration Program",
              href: "/fund-request/select-program?template=MAP",
            },
            { text: "Create fund request", href: "#" },
          ]}
        />
      }
      contentType="wizard"
      toolsOpen={isOpen}
      onToolsChange={toggle}
      tools={Panel}
      headerSelector=".navbar"
      footerSelector="#f"
      content={<MAPFundRequestCreationWizard />}
      navigationHide
    />
  );
};

export default CreateFundRequestWizardPage;
