import { IFieldSchema } from "external/programs/migration-acceleration-program/2024/fund-request/types/SchemaTypes";
import { FieldType } from "shared/programs/migration-acceleration-program/2024/fund-request/components/fields/FieldComponent";
import { STAGE } from "shared/util/constants/fundRequestStatusType";
import { ValidationType } from "shared/programs/migration-acceleration-program/2024/fund-request/components/fields/validations";

export const CashRequestDetailsEditSchema: IFieldSchema[] = [
  {
    label: "Currency",
    keyName: "currency",
    placeholder: "-",
    fieldType: FieldType.Input,
    editableStages: [],
  },
  {
    label: "Total project cost (local currency)",
    keyName: "totalCostOfActivity",
    placeholder: "-",
    fieldType: FieldType.Input,
    editableStages: [STAGE.BUSINESS_APPROVAL],
    validations: [ValidationType.ValidNumber],
  },
  {
    label: "Total fund request from AWS (local currency)",
    keyName: "cashFundingAmount",
    placeholder: "-",
    fieldType: FieldType.Input,
    editableStages: [STAGE.BUSINESS_APPROVAL],
    validations: [ValidationType.ValidNumber],
  },
  {
    label: "Invoice entity name",
    keyName: "invoiceEntityName",
    placeholder: "-",
    fieldType: FieldType.Input,
    editableStages: [STAGE.BUSINESS_APPROVAL],
  },
  {
    label: "Invoice remit country",
    keyName: "invoiceRemitCountry",
    placeholder: "-",
    fieldType: FieldType.Input,
    editableStages: [STAGE.BUSINESS_APPROVAL],
  },
  {
    label: "Street address",
    keyName: "invoiceRemitStreet",
    placeholder: "-",
    fieldType: FieldType.Input,
    editableStages: [STAGE.BUSINESS_APPROVAL],
  },
  {
    label: "Apartment / suite / unit",
    keyName: "invoiceRemitUnit",
    placeholder: "-",
    fieldType: FieldType.Input,
    editableStages: [STAGE.BUSINESS_APPROVAL],
  },
  {
    label: "City",
    keyName: "invoiceRemitCity",
    placeholder: "-",
    fieldType: FieldType.Input,
    editableStages: [STAGE.BUSINESS_APPROVAL],
  },
  {
    label: "State / region / province",
    keyName: "invoiceRemitState",
    placeholder: "-",
    fieldType: FieldType.Input,
    editableStages: [STAGE.BUSINESS_APPROVAL],
  },
  {
    label: "Postal code",
    keyName: "invoiceRemitPostalCode",
    placeholder: "-",
    fieldType: FieldType.Input,
    editableStages: [STAGE.BUSINESS_APPROVAL],
  },
  {
    label: "Approved cash amount (local currency)",
    keyName: "approvedCashLocalAmount",
    placeholder: "-",
    fieldType: FieldType.Input,
    editableStages: [],
    validations: [ValidationType.ValidNumber],
  },
  {
    label: "USD Conversion Rate",
    keyName: "currencyConversionRateToUsd",
    placeholder: "-",
    fieldType: FieldType.Input,
    editableStages: [],
    validations: [],
    conditional: ({ data }) => data?.currency !== "USD",
  },
  {
    label: "PO requisition number",
    keyName: "poRequestNumber",
    placeholder: "-",
    fieldType: FieldType.Input,
    editableStages: [STAGE.FINANCE_APPROVAL, STAGE.CASH_CLAIM],
  },
  {
    label: "PO number",
    keyName: "poNumber",
    placeholder: "-",
    fieldType: FieldType.Input,
    editableStages: [STAGE.FINANCE_APPROVAL, STAGE.CASH_CLAIM],
  },
  {
    label: "PO issued",
    keyName: "poIssued",
    placeholder: "-",
    fieldType: FieldType.Checkbox,
    editableStages: [STAGE.FINANCE_APPROVAL],
  },
  {
    label: "PO issued date",
    keyName: "poIssuedDate",
    placeholder: "-",
    fieldType: FieldType.DatePicker,
    editableStages: [STAGE.FINANCE_APPROVAL, STAGE.CASH_CLAIM],
  },
  {
    label: "PO amount (local currency)",
    keyName: "poLocalAmount",
    placeholder: "-",
    fieldType: FieldType.Input,
    editableStages: [STAGE.FINANCE_APPROVAL, STAGE.CASH_CLAIM],
    validations: [ValidationType.ValidNumber],
  },
  {
    label: "PO amount (USD)",
    keyName: "poUsdAmount",
    placeholder: "-",
    fieldType: FieldType.Input,
    editableStages: [STAGE.FINANCE_APPROVAL, STAGE.CASH_CLAIM],
    validations: [ValidationType.ValidNumber],
  },
  {
    label: "PO Update Reason",
    keyName: "reason",
    placeholder: "-",
    fieldType: FieldType.Input,
    editableStages: [STAGE.CASH_CLAIM],
  },
];
