import React, { useState } from "react";
import { FormField, Modal, SpaceBetween } from "@amzn/awsui-components-react";
import Input from "@amzn/awsui-components-react/polaris/input";
import DateRangePicker from "@amzn/awsui-components-react/polaris/date-range-picker";
import Textarea from "@amzn/awsui-components-react/polaris/textarea";
import ModalFooter from "./ModalFooter";
import PropTypes from "prop-types";
import {
  DELEGATION_KEYS,
  DELEGATION_MODAL_TYPE,
} from "../../DelegationConstants";

import AssigneeSelection from "../../../Review/AssigneeSelection";

const DelegationModal = ({
  isVisible,
  refresh,
  resetModalState,
  setState,
  showModal,
  state,
}) => {
  const [error, setError] = useState({
    [DELEGATION_KEYS.FROM_KEY]: "",
    [DELEGATION_KEYS.TO_KEY]: "",
    [DELEGATION_KEYS.START_KEY]: "",
    [DELEGATION_KEYS.END_KEY]: "",
    [DELEGATION_KEYS.REASON_KEY]: "",
  });

  const updateValue = (id, value) => {
    setState((prevState) => ({ ...prevState, [id]: value }));
  };

  const updateError = (id, message) => {
    setError((prevState) => ({ ...prevState, [id]: message }));
  };

  const handleAssigneeChange = (value) => {
    updateValue(DELEGATION_KEYS.TO_KEY, value);
  };

  const handleError = (value) => {
    updateError(DELEGATION_KEYS.TO_KEY, value);
  };
  const updateAliasValue = ({ fieldKey, value }) => {
    const aliasRegex = /^[A-Za-z]*$/;
    if (aliasRegex.test(value)) {
      updateError(fieldKey, "");
      updateValue(fieldKey, value);
    } else {
      updateError(fieldKey, "Please enter a valid Amazon alias.");
      updateValue(fieldKey, value);
    }
  };

  return (
    <Modal
      visible={isVisible}
      onDismiss={() => showModal(false)}
      closeAriaLabel="Close modal"
      header={`${
        state.modalType && state.modalType === DELEGATION_MODAL_TYPE.EDIT
          ? "Update"
          : "Create"
      } Delegation`}
      footer={
        <ModalFooter
          payload={state}
          refresh={refresh}
          resetModalState={resetModalState}
          setError={setError}
          showModal={showModal}
        />
      }
    >
      <SpaceBetween size="m">
        <FormField
          label="From"
          description="Delegator"
          errorText={error[DELEGATION_KEYS.FROM_KEY]}
        >
          <Input
            id={DELEGATION_KEYS.FROM_KEY}
            value={state[DELEGATION_KEYS.FROM_KEY]}
            placeholder="Enter a valid amazon alias"
            onChange={({ detail }) => {
              updateAliasValue({
                fieldKey: DELEGATION_KEYS.FROM_KEY,
                value: detail.value,
              });
            }}
          />
        </FormField>
        <FormField
          label="To"
          description="Delegated"
          errorText={error[DELEGATION_KEYS.TO_KEY]}
        >
          <AssigneeSelection
            assigneeType="Person"
            setAssignee={handleAssigneeChange}
            setError={handleError}
          />
        </FormField>
        <SpaceBetween size="s" direction="horizontal">
          <FormField
            label="Delegation Period"
            description="Delegation Time Period"
            errorText={error[DELEGATION_KEYS.DELEGATION_PERIOD]}
          >
            <DateRangePicker
              value={state[DELEGATION_KEYS.DELEGATION_PERIOD]}
              onChange={({ detail }) => {
                updateError(DELEGATION_KEYS.DELEGATION_PERIOD, "");
                updateValue(DELEGATION_KEYS.DELEGATION_PERIOD, detail.value);
              }}
              relativeOptions={[]}
              rangeSelectorMode="absolute-only"
              i18nStrings={{
                todayAriaLabel: "Today",
                nextMonthAriaLabel: "Next month",
                previousMonthAriaLabel: "Previous month",
                customRelativeRangeDurationLabel: "Duration",
                customRelativeRangeDurationPlaceholder: "Enter duration",
                customRelativeRangeOptionLabel: "Custom range",
                customRelativeRangeOptionDescription:
                  "Set a custom range in the past",
                customRelativeRangeUnitLabel: "Unit of time",
                formatRelativeRange: (e) => {
                  const t = 1 === e.amount ? e.unit : `${e.unit}s`;
                  return `Last ${e.amount} ${t}`;
                },
                formatUnit: (e, t) => (1 === t ? e : `${e}s`),
                dateTimeConstraintText: "",
                relativeModeTitle: "Relative range",
                absoluteModeTitle: "Absolute range",
                relativeRangeSelectionHeading: "Choose a range",
                startDateLabel: "Start date",
                endDateLabel: "End date",
                startTimeLabel: "Start time",
                endTimeLabel: "End time",
                clearButtonLabel: "Clear and dismiss",
                cancelButtonLabel: "Cancel",
                applyButtonLabel: "Apply",
              }}
            />
          </FormField>
        </SpaceBetween>
        <FormField
          label="Reason"
          description="Reason for delegation"
          errorText={error[DELEGATION_KEYS.REASON_KEY]}
        >
          <Textarea
            id={DELEGATION_KEYS.REASON_KEY}
            value={state[DELEGATION_KEYS.REASON_KEY]}
            onChange={({ detail }) => {
              updateError(DELEGATION_KEYS.REASON_KEY, "");
              updateValue(DELEGATION_KEYS.REASON_KEY, detail.value);
            }}
          />
        </FormField>
      </SpaceBetween>
    </Modal>
  );
};

DelegationModal.propTypes = {
  isVisible: PropTypes.bool,
  refresh: PropTypes.func,
  resetModalState: PropTypes.func,
  setState: PropTypes.func,
  showModal: PropTypes.func,
  state: PropTypes.object,
};

export default DelegationModal;
