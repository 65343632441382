import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ButtonDropdown from "@amzn/awsui-components-react/polaris/button-dropdown";
import {
  ERROR_TYPE,
  hasError,
  retryApiCall,
} from "../../../../../shared/util/services/data/DataService";
import { getTemplateEligibility } from "../../../../util/services/data/FundRequestService";
import { matchTemplateItemsWithEligibility } from "./util";
import {
  template_items_without_map_and_without_smp,
  template_items_without_map_and_with_smp,
  template_items_with_map_and_without_smp,
} from "./config";
import { setErrorMessage } from "../../../../../shared/util/common/helper";
import { isFeatureEnabled } from "../../../../../shared/util/services/features/FeatureFlagsService";
import { FEATURE_FLAG_ENUMS } from "../../../../../shared/util/constants/featureFlagValues";

const CreateFundRequestDropDown = ({ setNotificationsItems }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [enabledTemplateTypes, setEnabledTemplateTypes] = useState([]);

  useEffect(async () => {
    let templateTypes = [];
    try {
      const [map2024Enabled, oldMapDisabled, response] = await Promise.all([
        await isFeatureEnabled(FEATURE_FLAG_ENUMS.MAP_2024_ENABLED),
        await isFeatureEnabled(FEATURE_FLAG_ENUMS.OLD_MAP_DISABLED),
        await retryApiCall({
          callApi: getTemplateEligibility(),
        }),
      ]);

      if (hasError(response) && response.errorType === ERROR_TYPE.BANNER) {
        setIsLoading(false);
        setErrorMessage({
          setNotificationsItems,
          content: `Failed to get template eligibility: ${response.message}`,
          status: response.status,
        });
        return;
      }

      let template_items = template_items_without_map_and_with_smp;
      if (oldMapDisabled) {
        template_items = template_items_without_map_and_without_smp;
      }
      if (map2024Enabled) {
        template_items = template_items_with_map_and_without_smp;
      }

      if (response && response.length > 0) {
        templateTypes = matchTemplateItemsWithEligibility(
          template_items,
          response
        );
      }

      setEnabledTemplateTypes(templateTypes);
      setIsLoading(false);
    } catch (e) {
      console.error(e);
      setIsLoading(false);
    }
  }, []);

  return (
    <ButtonDropdown
      loading={isLoading}
      variant="primary"
      items={enabledTemplateTypes}
    >
      Create Fund Request
    </ButtonDropdown>
  );
};

CreateFundRequestDropDown.propTypes = {
  setNotificationsItems: PropTypes.func,
};

export default CreateFundRequestDropDown;
