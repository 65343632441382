import React from "react";
import PropTypes from "prop-types";
import { SECTION } from "shared/util/constants/section";
import { MigrationEngagementsSectionContent } from "./MigrationEngagementsSectionContent";
import { MigrationEngagementHeaderAction } from "../MigrationEngagementHeaderAction";
import { getMigrationEngagementFields } from "./util";
import { ExpandableSection } from "@amzn/awsui-components-react";

export const MigrationEngagementSectionContainer = ({
  fundingData,
  schema,
  filterFields,
  valueFactory,
  page,
}) => {
  let { overviewFields, RAFields, OLAFields } = getMigrationEngagementFields(
    fundingData,
    schema,
    filterFields,
    valueFactory
  );

  return (
    <ExpandableSection
      variant="container"
      defaultExpanded={true}
      headerText={SECTION.MIGRATION_PROGRAM_ENGAGEMENTS}
      headerActions={
        <MigrationEngagementHeaderAction
          fundingData={fundingData}
          page={page}
        ></MigrationEngagementHeaderAction>
      }
    >
      <MigrationEngagementsSectionContent
        overviewFields={overviewFields}
        RAFields={RAFields}
        OLAFields={OLAFields}
      />
    </ExpandableSection>
  );
};

MigrationEngagementSectionContainer.propTypes = {
  fundingData: PropTypes.object,
  schema: PropTypes.object,
  filterFields: PropTypes.func,
  valueFactory: PropTypes.func,
  page: PropTypes.string,
  index: PropTypes.number,
};
