import React, { useEffect, useState } from "react";

import Header from "@amzn/awsui-components-react/polaris/header";
import PropTypes from "prop-types";

import { useParams } from "react-router-dom";
import { ColumnLayout, SpaceBetween } from "@amzn/awsui-components-react";

import { useOptionsContext } from "../../../util/context/OptionsContext";
import useFields from "../../../../shared/util/hooks/useFields";
import {
  getInternalTemplate,
  filterFields,
  schema,
} from "../../../config/schema";
import { getFundRequest } from "../../../util/services/data/InternalDataService";

import "./style.css";
import { InputPropsBuilder } from "../../../../shared/components/FundRequest/InputPropsBuilder";
import { DisableFactory } from "./DisableFactory";
import { ValueFactory } from "./ValueFactory";
import { extractCashClaimIndex } from "./util.js";
import { InputFactory } from "../../../../shared/components/FundRequest/InputFactory";
import { SECTION_NAME } from "../../../../shared/util/constants/section";
import Section from "../../../../shared/components/FundRequest/StepsContainer/Section";
import Loading from "../../../../shared/components/common/Loading";
import { FileUploadTriggers } from "../../../util/services/data/dataFactory";
import { isFeatureEnabled } from "../../../../shared/util/services/features/FeatureFlagsService";
import { FEATURE_FLAG_ENUMS } from "../../../../shared/util/constants/featureFlagValues";
import { PAGES } from "../../../util/constants/pages";

const CashClaimEditSectionContainer = ({
  data,
  errors,
  dataInput,
  dataLoad,
  fieldToIdMap,
  updateErrors,
  setFieldToIdMap,
  stepTitle,
  validators,
  setNotificationItems,
  page,
  showHelperPanel,
}) => {
  const [options] = useOptionsContext();
  const { cashClaimId } = useParams();
  const stepTemplate = schema[stepTitle];

  const [{ fieldTemplate, isLoading }] = useFields({
    data,
    stepTemplate,
    fieldToIdMap,
    updateFieldMap: setFieldToIdMap,
    dataLoad,
    dataInput,
    getTemplate: getInternalTemplate,
    filterFields,
    schema,
    getFundRequest,
    page,
  });

  const claimIndex = extractCashClaimIndex(cashClaimId);

  const createTemplateKey = (sectionName) =>
    `${sectionName}${claimIndex > 1 ? `-${claimIndex}` : ""}`;

  const [attachmentFeatureFlag, setAttachmentFeatureFlag] = useState(false);
  const [claimAmountUpdateEnabled, setClaimAmountUpdateEnabled] =
    useState(false);

  useEffect(async () => {
    const attachmentFlag = await isFeatureEnabled(
      FEATURE_FLAG_ENUMS.CASH_CLAIM_ATTACHMENT_INTERNAL
    );
    setAttachmentFeatureFlag(attachmentFlag);
    const claimAmountUpdateFlag = await isFeatureEnabled(
      FEATURE_FLAG_ENUMS.UPDATE_CLAIM_AMOUNT_INTERNAL_ENABLE
    );
    setClaimAmountUpdateEnabled(claimAmountUpdateFlag);
  });

  let updatedFieldTemplate = JSON.parse(JSON.stringify(fieldTemplate));
  if (
    claimAmountUpdateEnabled &&
    updatedFieldTemplate &&
    updatedFieldTemplate[SECTION_NAME.CASH_CLAIM_ACTUALS]
  ) {
    const claimAmountIndex = updatedFieldTemplate[
      SECTION_NAME.CASH_CLAIM_ACTUALS
    ]["fields"].findIndex((field) => field.name === "claimAmount");
    if (claimAmountIndex !== -1) {
      updatedFieldTemplate[SECTION_NAME.CASH_CLAIM_ACTUALS]["fields"][
        claimAmountIndex
      ]["edit"] = [PAGES.CASH_CLAIM_APPROVAL];
    }
  }

  const generateSection = ({
    templateKey,
    defaultDisable = false,
    attachments = false,
  }) => {
    return (
      <React.Fragment>
        {attachments ? (
          <React.Fragment>
            {fieldTemplate &&
              fieldTemplate[templateKey] &&
              fieldTemplate[templateKey].fields.map((field, index) => {
                const inputProps = InputPropsBuilder({
                  data,
                  errors,
                  index: claimIndex,
                  field,
                  options,
                  fieldToIdMap,
                  defaultDisable,
                  disableFactory: DisableFactory,
                  valueFactory: ValueFactory,
                });

                FileUploadTriggers.deleteFile = attachmentFeatureFlag
                  ? FileUploadTriggers.deleteFile
                  : null;
                return (
                  <InputFactory
                    key={index}
                    dataInput={dataInput}
                    fieldToIdMap={fieldToIdMap}
                    updateErrors={updateErrors}
                    validators={validators}
                    setNotificationItems={setNotificationItems}
                    showHelperPanel={showHelperPanel}
                    fileuploadProp={FileUploadTriggers}
                    {...inputProps}
                  />
                );
              })}
          </React.Fragment>
        ) : (
          <ColumnLayout columns={2}>
            {updatedFieldTemplate &&
              updatedFieldTemplate[templateKey] &&
              updatedFieldTemplate[templateKey].fields.map((field, index) => {
                const inputProps = InputPropsBuilder({
                  data,
                  errors,
                  index: claimIndex,
                  field,
                  options,
                  fieldToIdMap,
                  defaultDisable,
                  disableFactory: DisableFactory,
                  valueFactory: ValueFactory,
                  page,
                });

                return (
                  <InputFactory
                    key={index}
                    dataInput={dataInput}
                    fieldToIdMap={fieldToIdMap}
                    updateErrors={updateErrors}
                    validators={validators}
                    setNotificationItems={setNotificationItems}
                    showHelperPanel={showHelperPanel}
                    {...inputProps}
                  />
                );
              })}
          </ColumnLayout>
        )}
      </React.Fragment>
    );
  };

  const section = isLoading ? (
    <Loading />
  ) : (
    <SpaceBetween size="xl">
      <Section header={<Header variant="h2">{stepTitle}</Header>}>
        <SpaceBetween size="xl">
          <div className="claim-section">
            <h4>Plans</h4>
            {generateSection({
              templateKey: createTemplateKey(SECTION_NAME.CASH_CLAIM_PLANS),
            })}
          </div>
          <div className="claim-section">
            <h4>Actuals</h4>
            {generateSection({
              templateKey: createTemplateKey(SECTION_NAME.CASH_CLAIM_ACTUALS),
            })}
          </div>
          <div>
            <h4>Invoice & Payments</h4>
            {generateSection({
              templateKey: createTemplateKey(
                SECTION_NAME.CASH_CLAIM_INVOICE_PAYMENT
              ),
            })}
          </div>
        </SpaceBetween>
      </Section>
    </SpaceBetween>
  );
  return <React.Fragment>{section}</React.Fragment>;
};

CashClaimEditSectionContainer.propTypes = {
  claimid: PropTypes.string,
  data: PropTypes.object,
  errors: PropTypes.object,
  dataInput: PropTypes.func,
  dataLoad: PropTypes.func,
  fieldToIdMap: PropTypes.object,
  updateErrors: PropTypes.func,
  setTemplate: PropTypes.func,
  validators: PropTypes.any,
  setFieldToIdMap: PropTypes.func,
  stepTitle: PropTypes.string,
  setNotificationItems: PropTypes.func,
  page: PropTypes.string,
  showHelperPanel: PropTypes.func,
};

export default React.memo(CashClaimEditSectionContainer);
