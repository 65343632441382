import React from "react";
import PropTypes from "prop-types";
import {
  getDownloadUrl,
  getFundRequestAttachments,
} from "../../../../util/services/data/FundRequestService";
import { AttachmentsReviewSectionContainer } from "../../../../../shared/components/Review/AttachmentsReviewSectionContainer";
import { UploadCreditAttachmentHeader } from "../Header/UploadCreditAttachmentHeader";

export const ExternalAttachmentsReviewSectionContainer = ({
  setNotificationsItems,
  stage,
  fundingData,
  id,
  history,
  setFundingData,
}) => (
  // TODO backend attach API allows when state==CREATED, but the below is not. consider display button only when attach is allowed.
  <AttachmentsReviewSectionContainer
    setNotificationsItems={setNotificationsItems}
    getFundRequestAttachments={getFundRequestAttachments}
    getDownloadUrl={getDownloadUrl}
    header={
      <UploadCreditAttachmentHeader
        id={id}
        stage={stage}
        history={history}
        fundingData={fundingData}
        setNotificationsItems={setNotificationsItems}
        setFundingData={setFundingData}
      />
    }
  />
);

ExternalAttachmentsReviewSectionContainer.propTypes = {
  setNotificationsItems: PropTypes.func,
  stage: PropTypes.string,
  id: PropTypes.string,
  history: PropTypes.any,
  fundingData: PropTypes.object,
  setFundingData: PropTypes.func,
};
