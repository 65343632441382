export const template_items_without_map_and_with_smp = [
  {
    text: "Build",
    items: [
      {
        text: "Innovation Sandbox",
        id: "SANDBOX",
        disabled: true,
        href: "/fund-request/select-program?template=Sandbox",
      },
    ],
  },
  {
    text: "Market",
    items: [
      {
        text: "Market Development Funds",
        id: "MDF",
        disabled: true,
        href: "/fund-request/select-program?template=MDF",
      },
    ],
  },
  {
    text: "Sell",
    items: [
      {
        text: "Migration Acceleration Program",
        id: "SMP",
        disabled: true,
        href: "/fund-request/select-program?template=SMP",
      },
      {
        text: "Proof of Concept",
        id: "POC",
        disabled: true,
        href: "/fund-request/select-program?template=POC",
      },
      {
        text: "Partner Initiative Funding",
        id: "PIF",
        disabled: true,
        href: "/fund-request/select-program?template=PIF",
      },
    ],
  },
  {
    text: "Miscellaneous",
    items: [
      {
        text: "Miscellaneous Template",
        id: "MISC",
        disabled: true,
        href: "/fund-request/select-program?template=Misc",
      },
    ],
  },
];

export const template_items_with_map_and_without_smp = [
  {
    text: "Build",
    items: [
      {
        text: "Innovation Sandbox",
        id: "SANDBOX",
        disabled: true,
        href: "/fund-request/select-program?template=Sandbox",
      },
    ],
  },
  {
    text: "Market",
    items: [
      {
        text: "Market Development Funds",
        id: "MDF",
        disabled: true,
        href: "/fund-request/select-program?template=MDF",
      },
    ],
  },
  {
    text: "Sell",
    items: [
      {
        text: "Migration Acceleration Program",
        id: "MAP",
        disabled: true,
        href: "/fund-request/select-program?template=MAP",
      },
      {
        text: "Proof of Concept",
        id: "POC",
        disabled: true,
        href: "/fund-request/select-program?template=POC",
      },
      {
        text: "Partner Initiative Funding",
        id: "PIF",
        disabled: true,
        href: "/fund-request/select-program?template=PIF",
      },
    ],
  },
  {
    text: "Miscellaneous",
    items: [
      {
        text: "Miscellaneous Template",
        id: "MISC",
        disabled: true,
        href: "/fund-request/select-program?template=Misc",
      },
    ],
  },
];

export const template_items_without_map_and_without_smp = [
  {
    text: "Build",
    items: [
      {
        text: "Innovation Sandbox",
        id: "SANDBOX",
        disabled: true,
        href: "/fund-request/select-program?template=Sandbox",
      },
    ],
  },
  {
    text: "Market",
    items: [
      {
        text: "Market Development Funds",
        id: "MDF",
        disabled: true,
        href: "/fund-request/select-program?template=MDF",
      },
    ],
  },
  {
    text: "Sell",
    items: [
      {
        text: "Proof of Concept",
        id: "POC",
        disabled: true,
        href: "/fund-request/select-program?template=POC",
      },
      {
        text: "Partner Initiative Funding",
        id: "PIF",
        disabled: true,
        href: "/fund-request/select-program?template=PIF",
      },
    ],
  },
  {
    text: "Miscellaneous",
    items: [
      {
        text: "Miscellaneous Template",
        id: "MISC",
        disabled: true,
        href: "/fund-request/select-program?template=Misc",
      },
    ],
  },
];
