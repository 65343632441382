import React, { useContext, useState } from "react";
import { Button } from "@amzn/awsui-components-react/polaris";
import ChangeAssignee from "internal/components/Review/ChangeAssignee";
import { DetailsActionProps } from "./DetailsActionsTypes";
import { INTERNAL_REVIEW_ACTION_TYPE } from "internal/components/common/CommentModal/reviewActionType";
import { changeAssignee } from "internal/util/services/data/InternalDataService";
import { hasError } from "shared/util/services/data/DataService";
import { NotificationsContext } from "shared/util/context/notifications/NotificationsContext";
import { IGenericObject } from "shared/programs/migration-acceleration-program/2024/fund-request/types/CommonTypes";
import { useHistory } from "react-router-dom";
import { RouteOrigin } from "shared/util/constants/RouteState";

interface ChangeAssigneeAction extends DetailsActionProps {}

const ChangeAssigneeAction = ({ loading, data }: ChangeAssigneeAction) => {
  const history = useHistory();
  const { addNotification } = useContext(NotificationsContext);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const updateVisibility = (key: string, visibility: boolean) => {
    setIsVisible(visibility);
  };

  const fundRequestId = data?.fundRequestId;

  const submitChangeAssignee = async (
    newAssignee: string,
    assigneeType: string,
    internalComment: string
  ) => {
    if (!fundRequestId) return;
    try {
      const response: IGenericObject = await changeAssignee({
        payload: {
          assignee: { assigneeId: newAssignee, assigneeType },
          internalComment: internalComment,
        },
        fundRequestId,
      });

      if (hasError(response)) {
        addNotification({
          content: response.message,
          type: "error",
        });
        return;
      }

      history.push("/dashboard", {
        origin: RouteOrigin.DetailsAction,
        message: "Assignee has been successfully changed.",
      });
    } catch (e) {
      console.error(e);
      addNotification({
        content: "An error has occurred, try again later.",
        type: "error",
      });
    } finally {
      updateVisibility(INTERNAL_REVIEW_ACTION_TYPE.CHANGE_ASSIGNEE, false);
    }
  };

  return (
    <>
      <Button
        loading={loading}
        onClick={() => {
          setIsVisible(!isVisible);
        }}
      >
        Reassign
      </Button>
      <ChangeAssignee
        isLoading={loading}
        isVisible={isVisible}
        updateVisibility={updateVisibility}
        submitChangeAssignee={submitChangeAssignee}
      />
    </>
  );
};

export default ChangeAssigneeAction;
