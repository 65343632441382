import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "@amzn/awsui-components-react/polaris/button";
import Select from "@amzn/awsui-components-react/polaris/select";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import Loading from "../Loading";
import {
  ERROR_TYPE,
  hasError,
  retryApiCall,
  checkStatusSuccess,
} from "../../../util/services/data/DataService";

import "./style.css";
import FilesList from "../../../components/FundRequest/FilesList";
import {
  setErrorMessage,
  setSuccessMessage,
} from "../../../util/common/helper";
import { FILE_EVENT_TYPE } from "../../../util/constants/eventType";
import { ATTACHMENT_TYPES } from "../../../util/constants/attachmentType";

const MAX_FILE_SIZE_IN_MB = 10 * 1024 * 1024;
const ACCEPTED_FILE_TYPES = ".pdf,.doc,.docx,.xlsx,.pptx,.csv";
const GENERIC_ERROR_MESSAGE =
  "A server error has occurred. Choose your selection again.";
const UPLOAD_ATTACHMENT_ERROR_MESSAGE =
  "Failed to upload the attachment, please try again.";

const FileUpload = ({
  setNotificationsItems,
  fundRequestId,
  fundClaimId,
  getUploadFileUrl,
  uploadFile,
  getDownloadUrl,
  deleteFile,
  getListOfAttachments,
  attachmentEventFactory,
}) => {
  const [files, setFiles] = useState([]);
  const [fileType, setFileType] = React.useState();
  const [buttonLoading, setButtonLoading] = React.useState(false);
  const [pageLoading, setPageLoading] = React.useState(true);

  useEffect(async () => {
    await retrieveFiles();
    setPageLoading(false);
  }, []);

  const addFile = async (file) => {
    if (!file) {
      setErrorMessage({
        setNotificationsItems,
        content: "File does not exist.",
      });
      return;
    }

    if (file.size > MAX_FILE_SIZE_IN_MB) {
      setErrorMessage({
        setNotificationsItems,
        content: "File is greater than 10MB.",
      });
      return;
    }

    setButtonLoading(true);

    const response = await sendFile(file);
    if (!response) {
      setButtonLoading(false);
      return;
    }
    await retrieveFiles();

    await updateAttachmentEvent(
      response.fileId,
      fundRequestId,
      fundClaimId,
      true
    );
    setButtonLoading(false);
  };

  const removeFile = async (fileId) => {
    try {
      if (!fileId) {
        setErrorMessage({
          setNotificationsItems,
          content: "No file ID found, please try again",
        });
        return;
      }
      let response = await retryApiCall({ callApi: deleteFile(fileId) });
      if (hasError(response) && response.errorType === ERROR_TYPE.BANNER) {
        setErrorMessage({
          setNotificationsItems,
          content: response.message ?? GENERIC_ERROR_MESSAGE,
        });
        return;
      }

      await retrieveFiles();
      await updateAttachmentEvent(fileId, fundRequestId, fundClaimId, false);
    } catch (err) {
      setErrorMessage({
        setNotificationsItems,
        content: GENERIC_ERROR_MESSAGE,
      });
      return;
    }
  };

  const updateAttachmentEvent = async (
    fileId,
    _fundRequestId,
    _fundClaimId,
    isAttach
  ) => {
    try {
      if (!fileId) {
        console.error("No file ID found, please try again");
        return;
      }
      const payload = {
        fileId: fileId,
        fundRequestId: _fundRequestId,
        fundClaimId: _fundClaimId || "",
      };

      let eventType = _fundClaimId
        ? FILE_EVENT_TYPE.ATTACH_TO_CASH_CLAIM
        : FILE_EVENT_TYPE.ATTACH_TO_FUND_REQUEST;

      if (!isAttach) {
        eventType = _fundClaimId
          ? FILE_EVENT_TYPE.DETACH_FROM_CASH_CLAIM
          : FILE_EVENT_TYPE.DETACH_FROM_FUND_REQUEST;
      }

      let response = await retryApiCall({
        callApi: attachmentEventFactory(eventType)({
          body: payload,
          fundRequestId: _fundRequestId,
          fundClaimId: _fundClaimId,
        }),
      });
      if (hasError(response)) {
        setErrorMessage({
          setNotificationsItems,
          content: response.message ?? GENERIC_ERROR_MESSAGE,
        });
        return;
      }
    } catch (err) {
      setErrorMessage({
        setNotificationsItems,
        content: GENERIC_ERROR_MESSAGE,
      });
      return;
    }
  };

  const retrieveFiles = async () => {
    if (!getListOfAttachments) {
      return;
    }

    const data = [];
    let nextPageToken = "";
    while (nextPageToken != null) {
      try {
        let response = await retryApiCall({
          callApi: getListOfAttachments(nextPageToken),
        });

        if (hasError(response) && response.errorType === ERROR_TYPE.BANNER) {
          setErrorMessage({
            setNotificationsItems,
            content: response.message ?? GENERIC_ERROR_MESSAGE,
          });
          return;
        }

        for (let item of response.items) {
          if (fundClaimId && item.fundClaimId !== fundClaimId) continue;
          data.push(item);
        }

        setFiles(data.slice(0));

        nextPageToken = response.nextPageToken;
      } catch (err) {
        setErrorMessage({
          setNotificationsItems,
          content: GENERIC_ERROR_MESSAGE,
        });
        return;
      }
    }
  };

  const sendFile = async (file) => {
    try {
      const payload = {
        file_name: file.name,
        file_type: fileType.value,
        file_details: {
          file_size: file.size,
          last_updated_time: new Date(file.lastModified).toLocaleString(),
          // same as above
          expire_time: new Date(
            file.lastModified + 1000000000000
          ).toLocaleString(),
        },
      };

      setSuccessMessage(
        setNotificationsItems,
        "Attachment upload in progress."
      );

      let getUploadUrlResponse = await retryApiCall({
        callApi: getUploadFileUrl(payload, fundClaimId),
      });

      if (
        hasError(getUploadUrlResponse) &&
        getUploadUrlResponse.errorType === ERROR_TYPE.BANNER
      ) {
        setErrorMessage({
          setNotificationsItems,
          content:
            getUploadUrlResponse.message ?? UPLOAD_ATTACHMENT_ERROR_MESSAGE,
        });
        return;
      }

      let uploadFileResponse = await retryApiCall({
        callApi: uploadFile(
          getUploadUrlResponse.url,
          file,
          getUploadUrlResponse.fileId
        ),
      });

      const success = checkStatusSuccess(uploadFileResponse.status);
      if (!success) {
        let err_message = `[${uploadFileResponse.status} ${uploadFileResponse.statusText}]
        ${UPLOAD_ATTACHMENT_ERROR_MESSAGE} `;

        setErrorMessage({
          setNotificationsItems,
          content: err_message,
        });
        return;
      }

      setSuccessMessage(
        setNotificationsItems,
        "Attachment was successfully and securely uploaded."
      );

      return getUploadUrlResponse;
    } catch (err) {
      setErrorMessage({
        setNotificationsItems,
        content: UPLOAD_ATTACHMENT_ERROR_MESSAGE,
      });
      return;
    }
  };

  return pageLoading ? (
    <Loading />
  ) : (
    <SpaceBetween size="xs">
      <FormField label={<span>File Type</span>}>
        <Select
          selectedOption={fileType}
          onChange={({ detail }) => setFileType(detail.selectedOption)}
          options={ATTACHMENT_TYPES}
          placeholder="Choose an option"
          selectedAriaLabel="Selected"
        />
      </FormField>
      <Button
        className="step-details-file-upload-button"
        onClick={() => document.getElementById("file-upload-input").click()}
        disabled={!fileType}
        loading={buttonLoading}
      >
        <input
          type="file"
          id="file-upload-input"
          className="step-details-file-upload"
          accept={ACCEPTED_FILE_TYPES}
          onChange={async (event) => await addFile(event.target.files[0])}
          readOnly
        />
        Choose file
      </Button>
      <br />
      <FilesList
        files={files}
        removeFile={deleteFile ? removeFile : null}
        setNotificationsItems={setNotificationsItems}
        getDownloadUrl={getDownloadUrl}
      />
    </SpaceBetween>
  );
};

FileUpload.propTypes = {
  setNotificationsItems: PropTypes.func.isRequired,
  fundRequestId: PropTypes.string.isRequired,
  fundClaimId: PropTypes.string,
  getUploadFileUrl: PropTypes.func.isRequired,
  uploadFile: PropTypes.func.isRequired,
  getDownloadUrl: PropTypes.func.isRequired,
  deleteFile: PropTypes.func,
  getListOfAttachments: PropTypes.func.isRequired,
  attachmentEventFactory: PropTypes.func.isRequired,
};

export default FileUpload;
