import { v4 as uuid } from "uuid";
import { Box } from "@amzn/awsui-components-react";
import React from "react";
import moment from "moment-timezone";

export const createValueField = ({ field, value }) => {
  if (field.name == "dueDate") {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const abbr = moment.tz(timezone).format("z");
    value += ` ${abbr}`;
  }

  return (
    <div key={uuid()}>
      <Box margin={{ bottom: "xxxs" }} color="text-label">
        {field.label}
      </Box>
      <div>{value}</div>
    </div>
  );
};
