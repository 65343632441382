import React from "react";

import ContentLayout from "@amzn/awsui-components-react/polaris/content-layout";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import FundRequestHistory from "./sections/FundRequestHistory";
import StageProgressTable from "../../../../../../../../shared/components/StageProgressTable/StageProgressTable";
import Header from "external/programs/migration-acceleration-program/2024/fund-request/components/details/FundRequestDetailsContainer/header/Header";

import ProjectDetailsContainer from "../../../views/details/sections/ProjectDetails/ProjectView";
import OpportunityDetailsContainer from "../../../views/details/sections/OpportunityDetails/OpportunityView";
import FundingDetailsContainer from "../../../views/details/sections/FundingDetails/FundingView";
import CashClaimsContainer from "../../../views/details/sections/FundingDetails/Claims/ClaimsView";
import ArtifactsContainer from "../../../views/details/sections/Artifacts/ArtifactsView";
import ReadOnlyMigrationEligibility from "../../../../../../../../shared/programs/migration-acceleration-program/2024/fund-request/components/read-only/MigrationEligibility";

import { IGenericObject } from "shared/programs/migration-acceleration-program/2024/fund-request/types/CommonTypes";

const FundRequestDetailsContainer = ({
  data,
  refresh,
  isLoading,
}: {
  data: IGenericObject;
  refresh: () => void;
  isLoading: boolean;
}) => {
  return (
    <ContentLayout
      header={<Header isLoading={isLoading} refresh={refresh} data={data} />}
    >
      <SpaceBetween size="l">
        <StageProgressTable fundingStage={data.stage} />
        <ReadOnlyMigrationEligibility data={data} />
        <ProjectDetailsContainer data={data} />
        <OpportunityDetailsContainer data={data} />
        <FundingDetailsContainer data={data} />
        <CashClaimsContainer data={data} />
        <ArtifactsContainer data={data} />
        <FundRequestHistory />
        <br />
      </SpaceBetween>
    </ContentLayout>
  );
};

export default FundRequestDetailsContainer;
