import React, { ReactElement } from "react";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Header from "@amzn/awsui-components-react/polaris/header";
import Button from "@amzn/awsui-components-react/polaris/button";

const ReviewSection = ({
  children,
  onEditClick,
  title,
}: {
  children: ReactElement;
  onEditClick: () => void;
  title: string;
}) => {
  return (
    <SpaceBetween size="l">
      <Header
        variant="h3"
        actions={<Button onClick={onEditClick}>Edit</Button>}
      >
        {title}
      </Header>
      {children}
    </SpaceBetween>
  );
};

export default ReviewSection;
