import {
  searchableColumns,
  defaultTemplate,
  defaultProgram,
  defaultStage,
  defaultStatus,
  defaultBudgetYear,
} from "./config";

const matchesTemplate = (item, selectedTemplate) => {
  return (
    selectedTemplate.value === defaultTemplate.value ||
    item.template === selectedTemplate.value
  );
};

const matchesProgram = (item, selectedProgram) => {
  return (
    selectedProgram.value === defaultProgram.value ||
    item.program === selectedProgram.value
  );
};

const matchesStage = (item, selectedStage) => {
  return (
    selectedStage.value === defaultStage.value ||
    item.stage === selectedStage.value
  );
};

const matchesStatus = (item, selectedStatus) => {
  return (
    selectedStatus.value === defaultStatus.value ||
    item.status === selectedStatus.value
  );
};

const matchesBudgetYear = (item, selectedBudgetYear) => {
  return (
    selectedBudgetYear.value === defaultBudgetYear.value ||
    item.budgetYear === selectedBudgetYear.value
  );
};

export const prepareSelectOptions = (data, field, defaultOption) => {
  const optionSet = [];

  data.forEach((item) => {
    if (optionSet.indexOf(item[field]) === -1) {
      optionSet.push(item[field]);
    }
  });

  optionSet.sort();

  const options = [defaultOption];

  optionSet.forEach((item) => options.push({ label: item, value: item }));
  return options;
};

export const filterItem = (
  item,
  filteringText,
  template,
  program,
  stage,
  status,
  budgetYear
) => {
  if (!matchesTemplate(item, template)) {
    return false;
  }

  if (!matchesProgram(item, program)) {
    return false;
  }

  if (!matchesStage(item, stage)) {
    return false;
  }

  if (!matchesStatus(item, status)) {
    return false;
  }
  if (!matchesBudgetYear(item, budgetYear)) {
    return false;
  }

  return searchableColumns
    .map((key) => item[key])
    .some(
      (value) =>
        typeof value === "string" &&
        value.toLowerCase().indexOf(filteringText.toLowerCase()) > -1
    );
};

export const clearFilter = (
  actions,
  setTemplate,
  setProgram,
  setStage,
  setStatus,
  setBudgetYear
) => {
  actions.setFiltering("");
  setTemplate(defaultTemplate);
  setProgram(defaultProgram);
  setStage(defaultStage);
  setStatus(defaultStatus);
  setBudgetYear(defaultBudgetYear);
};
