import React, { useEffect } from "react";
import { Alert, Box } from "@amzn/awsui-components-react";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import { IStepStateProps } from "../../../../../types/WizardTypes";
import ReadOnlyMigrationEligibility from "../../../../../../../../../../shared/programs/migration-acceleration-program/2024/fund-request/components/read-only/MigrationEligibility";
import ReadOnlyOpportunityDetails from "../../../../shared/sections/read-only/OpportunityDetails/ReadOnlyOpportunityDetails";
import ReadOnlyProjectDetails from "../../../../shared/sections/read-only/ProjectDetails/ReadOnlyProjectDetails";
import ReadOnlyFundingDetails from "../../../../shared/sections/read-only/FundingDetails/ReadOnlyFundingDetails";
import { useParams } from "react-router-dom";
import { fetchFundRequest } from "external/programs/migration-acceleration-program/2024/fund-request/components/create/wizard/util";
import ReviewSection from "external/programs/migration-acceleration-program/2024/fund-request/components/create/wizard/Steps/Review/ReviewSectionContainer";
import ArtifactsReview from "external/programs/migration-acceleration-program/2024/fund-request/components/create/wizard/Steps/Review/sections/artifacts/Artifacts";
import { IGenericObject } from "shared/programs/migration-acceleration-program/2024/fund-request/types/CommonTypes";
import ReviewErrorAlert from "external/programs/migration-acceleration-program/2024/fund-request/components/create/wizard/Steps/Review/errors/ReviewErrorAlert";
import ReviewCashClaims from "external/programs/migration-acceleration-program/2024/fund-request/components/create/wizard/Steps/Review/sections/claims/CashClaims";
import i18next from "external/i18n";

const Review = ({
  adapt,
  data,
  formError,
  setData,
  setActiveStepIndex,
}: IStepStateProps) => {
  const { id } = useParams<IGenericObject>();

  useEffect(() => {
    fetchFundRequest({ id, adapt, setData });
  }, []);
  return (
    <Box>
      <SpaceBetween size="l">
        <ReviewSection
          onEditClick={() => setActiveStepIndex(0)}
          title="Step 1: Associated opportunity"
        >
          <ReadOnlyOpportunityDetails data={data} />
        </ReviewSection>
        <SpaceBetween size="l">
          <ReviewSection
            onEditClick={() => setActiveStepIndex(1)}
            title="Step 2: Project details"
          >
            <ReadOnlyProjectDetails data={data} />
          </ReviewSection>

          <ReadOnlyMigrationEligibility data={data} />
        </SpaceBetween>
        <SpaceBetween size="l">
          <ReviewSection
            onEditClick={() => setActiveStepIndex(2)}
            title="Step 3: Cash request details"
          >
            <ReadOnlyFundingDetails data={data} />
          </ReviewSection>
          <ReviewCashClaims data={data} />
        </SpaceBetween>
        <SpaceBetween size="l">
          <ReviewSection
            onEditClick={() => setActiveStepIndex(3)}
            title="Step 4: Support documentation"
          >
            <ArtifactsReview data={data} />
          </ReviewSection>
        </SpaceBetween>
        <Alert type="info">
          {i18next.t("help.wizard.supportingDocumentation.alert.info")}
        </Alert>
        <ReviewErrorAlert isVisible={!!formError} error={formError} />
      </SpaceBetween>
    </Box>
  );
};

export default Review;
