import React, { useContext } from "react";
import { NotificationsContext } from "shared/util/context/notifications/NotificationsContext";
import Flashbar from "@amzn/awsui-components-react/polaris/flashbar";

const NotificationsFlashbar = () => {
  const { notifications } = useContext(NotificationsContext);

  return <Flashbar items={notifications} />;
};

export default NotificationsFlashbar;
