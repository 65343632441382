import React, { useEffect, useState } from "react";
import {
  IFundRequest,
  IMAPFundRequest,
} from "shared/programs/migration-acceleration-program/2024/fund-request/types/SchemaTypes";
import { IGenericObject } from "shared/programs/migration-acceleration-program/2024/fund-request/types/CommonTypes";
import { IMigrationEligibilityStateModel } from "external/programs/migration-acceleration-program/2024/fund-request/views/create/FundRequestCreationView";

export const useFundRequest = ({
  id,
  adapt,
  setData,
  fetch,
}: {
  id: string;
  adapt?: (response: IGenericObject) => IMAPFundRequest;
  setData:
    | React.Dispatch<React.SetStateAction<IFundRequest>>
    | React.Dispatch<React.SetStateAction<IMigrationEligibilityStateModel>>;
  fetch: (id: string) => IGenericObject;
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    refresh();
  }, []);

  const refresh = () => {
    fetchFundRequest({ id, adapt, setData, fetch, setIsLoading });
  };

  return { isLoading, refresh };
};

export const fetchFundRequest = async ({
  id,
  adapt,
  setData,
  fetch,
  setIsLoading,
}: {
  id: string;
  adapt?: (response: IGenericObject) => IMAPFundRequest;
  setData:
    | React.Dispatch<React.SetStateAction<IFundRequest>>
    | React.Dispatch<React.SetStateAction<IMigrationEligibilityStateModel>>;
  fetch: (id: string) => IGenericObject;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  setIsLoading(true);
  const response = await fetch(id);
  const state = adapt && adapt(response);
  if (state) setData(state);
  setIsLoading(false);
};
