import React from "react";
import { ContentLayout } from "@amzn/awsui-components-react";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import FundRequestDetailsHeader from "internal/programs/migration-acceleration-program/2024/fund-request/components/details/header/FundRequestDetailsHeader";
import StageProgressTable from "shared/components/StageProgressTable/StageProgressTable";
import MigrationProgramEngagementsView from "internal/programs/migration-acceleration-program/2024/fund-request/view/details/MigrationProgramEngagementsView";
import FundingDetailsContainer from "internal/programs/migration-acceleration-program/2024/fund-request/view/details/FundingView";
import ProjectDetailsContainer from "internal/programs/migration-acceleration-program/2024/fund-request/view/details/ProjectView";
import MigrationDetailsContainer from "internal/programs/migration-acceleration-program/2024/fund-request/view/details/MigrationDetailsView";
import PartnerDetails from "internal/programs/migration-acceleration-program/2024/fund-request/components/details/sections/partner/PartnerDetails";
import ApprovalDetails from "internal/programs/migration-acceleration-program/2024/fund-request/components/details/sections/approval/ApprovalDetails";
import ApplicationsDetails from "internal/programs/migration-acceleration-program/2024/fund-request/components/details/sections/applications/ApplicationsDetails";
import { IGenericObject } from "shared/programs/migration-acceleration-program/2024/fund-request/types/CommonTypes";
import CashClaimsContainer from "internal/programs/migration-acceleration-program/2024/fund-request/components/details/sections/funding-details/claims/ClaimsView";
import { FundingHistorySection } from "internal/components/Review/Sections/FundingHistory/FundingHistorySection";
import ArtifactsView from "internal/programs/migration-acceleration-program/2024/fund-request/view/details/ArtifactsView";
import OpportunityView from "internal/programs/migration-acceleration-program/2024/fund-request/components/details/sections/opportunities/OpportunityView";
import ApprovalAutomationResult from "internal/programs/migration-acceleration-program/2024/fund-request/components/details/sections/aiba/ApprovalAutomationResult";
import AskQuestionToAIBA from "internal/programs/migration-acceleration-program/2024/fund-request/components/details/sections/aiba/AskQuestion";
import { STAGE } from "shared/util/constants/fundRequestStatusType";

export interface IFundRequestDetailsContainer {
  fundRequestId: string;
  isLoading: boolean;
  refresh: () => void;
  data: IGenericObject;
}

const FundRequestDetailsContainer = ({
  isLoading,
  refresh,
  data,
}: IFundRequestDetailsContainer) => {
  return (
    <ContentLayout
      header={
        <FundRequestDetailsHeader
          data={data}
          loading={isLoading}
          refresh={refresh}
        />
      }
    >
      <SpaceBetween size="l">
        <StageProgressTable fundingStage={data.stage} />
        {data?.stage === STAGE.BUSINESS_APPROVAL && (
          <ApprovalAutomationResult />
        )}
        {data?.stage === STAGE.BUSINESS_APPROVAL && <AskQuestionToAIBA />}
        <MigrationDetailsContainer data={data} />
        <ProjectDetailsContainer data={data} />
        <OpportunityView data={data} />
        <MigrationProgramEngagementsView data={data} isLoading={isLoading} />
        <FundingDetailsContainer data={data} />
        <CashClaimsContainer data={data} loading={isLoading} />
        <ArtifactsView data={data} />
        <PartnerDetails data={data} loading={isLoading} />
        <ApprovalDetails data={data} loading={isLoading} />
        {/*<DuplicateDetails data={data} />*/}
        <ApplicationsDetails />
        <FundingHistorySection showHelperPanel={() => {}} />
      </SpaceBetween>
    </ContentLayout>
  );
};

export default FundRequestDetailsContainer;
