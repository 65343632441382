import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DOMPurify from "dompurify";

import withAppLayout from "../../../../shared/components/withAppLayout";
import Loading from "../../../../shared/components/common/Loading";

import { getFundRequest } from "../../../util/services/data/FundRequestService";
import {
  getSectionConstant,
  SECTION,
  SECTION_CONSTANT_TYPE,
  SECTION_NAME,
} from "../../../../shared/util/constants/section";
import SectionContainerFactory from "../StepsContainer/SectionFactory";
import { OptionsProvider } from "../../../util/context/OptionsContext";
import { filterFields, getTemplate, schema } from "../../../config/schema";
import {
  createFieldSchemaObject,
  createJsonSchemaObject,
} from "../../../../shared/util/schema/util";
import { createValidator } from "../../../../shared/util/validation/validator";
import { SpaceBetween } from "@amzn/awsui-components-react";
import {
  ERROR_TYPE,
  hasError,
  retryApiCall,
} from "../../../../shared/util/services/data/DataService";

import { getBreadcrumbs } from "../../../../shared/util/common/getBreadcrumbs";
import { TITLE } from "../../../../shared/util/constants/title";
import useHelpText from "../../../../shared/util/hooks/useHelpText";
import { HelperPanel } from "../../../../shared/components/FundRequest/Help/HelperPanel";
import { setErrorMessage } from "../../../../shared/util/common/helper";
import { FooterFactory } from "./Footer/FooterFactory";
import {
  DataSubmissionFactory,
  FileUploadTriggers,
} from "../../../util/services/data/dataFactory";

const EditContainer = () => {
  const { id, section } = useParams();
  const page = "fund-request";
  const sectionTitle = getSectionConstant({
    type: SECTION_CONSTANT_TYPE.TITLE,
    value: section,
  });
  const returnPath = `/${page}/${id}/review`;

  const [data, setData] = useState({});
  const [fieldToIdMap, setFieldToIdMap] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingNextStep, setIsLoadingNextStep] = useState(false);
  const [errors, setErrors] = useState({});
  const [validators, setValidators] = useState({});
  const [notifications, setNotifications] = useState([]);
  const {
    isHelperPanelOpen,
    helperPanelContent,
    setIsHelperPanelOpen,
    showHelperPanel,
  } = useHelpText();

  useEffect(async () => {
    const sectionTitle = getSectionConstant({
      type: SECTION_CONSTANT_TYPE.TITLE,
      value: section,
    });
    document.title = DOMPurify.sanitize(
      `${TITLE} - ${id} - ${sectionTitle} - Edit`
    );

    try {
      const response = await retryApiCall({ callApi: getFundRequest(id) });
      //TODO: Add failure condition
      if (hasError(response) && response.errorType === ERROR_TYPE.BANNER) {
        setErrorMessage({
          setNotificationsItems: setNotifications,
          content: response.message,
          status: response.status,
        });
        setIsLoading(false);
        return;
      }

      const {
        fundingTemplate: { fundingType, migrationPhase, program, subProgram },
      } = response;

      const newTemplate = getTemplate({ fundingType, migrationPhase, program });

      const sections = Object.keys(newTemplate).reduce(
        (previous, templateName) => {
          if (templateName === SECTION_NAME.CASH_REQUEST_INFORMATION) {
            newTemplate[templateName].sections.push(SECTION.CASH_CLAIM_ACTUALS);
          }
          const sections = newTemplate[templateName].sections.map(
            (sectionName) => {
              return {
                ...schema[sectionName],
                fields: filterFields({ section: schema[sectionName], program }),
              };
            }
          );
          return [...previous, ...sections];
        },
        []
      );

      const fieldsSchemas = [];
      const createdSchemas = [];
      for (let section of sections) {
        for (let field of section.fields) {
          if (!field.auto && !createdSchemas.includes(field.name)) {
            const attributes = {
              component: field.component,
              fieldName: field.name,
              type: field.type || undefined,
              pattern: field.pattern || undefined,
              errorMessage: field.errorMessage || undefined,
            };
            createdSchemas.push(field.name);
            fieldsSchemas.push(createFieldSchemaObject(attributes));
          }
        }
      }

      const sectionSchemas = sections.map((section) =>
        createJsonSchemaObject({
          sectionName: section.name,
          fields: section.fields,
          program: program,
        })
      );

      setData({
        fundingType,
        program,
        subProgram,
        migrationPhase,
        fundRequestId: id,
        annualRunRateUnits: response?.fundingTemplate?.annualRunRate?.units,
        ...response,
      });
      setValidators(createValidator([...sectionSchemas, ...fieldsSchemas]));
      showHelperPanel({
        title: sectionTitle,
        keys: [section],
        open: false,
      });
      setIsLoading(false);
    } catch (e) {
      console.error(e);
    }
  }, []);

  const dataLoad = (data) => {
    setData((originalData) => {
      const fundRequestInfo = {
        fundRequestId: originalData["fundRequestId"],
        fundingType: originalData["fundingType"],
        program: originalData["program"],
        subProgram: originalData["subProgram"],
        migrationPhase: originalData["migrationPhase"],
        annualRunRateUnits: originalData["annualRunRateUnits"],
      };

      return { ...fundRequestInfo, ...data };
    });
  };

  const dataInput = (inputData) => {
    setData((originalData) => ({ ...originalData, ...inputData }));
  };

  const updateErrors = (key, value) => {
    setErrors({
      ...errors,
      [key]: value,
    });
  };

  const Section = SectionContainerFactory({
    stepTitle: sectionTitle,
  });

  const Footer = FooterFactory({
    sectionTitle: sectionTitle,
  });

  return isLoading ? (
    <Loading />
  ) : (
    withAppLayout({
      component: (
        <OptionsProvider data={data}>
          <SpaceBetween size="l">
            <Section
              data={data}
              errors={errors}
              dataLoad={dataLoad}
              dataInput={dataInput}
              updateErrors={updateErrors}
              fieldToIdMap={fieldToIdMap}
              setFieldToIdMap={setFieldToIdMap}
              stepTitle={sectionTitle}
              validators={validators}
              setNotificationItems={setNotifications}
              showHelperPanel={showHelperPanel}
              fileuploadProp={FileUploadTriggers}
            />
            <Footer
              isLoadingNextStep={isLoadingNextStep}
              setIsLoadingNextStep={setIsLoadingNextStep}
              data={data}
              sectionTitle={sectionTitle}
              fieldToIdMap={fieldToIdMap}
              validators={validators}
              setNotificationItems={setNotifications}
              setErrors={setErrors}
              errors={errors}
              getTemplate={getTemplate}
              schema={schema}
              filterFields={filterFields}
              dataSubmissionFactory={DataSubmissionFactory}
              returnPath={returnPath}
            />
          </SpaceBetween>
        </OptionsProvider>
      ),
      breadcrumbs: getBreadcrumbs({ text: `Edit` }),
      toolsContent: <HelperPanel content={helperPanelContent} />,
      isToolsOpen: isHelperPanelOpen,
      onToolsChange: ({ detail }) => {
        setIsHelperPanelOpen(detail.open);
      },
      notificationsItems: notifications,
    })()
  );
};
export default EditContainer;
