import { INTERNAL_REVIEW_ACTION_TYPE } from "./reviewActionType";

export const getReviewActionType = ({ isVisible }) => {
  let selectedReviewAction = "";
  if (isVisible[INTERNAL_REVIEW_ACTION_TYPE.APPROVE]) {
    selectedReviewAction = INTERNAL_REVIEW_ACTION_TYPE.APPROVE;
  } else if (isVisible[INTERNAL_REVIEW_ACTION_TYPE.REJECT]) {
    selectedReviewAction = INTERNAL_REVIEW_ACTION_TYPE.REJECT;
  } else if (isVisible[INTERNAL_REVIEW_ACTION_TYPE.COMPLETE]) {
    selectedReviewAction = INTERNAL_REVIEW_ACTION_TYPE.COMPLETE;
  }

  return selectedReviewAction;
};

export const checkLoading = ({ isLoading }) => {
  let currentlyLoading = false;
  for (let actionType of Object.keys(isLoading)) {
    if (isLoading[actionType]) {
      currentlyLoading = true;
      break;
    }
  }
  return currentlyLoading;
};
