import React, { useState } from "react";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import Input from "@amzn/awsui-components-react/polaris/input";
import PropTypes from "prop-types";
import { Box, FormField, SpaceBetween } from "@amzn/awsui-components-react";
import Button from "@amzn/awsui-components-react/polaris/button";
import {
  ERROR_TYPE,
  hasError,
  retryApiCall,
} from "../../../../../shared/util/services/data/DataService";
import { changeFundRequestOwner } from "../../../../util/services/data/FundRequestService";
import {
  setErrorMessage,
  setSuccessMessage,
} from "../../../../../shared/util/common/helper";

const ChangeOwner = ({
  visible,
  setNotificationsItems,
  setModalVisibility,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [newOwner, setNewOwner] = useState("");
  const [error, setNewError] = useState("");

  const submitOwnerChange = async () => {
    setIsLoading(true);
    try {
      const response = await retryApiCall({
        callApi: changeFundRequestOwner({ userEmail: newOwner }),
      });
      console.log(response);

      //TODO: Add failure condition
      if (hasError(response) && response.errorType === ERROR_TYPE.BANNER) {
        setErrorMessage({
          setNotificationsItems,
          content: response.message,
          status: response.status,
        });
        setIsLoading(false);
        setModalVisibility("changeOwner", false);
      } else {
        setIsLoading(false);
        setModalVisibility("changeOwner", false);
        setSuccessMessage(
          setNotificationsItems,
          "Updated",
          `Fund Request Owner has been updated.`
        );
      }
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <Modal
      id="change-owner-modal"
      onDismiss={() => setModalVisibility("changeOwner", false)}
      visible={visible}
      closeAriaLabel="Close modal"
      size="large"
      header="Change Fund Request Owner"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              onClick={() => {
                setModalVisibility("changeOwner", false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              disabled={!newOwner}
              loading={isLoading}
              onClick={async () => {
                await submitOwnerChange();
              }}
            >
              Submit
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <FormField label="Please enter user's email" errorText={error}>
        <Input
          id="change-owner"
          value={newOwner}
          onChange={({ detail }) => {
            setNewError("");
            setNewOwner(detail.value);
          }}
        />
      </FormField>
    </Modal>
  );
};

ChangeOwner.propTypes = {
  visible: PropTypes.bool,
  setNotificationsItems: PropTypes.func,
  setModalVisibility: PropTypes.func,
};

export default ChangeOwner;
