import React from "react";
import Header from "@amzn/awsui-components-react/polaris/header";
import PropTypes from "prop-types";
import { useOptionsContext } from "../../../util/context/OptionsContext";
import {
  schema,
  getInternalTemplate,
  filterFields,
} from "../../../config/schema";
import useFields from "../../../../shared/util/hooks/useFields";
import { getFundRequest } from "../../../util/services/data/InternalDataService";
import Section from "../../../../shared/components/FundRequest/StepsContainer/Section";
import { InputPropsBuilder } from "../../../../shared/components/FundRequest/InputPropsBuilder";
import { DisableFactory } from "./DisableFactory";
import { ValueFactory } from "./ValueFactory";
import { InputFactory } from "../../../../shared/components/FundRequest/InputFactory";
import Loading from "../../../../shared/components/common/Loading";

const MigrationDetailsEditSectionContainer = ({
  data,
  errors,
  dataInput,
  dataLoad,
  fieldToIdMap,
  updateErrors,
  setFieldToIdMap,
  stepTitle,
  validators,
  page,
  showHelperPanel,
}) => {
  const [options] = useOptionsContext();
  const stepTemplate = schema[stepTitle];
  const [{ fieldTemplate, isLoading }] = useFields({
    data,
    stepTemplate,
    fieldToIdMap,
    updateFieldMap: setFieldToIdMap,
    dataLoad,
    dataInput,
    getTemplate: getInternalTemplate,
    filterFields,
    schema,
    getFundRequest,
  });

  const section = isLoading ? (
    <Loading />
  ) : (
    <React.Fragment>
      <Section header={<Header variant="h2">{stepTitle}</Header>}>
        <React.Fragment>
          {fieldTemplate &&
            fieldTemplate[stepTemplate?.name]?.fields.map((field, index) => {
              const inputProps = InputPropsBuilder({
                data: data,
                errors: errors,
                field: field,
                fieldToIdMap: fieldToIdMap,
                options: options,
                disableFactory: DisableFactory,
                valueFactory: ValueFactory,
                page: page,
              });

              return (
                <InputFactory
                  key={index}
                  dataInput={dataInput}
                  fieldToIdMap={fieldToIdMap}
                  updateErrors={updateErrors}
                  validators={validators}
                  showHelperPanel={showHelperPanel}
                  {...inputProps}
                />
              );
            })}
        </React.Fragment>
      </Section>
    </React.Fragment>
  );
  return <React.Fragment>{section}</React.Fragment>;
};

MigrationDetailsEditSectionContainer.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object,
  dataInput: PropTypes.func,
  dataLoad: PropTypes.func,
  fieldToIdMap: PropTypes.object,
  updateErrors: PropTypes.func,
  setTemplate: PropTypes.func,
  validators: PropTypes.any,
  setFieldToIdMap: PropTypes.func,
  stepTitle: PropTypes.string,
  page: PropTypes.string,
  showHelperPanel: PropTypes.func,
};

export default React.memo(MigrationDetailsEditSectionContainer);
