import React, { useState } from "react";
import { SpaceBetween } from "@amzn/awsui-components-react";
import Button from "@amzn/awsui-components-react/polaris/button";
import PropTypes from "prop-types";
import { deleteDelegation } from "../../../util/services/data/InternalDataService";
import {
  ERROR_TYPE,
  hasError,
} from "../../../../shared/util/services/data/DataService";
import { setErrorMessage } from "../../../../shared/util/common/helper";

const TableActions = ({
  refresh,
  selectedItems,
  setNotifications,
  showCreateModal,
  showEditModal,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const deleteDelegationAction = async () => {
    try {
      setIsLoading(true);
      if (selectedItems.length > 0) {
        const [delegation] = selectedItems;
        const { delegationId } = delegation;
        const response = await deleteDelegation({ delegationId });

        if (
          response &&
          hasError(response) &&
          response.errorType === ERROR_TYPE.BANNER
        ) {
          setErrorMessage({
            setNotificationsItems: setNotifications,
            content: response.message,
            status: response.status,
          });
          setIsLoading(false);
          return;
        }

        await refresh();
      }
    } catch (err) {
      console.log(err);
      setErrorMessage({
        setNotificationsItems: setNotifications,
      });
    }
    setIsLoading(false);
  };

  return (
    <SpaceBetween size="s" direction="horizontal">
      <Button
        iconName="refresh"
        variant="normal"
        loading={isLoading}
        onClick={async () => await refresh()}
      />
      <Button
        onClick={() => {
          showEditModal();
        }}
        loading={isLoading}
        disabled={isLoading || !selectedItems.length > 0}
      >
        Edit
      </Button>
      <Button
        loading={isLoading}
        disabled={isLoading || !selectedItems.length > 0}
        onClick={deleteDelegationAction}
      >
        Cancel
      </Button>
      <Button
        loading={isLoading}
        disabled={isLoading}
        variant="primary"
        onClick={() => {
          showCreateModal();
        }}
      >
        Create Delegation
      </Button>
    </SpaceBetween>
  );
};

TableActions.propTypes = {
  refresh: PropTypes.func,
  showCreateModal: PropTypes.func,
  showEditModal: PropTypes.func,
  selectedItems: PropTypes.array,
  setNotifications: PropTypes.func,
};

export default TableActions;
