import download from "downloadjs";
import moment from "moment";

export const CSV_TYPE = {
  FUNDING_ACTIVITIES: "FundingActivities",
  CREDITS: "CREDITS",
};
const fundingActivityData = (data) => {
  const header =
    "Fund Request Id,Template,Program,Activity Name,Stage,Status,PO Number,Budget Year,Funding Expiration Date,Opportunity Id";
  const content = [];
  content.push(header);
  const filteredData = data;
  filteredData.map((item) => {
    content.push(
      [
        item.fundRequestId,
        item.template,
        item.program,
        `"${item.activityName}"`, // added to ignore commas within activityName
        item.stage,
        item.status,
        item.poNumber,
        item.budgetYear,
        item.fundingExpirationDate,
        item.opportunityId,
      ].join(",")
    );
  });
  return content.join("\n");
};
const creditData = (data) => {
  const header =
    "Fund Request Id,AWS Promo Code,Amount,Code Redeemed,AWS Account Id,Issuance Date,Expiration Date,Template,Program";
  const content = [];
  content.push(header);
  const filteredData = data;
  filteredData.map((item) => {
    content.push(
      [
        item.fundRequestId,
        item.awsPromoCode,
        item.amount,
        item.codeRedeemed,
        item.awsAccountId,
        item.issuanceDate,
        item.expirationDate,
        item.template,
        item.program,
      ].join(",")
    );
  });
  return content.join("\n");
};

const exportData = {
  [CSV_TYPE.FUNDING_ACTIVITIES]: fundingActivityData,
  [CSV_TYPE.CREDITS]: creditData,
};

export const exportDataToCSV = ({ data, csvType, setNotificationsItems }) => {
  const date = moment().format("MM-DD-YYYY");
  const csvFile = exportData[csvType](data);
  try {
    download(csvFile, `${date}-${csvType}.csv`, "text/csv");
    setNotificationsItems([
      {
        type: "success",
        content: "Your file has been downloaded successfully.",
        dismissible: true,
        dimissLabel: "Dismiss message",
        onDismiss: () => setNotificationsItems([]),
        id: "file_download",
      },
    ]);
  } catch (e) {
    console.error(e);
    console.log("Error while downloading CSV file");
  }
};
