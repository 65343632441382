import { STAGE } from "../../../shared/util/constants/fundRequestStatusType";

export const PAGES = {
  AWS_APPROVAL: "aws-approvals",
  TECH_APPROVAL: "tech-approvals",
  BUSINESS_APPROVAL: "business-approvals",
  FINANCE_APPROVAL: "finance-approvals",
  PRE_APPROVAL: "pre-approvals",
  CASH_CLAIM_APPROVAL: "cash-claim-approvals",
  READ_ONLY: "fund-request",
};

export const PAGE_TO_STAGE = {
  [PAGES.AWS_APPROVAL]: STAGE.REVIEW,
  [PAGES.TECH_APPROVAL]: STAGE.TECH_APPROVAL,
  [PAGES.BUSINESS_APPROVAL]: STAGE.BUSINESS_APPROVAL,
  [PAGES.CASH_CLAIM_APPROVAL]: STAGE.CASH_CLAIM,
  [PAGES.FINANCE_APPROVAL]: STAGE.FINANCE_APPROVAL,
  [PAGES.PRE_APPROVAL]: STAGE.PRE_APPROVAL,
  [PAGES.READ_ONLY]: "Read-Only",
};

export const PAGE_TITLE = {
  [PAGES.AWS_APPROVAL]: "AWS Review",
  [PAGES.BUSINESS_APPROVAL]: "Business Review",
  [PAGES.TECH_APPROVAL]: "Tech Approval",
  [PAGES.CASH_CLAIM_APPROVAL]: "Cash Claim Approval",
  [PAGES.FINANCE_APPROVAL]: "Finance Approval",
  [PAGES.PRE_APPROVAL]: "Pre-Approval",
  [PAGES.READ_ONLY]: "Read-Only",
};

export const STAGE_MAPPING = {
  review: "aws-approvals",
  tech_approval: "tech-approvals",
  business_approval: "business-approvals",
  finance_approval: "finance-approvals",
  pre_approval: "pre-approvals",
  cash_claim: "cash-claim-approvals",
  completed: "read-only",
};

export const STAGE_TO_PAGE = {
  [STAGE.REVIEW]: PAGES.AWS_APPROVAL,
  [STAGE.TECH_APPROVAL]: PAGES.TECH_APPROVAL,
  [STAGE.BUSINESS_APPROVAL]: PAGES.BUSINESS_APPROVAL,
  [STAGE.FINANCE_APPROVAL]: PAGES.FINANCE_APPROVAL,
  [STAGE.PRE_APPROVAL]: PAGES.PRE_APPROVAL,
  [STAGE.CASH_CLAIM]: PAGES.CASH_CLAIM_APPROVAL,
  [STAGE.COMPLETED]: PAGES.READ_ONLY,
};

export const ALLOWED_STAGE_FOR_REVIEW = Object.keys(STAGE_MAPPING);
