import { MoneyType } from "external/util/services/data/model/createFundRequest";
import { IMAPFundRequest } from "shared/programs/migration-acceleration-program/2024/fund-request/types/SchemaTypes";

export interface IUpdateMigrationEligibility {
  annualRunRate: MoneyType;
  accurateWorkloadAcknowledgement?: boolean;
  modernizationIncentiveEligible?: boolean;
  modernizationArrUsdAmount?: MoneyType;
  vmwareIncentiveEligible?: boolean;
  vmwareBonusOption?: boolean;
}

const UpdateFundRequestTemplateAdapter = (fundRequest: IMAPFundRequest) => {
  if (!fundRequest) {
    throw new Error("Fund request payload is empty.");
  }

  const payload: IUpdateMigrationEligibility = {
    annualRunRate: fundRequest?.annualRunRate,
  };

  if (fundRequest?.modernizationIncentiveEligible) {
    payload["modernizationIncentiveEligible"] =
      fundRequest?.modernizationIncentiveEligible;
    payload["modernizationArrUsdAmount"] =
      fundRequest?.modernizationArrUsdAmount;
  }

  payload["vmwareIncentiveEligible"] =
    fundRequest?.vmwareIncentiveEligible || false;
  payload["vmwareBonusOption"] = fundRequest?.vmwareBonusOption || false;
  if (!payload["vmwareIncentiveEligible"]) {
    payload["vmwareBonusOption"] = false;
  }

  return payload;
};

export default UpdateFundRequestTemplateAdapter;
