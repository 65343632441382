import { decode } from "jsonwebtoken";
import SessionManager from "../../../../shared/util/services/auth/SessionManager";
import {
  checkAuthTokenExists,
  CLIENT_ID,
  COGNITO_ENDPOINT,
  isJson,
} from "../../../../shared/util/services/auth/AuthService";
import { getSfdcLandingPageLink } from "../../common/helper";

export const getUsername = () => {
  let username = "";

  if (checkAuthTokenExists()) {
    const decodedIdToken = decode(SessionManager.getIdToken(), {
      json: true,
    });

    const { profile } = decodedIdToken;

    if (profile && isJson(profile)) {
      username = JSON.parse(profile).name;
    }
  }

  return username;
};

export const getPartnerScorecardId = () => {
  let partnerScorecardId = "";

  if (checkAuthTokenExists()) {
    const decodedIdToken = decode(SessionManager.getIdToken(), {
      json: true,
    });

    const { profile } = decodedIdToken;

    if (profile) {
      partnerScorecardId = JSON.parse(profile).partnerScorecardId;
    }
  }

  return partnerScorecardId;
};

//NOTE: The logout_uri needs match the logoutUrls configuration in the environment's External Cognito App Client Settings
export const idpLogout = () =>
  `${COGNITO_ENDPOINT}/logout?client_id=${CLIENT_ID}&logout_uri=${getSfdcLandingPageLink()}`;
