import React, { useState } from "react";
import withAppLayout from "../../../shared/components/withAppLayout";
import { getBreadcrumbs } from "../../../shared/util/common/getBreadcrumbs";
import { HelperPanel } from "../../../shared/components/FundRequest/Help/HelperPanel";
import WalletCards from "../../components/common/Wallet/WalletCards/WalletCards";

const WalletPage = () => {
  const [notifications, setNotifications] = useState([]);
  return withAppLayout({
    component: <WalletCards setNotificationsItems={setNotifications} />,
    breadcrumbs: getBreadcrumbs({ text: "Wallet Information" }),
    notificationsItems: notifications,
    toolsContent: <HelperPanel content={""} />,
    isToolsOpen: false,
    onToolsChange: () => {},
  })();
};

export default WalletPage;
