import { ROOT_ENTITY_NAME } from "../../constant";

export const selfServiceEntityHistoryColumnDefinitions = [
  {
    id: "sequenceNumber",
    header: "Sequence Number",
    cell: (e) => e.sequenceNumber,
    sortingField: "sequenceNumber",
    sortingComparator: (i1, i2) => entityComparator(i1, i2),
  },
  {
    id: "operation",
    header: "Operation",
    cell: (e) => e.operation,
    sortingField: "operation",
  },
  {
    id: "index",
    header: "Item Index",
    cell: (e) => e.index,
    sortingField: "index",
    sortingComparator: (i1, i2) => entityComparator(i1, i2),
  },
  {
    id: "name",
    header: "Item Name",
    cell: (e) => e.name,
    sortingField: "name",
  },
  {
    id: "value",
    header: "Item Description",
    cell: (e) => e.value,
    sortingField: "value",
  },
  {
    id: "permissions",
    header: "Permissions",
    cell: (e) => e.permissions?.toString(),
    sortingField: "permissions",
  },
  {
    id: "date",
    header: "Date",
    cell: (e) => {
      const timestamp = e.date;
      return timestamp ? timestamp.split("T")[0] : `-`;
    },
    sortingField: "timestamp",
  },
  {
    id: "editor",
    header: "Editor",
    cell: (e) => e.editor,
    sortingField: "editor",
  },
  {
    id: "reason",
    header: "Reason",
    cell: (e) => e.reason,
    sortingField: "reason",
  },
];

export const searchableColumns = ["name", "value"];
export const sortingField = "sequenceNumber";

export const getVisibleColumns = (entity, selfServiceAuthorizerFlag) =>
  entity === ROOT_ENTITY_NAME && selfServiceAuthorizerFlag
    ? [
        "sequenceNumber",
        "operation",
        "index",
        "name",
        "value",
        "permissions",
        "date",
        "editor",
        "reason",
      ]
    : [
        "sequenceNumber",
        "operation",
        "index",
        "name",
        "value",
        "date",
        "editor",
        "reason",
      ];

export const entityComparator = (i1, i2) => {
  return i1.sequenceNumber === i2.sequenceNumber
    ? i1.index - i2.index
    : i1.sequenceNumber - i2.sequenceNumber;
};
