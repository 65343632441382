import React from "react";
import Input from "@amzn/awsui-components-react/polaris/input";
import Grid from "@amzn/awsui-components-react/polaris/grid";
import PropTypes from "prop-types";

const TableFilter = ({ actions, filterProps, label }) => {
  return (
    <Grid gridDefinition={[{ colspan: 4 }, { colspan: 2 }]}>
      <Input
        type="search"
        value={filterProps.filteringText}
        onChange={(event) => {
          actions.setFiltering(event.detail.value);
        }}
        placeholder="Search..."
        label={label}
        ariaDescribedby={null}
      />
    </Grid>
  );
};

TableFilter.propTypes = {
  actions: PropTypes.object,
  filterProps: PropTypes.object,
  label: PropTypes.string,
};

export default TableFilter;
