import React from "react";
import { SpaceBetween } from "@amzn/awsui-components-react";
import Button from "@amzn/awsui-components-react/polaris/button";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { STAGE_TO_PAGE } from "../../../util/constants/pages";
import { getUrlCashClaimId } from "./util";

const TableActions = ({ refresh, selectedItems }) => {
  const history = useHistory();

  return (
    <SpaceBetween size="s" direction="horizontal">
      <Button
        iconName="refresh"
        variant="normal"
        onClick={async () => await refresh()}
      />
      <Button
        variant="primary"
        onClick={() => {
          const [selectedItem] = selectedItems;
          const { frId, cashClaimId, stage } = selectedItem;
          console.log(selectedItem);
          const auditLink = `/${
            STAGE_TO_PAGE[stage]
          }/${frId}/review${getUrlCashClaimId(cashClaimId)}`;
          history.push(auditLink);
        }}
        disabled={!selectedItems.length > 0}
      >
        Audit
      </Button>
    </SpaceBetween>
  );
};

TableActions.propTypes = {
  refresh: PropTypes.func,
  selectedItems: PropTypes.array,
};

export default TableActions;
