import React from "react";
import Link from "@amzn/awsui-components-react/polaris/link";
import { PROGRAM_TYPE } from "../constants/programType";

import { removeCurrencyFormatting } from "../../programs/migration-acceleration-program/2024/fund-request/util/currency";

const MAP_LITE_THRESHOLD = 500000;

export const generateFundingLinkForExternal = ({ fundRequestId }) => {
  let link = `fund-request/${fundRequestId}/review`;
  return <Link href={link}>{fundRequestId}</Link>;
};

export const groupBy = (values, keyFinder) => {
  return values.reduce((a, b) => {
    // NOTE: depending upon the type of keyFinder (function or property)
    const key = typeof keyFinder === "function" ? keyFinder(b) : b[keyFinder];

    // aggregate values based on the keys
    if (!a[key]) {
      a[key] = [b];
    } else {
      a[key] = [...a[key], b];
    }

    return a;
  }, {});
};

export const cartesianProduct = (a, b) =>
  a.reduce((p, x) => [...p, ...b.map((y) => [x, y])], []);

export const isSMPLarge = (data) => {
  return (
    data &&
    data?.fundingTemplate?.annualRunRate?.units >= MAP_LITE_THRESHOLD &&
    data?.program === PROGRAM_TYPE.SMP
  );
};

export const isMapLarge2024 = (data) => {
  return (
    data &&
    parseInt(removeCurrencyFormatting(data?.arrUsdAmount)) >=
      MAP_LITE_THRESHOLD &&
    data?.program === PROGRAM_TYPE.MAP2024
  );
};
