import React from "react";
import { Header } from "@amzn/awsui-components-react";
import TableActions from "./TableActions";
import PropTypes from "prop-types";

const TableHeader = ({ counter, refresh, selectedItems }) => {
  return (
    <React.Fragment>
      <Header
        counter={counter}
        actions={
          <TableActions refresh={refresh} selectedItems={selectedItems} />
        }
      >
        Audits
      </Header>
    </React.Fragment>
  );
};

TableHeader.propTypes = {
  counter: PropTypes.string,
  refresh: PropTypes.func,
  selectedItems: PropTypes.array,
};

export default TableHeader;
