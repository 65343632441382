import { useHistory, useParams } from "react-router-dom";
import { Header, SpaceBetween } from "@amzn/awsui-components-react";
import Button from "@amzn/awsui-components-react/polaris/button";
import {
  getSectionConstant,
  SECTION,
  SECTION_CONSTANT_TYPE,
  SECTION_NAME,
} from "../../../../util/constants/section";
import { formatData } from "../../../../util/adapter/toApi/adapter";
import { ValidationFactory } from "../../StepsContainer/ValidationFactory";
import {
  ERROR_TYPE,
  hasError,
  retryApiCall,
} from "../../../../util/services/data/DataService";
import { setErrorMessage } from "../../../../util/common/helper";
import PropTypes from "prop-types";
import React from "react";
import { generateMultiSectionKey } from "../../../../util/common/generateMultiSectionKey";
import { smpMapLite } from "../../../../util/constants/smpMapLite";
import { PAGES } from "../../../../../internal/util/constants/pages";
import { PROGRAM_TYPE } from "../../../../util/constants/programType";

export const isPifPublicSectorDetailsBusinessApprovals = (
  program,
  sectionTitle,
  page
) => {
  return (
    program == PROGRAM_TYPE.PIF &&
    sectionTitle == SECTION.PUBLIC_SECTOR_DETAILS &&
    page == PAGES.BUSINESS_APPROVAL
  );
};

export const DefaultEditFooter = ({
  isLoadingNextStep,
  setIsLoadingNextStep,
  data,
  sectionTitle,
  fieldToIdMap,
  validators,
  setNotificationItems,
  setErrors,
  errors,
  getTemplate,
  schema,
  filterFields,
  dataSubmissionFactory,
  page,
}) => {
  const history = useHistory();
  const { id } = useParams();
  const source = page ? page : "fund-request";
  return (
    <Header
      actions={
        <SpaceBetween direction="horizontal" size="xs">
          <Button
            onClick={() => {
              history.push(`/${source}/${id}/review`);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            loading={isLoadingNextStep}
            onClick={async () => {
              setIsLoadingNextStep(true);

              const template = getTemplate({
                fundingType: data["fundingType"],
                migrationPhase: data["migrationPhase"],
                program: data["program"],
              });

              const currentSectionName = schema[sectionTitle].name;
              const currentSchema = template[currentSectionName];
              const { sections } = currentSchema;

              let validated = true;
              let validationErrors = {};
              let submissionData = {};

              if (
                sectionTitle === SECTION.PROJECT_INFORMATION &&
                Object.keys(fieldToIdMap).includes(
                  SECTION_NAME.PUBLIC_SECTOR_DETAILS
                )
              ) {
                sections.push(SECTION.PUBLIC_SECTOR_DETAILS);
              }

              // Format, and validate data
              for (let sectionTitle of sections) {
                // Skip cash claims plans/actuals, migration workloads since the data and validation is part of cash request
                if (
                  sectionTitle === SECTION.CASH_CLAIM_PLANS ||
                  sectionTitle === SECTION.MIGRATION_WORKLOADS ||
                  sectionTitle === SECTION.CASH_CLAIM_ACTUALS
                )
                  continue;

                const sectionName = getSectionConstant({
                  type: SECTION_CONSTANT_TYPE.NAME,
                  value: sectionTitle,
                });

                const formattedData = formatData(sectionName)({
                  step: currentSchema,
                  stepName: sectionName,
                  stepTitle: sectionTitle,
                  data,
                  fieldMap: fieldToIdMap,
                  schema,
                  filterFields,
                  page,
                });

                //Validate formatted data
                const { errors, valid } = ValidationFactory(sectionTitle)({
                  validators,
                  sectionName,
                  data: formattedData,
                  mapLite: smpMapLite(data),
                  program: data.program,
                });

                validated = valid && validated;

                submissionData = {
                  ...submissionData,
                  [sectionTitle]: { ...formattedData },
                };

                validationErrors = {
                  ...validationErrors,
                  ...errors,
                };
              }

              let submitted = validated;
              // Attempt submission and capture errors.
              if (validated) {
                for (let sectionTitle of Object.keys(submissionData)) {
                  if (
                    !isPifPublicSectorDetailsBusinessApprovals(
                      data["program"],
                      sectionTitle,
                      page
                    )
                  ) {
                    const sectionName = getSectionConstant({
                      type: SECTION_CONSTANT_TYPE.NAME,
                      value: sectionTitle,
                    });

                    const submissionResponse = await retryApiCall({
                      callApi: dataSubmissionFactory({
                        title: sectionTitle,
                        page,
                      })(submissionData[sectionTitle]),
                    });

                    //TODO: Add failure condition
                    const success = !hasError(submissionResponse);

                    if (!success) {
                      //Handle error
                      const responseBodyKeys = Object.keys(submissionResponse);

                      if (
                        responseBodyKeys.length > 0 &&
                        responseBodyKeys.includes("errors") &&
                        typeof submissionResponse["errors"] === "object" &&
                        submissionResponse["errorType"] !== ERROR_TYPE.BANNER
                      ) {
                        if (
                          Object.keys(submissionResponse["errors"]).includes(
                            "cashClaims"
                          )
                        ) {
                          const cashClaimKeys = Object.keys(
                            submissionResponse["errors"]["cashClaims"]
                          );
                          cashClaimKeys.map((cashClaimKey) => {
                            const index = cashClaimKey[cashClaimKey.length - 1];
                            const cashClaimSectionName =
                              generateMultiSectionKey({
                                index,
                                sectionName: SECTION_NAME.CASH_CLAIM_PLANS,
                              });

                            validationErrors = {
                              ...validationErrors,
                              [cashClaimSectionName]: {
                                ...submissionResponse["errors"]["cashClaims"][
                                  cashClaimKey
                                ],
                              },
                            };
                          });
                        } else {
                          validationErrors = {
                            ...validationErrors,
                            [sectionName]: {
                              ...submissionResponse["errors"],
                              ...validationErrors[sectionName],
                            },
                          };
                        }
                      } else {
                        setErrorMessage({
                          setNotificationsItems: setNotificationItems,
                          content:
                            submissionResponse &&
                            (submissionResponse.message ||
                              JSON.stringify(submissionResponse)),
                          status: submissionResponse.status,
                        });
                      }
                      submitted = success && submitted;
                    }
                  }
                }
              }
              setIsLoadingNextStep(false);

              //Handling errors and preventing continuation.
              if (!submitted) {
                const newErrors = {};
                Object.keys(validationErrors).forEach((sectionName) => {
                  Object.keys(validationErrors[sectionName]).forEach(
                    (fieldName) => {
                      let errorId = fieldToIdMap[sectionName][fieldName];
                      newErrors[errorId] =
                        validationErrors[sectionName][fieldName];
                    }
                  );
                });

                setErrors({ ...errors, ...newErrors });
                return;
              }

              history.push(`/${source}/${id}/review`);
            }}
          >
            Save
          </Button>
        </SpaceBetween>
      }
    />
  );
};

DefaultEditFooter.propTypes = {
  isLoadingNextStep: PropTypes.bool,
  setIsLoadingNextStep: PropTypes.func,
  data: PropTypes.object,
  sectionTitle: PropTypes.string,
  fieldToIdMap: PropTypes.object,
  validators: PropTypes.object,
  setNotificationItems: PropTypes.func,
  setErrors: PropTypes.func,
  errors: PropTypes.object,
  getTemplate: PropTypes.func,
  schema: PropTypes.object,
  filterFields: PropTypes.func,
  dataSubmissionFactory: PropTypes.func,
  page: PropTypes.string,
};
