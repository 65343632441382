import {
  submitReivew,
  submitBusinessApproval,
  submitFinanceApproval,
  submitPreApproval,
  submitCashClaimApproval,
  submitCompletion,
  rejectReivew,
  rejectBusinessApproval,
  rejectFinanceApproval,
  rejectPreApproval,
  rejectCashClaimActual,
  updateFundRequestInformation,
  updateProjectInformation,
  updateMigrationDetails,
  updatePublicSectorDetails,
  updateCashRequestInformation,
  updateCreditRequestDetails,
  updateApprovalDetails,
  updateCashClaimInvoice,
  submitCashClaimInvoice,
  updatePurchaseOrder,
  updateExceptionApproval,
  updateThirdPartyFundingId,
  updateInformationalLetterId,
  updateAccountManagerApproval,
  uploadFile,
  getUploadFileUrl,
  getDownloadUrl,
  getFundRequestAttachments,
  attachFileToFundRequest,
  deleteFile,
} from "./InternalDataService";

import { STAGE } from "../../../../shared/util/constants/fundRequestStatusType";
import { SECTION } from "../../../../shared/util/constants/section";
import { PAGES } from "../../constants/pages";
import { FILE_EVENT_TYPE } from "../../../../external/util/constants/eventType";
import {
  attachFileToCashClaim,
  detachFileFromCashClaim,
  detachFileFromFundRequest,
  updateCashClaim,
} from "../../../../external/util/services/data/FundRequestService";

export const SubmitFactory = (stage) => {
  switch (stage) {
    case STAGE.REVIEW:
      return submitReivew;
    case STAGE.TECH_APPROVAL:
    case STAGE.BUSINESS_APPROVAL:
      return submitBusinessApproval;
    case STAGE.FINANCE_APPROVAL:
      return submitFinanceApproval;
    case STAGE.PRE_APPROVAL:
      return submitPreApproval;
    case STAGE.CASH_CLAIM:
      return submitCashClaimApproval;
    case STAGE.COMPLETED:
      return submitCompletion;
  }
};

export const RejectFactory = (stage) => {
  switch (stage) {
    case STAGE.REVIEW:
      return rejectReivew;
    case STAGE.TECH_APPROVAL:
    case STAGE.BUSINESS_APPROVAL:
      return rejectBusinessApproval;
    case STAGE.FINANCE_APPROVAL:
      return rejectFinanceApproval;
    case STAGE.PRE_APPROVAL:
      return rejectPreApproval;
    case STAGE.CASH_CLAIM:
      return rejectCashClaimActual;
  }
};

export const DataSubmissionFactory = ({ title, submit, page }) => {
  switch (title) {
    case SECTION.FUND_REQUEST_INFORMATION: {
      if (page === PAGES.BUSINESS_APPROVAL) {
        return updateThirdPartyFundingId;
      }
      return updateFundRequestInformation;
    }
    case SECTION.PROJECT_INFORMATION:
      return updateProjectInformation;
    case SECTION.CASH_REQUEST_INFORMATION: {
      let pagesAllowedToEditPO = [
        PAGES.FINANCE_APPROVAL,
        PAGES.PRE_APPROVAL,
        PAGES.CASH_CLAIM_APPROVAL,
      ];
      if (page === PAGES.AWS_APPROVAL) {
        return updateCashRequestInformation;
      }
      if (pagesAllowedToEditPO.includes(page)) {
        return updatePurchaseOrder;
      }
      break;
    }
    case SECTION.CREDIT_REQUEST_DETAILS: {
      if (page === PAGES.FINANCE_APPROVAL) {
        return updateAccountManagerApproval;
      }

      return updateCreditRequestDetails;
    }
    case SECTION.MIGRATION_DETAILS:
      if (page === PAGES.PRE_APPROVAL) {
        return updateInformationalLetterId;
      }
      return updateMigrationDetails;
    case SECTION.PUBLIC_SECTOR_DETAILS:
      return updatePublicSectorDetails;
    case SECTION.APPROVAL_DETAILS: {
      if (page === PAGES.BUSINESS_APPROVAL) {
        return updateExceptionApproval;
      }
      return updateApprovalDetails;
    }
    case SECTION.CASH_CLAIM_ACTUALS: {
      return updateCashClaim;
    }

    case SECTION.CASH_CLAIM_INVOICE_PAYMENT: {
      if (submit) {
        return submitCashClaimInvoice;
      }
      return updateCashClaimInvoice;
    }
  }
};

const AttachmentEventFactory = (eventType) => {
  switch (eventType) {
    case FILE_EVENT_TYPE.ATTACH_TO_FUND_REQUEST:
      return attachFileToFundRequest;
    case FILE_EVENT_TYPE.ATTACH_TO_CASH_CLAIM:
      return attachFileToCashClaim;
    case FILE_EVENT_TYPE.DETACH_FROM_FUND_REQUEST:
      return detachFileFromFundRequest;
    case FILE_EVENT_TYPE.DETACH_FROM_CASH_CLAIM:
      return detachFileFromCashClaim;
    default:
      return;
  }
};

export const FileUploadTriggers = {
  // currently, not supporting delete file
  getUploadFileUrl: getUploadFileUrl,
  uploadFile: uploadFile,
  getDownloadUrl: getDownloadUrl,
  deleteFile: deleteFile,
  getListOfAttachments: getFundRequestAttachments,
  attachmentEventFactory: AttachmentEventFactory,
};
