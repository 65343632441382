import React from "react";
import PropTypes from "prop-types";
import { AttachmentsReviewSectionContainer } from "../../../../shared/components/Review/AttachmentsReviewSectionContainer";
import {
  getDownloadUrl,
  getFundRequestAttachments,
} from "../../../util/services/data/InternalDataService";
import { HeaderLinkButton } from "../../../../shared/components/FundRequest/Review/Header/HeaderLinkButton";
import {
  STAGE,
  STATUS,
} from "../../../../shared/util/constants/fundRequestStatusType";

import { useHistory, useParams } from "react-router-dom";
import { STAGE_MAPPING } from "../../../util/constants/pages";

export const InternalAttachmentsReviewSectionContainer = ({
  setNotificationsItems,
  fundingData,
}) => {
  const history = useHistory();
  const { id } = useParams();
  const validStages = [
    STAGE.REVIEW,
    STAGE.BUSINESS_APPROVAL,
    STAGE.FINANCE_APPROVAL,
    STAGE.CASH_CLAIM,
    STAGE.TECH_APPROVAL,
  ];
  const showCondition =
    fundingData.status === STATUS.ACTIVE &&
    validStages.includes(fundingData.stage);
  const path = STAGE_MAPPING[fundingData.stage];

  return (
    <AttachmentsReviewSectionContainer
      setNotificationsItems={setNotificationsItems}
      getFundRequestAttachments={getFundRequestAttachments}
      getDownloadUrl={getDownloadUrl}
      header={
        <HeaderLinkButton
          history={history}
          condition={showCondition}
          destinationPath={`/${
            path ?? "business-approvals"
          }/${id}/attachments/edit`}
          buttonName="Upload Additional Attachment(s)"
          headerName="Fund Request Attachments"
        />
      }
    />
  );
};

InternalAttachmentsReviewSectionContainer.propTypes = {
  setNotificationsItems: PropTypes.func,
  fundingData: PropTypes.object,
  page: PropTypes.object,
};
