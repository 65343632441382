const AWS_CALC_PATTERN =
  "^((https)://)(calculator)\\.aws/#/estimate\\?id=[^\\s][A-Fa-f0-9]*$";
const SIMPLE_MONTHLY_PATTERN =
  "^((https)://)(calculator)\\.s3\\.amazonaws\\.com/index\\.html#r=[A-Z0-9]{3,4}&s=S3&key=files/calc-[0-9A-Fa-f]*&v=[a-zA-Z0-9]{1,30}$";
const NEW_MONTHLY_CALC_PATTERN =
  "^((https)://)(calculator)\\.aws/#/estimate\\?key=new&id=[^\\s][0-9A-Fa-f]*$";
// TODO: https://issues.amazon.com/issues/APN-35836.
//  Remove Old Simple Monthly Calculator Pattern after it is completely deprecated.

export const URL_UPLOAD_PATTERN = new RegExp(
  `${AWS_CALC_PATTERN}|${SIMPLE_MONTHLY_PATTERN}|${NEW_MONTHLY_CALC_PATTERN}`
);
