import { Header, SpaceBetween } from "@amzn/awsui-components-react";
import Button from "@amzn/awsui-components-react/polaris/button";
import { filterFields, schema } from "../../../../config/schema";
import {
  getSectionConstant,
  SECTION,
  SECTION_CONSTANT_TYPE,
} from "../../../../../shared/util/constants/section";
import { formatData } from "../../../../../shared/util/adapter/toApi/adapter";
import { ValidationFactory } from "../../../../../shared/components/FundRequest/StepsContainer/ValidationFactory";
import {
  ERROR_TYPE,
  hasError,
  retryApiCall,
} from "../../../../../shared/util/services/data/DataService";
import { DataSubmissionFactory } from "../../../../util/services/data/dataFactory";
import { setErrorMessage } from "../../../../../shared/util/common/helper";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import {
  createFieldSchemaObject,
  createJsonSchemaObject,
} from "../../../../../shared/util/schema/util";
import { createValidator } from "../../../../../shared/util/validation/validator";
import { extractCashClaimIndex } from "../../../../../internal/components/Edit/Sections/util";

export const CashRequestActualsEditFooter = ({
  isLoadingNextStep,
  setIsLoadingNextStep,
  data,
  sectionTitle,
  fieldToIdMap,
  setNotificationItems,
  setErrors,
  errors,
}) => {
  const history = useHistory();
  const { id, cashClaimId } = useParams();
  const [validators, setValidators] = useState({});

  const getTemplateKey = (sectionName) => {
    // return the field key used inside fieldToIdMap;
    const claimIndex = extractCashClaimIndex(cashClaimId);
    return `${sectionName}${claimIndex > 1 ? `-${claimIndex}` : ""}`;
  };

  useEffect(() => {
    const fieldsSchemas = [];
    const createdSchemas = [];
    const cashClaimActual = schema[SECTION.CASH_CLAIM_ACTUALS];

    for (let field of cashClaimActual.fields) {
      if (!field.auto && !createdSchemas.includes(field.name)) {
        const attributes = {
          component: field.component,
          fieldName: field.name,
          type: field.type || undefined,
          pattern: field.pattern || undefined,
          errorMessage: field.errorMessage || undefined,
        };
        createdSchemas.push(field.name);
        fieldsSchemas.push(createFieldSchemaObject(attributes));
      }
    }

    const sectionSchemas = [
      createJsonSchemaObject({
        sectionName: cashClaimActual.name,
        fields: cashClaimActual.fields,
        program: data["program"],
      }),
    ];
    setValidators(createValidator([...sectionSchemas, ...fieldsSchemas]));
  }, []);

  return (
    <Header
      actions={
        <SpaceBetween direction="horizontal" size="xs">
          <Button
            onClick={() => {
              history.push(`/fund-request/${id}/review`);
            }}
          >
            Cancel
          </Button>
          <Button
            loading={isLoadingNextStep}
            onClick={async () => {
              setIsLoadingNextStep(true);

              const currentSchema = schema[SECTION.CASH_CLAIM_ACTUALS];
              const sectionName = schema[sectionTitle].name;

              let validated = true;
              let validationErrors = {};
              let submissionData = {};

              const sections = [SECTION.CASH_CLAIM_ACTUALS];

              // Format, and validate data
              for (let sectionTitle of sections) {
                // Skip cash claims, since the data and validation is part of cash request
                if (sectionTitle === SECTION.CASH_CLAIM_PLANS) continue;

                const formattedData = formatData(sectionName)({
                  step: currentSchema,
                  stepName: sectionName,
                  stepTitle: sectionTitle,
                  data,
                  fieldMap: fieldToIdMap,
                  filterFields: filterFields,
                  schema,
                  cashClaimId,
                });

                formattedData["fundRequestId"] = id;
                formattedData["fundClaimId"] = cashClaimId;
                console.log(formattedData);

                //Validate formatted data
                const { errors, valid } = ValidationFactory(sectionTitle)({
                  validators,
                  sectionName,
                  data: formattedData,
                });

                validated = valid && validated;

                submissionData = {
                  ...submissionData,
                  [sectionTitle]: { ...formattedData },
                };

                validationErrors = {
                  ...validationErrors,
                  ...errors,
                };
              }

              let submitted = validated;
              // Attempt submission and capture errors.
              if (validated) {
                for (let sectionTitle of Object.keys(submissionData)) {
                  const sectionName = getSectionConstant({
                    type: SECTION_CONSTANT_TYPE.NAME,
                    value: sectionTitle,
                  });

                  const submissionResponse = await retryApiCall({
                    callApi: DataSubmissionFactory({ title: sectionTitle })(
                      submissionData[sectionTitle],
                      cashClaimId
                    ),
                  });

                  const success = !hasError(submissionResponse);
                  if (!success) {
                    //Handle error
                    const responseBodyKeys = Object.keys(submissionResponse);
                    if (
                      responseBodyKeys.length > 0 &&
                      responseBodyKeys.includes("errors") &&
                      typeof submissionResponse["errors"] === "object" &&
                      submissionResponse["errorType"] !== ERROR_TYPE.BANNER
                    ) {
                      const sectionNameKey = getTemplateKey(sectionName);
                      validationErrors = {
                        ...validationErrors,
                        [sectionNameKey]: {
                          ...submissionResponse["errors"],
                          ...validationErrors[sectionNameKey],
                        },
                      };
                    } else {
                      setErrorMessage({
                        setNotificationsItems: setNotificationItems,
                        content:
                          submissionResponse &&
                          (submissionResponse.message ||
                            JSON.stringify(submissionResponse)),
                        status: submissionResponse.status,
                      });
                    }
                    submitted = success && submitted;
                  }
                }
              }
              setIsLoadingNextStep(false);

              //Handling errors and preventing continuation.
              if (!submitted) {
                const newErrors = {};
                Object.keys(validationErrors).forEach((sectionName) => {
                  Object.keys(validationErrors[sectionName]).forEach(
                    (fieldName) => {
                      let errorId = fieldToIdMap[sectionName][fieldName];
                      newErrors[errorId] =
                        validationErrors[sectionName][fieldName];
                    }
                  );
                });

                setErrors({ ...errors, ...newErrors });
                return;
              }

              history.push(`/fund-request/${id}/review`, {
                origin: "CashClaimActualUpdate",
                cashClaimId: cashClaimId,
              });
            }}
          >
            Save
          </Button>
          <Button
            variant="primary"
            loading={isLoadingNextStep}
            onClick={async () => {
              setIsLoadingNextStep(true);

              const currentSchema = schema[SECTION.CASH_CLAIM_ACTUALS];
              const sectionName = schema[sectionTitle].name;

              let validated = true;
              let validationErrors = {};
              let submissionData = {};

              const sections = [SECTION.CASH_CLAIM_ACTUALS];

              // Format, and validate data
              for (let sectionTitle of sections) {
                // Skip cash claims, since the data and validation is part of cash request
                if (sectionTitle === SECTION.CASH_CLAIM_PLANS) continue;

                const formattedData = formatData(sectionName)({
                  step: currentSchema,
                  stepName: sectionName,
                  stepTitle: sectionTitle,
                  data,
                  fieldMap: fieldToIdMap,
                  schema,
                  filterFields,
                  cashClaimId,
                });
                console.log(formattedData);
                formattedData["fundRequestId"] = id;
                formattedData["fundClaimId"] = cashClaimId;

                //Validate formatted data
                const { errors, valid } = ValidationFactory(sectionTitle)({
                  validators,
                  sectionName,
                  data: formattedData,
                });

                validated = valid && validated;

                submissionData = {
                  ...submissionData,
                  [sectionTitle]: { ...formattedData },
                };

                validationErrors = {
                  ...validationErrors,
                  ...errors,
                };
              }

              let submitted = validated;
              // Attempt submission and capture errors.
              if (validated) {
                for (let sectionTitle of Object.keys(submissionData)) {
                  const sectionName = getSectionConstant({
                    type: SECTION_CONSTANT_TYPE.NAME,
                    value: sectionTitle,
                  });

                  const submissionResponse = await retryApiCall({
                    callApi: DataSubmissionFactory({
                      title: sectionTitle,
                      submit: true,
                    })(submissionData[sectionTitle], cashClaimId),
                  });

                  const success = !hasError(submissionResponse);
                  if (!success) {
                    //Handle error
                    const responseBodyKeys = Object.keys(submissionResponse);
                    console.log(submissionResponse);
                    if (
                      responseBodyKeys.length > 0 &&
                      responseBodyKeys.includes("errors") &&
                      typeof submissionResponse["errors"] === "object" &&
                      submissionResponse["errorType"] !== ERROR_TYPE.BANNER
                    ) {
                      const sectionNameKey = getTemplateKey(sectionName);
                      validationErrors = {
                        ...validationErrors,
                        [sectionNameKey]: {
                          ...submissionResponse["errors"],
                          ...validationErrors[sectionNameKey],
                        },
                      };
                    } else {
                      setErrorMessage({
                        setNotificationsItems: setNotificationItems,
                        content: submissionResponse.message,
                        status: submissionResponse.status,
                      });
                    }
                    submitted = success && submitted;
                  }
                }
              }
              setIsLoadingNextStep(false);

              //Handling errors and preventing continuation.
              if (!submitted) {
                const newErrors = {};
                Object.keys(validationErrors).forEach((sectionName) => {
                  Object.keys(validationErrors[sectionName]).forEach(
                    (fieldName) => {
                      let errorId = fieldToIdMap[sectionName][fieldName];
                      newErrors[errorId] =
                        validationErrors[sectionName][fieldName];
                    }
                  );
                });

                setErrors({ ...errors, ...newErrors });
                return;
              }

              history.push(`/fund-request/${id}/review`, {
                origin: "CashClaimActualSubmit",
                cashClaimId: cashClaimId,
              });
            }}
          >
            Save & Submit
          </Button>
        </SpaceBetween>
      }
    />
  );
};

CashRequestActualsEditFooter.propTypes = {
  isLoadingNextStep: PropTypes.bool,
  setIsLoadingNextStep: PropTypes.func,
  data: PropTypes.object,
  sectionTitle: PropTypes.string,
  fieldToIdMap: PropTypes.object,
  validators: PropTypes.object,
  setNotificationItems: PropTypes.func,
  setErrors: PropTypes.func,
  errors: PropTypes.object,
};
