import { HelpPanel } from "@amzn/awsui-components-react";
import { HelperPanelContent } from "./HelperPanelContent";
import React from "react";
import PropTypes from "prop-types";

export const HelperPanel = ({ content }) => (
  <HelpPanel header={<h2>{(content && content.title) || ""}</h2>}>
    <HelperPanelContent
      content={content.content}
      selfServiceHelperTextFlag={content.selfServiceHelperTextFlag}
    />
  </HelpPanel>
);

HelperPanel.propTypes = {
  content: PropTypes.object,
};
