import React, { useEffect, useState } from "react";
import FilesList from "../../FundRequest/FilesList";
import {
  ERROR_TYPE,
  hasError,
  retryApiCall,
} from "../../../util/services/data/DataService";
import PropTypes from "prop-types";
import Loading from "../../common/Loading";
import { Box } from "@amzn/awsui-components-react";
import { setErrorMessage } from "../../../util/common/helper";

export const CashClaimReviewAttachments = ({
  cashClaimId,
  setNotificationsItems,
  getDownloadUrl,
  getFundRequestAttachments,
}) => {
  const [files, setFiles] = useState([]);
  const [pageLoading, setPageLoading] = useState(true);

  useEffect(async () => {
    await retrieveFiles();
    setPageLoading(false);
  }, []);

  const retrieveFiles = async () => {
    const data = [];
    let nextPageToken = "";
    while (nextPageToken != null) {
      try {
        let response = await retryApiCall({
          callApi: getFundRequestAttachments(nextPageToken),
        });

        //TODO: Add failure condition
        if (hasError(response) && response.errorType === ERROR_TYPE.BANNER) {
          setErrorMessage({
            setNotificationsItems,
            content: response.message,
            status: response.status,
          });
          return;
        }

        for (let item of response.items) {
          const attachmentMetadata = item;
          if (attachmentMetadata.fundClaimId === cashClaimId) {
            data.push(attachmentMetadata);
          }
        }

        nextPageToken = response.nextPageToken;
      } catch (err) {
        console.error(err);
        break;
      }
    }
    setFiles(data);
  };

  return pageLoading ? (
    <Loading />
  ) : (
    <div>
      <h4>Cash Claim Attachments</h4>
      {files.length > 0 ? (
        <FilesList
          files={files}
          setNotificationsItems={setNotificationsItems}
          getDownloadUrl={getDownloadUrl}
        />
      ) : (
        <Box textAlign="center" color="text-body-secondary">
          No attachments available for this fund claim.
        </Box>
      )}
    </div>
  );
};

CashClaimReviewAttachments.propTypes = {
  cashClaimId: PropTypes.string,
  setNotificationsItems: PropTypes.func,
  getDownloadUrl: PropTypes.func,
  getFundRequestAttachments: PropTypes.func,
};
