import React, { ReactNode, useContext } from "react";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import Box from "@amzn/awsui-components-react/polaris/box";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Button from "@amzn/awsui-components-react/polaris/button";
import { FormError, Result, ResultType } from "shared/util/api/util";
import { NotificationsContext } from "shared/util/context/notifications/NotificationsContext";
import { GENERIC_ERROR_MESSAGE } from "external/programs/migration-acceleration-program/2024/fund-request/components/create/wizard/Steps/Artifacts/util";

export interface IDetailsActionWithModalLabels {
  actionButton: string;
  submitButton?: string;
  title: string;
  successMessage?: string;
  errorMessage?: string;
}

const DetailsActionWithModal = ({
  children,
  isLoading,
  isDisabled = false,
  dropModalOnError = true,
  submit,
  cancel,
  labels,
}: {
  children: ReactNode;
  isLoading: boolean;
  isDisabled?: boolean;
  dropModalOnError?: boolean;
  submit: () => Promise<Result<ResultType>>;
  cancel: () => void;
  labels: IDetailsActionWithModalLabels;
}) => {
  const { addNotification } = useContext(NotificationsContext);
  const [visible, setVisible] = React.useState(false);
  return (
    <>
      <Button loading={isLoading} onClick={() => setVisible(true)}>
        {labels.actionButton}
      </Button>
      <Modal
        onDismiss={() => {
          cancel();
          setVisible(false);
        }}
        visible={visible}
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                loading={isLoading}
                variant="link"
                onClick={() => {
                  cancel();
                  setVisible(false);
                }}
              >
                Cancel
              </Button>
              <Button
                loading={isLoading}
                disabled={isDisabled}
                variant="primary"
                onClick={async () => {
                  const response = await submit();
                  if (!response.success) {
                    const payload = response.payload as FormError;
                    // NOTE: we use the error text on input fields instead of
                    // error banner
                    if (dropModalOnError) {
                      addNotification({
                        content:
                          payload.message ||
                          labels.errorMessage ||
                          GENERIC_ERROR_MESSAGE,
                        type: "error",
                      });
                      setVisible(false);
                    }
                  } else {
                    addNotification({
                      content:
                        labels.successMessage ||
                        `${labels.actionButton} was successful.`,
                      type: "success",
                    });
                    setVisible(false);
                  }
                }}
              >
                {labels.submitButton || "Submit"}
              </Button>
            </SpaceBetween>
          </Box>
        }
        header={labels.title}
      >
        {children}
      </Modal>
    </>
  );
};

export default DetailsActionWithModal;
