import React, { ReactElement } from "react";
import { IGenericObject } from "shared/programs/migration-acceleration-program/2024/fund-request/types/CommonTypes";
import { TableProps } from "@amzn/awsui-components-react/polaris/table/interfaces";
import { Box } from "@amzn/awsui-components-react";

export const mpeAttributes: { [key: string]: string } = {
  recordStatus: "Record Status",
  engagementStatus: "Engagement Status",
  migrationArr: "Migration ARR",
  consultingPartnerEngagement: "Consulting Partner Engagement",
  agreementCategory: "Agreement Category",
  privateEquityBacked: "Private Equity Backed",
  engagementStatusWonStartDate: "Engagement Status Won Start Date",
  workshopCompletionDate: "Workshop Completion Date",
  readinessAssessmentStatus: "Readiness Assessment Status",
  deliveredBy: "Delivered By",
  readoutPresentationDate: "Readout Presentation Date",
  mraMethdology: "MRA Methdology",
  olaCompletionDate: "Ola Completion Date",
  confirmed: "OLA Confirmed",
  olaStatus: "Ola Status",
};

export const generateItems = ({
  engagements,
}: {
  engagements: Array<unknown>;
}) => {
  const engagementIdToEngagement = new Map();

  (engagements as Array<IGenericObject>).forEach((engagement) => {
    engagementIdToEngagement.set(engagement?.engagementId, engagement);
  });

  const items = Object.keys(mpeAttributes).map((attribute) => {
    const item: { [key: string]: string | ReactElement } = {
      label: <strong>{mpeAttributes[attribute]}</strong>,
    };

    Array.from(engagementIdToEngagement.keys()).forEach((engagementId) => {
      const engagement = engagementIdToEngagement.get(engagementId);
      item[engagementId] = engagement?.[attribute];
    });

    return item;
  });

  return items;
};

export const generateColumnDefinitions = ({
  engagementIds,
}: {
  engagementIds: Array<string>;
}) => {
  const columnDefinitions: Array<TableProps.ColumnDefinition<IGenericObject>> =
    [
      {
        id: "label",
        header: "",
        cell: (e: IGenericObject) => <Box variant="strong">{e.label}</Box>,
        width: 70,
      },
    ];

  engagementIds.forEach((engagementId, index) => {
    const header = {
      id: index.toString(),
      header: engagementId,
      cell: (e: IGenericObject) => e[engagementId],
    };

    columnDefinitions.push(header);
  });

  return columnDefinitions;
};
