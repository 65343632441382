import React, { useState, useEffect } from "react";
import { Header } from "@amzn/awsui-components-react";
import PropTypes from "prop-types";
import Button from "@amzn/awsui-components-react/polaris/button";
import { STAGE } from "../../../../../shared/util/constants/fundRequestStatusType";
import {
  FUNDING_TYPE,
  PROGRAM_TYPE,
} from "../../../../../shared/util/constants/programType";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import { ISV_WORKLOAD_MIGRATION_PROGRAM_NAME } from "../../../../../shared/util/constants/subProgram";
import WMPConsent from "../WMPConsent";
import { isFeatureEnabled } from "../../../../../shared/util/services/features/FeatureFlagsService";
import { FEATURE_FLAG_ENUMS } from "../../../../../shared/util/constants/featureFlagValues";

export const UploadCreditAttachmentHeader = ({
  id,
  stage,
  history,
  fundingData,
  setNotificationsItems,
  setFundingData,
}) => {
  const [consentFlagEnabled, setConsentFlagEnabled] = useState(false);

  const isConsentButtonDisabled = () => {
    return (
      fundingData &&
      fundingData.fundingTemplate &&
      fundingData.fundingTemplate.isvWmpSecondTrancheConsentCaptured
    );
  };

  const [buttonDisabled, setButtonDisabled] = useState(isConsentButtonDisabled);
  const [consentChecked, setConsentChecked] = useState(false);
  const [consentVisible, setConsentVisible] = useState(false);

  useEffect(async () => {
    setConsentFlagEnabled(
      await isFeatureEnabled(FEATURE_FLAG_ENUMS.ISV_WMP_CONSENT_ENABLED)
    );
  }, []);

  const allow_credit_upload =
    stage === STAGE.PRE_APPROVAL &&
    fundingData.fundingType === FUNDING_TYPE.CREDIT;

  const is_isv_wmp_program =
    fundingData.program === PROGRAM_TYPE.MISC &&
    fundingData.fundingTemplate &&
    fundingData.fundingTemplate.subProgram ===
      ISV_WORKLOAD_MIGRATION_PROGRAM_NAME;

  if (consentFlagEnabled) {
    return (
      <React.Fragment>
        <Header
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              {allow_credit_upload && is_isv_wmp_program && (
                <Button
                  disabled={buttonDisabled}
                  variant="primary"
                  onClick={() => {
                    setConsentVisible(true);
                  }}
                >
                  Second Tranche Claim
                </Button>
              )}
              {allow_credit_upload && !is_isv_wmp_program && (
                <Button
                  onClick={() => {
                    history.push(`/fund-request/${id}/attachments/edit`);
                  }}
                >
                  Upload Proof of Credit Claim
                </Button>
              )}
            </SpaceBetween>
          }
        >
          Fund Request Attachments
        </Header>
        {consentVisible && (
          <WMPConsent
            setButtonDisabled={setButtonDisabled}
            consentVisible={consentVisible}
            setConsentVisible={setConsentVisible}
            consentChecked={consentChecked}
            setConsentChecked={setConsentChecked}
            setNotificationsItems={setNotificationsItems}
            setFundingData={setFundingData}
            fundingData={fundingData}
          />
        )}
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <Header
          actions={
            allow_credit_upload && (
              <Button
                onClick={() => {
                  history.push(`/fund-request/${id}/attachments/edit`);
                }}
              >
                Upload Proof of Credit Claim
              </Button>
            )
          }
        >
          Fund Request Attachments
        </Header>
      </React.Fragment>
    );
  }
};

UploadCreditAttachmentHeader.propTypes = {
  id: PropTypes.string,
  stage: PropTypes.string,
  history: PropTypes.any,
  fundingData: PropTypes.object,
  setNotificationsItems: PropTypes.func,
  setFundingData: PropTypes.func,
};
