export interface ComponentAttributes {
  keyName: string;
}

export interface CustomComponentProps {
  [key: string]: string | object;
}

export const generateCustomComponentProps = ({
  keyName,
}: ComponentAttributes): CustomComponentProps => {
  return keynameToCustomAttributes[keyName] || {};
};

export const keynameToCustomAttributes: {
  [key: string]: { [key: string]: object };
} = {
  plannedStartDate: {
    isDateEnabled: (date: Date) => {
      const maxInvalidDate = new Date();
      const invalidDateDifference = 13;
      maxInvalidDate.setDate(maxInvalidDate.getDate() + invalidDateDifference);

      return date > maxInvalidDate;
    },
  },
  plannedEndDate: {
    isDateEnabled: (date: Date) => {
      const maxInvalidDate = new Date();
      const invalidDateDifference = 13;
      maxInvalidDate.setDate(maxInvalidDate.getDate() + invalidDateDifference);

      return date > maxInvalidDate;
    },
  },
};
