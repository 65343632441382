import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Button from "@amzn/awsui-components-react/polaris/button";
import React from "react";
import { useHistory } from "react-router-dom";
import { getFundRequestId } from "external/util/common/helper";
import ConditionalField from "shared/components/common/Conditional/ConditionalField";

const ReadOnlySectionActions = ({
  isVisible,
  sectionNumber,
}: {
  isVisible: boolean;
  sectionNumber: number;
}) => {
  const history = useHistory();
  return (
    <ConditionalField showField={isVisible}>
      <SpaceBetween direction="horizontal" size="xs">
        <Button
          onClick={() => {
            history.push(
              `/fund-requests/${getFundRequestId()}/new?activeSection=${sectionNumber}`
            );
          }}
        >
          Edit
        </Button>
      </SpaceBetween>
    </ConditionalField>
  );
};

export default ReadOnlySectionActions;
