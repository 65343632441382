import React from "react";
import Link from "@amzn/awsui-components-react/polaris/link";

export const generatePhonetoolLink = (alias) => {
  let link = "-";

  if (alias) {
    link = alias.includes("@") ? (
      alias
    ) : (
      <a
        href={`https://phonetool.amazon.com/users/${alias}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {alias}@
      </a>
    );
  }

  return link;
};

export const generatePhonetoolLinkFromEmail = (email, field) => {
  if (email) {
    const userId = email.split("@")[0];
    return (
      <Link external href={`https://phonetool.amazon.com/users/${userId}`}>
        {field || userId}
      </Link>
    );
  }
};
