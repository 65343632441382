import React from "react";
import PartnerCommentDetailsActionWithModal, {
  handlePartnerCommentErrorResponse,
  ISubmitPayload,
} from "external/programs/migration-acceleration-program/2024/fund-request/components/details/FundRequestDetailsContainer/header/actions/PartnerCommentDetailsActionWithModal";
import { recallFundRequest } from "external/util/services/data/FundRequestService";
import { hasError } from "shared/util/services/data/DataService";
import { IGenericObject } from "shared/programs/migration-acceleration-program/2024/fund-request/types/CommonTypes";
import { Result, Success } from "shared/util/api/util";

const Recall = ({ isLoading }: { isLoading: boolean }) => {
  const onSubmit = async ({ payload }: ISubmitPayload) => {
    let result = new Result<Success>({});
    const response: IGenericObject = await recallFundRequest(payload);

    if (hasError(response)) {
      result = handlePartnerCommentErrorResponse({ response });
    }

    return result;
  };

  return (
    <>
      <PartnerCommentDetailsActionWithModal
        isLoading={isLoading}
        submit={onSubmit}
        labels={{
          actionButton: "Recall",
          submitButton: "Recall fund request",
          title: "Recall fund request",
          description:
            "Provide the reason(s) why you are recalling this fund request; details on what fields needs to be updated or why the fund request needs to be on hold.",
          label: "Recall reason",
        }}
      />
    </>
  );
};

export default Recall;
