import {
  IClaim,
  IFundRequest,
} from "shared/programs/migration-acceleration-program/2024/fund-request/types/SchemaTypes";

import {
  extractClaimActualDetails,
  extractClaimBaseDetails,
  extractClaimIdToClaim,
  extractClaimInvoiceDetails,
  extractFundRequestBaseDetails,
  extractMigrationEligibility,
  extractPurchaseOrderDetails,
  formatCurrency,
} from "shared/programs/migration-acceleration-program/2024/fund-request/api/adapters";
import {
  IArtifacts,
  IFundingDetails,
  IMigrationEligibility,
  IOpportunityDetails,
  IProjectDetails,
  IReview,
} from "shared/programs/migration-acceleration-program/2024/fund-request/types/FundRequestAPIModelTypes";
import { IGenericObject } from "shared/programs/migration-acceleration-program/2024/fund-request/types/CommonTypes";

export const opportunityAdapter = (
  apiResponse: IGenericObject
): IOpportunityDetails => {
  const fundRequest = apiResponse["fundRequest"];

  return {
    ...extractFundRequestBaseDetails(fundRequest),
    ...extractOpportunity(fundRequest),
    ...extractMigrationEligibility(fundRequest),
  };
};

const extractOpportunity = (fundRequest: IFundRequest) => {
  const opportunities = fundRequest["opportunities"];
  const aceOpportunityId = opportunities && Object.keys(opportunities)[0];

  const opportunity =
    opportunities && aceOpportunityId && opportunities[aceOpportunityId];
  const record = opportunity && opportunity["record"];

  return {
    aceOpportunityId,
    opportunity: record,
  };
};

export const projectDetailsAdapter = (
  apiResponse: IGenericObject
): IProjectDetails & IMigrationEligibility => {
  const fundRequest = apiResponse["fundRequest"];

  return {
    ...extractFundRequestBaseDetails(fundRequest),
    ...extractProjectDetails(fundRequest).projectDetails,
    ...extractOpportunity(fundRequest),
    ...extractMigrationEligibility(fundRequest),
  };
};

const extractProjectDetails = (fundRequest: IFundRequest) => {
  const project = fundRequest["project"];
  const vmwareAcknowledgement =
    fundRequest?.incentives?.vmware?.eligible || false;
  const numberOfVmwareToMigrate =
    fundRequest?.incentives?.vmware?.numberOfVmwareToMigrate;
  const totalNumberOfWorkloadsInUse =
    fundRequest?.incentives?.vmware?.totalNumberOfWorkloadsInUse;

  let projectDetails: IGenericObject = {};
  if (project) {
    const activityName = project["activityName"];
    const description = project["description"];
    const plannedStartDate = project["startDate"];
    const plannedEndDate = project["endDate"];
    const awsAccountId = project["awsAccountId"];

    const location = project["location"];
    const executedCountry = location["country"];

    const states = location["states"];
    const executedState = Array.isArray(states) ? states.join(",") : states;

    const artifacts = fundRequest && fundRequest["artifacts"];
    const consents = artifacts && artifacts["consents"];
    const extensions = project["extensions"];
    const customerRfx = project["customerRfx"];

    if (consents && consents.length > 0) {
      const [wwpsConsent] = consents.filter(
        (consent: IGenericObject) => consent.name === "WWPS"
      );
      projectDetails["wwpsConsent"] = !!wwpsConsent;
    }

    if (customerRfx) {
      projectDetails["customerRfx"] = customerRfx;
    }

    let extendedStartDate;
    let extendedEndDate;
    let extensionReason;
    if (extensions && extensions.length > 0) {
      const latestExtension = extensions.at(-1);
      extendedStartDate = latestExtension?.startDate;
      extendedEndDate = latestExtension?.endDate;
      extensionReason = latestExtension?.reason;
    }

    projectDetails = {
      ...projectDetails,
      activityName,
      description,
      plannedStartDate,
      plannedEndDate,
      extendedStartDate,
      extendedEndDate,
      extensionReason,
      awsAccountId,
      executedCountry,
      executedState,
      vmwareAcknowledgement,
      numberOfVmwareToMigrate,
      totalNumberOfWorkloadsInUse,
    };
  }
  return {
    projectDetails,
  };
};

export const fundingDetailsAdapter = (
  apiResponse: IGenericObject
): IFundingDetails => {
  const fundRequest = apiResponse["fundRequest"];

  return {
    ...extractFundRequestBaseDetails(fundRequest),
    ...extractMigrationEligibility(fundRequest),
    ...extractFundingDetails(fundRequest)["fundingDetails"],
  };
};

const extractFundingDetails = (fundRequest: IFundRequest) => {
  const funding = fundRequest["fundingDetails"];
  let fundingDetails = {};
  if (funding) {
    const totalCostOfActivity = formatCurrency(
      funding["totalCostOfActivityLocalAmount"]
    );
    const cashFundingAmount = formatCurrency(
      funding["totalFundingRequestedFromAwsLocalAmount"]
    );
    const currency = funding["localCurrency"];
    const currencyConversionRateToUsd =
      funding?.currencyConversionRateToUsd?.rate?.toFixed(8);

    const approvedCashLocalAmount = formatCurrency(
      funding["approvedCashLocalAmount"],
      true
    );

    const invoiceAddress = funding["invoiceAddress"];
    const invoiceEntityName = invoiceAddress["name"];
    const invoiceRemitStreet = invoiceAddress["street"];
    const invoiceRemitUnit = invoiceAddress["unit"];
    const invoiceRemitCity = invoiceAddress["city"];
    const invoiceRemitState = invoiceAddress["state"];
    const invoiceRemitPostalCode = invoiceAddress["postalCode"];
    const invoiceRemitCountry = invoiceAddress["country"];
    const claimPriorities = funding.claimPriorities;

    fundingDetails = {
      ...extractPurchaseOrderDetails(fundRequest),
      totalCostOfActivity,
      cashFundingAmount,
      approvedCashLocalAmount,
      invoiceEntityName,
      invoiceRemitStreet,
      invoiceRemitUnit,
      invoiceRemitCity,
      invoiceRemitState,
      invoiceRemitPostalCode,
      currency,
      currencyConversionRateToUsd,
      invoiceRemitCountry,
      claimPriorities,
    };
  }

  return {
    fundingDetails,
  };
};

export const artifactsAdapter = (apiResponse: IGenericObject): IArtifacts => {
  const fundRequest = apiResponse["fundRequest"];
  const vmwareAcknowledgement =
    fundRequest?.incentives?.vmware?.eligible || false;

  return {
    ...extractFundRequestBaseDetails(fundRequest),
    ...extractArtifacts(fundRequest),
    vmwareAcknowledgement,
  };
};

const extractClaims = (
  fundRequest: IFundRequest,
  claimsList: IGenericObject
) => {
  let claims: IClaim[] = [];
  const fundingDetails = fundRequest && fundRequest["fundingDetails"];
  const projectDetails = fundRequest && fundRequest["project"];
  const claimPlans = fundingDetails && fundingDetails["claimPlans"];
  if (!claimPlans) {
    return {};
  }
  Object.keys(claimPlans).forEach((claimId: string) => {
    const plan = claimPlans[claimId];
    const claim = claimsList[claimId];
    const projectStartDate = projectDetails && projectDetails["startDate"];
    const claimDueDate = plan && plan["dueDate"];
    const claimAmount = plan && plan["localAmount"];
    const claimPriority =
      claimsList && claimsList[claimId] && claimsList[claimId]["priority"];
    const claimStatus =
      claimsList && claimsList[claimId] && claimsList[claimId]["status"];
    const claimStage =
      claimsList && claimsList[claimId] && claimsList[claimId]["stage"];
    const vmwareClaim = plan?.properties?.vmwareBonus !== undefined;
    const vmwareMSPAcknowledgement =
      plan?.properties?.vmwareBonus?.plannedBonusOption === "vmware_manage";
    const isVMwareManagedServicesInScope =
      claim?.properties?.vmwareBonus?.actualBonusOption === "vmware_manage";
    const numberOfVmwareToMigrateFromPlan =
      plan?.properties?.vmwareBonus?.numberOfVmwareToMigrate;
    const totalNumberOfWorkloadsInUseFromPlan =
      plan?.properties?.vmwareBonus?.totalNumberOfWorkloadsInUse;
    const numberOfVmwareToMigrate =
      claim?.properties?.vmwareBonus?.numberOfVmwareToMigrate ||
      numberOfVmwareToMigrateFromPlan;
    const totalNumberOfWorkloadsInUse =
      claim?.properties?.vmwareBonus?.totalNumberOfWorkloadsInUse ||
      totalNumberOfWorkloadsInUseFromPlan;
    const toolsUsedForGeneratingMigrationReport =
      claim?.properties?.vmwareBonus?.toolsUsedForGeneratingMigrationReport &&
      claim?.properties?.vmwareBonus?.toolsUsedForGeneratingMigrationReport.join(
        ","
      );
    claims.push({
      ...extractClaimBaseDetails(claimsList[claimId]),
      invoice: extractClaimInvoiceDetails(claimsList[claimId]),
      ...extractClaimActualDetails(claimsList[claimId]),
      claimId,
      claimPriority,
      projectStartDate,
      claimDueDate,
      claimAmount,
      claimStatus,
      claimStage,
      vmwareClaim,
      vmwareMSPAcknowledgement,
      isVMwareManagedServicesInScope,
      numberOfVmwareToMigrate,
      totalNumberOfWorkloadsInUse,
      toolsUsedForGeneratingMigrationReport,
    });
  });
  claims = [...claims].sort(
    (a: IClaim, b: IClaim) => a.claimPriority - b.claimPriority
  );
  return {
    claims,
  };
};

const extractArtifacts = (fundRequest: IFundRequest) => {
  const artifacts = fundRequest["artifacts"];
  const pricingCalculatorLinks = artifacts["pricingCalculatorLinks"];
  let awsCalculatorUrl = "";

  if (pricingCalculatorLinks.length > 0) {
    awsCalculatorUrl = pricingCalculatorLinks.join(",");
  }
  return {
    artifacts,
    awsCalculatorUrl,
  };
};

export const reviewAdapter = (apiResponse: IGenericObject): IReview => {
  const fundRequest = apiResponse["fundRequest"];
  const claims = apiResponse["claims"];
  if (!fundRequest) return {};
  if (!claims) return {};
  return {
    ...extractFundRequestBaseDetails(fundRequest),
    ...extractOpportunity(fundRequest),
    ...extractProjectDetails(fundRequest),
    ...extractMigrationEligibility(fundRequest),
    ...extractFundingDetails(fundRequest),
    ...extractArtifacts(fundRequest),
    ...extractClaimIdToClaim(apiResponse),
    ...extractClaims(fundRequest, claims),
  };
};
