export const PROGRAM_TYPE = {
  SMP: "SMP",
  MDF: "MDF",
  SANDBOX: "Sandbox",
  MISC: "Misc",
  POC: "POC",
  MAP_LITE: "MAP LITE",
  MAP_LARGE: "MAP LARGE",
  PIF: "PIF",
  MAP2024: "MAP",
};

export const FUNDING_TYPE = {
  CASH: "Cash",
  CREDIT: "Credit",
  COMBO: "Combo",
};

export const PROGRAM_NAME_TYPE = {
  [PROGRAM_TYPE.SMP]: "Simplified Migration Program",
  [PROGRAM_TYPE.MDF]: "Marketing Development Fund",
  [PROGRAM_TYPE.SANDBOX]: "Sandbox",
  [PROGRAM_TYPE.MISC]: "Miscellaneous",
  [PROGRAM_TYPE.POC]: "Proof Of Concept",
  [PROGRAM_TYPE.PIF]: "Partner Initiative Funding",
  [PROGRAM_TYPE.MAP2024]: "Migration Acceleration Program",
};
