import React, { createContext, useContext } from "react";
import PropTypes from "prop-types";

const StepsContainerContext = createContext([]);

export const useStepContainerContext = () => useContext(StepsContainerContext);

const StepsContainerProvider = ({ activeIndex, setActiveIndex, children }) => {
  return (
    <StepsContainerContext.Provider value={[activeIndex, setActiveIndex]}>
      {children}
    </StepsContainerContext.Provider>
  );
};

StepsContainerProvider.propTypes = {
  children: PropTypes.element.isRequired,
  activeIndex: PropTypes.number,
  setActiveIndex: PropTypes.func,
};

export { StepsContainerProvider };
