import React from "react";
import PropTypes from "prop-types";
import Button from "@amzn/awsui-components-react/polaris/button";

const AddButton = ({ multiSectionDetails, addSection, isDisabled = false }) => {
  return (
    <div>
      <div>
        <Button
          variant="normal"
          onClick={() => addSection()}
          disabled={isDisabled}
        >
          {multiSectionDetails.addButtonText}
        </Button>
        <div className="steps-details-small">
          {isDisabled
            ? multiSectionDetails.addButtonDisabledMessage
            : multiSectionDetails.addButtonMessage}
        </div>
      </div>
    </div>
  );
};

AddButton.propTypes = {
  multiSectionDetails: PropTypes.object,
  addSection: PropTypes.func,
  isDisabled: PropTypes.bool,
};

export default AddButton;
