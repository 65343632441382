import React from "react";
import PropTypes from "prop-types";
import { Header, SplitPanel } from "@amzn/awsui-components-react";
import Table from "@amzn/awsui-components-react/polaris/table";
import {
  getSplitPanelColumnDefinitions,
  getSplitPanelRowEntries,
} from "./util";

export const MigrationEngagementsSplitPanelContainer = ({ selectedItems }) => {
  let splitPanelContentHeader =
    selectedItems.length === 1 ? "View Details" : "Compare Details";

  return (
    <SplitPanel
      header="MPE(s) selected"
      hidePreferencesButton="true"
      closeBehavior="collapse"
    >
      <Table
        columnDefinitions={getSplitPanelColumnDefinitions(selectedItems)}
        items={getSplitPanelRowEntries(selectedItems)}
        header={<Header> {splitPanelContentHeader} </Header>}
        stickyHeader
        stripedRows
      />
    </SplitPanel>
  );
};

MigrationEngagementsSplitPanelContainer.propTypes = {
  selectedItems: PropTypes.array,
};
