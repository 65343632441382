import React from "react";
import PropTypes from "prop-types";
import { v4 as uuid } from "uuid";
import {
  ColumnLayout,
  Container,
  ExpandableSection,
  Tabs,
} from "@amzn/awsui-components-react";
import { DefaultSectionHeader } from "./SectionHeader/DefaultSectionHeader";
import {
  SECTION,
  SECTION_NAME,
} from "../../../../shared/util/constants/section";
import { uiToApiMap } from "../../../../shared/util/adapter/toUi/adapter";
import { createValueField } from "../../../../shared/components/Review/CreateValueField";
import Header from "@amzn/awsui-components-react/polaris/header";
import { ValueFactory } from "./ValueFactory";
import { filterFields, schema } from "../../../config/schema";

export const MigrationDetailsReviewSectionContainer = ({
  template,
  fundingData,
}) => {
  const fields = [];

  const migrationDetailsApiKey = uiToApiMap[SECTION_NAME.MIGRATION_DETAILS];

  const filteredFields = filterFields({
    section: schema[SECTION.MIGRATION_DETAILS],
    program: fundingData.program,
  });

  for (let field of filteredFields) {
    fields.push(
      createValueField({
        field,
        value: ValueFactory({
          apiKey: migrationDetailsApiKey,
          sectionTitle: SECTION.MIGRATION_DETAILS,
          name: field.name,
          data: fundingData,
          field,
        }),
      })
    );
  }

  const apiKey = uiToApiMap[SECTION_NAME.MIGRATION_DETAILS];

  const migrationWorkloadFilteredFields = filterFields({
    section: schema[SECTION.MIGRATION_WORKLOADS],
    program: fundingData.program,
  });

  const workloadData =
    fundingData && fundingData[apiKey] && fundingData[apiKey]["workloads"];

  const workloads = [];
  workloadData &&
    workloadData.length > 0 &&
    workloadData.map((workload, index) => {
      const workloadFields = [];
      migrationWorkloadFilteredFields.map((field) => {
        workloadFields.push(
          createValueField({
            field,
            value: ValueFactory({
              apiKey,
              sectionTitle: SECTION.MIGRATION_WORKLOADS,
              name: field.name,
              data: workload,
              field,
            }),
          })
        );
      });

      const Workload = (
        <Container header={<Header>Migration {index + 1}</Header>}>
          <ColumnLayout columns={4} variant="text-grid">
            {workloadFields}
          </ColumnLayout>
        </Container>
      );

      workloads.push({
        id: `migration-${index}`,
        label: `Migration ${index + 1}`,
        content: Workload,
      });
    });

  return (
    <React.Fragment key={uuid()}>
      <ExpandableSection
        variant="container"
        defaultExpanded={true}
        header={
          <DefaultSectionHeader
            title={SECTION.MIGRATION_DETAILS}
            name={SECTION_NAME.MIGRATION_DETAILS}
            template={template}
            stage={fundingData.stage}
            status={fundingData.status}
          />
        }
      >
        <ColumnLayout columns={4} variant="text-grid">
          {fields}
        </ColumnLayout>
      </ExpandableSection>
      {workloads.length > 0 ? <Tabs tabs={workloads} /> : ""}
    </React.Fragment>
  );
};
MigrationDetailsReviewSectionContainer.propTypes = {
  fundingData: PropTypes.object,
  step: PropTypes.array,
  template: PropTypes.object,
};
