import React, { useContext, useEffect, useState } from "react";

import FundRequestDetailsContainer from "../../components/details/FundRequestDetailsContainer";
import { fetchFundRequest } from "external/programs/migration-acceleration-program/2024/fund-request/components/create/wizard/util";
import { useLocation, useParams } from "react-router-dom";
import { reviewAdapter } from "external/programs/migration-acceleration-program/2024/fund-request/components/create/wizard/api/adapters";
import { IGenericObject } from "shared/programs/migration-acceleration-program/2024/fund-request/types/CommonTypes";
import { NotificationsContext } from "shared/util/context/notifications/NotificationsContext";
import { RouteOrigin } from "shared/util/constants/RouteState";
import { HelpPanelContentContext } from "shared/util/context/help/HelpContext";

const MAPFundRequestDetails = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<IGenericObject>({});
  const { id } = useParams<IGenericObject>();
  const { state } = useLocation<IGenericObject>();
  const { addNotification } = useContext(NotificationsContext);
  const { setKey } = React.useContext(HelpPanelContentContext);

  useEffect(() => {
    if (state?.origin === RouteOrigin.Wizard) {
      addNotification({
        content: "Fund request has been successfully submitted.",
        type: "success",
      });
    }

    if (state?.origin === RouteOrigin.ClaimSubmission) {
      addNotification({
        content: state?.message,
        type: "success",
      });
    }

    setKey("details.progression");

    getFundRequest();
  }, []);

  const getFundRequest = async () => {
    setIsLoading(true);
    const adapt = reviewAdapter;
    await fetchFundRequest({ id, adapt, setData });
    setIsLoading(false);
  };

  return (
    <>
      <FundRequestDetailsContainer
        isLoading={isLoading}
        refresh={getFundRequest}
        data={data}
      />
    </>
  );
};

export default MAPFundRequestDetails;
