import React from "react";

import { updateTemplateInformation } from "../../util/services/data/FundRequestService";
import EditProgramSelectContainer from "../../../shared/components/FundRequest/Edit/EditProgramSelectContainer";
import { useParams } from "react-router-dom";

const ProgramSelectEditPage = () => {
  const { id } = useParams();

  const successfulSubmitRedirectUrl = `/fund-request/${id}/draft`;
  return (
    <EditProgramSelectContainer
      updateTemplateInformation={updateTemplateInformation}
      successfulSubmitRedirectUrl={successfulSubmitRedirectUrl}
    />
  );
};

export default ProgramSelectEditPage;
