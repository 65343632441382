import React, { useEffect, useState } from "react";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import {
  Alert,
  Box,
  FormField,
  SpaceBetween,
} from "@amzn/awsui-components-react";
import Button from "@amzn/awsui-components-react/polaris/button";
import PropTypes from "prop-types";
import Textarea from "@amzn/awsui-components-react/polaris/textarea";
import { capitalize } from "../../../util/helper";
import Multiselect from "@amzn/awsui-components-react/polaris/multiselect";
import {
  optionsMap,
  selfServiceRejectionReasonCodeEntityMap,
} from "../../../util/constants/rejections";
import { isFeatureEnabled } from "../../../../shared/util/services/features/FeatureFlagsService";
import { FEATURE_FLAG_ENUMS } from "../../../../shared/util/constants/featureFlagValues";
import { getSelfServiceOptions } from "../../../../shared/util/common/selfServiceUtil";

const COMMENT_TYPE = {
  INTERNAL: "internal",
  EXTERNAL: "external",
  REJECTION_CODES: "reason_codes",
};
const errorMessage =
  "Upon rejection, both comments and reason codes are required";
const RejectCommentModal = ({
  reviewActionType,
  isLoading,
  isVisible,
  reviewAction,
  updateVisibility,
  stage,
}) => {
  const [comment, setComment] = useState({
    [COMMENT_TYPE.INTERNAL]: "",
    [COMMENT_TYPE.EXTERNAL]: "",
    [COMMENT_TYPE.REJECTION_CODES]: [],
  });
  const [error, setNewError] = useState({
    [COMMENT_TYPE.INTERNAL]: "",
    [COMMENT_TYPE.EXTERNAL]: "",
    [COMMENT_TYPE.REJECTION_CODES]: "",
  });
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [otherAlertVisibility, setOtherAlertVisibility] = useState(false);
  const maxNumberOfSelection = 5;
  const [rejectionReasonCodeOptions, setRejectionReasonCodeOptions] = useState(
    []
  );
  const [optionsStatus, setOptionsStatus] = useState("loading");

  useEffect(() => {
    const featureFlag = async () => {
      const selfServiceRejectionReasonCodesFlag = await isFeatureEnabled(
        FEATURE_FLAG_ENUMS.SELF_SERVICE_REJECTION_REASON_CODE
      );

      if (!ignore) {
        let options;
        if (
          selfServiceRejectionReasonCodesFlag &&
          stage in selfServiceRejectionReasonCodeEntityMap
        ) {
          console.log({ stage });
          options = await getSelfServiceOptions(
            selfServiceRejectionReasonCodeEntityMap[stage]
          );
        } else {
          options = (stage && optionsMap[stage]) || [];
        }
        setRejectionReasonCodeOptions(options);
        options?.length
          ? setOptionsStatus("finished")
          : setOptionsStatus("error");
      }
    };
    let ignore = false;
    featureFlag();
    return () => {
      ignore = true;
    };
  }, [stage]);

  useEffect(() => {
    setComment({
      [COMMENT_TYPE.INTERNAL]: "",
      [COMMENT_TYPE.EXTERNAL]: "",
      [COMMENT_TYPE.REJECTION_CODES]: [],
    });
    setNewError({
      [COMMENT_TYPE.INTERNAL]: "",
      [COMMENT_TYPE.EXTERNAL]: "",
      [COMMENT_TYPE.REJECTION_CODES]: "",
    });
    setSelectedOptions([]);
    setOtherAlertVisibility(false);
  }, [reviewActionType]);

  return (
    <Modal
      id="comment-modal"
      onDismiss={() => updateVisibility(reviewActionType, false)}
      visible={isVisible}
      closeAriaLabel="Close modal"
      size="large"
      header={`${capitalize(reviewActionType)} Fund Request`}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              onClick={() => {
                updateVisibility(reviewActionType, false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              loading={isLoading}
              onClick={async () => {
                const errors = {};
                if (!comment[COMMENT_TYPE.INTERNAL]) {
                  errors[COMMENT_TYPE.INTERNAL] = errorMessage;
                }
                if (!comment[COMMENT_TYPE.EXTERNAL]) {
                  errors[COMMENT_TYPE.EXTERNAL] = errorMessage;
                }
                if (comment[COMMENT_TYPE.REJECTION_CODES].length === 0) {
                  errors[COMMENT_TYPE.REJECTION_CODES] = errorMessage;
                }
                if (Object.keys(errors).length > 0) {
                  setNewError({
                    ...error,
                    ...errors,
                  });
                  return;
                }
                await reviewAction({
                  internalComment: comment[COMMENT_TYPE.INTERNAL],
                  externalComment: comment[COMMENT_TYPE.EXTERNAL],
                  reasonCodes: comment[COMMENT_TYPE.REJECTION_CODES],
                });
              }}
            >
              Submit
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <SpaceBetween size="l">
        <FormField
          label="Please enter an internal comment."
          errorText={error[COMMENT_TYPE.INTERNAL]}
        >
          <Textarea
            id="internal-comment"
            value={comment[COMMENT_TYPE.INTERNAL]}
            onChange={({ detail }) => {
              setNewError({ ...error, [COMMENT_TYPE.INTERNAL]: "" });
              setComment({ ...comment, [COMMENT_TYPE.INTERNAL]: detail.value });
            }}
          />
        </FormField>
        <FormField
          label="Please enter a comment for the partner."
          description="This comment will be visible by the partner."
          errorText={error[COMMENT_TYPE.EXTERNAL]}
        >
          <Textarea
            id="external-comment"
            value={comment[COMMENT_TYPE.EXTERNAL]}
            onChange={({ detail }) => {
              setNewError({ ...error, [COMMENT_TYPE.EXTERNAL]: "" });
              setComment({ ...comment, [COMMENT_TYPE.EXTERNAL]: detail.value });
            }}
          />
        </FormField>

        <FormField
          label="Please select up to 5 rejection reason code from the drop down below."
          errorText={error[COMMENT_TYPE.REJECTION_CODES]}
        >
          {otherAlertVisibility && (
            <Alert>
              Internal and external comments are required to reject a fund
              request.
            </Alert>
          )}
          <Multiselect
            selectedOptions={selectedOptions}
            onChange={({ detail }) => {
              const selectedCodes = detail.selectedOptions;
              const hasOther = selectedCodes.some(
                (option) => option.label === "Other"
              );
              hasOther
                ? setOtherAlertVisibility(true)
                : setOtherAlertVisibility(false);

              setSelectedOptions(selectedCodes);
              setComment({
                ...comment,
                [COMMENT_TYPE.REJECTION_CODES]: selectedCodes.map(
                  (option) => option.value
                ),
              });

              const errorMsg =
                selectedCodes.length > maxNumberOfSelection
                  ? `Please select up to ${maxNumberOfSelection} rejection codes.`
                  : "";
              setNewError({
                ...error,
                [COMMENT_TYPE.REJECTION_CODES]: errorMsg,
              });
            }}
            deselectAriaLabel={(e) => `Remove ${e.label}`}
            selectedAriaLabel="Selected"
            filteringType="auto"
            placeholder={`You can select a maximum of ${maxNumberOfSelection} rejection codes`}
            loadingText="Loading rejection codes"
            empty="No rejection code found for current stage"
            errorText="Error fetching rejection codes"
            options={rejectionReasonCodeOptions}
            statusType={optionsStatus}
          />
        </FormField>
      </SpaceBetween>
    </Modal>
  );
};

RejectCommentModal.propTypes = {
  isLoading: PropTypes.bool,
  isVisible: PropTypes.bool,
  reviewActionType: PropTypes.string,
  reviewAction: PropTypes.func,
  updateVisibility: PropTypes.func,
  stage: PropTypes.string,
};

export default RejectCommentModal;
