import React from "react";
import AppLayout from "@amzn/awsui-components-react/polaris/app-layout";
import BreadcrumbGroup from "@amzn/awsui-components-react/polaris/breadcrumb-group";
import MAPFundRequestDetails from "internal/programs/migration-acceleration-program/2024/fund-request/view/details/MAPFundRequestDetails";
import { getFundRequestId } from "internal/util/helper";
import NotificationsProvider from "shared/util/context/notifications/NotificationsContext";
import NotificationsFlashbar from "shared/components/common/notifications/NotificationsFlashbar";
import SplitPanelProvider from "shared/util/context/split-panel/SplitPanelContext";
import SplitPanelContainer from "shared/components/common/split-panel/SplitPanelContainer";

const FundRequestDetailsPage = () => {
  return (
    <SplitPanelProvider>
      <NotificationsProvider>
        <AppLayout
          breadcrumbs={
            <BreadcrumbGroup
              items={[
                { text: "Dashboard", href: "/dashboard" },
                {
                  text: getFundRequestId(),
                  href: `/fund-requests/${getFundRequestId()}/details`,
                },
              ]}
            />
          }
          headerSelector=".navbar"
          content={<MAPFundRequestDetails />}
          notifications={<NotificationsFlashbar />}
          splitPanel={<SplitPanelContainer />}
          navigationHide
        />
      </NotificationsProvider>
    </SplitPanelProvider>
  );
};

export default FundRequestDetailsPage;
