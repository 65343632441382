import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import { ColumnLayout, CopyToClipboard } from "@amzn/awsui-components-react";
import Container from "@amzn/awsui-components-react/polaris/container";
import Header from "@amzn/awsui-components-react/polaris/header";
import React, { ReactElement } from "react";
import ValueWithLabel from "shared/programs/migration-acceleration-program/2024/fund-request/components/fields/ValueWithLabel";
import { display } from "shared/util/common/display";
import { longLocalDateFormat } from "shared/util/common/date";
import { formatCurrency } from "shared/programs/migration-acceleration-program/2024/fund-request/api/adapters";
import { IGenericObject } from "shared/programs/migration-acceleration-program/2024/fund-request/types/CommonTypes";
import InfoLink from "shared/components/InfoLink";
import { HelpPanelContentContext } from "shared/util/context/help/HelpContext";
import useFeatureFlag from "shared/util/hooks/useFeatureFlag";
import { FEATURE_FLAG_ENUMS } from "shared/util/constants/featureFlagValues";
import ConditionalField from "shared/components/common/Conditional/ConditionalField";
import { POST_CLAIM_SUBMITTED_STAGES } from "shared/programs/migration-acceleration-program/2024/fund-request/util/claims";

const CashClaimPlan = ({
  headerTitle,
  data,
  claim,
  actions = <></>,
}: {
  headerTitle: string;
  data: IGenericObject;
  claim: IGenericObject;
  actions?: ReactElement;
}) => {
  if (!data || !claim) return <></>;
  const { setHelpPanelContent } = React.useContext(HelpPanelContentContext);
  const vmwareClaim = claim?.vmwareClaim;
  const claimStage = claim?.claimStage;
  const isVMwareMapEnabled = useFeatureFlag(
    FEATURE_FLAG_ENUMS.VMWARE_MAP_ENABLED
  );
  const localCurrency = data?.currency;

  return (
    <Container
      header={
        <Header
          variant="h2"
          info={
            <InfoLink
              onFollow={() => {
                setHelpPanelContent("wizard.review.claim");
              }}
            />
          }
          actions={actions}
        >
          {headerTitle}
        </Header>
      }
    >
      <SpaceBetween size="l">
        <ValueWithLabel label="Cash claim ID">
          <CopyToClipboard
            variant="inline"
            textToCopy={display({ data: claim, key: "claimId" }) as string}
            copySuccessText="Copied to clipboard."
            copyErrorText="Failed to copy to clipboard."
          />
        </ValueWithLabel>
        <ColumnLayout columns={2} variant="text-grid">
          <SpaceBetween size="l">
            <ValueWithLabel label="Claim due date">
              {display({
                data: claim,
                key: "claimDueDate",
                format: (value) => longLocalDateFormat(value as string),
              })}
            </ValueWithLabel>
            <ConditionalField showField={isVMwareMapEnabled && vmwareClaim}>
              <ValueWithLabel label="Number of VMs hosted on VMware in scope for migration to AWS">
                {display({
                  data: claim,
                  key: "numberOfVmwareToMigrate",
                })}
              </ValueWithLabel>
            </ConditionalField>
            <ConditionalField
              showField={
                isVMwareMapEnabled &&
                vmwareClaim &&
                POST_CLAIM_SUBMITTED_STAGES.includes(claimStage)
              }
            >
              <ValueWithLabel label="Tools used for generating migration report">
                {display({
                  data: claim,
                  key: "toolsUsedForGeneratingMigrationReport",
                  format: (value) => value.replace(/,/g, ", "),
                })}
              </ValueWithLabel>
            </ConditionalField>
          </SpaceBetween>
          <SpaceBetween size="l">
            <ValueWithLabel label={`Claim amount (${localCurrency})`}>
              {display({
                data: claim,
                key: "claimAmount",
                format: (value) => formatCurrency(value as string),
              })}
            </ValueWithLabel>
            <ConditionalField showField={isVMwareMapEnabled && vmwareClaim}>
              <ValueWithLabel label="Total number of servers in scope for migration to AWS">
                {display({
                  data: claim,
                  key: "totalNumberOfWorkloadsInUse",
                })}
              </ValueWithLabel>
            </ConditionalField>
          </SpaceBetween>
        </ColumnLayout>
      </SpaceBetween>
    </Container>
  );
};

export default CashClaimPlan;
