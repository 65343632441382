import React from "react";
import { Header } from "@amzn/awsui-components-react";
import TableActions from "./TableActions";
import PropTypes from "prop-types";

const SelfServiceTableHeader = ({ counter, selectedItems, refresh }) => {
  return (
    <React.Fragment>
      <Header
        variant="h2"
        counter={counter}
        actions={
          <TableActions selectedItems={selectedItems} refresh={refresh} />
        }
      >
        Self Service Entities
      </Header>
    </React.Fragment>
  );
};

SelfServiceTableHeader.propTypes = {
  counter: PropTypes.string,
  selectedItems: PropTypes.array,
  refresh: PropTypes.func,
};

export default SelfServiceTableHeader;
