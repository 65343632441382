import { Table, Pagination } from "@amzn/awsui-components-react";
import React, { useState } from "react";
import BulkActionsReviewHeader from "./BulkActionReviewTableHeader";
import {
  BULK_ACTION_TYPE_TO_COLUMN_DEFINITION,
  REVIEW_TABLE_SIZE,
  ariaLabels,
} from "./config";
import PropTypes from "prop-types";
import TableEmptyState from "internal/components/common/TableEmptyState";
import TableNoMatchState from "internal/components/common/TableNoMatchState";
import { useCollection } from "@amzn/awsui-collection-hooks";

const withSideEffect =
  (fn, sideEffect) =>
  (...args) => {
    sideEffect(...args);
    return fn(...args);
  };

const BulkActionReviewTable = ({ records, bulkActionType, fileName }) => {
  const [tableItems, setTableItems] = useState(records);
  const [itemsSnap, setItemsSnap] = useState(null);

  const persistChanges = () => {
    setTableItems(tableItems);
    setItemsSnap(null);
  };

  const { items, actions, collectionProps, paginationProps, allPageItems } =
    useCollection(tableItems, {
      filtering: {
        empty: <TableEmptyState resourceName="Bulk Action Submission Review" />,
        noMatch: (
          <TableNoMatchState onClearFilter={() => actions.setFiltering("")} />
        ),
      },
      pagination: { pageSize: REVIEW_TABLE_SIZE },
      // If you sort on Fund Request ID and the user changes one of the Fund Request IDs, then the items will be re-sorted and the user will lose track of where their entry was
      sorting: {},
    });

  const tablePaginationProps = {
    ...paginationProps,
    onChange: withSideEffect(paginationProps.onChange, persistChanges),
  };

  const tableCollectionProps = {
    ...collectionProps,
    onSortingChange: withSideEffect(
      collectionProps.onSortingChange,
      persistChanges
    ),
  };

  const handleSubmit = async (currentItem, column, value) => {
    await new Promise((resolve) => setTimeout(resolve, 1500));
    let fullCollection = tableItems;

    const newItem = { ...currentItem, [column.id]: value };

    if (collectionProps.sortingColumn === column) {
      actions.setSorting(null);
      fullCollection = allPageItems;
    }

    if (collectionProps.sortingColumn === column) {
      setItemsSnap(
        items.map((item) => (item === currentItem ? newItem : item))
      );
    }

    setTableItems(
      fullCollection.map((item) => (item === currentItem ? newItem : item))
    );
  };

  return (
    <Table
      {...tableCollectionProps}
      items={itemsSnap || items}
      ariaLabels={ariaLabels}
      loadingText="Loading resources"
      submitEdit={handleSubmit}
      sortingDisabled
      columnDefinitions={
        BULK_ACTION_TYPE_TO_COLUMN_DEFINITION[bulkActionType.value]
      }
      header={
        <BulkActionsReviewHeader
          itemsLength={items.length}
          bulkActionType={bulkActionType}
          records={tableItems}
          fileName={fileName}
        />
      }
      pagination={<Pagination {...tablePaginationProps} />}
    />
  );
};

BulkActionReviewTable.propTypes = {
  records: PropTypes.array,
  bulkActionType: PropTypes.object,
  fileName: PropTypes.string,
  setNotificationsItems: PropTypes.func,
};

export default BulkActionReviewTable;
