import React from "react";
import { Spinner } from "@amzn/awsui-components-react";

import "./style.css";

const Loading = () => (
  <div className="loading-spinner">
    <Spinner size="large" />
  </div>
);

export default Loading;
