import * as jsonpatch from "fast-json-patch";
import { PatchOperations, getOperation } from "./constant";
import { Action, Item, ROOT_ENTITY_NAME } from "../constant";

export const ConstructRollbackEvents = (
  entityDetails,
  entityEvents,
  currentEntityVersion,
  reason = ""
) => {
  try {
    let documentA = GetPatchedEvents(entityDetails.entityType, entityEvents);
    let documentB = GetPatchedEvents(
      entityDetails.entityType,
      entityEvents.slice(0, currentEntityVersion)
    );

    return {
      patch: {
        patches: jsonpatch.compare(documentA, documentB),
        rollback: true,
      },
      version: `${entityDetails.version}`,
      reason: reason,
    };
  } catch (e) {
    console.error(e.message);
  }
};

export const ConstructPayload = (entityDetails, action, item) => {
  try {
    return {
      patch: {
        patches: ConstructPatch(entityDetails, action, item),
        rollback: false,
      },
      version: entityDetails.version,
      reason: item.reason,
    };
  } catch (e) {
    console.error(e.message);
  }
};

export const ConstructPatch = (entityDetails, action, item) => {
  let patch = [];
  const index =
    item.index !== undefined ? item.index : entityDetails.entityListLength;
  const operation = getOperation[action];

  if (action === Action.CREATE) {
    let value = {};
    if (entityDetails.entityType === ROOT_ENTITY_NAME) {
      value = {
        name: item.name,
        description: item.value,
        permissions: item.permissions ? item.permissions : [],
      };
    } else {
      value = {
        name: item.name,
        description: item.value,
      };
    }

    patch.push({
      op: operation,
      path: `/${entityDetails.entityType}/${index}`,
      value: value,
    });
  }

  if (action === Action.EDIT) {
    if (item.hasNameChanged) {
      patch.push({
        op: operation,
        path: `/${entityDetails.entityType}/${index}/${Item.NAME}`,
        value: item.name,
      });
    }
    if (item.hasValueChanged) {
      patch.push({
        op: operation,
        path: `/${entityDetails.entityType}/${index}/${Item.DESCRIPTION}`,
        value: item.value,
      });
    }
    if (item.hasPermissionsChanged) {
      patch.push({
        op: operation,
        path: `/${entityDetails.entityType}/${index}/${Item.PERMISSIONS}`,
        value: item.permissions,
      });
    }
  }

  if (action === Action.DELETE) {
    patch.push({
      op: operation,
      path: `/${entityDetails.entityType}/${index}`,
    });
  }

  return patch;
};

export const GetPatchedEvents = (
  entityType,
  events,
  doc = {},
  validateOperation = true
) => {
  try {
    return jsonpatch.applyPatch(
      doc,
      GetPatchList(entityType, doc, events),
      validateOperation
    ).newDocument;
  } catch (e) {
    console.error(e.message);
  }
};

const GetPatchList = (entityType, doc, events) => {
  const patches = structuredClone(
    events
      .map((event) => {
        if (!event.patch || !event.patch.patches) {
          throw {
            message: `Invalid patch for entityType: ${event.entityType}, sequenceNumber: ${event.sequenceNumber}`,
          };
        }
        return event.patch.patches;
      })
      .flat()
  );

  const root_patch = [
    {
      op: PatchOperations.ADD,
      path: `/${entityType}`,
      value: [],
    },
  ];
  return Object.keys(doc).length === 0 ? [...root_patch, ...patches] : patches;
};
