export const generateLengthConstraintText = (item, lengthLimit) => {
  return `${item ? item.length : "0"}/${lengthLimit}`;
};

export const isEntityDeploymentVersionValid = (
  entityLatestVersion,
  entityCurrentVersion
) => {
  return (
    entityLatestVersion === entityCurrentVersion ||
    entityLatestVersion - entityCurrentVersion > 3
  );
};
