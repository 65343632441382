import React from "react";
import PropTypes from "prop-types";
import ExtendButton from "./ExtendButton";
import ExtendModal from "./ExtendModal";
import { ExtendContextProvider } from "./Context";

export const Extend = ({ fundRequests, setNotificationsItems, loading }) => {
  return (
    <>
      <ExtendContextProvider>
        <ExtendButton fundRequests={fundRequests} loading={loading} />
        <ExtendModal
          fundRequests={fundRequests}
          setNotificationsItems={setNotificationsItems}
        />
      </ExtendContextProvider>
    </>
  );
};

Extend.propTypes = {
  fundRequests: PropTypes.array,
  setNotificationsItems: PropTypes.func,
  loading: PropTypes.bool,
};
