import React from "react";

const INSTANCE_URL = "https://aws-crm.lightning.force.com";
const RECORD_PATH = "lightning/r/Opportunity";
const VIEW = "view";

export const generateViewURLForAWSOpportunity = (recordId) => {
  return `${INSTANCE_URL}/${RECORD_PATH}/${recordId}/${VIEW}`;
};

export const validateOpportunityId = (recordId) => {
  return recordId && recordId.startsWith("006");
};

export const generateAWSOpportunityLink = (awsOpportunityId) => {
  return (
    <a
      href={`${generateViewURLForAWSOpportunity(awsOpportunityId)}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      {awsOpportunityId}
    </a>
  );
};
