import withAppLayout from "shared/components/withAppLayout";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Header from "@amzn/awsui-components-react/polaris/header";
import templateMappingData from "external/components/FundRequest/ProgramSelect/templateMappingData";
import Container from "@amzn/awsui-components-react/polaris/container";
import Box from "@amzn/awsui-components-react/polaris/box";
import Tiles from "@amzn/awsui-components-react/polaris/tiles";
import { ColumnLayout, FormField } from "@amzn/awsui-components-react";
import {
  helperTextMap,
  selfServiceHelperTextEntityMap,
} from "shared/util/constants/helperText";
import Link from "@amzn/awsui-components-react/polaris/link";
import Select from "@amzn/awsui-components-react/polaris/select";
import {
  FUNDING_TYPE,
  PROGRAM_NAME_TYPE,
  PROGRAM_TYPE,
} from "shared/util/constants/programType";
import { MIGRATION_PHASE } from "shared/util/constants/migrationPhaseType";
import { migrationPhase as migrationPhaseTypes } from "shared/util/constants/staticOptions";
import Input from "@amzn/awsui-components-react/polaris/input";
import Button from "@amzn/awsui-components-react/polaris/button";
import { getBreadcrumbs } from "shared/util/common/getBreadcrumbs";
import { HelperPanel } from "../Help/HelperPanel";
import React, { useEffect, useState } from "react";
import { getFundingTypeOptions } from "external/components/FundRequest/ProgramSelect/util";
import { useHistory, useParams } from "react-router-dom";
import DOMPurify from "dompurify";
import { TITLE } from "shared/util/constants/title";
import {
  ERROR_TYPE,
  hasError,
  retryApiCall,
} from "shared/util/services/data/DataService";
import { getFundRequest } from "external/util/services/data/FundRequestService";
import { setErrorMessage } from "shared/util/common/helper";
import useHelpText from "shared/util/hooks/useHelpText";
import Loading from "shared/components/common/Loading";
import { convertSMPtoMAP } from "shared/util/common/convertSMPtoMAP";
import PropTypes from "prop-types";

const EditProgramSelectContainer = ({
  updateTemplateInformation,
  successfulSubmitRedirectUrl,
}) => {
  const history = useHistory();
  const { id } = useParams();
  const TILE_CLASSNAMES = [
    "program-select-tiles-1-item",
    "program-select-tiles-2-items",
  ];

  const ARR_KEY = "arr";
  const PROGRAM_KEY = "program";
  const FUNDING_TYPE_KEY = "fundingType";
  const MIGRATION_PHASE_KEY = "migrationPhase";

  const [state, setState] = useState({
    [PROGRAM_KEY]: "",
    [FUNDING_TYPE_KEY]: "",
    [MIGRATION_PHASE_KEY]: "",
  });
  const [arr, setArr] = useState("");
  const [error, setError] = useState("");

  const [isLoading, setIsLoading] = useState({
    page: true,
    submit: false,
  });
  const [notifications, setNotifications] = useState([]);

  const [fundingTypeOptions, setFundingTypeOptions] = useState([]);

  const {
    isHelperPanelOpen,
    helperPanelContent,
    setIsHelperPanelOpen,
    showHelperPanel,
  } = useHelpText();

  const [programOptions, setProgramOptions] = useState([]);

  useEffect(async () => {
    document.title = DOMPurify.sanitize(
      `${TITLE} - ${id} - Edit Program Select`
    );

    if (isTemplateEdit()) {
      history.push(`/fund-request/${id}/draft`);
      return;
    }
    await loadFundRequestInformation();
  }, []);

  const isTemplateEdit = (id) => {
    return (
      id && (id.includes("SMP") || id.includes("POC") || id.includes("PIF"))
    );
  };

  const loadFundRequestInformation = async () => {
    setIsLoading((prevState) => ({ ...prevState, page: true }));
    try {
      const response = await retryApiCall({
        callApi: getFundRequest(`${id}`),
      });
      console.log(response);

      if (hasError(response) && response.errorType === ERROR_TYPE.BANNER) {
        setErrorMessage({
          setNotificationsItems: setNotifications,
          content: response.message,
          status: response.status,
        });
        setIsLoading((prevState) => ({ ...prevState, page: false }));
        return;
      }

      const {
        fundingTemplate: {
          annualRunRate,
          program,
          fundingType,
          migrationPhase,
        },
        pifProgramName,
      } = response;

      let loadededFundingTypeOptions = [{}];

      if (pifProgramName) {
        loadededFundingTypeOptions = [
          { label: fundingType, value: fundingType, disabled: true },
        ];
        setProgramOptions([
          { label: pifProgramName, value: pifProgramName, disabled: true },
        ]);
      } else {
        loadededFundingTypeOptions = getFundingTypeOptions(
          templateMappingData[program].programs[PROGRAM_NAME_TYPE[program]]
            .typesOfFunding
        ).map((fundingType) => ({ ...fundingType, disabled: true }));
      }

      setState({
        program,
        fundingType,
        migrationPhase,
      });

      setFundingTypeOptions(loadededFundingTypeOptions);
      setArr(annualRunRate.units);
    } catch (e) {
      setErrorMessage({
        setNotificationsItems: setNotifications,
      });
      setIsLoading((prevState) => ({ ...prevState, page: false }));
      console.error(e);
    }
    setIsLoading((prevState) => ({ ...prevState, page: false }));
  };

  const submitData = async () => {
    setIsLoading((prevState) => ({ ...prevState, submit: true }));
    const data = {};

    if (
      state[MIGRATION_PHASE_KEY] === MIGRATION_PHASE.ASSES_BUSINESS_CASE &&
      arr < 250000
    ) {
      setError(
        "Annual Run Rate must be greater than $250k USD for Assess Business Case Phase"
      );
      setIsLoading((prevState) => ({ ...prevState, submit: false }));
      return;
    } else if (
      state[MIGRATION_PHASE_KEY] === MIGRATION_PHASE.MOBILIZE &&
      arr < 250000
    ) {
      setError(
        "Annual Run Rate must be greater than $250k USD for Mobilize Phase"
      );
      setIsLoading((prevState) => ({ ...prevState, submit: false }));
      return;
    } else if ((!arr || arr < 1) && state[PROGRAM_KEY] !== PROGRAM_TYPE.PIF) {
      setError("Annual Run Rate must be greater than 0 USD.");
      setIsLoading((prevState) => ({ ...prevState, submit: false }));
      return;
    }
    data[ARR_KEY] = {
      units: parseInt(arr),
      nanos: 0,
      currency: "USD",
    };

    try {
      const response = await retryApiCall({
        callApi: updateTemplateInformation(data),
      });
      if (hasError(response) && response.errorType === ERROR_TYPE.BANNER) {
        setErrorMessage({
          setNotificationsItems: setNotifications,
          content: response.message,
          status: response.status,
        });
        setIsLoading((prevState) => ({ ...prevState, submit: false }));
        return;
      }
    } catch (err) {
      setErrorMessage({
        setNotificationsItems: setNotifications,
      });
      setIsLoading((prevState) => ({ ...prevState, submit: false }));
      console.error(err);
      return;
    }

    history.push(successfulSubmitRedirectUrl);
  };

  const ProgramSelect = () => {
    return (
      <React.Fragment>
        <Header variant="h1">
          Select {convertSMPtoMAP(PROGRAM_NAME_TYPE[state[PROGRAM_KEY]])}
        </Header>
        <Container header={<Header variant="h2">Programs</Header>}>
          <SpaceBetween size="s">
            <Box>Select program</Box>
            <Tiles
              className={TILE_CLASSNAMES[programOptions.length - 1]}
              value={PROGRAM_NAME_TYPE[state[PROGRAM_KEY]]}
              items={programOptions}
            />
          </SpaceBetween>
        </Container>
      </React.Fragment>
    );
  };

  return withAppLayout({
    component: isLoading["page"] ? (
      <Container>
        <Loading />
      </Container>
    ) : (
      <SpaceBetween size="l">
        <ProgramSelect />
        <Container header={<Header variant="h2">Types of Funding</Header>}>
          <SpaceBetween size="s">
            <Box>Select type of funding</Box>

            <Tiles
              className={TILE_CLASSNAMES[fundingTypeOptions.length - 1]}
              value={state[FUNDING_TYPE_KEY]}
              items={fundingTypeOptions}
            />
          </SpaceBetween>
        </Container>
        <Container
          header={
            <Header variant="h2">
              {state[PROGRAM_KEY] === PROGRAM_TYPE.SMP
                ? "Migration Eligibility"
                : "Annual Recurring Revenue"}
            </Header>
          }
        >
          <SpaceBetween size="s">
            <ColumnLayout columns={2}>
              {state[PROGRAM_KEY] === PROGRAM_TYPE.SMP && (
                <FormField
                  label="Migration Phase"
                  info={
                    (
                      helperPanelContent.selfServiceHelperTextFlag
                        ? selfServiceHelperTextEntityMap["migrationPhase"]
                        : helperTextMap["migrationPhase"]
                    ) ? (
                      <Link
                        variant="Info"
                        onFollow={() =>
                          showHelperPanel({
                            title: "Migration Phase",
                            keys: ["migrationPhase"],
                            open: true,
                          })
                        }
                      >
                        Info
                      </Link>
                    ) : (
                      ""
                    )
                  }
                >
                  <Select
                    id="migrationPhase"
                    selectedOption={{
                      label: state[MIGRATION_PHASE_KEY],
                      value: state[MIGRATION_PHASE_KEY],
                    }}
                    className="step-details-field"
                    placeholder="Choose an option"
                    filteringType="auto"
                    disabled
                    options={
                      state[FUNDING_TYPE_KEY]
                        ? state[FUNDING_TYPE_KEY] === FUNDING_TYPE.CREDIT
                          ? [
                              {
                                label: MIGRATION_PHASE.MIGRATE_MODERNIZE,
                                value: MIGRATION_PHASE.MIGRATE_MODERNIZE,
                              },
                            ]
                          : migrationPhaseTypes
                        : []
                    }
                  />
                </FormField>
              )}
              <FormField
                label={`Annual Recurring Revenue (ARR) Once In Production (USD) ${
                  state[PROGRAM_KEY] === PROGRAM_TYPE.PIF ? "- Optional" : ""
                }`}
                errorText={error}
                info={
                  (
                    helperPanelContent.selfServiceHelperTextFlag
                      ? selfServiceHelperTextEntityMap["annualRunRate"]
                      : helperTextMap["annualRunRate"]
                  ) ? (
                    <Link
                      variant="Info"
                      onFollow={() =>
                        showHelperPanel({
                          title: "Annual Run Rate",
                          keys: ["annualRunRate"],
                          open: true,
                        })
                      }
                    >
                      Info
                    </Link>
                  ) : (
                    ""
                  )
                }
              >
                <Input
                  id="annualRunRate"
                  value={
                    arr
                      ? !isNaN(arr)
                        ? parseFloat(arr).toLocaleString("en-US")
                        : arr
                      : ""
                  }
                  placeholder="Please enter annual run rate for the fund request."
                  onChange={({ detail }) => {
                    const value = detail.value.replace(/,/g, "");
                    const arrPattern = /^\d+$/;
                    if (arrPattern.test(value)) {
                      setError("");
                      setArr(value);
                    } else {
                      setError("Must be a number value");
                      setArr(value);
                    }
                  }}
                />
              </FormField>
            </ColumnLayout>
          </SpaceBetween>
        </Container>
        <Header
          variant="h1"
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button variant="link" onClick={() => history.goBack()}>
                Cancel
              </Button>
              <Button
                variant="primary"
                loading={isLoading["submit"]}
                onClick={async () => {
                  await submitData();
                }}
              >
                Save & Next
              </Button>
            </SpaceBetween>
          }
        />
      </SpaceBetween>
    ),
    breadcrumbs: getBreadcrumbs({ text: "Apply for the program" }),
    notificationsItems: notifications,
    toolsContent: <HelperPanel content={helperPanelContent} />,
    isToolsOpen: isHelperPanelOpen,
    onToolsChange: ({ detail }) => {
      setIsHelperPanelOpen(detail.open);
    },
  })();
};

EditProgramSelectContainer.propTypes = {
  updateTemplateInformation: PropTypes.func,
  successfulSubmitRedirectUrl: PropTypes.string,
};

export default EditProgramSelectContainer;
