import React, { useState } from "react";

import Header from "@amzn/awsui-components-react/polaris/header";
import Table from "@amzn/awsui-components-react/polaris/table";

import InfoLink from "shared/components/InfoLink";
import {
  IArtifact,
  IReadOnlyComponentProps,
} from "../../../../../types/SchemaTypes";
import { useCollection } from "@amzn/awsui-collection-hooks";
import TableEmptyState from "external/components/common/TableEmptyState";
import TableNoMatchState from "external/components/common/TableNoMatchState";
import { FILE_STATUS } from "shared/util/constants/fileStatus";
import { TableProps } from "@amzn/awsui-components-react/polaris/table/interfaces";
import { CollectionPreferencesProps } from "@amzn/awsui-components-react/polaris/collection-preferences/interfaces";
import {
  filter,
  getColumnsAsVisible,
  getCount,
  getTableCollectionPreferences,
} from "external/programs/migration-acceleration-program/2024/fund-request/components/shared/table/util";
import {
  CollectionPreferences,
  Pagination,
} from "@amzn/awsui-components-react";
import TextFilter from "@amzn/awsui-components-react/polaris/text-filter";
import { IGenericObject } from "shared/programs/migration-acceleration-program/2024/fund-request/types/CommonTypes";
import { HelpPanelContentContext } from "shared/util/context/help/HelpContext";

interface IReadOnlyArtifactsProps extends IReadOnlyComponentProps {
  isLoading: boolean;
  artifacts: IGenericObject[];
  selectedItems?: IGenericObject[];
  setSelectedItems?: React.Dispatch<React.SetStateAction<IGenericObject[]>>;
}

const TABLE_RESOURCE_NAME = "artifacts";

const ReadOnlyArtifacts = (props: IReadOnlyArtifactsProps) => {
  const [preferences, setPreferences] =
    useState<CollectionPreferencesProps.Preferences>({
      pageSize: 10,
      visibleContent: getColumnsAsVisible({ columnDefinitions }),
    });
  const { setHelpPanelContent } = React.useContext(HelpPanelContentContext);
  const {
    isLoading,
    artifacts,
    selectedItems,
    actions: headerActions,
    setSelectedItems,
  } = props;

  const { items, actions, collectionProps, filterProps, paginationProps } =
    useCollection(artifacts || [], {
      filtering: {
        empty: <TableEmptyState resourceName={TABLE_RESOURCE_NAME} />,
        noMatch: (
          <TableNoMatchState onClearFilter={() => actions.setFiltering("")} />
        ),
        filteringFunction: (item, filteringText) => {
          return filter({ item, filteringText, columnDefinitions });
        },
      },
      sorting: {},
      selection: {},
      pagination: { pageSize: preferences.pageSize },
    });

  console.debug({ filterProps });

  const isNotFile = (item: IGenericObject) => {
    const scanStatus =
      item.scanStatus && item.scanStatus.props && item.scanStatus.props.status;
    return (
      !item.fileType || item.fileType === "-" || isFileNotClean(scanStatus)
    );
  };

  const isFileNotClean = (status: string) => {
    if (!status) return false;
    return status !== FILE_STATUS.ScannedClean;
  };

  let multiSelectProps = {};

  if (Array.isArray(selectedItems)) {
    multiSelectProps = {
      onSelectionChange: ({
        detail,
      }: {
        detail: TableProps.SelectionChangeDetail<IGenericObject>;
      }) => setSelectedItems && setSelectedItems(detail.selectedItems),
      selectedItems: selectedItems,
      selectionType: "multi",
      isItemDisabled: isNotFile,
    };
  }

  const count = getCount({ collection: artifacts });

  return (
    <Table
      {...collectionProps}
      {...multiSelectProps}
      columnDefinitions={columnDefinitions}
      items={items}
      loading={isLoading}
      loadingText={`Loading ${TABLE_RESOURCE_NAME}`}
      visibleColumns={preferences.visibleContent}
      header={
        <Header
          counter={count}
          info={
            <InfoLink
              onFollow={() => {
                setHelpPanelContent("wizard.review.supportingDocumentation");
              }}
            />
          }
          actions={headerActions}
        >
          Supporting documentation
        </Header>
      }
      pagination={<Pagination {...paginationProps} />}
      preferences={
        <CollectionPreferences
          {...getTableCollectionPreferences({
            name: TABLE_RESOURCE_NAME,
            columnDefinitions,
            editable: ({ id }: { id?: string }) => id !== "artifact",
          })}
          preferences={preferences}
          onConfirm={({ detail }) => setPreferences(detail)}
        />
      }
      filter={
        <TextFilter
          {...filterProps}
          filteringPlaceholder={`Find ${TABLE_RESOURCE_NAME}`}
        />
      }
    />
  );
};

const columnDefinitions = [
  {
    id: "artifact",
    header: "Artifact",
    cell: (item: IArtifact) => item.artifact,
    isRowHeader: true,
    maxWidth: "250px",
  },
  {
    id: "scanStatus",
    header: "Malware scan status",
    cell: (item: IArtifact) => item.scanStatus,
    sortingField: "scanStatus",
    isRowHeader: true,
  },
  {
    id: "artifactType",
    header: "Artifact type",
    cell: (item: IArtifact) => item.artifactType,
    sortingField: "artifactType",
    isRowHeader: true,
  },
  {
    id: "fileType",
    header: "File type",
    cell: (item: IArtifact) => item.fileType,
    sortingField: "fileType",
    isRowHeader: true,
  },
  {
    id: "associatedStep",
    header: "Associated step",
    cell: (item: IArtifact) => item.associatedStep,
    sortingField: "associatedStep",
    isRowHeader: true,
  },
  {
    id: "uploadDate",
    header: "Upload date",
    cell: (item: IArtifact) => item.uploadDate,
    sortingField: "uploadDate",
    isRowHeader: true,
  },
];

export default ReadOnlyArtifacts;
