import React, { ReactElement } from "react";

const ConditionalField = ({
  showField,
  children,
}: {
  showField: boolean;
  children: ReactElement;
}) => {
  let content = <></>;

  if (showField) {
    content = <>{children}</>;
  }

  return content;
};

export default ConditionalField;
