import { COMPONENT_TYPE } from "shared/util/constants/componentType";
import DatePicker from "@amzn/awsui-components-react/polaris/date-picker";
import Textarea from "@amzn/awsui-components-react/polaris/textarea";
import Select from "@amzn/awsui-components-react/polaris/select";
import Multiselect from "@amzn/awsui-components-react/polaris/multiselect";
import Checkbox from "@amzn/awsui-components-react/polaris/checkbox";
import { useParams } from "react-router-dom";
import FileUpload from "../common/FileUpload/FileUpload";
import Input from "@amzn/awsui-components-react/polaris/input";
import { FormField } from "@amzn/awsui-components-react";
import React from "react";
import PropTypes from "prop-types";
import Link from "@amzn/awsui-components-react/polaris/link";
import {
  helperTextMap,
  selfServiceHelperTextEntityMap,
} from "shared/util/constants/helperText";
import { getFundRequestId } from "external/util/common/helper";
import AssigneeSelection from "internal/components/Review/AssigneeSelection";
import { isFloat } from "shared/util/common/helper";
import useHelpText from "shared/util/hooks/useHelpText";

export const InputFactory = ({
  componentType,
  id,
  name,
  value,
  disabled,
  dataInput,
  label,
  options,
  placeholder,
  description,
  optional,
  error,
  type,
  invalid,
  updateErrors,
  validators,
  setNotificationItems,
  statusType,
  showHelperPanel,
  fileuploadProp,
}) => {
  let component;
  const { helperPanelContent } = useHelpText();

  switch (componentType) {
    case COMPONENT_TYPE.DATEPICKER:
      component = (
        <DatePicker
          id={id}
          value={value || ""}
          className="step-details-field"
          disabled={disabled}
          placeholder={!disabled ? "YYYY/MM/DD" : ""}
          onChange={({ detail }) => {
            updateErrors(id, "");
            dataInput({ [id]: detail.value });
          }}
        />
      );
      break;
    case COMPONENT_TYPE.TEXTAREA:
      component = (
        <Textarea
          id={id}
          value={value || ""}
          className="step-details-textarea"
          disabled={disabled}
          placeholder={placeholder || `Enter ${label}`}
          onChange={({ detail }) => {
            updateErrors(id, "");
            dataInput({ [id]: detail.value });
          }}
        />
      );
      break;
    case COMPONENT_TYPE.SELECT: {
      const [selection] = options.filter((option) => option.value === value);
      value = selection ? selection : "";
      component = (
        <Select
          id={id}
          selectedOption={value || []}
          className="step-details-field"
          disabled={disabled}
          placeholder={placeholder || `Choose an option`}
          filteringType="auto"
          statusType={statusType}
          onChange={({ detail }) => {
            updateErrors(id, "");
            dataInput({ [id]: detail.selectedOption.value });
          }}
          options={Array.isArray(options) ? options : []}
        />
      );
      break;
    }
    case COMPONENT_TYPE.MULTI_SELECT:
      component = (
        <Multiselect
          id={id}
          selectedOptions={value || []}
          className="step-details-field"
          disabled={disabled}
          placeholder={placeholder || "Choose options"}
          statusType={statusType}
          onChange={({ detail }) => {
            updateErrors(id, "");
            dataInput({ [id]: detail.selectedOptions });
          }}
          options={options || []}
        />
      );
      break;
    case COMPONENT_TYPE.CHECKBOX:
      component = (
        <Checkbox
          id={id}
          checked={value || false}
          disabled={disabled}
          className="step-details-field"
          onChange={({ detail }) => {
            updateErrors(id, "");
            dataInput({ [id]: detail.checked });
          }}
        />
      );
      break;
    case COMPONENT_TYPE.PSA_SELECT:
      component = (
        <AssigneeSelection
          assigneeType="Person"
          setAssignee={(detail) => {
            dataInput({ [id]: detail });
          }}
          setError={(detail) => {
            updateErrors(id, detail);
          }}
        />
      );
      break;
    case COMPONENT_TYPE.FILE_UPLOAD: {
      const { cashClaimId } = useParams();
      const fundRequestId = getFundRequestId();

      component = (
        <FileUpload
          setNotificationsItems={setNotificationItems}
          fundRequestId={fundRequestId}
          fundClaimId={cashClaimId ? cashClaimId : null}
          getUploadFileUrl={fileuploadProp.getUploadFileUrl}
          uploadFile={fileuploadProp.uploadFile}
          getDownloadUrl={fileuploadProp.getDownloadUrl}
          deleteFile={fileuploadProp.deleteFile}
          getListOfAttachments={fileuploadProp.getListOfAttachments}
          attachmentEventFactory={fileuploadProp.attachmentEventFactory}
        />
      );
      break;
    }
    default:
      component = (
        <Input
          id={id}
          value={
            value && !isNaN(value) && isFloat(value)
              ? parseFloat(value).toFixed(2)
              : value
          }
          className="step-details-field"
          disabled={disabled}
          placeholder={placeholder || !disabled ? `Enter ${label}` : ""}
          invalid={invalid}
          onChange={({ detail }) => {
            let inputValue = detail.value;
            if (type === "number") {
              inputValue = detail.value && parseInt(detail.value);
            }
            if (type === "decimal") {
              inputValue = detail.value && parseFloat(detail.value).toFixed(2);
            }

            let validate = {};
            let validated = true;
            if (type !== "money") {
              validate = validators.getSchema(name);
              validated = validate(inputValue);
            }

            if (type === "money" && !disabled) {
              const value = detail.value.replace(/,/g, "");
              const arrPattern = /^\d+$/;
              if (
                value &&
                (!arrPattern.test(value) || parseFloat(value) > 999999999999)
              ) {
                return;
              }

              inputValue = value
                ? !isNaN(value)
                  ? parseFloat(value).toLocaleString("en-US")
                  : ""
                : "";
            }

            if (validated) {
              updateErrors(id, "");
              dataInput({ [id]: inputValue });
            } else {
              updateErrors(id, validate.errors[0].message);
              dataInput({ [id]: inputValue });
            }
          }}
        />
      );
  }

  return (
    <FormField
      label={
        <span>
          {`${label}`}
          {optional && <i>- Optional</i>}
        </span>
      }
      info={
        (
          helperPanelContent.selfServiceHelperTextFlag
            ? selfServiceHelperTextEntityMap[name]
            : helperTextMap[name]
        ) ? (
          <Link
            variant="Info"
            onFollow={() =>
              showHelperPanel({ title: label, keys: [name], open: true })
            }
          >
            Info
          </Link>
        ) : (
          ""
        )
      }
      description={description}
      errorText={error}
    >
      {component}
    </FormField>
  );
};

InputFactory.propTypes = {
  componentType: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
    PropTypes.number,
    PropTypes.bool,
  ]),
  disabled: PropTypes.bool,
  dataInput: PropTypes.func,
  fieldToIdMap: PropTypes.object,
  label: PropTypes.string,
  options: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  placeholder: PropTypes.string,
  description: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.array,
  ]),
  optional: PropTypes.bool,
  error: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  invalid: PropTypes.bool,
  updateErrors: PropTypes.func,
  validators: PropTypes.any,
  setNotificationItems: PropTypes.any,
  statusType: PropTypes.string,
  showHelperPanel: PropTypes.func,
  data: PropTypes.object,

  fileuploadProp: PropTypes.shape({
    getUploadFileUrl: PropTypes.func,
    uploadFile: PropTypes.func,
    getDownloadUrl: PropTypes.func,
    deleteFile: PropTypes.func,
    getListOfAttachments: PropTypes.func,
    attachmentEventFactory: PropTypes.func,
  }),
};

export default React.memo(InputFactory);
