import React from "react";

import ReadOnlySectionActions from "../../../../components/shared/sections/read-only/ReadOnlySectionActions";
import ReadOnlyOpportunityDetails from "../../../../components/shared/sections/read-only/OpportunityDetails/ReadOnlyOpportunityDetails";
import { IDetailsProps } from "shared/programs/migration-acceleration-program/2024/fund-request/types/SchemaTypes";

const OpportunityDetailsContainer = ({ ...props }: IDetailsProps) => (
  <ReadOnlyOpportunityDetails
    {...props}
    actions={
      <ReadOnlySectionActions
        isVisible={
          props.data &&
          props.data["stage"] === "partner" &&
          props.data["status"] === "active"
        }
        sectionNumber={0}
      />
    }
  />
);

export default OpportunityDetailsContainer;
