export const initialData = {
  isModalVisible: false,
  isLoading: false,
  dateRange: undefined,
  reason: "",
  reasonError: "",
  dateRangeError: "",
  fundRequests: {},
};

export const Enums = {
  SET_MODAL: "SET_MODAL",
  SET_LOADING: "SET_LOADING",
  SET_REASON: "SET_REASON",
  SET_REASON_ERROR: "SET_REASON_ERROR",
  SET_DATE_RANGE: "SET_DATE_RANGE",
  SET_DATE_RANGE_ERROR: "SET_DATE_RANGE_ERROR",
  ADD_FUND_REQUEST: "ADD_FUND_REQUEST",
};
