import React, { useState } from "react";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import DetailsActionWithModal from "shared/programs/migration-acceleration-program/2024/fund-request/components/modal/DetailsActionWithModal";
import { FormField } from "@amzn/awsui-components-react";
import Input from "@amzn/awsui-components-react/polaris/input";
import { changeFundRequestOwner } from "external/util/services/data/FundRequestService";
import { hasError } from "shared/util/services/data/DataService";
import { handlePartnerCommentErrorResponse } from "external/programs/migration-acceleration-program/2024/fund-request/components/details/FundRequestDetailsContainer/header/actions/PartnerCommentDetailsActionWithModal";
import { FormError, Result, ResultType, Success } from "shared/util/api/util";

const EMAIL_VALIDATION_ERROR_MESSAGE = "Invalid email address";

const ChangeOwner = ({ isLoading }: { isLoading: boolean }) => {
  const [isModalLoading, setIsModalLoading] = useState<boolean>(false);
  const [partnerEmail, setPartnerEmail] = useState<string>("");
  const [error, setError] = useState<string>("");

  const onSubmit = async (): Promise<Result<ResultType>> => {
    setIsModalLoading(true);
    let result: Result<ResultType> = new Result<Success>({});

    // This is just the basic Mime RFC address format.
    // Email validation is error prone.
    // We should definitely just check that this email
    // exists as an authorized partner user email.

    const isValidEmail = /^.+@.+$/;
    if (!isValidEmail.test(partnerEmail)) {
      setError(EMAIL_VALIDATION_ERROR_MESSAGE);
      return new Result<FormError>({
        success: false,
        payload: new FormError(EMAIL_VALIDATION_ERROR_MESSAGE),
      });
    }

    try {
      const response = await changeFundRequestOwner({
        userEmail: partnerEmail,
      });

      if (hasError(response)) {
        result = handlePartnerCommentErrorResponse({ response });
        setError("An error has occurred, try again.");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsModalLoading(false);
    }

    return result;
  };

  const onCancel = () => {
    setPartnerEmail("");
    setError("");
  };

  return (
    <>
      <DetailsActionWithModal
        isLoading={isModalLoading || isLoading}
        submit={onSubmit}
        cancel={onCancel}
        labels={{
          actionButton: "Change owner",
          title: "Change fund request owner",
          submitButton: "Change fund request owner",
          successMessage: `The fund request's owner has successfully been updated to ${partnerEmail}.`,
        }}
      >
        <SpaceBetween size="l">
          <FormField
            label="New owner's email address"
            description="Provide a valid email address of a partner that is registered within the AWS Partner Network."
            errorText={error}
          >
            <Input
              value={partnerEmail}
              placeholder="Enter email address"
              inputMode="email"
              onChange={({ detail }) => {
                setPartnerEmail(detail.value.trim());
                setError("");
              }}
              spellcheck
              autoFocus
            />
          </FormField>
        </SpaceBetween>
      </DetailsActionWithModal>
    </>
  );
};

export default ChangeOwner;
