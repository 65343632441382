import {
  b64EncodeUnicode,
  deleteData,
  getData,
  hasError,
  postData,
  putData,
} from "shared/util/services/data/DataService";
import Config from "config";

const API_V1_END_POINT = `${Config.FUNDING_ENDPOINT}`;

export const getUserPersistedSettingsPerVersion = async (version) => {
  let settings = [];
  let nextPageToken = "";
  while (nextPageToken != null) {
    const response = await getData({
      endpoint: `${API_V1_END_POINT}/${version}/settings`,
      queryParam: [`nextPageToken=${nextPageToken}`],
    });

    if (response && hasError(response)) {
      throw new Error(response);
    }
    settings = [...settings, ...response.data];
    nextPageToken = response.nextPageToken;
  }
  return settings;
};

export const getUserPersistedSettingsPerComponent = async (
  version,
  componentName
) => {
  let settings = [];
  let nextPageToken = "";
  while (nextPageToken != null) {
    const response = await getData({
      endpoint: `${API_V1_END_POINT}/${version}/settings/${componentName}`,
      queryParam: [`nextPageToken=${nextPageToken}`],
    });

    if (response && hasError(response)) {
      throw new Error(response);
    }
    settings = [...settings, ...response.settings];
    nextPageToken = response.nextPageToken;
  }
  return settings;
};

export const getUserPersistedSettingsPerComponentAttribute = (
  version,
  componentName,
  componentAttribute
) => {
  return getData({
    endpoint: `${API_V1_END_POINT}/${version}/settings/${componentName}/${componentAttribute}`,
  });
};

export const saveUserSettings = (
  version,
  componentName,
  componentAttribute,
  entityType,
  settings
) => {
  try {
    const payload = {
      componentName: componentName,
      componentAttribute: componentAttribute,
      entityType: entityType,
      settings: settings,
    };

    return postData({
      endpoint: `${API_V1_END_POINT}/${version}/settings`,
      body: b64EncodeUnicode(
        JSON.stringify({
          ...payload,
        })
      ),
    });
  } catch (e) {
    console.error(e);
    throw new Error(e);
  }
};

export const updateUserSettings = (
  version,
  componentName,
  componentAttribute,
  entityType,
  settings
) => {
  try {
    const payload = {
      entityType: entityType,
      settings: settings,
    };

    return putData({
      endpoint: `${API_V1_END_POINT}/${version}/settings/${componentName}/${componentAttribute}`,
      body: b64EncodeUnicode(
        JSON.stringify({
          ...payload,
        })
      ),
    });
  } catch (e) {
    console.error(e);
    throw new Error(e);
  }
};

export const deleteUserSettings = (
  version,
  componentName,
  componentAttribute
) => {
  try {
    return deleteData({
      endpoint: `${API_V1_END_POINT}/${version}/settings/${componentName}/${componentAttribute}`,
    });
  } catch (e) {
    console.error(e);
    throw new Error(e);
  }
};
