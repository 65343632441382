import React, { useState } from "react";
import { Alert, Button } from "@amzn/awsui-components-react";
import { Box, SpaceBetween } from "@amzn/awsui-components-react";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import propTypes from "prop-types";
import {
  ERROR_TYPE,
  hasError,
  retryApiCall,
} from "../../../../../shared/util/services/data/DataService";
import { changeStatus } from "../../../../util/services/data/dataFactory";
import { setErrorMessage } from "../../../../../shared/util/common/helper";
import { groupBy } from "shared/util/common/util";
import { useHistory } from "react-router-dom";

const DeleteModal = ({
  fundRequesIds,
  showDeleteModal,
  setShowDeleteModal,
  setNotificationsItems,
  setSelectedItems,
}) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const onDelete = async () => {
    await Promise.all(
      fundRequesIds.map((id) =>
        retryApiCall({
          callApi: changeStatus("Delete")(id),
        })
      )
    ).then((responses) => {
      const { errors, results } = groupBy(responses, (r) =>
        hasError(r) && r.errorType === ERROR_TYPE.BANNER ? "errors" : "results"
      );
      if (errors && errors.length !== 0) {
        setErrorMessage({
          setNotificationsItems,
          content: errors.map((err) => err.message),
        });
      }
      setIsLoading(false);
      setSelectedItems([]);
      setShowDeleteModal(false);
      history.push("/dashboard", {
        origin: "Delete",
        deletedFundRequests: new Set(results.map((r) => r.fundRequestId)),
      });
    });
  };
  return (
    <Modal
      visible={showDeleteModal}
      onDismiss={() => setShowDeleteModal(false)}
      header={"Delete Fund Request"}
      closeAriaLabel="Close dialog"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={() => setShowDeleteModal(false)}>
              Cancel
            </Button>
            <Button
              loading={isLoading}
              variant="primary"
              onClick={async () => {
                setIsLoading(true);
                return await onDelete();
              }}
            >
              Delete
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <SpaceBetween size="m">
        <Box variant="span">
          Delete{" "}
          <Box variant="span" fontWeight="bold">
            {fundRequesIds.length > 1
              ? `${fundRequesIds.length} Fund Requests`
              : `Fund Request ${fundRequesIds}`}
          </Box>{" "}
          permanently? This action cannot be undone.
        </Box>

        <Alert statusIconAriaLabel="Info">
          You are about to delete the selected fund request and cannot be
          recovered. Are you sure you want to proceed?
        </Alert>
      </SpaceBetween>
    </Modal>
  );
};

DeleteModal.propTypes = {
  fundRequesIds: propTypes.array,
  showDeleteModal: propTypes.bool,
  setShowDeleteModal: propTypes.func,
  setNotificationsItems: propTypes.func,
  setSelectedItems: propTypes.func,
};

export default DeleteModal;
