import { DefaultReviewHeader } from "./DefaultReviewHeader";
import { FundingReviewHeader } from "./FundingReviewHeader";
import { PAGE_TO_STAGE, PAGES } from "../../../util/constants/pages";
import { isActive } from "../../../../shared/util/constants/fundRequestStatusType";

export const ReviewHeaderFactory = ({ page, stage, status }) => {
  let header = DefaultReviewHeader;

  if (PAGE_TO_STAGE[page] === stage && isActive(status)) {
    if (
      page === PAGES.BUSINESS_APPROVAL ||
      page === PAGES.FINANCE_APPROVAL ||
      page === PAGES.PRE_APPROVAL ||
      page === PAGES.CASH_CLAIM_APPROVAL ||
      page === PAGES.AWS_APPROVAL ||
      page === PAGES.TECH_APPROVAL
    ) {
      header = FundingReviewHeader;
    }
  }

  return header;
};
