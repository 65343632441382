import React from "react";
import { ColumnLayout, ExpandableSection } from "@amzn/awsui-components-react";
import { v4 as uuid } from "uuid";
import PropTypes from "prop-types";
import {
  getSectionConstant,
  SECTION_CONSTANT_TYPE,
} from "../../../../shared/util/constants/section";
import { uiToApiMap } from "../../../../shared/util/adapter/toUi/adapter";
import { filterFields, schema } from "../../../config/schema";
import { DefaultSectionHeader } from "./SectionHeader/DefaultSectionHeader";
import { createValueField } from "../../../../shared/components/Review/CreateValueField";
import { ValueFactory } from "./ValueFactory";

export const DefaultReviewSectionContainer = ({
  fundingData,
  step,
  template,
}) => {
  const fields = [];

  for (let sectionTitle of template[step].sections) {
    const sectionName = getSectionConstant({
      type: SECTION_CONSTANT_TYPE.NAME,
      value: sectionTitle,
    });

    const apiKey = uiToApiMap[sectionName];
    const filteredFields = filterFields({
      section: schema[sectionTitle],
      program: fundingData.program,
    });

    for (let field of filteredFields) {
      fields.push(
        createValueField({
          field,
          value: ValueFactory({
            apiKey,
            sectionTitle,
            name: field.name,
            data: fundingData,
            field,
          }),
        })
      );
    }
  }

  return (
    <React.Fragment key={uuid()}>
      <ExpandableSection
        variant="container"
        defaultExpanded={true}
        header={
          <DefaultSectionHeader
            title={template[step].title}
            name={template[step].name}
            template={template}
            stage={fundingData.stage}
            status={fundingData.status}
            program={fundingData.program}
            isAutoApproved={
              fundingData["approval"] &&
              fundingData["approval"]["eligibleForPsaAutoApproval"]
            }
          />
        }
      >
        <ColumnLayout columns={4} variant="text-grid">
          {fields}
        </ColumnLayout>
      </ExpandableSection>
      <br />
    </React.Fragment>
  );
};

DefaultReviewSectionContainer.propTypes = {
  fundingData: PropTypes.object,
  step: PropTypes.array,
  template: PropTypes.object,
};
