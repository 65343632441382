export enum ErrorDisplayType {
  FORM = "FORM",
  FIELD = "FIELD",
}

export interface IErrorDisplay<T> {
  type: ErrorDisplayType;
  reason: T;
  fieldKey?: string;
}

export class ValidationResult<T> {
  isValid: boolean;
  error?: IErrorDisplay<T>;

  constructor({
    isValid = true,
    error,
  }: {
    isValid?: boolean;
    error?: IErrorDisplay<T>;
  }) {
    this.isValid = isValid;
    this.error = error;
  }
}
