import React, { useContext } from "react";
import PropTypes from "prop-types";
import Button from "@amzn/awsui-components-react/polaris/button";
import { ROLES } from "internal/util/constants/roles";
import { getInternalRoles } from "internal/util/services/auth/AuthService";
import { STAGE } from "shared/util/constants/fundRequestStatusType";
import { allEqual } from "shared/util/common/helper";
import { Enums } from "./Constants";
import { ExtendContext } from "./Context";

const ExtendButton = ({ fundRequests, loading }) => {
  const { extendDispatch } = useContext(ExtendContext);

  const disableExtends = (fundRequests) => {
    return !(
      getInternalRoles().includes(ROLES.FUNDING_OPS) &&
      fundRequests.length !== 0 &&
      allEqual(fundRequests.map((fr) => fr.stage)) &&
      fundRequests.find(
        (fr) =>
          fr.stage === STAGE.REVIEW ||
          fr.stage === STAGE.TECH_APPROVAL ||
          fr.stage === STAGE.BUSINESS_APPROVAL ||
          fr.stage === STAGE.FINANCE_APPROVAL ||
          fr.stage === STAGE.PRE_APPROVAL ||
          fr.stage === STAGE.CASH_CLAIM
      )
    );
  };
  return (
    <Button
      loading={loading}
      disabled={disableExtends(fundRequests)}
      onClick={() => extendDispatch({ type: Enums.SET_MODAL, payload: true })}
    >
      Extend
    </Button>
  );
};

ExtendButton.propTypes = {
  fundRequests: PropTypes.array,
  loading: PropTypes.bool,
};

export default ExtendButton;
