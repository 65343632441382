import { IGenericObject } from "shared/programs/migration-acceleration-program/2024/fund-request/types/CommonTypes";
import { hasError, retryApiCall } from "shared/util/services/data/DataService";
import { handleApiError } from "shared/util/api/util";
import { getFundRequestAttachments } from "external/util/services/data/FundRequestService";

interface IGetAssociatedStep {
  claimId?: string;
  claimPriorities?: IGenericObject;
}

export const getAssociatedStep = ({
  claimId,
  claimPriorities,
}: IGetAssociatedStep) => {
  if (!claimId) return "Fund request";
  if (!claimPriorities) return "Fund claim";

  const priority = findPriority({ claimPriorities, claimId });

  if (!priority) return "Fund claim";

  return `Fund claim ${Number(priority) + 1}`;
};

export const findPriority = ({
  claimPriorities,
  claimId,
}: {
  claimPriorities: {
    [key: string]: string;
  };
  claimId: string;
}) => {
  if (!claimPriorities || !claimId) return;

  const priority = Object.keys(claimPriorities).find(
    (key: string) => claimPriorities[key] === claimId
  );

  return priority;
};

export const retrieveFilesAfterUpdate = async (data?: IGenericObject) => {
  let retrievedFiles: IGenericObject[] = [];
  let nextPageToken = "";
  const claimPriorities = data?.fundingDetails?.claimPriorities;

  while (nextPageToken != null) {
    try {
      // @ts-expect-error Still in javascript, so the implementation details don't matter
      const response = await retryApiCall({
        callApi: getFundRequestAttachments(nextPageToken),
      });

      if (hasError(response)) {
        return handleApiError(response.errorType)(response);
      }

      const newFiles = response.items.map((file: IGenericObject) => {
        const associatedStep = getAssociatedStep({
          claimId: file.fundClaimId,
          claimPriorities,
        });

        return {
          documentId: file.fileId,
          name: file.fileName,
          fileType: file.fileType,
          associatedStep: associatedStep,
          status: file.fileStatus,
          uploadedDate: file.lastUpdatedOn?.timestamp,
          size: file.fileDetails?.fileSize,
          partnerId: file.partnerId,
          userId: file.userId,
        };
      });

      retrievedFiles = retrievedFiles.concat(newFiles);
      nextPageToken = response.nextPageToken;
    } catch (err) {
      console.error(err);
      break;
    }
  }

  const artifactsData = data && data["artifacts"];
  const urlsData = artifactsData && artifactsData["pricingCalculatorLinks"];
  let urls = [];

  if (urlsData) {
    urls = urlsData.map((url: string) => {
      return {
        name: url,
        fileType: "AWS Pricing Calculator",
        associatedStep: "Fund Request",
        status: "-",
        uploadedDate: "-",
      };
    });
  }
  const newArtifacts = retrievedFiles.concat(urls);
  return newArtifacts;
};
