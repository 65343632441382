import React from "react";
import { filterFields, schema } from "../../../../config/schema";
import {
  SECTION,
  SECTION_NAME,
} from "../../../../../shared/util/constants/section";
import { ColumnLayout, Container, Tabs } from "@amzn/awsui-components-react";
import PropTypes from "prop-types";
import { uiToApiMap } from "../../../../../shared/util/adapter/toUi/adapter";
import { ValueFactory } from "./ValueFactory";
import Button from "@amzn/awsui-components-react/polaris/button";
import { useHistory, useParams } from "react-router-dom";
import Header from "@amzn/awsui-components-react/polaris/header";
import { createValueField } from "../../../../../shared/components/Review/CreateValueField";
import { canEdit } from "../util";

export const MigrationWorkloadsReviewSectionContainer = ({
  fundingData,
  index,
}) => {
  const history = useHistory();
  const { id } = useParams();
  const migrationsApiKey = uiToApiMap[SECTION_NAME.MIGRATION_DETAILS];
  const workloadsApiKey = uiToApiMap[SECTION_NAME.MIGRATION_WORKLOADS];

  const migrationDetailsFilteredFields = filterFields({
    section: schema[SECTION.MIGRATION_WORKLOADS],
    program: fundingData.program,
  });

  const workloadData =
    fundingData &&
    fundingData[migrationsApiKey] &&
    fundingData[migrationsApiKey][workloadsApiKey];

  const workloads = [];
  workloadData &&
    workloadData.length > 0 &&
    workloadData.map((workload, index) => {
      const workloadFields = [];
      migrationDetailsFilteredFields.map((field) => {
        workloadFields.push(
          createValueField({
            field,
            value: ValueFactory({
              apiKey: workloadsApiKey,
              sectionTitle: SECTION.MIGRATION_WORKLOADS,
              name: field.name,
              data: workload,
              field,
            }),
          })
        );
      });

      const Workload = (
        <Container
          header={
            <Header
              actions={
                canEdit({
                  status: fundingData["status"],
                  stage: fundingData["stage"],
                }) ? (
                  <Button
                    onClick={() =>
                      history.push(
                        `/fund-request/${id}/${SECTION_NAME.MIGRATION_WORKLOADS}/edit`
                      )
                    }
                  >
                    Edit
                  </Button>
                ) : (
                  ""
                )
              }
            >
              Migration {index + 1}
            </Header>
          }
        >
          <ColumnLayout columns={4} variant="text-grid">
            {workloadFields}
          </ColumnLayout>
        </Container>
      );

      workloads.push({
        id: `migration-${index}`,
        label: `Migration ${index + 1}`,
        content: Workload,
      });
    });

  return workloads.length > 0 ? (
    <React.Fragment key={index}>
      <Tabs tabs={workloads} />
      <br />
    </React.Fragment>
  ) : (
    ""
  );
};

MigrationWorkloadsReviewSectionContainer.propTypes = {
  fundingData: PropTypes.object,
  index: PropTypes.number,
};
