import React from "react";
import PropTypes from "prop-types";
import Box from "@amzn/awsui-components-react/polaris/box";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Button from "@amzn/awsui-components-react/polaris/button";

const TableNoMatchState = ({ onClearFilter, resourceName }) => {
  if (resourceName === "opportunities") {
    return (
      <Box margin={{ vertical: "xs" }} textAlign="center" color="inherit">
        <SpaceBetween size="xxs">
          <div>
            Unable to find the opportunity ID you are looking for? Refer to the
            help section on the right side info panel.
          </div>
          <Button onClick={onClearFilter}>Clear filter</Button>
        </SpaceBetween>
      </Box>
    );
  } else {
    return (
      <Box margin={{ vertical: "xs" }} textAlign="center" color="inherit">
        <SpaceBetween size="xxs">
          <div>
            <b>No matches</b>
            <Box variant="p" color="inherit">
              We cant find a match.
            </Box>
          </div>
          <Button onClick={onClearFilter}>Clear filter</Button>
        </SpaceBetween>
      </Box>
    );
  }
};

TableNoMatchState.propTypes = {
  onClearFilter: PropTypes.func,
  resourceName: PropTypes.string,
};

export default TableNoMatchState;
