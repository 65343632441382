import { searchableColumns } from "./tableConfig";

export const filterItem = ({ item, filteringText }) => {
  return searchableColumns
    .map((key) => item[key])
    .some(
      (value) =>
        typeof value === "string" &&
        value.toLowerCase().indexOf(filteringText.toLowerCase()) > -1
    );
};

export const formatReasonCodes = (reasonCodes) => {
  return reasonCodes ? reasonCodes.join("; ") : "-";
};
