import React from "react";
import { FieldType } from "shared/programs/migration-acceleration-program/2024/fund-request/components/fields/FieldComponent";
import { IFieldSchema } from "external/programs/migration-acceleration-program/2024/fund-request/types/SchemaTypes";
import { ValidationType } from "shared/programs/migration-acceleration-program/2024/fund-request/components/fields/validations";
import { STAGE } from "shared/util/constants/fundRequestStatusType";
import { SelectOptionType } from "shared/programs/migration-acceleration-program/2024/fund-request/hooks/useSelectOptions";
import Link from "@amzn/awsui-components-react/polaris/link";
import { MAP2024_MIGRATION_PHASE } from "external/programs/migration-acceleration-program/2024/fund-request/components/create/eligibility/util";

export const ProjectDetailsEditSchema: IFieldSchema[] = [
  {
    label: "Fund request ID",
    keyName: "fundRequestId",
    placeholder: "Fund request ID",
    fieldType: FieldType.Input,
    editableStages: [],
  },
  {
    label: "Current approval stage",
    keyName: "stage",
    placeholder: "Current approval stage",
    fieldType: FieldType.Input,
    editableStages: [],
  },
  {
    label: "Fund request status",
    keyName: "status",
    placeholder: "Fund request status",
    fieldType: FieldType.Input,
    editableStages: [],
  },
  {
    label: "AWS account ID",
    keyName: "awsAccountId",
    placeholder: "AWS account ID",
    fieldType: FieldType.Input,
    editableStages: [
      STAGE.BUSINESS_APPROVAL,
      STAGE.FINANCE_APPROVAL,
      STAGE.CASH_CLAIM,
    ],
    validations: [ValidationType.AwsAccountId],
  },
  {
    label: "Delivery start date",
    keyName: "plannedStartDate",
    placeholder: "YYYY/MM/DD",
    fieldType: FieldType.DatePicker,
    editableStages: [STAGE.BUSINESS_APPROVAL],
  },
  {
    label: "Delivery end date",
    keyName: "plannedEndDate",
    placeholder: "YYYY/MM/DD",
    fieldType: FieldType.DatePicker,
    editableStages: [STAGE.BUSINESS_APPROVAL],
  },
  {
    label: "Fund request owner",
    keyName: "fundRequestOwner",
    placeholder: "Fund request owner",
    fieldType: FieldType.Input,
    editableStages: [],
  },
  {
    label: "Project country",
    keyName: "executedCountry",
    placeholder: "Select project country",
    selectOptionType: SelectOptionType.Countries,
    fieldType: FieldType.Select,
    editableStages: [STAGE.BUSINESS_APPROVAL],
  },
  {
    label: "Project state",
    keyName: "executedState",
    placeholder: "Select project state",
    selectOptionType: SelectOptionType.States,
    fieldType: FieldType.MultiSelect,
    editableStages: [STAGE.BUSINESS_APPROVAL],
  },
  {
    label: "Greenfield incentive eligible",
    keyName: "greenfieldIncentiveEligible",
    placeholder: "-",
    fieldType: FieldType.Checkbox,
    editableStages: [STAGE.BUSINESS_APPROVAL],
    conditional: ({ data }) =>
      data?.migrationPhase === MAP2024_MIGRATION_PHASE.MOBILIZE,
  },
  {
    label: "Program engagement ID",
    keyName: "programEngagementId",
    placeholder: "Enter program engagement ID",
    description: (
      <>
        Please input the last 10 alphanumeric characters of the Migration
        Program Engagement ID.{" "}
        <Link
          href="https://amazon.awsapps.com/workdocs-preview/index.html#/document/38ac9ddadbb6d024760b5810f025a67af75668db44413bcdc6b3ad2b423e0909"
          external
          fontSize="inherit"
        >
          Learn
        </Link>{" "}
        how to retrieve the ID using the five digit legacy ID.
      </>
    ),
    fieldType: FieldType.Input,
    editableStages: [STAGE.BUSINESS_APPROVAL],
  },
  {
    label: "Project name",
    keyName: "activityName",
    placeholder: "Project or activity name",
    fieldType: FieldType.Input,
    constraintText: (value: string = "") => (
      <>
        Project name must be 1 to 300 characters. Character count:{" "}
        {value.length}
        /300
      </>
    ),
    validations: [ValidationType.MaxLength300Chars],
    editableStages: [STAGE.BUSINESS_APPROVAL],
  },
  {
    label: "Business description",
    keyName: "description",
    placeholder: "List out the reasons for why you're requesting funding",
    fieldType: FieldType.Textarea,
    description: "List out the reasons for why you're requesting funding.",
    constraintText: (value: string = "") => (
      <>
        Max of 5000 characters including special characters (e.g. space, comma,
        etc.) Character count: {value.length}/5000
      </>
    ),
    validations: [ValidationType.MaxLength5000Chars],
    editableStages: [STAGE.BUSINESS_APPROVAL],
  },
];

export const vmwareProjectDetailsEditSchema: IFieldSchema[] =
  ProjectDetailsEditSchema.concat([
    {
      label: "Number of VMs hosted on VMware in scope for migration to AWS",
      keyName: "numberOfVmwareToMigrate",
      fieldType: FieldType.Input,
      validations: [ValidationType.ValidNumber],
      editableStages: [STAGE.BUSINESS_APPROVAL],
      conditional: ({ data }) => data?.vmwareAcknowledgement === true,
    },
    {
      label: "Total number of servers in scope for migration to AWS",
      keyName: "totalNumberOfWorkloadsInUse",
      fieldType: FieldType.Input,
      validations: [ValidationType.ValidNumber],
      editableStages: [STAGE.BUSINESS_APPROVAL],
      conditional: ({ data }) => data?.vmwareAcknowledgement === true,
    },
  ]);
