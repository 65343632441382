import React from "react";
import { FieldError, FormError, Result } from "shared/util/api/util";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import { Button } from "@amzn/awsui-components-react/polaris";
import ConditionalField from "shared/components/common/Conditional/ConditionalField";

interface ArtifactsDetailsActionsProps {
  isLoading: boolean;
  isEnabled: boolean;
  areFilesMutable: boolean;
  download: () => void;
  remove: () => void;
  refresh: () => Promise<Result<FieldError> | Result<FormError> | undefined>;
  addAttachment: () => void;
}

const ArtifactsDetailsActions = ({
  isLoading,
  download,
  isEnabled,
  areFilesMutable,
  remove,
  refresh,
  addAttachment,
}: ArtifactsDetailsActionsProps) => {
  return (
    <SpaceBetween size="l" direction="horizontal">
      <Button
        iconName="download"
        disabled={!isEnabled}
        loading={isLoading}
        onClick={download}
      >
        Download
      </Button>
      <ConditionalField showField={areFilesMutable}>
        <Button disabled={!isEnabled} loading={isLoading} onClick={remove}>
          Remove
        </Button>
      </ConditionalField>
      <Button iconName={"refresh"} loading={isLoading} onClick={refresh}>
        Refresh scan status
      </Button>
      <ConditionalField showField={areFilesMutable}>
        <Button loading={isLoading} onClick={addAttachment}>
          Add attachment
        </Button>
      </ConditionalField>
    </SpaceBetween>
  );
};

export default ArtifactsDetailsActions;
