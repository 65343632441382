import i18next from "external/i18n";
import { MAP_LITE_THRESHOLD } from "shared/programs/migration-acceleration-program/2024/fund-request/utils/constants";

export const MAP2024_MIGRATION_PHASE = {
  ASSESS: "Assess",
  MOBILIZE: "Mobilize",
};

export const showModernizationCheck = ({
  annualRunRate,
  migrationPhase,
}: {
  annualRunRate?: number;
  migrationPhase?: string;
}): boolean => {
  if (!annualRunRate) return false;

  return (
    Number(annualRunRate) >= MAP_LITE_THRESHOLD &&
    migrationPhase === MAP2024_MIGRATION_PHASE.MOBILIZE
  );
};

export const migrationPhaseOptionsForMAP = [
  {
    label: MAP2024_MIGRATION_PHASE.ASSESS,
    description: i18next.t(
      "eligibility.container.content.migrationEligibility.fields.migrationPhase.options.assess.description"
    ),
    value: MAP2024_MIGRATION_PHASE.ASSESS,
  },
  {
    label: MAP2024_MIGRATION_PHASE.MOBILIZE,
    description: i18next.t(
      "eligibility.container.content.migrationEligibility.fields.migrationPhase.options.mobilize.description"
    ),
    value: MAP2024_MIGRATION_PHASE.MOBILIZE,
  },
];
