import React from "react";
import Container from "@amzn/awsui-components-react/polaris/container";
import Header from "@amzn/awsui-components-react/polaris/header";
import { ColumnLayout, CopyToClipboard } from "@amzn/awsui-components-react";
import { IReadOnlyComponentProps } from "external/programs/migration-acceleration-program/2024/fund-request/types/SchemaTypes";
import ValueWithLabel from "shared/programs/migration-acceleration-program/2024/fund-request/components/fields/ValueWithLabel";
import { longLocalDateFormat } from "shared/util/common/date";
import ConditionalField from "shared/components/common/Conditional/ConditionalField";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import FundRequestStatus from "external/programs/migration-acceleration-program/2024/fund-request/components/shared/common/status/FundRequestStatus";
import { capitalizeFirstLetter } from "external/programs/migration-acceleration-program/2024/fund-request/utils/string";
import { display } from "shared/util/common/display";
import useFeatureFlag from "shared/util/hooks/useFeatureFlag";
import { FEATURE_FLAG_ENUMS } from "shared/util/constants/featureFlagValues";
import { MAP2024_MIGRATION_PHASE } from "../../../create/eligibility/util";

const ProjectDetailsDetailContainer = ({
  data,
  actions = <></>,
}: IReadOnlyComponentProps) => {
  const { fundRequestId, stage, status, owner, migrationPhase } = data;
  const projectDetails = data["projectDetails"];
  const vmwareAcknowledgement = projectDetails?.vmwareAcknowledgement;
  const isVMwareMapEnabled = useFeatureFlag(
    FEATURE_FLAG_ENUMS.VMWARE_MAP_ENABLED
  );

  const executedState = (
    display({
      data: projectDetails,
      key: "executedState",
    }) as string
  )
    .split(",")
    .join(", ");

  return (
    <Container
      header={
        <Header variant="h2" actions={actions}>
          Project details
        </Header>
      }
    >
      <SpaceBetween size="xs">
        <ColumnLayout columns={3} variant="text-grid">
          <SpaceBetween size="l">
            <ValueWithLabel label="Fund request ID">
              <CopyToClipboard
                textToCopy={fundRequestId || "-"}
                copySuccessText="Copied to clipboard."
                copyErrorText="Failed to copy to clipboard."
                variant="inline"
              />
            </ValueWithLabel>
            <ValueWithLabel label="AWS account ID">
              {display({
                data: projectDetails,
                key: "awsAccountId",
              })}
            </ValueWithLabel>
            <ValueWithLabel label="Owner">{owner}</ValueWithLabel>
            <ValueWithLabel label="Project country">
              {display({ data: projectDetails, key: "executedCountry" })}
            </ValueWithLabel>
            <ConditionalField
              showField={
                isVMwareMapEnabled &&
                vmwareAcknowledgement &&
                migrationPhase === MAP2024_MIGRATION_PHASE.MOBILIZE
              }
            >
              <ValueWithLabel label="Total number of servers in scope for migration to AWS">
                {display({
                  data: projectDetails,
                  key: "totalNumberOfWorkloadsInUse",
                })}
              </ValueWithLabel>
            </ConditionalField>
          </SpaceBetween>
          <SpaceBetween size="l">
            <ValueWithLabel label="Current approval stage">
              {capitalizeFirstLetter(stage)}
            </ValueWithLabel>
            <ValueWithLabel label="Project start date">
              {
                display({
                  data: projectDetails,
                  key: "plannedStartDate",
                  format: (value) => longLocalDateFormat(value),
                }) as string
              }
            </ValueWithLabel>
            <ValueWithLabel label="Extended project start date">
              {
                display({
                  data: projectDetails,
                  key: "extendedStartDate",
                  format: (value) => longLocalDateFormat(value),
                }) as string
              }
            </ValueWithLabel>
            <ConditionalField showField={!!executedState}>
              <ValueWithLabel label="Project state">
                {executedState}
              </ValueWithLabel>
            </ConditionalField>
          </SpaceBetween>
          <SpaceBetween size="l">
            <ValueWithLabel label="Status">
              <FundRequestStatus status={status} />
            </ValueWithLabel>
            <ValueWithLabel label="Project end date">
              {
                display({
                  data: projectDetails,
                  key: "plannedEndDate",
                  format: (value) => longLocalDateFormat(value),
                }) as string
              }
            </ValueWithLabel>
            <ValueWithLabel label="Extended project end date">
              {
                display({
                  data: projectDetails,
                  key: "extendedEndDate",
                  format: (value) => longLocalDateFormat(value),
                }) as string
              }
            </ValueWithLabel>
            <ValueWithLabel label="Reason for extension">
              {
                display({
                  data: projectDetails,
                  key: "extensionReason",
                }) as string
              }
            </ValueWithLabel>
            <ConditionalField
              showField={
                isVMwareMapEnabled &&
                vmwareAcknowledgement &&
                migrationPhase === MAP2024_MIGRATION_PHASE.MOBILIZE
              }
            >
              <ValueWithLabel label="Number of VMs hosted on VMware in scope for migration to AWS">
                {display({
                  data: projectDetails,
                  key: "numberOfVmwareToMigrate",
                })}
              </ValueWithLabel>
            </ConditionalField>
          </SpaceBetween>
        </ColumnLayout>
        <ValueWithLabel label="Project name">
          {display({ data: projectDetails, key: "activityName" })}
        </ValueWithLabel>
        <ValueWithLabel label="Business description">
          {display({ data: projectDetails, key: "description" })}
        </ValueWithLabel>
      </SpaceBetween>
    </Container>
  );
};

export default ProjectDetailsDetailContainer;
