import {
  numberComparator,
  getYearFromTimeStampString,
} from "../../../../../../shared/util/common/helper";

export const columnDefinitions = [
  {
    id: "customerCompanyName",
    header: "Customer Company Name",
    cell: (e) => e.customerCompanyName || "-",
    sortingField: "customerCompanyName",
  },
  {
    id: "userFriendlyId",
    header: "Opportunity Id",
    cell: (e) => e.userFriendlyId || "-",
    sortingField: "userFriendlyId",
  },
  {
    id: "opportunityOwnerName",
    header: "Opportunity Owner Name",
    cell: (e) => e.opportunityOwnerName || "-",
    sortingField: "opportunityOwnerName",
  },
  {
    id: "opportunityOwnership",
    header: "Opportunity Ownership",
    cell: (e) => e.opportunityOwnership || "-",
    sortingField: "opportunityOwnership",
  },
  {
    id: "targetCloseDate",
    header: "Target Closed Date",
    cell: (e) => e.targetCloseDate || "-",
    sortingField: "targetCloseDate",
  },
  {
    id: "Created Year",
    header: "Created Year",
    cell: (e) => getYearFromTimeStampString(e.createdDate) || "-",
    sortingField: "createdDate",
    sortingComparator: (e1, e2) =>
      numberComparator(e1.createdDate, e2.createdDate),
  },
  {
    id: "projectDescription",
    header: "Project Description",
    cell: (e) => e.projectDescription.slice(0, 200) || "-",
    sortingField: "projectDescription",
  },
  {
    id: "status",
    header: "Status",
    cell: (e) => e.status || "-",
    sortingField: "status",
  },
  {
    id: "aceValidationStage",
    header: "ACE Validation Stage",
    cell: (e) => e.aceValidationStage || "-",
    sortingField: "aceValidationStage",
  },
  {
    id: "partnerSuccessManager",
    header: "Partner Success Manager",
    cell: (e) => e.partnerSuccessManager || "-",
    sortingField: "partnerSuccessManager",
  },
  {
    id: "partnerDevelopmentManager",
    header: "Partner Development Manager",
    cell: (e) => e.partnerDevelopmentManager || "-",
    sortingField: "partnerDeveloperManager",
  },
  {
    id: "wwpsPartnerDeveloperManager",
    header: "WWPS Partner Developer Manager",
    cell: (e) => e.wwpsPartnerDevelopmentManager || "-",
    sortingField: "wwpsPartnerDeveloperManager",
  },
  {
    id: "customerBizUnit",
    header: "Customer Biz Unit",
    cell: (e) => e.customerBizUnit || "-",
    sortingField: "customerBizUnit",
  },
];

export const searchableColumns = [
  "customerCompanyName",
  "userFriendlyId",
  "opportunityOwnerName",
  "opportunityOwnership",
  "targetCloseDate",
  "projectDescription",
  "status",
  "aceValidationStage",
  "partnerSuccessManager",
  "partnerDevelopmentManager",
  "customerBizUnit",
];

export const defaultStatus = {
  label: "Any Status",
  value: "Any Status",
};

export const defaultStage = {
  label: "Any Stage",
  value: "Any Stage",
};

export const defaultBizUnit = {
  label: "Any Sector",
  value: "Any Sector",
};

export const defaultCreatedYear = {
  label: "Created Year",
  value: "Created Year",
};
