import React from "react";
import ConditionalField from "shared/components/common/Conditional/ConditionalField";
import Container from "@amzn/awsui-components-react/polaris/container";
import { CheckboxProps } from "@amzn/awsui-components-react";
import Checkbox from "@amzn/awsui-components-react/polaris/checkbox";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Header from "@amzn/awsui-components-react/polaris/header";
import { IGenericObject } from "shared/programs/migration-acceleration-program/2024/fund-request/types/CommonTypes";
import { useTranslation } from "react-i18next";

export const PublicSector = ({
  data,
  onChange,
}: {
  data: IGenericObject;
  onChange: ({
    key,
    value,
  }: {
    key: string;
    value: string | undefined;
  }) => void;
}) => {
  const { t } = useTranslation();

  const opportunity: IGenericObject = data && data["opportunity"];
  const isWwps =
    opportunity &&
    (opportunity["customerBizUnit"] === "PS" ||
      opportunity["customerType"] === "PS");

  const onCheck = ({
    detail,
    key,
  }: {
    detail: CheckboxProps.ChangeDetail;
    key: string;
  }) => {
    onChange({
      key,
      value: detail.checked as unknown as string,
    });
  };

  const ConsentDescription = () => (
    <>
      <p>
        {t(
          "wizard.section.projectDetails.content.publicSectorDetails.fields.wwpsConsent.description.paragraph1"
        )}
      </p>
      <p>
        {t(
          "wizard.section.projectDetails.content.publicSectorDetails.fields.wwpsConsent.description.paragraph2"
        )}
      </p>
      <p>
        {t(
          "wizard.section.projectDetails.content.publicSectorDetails.fields.wwpsConsent.description.paragraph3"
        )}
      </p>
      <p>
        {t(
          "wizard.section.projectDetails.content.publicSectorDetails.fields.wwpsConsent.description.paragraph4"
        )}
      </p>
    </>
  );

  return (
    <ConditionalField showField={isWwps}>
      <Container
        header={
          <Header
            variant="h2"
            description={t(
              "wizard.section.projectDetails.content.publicSectorDetails.header.description"
            )}
          >
            {t(
              "wizard.section.projectDetails.content.publicSectorDetails.header.title"
            )}
          </Header>
        }
      >
        <SpaceBetween size="l">
          <Checkbox
            onChange={({ detail }) => {
              onCheck({ detail, key: "customerRfx" });
            }}
            checked={data["customerRfx"] as unknown as boolean}
          >
            {t(
              "wizard.section.projectDetails.content.publicSectorDetails.fields.customerRfx.label"
            )}{" "}
            - <i>optional</i>
          </Checkbox>
          <Checkbox
            onChange={({ detail }) => {
              onCheck({ detail, key: "wwpsConsent" });
            }}
            checked={data["wwpsConsent"] as unknown as boolean}
          >
            {t(
              "wizard.section.projectDetails.content.publicSectorDetails.fields.wwpsConsent.label"
            )}
          </Checkbox>
        </SpaceBetween>
        <ConsentDescription />
      </Container>
    </ConditionalField>
  );
};
