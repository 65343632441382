import { Container, SpaceBetween } from "@amzn/awsui-components-react";
import PropTypes from "prop-types";
import React from "react";

const Section = ({ children, header, sectionIndex }) => (
  <Container key={sectionIndex} header={header}>
    <div className="step-details">
      <SpaceBetween size="l">{children}</SpaceBetween>
    </div>
    <br />
  </Container>
);

Section.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  header: PropTypes.element,
  sectionIndex: PropTypes.number,
};
export default Section;
