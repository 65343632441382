export const capitalizeFirstLetter = (value?: string): string => {
  if (!value) return "-";
  return value.toLowerCase().charAt(0).toUpperCase() + value.slice(1);
};

export const sentenceCase = (value?: string): string => {
  if (!value) return "-";
  return (
    value.toLowerCase().charAt(0).toUpperCase() + value.toLowerCase().slice(1)
  );
};
