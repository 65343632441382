import React, { useContext, useEffect, useState } from "react";
import { IGenericObject } from "shared/programs/migration-acceleration-program/2024/fund-request/types/CommonTypes";
import Container from "@amzn/awsui-components-react/polaris/container";
import { SpaceBetween } from "@amzn/awsui-components-react/polaris";
import ValueWithLabel from "shared/programs/migration-acceleration-program/2024/fund-request/components/fields/ValueWithLabel";
import Header from "@amzn/awsui-components-react/polaris/header";
import StatusIndicator, {
  StatusIndicatorProps,
} from "@amzn/awsui-components-react/polaris/status-indicator";
import { getApprovalAutomationResult } from "internal/util/services/aiba/AIBAService";
import { NotificationsContext } from "shared/util/context/notifications/NotificationsContext";
import { hasError } from "shared/util/services/data/DataService";
import { GENERIC_ERROR_MESSAGE } from "external/programs/migration-acceleration-program/2024/fund-request/components/create/wizard/Steps/Artifacts/util";
import { FEATURE_FLAG_ENUMS } from "shared/util/constants/featureFlagValues";
import useFeatureFlag from "shared/util/hooks/useFeatureFlag";

interface StatusMapItem {
  type: "success" | "error" | "warning" | "pending";
  children?: string;
}

interface StatusMap {
  [key: string]: StatusMapItem;
}

const statusMap: StatusMap = {
  Reject: {
    type: "error",
    children: "Reject",
  },
  Approve: {
    type: "success",
    children: "Approve",
  },
  "Needs more information": {
    type: "warning",
    children: "Needs more information",
  },
  Pending: {
    type: "pending",
    children: "Pending",
  },
  UNEXPECTED_RESPONSE_FORMAT: {
    type: "error",
    children: "Reject",
  },
};

const ApprovalAutomationResult = () => {
  const [response, setResponse] = useState<IGenericObject | null>(null);
  const { addNotification } = useContext(NotificationsContext);
  const fetchResult = async () => {
    try {
      const result = await getApprovalAutomationResult();
      if (hasError(result)) {
        addNotification({
          content: GENERIC_ERROR_MESSAGE,
          type: "error",
        });
        return;
      }
      if (result !== undefined) {
        setResponse(result);
      }
    } catch (e) {
      addNotification({
        content: GENERIC_ERROR_MESSAGE,
        type: "error",
      });
    }
  };

  useEffect(() => {
    fetchResult();
  }, []);

  const StatusIndicatorMap = (status: string): StatusIndicatorProps.Type => {
    switch (status) {
      case "Fail":
        return "error";
      case "Pass":
        return "success";
      case "More Info Needed":
        return "warning";
      default:
        return "pending";
    }
  };

  const FundRequestQualitySummary = () => {
    if (!response) return null;
    const businessCommentValue = response["businessComment"];
    return (
      <div>
        {Array.isArray(businessCommentValue) ? (
          <ul style={{ listStyleType: "none", padding: 0 }}>
            {businessCommentValue.map(
              (check: { Status: string; Reason: string }, index: number) => (
                <li
                  key={index}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <span
                    style={{
                      display: "inline-flex",
                      alignItems: "center",
                      gap: "0.5rem",
                    }}
                  >
                    <StatusIndicator type={StatusIndicatorMap(check.Status)} />
                    {check.Reason}
                  </span>
                </li>
              )
            )}
          </ul>
        ) : (
          <p>{businessCommentValue}</p>
        )}
      </div>
    );
  };

  const getStatusProps = (): StatusIndicatorProps => {
    let statusProps: StatusIndicatorProps = statusMap["Pending"];
    if (response) {
      const businessRejectionCodeValue = response["businessRejectionCode"];
      statusProps =
        typeof businessRejectionCodeValue === "string"
          ? statusMap[businessRejectionCodeValue]
          : statusMap["Pending"];
    }
    return statusProps;
  };

  const aibaFrontendFeatureFlag = useFeatureFlag(
    FEATURE_FLAG_ENUMS.AIBA_FRONTEND_ENABLED
  );
  if (!aibaFrontendFeatureFlag) {
    return null; // Do not render the component if feature flag is false
  }
  if (response != null && response["businessRejectionCode"] == 'UNEXPECTED_RESPONSE_FORMAT'
      && response["businessComment"] == "Suggestion is not ready yet.") {
    return null;
  }

  return (
    <Container
      header={<Header variant="h2">Fund request quality summary</Header>}
    >
      <SpaceBetween direction="vertical" size="l">
        <ValueWithLabel label="Recommended action">
          <StatusIndicator {...getStatusProps()} />
        </ValueWithLabel>
        <ValueWithLabel label="Reason(s)">
          {response ? FundRequestQualitySummary() : "Pending"}
        </ValueWithLabel>
      </SpaceBetween>
    </Container>
  );
};

export default ApprovalAutomationResult;
