import React, { createContext, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import {
  customerAlsoConsidering,
  locationSlashStateActivityExecuted,
  mdfActivityType as externalMdfActivityType,
  mdfCreditActivityType as externalMdfCreditActivityType,
  migrationPhase,
  pifWorkloads,
  projectType,
  sandboxDesignWinActivity,
  specializedWorkloads as externalSpecializedWorkloads,
} from "../../../shared/util/constants/staticOptions";
import {
  loadCountries,
  loadCurrencies,
} from "../services/data/FundRequestService";
import { mapLiteCheck } from "../../../shared/util/constants/smpMapLite";
import { isFeatureEnabled } from "../../../shared/util/services/features/FeatureFlagsService";
import { FEATURE_FLAG_ENUMS } from "../../../shared/util/constants/featureFlagValues";
import { getSelfServiceOptions } from "../../../shared/util/common/selfServiceUtil";

const OptionsContext = createContext();

const useOptionsContext = () => useContext(OptionsContext);

const loadStatic = (specializedWorkloads) => ({
  sandboxDesignWinActivity,
  locationSlashStateActivityExecuted,
  projectType,
  customerAlsoConsidering,
  migrationPhase,
  specializedWorkloads,
  pifWorkloads,
});

const OptionsProvider = ({ children, data }) => {
  const [options, setOptions] = React.useState({ isLoading: true });

  useEffect(() => {
    const featureFlag = async () => {
      const expandSpecializedWorkloadsFlag = await isFeatureEnabled(
        FEATURE_FLAG_ENUMS.EXPAND_SPECIALIZED_WORKLOADS_ENABLED
      );
      const selfServiceMdfActivityTypeFlag = await isFeatureEnabled(
        FEATURE_FLAG_ENUMS.SELF_SERVICE_FUND_REQUEST_MDF_ACTIVITY_TYPE
      );
      const countries = await loadCountries();
      const currencies = await loadCurrencies();
      const mdfActivityType = selfServiceMdfActivityTypeFlag
        ? await getSelfServiceOptions("FundRequestMdfCashActivityType")
        : externalMdfActivityType.filter(
            (mdfActivity) => !mdfActivity.disabled
          );
      const mdfCreditActivityType = selfServiceMdfActivityTypeFlag
        ? await getSelfServiceOptions("FundRequestMdfCreditActivityType")
        : externalMdfCreditActivityType.filter(
            (mdfCredit) => !mdfCredit.disabled
          );
      const staticOptions = loadStatic(
        setSpecializedWorkloads(data, expandSpecializedWorkloadsFlag)
      );

      if (!ignore) {
        setOptions({
          isLoading: false,
          countries,
          currencies,
          mdfActivityType,
          mdfCreditActivityType,
          ...staticOptions,
        });
      }
    };

    let ignore = false;
    featureFlag();
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <OptionsContext.Provider value={[options, setOptions]}>
      {children}
    </OptionsContext.Provider>
  );
};

const setSpecializedWorkloads = (data, expandSpecializedWorkloadsFlag) => {
  let specializedWorkloadsforMapLite = externalSpecializedWorkloads.filter(
    (workload) => !workload.disabled
  );
  const specializedWorkloads = externalSpecializedWorkloads.filter(
    (workload) => !workload.validOnlyForMapLite && !workload.disabled
  );
  specializedWorkloadsforMapLite = expandSpecializedWorkloadsFlag
    ? specializedWorkloadsforMapLite
    : specializedWorkloads;

  return mapLiteCheck(data)
    ? specializedWorkloadsforMapLite
    : specializedWorkloads;
};

OptionsProvider.propTypes = {
  children: PropTypes.element.isRequired,
  defaultOptions: PropTypes.object,
  data: PropTypes.object,
};

export { useOptionsContext, OptionsProvider };
