import React, { useEffect } from "react";
import {
  Container,
  ContentLayout,
  Header,
  SpaceBetween,
} from "@amzn/awsui-components-react";
import { fetchCashClaim } from "internal/programs/migration-acceleration-program/2024/fund-request/utils/util";
import InputSectionFieldsFactory from "shared/programs/migration-acceleration-program/2024/fund-request/components/fields/input/InputSectionFieldsFactory";
import SystemErrorAlert from "shared/programs/migration-acceleration-program/2024/fund-request/components/common/SystemErrorAlert";
import { ClaimInvoiceEditSchema } from "internal/programs/migration-acceleration-program/2024/fund-request/components/edit/ClaimInvoiceEditContainer/schema";
import { IEditContainerProps } from "internal/programs/migration-acceleration-program/2024/fund-request/types/SchemaType";
import { getClaimId, getFundRequestId } from "internal/util/helper";

const ClaimInvoiceEditContainer = ({
  title,
  adapt,
  data,
  errors,
  onError,
  onChange,
  setData,
  formError,
}: IEditContainerProps) => {
  useEffect(() => {
    const id = getFundRequestId();
    const claimId = getClaimId();
    fetchCashClaim({ id, adapt, setData, claimId });
  }, []);

  return (
    <ContentLayout>
      <SpaceBetween size="l">
        <Container header={<Header variant="h2">{title}</Header>}>
          <InputSectionFieldsFactory
            data={data}
            errors={errors}
            onChange={onChange}
            onError={onError}
            schema={ClaimInvoiceEditSchema}
          />
        </Container>
        <SystemErrorAlert isVisible={!!formError} error={formError} />
      </SpaceBetween>
    </ContentLayout>
  );
};

export default ClaimInvoiceEditContainer;
