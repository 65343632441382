import React from "react";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Grid from "@amzn/awsui-components-react/polaris/grid";
import Icon from "@amzn/awsui-components-react/polaris/icon";
import Box from "@amzn/awsui-components-react/polaris/box";
import PropTypes from "prop-types";
import { v4 as uuid } from "uuid";
import Button from "@amzn/awsui-components-react/polaris/button";
import {
  formatFileSize,
  setErrorMessage,
  getISOTime,
} from "../../util/common/helper";
import { hasError } from "../../util/services/data/DataService";
import { FILE_STATUS } from "../../util/constants/fileStatus";

const FilesList = ({
  files,
  removeFile,
  setNotificationsItems,
  getDownloadUrl,
}) => {
  const downLoadFile = async (fileId) => {
    try {
      let response = await getDownloadUrl(fileId);

      if (hasError(response)) {
        setErrorMessage({
          setNotificationsItems,
          content: response.message,
          status: response.status,
        });
        return;
      }

      window.open(response.url);
    } catch (err) {
      console.error(err);
    }
  };

  const getStatus = (status) => {
    if (status) {
      return FILE_STATUS[status];
    }

    return FILE_STATUS.PendingScan;
  };

  return (
    <SpaceBetween size="xxl" key={uuid()}>
      <Grid
        key={uuid()}
        gridDefinition={[{ colspan: 4 }, { colspan: 4 }, { colspan: 4 }]}
      >
        {files.map((file, indexOfFile) => {
          let fileStatusIcon = (
            <Icon
              name="status-pending"
              size="normal"
              variant="normal"
              className="step-details-file-upload-status"
            />
          );

          if (file.fileStatus === FILE_STATUS.ScannedQuarantined) {
            fileStatusIcon = (
              <Icon
                name="status-negative"
                size="normal"
                variant="warning"
                className="step-details-file-upload-status"
              />
            );
          }

          if (file.fileStatus === FILE_STATUS.ScannedClean) {
            fileStatusIcon = (
              <Icon
                name="status-positive"
                size="normal"
                variant="success"
                className="step-details-file-upload-status"
              />
            );
          }
          return (
            <div
              key={indexOfFile}
              className="step-details-file-upload-container"
            >
              <Button
                variant="link"
                className="step-details-file-button"
                onClick={() => downLoadFile(file.fileId)}
                disabled={
                  FILE_STATUS[file.fileStatus] !== FILE_STATUS.ScannedClean
                }
              >
                <div className="step-details-file-button-container">
                  {fileStatusIcon}
                  <div className="step-details-file-upload-metadata">
                    <div>
                      <span>{file.fileName}</span>
                      {file.fileStatus === FILE_STATUS.ScannedClean ? (
                        <Icon
                          name="download"
                          size="normal"
                          variant="normal"
                          className="step-details-file-download-icon"
                        />
                      ) : (
                        ""
                      )}
                    </div>
                    <Box color="text-body-secondary">
                      File Status is {getStatus(file.fileStatus)}
                    </Box>
                    {file.fileStatus === FILE_STATUS.ScannedQuarantined ? (
                      <Box color="text-body-secondary">
                        Quarantine Reason: {file.quarantineReason}
                      </Box>
                    ) : (
                      <br />
                    )}
                    <Box color="text-body-secondary">
                      File Size in {formatFileSize(file.fileDetails.fileSize)}
                    </Box>
                    <Box color="text-body-secondary">
                      File Type is {file.fileType}
                    </Box>
                    <Box color="text-body-secondary">
                      Last date modified: {getISOTime(file.lastUpdatedOn)}
                    </Box>
                  </div>
                </div>
              </Button>
              {removeFile ? (
                <div
                  className="step-details-file-upload-delete"
                  onClick={() => removeFile(file.fileId)}
                >
                  <Icon name="close" size="normal" variant="normal" />
                </div>
              ) : (
                ""
              )}
            </div>
          );
        })}
      </Grid>
    </SpaceBetween>
  );
};

FilesList.propTypes = {
  files: PropTypes.array,
  removeFile: PropTypes.func,
  setNotificationsItems: PropTypes.func,
  getDownloadUrl: PropTypes.func,
};

export default FilesList;
