export const InputPropsBuilder = ({
  data,
  errors,
  field,
  fieldToIdMap,
  index,
  options,
  sectionName,
  defaultDisable,
  disableFactory,
  valueFactory,
  page,
}) => {
  const {
    component,
    disabled,
    auto,
    description,
    id,
    key,
    label,
    name,
    placeholder,
    edit,
    type,
    required,
  } = field;

  const props = {
    componentType: component,
    description,
    id,
    label,
    name,
    placeholder,
  };

  props["invalid"] = !!errors[id];
  props["error"] = errors[id];
  props["type"] = "";
  props["options"] = [];
  props["optional"] = !required;

  if (type) {
    props["type"] = type;
  }

  if (options && options["isLoading"]) {
    props["options"] = [];
    props["statusType"] = "loading";
  } else if (options[key]) {
    props["options"] = options[key] || [];
  }

  props["disabled"] = disableFactory(name)({
    data,
    edit,
    disabled: defaultDisable || disabled,
    fieldToIdMap,
    auto,
    page,
  });
  props["value"] = valueFactory(name)({
    field,
    data,
    id,
    index,
    fieldToIdMap,
    sectionName,
  });
  return props;
};
