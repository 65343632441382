import {
  getISODate,
  timestampComparator,
} from "../../../../../../shared/util/common/helper";

import { formatReasonCodes } from "./util";

export const fundingHistoryColumnDefinitions = [
  {
    id: "requestId",
    header: "Fund Request or Claim ID",
    cell: (e) => (e.cashClaimId ? e.cashClaimId : e.fundRequestId),
    sortingField: "requestId",
  },
  {
    id: "date",
    header: "Date",
    cell: (e) => getISODate(e.date),
    sortingField: "date",
    sortingComparator: (e1, e2) => timestampComparator(e1.date, e2.date),
  },
  {
    id: "action",
    header: "Action",
    cell: (e) => e.action,
  },
  {
    id: "commentsToPartner",
    header: "Comments to the Partner",
    cell: (e) => e.commentsToPartner,
  },
  {
    id: "partnerComments",
    header: "Partner Comments",
    cell: (e) => e.partnerComments,
  },
  {
    id: "reasonCodes",
    header: "Rejection Reasons",
    cell: (e) => formatReasonCodes(e.reasonCodes),
  },
  {
    id: "stage",
    header: "Stage",
    cell: (e) => e.stage,
    sortingField: "stage",
  },
  {
    id: "claimCompleted",
    header: "Claim Completed",
    cell: (e) => (e.cashClaimCompleted ? e.cashClaimCompleted : "-"),
  },
];

export const searchableColumns = [
  "requestId",
  "stage",
  "date",
  "action",
  "commentsToPartner",
  "partnerComments",
  "claimCompleted",
];
