import React, { useEffect } from "react";
import { COGNITO_AUTH_URL } from "../../../util/services/auth/AuthService";
import Loading from "../../../../shared/components/common/Loading";

const Login = () => {
  useEffect(() => {
    window.location.replace(COGNITO_AUTH_URL);
  });

  return <Loading />;
};

export default Login;
