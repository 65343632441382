import React, { useEffect, useState } from "react";
import { useCollection } from "@amzn/awsui-collection-hooks";
import PropTypes from "prop-types";
import Table from "@amzn/awsui-components-react/polaris/table";
import Header from "@amzn/awsui-components-react/polaris/header";
import Grid from "@amzn/awsui-components-react/polaris/grid";
import Select from "@amzn/awsui-components-react/polaris/select";
import Pagination from "@amzn/awsui-components-react/polaris/pagination";
import {
  partnerApplicationColumnDefinitions,
  defaultStatus,
  defaultRecordTypeLabel,
} from "./tableConfig";
import { getPartnerApplicationDetails } from "../../../../util/services/data/InternalDataService";
import { Box } from "@amzn/awsui-components-react";
import TableNoMatchState from "../../../common/TableNoMatchState/TableNoMatchState";
import TableEmptyState from "../../../common/TableEmptyState/TableEmptyState";
import { clearFilter, filterItem, prepareSelectOptions } from "./util";

export const PartnerApplicationTable = ({ getPartnerAppDetails }) => {
  const pageSize = 10;
  const [isLoading, setIsLoading] = useState(true);
  const [applications, setApplications] = useState([]);
  const [status, setStatus] = useState(defaultStatus);
  const [recordTypeLabel, setRecordTypeLabel] = useState(
    defaultRecordTypeLabel
  );

  useEffect(async () => {
    const data = await getPartnerAppDetails({
      getPartnerApplicationDetails,
      setIsLoading,
    });
    setApplications(data);
    setIsLoading(false);
  }, []);

  const { items, actions, collectionProps, paginationProps } = useCollection(
    applications || [],
    {
      pagination: { pageSize },
      empty: <TableEmptyState resourceName="applications" />,
      filtering: {
        noMatch: (
          <TableNoMatchState
            onClearFilter={() =>
              clearFilter(actions, setStatus, setRecordTypeLabel)
            }
          />
        ),
        filteringFunction: (item, filteringText) => {
          return filterItem({
            item,
            filteringText,
            status,
            recordTypeLabel,
          });
        },
      },
      sorting: {},
      selection: {},
    }
  );

  const selectStatusOptions = prepareSelectOptions(
    applications,
    "status",
    defaultStatus
  );

  const selectRecordTypeLabelOptions = prepareSelectOptions(
    applications,
    "recordTypeLabel",
    defaultRecordTypeLabel
  );

  return (
    <Table
      {...collectionProps}
      loading={isLoading}
      loadingText="Loading Partner Application..."
      items={items}
      columnDefinitions={partnerApplicationColumnDefinitions}
      wrapLines={true}
      filter={
        <Grid gridDefinition={[{ colspan: 2 }, { colspan: 2 }]}>
          <Select
            selectedOption={status}
            onChange={(event) => {
              setStatus(event.detail.selectedOption);
            }}
            options={selectStatusOptions}
          />
          <Select
            selectedOption={recordTypeLabel}
            onChange={(event) => {
              setRecordTypeLabel(event.detail.selectedOption);
            }}
            options={selectRecordTypeLabelOptions}
          />
        </Grid>
      }
      empty={
        <Box textAlign="center" color="inherit">
          <Box padding={{ bottom: "s" }} variant="p" color="inherit">
            No Application(s) available.
          </Box>
        </Box>
      }
      header={
        <Header refresh={getPartnerAppDetails} disabled={isLoading}>
          Application(s)
        </Header>
      }
      pagination={<Pagination {...paginationProps} />}
    />
  );
};

PartnerApplicationTable.propTypes = {
  getPartnerAppDetails: PropTypes.func,
};
