import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  getSectionConstant,
  SECTION,
  SECTION_CONSTANT_TYPE,
  SECTION_NAME,
} from "../../../../shared/util/constants/section";
import { CASH_CLAIM_STAGE } from "../../../../shared/util/constants/fundRequestStatusType";
import { schema, filterFields } from "../../../config/schema";
import {
  createFieldSchemaObject,
  createJsonSchemaObject,
} from "../../../../shared/util/schema/util";
import { createValidator } from "../../../../shared/util/validation/validator";
import { Header, SpaceBetween } from "@amzn/awsui-components-react";
import Button from "@amzn/awsui-components-react/polaris/button";
import { formatData } from "../../../../shared/util/adapter/toApi/adapter";
import { ValidationFactory } from "../../../../shared/components/FundRequest/StepsContainer/ValidationFactory";
import {
  ERROR_TYPE,
  hasError,
  retryApiCall,
} from "../../../../shared/util/services/data/DataService";
import { DataSubmissionFactory } from "../../../util/services/data/dataFactory";
import { setErrorMessage } from "../../../../shared/util/common/helper";
import PropTypes from "prop-types";
import { PAGES } from "../../../util/constants/pages";
import { getCashClaimStage } from "../../../../shared/components/Review/CashClaim/util";

export const CashClaimInvoicePayment = ({
  isLoadingNextStep,
  setIsLoadingNextStep,
  data,
  sectionTitle,
  fieldToIdMap,
  setNotificationItems,
  setErrors,
  errors,
  page,
}) => {
  const history = useHistory();
  const { id, cashClaimId } = useParams();
  const [validators, setValidators] = useState({});
  const [cashClaimStage, setCashClaimStage] = useState(null);

  useEffect(() => {
    const fieldsSchemas = [];
    const createdSchemas = [];
    const cashClaimInvoicePayment = schema[SECTION.CASH_CLAIM_INVOICE_PAYMENT];
    setCashClaimStage(getCashClaimStage(data, cashClaimId));

    for (let field of cashClaimInvoicePayment.fields) {
      if (!field.auto && !createdSchemas.includes(field.name)) {
        const attributes = {
          component: field.component,
          fieldName: field.name,
          type: field.type || undefined,
          pattern: field.pattern || undefined,
          errorMessage: field.errorMessage || undefined,
        };
        createdSchemas.push(field.name);
        fieldsSchemas.push(createFieldSchemaObject(attributes));
      }
    }

    const sectionSchemas = [
      createJsonSchemaObject({
        sectionName: cashClaimInvoicePayment.name,
        fields: cashClaimInvoicePayment.fields,
        program: data["program"],
        page,
      }),
    ];
    setValidators(createValidator([...sectionSchemas, ...fieldsSchemas]));
  }, []);

  return (
    <Header
      actions={
        <SpaceBetween direction="horizontal" size="xs">
          <Button
            onClick={() => {
              history.push(`/${PAGES.CASH_CLAIM_APPROVAL}/${id}/review`);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            loading={isLoadingNextStep}
            onClick={async () => {
              setIsLoadingNextStep(true);

              const currentSchema = schema[SECTION.CASH_CLAIM_INVOICE_PAYMENT];
              const sectionName = schema[sectionTitle].name;

              let validated = true;
              let validationErrors = {};
              let submissionData = {};

              const sections = [SECTION.CASH_CLAIM_INVOICE_PAYMENT];

              // Format, and validate data
              for (let sectionTitle of sections) {
                const formattedData = formatData(sectionName)({
                  step: currentSchema,
                  stepName: sectionName,
                  stepTitle: sectionTitle,
                  data,
                  fieldMap: fieldToIdMap,
                  filterFields: filterFields,
                  schema,
                  cashClaimId,
                });

                formattedData["fundRequestId"] = id;
                formattedData["cashClaimId"] = cashClaimId;
                formattedData["currency"] =
                  data[
                    fieldToIdMap[SECTION_NAME.CASH_REQUEST_INFORMATION][
                      "currency"
                    ]
                  ];

                //Validate formatted data
                const { errors, valid } = ValidationFactory(sectionTitle)({
                  validators,
                  sectionName,
                  data: formattedData,
                });

                validated = valid && validated;

                submissionData = {
                  ...submissionData,
                  [sectionTitle]: { ...formattedData },
                };

                validationErrors = {
                  ...validationErrors,
                  ...errors,
                };
              }

              let submitted = validated;
              // Attempt submission and capture errors.
              if (validated) {
                for (let sectionTitle of Object.keys(submissionData)) {
                  const sectionName = getSectionConstant({
                    type: SECTION_CONSTANT_TYPE.NAME,
                    value: sectionTitle,
                  });

                  const submissionResponse = await retryApiCall({
                    callApi: DataSubmissionFactory({ title: sectionTitle })(
                      submissionData[sectionTitle],
                      cashClaimId
                    ),
                  });
                  //TODO: Add failure condition

                  const success = !hasError(submissionResponse);
                  if (!success) {
                    //Handle error
                    const responseBodyKeys = Object.keys(submissionResponse);

                    if (
                      responseBodyKeys.length > 0 &&
                      responseBodyKeys.includes("errors") &&
                      typeof submissionResponse["errors"] === "object" &&
                      submissionResponse["errorType"] !== ERROR_TYPE.BANNER
                    ) {
                      validationErrors = {
                        ...validationErrors,
                        [sectionName]: {
                          ...submissionResponse["errors"],
                          ...validationErrors[sectionName],
                        },
                      };
                    } else {
                      setErrorMessage({
                        setNotificationsItems: setNotificationItems,
                        content:
                          submissionResponse &&
                          (submissionResponse.message ||
                            JSON.stringify(submissionResponse)),
                        status: submissionResponse.status,
                      });
                    }
                    submitted = success && submitted;
                  }
                }
              }
              setIsLoadingNextStep(false);

              //Handling errors and preventing continuation.
              if (!submitted) {
                const newErrors = {};
                Object.keys(validationErrors).forEach((sectionName) => {
                  Object.keys(validationErrors[sectionName]).forEach(
                    (fieldName) => {
                      let errorId = fieldToIdMap[sectionName][fieldName];
                      newErrors[errorId] =
                        validationErrors[sectionName][fieldName];
                    }
                  );
                });

                setErrors({ ...errors, ...newErrors });
                return;
              }

              history.push(`/${PAGES.CASH_CLAIM_APPROVAL}/${id}/review`, {
                origin: "CashClaimActualUpdate",
                cashClaimId: cashClaimId,
              });
            }}
          >
            Save
          </Button>
          {cashClaimStage && cashClaimStage !== CASH_CLAIM_STAGE.COMPLETED && (
            <Button
              variant="primary"
              loading={isLoadingNextStep}
              onClick={async () => {
                setIsLoadingNextStep(true);

                const currentSchema =
                  schema[SECTION.CASH_CLAIM_INVOICE_PAYMENT];
                const sectionName = schema[sectionTitle].name;

                let validated = true;
                let validationErrors = {};
                let submissionData = {};

                const sections = [SECTION.CASH_CLAIM_INVOICE_PAYMENT];

                // Format, and validate data
                for (let sectionTitle of sections) {
                  const formattedData = formatData(sectionName)({
                    step: currentSchema,
                    stepName: sectionName,
                    stepTitle: sectionTitle,
                    data,
                    fieldMap: fieldToIdMap,
                    filterFields: filterFields,
                    schema,
                    cashClaimId,
                  });

                  formattedData["fundRequestId"] = id;
                  formattedData["cashClaimId"] = cashClaimId;
                  formattedData["currency"] =
                    data[
                      fieldToIdMap[SECTION_NAME.CASH_REQUEST_INFORMATION][
                        "currency"
                      ]
                    ];
                  console.log(formattedData);

                  //Validate formatted data
                  const { errors, valid } = ValidationFactory(sectionTitle)({
                    validators,
                    sectionName,
                    data: formattedData,
                  });

                  validated = valid && validated;

                  submissionData = {
                    ...submissionData,
                    [sectionTitle]: { ...formattedData },
                  };

                  validationErrors = {
                    ...validationErrors,
                    ...errors,
                  };
                }

                let submitted = validated;
                // Attempt submission and capture errors.
                if (validated) {
                  for (let sectionTitle of Object.keys(submissionData)) {
                    const sectionName = getSectionConstant({
                      type: SECTION_CONSTANT_TYPE.NAME,
                      value: sectionTitle,
                    });

                    const submissionResponse = await retryApiCall({
                      callApi: DataSubmissionFactory({
                        title: sectionTitle,
                        submit: true,
                      })(submissionData[sectionTitle], cashClaimId),
                    });

                    //TODO: Add failure condition

                    const success = !hasError(submissionResponse);
                    if (!success) {
                      //Handle error
                      const responseBodyKeys = Object.keys(submissionResponse);

                      if (
                        responseBodyKeys.length > 0 &&
                        responseBodyKeys.includes("errors") &&
                        typeof submissionResponse["errors"] === "object" &&
                        submissionResponse["errorType"] !== ERROR_TYPE.BANNER
                      ) {
                        validationErrors = {
                          ...validationErrors,
                          [sectionName]: {
                            ...submissionResponse["errors"],
                            ...validationErrors[sectionName],
                          },
                        };
                      } else {
                        setErrorMessage({
                          setNotificationItems,
                          content: JSON.stringify(submissionResponse),
                          status: submissionResponse.status,
                        });
                      }
                      submitted = success && submitted;
                    }
                  }
                }
                setIsLoadingNextStep(false);

                //Handling errors and preventing continuation.
                if (!submitted) {
                  const newErrors = {};
                  Object.keys(validationErrors).forEach((sectionName) => {
                    Object.keys(validationErrors[sectionName]).forEach(
                      (fieldName) => {
                        let errorId = fieldToIdMap[sectionName][fieldName];
                        newErrors[errorId] =
                          validationErrors[sectionName][fieldName];
                      }
                    );
                  });

                  setErrors({ ...errors, ...newErrors });
                  return;
                }

                history.push(`/${PAGES.CASH_CLAIM_APPROVAL}/${id}/review`, {
                  origin: "CashClaimActualUpdate",
                  cashClaimId: cashClaimId,
                });
              }}
            >
              Save & Complete
            </Button>
          )}
        </SpaceBetween>
      }
    />
  );
};

CashClaimInvoicePayment.propTypes = {
  isLoadingNextStep: PropTypes.bool,
  setIsLoadingNextStep: PropTypes.func,
  data: PropTypes.object,
  sectionTitle: PropTypes.string,
  fieldToIdMap: PropTypes.object,
  validators: PropTypes.object,
  setNotificationItems: PropTypes.func,
  setErrors: PropTypes.func,
  errors: PropTypes.object,
  page: PropTypes.string,
};
