import React, { ReactElement, useState } from "react";
import {
  FileUpload,
  FormField,
  SpaceBetween,
  Spinner,
} from "@amzn/awsui-components-react";
import {
  ACCEPTED_FILE_TYPES,
  fileUploadI18nStrings,
} from "external/programs/migration-acceleration-program/2024/fund-request/components/create/wizard/Steps/Artifacts/util";
import {
  FILE_UPLOAD_FAILURE_MESSAGE,
  fileChangeHandler,
  FileChangeType,
  identifyNontrackedFile,
} from "shared/programs/migration-acceleration-program/2024/fund-request/components/attachments/util";
import ConditionalField from "shared/components/common/Conditional/ConditionalField";
import { IArtifactFileModel } from "shared/programs/migration-acceleration-program/2024/fund-request/components/attachments/types/fileTypes";
import { IGenericObject } from "shared/programs/migration-acceleration-program/2024/fund-request/types/CommonTypes";
import { FormError, Result, Success } from "shared/util/api/util";
import { attachFileToFundRequest } from "external/util/services/data/FundRequestService";
import { retrieveFilesAfterUpdate } from "shared/programs/migration-acceleration-program/2024/fund-request/util/attachments";

interface IFileUploadProps {
  artifacts: {
    files: File[];
    fileIdToAttributes: IGenericObject;
  };
  setData?: React.Dispatch<React.SetStateAction<IGenericObject>>;
  label: string | ReactElement;
  description?: string | ReactElement;
  constraintText?: string | ReactElement;
  supportingDocumentType?: string;
  multiple?: boolean;
  children?: ReactElement;
  claimId?: string;
  updateFiles: (files: File[]) => void;
  setFileIdToAttributes: React.Dispatch<
    React.SetStateAction<IArtifactFileModel>
  >;
  attach?: ({
    body,
    fundRequestId,
  }: {
    body: unknown;
    fundRequestId: unknown;
  }) => Promise<IGenericObject>;
}

export const FileUploadComponent = ({
  artifacts,
  updateFiles,
  setData,
  setFileIdToAttributes,
  label,
  description,
  constraintText,
  supportingDocumentType,
  multiple = true,
  children,
  attach,
  claimId,
}: IFileUploadProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [formError, setFormError] = useState<string>();

  const { files, fileIdToAttributes } = artifacts;
  return (
    <FormField label={label} description={description} errorText={formError}>
      <SpaceBetween size="xs">
        {children}
        <FileUpload
          constraintText={constraintText}
          onChange={async ({ detail }) => {
            const { value } = detail;

            if (isLoading) {
              setFormError(
                "You are currently trying to upload a file. Please wait until the file has finished uploading before uploading."
              );
            }

            const action =
              value.length > files.length
                ? FileChangeType.ADD
                : FileChangeType.REMOVE;

            if (action === FileChangeType.ADD && !supportingDocumentType) {
              setFormError(
                "Selecting a document type is required before uploading your document."
              );
              setIsLoading(false);
              return;
            }

            if (value.length === files.length) return;

            setFormError("");
            setIsLoading(true);

            const handler = fileChangeHandler({ type: action });
            const result = await handler({
              previous: files,
              current: value,
              claimId: claimId,
              fileIdToAttributes,
              supportingDocumentType,
              attach: attach || attachFileToFundRequest,
            });

            if (result && !result.success) {
              const errorResult = result as Result<FormError>;
              setFormError(
                errorResult.payload?.message || FILE_UPLOAD_FAILURE_MESSAGE
              );
              setIsLoading(false);
              return;
            }

            if (action === FileChangeType.ADD) {
              const nonTrackedFile = identifyNontrackedFile({
                files: value,
                fileIdToAttributes,
              });

              if (!nonTrackedFile) {
                setFormError(
                  "You have already previously uploaded this file. Please upload a different file."
                );
                setIsLoading(false);
                return;
              }
              // Technically in this case, the success message is the fileId.
              const { message } = result.payload as Success;
              setFileIdToAttributes((prevState) => ({
                ...prevState,
                [message]: {
                  size: nonTrackedFile.size,
                  name: nonTrackedFile.name,
                  lastModified: nonTrackedFile.lastModified,
                },
              }));
            }

            if (action === FileChangeType.REMOVE) {
              const { message } = result.payload as Success;
              setFileIdToAttributes((prevState) => {
                delete prevState[message];
                return prevState;
              });
            }

            updateFiles(value);

            if (setData) {
              const updatedFiles = await retrieveFilesAfterUpdate();
              const updatedDocuments = Object.assign({}, updatedFiles);
              setData((prevData) => ({
                ...prevData,
                artifacts: {
                  ...prevData.artifacts,
                  documents: updatedDocuments,
                },
              }));
            }
            setIsLoading(false);
          }}
          value={files}
          i18nStrings={fileUploadI18nStrings}
          multiple={multiple}
          accept={ACCEPTED_FILE_TYPES}
          showFileLastModified
          showFileSize
          tokenLimit={3}
        />
      </SpaceBetween>
      <ConditionalField showField={isLoading}>
        <Spinner />
      </ConditionalField>
    </FormField>
  );
};
