import React from "react";
import { SpaceBetween } from "@amzn/awsui-components-react";
import Button from "@amzn/awsui-components-react/polaris/button";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

const TableActions = ({ selectedItems, refresh }) => {
  const history = useHistory();

  return (
    <SpaceBetween size="s" direction="horizontal">
      <Button
        iconName="refresh"
        variant="normal"
        onClick={() => refresh()}
        disabled={false}
      />
      <Button
        variant="primary"
        onClick={() => {
          const [selectedItem] = selectedItems;
          const { name } = selectedItem;
          const EntityLink = `/self-service/${name}`;
          history.push(EntityLink);
        }}
        disabled={!selectedItems.length > 0}
      >
        View Entity
      </Button>
    </SpaceBetween>
  );
};

TableActions.propTypes = {
  selectedItems: PropTypes.array,
  refresh: PropTypes.func,
};

export default TableActions;
