import { SECTION_NAME } from "../../../../../shared/util/constants/section";
import { DefaultReviewSectionContainer } from "./DefaultReviewSectionContainer";
import { MigrationWorkloadsReviewSectionContainer } from "./MigrationWorkloadsReviewSectionContainer";
import { OpportunityReviewSectionContainer } from "../../../../../shared/components/FundRequest/Review/Sections/OpportunityReviewSectionContainer";
import { ExternalProjectInformationReviewSectionContainer } from "./ExternalProjectInformationReviewSectionContainer";
import { ExternalCashRequestReviewSectionContainer } from "./ExternalCashRequestReviewSectionContainer";
import { ExternalAttachmentsReviewSectionContainer } from "./ExternalAttachmentsReviewSectionContainer";

export const ReviewSectionFactory = (name) => {
  switch (name) {
    case SECTION_NAME.PROJECT_INFORMATION:
      return ExternalProjectInformationReviewSectionContainer;
    case SECTION_NAME.CASH_REQUEST_INFORMATION:
      return ExternalCashRequestReviewSectionContainer;
    case SECTION_NAME.MIGRATION_WORKLOADS:
      return MigrationWorkloadsReviewSectionContainer;
    case SECTION_NAME.OPPORTUNITY_INFORMATION:
      return OpportunityReviewSectionContainer;
    case SECTION_NAME.ATTACHMENTS:
      return ExternalAttachmentsReviewSectionContainer;
    default:
      return DefaultReviewSectionContainer;
  }
};
