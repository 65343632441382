import { SECTION, SECTION_NAME } from "shared/util/constants/section";
import { createValueField } from "shared/components/Review/CreateValueField";

export const getMigrationEngagementFields = (
  fundingData,
  schema,
  filterFields,
  valueFactory
) => {
  let overviewFields = [];
  let RAFields = [];
  let OLAFields = [];
  const migrationEngagementSectionMap = {
    overview: {
      fields: overviewFields,
      section: SECTION.MIGRATION_ENGAGEMENT_OVERVIEW,
      header: SECTION_NAME.MIGRATION_ENGAGEMENT_OVERVIEW,
    },
    readinessAgreement: {
      fields: RAFields,
      section: SECTION.MIGRATION_ENGAGEMENT_RA,
      header: SECTION_NAME.MIGRATION_ENGAGEMENT_RA,
    },
    optimizationAndLicensing: {
      fields: OLAFields,
      section: SECTION.MIGRATION_ENGAGEMENT_OLA,
      header: SECTION_NAME.MIGRATION_ENGAGEMENT_OLA,
    },
  };

  for (let section in migrationEngagementSectionMap) {
    const filteredFieldsFromSchema = filterFields({
      section: schema[migrationEngagementSectionMap[section].section],
      program: fundingData.program,
    });

    filteredFieldsFromSchema.map((field) =>
      migrationEngagementSectionMap[section].fields.push(
        createValueField({
          field,
          value: valueFactory({
            apiKey: migrationEngagementSectionMap[section].header,
            sectionTitle: migrationEngagementSectionMap[section].header,
            name: field.name,
            data: fundingData?.migrationEngagements[0],
            field,
          }),
        })
      )
    );
  }
  return { overviewFields, RAFields, OLAFields };
};
