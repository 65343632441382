import React, { useEffect, useState } from "react";
import { ContentLayout } from "@amzn/awsui-components-react";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Container from "@amzn/awsui-components-react/polaris/container";
import Header from "@amzn/awsui-components-react/polaris/header";
import { IStepStateProps } from "../../../../../types/WizardTypes";
import { cashRequestDetailsSchema, invoiceDetails } from "./schema";
import { useParams } from "react-router-dom";
import { fetchFundRequest } from "external/programs/migration-acceleration-program/2024/fund-request/components/create/wizard/util";
import InputSectionFieldsFactory from "external/programs/migration-acceleration-program/2024/fund-request/components/create/wizard/Steps/InputSectionFieldsFactory";
import SystemErrorAlert from "shared/programs/migration-acceleration-program/2024/fund-request/components/common/SystemErrorAlert";
import IncentiveAlerts from "external/programs/migration-acceleration-program/2024/fund-request/components/create/wizard/Steps/FundingDetails/IncentiveAlerts";
import { IGenericObject } from "shared/programs/migration-acceleration-program/2024/fund-request/types/CommonTypes";
import { HelpPanelContentContext } from "shared/util/context/help/HelpContext";
import { isFeatureEnabled } from "shared/util/services/features/FeatureFlagsService";
import { FEATURE_FLAG_ENUMS } from "shared/util/constants/featureFlagValues";
import IncentiveAlertsWithVMware from "./IncentiveAlertsWithVMware";

const FundingDetails = ({
  adapt,
  data,
  errors,
  formError,
  onError,
  onChange,
  setData,
}: IStepStateProps) => {
  const { id } = useParams<IGenericObject>();
  const { setKey } = React.useContext(HelpPanelContentContext);
  const [vmwareFlag, setVmwareFlag] = useState<boolean>(false);

  // @ts-expect-error Promise not being returned
  useEffect(async () => {
    setKey("wizard.fundingDetails.page");
    setVmwareFlag(
      await isFeatureEnabled(FEATURE_FLAG_ENUMS.VMWARE_MAP_ENABLED)
    );
    await fetchFundRequest({ id, adapt, setData });
  }, []);

  return (
    <ContentLayout>
      <SpaceBetween size="l">
        {vmwareFlag ? (
          <IncentiveAlertsWithVMware data={data} />
        ) : (
          <IncentiveAlerts data={data} />
        )}
        <Container header={<Header variant="h2">Cash request details</Header>}>
          <InputSectionFieldsFactory
            data={data}
            errors={errors}
            onChange={onChange}
            onError={onError}
            schema={cashRequestDetailsSchema}
          />
        </Container>
        <Container
          header={
            <Header
              variant="h2"
              description="All invoice details must match the values from Amazon Payee Central."
            >
              Invoice details
            </Header>
          }
        >
          <InputSectionFieldsFactory
            data={data}
            errors={errors}
            onChange={onChange}
            onError={onError}
            schema={invoiceDetails}
          />
        </Container>
        <SystemErrorAlert isVisible={!!formError} error={formError} />
      </SpaceBetween>
    </ContentLayout>
  );
};

export default FundingDetails;
