import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useHistory, useLocation, useParams } from "react-router-dom";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Header from "@amzn/awsui-components-react/polaris/header";
import Button from "@amzn/awsui-components-react/polaris/button";
import DOMPurify from "dompurify";
import Loading from "../../../../shared/components/common/Loading";
import StatusBar from "../../../../shared/components/StatusBar";
import withAppLayout from "../../../../shared/components/withAppLayout";
import {
  ERROR_TYPE,
  hasError,
  retryApiCall,
} from "../../../../shared/util/services/data/DataService";
import { ReviewSectionFactory } from "./Sections/ReviewSectionFactory";
import { EXTERNAL_REVIEW_ACTION_TYPE } from "../../../util/constants/reviewActions";
import ChangeOwner from "./ChangeOwner";
import { getFundRequest } from "../../../util/services/data/FundRequestService";
import { v4 as uuid } from "uuid";
import {
  setErrorMessage,
  setInfoMessage,
  setSuccessMessage,
} from "../../../../shared/util/common/helper";
import { getBreadcrumbs } from "../../../../shared/util/common/getBreadcrumbs";
import { filterFields, getTemplate, schema } from "../../../config/schema";
import { TITLE } from "../../../../shared/util/constants/title";
import ExtendFundRequest from "./ExtendFundRequest";
import {
  PROGRAM_NAME_TYPE,
  PROGRAM_TYPE,
} from "../../../../shared/util/constants/programType";
import { convertSMPtoMAP } from "../../../../shared/util/common/convertSMPtoMAP";
import { disableButton, isAllianceLead } from "../../../util/common/helper";
import {
  STAGE,
  STATUS,
} from "../../../../shared/util/constants/fundRequestStatusType";
import ChangeStatus from "./ChangeStatus/ChangeStatus";
import { ValueFactory } from "./Sections/ValueFactory";
import { canExtend } from "./util";
import FundRequestComments from "./Sections/FundRequestComments";
import SurveyBannerContainer from "../../../../shared/components/SurveyBannerContainer";
import Config from "config";
import { isFeatureEnabled } from "../../../../shared/util/services/features/FeatureFlagsService";
import { FEATURE_FLAG_ENUMS } from "../../../../shared/util/constants/featureFlagValues";
import { mpe_records_do_exists } from "../StepsContainer/util/util";
import { MAP2024_MAP_PREFIX } from "../../../../shared/util/constants/fund-request";

const Review = ({ hideBanner }) => {
  const history = useHistory();
  const { id } = useParams();
  const { state } = useLocation();
  const historyRef = useRef(null);
  const [isLoading, setIsLoading] = useState({
    review: true,
  });
  const [fundingData, setFundingData] = useState();
  const [visible, setVisible] = useState({
    changeOwner: false,
    extension: false,
    [EXTERNAL_REVIEW_ACTION_TYPE.RECALL.toLowerCase()]: false,
    [EXTERNAL_REVIEW_ACTION_TYPE.TERMINATE.toLowerCase()]: false,
  });
  const [notificationsItems, setNotificationsItems] = useState([]);
  const [status, setStatus] = useState();
  const [stage, setStage] = useState();
  const [hideSubmit, setHideSubmit] = useState(false);
  const STAGES_ELIGIBLE_FOR_PROCESSING_DELAY_BANNER = [
    STAGE.REVIEW,
    STAGE.SUBMITTED,
    STAGE.CREATED,
  ];

  useEffect(async () => {
    document.title = DOMPurify.sanitize(`${TITLE} - ${id} - Review`);

    if (id.startsWith(MAP2024_MAP_PREFIX)) {
      history.push(`/fund-requests/${id}/details`);
    }

    if (state && state.origin === "StepsContainer") {
      setSuccessMessage(
        setNotificationsItems,
        "Submitted",
        `Your fund request has been successfully submitted!`
      );
    }

    if (state && state.origin === "CashClaimActualUpdate") {
      setSuccessMessage(
        setNotificationsItems,
        "Updated",
        `The cash claim for claim id: ${state.cashClaimId} has been updated!`
      );
    }

    if (state && state.origin === "CashClaimActualSubmit") {
      setSuccessMessage(
        setNotificationsItems,
        "Submitted",
        `The cash claim for claim id: ${state.cashClaimId} has been submitted!`
      );
    }

    await loadFundRequestInformation();
  }, []);

  const loadFundRequestInformation = async () => {
    setIsLoading({ ...isLoading, review: true });
    try {
      const response = await retryApiCall({ callApi: getFundRequest(`${id}`) });
      const mpe_id_and_arr_validation_for_map_is_enabled =
        await isFeatureEnabled(
          FEATURE_FLAG_ENUMS.MPE_AND_ARR_VALIDATION_FOR_MAP_OPPORTUNITY
        );

      //TODO: Add failure condition
      if (hasError(response) && response.errorType === ERROR_TYPE.BANNER) {
        setErrorMessage({
          setNotificationsItems,
          content: response.message,
          status: response.status,
        });
        setIsLoading({ ...isLoading, review: false });
        return;
      }

      if (
        mpe_id_and_arr_validation_for_map_is_enabled &&
        STAGES_ELIGIBLE_FOR_PROCESSING_DELAY_BANNER.includes(
          response["stage"]
        ) &&
        mpe_records_do_exists(response) === false
      ) {
        setInfoMessage({
          setNotificationsItems,
          header: "Caution:",
          content:
            "Please expect some processing delays and try to work with respective PSM.",
        });
      }

      const {
        status,
        stage,
        fundingTemplate: { program, fundingType, migrationPhase },
      } = response;
      setFundingData({ program, migrationPhase, fundingType, ...response });
      setStatus(status);
      setStage(stage);

      if (stage !== STAGE.CREATED || status !== STATUS.ACTIVE) {
        setHideSubmit(true);
      }
    } catch (e) {
      setErrorMessage({
        setNotificationsItems,
      });
      setIsLoading({ ...isLoading, review: false });
      setFundingData({});
      console.error(e);
    }

    setIsLoading({ ...isLoading, review: false });
  };

  function handleHistoryButton() {
    historyRef.current?.scrollIntoView({ behavior: "smooth" });
  }

  const FundRequestReview = ({ fundingData, setNotificationsItems }) => {
    const template = getTemplate({
      program: fundingData.program,
      migrationPhase: fundingData.migrationPhase,
      fundingType: fundingData.fundingType,
    });
    return Object.keys(template).map((step, index) => {
      return (
        <React.Fragment key={uuid()}>
          {ReviewSectionFactory(step)({
            step,
            stage,
            index,
            template,
            fundingData,
            setNotificationsItems,
            id,
            history,
            setFundingData,
            filterFields,
            schema,
            valueFactory: ValueFactory,
          })}
        </React.Fragment>
      );
    });
  };

  const setModalVisibility = (modalName, visible) => {
    setVisible({ ...visible, [modalName]: visible });
  };

  let changeStatusModal = "";
  if (visible[EXTERNAL_REVIEW_ACTION_TYPE.RECALL]) {
    changeStatusModal = EXTERNAL_REVIEW_ACTION_TYPE.RECALL;
  } else if (visible[EXTERNAL_REVIEW_ACTION_TYPE.TERMINATE]) {
    changeStatusModal = EXTERNAL_REVIEW_ACTION_TYPE.TERMINATE;
  }

  const surveyBannerQuestion = {
    questionText: <h3>APFP Feedback Survey</h3>,
    questionDescription: (
      <p>
        Tell us what you think about the Fund Request submission experience.
      </p>
    ),
  };

  return withAppLayout({
    component: isLoading["review"] ? (
      <Loading />
    ) : fundingData ? (
      <SpaceBetween size="l">
        {!hideBanner && (
          <SurveyBannerContainer
            apertureForm={Config.FR_SUBMISSION_FEEDBACK_FORM}
            nonConsoleOptions={Config.APERTURE_WIDGET_CONFIG}
            question={surveyBannerQuestion}
            hideBanner={hideBanner}
          />
        )}
        <Header
          variant="h1"
          description={`Fund Request Id: ${fundingData.fundRequestId}`}
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                iconName="refresh"
                variant="normal"
                loading={isLoading["review"]}
                onClick={async () => await loadFundRequestInformation()}
              />

              <Button variant="primary" onClick={handleHistoryButton}>
                Approval History
              </Button>

              {!hideSubmit && (
                <Button
                  variant="primary"
                  onClick={() => {
                    if (
                      id.includes(PROGRAM_TYPE.SMP) ||
                      id.includes(PROGRAM_TYPE.POC)
                    )
                      window.location.replace(
                        `/fund-request/${id}/select-program/edit`
                      );
                    else window.location.replace(`/fund-request/${id}/draft`);
                  }}
                >
                  Submit
                </Button>
              )}

              {canExtend({ data: fundingData, stage }) ? (
                <Button
                  disabled={disableButton({ stage, status })}
                  onClick={() => {
                    setModalVisibility("extension", true);
                  }}
                >
                  Extend
                </Button>
              ) : (
                ""
              )}
              <Button
                disabled={disableButton({ stage, status })}
                loading={isLoading["terminate"]}
                onClick={() => {
                  setModalVisibility(
                    EXTERNAL_REVIEW_ACTION_TYPE.TERMINATE,
                    true
                  );
                }}
              >
                Terminate
              </Button>
              <Button
                disabled={
                  disableButton({ stage, status }) ||
                  stage === STAGE.PRE_APPROVAL ||
                  stage === STAGE.CASH_CLAIM
                }
                loading={isLoading["recall"]}
                onClick={() => {
                  setModalVisibility(EXTERNAL_REVIEW_ACTION_TYPE.RECALL, true);
                }}
              >
                Recall
              </Button>
              <Button
                disabled={disableButton({ stage, status }) || !isAllianceLead()}
                onClick={() => {
                  setModalVisibility("changeOwner", true);
                }}
              >
                Change Owner
              </Button>
              <Button
                variant="primary"
                onClick={() => history.push("/dashboard")}
              >
                Dashboard
              </Button>
            </SpaceBetween>
          }
        >
          {convertSMPtoMAP(PROGRAM_NAME_TYPE[fundingData.program])}
        </Header>
        <StatusBar
          stage={stage}
          program={fundingData.program}
          fundingType={fundingData.fundingType}
          data={fundingData}
        />
        <FundRequestReview
          fundingData={fundingData}
          setNotificationsItems={setNotificationsItems}
        />
        <FundRequestComments
          setNotificationsItems={setNotificationsItems}
          ref={historyRef}
        />
        <br />
        <br />
        <ChangeOwner
          visible={visible["changeOwner"]}
          setModalVisibility={setModalVisibility}
          setNotificationsItems={setNotificationsItems}
        />
        <ExtendFundRequest
          visible={visible["extension"]}
          setModalVisibility={setModalVisibility}
          setNotificationsItems={setNotificationsItems}
        />
        <ChangeStatus
          history={history}
          reviewAction={changeStatusModal}
          visible={changeStatusModal ? visible[changeStatusModal] : false}
          setNotificationsItems={setNotificationsItems}
          program={fundingData.program}
          setModalVisibility={setModalVisibility}
          id={id}
        />
      </SpaceBetween>
    ) : (
      ""
    ),
    breadcrumbs: getBreadcrumbs({
      text: fundingData ? convertSMPtoMAP(fundingData.program) : "",
    }),
    toolsHide: true,
    notificationsItems: notificationsItems,
  })();
};

Review.propTypes = {
  history: PropTypes.object,
};

export default Review;
