import { useState } from "react";
import DetailsActionWithModal from "shared/programs/migration-acceleration-program/2024/fund-request/components/modal/DetailsActionWithModal";
import { hasError } from "shared/util/services/data/DataService";
import { Result, ResultType, Success } from "shared/util/api/util";
import {
  ColumnLayout,
  DatePicker,
  FormField,
  Textarea,
  SpaceBetween,
} from "@amzn/awsui-components-react";
import { submitFundRequestExtension } from "external/util/services/data/FundRequestService";
import { handlePartnerCommentErrorResponse } from "external/programs/migration-acceleration-program/2024/fund-request/components/details/FundRequestDetailsContainer/header/actions/PartnerCommentDetailsActionWithModal";
import withFeatureFlag from "shared/components/FeatureFlag";
import { FEATURE_FLAG_ENUMS } from "shared/util/constants/featureFlagValues";

interface IExtendErrorResponse {
  errorType: string;
  errors: { [key: string]: string };
  status: number;
}

interface IExtendState {
  isModalLoading: boolean;
  inputs: {
    extendedReason: string;
    extendedStartDate: string;
    extendedEndDate: string;
  };
  errors: {
    extendedReason: string;
    extendedStartDate: string;
    extendedEndDate: string;
  };
}

const Extend = ({ isLoading }: { isLoading: boolean }) => {
  const [extendState, setExtendState] = useState<IExtendState>({
    isModalLoading: false,
    inputs: {
      extendedReason: "",
      extendedStartDate: "",
      extendedEndDate: "",
    },
    errors: {
      extendedReason: "",
      extendedStartDate: "",
      extendedEndDate: "",
    },
  });

  const onSubmit = async (): Promise<Result<ResultType>> => {
    setExtendState((currentState) => ({
      ...currentState,
      isModalLoading: true,
    }));
    let result = new Result<Success>({});
    try {
      const response = await submitFundRequestExtension({
        extendedStartDate: {
          timestamp: new Date(extendState.inputs?.extendedStartDate).getTime(),
        },
        extendedEndDate: {
          timestamp: new Date(extendState.inputs?.extendedEndDate).getTime(),
        },
        extendedReason: extendState.inputs?.extendedReason,
      });
      if (hasError(response)) {
        // NOTE: dont follow this pattern...please...
        // I don't have time to fix the FundRequestService and type it all
        const errorResponse = response as IExtendErrorResponse;
        result = handlePartnerCommentErrorResponse({ response });
        setExtendState((currentState) => ({
          ...currentState,
          errors: {
            extendedStartDate: errorResponse?.errors?.extendedStartDate || "",
            extendedEndDate: errorResponse?.errors?.extendedEndDate || "",
            extendedReason: errorResponse?.errors?.extendedReason || "",
          },
        }));
      } else {
        onCancel();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setExtendState((currentState) => ({
        ...currentState,
        isModalLoading: false,
      }));
    }
    return result;
  };

  const onCancel = () => {
    setExtendState({
      isModalLoading: false,
      inputs: {
        extendedReason: "",
        extendedStartDate: "",
        extendedEndDate: "",
      },
      errors: {
        extendedReason: "",
        extendedStartDate: "",
        extendedEndDate: "",
      },
    });
  };

  return (
    <>
      <DetailsActionWithModal
        isLoading={extendState.isModalLoading || isLoading}
        isDisabled={
          extendState.inputs.extendedStartDate === "" ||
          extendState.inputs.extendedEndDate === "" ||
          extendState.inputs.extendedReason === ""
        }
        dropModalOnError={false}
        submit={onSubmit}
        cancel={onCancel}
        labels={{
          actionButton: "Extend",
          title: "Extend fund request",
          submitButton: "Extend fund request",
          successMessage: `Successfully Extended FundRequest.`,
        }}
      >
        <SpaceBetween size="l">
          <ColumnLayout columns={2}>
            <FormField
              label="Please select extension start date."
              errorText={extendState.errors.extendedStartDate}
            >
              <DatePicker
                value={extendState.inputs.extendedStartDate}
                placeholder="YYYY/MM/DD"
                onChange={({ detail }) => {
                  setExtendState((currentState) => ({
                    ...currentState,
                    inputs: {
                      extendedStartDate: detail.value,
                      extendedEndDate: extendState.inputs.extendedEndDate,
                      extendedReason: extendState.inputs.extendedReason,
                    },
                    errors: {
                      extendedStartDate: "",
                      extendedEndDate: extendState.errors.extendedEndDate,
                      extendedReason: extendState.errors.extendedReason,
                    },
                  }));
                }}
              />
            </FormField>
            <FormField
              label="Please select extension end date."
              errorText={extendState.errors.extendedEndDate}
            >
              <DatePicker
                value={extendState.inputs.extendedEndDate}
                placeholder="YYYY/MM/DD"
                onChange={({ detail }) => {
                  setExtendState((currentState) => ({
                    ...currentState,
                    inputs: {
                      extendedStartDate: extendState.inputs.extendedStartDate,
                      extendedEndDate: detail.value,
                      extendedReason: extendState.inputs.extendedReason,
                    },
                    errors: {
                      extendedStartDate: extendState.errors.extendedStartDate,
                      extendedEndDate: "",
                      extendedReason: extendState.errors.extendedReason,
                    },
                  }));
                }}
              />
            </FormField>
          </ColumnLayout>
          <FormField
            label="Please enter the reason for extension."
            errorText={extendState.errors.extendedReason}
          >
            <Textarea
              value={extendState.inputs.extendedReason}
              onChange={({ detail }) => {
                setExtendState((currentState) => ({
                  ...currentState,
                  inputs: {
                    extendedStartDate: extendState.inputs.extendedStartDate,
                    extendedEndDate: extendState.inputs.extendedEndDate,
                    extendedReason: detail.value,
                  },
                  errors: {
                    extendedStartDate: extendState.errors.extendedStartDate,
                    extendedEndDate: extendState.errors.extendedEndDate,
                    extendedReason: "",
                  },
                }));
              }}
            />
          </FormField>
        </SpaceBetween>
      </DetailsActionWithModal>
    </>
  );
};

export default withFeatureFlag(
  Extend,
  FEATURE_FLAG_ENUMS.MAP_FR_EXTERNAL_EXTENDS,
  true
);
