import { Enums } from "./Constants";

export function reducer(state, action) {
  switch (action.type) {
    case Enums.SET_MODAL:
      return { ...state, isModalVisible: action.payload };
    case Enums.SET_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case Enums.SET_REASON:
      return { ...state, reason: action.payload, reasonError: "" };
    case Enums.SET_REASON_ERROR:
      return { ...state, reasonError: action.payload };
    case Enums.SET_DATE_RANGE:
      return { ...state, dateRange: action.payload, dateRangeError: "" };
    case Enums.SET_DATE_RANGE_ERROR:
      return { ...state, dateRangeError: action.payload };
    case Enums.ADD_FUND_REQUEST:
      return {
        ...state,
        fundRequests: {
          ...state.fundRequests,
          [action.payload.fundRequestId]: action.payload,
        },
      };
    default:
      return state;
  }
}
