import React from "react";
import PropTypes from "prop-types";
import Header from "@amzn/awsui-components-react/polaris/header";
import Button from "@amzn/awsui-components-react/polaris/button";

import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";

const TableHeader = ({
  itemsLength,
  allItemsLength,
  setUploadComponentVisible,
  refresh,
}) => {
  return (
    <div>
      <Header
        counter={itemsLength + "/" + allItemsLength}
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              iconName="refresh"
              variant="normal"
              onClick={async () => await refresh()}
            />
            <Button onClick={() => window.location.replace("/template.xlsx")}>
              Download Template
            </Button>
            <Button
              variant="primary"
              onClick={() => setUploadComponentVisible(true)}
            >
              Upload
            </Button>
          </SpaceBetween>
        }
      >
        Credits
      </Header>
    </div>
  );
};

TableHeader.propTypes = {
  itemsLength: PropTypes.number,
  allItemsLength: PropTypes.number,
  setUploadComponentVisible: PropTypes.func,
  refresh: PropTypes.func,
};

export default TableHeader;
