import { ErrorDisplayType } from "external/util/common/validationHelper";
import { ERROR_TYPE } from "shared/util/services/data/DataService";
import { IGenericObject } from "shared/programs/migration-acceleration-program/2024/fund-request/types/CommonTypes";
import { Error as ResultError } from "shared/util/api/util";
import React from "react";

export class Result<T> {
  success: boolean;
  payload?: T;

  constructor({ success = true, payload }: { success?: boolean; payload?: T }) {
    this.success = success;
    this.payload = payload;
  }
}

export const handleApiError = (errorType: string) => {
  const errorHandlers = {
    [ERROR_TYPE.FIELD]: handleFieldError,
    [ERROR_TYPE.BANNER]: handleFormError,
  };

  return errorHandlers[errorType];
};
const handleFieldError = (response: IGenericObject) => {
  return new Result<FieldError>({
    success: false,
    payload: new FieldError({ errors: response.errors }),
  });
};
const handleFormError = (response: IGenericObject) => {
  return new Result<FormError>({
    success: false,
    payload: new FormError(response.message),
  });
};

export interface ResultType {}

//TODO: ErrorDisplayType needs to move to a more common place....
export class Error<T> implements ResultType {
  type: ErrorDisplayType;
  reason: T;

  constructor({ type, reason }: { type: ErrorDisplayType; reason: T }) {
    this.type = type;
    this.reason = reason;
  }
}

export interface ResultErrorType {
  type: ErrorDisplayType;
}

export class FieldError implements ResultErrorType {
  type: ErrorDisplayType;
  errors: { [key: string]: string };

  constructor({ errors }: { errors: { [key: string]: string } }) {
    this.type = ErrorDisplayType.FIELD;
    this.errors = errors;
  }
}

export class FormError implements ResultErrorType {
  type: ErrorDisplayType;
  message: string;

  constructor(message: string) {
    this.type = ErrorDisplayType.FORM;
    this.message = message;
  }
}

export class Success implements ResultType {
  message: string;

  constructor({ message }: { message: string }) {
    this.message = message;
  }
}

export const handleErrors = ({
  result,
  setErrors,
  setFormError,
}: {
  result: Result<ResultError<ResultErrorType>>;
  setErrors?: React.Dispatch<React.SetStateAction<IGenericObject>>;
  setFormError: React.Dispatch<React.SetStateAction<string>>;
}) => {
  console.error(result);
  if (result.payload?.type === ErrorDisplayType.FIELD) {
    const error = result.payload as unknown as FieldError;
    if (setErrors) setErrors(error.errors);
  }
  if (result.payload?.type === ErrorDisplayType.FORM) {
    const error = result.payload as unknown as FormError;
    setFormError(error.message);
  }
};
