import React from "react";
import AppLayout from "@amzn/awsui-components-react/polaris/app-layout";
import Flashbar from "@amzn/awsui-components-react/polaris/flashbar";

const withAppLayout = ({
  component,
  breadcrumbs,
  toolsContent,
  isToolsOpen,
  onToolsChange,
  navigationHide = true,
  navigation,
  toolsHide,
  notificationsItems,
  splitPanelData,
  splitPanelEnabled,
  onSplitPanelToggle,
}) => {
  const appLayout = () => {
    return (
      <AppLayout
        tools={toolsContent}
        toolsOpen={isToolsOpen}
        onToolsChange={onToolsChange}
        toolsHide={toolsHide}
        content={component}
        navigationHide={navigationHide}
        breadcrumbs={breadcrumbs}
        navigation={navigation}
        notifications={<Flashbar items={notificationsItems} />}
        stickyNotifications={true}
        headerSelector="#h"
        splitPanelOpen={splitPanelEnabled}
        onSplitPanelToggle={onSplitPanelToggle}
        splitPanel={splitPanelData}
      />
    );
  };

  appLayout.displayName = "appLayout";

  return appLayout;
};

export default withAppLayout;
