import React from "react";
import { IGenericObject } from "shared/programs/migration-acceleration-program/2024/fund-request/types/CommonTypes";
import Container from "@amzn/awsui-components-react/polaris/container";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import { ColumnLayout } from "@amzn/awsui-components-react";
import ValueWithLabel from "shared/programs/migration-acceleration-program/2024/fund-request/components/fields/ValueWithLabel";
import { display } from "shared/util/common/display";
import { longLocalDateFormat } from "shared/util/common/date";
import Header from "@amzn/awsui-components-react/polaris/header";
import { generatePhonetoolLinkFromEmail } from "internal/util/constants/generatePhonetoolLink";

interface ApprovalDetailsProps {
  data: IGenericObject;
  loading: boolean;
}

const ApprovalDetails = ({ data }: ApprovalDetailsProps) => {
  const approvalDetails = data?.approvalDetails;
  const opportunity = data?.opportunity;

  return (
    <Container header={<Header variant="h2">Approval details</Header>}>
      <ColumnLayout columns={3} variant="text-grid">
        <SpaceBetween size="l">
          <ValueWithLabel label="Global funding ops">
            {display({ data: approvalDetails, key: "globalFundingOps" })}
          </ValueWithLabel>
          <ValueWithLabel label="Exception approval">
            {display({ data: approvalDetails, key: "exceptionApproval" })}
          </ValueWithLabel>
          <ValueWithLabel label="AWS Sales Rep">
            {display({
              data: opportunity,
              key: "awsSalesRepEmail",
              format: (value) =>
                generatePhonetoolLinkFromEmail(
                  value,
                  opportunity["awsSalesRepName"]
                ),
            })}
          </ValueWithLabel>
          <ValueWithLabel label="Partner Success Manager ">
            {display({
              data: opportunity,
              key: "partnerSuccessManagerEmail",
              format: (value) =>
                generatePhonetoolLinkFromEmail(
                  value,
                  opportunity["partnerSuccessManager"]
                ),
            })}
          </ValueWithLabel>
        </SpaceBetween>
        <SpaceBetween size="l">
          <ValueWithLabel label="Date submitted for approval">
            {display({
              data: approvalDetails,
              key: "approvalSubmissionDate",
              format: (value) => longLocalDateFormat(value),
            })}
          </ValueWithLabel>
          <ValueWithLabel label="Exception comment (internal only)">
            {display({ data: approvalDetails, key: "exceptionComment" })}
          </ValueWithLabel>
          <ValueWithLabel label="Partner Sales Rep ">
            {display({
              data: opportunity,
              key: "partnerSalesRepEmail",
              format: (value) =>
                generatePhonetoolLinkFromEmail(
                  value,
                  opportunity["partnerSalesRepName"]
                ),
            })}
          </ValueWithLabel>
          <ValueWithLabel label="Partner Development Manager">
            {display({
              data: opportunity,
              key: "partnerDevelopmentManagerEmail",
              format: (value) =>
                generatePhonetoolLinkFromEmail(
                  value,
                  opportunity["partnerDevelopmentManager"]
                ),
            })}
          </ValueWithLabel>
        </SpaceBetween>
        <SpaceBetween size="l">
          <ValueWithLabel label="Date approved">
            {display({
              data: approvalDetails,
              key: "approvalDate",
              format: (value) => longLocalDateFormat(value),
            })}
          </ValueWithLabel>
          <ValueWithLabel label="External approval system ID">
            {display({
              data: approvalDetails,
              key: "externalApprovalSystemId",
            })}
          </ValueWithLabel>
          <ValueWithLabel label="AWS Account Manager ">
            {display({
              data: opportunity,
              key: "awsAccountManagerEmail",
              format: (value) =>
                generatePhonetoolLinkFromEmail(
                  value,
                  opportunity["awsAccountManagerName"]
                ),
            })}
          </ValueWithLabel>
        </SpaceBetween>
      </ColumnLayout>
    </Container>
  );
};

export default ApprovalDetails;
