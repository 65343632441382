import { getFundRequest } from "external/util/services/data/FundRequestService";
import React from "react";
import {
  IFundRequest,
  IMAPFundRequest,
} from "shared/programs/migration-acceleration-program/2024/fund-request/types/SchemaTypes";
import { IGenericObject } from "shared/programs/migration-acceleration-program/2024/fund-request/types/CommonTypes";
import {
  FieldError,
  FormError,
  Result,
  ResultType,
} from "shared/util/api/util";

export const fetchFundRequest = async ({
  id,
  adapt,
  setData,
}: {
  id: string;
  adapt?: (response: IGenericObject) => IMAPFundRequest;
  setData: React.Dispatch<React.SetStateAction<IFundRequest>>;
}) => {
  const response = await getFundRequest(id);

  const state = adapt && adapt(response);
  if (state) setData(state);
};

export const handleResultError = ({
  result,
}: {
  result: Result<ResultType>;
}) => {
  const resultType = result.payload instanceof FieldError ? "field" : "form";
  const handlers: {
    [key: string]: ({
      result,
      setError,
    }: {
      result: Result<ResultType>;
      setError:
        | React.Dispatch<React.SetStateAction<string>>
        | React.Dispatch<React.SetStateAction<IGenericObject>>;
    }) => void;
  } = {
    form: handleFormError,
    field: handleFieldError,
  };
  return handlers[resultType];
};

const handleFormError = ({
  result,
  setError,
}: {
  result: Result<ResultType>;
  setError:
    | React.Dispatch<React.SetStateAction<string>>
    | React.Dispatch<React.SetStateAction<IGenericObject>>;
}) => {
  const message = (result.payload as FormError)?.message;
  (setError as React.Dispatch<React.SetStateAction<string>>)(message);
};

const handleFieldError = ({
  result,
  setError,
}: {
  result: Result<ResultType>;
  setError:
    | React.Dispatch<React.SetStateAction<string>>
    | React.Dispatch<React.SetStateAction<IGenericObject>>;
}) => {
  const fieldErrors = (result.payload as FieldError)?.errors;
  (setError as React.Dispatch<React.SetStateAction<IGenericObject>>)(
    fieldErrors
  );
};

export const formatNumberInput = (value: unknown) => {
  if (value === undefined || value === null || value === "" || value === "0") {
    return 0;
  }
  if (value && typeof value === "string") {
    return parseInt(value);
  }
  return value;
};
