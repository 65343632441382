import React, { useEffect } from "react";
import {
  MAIN_RESOURCE_NAME,
  MAIN_TABLE_SIZE,
  bulkActionsColumnDefinition,
  parseStatus,
} from "./config";
import { useCollection } from "@amzn/awsui-collection-hooks";
import BulkActionsTableHeader from "./TableHeader";
import { Pagination, Table } from "@amzn/awsui-components-react";
import TableEmptyState from "../../common/TableEmptyState";
import TableNoMatchState from "../../common/TableNoMatchState";
import PropTypes from "prop-types";
import { getBulkActions } from "../../../util/services/data/InternalDataService";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { nanoid } from "nanoid";
import ExportToCSV from "./ExportToCSV";
import { dateComparator } from "shared/util/common/helper";

const parseCount = (count) => {
  return count ? parseInt(count) : 0;
};

const BulkActionsTable = ({
  setNotificationsItems,
  setIsDownloadTemplateComponentVisible,
  setNewBulkActionVisible,
}) => {
  const parseBulkAction = (bulk_action) => {
    const success_count = parseCount(bulk_action.successCount);
    const pending_count = parseCount(bulk_action.pendingCount);
    const failed_count = parseCount(bulk_action.failedCount);
    const total_count = parseCount(bulk_action.totalCount);

    const submitted_at_date = new Date(bulk_action.submittedAt * 1000);

    let exportResult = null;
    if (pending_count == 0) {
      exportResult = (
        <ExportToCSV
          action={bulk_action.action}
          task_id={bulk_action.taskId}
          filename={bulk_action.fileName}
          setNotificationsItems={setNotificationsItems}
        />
      );
    }

    return {
      task_id: bulk_action.taskId,
      success_count: success_count,
      pending_count: pending_count,
      failed_count: failed_count,
      total_count: total_count,
      file_name: bulk_action.fileName,
      submitted_at: submitted_at_date,
      action: bulk_action.action,
      status: parseStatus(pending_count),
      export_result: exportResult,
    };
  };

  const fetchBulkActions = async () => {
    let nextPageToken = "";
    let allBulkActions = [];
    while (nextPageToken != null) {
      try {
        const response = await getBulkActions({ nextPageToken: nextPageToken });
        const newBulkActions = response.bulkActions.map((bulk_action) =>
          parseBulkAction(bulk_action)
        );
        allBulkActions = [...allBulkActions, ...newBulkActions];
        nextPageToken = response.newPageAction;
      } catch {
        throw new Error("Failed to get bulk actions");
      }
    }
    return allBulkActions;
  };

  const queryClient = useQueryClient();
  const queryResult = useQuery({
    queryKey: ["getBulkActions"],
    queryFn: () => fetchBulkActions(),
    refetchInterval: 10000,
  });

  const isLoading = queryResult.isLoading;
  const isError = queryResult.isError;
  const bulkActions = queryResult?.data || [];

  useEffect(() => {
    if (isError) {
      setNotificationsItems([
        {
          type: "error",
          dismissible: true,
          dismissLabel: "Dismiss message",
          onDismiss: () => setNotificationsItems([]),
          header: "Error",
          content: "Failed to load bulk actions",
          id: nanoid(10),
        },
      ]);
    }
  }, [isError]);

  const { items, actions, collectionProps, paginationProps } = useCollection(
    bulkActions,
    {
      filtering: {
        empty: <TableEmptyState resourceName={MAIN_RESOURCE_NAME} />,
        noMatch: (
          <TableNoMatchState onClearFilter={() => actions.setFiltering("")} />
        ),
      },
      pagination: { pageSize: MAIN_TABLE_SIZE },
      sorting: {
        defaultState: {
          sortingColumn: {
            sortingField: "submitted_at",
            sortingComparator: (e1, e2) =>
              dateComparator(e1.submitted_at, e2.submitted_at),
          },
          isDescending: true,
        },
      },
    }
  );

  return (
    <Table
      {...collectionProps}
      loading={isLoading}
      loadingText="Loading Bulk Actions"
      items={items}
      columnDefinitions={bulkActionsColumnDefinition}
      header={
        <BulkActionsTableHeader
          itemsLength={items.length}
          allItemsLength={bulkActions.length}
          refresh={() =>
            queryClient.invalidateQueries({ queryKey: ["getBulkActions"] })
          }
          setIsDownloadTemplateComponentVisible={
            setIsDownloadTemplateComponentVisible
          }
          setNewBulkActionVisible={setNewBulkActionVisible}
        />
      }
      pagination={<Pagination {...paginationProps} />}
    />
  );
};

BulkActionsTable.propTypes = {
  setNotificationsItems: PropTypes.func,
  setIsDownloadTemplateComponentVisible: PropTypes.func,
  setNewBulkActionVisible: PropTypes.func,
};
export default BulkActionsTable;
