import React from "react";
import StatusIndicator from "@amzn/awsui-components-react/polaris/status-indicator";
import { STAGE_NAME } from "../StatusBar/util";

const getStageProgressBadge = (fundingStage, targetStage, validStages) => {
  const fundingStageIndex = validStages.indexOf(fundingStage);
  const targetStageIndex = validStages.indexOf(targetStage);
  if (targetStageIndex < fundingStageIndex) {
    if (targetStage === STAGE_NAME.created.toLowerCase()) {
      return <StatusIndicator>{STAGE_NAME.created}</StatusIndicator>;
    }
    return <StatusIndicator>Completed</StatusIndicator>;
  }
  if (targetStageIndex === fundingStageIndex) {
    if (targetStage === STAGE_NAME.completed.toLowerCase()) {
      return <StatusIndicator>{STAGE_NAME.completed}</StatusIndicator>;
    }
    return <StatusIndicator type="in-progress">In-progress</StatusIndicator>;
  }
  if (targetStageIndex > fundingStageIndex) {
    return <StatusIndicator type="pending">Pending</StatusIndicator>;
  }
};

export const getTableProgress = (fundingStage, validStages) => {
  let tableItems = [];
  let stageProgressItems = {};
  for (let stage of validStages) {
    stageProgressItems[stage] = getStageProgressBadge(
      fundingStage,
      stage,
      validStages
    );
  }
  tableItems.push(stageProgressItems);
  return tableItems;
};

export const getColumnDefinitions = (validStages) => {
  return validStages.map((stage) => ({
    id: stage,
    header: STAGE_NAME[stage],
    cell: (item) => item[stage],
  }));
};
