import React, { useEffect } from "react";
import { useHistory, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import qs from "querystring";
import {
  login,
  CLIENT_ID,
  REDIRECT_URI,
  COGNITO_OAUTH_ENDPOINT,
} from "../../../util/services/auth/AuthService";
import Loading from "../../../../shared/components/common/Loading";
import SessionManager from "../../../util/services/auth/SessionManager";

const Auth = () => {
  const history = useHistory();
  useEffect(async () => {
    const authenticate = async () => {
      let responseUrlCode = document.location.search;
      const authCode = responseUrlCode.split("=")[1];

      if (authCode) {
        const tokensReqBody = {
          grant_type: "authorization_code",
          code: authCode,
          client_id: CLIENT_ID,
          redirect_uri: REDIRECT_URI,
        };

        const headers = { "Content-Type": "application/x-www-form-urlencoded" };

        try {
          const response = await fetch(`${COGNITO_OAUTH_ENDPOINT}/token`, {
            method: "POST",
            headers,
            body: qs.stringify(tokensReqBody),
          });

          if (response.ok) {
            const tokenPayload = await response.json();
            const expires_in = tokenPayload.expires_in
              ? parseInt(tokenPayload.expires_in) / 60
              : 60;

            const expireDate = new Date();
            expireDate.setTime(
              expireDate.setMinutes(expireDate.getMinutes() + expires_in)
            );

            const id_token = tokenPayload.id_token;
            const access_token = tokenPayload.access_token;
            const refresh_token = tokenPayload.refresh_token;

            login(
              id_token,
              access_token,
              refresh_token,
              expireDate.toUTCString()
            );

            const originUrl = SessionManager.getOriginUrl();
            SessionManager.removeOriginUrl();
            window.location.href = originUrl || "/dashboard";
          }
        } catch (error) {
          console.error(error);
        }
      } else {
        console.error("Authentication failed, redirecting user to error page");
        history.push("/error");
      }
    };
    await authenticate();
  }, []);

  return <Loading />;
};

export default withRouter(Auth);

Auth.propTypes = {
  history: PropTypes.object.isRequired,
};
