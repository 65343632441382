import addFormats from "ajv-formats";

const Ajv = require("ajv").default;
const errors = require("ajv-errors");

export const createValidator = (schemas) => {
  const ajv = new Ajv({
    allErrors: true,
  });
  ajv.addKeyword({
    keyword: "tags",
  });
  addFormats(ajv);
  errors(ajv);
  for (let schema of schemas) {
    ajv.getSchema(schema["$id"]) || ajv.compile(schema);
  }
  return ajv;
};
