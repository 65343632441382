import React from "react";
import { Header } from "@amzn/awsui-components-react";
import PropTypes from "prop-types";
import Button from "@amzn/awsui-components-react/polaris/button";

export const HeaderLinkButton = ({
  history,
  condition,
  destinationPath,
  buttonName,
  headerName,
}) => {
  return (
    <React.Fragment>
      <Header
        actions={
          condition && (
            <Button
              onClick={() => {
                history.push(destinationPath);
              }}
            >
              {buttonName}
            </Button>
          )
        }
      >
        {headerName}
      </Header>
    </React.Fragment>
  );
};

HeaderLinkButton.propTypes = {
  history: PropTypes.any,
  condition: PropTypes.bool,
  destinationPath: PropTypes.string,
  buttonName: PropTypes.string,
  headerName: PropTypes.string,
};
