import { SECTION } from "../../../../shared/util/constants/section";
import CashClaimEditSectionContainer from "./CashClaimEditSectionContainer";
import CashRequestEditSectionContainer from "./CashRequestEditSectionContainer";
import DefaultEditSectionContainer from "./DefaultEditSectionContainer";
import MigrationDetailsEditSectionContainer from "./MigrationDetailsEditSectionContainer";
import ProjectInformationEditSectionContainer from "./ProjectInformationEditSectionContainer";
import MigrationEngagementEditSectionContainer from "./MigrationEngagementEditSectionContainer";

export const EditSectionContainerFactory = ({ sectionTitle }) => {
  switch (sectionTitle) {
    case SECTION.PROJECT_INFORMATION:
      return ProjectInformationEditSectionContainer;
    case SECTION.MIGRATION_DETAILS:
      return MigrationDetailsEditSectionContainer;
    case SECTION.CASH_CLAIM_ACTUALS:
      return CashRequestEditSectionContainer;
    case SECTION.CASH_REQUEST_INFORMATION:
      return CashRequestEditSectionContainer;
    case SECTION.CASH_CLAIM_INVOICE_PAYMENT:
      return CashClaimEditSectionContainer;
    case SECTION.MIGRATION_PROGRAM_ENGAGEMENTS:
      return MigrationEngagementEditSectionContainer;
    default:
      return DefaultEditSectionContainer;
  }
};
