import React from "react";

import { useLocation, useParams } from "react-router-dom";
import { updateTemplateInformation } from "../../util/services/data/InternalDataService";
import EditProgramSelectContainer from "../../../shared/components/FundRequest/Edit/EditProgramSelectContainer";

const ProgramSelectEditPage = () => {
  const { state } = useLocation();
  const { id } = useParams();

  const successfulSubmitRedirectUrl =
    state && state.origin ? `/${state.origin}/${id}/review` : `/dashboard`;

  return (
    <EditProgramSelectContainer
      updateTemplateInformation={updateTemplateInformation}
      successfulSubmitRedirectUrl={successfulSubmitRedirectUrl}
    />
  );
};

export default ProgramSelectEditPage;
