import { IGenericObject } from "shared/programs/migration-acceleration-program/2024/fund-request/types/CommonTypes";

export interface DetailsActionProps {
  loading: boolean;
  data?: IGenericObject;
}

export enum AudienceType {
  Internal = "Internal",
  External = "External",
}

export type BaseDetailsAction = {
  [key in AudienceType]: string;
};
