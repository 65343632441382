import { Icon, Box } from "@amzn/awsui-components-react";
import React from "react";
import { getBulkActionLabelByValue } from "../config";
import { dateComparator } from "shared/util/common/helper";

export const MAIN_TABLE_SIZE = 10;
export const MAIN_RESOURCE_NAME = "Bulk Actions";

const BulkActionsStatus = Object.freeze({
  pending: "PENDING",
  completed: "COMPLETED",
});

export const parseStatus = (pending_count) => {
  return pending_count == 0
    ? BulkActionsStatus.completed
    : BulkActionsStatus.pending;
};

const getPrettyStatus = (status) => {
  if (status === BulkActionsStatus.completed) {
    return (
      <div>
        <Icon name="status-positive" size="normal" variant="success" />{" "}
        <Box color="text-status-success" display="inline-block">
          {status}
        </Box>
      </div>
    );
  } else {
    return (
      <div>
        <Icon name="status-pending" size="normal" variant="link" />{" "}
        <Box color="text-status-info" display="inline-block">
          {status}
        </Box>
      </div>
    );
  }
};

const formatDate = (date) => {
  // m/d/y hr:min
  let hours = date.getUTCHours();
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // hour 0 is 12
  let minutes = date.getUTCMinutes();
  minutes = minutes < 10 ? "0" + minutes : minutes;

  const strTime = `${hours}:${minutes} ${ampm}`;

  const month = date.getUTCMonth() + 1; // range is [0,11]
  const day = date.getUTCDate();
  const year = date.getUTCFullYear();
  const strDate = `${month}/${day}/${year}`;
  return `${strDate} ${strTime}`;
};

export const bulkActionsColumnDefinition = [
  {
    id: "export_result",
    header: "Export Result",
    cell: (e) => e.export_result,
  },
  {
    id: "task_id",
    header: "Task ID",
    cell: (e) => e.task_id,
    sortingField: "task_id",
  },
  {
    id: "action",
    header: "Action",
    cell: (e) => getBulkActionLabelByValue(e.action),
    sortingField: "action",
  },
  {
    id: "submitted_at",
    header: "Submitted At (UTC)",
    cell: (e) => formatDate(e.submitted_at),
    sortingField: "submitted_at",
    sortingComparator: (e1, e2) =>
      dateComparator(e1.submitted_at, e2.submitted_at),
  },
  {
    id: "file_name",
    header: "File Name",
    cell: (e) => e.file_name,
    sortingField: "file_name",
  },
  {
    id: "status",
    header: "Status",
    cell: (e) => getPrettyStatus(e.status),
    sortingField: "status",
  },
  {
    id: "total_count",
    header: "Total",
    cell: (e) => e.total_count,
  },
  {
    id: "success_count",
    header: "Successful",
    cell: (e) => e.success_count,
  },
  {
    id: "failed_count",
    header: "Unsuccessful",
    cell: (e) => e.failed_count,
  },
  {
    id: "pending_count",
    header: "Pending",
    cell: (e) => e.pending_count,
  },
];
