import React, { useContext, useState } from "react";
import { DetailsActionProps } from "internal/programs/migration-acceleration-program/2024/fund-request/components/details/header/actions/DetailsActionsTypes";
import { Button } from "@amzn/awsui-components-react/polaris";
import RejectCommentModal from "internal/components/common/CommentModal/RejectCommentModal";
import { INTERNAL_REVIEW_ACTION_TYPE } from "internal/components/common/CommentModal/reviewActionType";
import { hasError, retryApiCall } from "shared/util/services/data/DataService";
import { NotificationsContext } from "shared/util/context/notifications/NotificationsContext";
import { IGenericObject } from "shared/programs/migration-acceleration-program/2024/fund-request/types/CommonTypes";
import { useHistory } from "react-router-dom";
import { RouteOrigin } from "shared/util/constants/RouteState";
import { rejectCashClaimActual } from "internal/util/services/data/InternalDataService";
import { getFundRequestId } from "internal/util/helper";

interface ClaimRejectActionProps extends DetailsActionProps {
  claimId: string;
}

const RejectAction = ({ data, claimId, loading }: ClaimRejectActionProps) => {
  const stage = data?.stage;
  const history = useHistory();
  const { addNotification } = useContext(NotificationsContext);

  const [isVisible, setIsVisible] = useState<boolean>();

  const rejectClaim = async (payload: IGenericObject) => {
    try {
      // @ts-expect-error Original js code.
      const response = await retryApiCall({
        callApi: rejectCashClaimActual(
          {
            fundRequestId: getFundRequestId(),
            comment: payload["externalComment"],
            internalComment: payload["internalComment"],
            reasonCodes: payload["reasonCodes"],
          },
          claimId
        ),
      });

      if (hasError(response)) {
        addNotification({
          content: response.message,
          type: "error",
        });
        return;
      }

      history.push("/dashboard", {
        origin: RouteOrigin.DetailsAction,
        message: `${claimId} has been successfully rejected.`,
      });
    } catch (e) {
      console.error(e);
      addNotification({
        content: "An error has occurred, try again later.",
        type: "error",
      });
    } finally {
      updateVisibility(INTERNAL_REVIEW_ACTION_TYPE.REJECT, false);
    }
  };

  const updateVisibility = (key: string, visibility: boolean) => {
    setIsVisible(visibility);
  };

  return (
    <>
      <Button
        loading={loading}
        onClick={() => {
          setIsVisible(!isVisible);
        }}
      >
        Reject
      </Button>
      <RejectCommentModal
        reviewActionType={"reject"}
        reviewAction={rejectClaim}
        isLoading={loading}
        isVisible={isVisible}
        updateVisibility={updateVisibility}
        stage={stage}
      />
    </>
  );
};

export default RejectAction;
