import React, { useState } from "react";
import { queryToString } from "../util";
import {
  Box,
  FormField,
  Input,
  SpaceBetween,
} from "@amzn/awsui-components-react";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import Button from "@amzn/awsui-components-react/polaris/button";
import PropTypes from "prop-types";

const SaveFilterSetModal = ({
  filterSets,
  query,
  filteringProperties,
  onCancel,
  onSubmit,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(true);
  const [inputs, setInputs] = useState({});
  const [errors, setErrors] = useState({});

  const inputsValidation = {
    limit: 50,
    message: "maximum characters limit is 50",
  };
  const onDismiss = () => {
    setInputs({});
    setErrors({});
    setIsModalVisible(false);
    onCancel();
  };

  const validateName = (nameValue) => {
    if (nameValue?.length > inputsValidation.limit) {
      setErrors({
        ...errors,
        name: inputsValidation.message,
      });
    } else {
      setErrors({ ...errors, name: "" });
      setInputs({ ...inputs, name: nameValue });
    }
  };

  const validateDescription = (descriptionValue) => {
    if (descriptionValue?.length > inputsValidation.limit) {
      setErrors({
        ...errors,
        description: inputsValidation.message,
      });
    } else {
      setErrors({ ...errors, description: "" });
      setInputs({ ...inputs, description: descriptionValue });
    }
  };

  const validateInputs = () => {
    if (!inputs?.name) {
      setErrors({ ...errors, name: "You must specify a valid name." });
      return false;
    }

    const nameValueExists = filterSets?.find(
      (filterSet) => filterSet.name === inputs?.name
    );
    if (nameValueExists) {
      setErrors({
        ...errors,
        name: "Filter name already exists in your saved filter set, please provide different name.",
      });
      return false;
    }
    return true;
  };

  const submitModal = async () => {
    const hasValidInputs = validateInputs();
    if (!hasValidInputs) {
      return;
    }
    onSubmit(inputs?.name, inputs?.description);
    setIsModalVisible(false);
  };

  return (
    <Modal
      header="Save filter set"
      visible={isModalVisible}
      onDismiss={onDismiss}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={onDismiss}>
              Cancel
            </Button>
            <Button
              variant="primary"
              data-testid="submit"
              onClick={submitModal}
            >
              Save
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <SpaceBetween size="m">
        <Box variant="span">
          Save this custom filter set to easily reproduce this view again later.
        </Box>
        <FormField
          label="Filter set name"
          constraintText={`${inputs.name ? inputs.name.length : "0"}/${
            inputsValidation.limit
          }`}
          errorText={errors.name}
        >
          <Input
            value={inputs.name}
            onChange={({ detail }) => validateName(detail.value)}
          />
        </FormField>
        <FormField
          label={
            <span>
              Filter set description <i>- optional</i>
            </span>
          }
          constraintText={`${
            inputs.description ? inputs.description.length : "0"
          }/${inputsValidation.limit}`}
          errorText={errors.description}
        >
          <Input
            value={inputs.description}
            onChange={({ detail }) => validateDescription(detail.value)}
          />
        </FormField>
        <div>
          <Box variant="awsui-key-label">Filter values</Box>
          <div>{queryToString(query, filteringProperties)}</div>
        </div>
      </SpaceBetween>
    </Modal>
  );
};

SaveFilterSetModal.propTypes = {
  filterSets: PropTypes.array,
  query: PropTypes.object,
  filteringProperties: PropTypes.array,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default SaveFilterSetModal;
