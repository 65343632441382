import Tiles from "@amzn/awsui-components-react/polaris/tiles";
import React from "react";
import PropTypes from "prop-types";

const DefaultSubProgramContainer = ({
  programOptions,
  programValue,
  setProgramValue,
  updateFundingType,
}) => {
  return (
    <Tiles
      className="program-select-tiles-1-item"
      onChange={({ detail }) => {
        const newProgramValue = detail.value;
        setProgramValue(newProgramValue);
        updateFundingType({ programValue: newProgramValue });
      }}
      value={programValue}
      items={programOptions}
    />
  );
};

DefaultSubProgramContainer.propTypes = {
  programOptions: PropTypes.array,
  programValue: PropTypes.string,
  setProgramValue: PropTypes.func,
  updateFundingType: PropTypes.func,
};
export default DefaultSubProgramContainer;
