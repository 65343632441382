import React, { ReactNode, useEffect } from "react";
import { ContentLayout } from "@amzn/awsui-components-react";
import Container from "@amzn/awsui-components-react/polaris/container";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import { projectDetailsSchema, vmwareProjectDetailsSchema } from "./schema";
import Header from "@amzn/awsui-components-react/polaris/header";
import { IStepStateProps } from "../../../../../types/WizardTypes";
import InputSectionFieldsFactory from "external/programs/migration-acceleration-program/2024/fund-request/components/create/wizard/Steps/InputSectionFieldsFactory";
import { fetchFundRequest } from "external/programs/migration-acceleration-program/2024/fund-request/components/create/wizard/util";
import { useParams } from "react-router-dom";
import SystemErrorAlert from "shared/programs/migration-acceleration-program/2024/fund-request/components/common/SystemErrorAlert";
import { PublicSector } from "external/programs/migration-acceleration-program/2024/fund-request/components/create/wizard/Steps/ProjectDetails/PublicSector";
import { IGenericObject } from "shared/programs/migration-acceleration-program/2024/fund-request/types/CommonTypes";
import WizardReadOnlyMigrationEligibility from "external/programs/migration-acceleration-program/2024/fund-request/components/create/wizard/Steps/MigrationEligibility/WizardReadOnlyMigrationEligibility";
import { HelpPanelContentContext } from "shared/util/context/help/HelpContext";
import InfoLink from "shared/components/InfoLink";
import useFeatureFlag from "shared/util/hooks/useFeatureFlag";
import { FEATURE_FLAG_ENUMS } from "shared/util/constants/featureFlagValues";
import { MAP2024_MIGRATION_PHASE } from "../../../eligibility/util";

interface IProjectDetails extends IStepStateProps {
  children?: ReactNode;
}

const ProjectDetails = ({
  adapt,
  data,
  errors,
  onError,
  formError,
  onChange,
  setData,
}: IProjectDetails) => {
  const { id } = useParams<IGenericObject>();
  const { setKey, setHelpPanelContent } = React.useContext(
    HelpPanelContentContext
  );
  const { migrationPhase } = data;
  const isVMwareMapEnabled = useFeatureFlag(
    FEATURE_FLAG_ENUMS.VMWARE_MAP_ENABLED
  );

  useEffect(() => {
    setKey("wizard.projectDetails.page");
    fetchFundRequest({ id, adapt, setData });
  }, []);

  return (
    <ContentLayout>
      <SpaceBetween size="l">
        <Container
          header={
            <Header
              variant="h2"
              info={
                <InfoLink
                  onFollow={() => {
                    setHelpPanelContent(
                      "wizard.projectDetails.container.awsAccountId"
                    );
                  }}
                />
              }
            >
              Project details
            </Header>
          }
        >
          <InputSectionFieldsFactory
            data={data}
            errors={errors}
            onChange={onChange}
            onError={onError}
            schema={
              isVMwareMapEnabled &&
              migrationPhase === MAP2024_MIGRATION_PHASE.MOBILIZE
                ? vmwareProjectDetailsSchema
                : projectDetailsSchema
            }
          />
        </Container>
        <WizardReadOnlyMigrationEligibility data={data} />
        <PublicSector data={data} onChange={onChange} />
        <SystemErrorAlert isVisible={!!formError} error={formError} />
      </SpaceBetween>
    </ContentLayout>
  );
};

export default ProjectDetails;
