import Config from "config";
import {
  STAGE,
  STATUS,
} from "../../../shared/util/constants/fundRequestStatusType";
import { getRoles } from "../../../shared/util/services/auth/AuthService";
import { ALLIANCE_LEAD } from "../../config/roles";

export const createSecondGranularityTimestamp = () =>
  new Date().getTime() / 1000;

export const getFundRequestId = () => {
  return document.location.pathname.split("/")[2];
};

export const getSfdcLandingPageLink = () =>
  `${Config.SALESFORCE_URL}/apex/APFPFundingLandingPage`;

export const isAllianceLead = () => getRoles().includes(ALLIANCE_LEAD);

export const disableButton = ({ stage, status }) =>
  status === STATUS.CANCELLED ||
  status === STATUS.EXPIRED ||
  status === STATUS.TERMINATED ||
  status === STATUS.DELETED ||
  stage === STAGE.CREATED ||
  stage === STAGE.COMPLETED;
