import React, { useState } from "react";

import DetailsActionWithModal from "shared/programs/migration-acceleration-program/2024/fund-request/components/modal/DetailsActionWithModal";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import { FormField } from "@amzn/awsui-components-react";
import Textarea from "@amzn/awsui-components-react/polaris/textarea";
import { BaseChangeDetail } from "@amzn/awsui-components-react/polaris/input/interfaces";
import { getFundRequestId } from "internal/util/helper";
import { approveCashClaimActual } from "internal/util/services/data/InternalDataService";
import { hasError } from "shared/util/services/data/DataService";

import { useHistory } from "react-router-dom";
import { RouteOrigin } from "shared/util/constants/RouteState";
import {
  AudienceType,
  BaseDetailsAction,
  DetailsActionProps,
} from "internal/programs/migration-acceleration-program/2024/fund-request/components/details/header/actions/DetailsActionsTypes";
import { FormError, Result, Success } from "shared/util/api/util";

interface ClaimApproveActionProps extends DetailsActionProps {
  claimId: string;
}

const DEFAULT_ACTION_STATE = {
  [AudienceType.Internal]: "",
  [AudienceType.External]: "",
};

const createPayload = ({
  internal,
  external,
}: {
  internal: string;
  external: string;
}) => {
  return {
    internalComment: internal,
    comment: external,
  };
};

const ClaimApproveAction = ({ claimId, loading }: ClaimApproveActionProps) => {
  const history = useHistory();
  const [comments, setComments] =
    useState<BaseDetailsAction>(DEFAULT_ACTION_STATE);

  const [errors, setErrors] = useState(DEFAULT_ACTION_STATE);

  const submit = async () => {
    const submit = approveCashClaimActual;
    const payload = createPayload({
      internal: comments[AudienceType.Internal],
      external: comments[AudienceType.External],
    });

    console.table([payload, submit]);

    if (!submit) {
      return new Result<FormError>({
        success: false,
        payload: new FormError("Unable to submit approval."),
      });
    }

    const response: Record<string, any> = await submit(
      {
        ...payload,
        fundRequestId: getFundRequestId(),
      },
      claimId
    );

    if (hasError(response)) {
      return new Result<FormError>({
        success: false,
        payload: new FormError(
          response.message || "Failed to submit claim approval successfully."
        ),
      });
    }

    resetModalState();
    history.push("/dashboard", {
      origin: RouteOrigin.DetailsAction,
      message: `${claimId} has been successfully approved.`,
    });
    return new Result<Success>({});
  };

  const resetModalState = () => {
    setErrors(DEFAULT_ACTION_STATE);
    setComments(DEFAULT_ACTION_STATE);
  };

  const onChange = ({
    detail,
    audience,
  }: {
    detail: BaseChangeDetail;
    audience: AudienceType;
  }) => {
    if (errors[audience]) setErrors(DEFAULT_ACTION_STATE);
    setComments({
      ...comments,
      [audience]: detail.value,
    });
  };

  return (
    <DetailsActionWithModal
      isLoading={loading}
      submit={submit}
      cancel={resetModalState}
      labels={{ actionButton: "Approve", title: "Approve cash claim" }}
    >
      <SpaceBetween size="l">
        <FormField
          label="Internal comment"
          errorText={errors[AudienceType.Internal]}
        >
          <Textarea
            value={comments[AudienceType.Internal]}
            placeholder="Enter an internal comment"
            onChange={({ detail }) => {
              onChange({ detail, audience: AudienceType.Internal });
            }}
          />
        </FormField>
        <FormField
          label="Partner comment"
          description="This comment will be visible by the partner."
          errorText={errors[AudienceType.External]}
        >
          <Textarea
            value={comments[AudienceType.External]}
            placeholder="Enter a comment for the partner."
            onChange={({ detail }) => {
              onChange({ detail, audience: AudienceType.External });
            }}
          />
        </FormField>
      </SpaceBetween>
    </DetailsActionWithModal>
  );
};

export default ClaimApproveAction;
