import { getData } from "shared/util/services/data/DataService";
import Config from "config";
import { QueryClient } from "@tanstack/react-query";

const API_V1_END_POINT = `${Config.FUNDING_ENDPOINT}/v1`;
const defaultQueryFn = async ({ queryKey }) =>
  await getData({ endpoint: `${API_V1_END_POINT}/${queryKey.join("/")}` });

export const APFPv1QueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: defaultQueryFn,
      staleTime: 60 * 1000, // 1 minute stale time,
    },
  },
});
