import { ColumnLayout } from "@amzn/awsui-components-react";
import React from "react";
import Container from "@amzn/awsui-components-react/polaris/container";
import Header from "@amzn/awsui-components-react/polaris/header";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import ValueWithLabel from "shared/programs/migration-acceleration-program/2024/fund-request/components/fields/ValueWithLabel";
import InfoLink from "shared/components/InfoLink";
import { IReadOnlyComponentProps } from "../../../../../types/SchemaTypes";
import { display } from "shared/util/common/display";
import { HelpPanelContentContext } from "shared/util/context/help/HelpContext";
import ConditionalField from "shared/components/common/Conditional/ConditionalField";

const ReadOnlyFundingDetails = ({
  data,
  actions = <></>,
}: IReadOnlyComponentProps) => {
  const { setHelpPanelContent } = React.useContext(HelpPanelContentContext);
  const fundingDetails = data && data["fundingDetails"];
  const localCurrency = fundingDetails && fundingDetails.currency;

  return (
    <Container
      header={
        <Header
          variant="h2"
          info={
            <InfoLink
              onFollow={() => {
                setHelpPanelContent("wizard.fundingDetails.page");
              }}
            />
          }
          actions={actions}
        >
          Cash request details
        </Header>
      }
    >
      <ColumnLayout columns={3} variant="text-grid">
        <SpaceBetween size="l">
          <ValueWithLabel label="Currency">
            {display({ data: fundingDetails, key: "currency" })}
          </ValueWithLabel>
          <ValueWithLabel label="Invoice entity name">
            {display({ data: fundingDetails, key: "invoiceEntityName" })}
          </ValueWithLabel>
          <ValueWithLabel label="Apartment / suite / unit">
            {display({ data: fundingDetails, key: "invoiceRemitUnit" })}
          </ValueWithLabel>
          <ValueWithLabel label="Postal code">
            {display({ data: fundingDetails, key: "invoiceRemitPostalCode" })}
          </ValueWithLabel>
          <ConditionalField showField={localCurrency !== "USD"}>
            <ValueWithLabel label="USD Conversion Rate">
              {display({
                data: fundingDetails,
                key: "currencyConversionRateToUsd",
              })}
            </ValueWithLabel>
          </ConditionalField>
        </SpaceBetween>
        <SpaceBetween size="l">
          <ValueWithLabel label={`Total project cost (${localCurrency})`}>
            {display({
              data: fundingDetails,
              key: "totalCostOfActivity",
            })}
          </ValueWithLabel>
          <ValueWithLabel label="Invoice remit country">
            {display({ data: fundingDetails, key: "invoiceRemitCountry" })}
          </ValueWithLabel>
          <ValueWithLabel label="City">
            {display({ data: fundingDetails, key: "invoiceRemitCity" })}
          </ValueWithLabel>
          <ValueWithLabel label="PO number">
            {display({ data: fundingDetails, key: "poNumber" })}
          </ValueWithLabel>
        </SpaceBetween>
        <SpaceBetween size="l">
          <ValueWithLabel
            label={`Total funds requested from AWS (${localCurrency})`}
          >
            {display({
              data: fundingDetails,
              key: "cashFundingAmount",
            })}
          </ValueWithLabel>
          <ValueWithLabel label="Street address">
            {display({ data: fundingDetails, key: "invoiceRemitStreet" })}
          </ValueWithLabel>
          <ValueWithLabel label="State / region / province">
            {display({ data: fundingDetails, key: "invoiceRemitState" })}
          </ValueWithLabel>
          <ValueWithLabel label={`Approved cash amount (${localCurrency})`}>
            {display({
              data: fundingDetails,
              key: "approvedCashLocalAmount",
            })}
          </ValueWithLabel>
        </SpaceBetween>
      </ColumnLayout>
    </Container>
  );
};

export default ReadOnlyFundingDetails;
