import React from "react";
import {
  ColumnLayout,
  Container,
  CopyToClipboard,
  Header,
  SpaceBetween,
} from "@amzn/awsui-components-react";
import { IContainerProps } from "shared/programs/migration-acceleration-program/2024/fund-request/types/ContainerTypes";
import ValueWithLabel from "shared/programs/migration-acceleration-program/2024/fund-request/components/fields/ValueWithLabel";
import { display } from "shared/util/common/display";
import { longLocalDateFormat } from "shared/util/common/date";

const CashClaimPlans = ({ data }: IContainerProps) => {
  return (
    <Container header={<Header variant="h2">Cash claim plans</Header>}>
      <SpaceBetween size="l">
        <ColumnLayout columns={1} variant="text-grid">
          <ValueWithLabel label="Cash claim ID">
            <CopyToClipboard
              variant="inline"
              textToCopy={display({ data, key: "claimId" }) as string}
              copySuccessText="Copied to clipboard."
              copyErrorText="Failed to copy to clipboard."
            />
          </ValueWithLabel>
          <ValueWithLabel label="Due date">
            {display({
              data,
              key: "claimDueDate",
              format: (value) => longLocalDateFormat(value as string),
            })}
          </ValueWithLabel>
        </ColumnLayout>
      </SpaceBetween>
    </Container>
  );
};

export default CashClaimPlans;
