import React from "react";
import parse from "html-react-parser";
import DOMPurify from "dompurify";
import { SECTION_NAME } from "./section";
import {
  FINANCIAL_APPROVAL_GUIDE_LINK,
  PROGRAM_ENGAGEMENT_NEW_ID_GUIDE_LINK,
} from "./constantValues";
import { getSelfServiceEntityValues } from "../common/selfServiceUtil";

export const helperTextMap = {
  sandboxDesignWinActivity:
    'The term "Design Win" refers to new products on AWS, or a signficant re-architecture of existing products using AWS. This product is launched for production use and must include a statement from the partner saying that is is fully supported on AWS.Please select the design win activity for your Innovation Sandbox Funding. AWS will fund up to 3 months for a new design win activity: either a new product or solution built with AWS. An innovation plan is required to be attached as part of the fund request.',
  majorityPublicSector:
    'The term "Public Sector" refers to specific engagements where end-customers are recognized as a federal, state, or local government agency, an educational or nonprofit institution. Market Development Funding (MDF): by checking this box, you are committing Public Sector MDF cash or Public Sector MDF Credit from your wallet. Please reach out to your Public Sector Partner Development Manager to learn more. Innovation Sandbox: by checking this box, you are committing Public Sector Sandbox Credits. Please reach out to your Public Sector Partner Development Manager to learn more.',
  [SECTION_NAME.OPPORTUNITY_INFORMATION]: (
    <React.Fragment>
      <p>
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        This Funding Benefit requires a Partner-led or involved customer
        opportunity associated with each fund request. Here you will have access
        to a list of validated, open opportunities which meet the funding
        program&apos;s opportunity qualification criteria. Please select a
        qualified opportunity to associate with this fund request. If you do not
        see your opportunity or need access to additional opportunities
        presented here for your organization, please contact your AWS Alliance
        Team or Partner Manager. To learn more about each funding program&apos;s
        opportunity qualification criteria, please read the ACE Opportunity help
        text or review the APN Funding Program Guide.
      </p>
      <p>
        Please note that for Partner MAP Funding, each phase of MAP Fund request
        for a migration opportunity must only have one ACE submission for small
        migrations ( less than $500K ARR).
      </p>
      <p>
        Partners pursuing any POC/MAP/ISV WMP/AWS Jumpstart funding will now
        only be able to select opportunities which meet each program’s
        opportunity qualification criteria:
      </p>
      <ul>
        <li>
          <b>Proof of Concept (POC):</b> Technical Validation+
        </li>
        <li>
          <b>MAP Assess Phase:</b> Technical Validation+
        </li>
        <li>
          <b>MAP Mobilize Phase:</b> Business Validation+
        </li>
        <li>
          <b>MAP Migrate & Modernize Phase:</b> Committed+
        </li>
        <li>
          <b>ISV WMP:</b> Launched
        </li>
        <li>
          <b>AWS Jumpstart:</b> Business Validation+
        </li>
      </ul>
      <p>
        Opportunities with ACE Opportunity stage values of Prospect, Qualified,
        or Closed/Lost will not be selectable for partners.
      </p>
    </React.Fragment>
  ),
  executedState:
    "This field is eligible only if United States is the country where the activity is executed in.",
  workloadName: "Please select the workload relating to this project",
  arn: (
    <React.Fragment>
      <div>
        To locate an ARN, navigate to the Well-Architected Tool in the AWS
        Console and select the workload you are reporting, then click on
        Properties. This will display the ARN for that specific workload.
        <li>
          All ARNs must be in this format:
          arn:aws:wellarchitected:us-east-1:123456789:workload/a012b34567891011c1213d14151617eg
        </li>
      </div>
    </React.Fragment>
  ),
  awsAccountId: (
    <React.Fragment>
      <p>
        A valid AWS Account ID is required for any Credit fund request (Except
        MDF Funding). For these Credit fund requests, AWS will apply the
        approved credits directly into the AWS account ID provided. Please note,
        only one AWS Account ID is supported in each project. Please enter the
        representative payer or linked Account ID for each fund request where
        all the approved credits will be applied to.
      </p>
      <p>
        <h4>Innovation Sandbox:</h4>
        This should be the partner&apos;s AWS Account ID where the
        partner&apos;s development work is taking place.
      </p>
      <p>
        <h4>Market Development Funds (MDF):</h4>
        An AWS Account ID is not required.
      </p>
      <p>
        <h4>Proof-of-Concept (POC):</h4>A valid AWS Account ID is required for
        cash and credit fund requests. This can be either a customer or partner
        AWS Account ID. This should be where the partner&apos;s POC work is
        taking place and where the credits will be applied.
      </p>
      <p>
        <h4>Migration Acceleration Program (MAP):</h4>A valid AWS Account ID is
        required for Migrate & Modernize Phase cash & credit fund requests. For
        Mobilize Phase or Assess Phase fund requests, where an AWS Account ID is
        not required or not known at time of submission, please input 12-zeroes
        (&quot;000000000000&quot;). For MAP Large Migrations greater than $500K
        ARR (USD), the AWS Account ID must be the same account used for MAP
        tagging and the credits will be redeemed directly to end-customer.
      </p>
    </React.Fragment>
  ),
  numberOfClaims:
    'For MDF, Please click the ""Add Another Activity/Milestone"" button if you are requesting a multi-campaign activity. Each activity represents a MDF cash activity type and can be claimed independently. | For MAP,  Please note that milestone payments are only available for large ($500K+ ARR) MAP Migrate & Modernize Phase deals. please click the ""Add Another Activity/Milestone"" button if you are requesting milestone payment.',
  mdfActivityType: (
    <p>
      End Customer Events (in-person and virtual): Execution and promotion of an
      in-person or virtual event that showcases your AWS solution (i.e.
      networking, sporting, roundtables, partner hosted, etc.
      <br />
      <br />
      Customer Focused Webinars: Webinars led by AWS Partners focused
      exclusively on your AWS solutions, or joint events with other vendors that
      demonstrate your AWS solution.
      <br />
      <br />
      Display Advertising and Search Marketing: Advertising support across
      display, paid search, SEO, or social media. <br />
      <br />
      Email Campaign: Templates; Design; Execution (i.e. Marketo Eloqua), Web
      copy; Landing pages; Microsites; Design; Development.
      <br />
      <br /> Industry Conference Event: Participation in a third-party industry
      or technology conference to showcase your AWS solutions.
      <br />
      <br />
      Lead List Purchase: List purchase; List enhancement/enrichment.
      <br />
      <br />
      Customer Event associated with an AWS-led Event: Networking events run by
      AWS Partners focused exclusively on your AWS solutions including promoting
      AWS lead event (re:Invent, Summit, etc.) meetings before, during, or after
      the event; happy hours or receptions; advertising your attendance;
      marketing collateral; swag or giveaway.
      <br />
      <br /> Partner Produced Case Study (Written and/or Video): Customer Case
      study on a completed deployment that showcases your AWS solutions.
      <br />
      <br /> Partner Sales Content: Development and design of partner marketing
      and sales content (i.e. whitepaper, e-book, solution brief, technical
      brief, landing pages, etc.) that showcases your AWS solutions.
      <br />
      <br />
      Partner Sales Kick Off (SKO): AWS Partner event focused on your annual
      sales strategy and go-to-market with AWS.
      <br />
      <br /> Telemarketing Campaigns: Call campaigns and supporting assets (i.e.
      battlecard, sales call script, first call deck, telesales outreach, etc.)
      that showcase your AWS solutions.
      <br />
      <br /> AWS Led Joint Campaign (Invite Only): AWS Led Joint Campaign
      focusing on highlighting value that AWS and a select set of Technology and
      Consulting Competency Partners provide to customers.
      <br />
      <br />
      Gifts/Giveaways/Swag: Logo items specifically associated with a MDF
      activity or AWS marketing campaign.
      <br />
      <br />
      Social/Recreational Events: Social and recreational costs for training or
      marketing events that showcase your AWS solutions. AWS provided
      opportunity to participate, provide training and materials.
      <br />
      <br /> Video Conferencing: Cost to host video conference services (ie.
      Zoom, GoToMeeting, WebEx) for events that showcase your AWS solutions.
      <br />
      <br />
      Catering: Catering of food, beverages and gratuities associated with an
      event that showcases your AWS solutions. Food vouchers for virtual events.
      (ie Doordash, Grubhub, Postmates etc.)
    </p>
  ),
  percentageCompletion:
    "Large Migrate & Modernize Partner Cash is available in milestones that are tied to the percentage of work completed. AWS recommends 3-6 milestones per migration project.",
  expectedRevenueRamp:
    "For Large Migrate & Modernize Cash deals, the AWS Partner will need to work with the customer to complete the Migration Tagging. Cash payment will be available in milestones that are tied to the percentage of work completed. The milestone schedule must include an estimate of the post-migration ARR and expected quarterly revenue ramp that is aligned to timing of milestone cash payout. Upon submission of a milestone claim, AWS will validate the customer revenue against the customer revenue ramp estimate submitted for funding pre-approval. Please refer to the Migrate & Modernize Project Plan template or APN Partner Funding Benefits Guide for further guidance on the Migrate & Modernize milestone schedule.",
  totalCostOfMdfActivity:
    "Please only enter the cash amount of your activity. If you need credit benefit support, please enter it under the Credit Code section below. If there is no cash amount in your activity, please enter 0.",
  attachment: (
    <>
      <p>
        For the MDF program, please attach all 3rd party receipts relating to
        the cost of this activity. For the Migration Funding program, please
        attach the customer sign-off document.
      </p>
      <p>
        For MAP request with Connect workloads, complete and attach the{" "}
        <a href="https://partnercentral.awspartner.com/partnercentral2/s/resources?Id=0698W00000lzFMCQA2">
          Migration Complexity Assessment Questionnaire
        </a>{" "}
        to validate the customers ability to deploy $500K+ ARR in Connect
        workloads within the first 12 months of the agreement.
      </p>
    </>
  ),
  mdfCreditActivityType: (
    <p>
      End Customer Events (in-person and virtual): Execution and promotion of an
      in-person or virtual event that showcases your AWS solution (i.e.
      networking, sporting, roundtables, partner hosted, etc.
      <br />
      <br />
      Customer Focused Webinars: Webinars led by AWS Partners focused
      exclusively on your AWS solutions, or joint events with other vendors that
      demonstrate your AWS solution.
      <br />
      <br />
      Display Advertising and Search Marketing: Advertising support across
      display, paid search, SEO, or social media. <br />
      <br />
      Email Campaign: Templates; Design; Execution (i.e. Marketo Eloqua), Web
      copy; Landing pages; Microsites; Design; Development.
      <br />
      <br /> Industry Conference Event: Participation in a third-party industry
      or technology conference to showcase your AWS solutions.
      <br />
      <br />
      Lead List Purchase: List purchase; List enhancement/enrichment.
      <br />
      <br />
      Customer Event associated with an AWS-led Event: Networking events run by
      AWS Partners focused exclusively on your AWS solutions including promoting
      AWS lead event (re:Invent, Summit, etc.) meetings before, during, or after
      the event; happy hours or receptions; advertising your attendance;
      marketing collateral; swag or giveaway.
      <br />
      <br /> Partner Produced Case Study (Written and/or Video): Customer Case
      study on a completed deployment that showcases your AWS solutions.
      <br />
      <br /> Partner Sales Content: Development and design of partner marketing
      and sales content (i.e. whitepaper, e-book, solution brief, technical
      brief, landing pages, etc.) that showcases your AWS solutions.
      <br />
      <br />
      Partner Sales Kick Off (SKO): AWS Partner event focused on your annual
      sales strategy and go-to-market with AWS.
      <br />
      <br /> Telemarketing Campaigns: Call campaigns and supporting assets (i.e.
      battlecard, sales call script, first call deck, telesales outreach, etc.)
      that showcase your AWS solutions.
      <br />
      <br /> AWS Led Joint Campaign (Invite Only): AWS Led Joint Campaign
      focusing on highlighting value that AWS and a select set of Technology and
      Consulting Competency Partners provide to customers.
      <br />
      <br /> Gifts/Giveaways/Swag: Logo items specifically associated with a MDF
      activity or AWS marketing campaign.
      <br />
      <br />
      Social/Recreational Events: Social and recreational costs for training or
      marketing events that showcase your AWS solutions. AWS provided
      opportunity to participate, provide training and materials.
      <br />
      <br /> Video Conferencing: Cost to host video conference services (ie.
      Zoom, GoToMeeting, WebEx) for events that showcase your AWS solutions.
      <br />
      <br />
      Catering: Catering of food, beverages and gratuities associated with an
      event that showcases your AWS solutions. Food vouchers for virtual events.
      (ie Doordash, Grubhub, Postmates etc.)
    </p>
  ),
  specializedWorkload: (
    <p>
      Migration workloads of specific technology, application platforms,
      services and industry verticals. For Connect workloads, complete and
      attach the{" "}
      <a href="https://partnercentral.awspartner.com/partnercentral2/s/resources?Id=0698W00000lzFMCQA2">
        Migration Complexity Assessment Questionnaire
      </a>{" "}
      to validate the customers ability to deploy $500K+ ARR in Connect
      workloads within the first 12 months of the agreement.
    </p>
  ),
  [SECTION_NAME.ATTACHMENTS]:
    'Please attach all supporting documents as required by each funding program, e.g., project plan, AWS Pricing Calculator, Migration Capability Review, etc. | Note: the following file types are supported for attachments: .doc, .docx, .xlsx,.pptx, .pdf,.png,.jpeg,.svg,.csv. | Formatting Note: the following file name formats are supported for attachments: alpha numeric, spaces, "-" and "_". All other special characters are not supported. | Capacity Note: The maximum file size is 25MB per file and the maximum number of attachments for a fund request and associated claims is 20 unique files. | Innovation Sandbox: a Project Plan and AWS Pricing Calculator may be required to support your Innovation Sandbox request below 10K USD. For requests over 10K USD, an Innovation Plan will be required to support your Innovation Sandbox request. To attach your AWS Pricing Calculator, either upload a .doc file with the URL string embedded or attach the output file from the AWS Pricing Calculator website. | Market Development Funds (MDF): a Marketing Plan is an optional attachment to support your MDF fund request. | Proof-of-Concept (POC): A Project Plan/Checklist is required for any requests greater than or equal to $10k in eligilble funding. An AWS Pricing Calculator is required for all fund request submissions. | Migration Acceleration Program (MAP) : a Project Plan/Checklist is required to support your MAP fund request. An AWS Pricing Calculator is required for all phases except Assess Phase Requests with ARR under $250K.  Partners participating in MAP Autopilot can their MAP Autopilot Checklist as a "Project Plan/Checklist" file type. Please reach out to your Partner Manager if you have questions about these document requirements for each program that you are applying for.',
  invoiceEntityName:
    "This name must match your company’s official tax documents and your Amazon Payee Central Account.",
  invoiceRemitAddress:
    "This address must match your company’s official tax documents, invoices and your Amazon Payee Central Account.",
  FILE_TYPE_MISMATCH:
    "File contents do not match filetype. Please note that allowed file types are: pdf, doc, docx, xlsx, pptx, csv.",
  FILE_SIZE_OVER_ALLOWED_LIMIT:
    "Max file size is 10MB. Please re-upload reducing the size of the file.",
  INFECTED_FILE: "Malware detected. Please re-upload a clean file.",
  MALFORMED_CSV:
    "Please verify if all rows have the same length and do not contain any disallowed formula. Also please note that CSV file with less than 2 rows/columns are not allowed.",
  MALFORMED_XLSX:
    "Invalid formula detected. Please double check the formula and re-upload.",
  UNKNOWN:
    "There was an error escaping formula characters. Please double check the formula and re-upload.",
  // Internal Only
  programEngagementId: (
    <p>
      Info: A Qualified+ Migration Program Engagement (MPE) is required for each
      MAP Large Migration ({">"}$500K ARR) Fund Request. Each phase of MAP
      funding requested for a migration opportunity must use the same MPE.
      <br />
      <br />
      Info: If you have the legacy 5 digit MPE ID,{" "}
      <a
        href={PROGRAM_ENGAGEMENT_NEW_ID_GUIDE_LINK}
        target="_blank"
        rel="noopener noreferrer"
      >
        learn
      </a>{" "}
      how to retrieve the updated 10 character alphanumeric ID with the legacy 5
      digit ID.
    </p>
  ),
  migrationBdApproved:
    "MAP BD approval is only required for large (>$500K ARR) MAP Partner Funding opportunities. The MAP BD will provide their approval on the Migration Program Engagement (MPE) record, and the MAP BD approval not be required for each individual phase of funding. ",
  peCustomer:
    'PE MAP eligibility can be determined in the customer\'s AWS Salesforce Account by going to the "Private Equity Backing" field in the "GTM attributes" section.',
  startupCustomer:
    "Startup eligibility can be determiend in customer's AWS Salesforce Account or AWS Opportunity Segment.",
  mraCompleted:
    "A Migration Readiness Assessment (MRA) must be completed prior to the Large Migration (>$500K) MAP Migrate & Modernize Phase. The MRA is not required for Small Migrations (<$500K).",
  partnerSolutionArchitect:
    "Info: For MAP, only trained Partner Solution Architects are eligible to review the SOW/Project Plan. See the MAP Enabled Partner Solution Architect list <https://w.amazon.com/bin/view/AWS/Teams/Partner_SA/Technology_Partner_SA_Team/Segment/Migration/MigrationTeam/PSACertified>. | Please input the correct PSA's alias.",
  // External only
  migrationPhase:
    "Eligible AWS Services Path Partners with the AWS Migration Competency, or acting with their relevant AWS Workload Competency/Service Delivery Designation can leverage MAP Partner Funding at each phase of the customer migration journey: Assess, Mobilize, Migrate & Modernize. | Partners at Validated Stage on the Software, Services, or Hardware Partner Path may be eligible for the Small (<$500K ARR) Migrate & Modernize baseline incentive, or POC incentives. If you are uncertain about which phase of funding should be requested, please review MAP section of the APN Partner Funding Benefits Guide, or consult your Partner Sales Manager. | Note: POC Funding Selection can be made in the proceeding steps by selecting Proof-of-Concept under the Project Type field.",
  annualRunRate:
    "Annual Recurring Revenue (ARR) Once in Production (USD) is the total annual revenue amount AWS will receive for the opportunity. This amount should match the total opportunity amount (MRR x 12 months) | | Proof-of-Concept Eligibility | For projects where the customer has not committed to adopting AWS, and the anticipated post-migration ARR is below $250K (USD). POC funding cannot be combined with MAP incentives, but MAP incentives can be requested upon successful completion of a POC where the customer agrees to a larger migration or modernization engagement. POC funding will be deducted from subsequent MAP funding. If ARR is greater than $250K (USD), it is recommended that the POC/Pilot activity be conducted through the MAP Mobilize Phase. | | Migration Eligibility | Migration Acceleration Program (MAP) requests with ARR greater than or equal to 500K (USD):  All migration phases for these requests require AWS Services Path Partner with the Migration Competency, or relevant Service Delivery Designation/Workload  Competency for all the phases (Asses, Mobilize and Migrate & Modernize) | Migration Acceleration Program (MAP) requests with ARR less than 500K (USD):  Assess and Mobilize phases requests require AWS Services Path Partner with the Migration Competency, or relevant Service Delivery Designation/Workload Competency | Migration Acceleration Program (MAP) requests with ARR less than 500K (USD):  Migrate & Modernize phase require AWS Partner at Validated Stage or higher on the Services Partner Path",
  [SECTION_NAME.FUND_REQUEST_INFORMATION]:
    "All pre-approved funding amounts are inclusive of any applicable taxes and fees, including Value Added Tax (VAT), Goods and Services Tax (GST) and similar taxes.",
  awsCalculatorUrl: (
    <p>
      Please click one of the links mentioned below to navigate to the AWS
      Pricing Calculator or the AWS Monthly Calculator homepage.
      <br />
      <a
        href={"https://calculator.aws/#/"}
        target="_blank"
        rel="noopener noreferrer"
      >
        {"https://calculator.aws/#/"}
      </a>
      <br />
      <a
        href={"https://calculator.s3.amazonaws.com/index.html"}
        target="_blank"
        rel="noopener noreferrer"
      >
        {"https://calculator.s3.amazonaws.com/index.html"}
      </a>
      <br />
      For fund requests that require multiple AWS Pricing Calculators, enter
      each hyperlink/URL string separated by a comma (,) and ensure there are no
      empty spaces in between each entry.
    </p>
  ),
  [SECTION_NAME.MIGRATION_DETAILS]:
    'To remove a specialized workload, click the "Add Another Workload\' button and then click the "Remove" button in the top right corner of the Specialized Workload section needing to be removed.',
  [SECTION_NAME.MIGRATION_WORKLOADS]:
    "Applications, Services, Data, Programs, etc. considered to be migrated from on-premise/public cloud/other cloud providers to AWS Cloud.",
  [SECTION_NAME.CASH_CLAIM_ACTUALS]:
    "Cash claim actuals must be approved prior to submitting webform invoice.",
  [SECTION_NAME.CASH_REQUEST_INFORMATION]: (
    <p>
      The{" "}
      <a
        href={FINANCIAL_APPROVAL_GUIDE_LINK}
        target="_blank"
        rel="noopener noreferrer"
      >
        Finance Approval Guide
      </a>{" "}
      provides guidance how to accurately complete cash request information
      using your company’s accounts in Payee Central to reduce likelihood of
      future Fund Request rejections at the Finance Approval stage which delays
      funding and approvals.
    </p>
  ),
  currencyConversionRateToUsd:
    "This is the associated USD conversion rate for the chosen local currency that has been applied at the time of fund request pre-approval to convert local currency into USD.",
};

export const selfServiceHelperTextEntityMap = {
  sandboxDesignWinActivity: "InfoSandboxDesignWinActivity",
  majorityPublicSector: "InfoMajorityPublicSector",
  [SECTION_NAME.OPPORTUNITY_INFORMATION]: "InfoOpportunityInformation",
  executedState: "InfoLocationStateActivityIsExecuted",
  workloadName: "InfoWorkloadName",
  arn: "InfoWorkloadArn",
  awsAccountId: "InfoAwsAccountId",
  numberOfClaims: "InfoNumberOfClaims",
  mdfActivityType: "InfoMdfCashActivityType",
  percentageCompletion: "InfoPercentageProjectCompletion",
  expectedRevenueRamp: "InfoExpectedRevenueRamp",
  totalCostOfMdfActivity: "InfoTotalCostOfMdfActivity",
  attachment: "InfoAttachment",
  mdfCreditActivityType: "InfoMdfCreditActivityType",
  specializedWorkload: "InfoSpecializedWorkloads",
  [SECTION_NAME.ATTACHMENTS]: "InfoFileAttachments",
  invoiceEntityName: "InfoInvoiceEntityName",
  invoiceRemitAddress: "InfoInvoiceRemitAddress",
  FILE_TYPE_MISMATCH: "InfoFileTypeMismatch",
  FILE_SIZE_OVER_ALLOWED_LIMIT: "InfoFileSizeOverAllowedLimit",
  INFECTED_FILE: "InfoFileInfected",
  MALFORMED_CSV: "InfoFileMalformedCsv",
  MALFORMED_XLSX: "InfoFileMalformedXlsx",
  UNKNOWN: "InfoFileUnkown",
  // Internal Only
  programEngagementId: "InfoProgramEngagementId",
  migrationBdApproved: "InfoMigrationBdApproved",
  peCustomer: "InfoPeCustomer",
  startupCustomer: "InfoStartupCustomer",
  mraCompleted: "InfoMraCompleted",
  partnerSolutionArchitect: "InfoPartnerSolutionArchitect",
  // External only
  migrationPhase: "InfoMigrationPhase",
  annualRunRate: "InfoAnnualRunRate",
  [SECTION_NAME.FUND_REQUEST_INFORMATION]: "InfoFundRequestInformation",
  awsCalculatorUrl: "InfoAwsPricingCalculator",
  [SECTION_NAME.MIGRATION_DETAILS]: "InfoMigrationDetails",
  [SECTION_NAME.MIGRATION_WORKLOADS]: "InfoMigrationWorkloads",
  [SECTION_NAME.CASH_CLAIM_ACTUALS]: "InfoCashClaimActuals",
  [SECTION_NAME.CASH_REQUEST_INFORMATION]: "InfoCashRequestInformation",
  currencyConversionRateToUsd: "InfoUsdConversionRate",
};

export const getSelfServiceHelperText = async (helperTextEntityType) => {
  try {
    const doc = await getSelfServiceEntityValues(helperTextEntityType);
    if (Object.keys(doc)?.length !== 0) {
      let content = "";
      doc[helperTextEntityType]?.forEach((item) =>
        item.name === "Text"
          ? (content += escape(item.description))
          : (content += item.description)
      );
      const cleanContent = DOMPurify.sanitize(content, {
        USE_PROFILES: { html: true },
      });
      return parse(cleanContent);
    }
  } catch (e) {
    console.error(e);
    return "";
  }
  return "";
};

const escape = (htmlStr) => {
  return htmlStr
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#39;");
};
