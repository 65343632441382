export const queryToString = (query, filteringProperties) => {
  return query.tokens
    .map(({ operator, value, propertyKey }) => {
      if (propertyKey) {
        const property = filteringProperties?.find(
          ({ key }) => key === propertyKey
        );
        const keyLabel = property?.propertyLabel ?? propertyKey;

        let valueLabel = value;

        if (property && property.operators) {
          property.operators.forEach((propertyOperator) => {
            if (
              typeof propertyOperator !== "string" &&
              propertyOperator.operator === operator &&
              propertyOperator.format
            ) {
              valueLabel = propertyOperator.format(value);
            }
          });
        }

        return `${keyLabel} ${operator} ${valueLabel}`;
      }
      return value;
    })
    .join(`, ${query.operation} `);
};

export const isQueryEqual = (queryA, queryB) => {
  return (
    queryA.operation === queryB.operation &&
    queryA.tokens.length === queryB.tokens.length &&
    queryA.tokens.every((_, i) => {
      return (
        queryA.tokens[i].operator === queryB.tokens[i].operator &&
        queryA.tokens[i].value === queryB.tokens[i].value &&
        queryA.tokens[i].propertyKey === queryB.tokens[i].propertyKey
      );
    })
  );
};
