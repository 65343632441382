import React from "react";
import { ColumnLayout, Container } from "@amzn/awsui-components-react";
import { v4 as uuid } from "uuid";
import Header from "@amzn/awsui-components-react/polaris/header";
import PropTypes from "prop-types";
import { CashClaimAction } from "./CashClaimAction";
import { getCashClaimStage } from "../../../../../shared/components/Review/CashClaim/util";

export const CashClaimReviewSectionContainer = ({
  history,
  fundRequestId,
  fundingData,
  cashClaimId,
  planFields,
  actualFields,
  invoiceFields,
  cashClaimAttachmentComponent,
  setNotificationsItems,
}) => {
  const cashClaimStage = getCashClaimStage(fundingData, cashClaimId);

  return (
    <Container
      key={uuid()}
      header={
        <Header
          actions={
            <CashClaimAction
              cashClaimId={cashClaimId}
              cashClaimStage={cashClaimStage}
              history={history}
              fundRequestId={fundRequestId}
              stage={fundingData["stage"]}
              status={fundingData["status"]}
              setNotificationsItems={setNotificationsItems}
            />
          }
        >
          Cash Claim
        </Header>
      }
    >
      <ColumnLayout columns={1}>
        <div className="claim-section">
          <h4>Plans</h4>
          <ColumnLayout columns={4} borders="vertical">
            {planFields}
          </ColumnLayout>
        </div>
        <div className="claim-section">
          <h4>Actuals</h4>
          <ColumnLayout columns={4} borders="vertical">
            {actualFields}
          </ColumnLayout>
        </div>
        <div>
          <h4>Invoice & Payment</h4>
          <ColumnLayout columns={4} borders="vertical">
            {invoiceFields}
          </ColumnLayout>
        </div>
        {cashClaimAttachmentComponent}
      </ColumnLayout>
    </Container>
  );
};

CashClaimReviewSectionContainer.propTypes = {
  history: PropTypes.any,
  fundRequestId: PropTypes.string,
  fundingData: PropTypes.object,
  cashClaimId: PropTypes.string,
  planFields: PropTypes.array,
  actualFields: PropTypes.array,
  invoiceFields: PropTypes.array,
  cashClaimAttachmentComponent: PropTypes.element,
  setNotificationsItems: PropTypes.func,
};
