/* eslint react/prop-types: 0 */
import { COMPONENT_TYPE } from "../../../../shared/util/constants/componentType";
import { statusBadge } from "../../../../shared/components/common/StatusBadge";
import { SECTION } from "../../../../shared/util/constants/section";
import { convertSMPtoMAP } from "../../../../shared/util/common/convertSMPtoMAP";
import { isString } from "../../../../shared/util/services/data/DataService";
import { ASSIGNEE_TYPE } from "../Header/util";
import { generatePhonetoolLink } from "../../../util/constants/generatePhonetoolLink";
import { generatePricingCalculatorLink } from "../../../util/constants/generatePricingCalculatorLink";
import Config from "config";
import {
  generateAWSOpportunityLink,
  validateOpportunityId,
} from "../../../util/constants/generateAWSOpportunityLink";
import { generateEngagementIdLink } from "../../../util/constants/generateEngagementIdLink";
import useFeatureFlag from "../../../../shared/util/hooks/useFeatureFlag";
import { FEATURE_FLAG_ENUMS } from "../../../../shared/util/constants/featureFlagValues";
import CopyToClipboardDataField from "../../../../shared/components/common/CopytoClipboard/DataField";
import React from "react";
import { formatMigrationARR } from "./MigrationEngagement/util";
import { generateOpportunityIdLink } from "../../../../shared/util/common/generateOpportunityIdLink";
import { getIsvWmpSecondTrancheClaimConsentStatus } from "../../../../shared/components/FundRequest/Review/Sections/dataValueFactory";

export const ValueFactory = ({ apiKey, data, field, name, sectionTitle }) => {
  if (field.label === "PO Currency") {
    return data[apiKey]["currency"];
  }

  switch (name) {
    case "mpeId":
      return (data?.mpeId && generateEngagementIdLink(data?.mpeId)) || "-";
    case "migrationArr": {
      const value = data && data[name];
      return formatMigrationARR(value);
    }
    case "fundRequestOwner":
      return data["owner"]["userName"];
    case "fundRequestId":
      return (
        <CopyToClipboardDataField
          copyValue={data && data["fundRequestId"]}
          popoverText="Fund Request Id Copied"
        />
      );
    case "assignee": {
      let value = "-";
      const { assigneeType, assigneeId } = data && data["assignee"];

      if (assigneeType && assigneeId) {
        if (assigneeType === ASSIGNEE_TYPE.person) {
          value = generatePhonetoolLink(assigneeId);
        } else {
          value = assigneeId;
        }
      }

      return value;
    }
    case "awsCalculatorUrl":
      return generatePricingCalculatorLink(data);
    case "opportunityId": {
      let value = "-";
      const aceOpportunityId = data && data["aceOpportunityId"];
      const friendlyOpportunityId =
        data &&
        data["opportunityDetail"] &&
        data["opportunityDetail"]["opportunityId"];
      if (aceOpportunityId && friendlyOpportunityId) {
        const baseUrl = Config.opportunityBaseUrl;
        value = generateOpportunityIdLink({
          aceOpportunityId,
          friendlyOpportunityId,
          baseUrl,
        });
      }
      return value;
    }
    case "awsOpportunityId": {
      if (
        data &&
        data["opportunityDetail"] &&
        data["opportunityDetail"]["awsOpportunityId"] &&
        validateOpportunityId(data["opportunityDetail"]["awsOpportunityId"])
      ) {
        return generateAWSOpportunityLink(
          data["opportunityDetail"]["awsOpportunityId"]
        );
      }
      return "-";
    }
    case "programEngagementId": {
      if (
        useFeatureFlag(FEATURE_FLAG_ENUMS.MAP_WF_MPE_POPULATION) &&
        data &&
        data["project"] &&
        data["project"]["programEngagementId"]
      ) {
        const engagement_id = data["project"]["programEngagementId"];
        return generateEngagementIdLink(engagement_id);
      }
      if (data && data["project"] && data["project"]["programEngagementId"]) {
        return data["project"]["programEngagementId"];
      }
      return "-";
    }
    case "extendedStartDate":
    case "extendedEndDate":
    case "extendedReason": {
      let value = data["projectExtension"][name] || "-";
      if (Object.keys(value).includes("timestamp")) {
        value = new Date(value.timestamp).toLocaleDateString();
      }

      return value;
    }
    case "program":
      return convertSMPtoMAP(data["fundingTemplate"][name]) || "-";
    case "fundingType":
      return data["fundingTemplate"][name] || "-";
    case "mdfFundingPercentage": {
      const value = data && data["plan"]["mdfFundingPercentage"];
      return value && Math.floor(value);
    }
    case "requestedCreditAmountEditable": {
      const value =
        data && data["creditRequest"]["requestedCreditAmount"]["units"];
      return `${parseFloat(value).toLocaleString("en-US")}`;
    }
    case "status": {
      let status = "-";

      if (Object.keys(data).includes("status")) {
        status = data["status"];
      }

      if (data[apiKey] && Object.keys(data[apiKey]).includes("status")) {
        status = data[apiKey]["status"];
      }

      if (sectionTitle === SECTION.FUND_REQUEST_INFORMATION) {
        status = statusBadge(data["status"]);
      }

      return status;
    }
    case "secondTrancheClaimConsent":
      return getIsvWmpSecondTrancheClaimConsentStatus(data);
    default: {
      let value = "-";

      if (data && (data[name] || (data[apiKey] && data[apiKey][name]))) {
        value = data[name] || data[apiKey][name];

        if (field.component === COMPONENT_TYPE.CHECKBOX) {
          value = isString(value)
            ? value === "true"
              ? "Yes"
              : "-"
            : value
            ? "Yes"
            : "-";
        } else if (field.component === COMPONENT_TYPE.DATEPICKER && value) {
          value = new Date(value.timestamp).toLocaleDateString();
        } else if (field.type === "money" && value && value.units) {
          if (!value.units || isNaN(value.units)) {
            value = "-";
          } else {
            value = `${parseFloat(value.units).toLocaleString("en-US")}`;
          }
        } else if (Object.keys(value).includes("units") && value) {
          value = value.units;
        }
      }

      return value;
    }
  }
};
