import React, { useEffect, useState } from "react";
import Header from "@amzn/awsui-components-react/polaris/header";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Container from "@amzn/awsui-components-react/polaris/container";
import Tiles from "@amzn/awsui-components-react/polaris/tiles";
import Box from "@amzn/awsui-components-react/polaris/box";
import Button from "@amzn/awsui-components-react/polaris/button";
import DOMPurify from "dompurify";

import {
  ERROR_TYPE,
  hasError,
  retryApiCall,
} from "../../../../shared/util/services/data/DataService";
import templateMappingData from "./templateMappingData";
import withAppLayout from "../../../../shared/components/withAppLayout";
import { getTemplate, getProgramOptions, getFundingTypeOptions } from "./util";
import {
  createSecondGranularityTimestamp,
  getSfdcLandingPageLink,
} from "../../../util/common/helper";
import { getSubjectId } from "../../../../shared/util/services/auth/AuthService";

import "./style.css";
import { ColumnLayout, FormField } from "@amzn/awsui-components-react";
import Input from "@amzn/awsui-components-react/polaris/input";
import Select from "@amzn/awsui-components-react/polaris/select";
import { migrationPhase } from "../../../../shared/util/constants/staticOptions";
import { MIGRATION_PHASE } from "../../../../shared/util/constants/migrationPhaseType";
import { createFundRequest } from "../../../util/services/data/FundRequestService";
import { setErrorMessage } from "../../../../shared/util/common/helper";
import { getBreadcrumbs } from "../../../../shared/util/common/getBreadcrumbs";
import { TITLE } from "../../../../shared/util/constants/title";
import {
  FUNDING_TYPE,
  PROGRAM_TYPE,
} from "../../../../shared/util/constants/programType";
import { convertSMPtoMAP } from "../../../../shared/util/common/convertSMPtoMAP";
import { useHistory } from "react-router-dom";
import { HelperPanel } from "../../../../shared/components/FundRequest/Help/HelperPanel";
import useHelpText from "../../../../shared/util/hooks/useHelpText";
import {
  helperTextMap,
  selfServiceHelperTextEntityMap,
} from "../../../../shared/util/constants/helperText";
import Link from "@amzn/awsui-components-react/polaris/link";
import SubProgram from "./SubProgram";
import Loading from "../../../../shared/components/common/Loading";
import { FEATURE_FLAG_ENUMS } from "../../../../shared/util/constants/featureFlagValues";
import { isFeatureEnabled } from "../../../../shared/util/services/features/FeatureFlagsService";
import { AWS_JUMPSTART_PROGRAM_NAME } from "shared/util/constants/subProgram";

const ProgramSelect = () => {
  const history = useHistory();

  const ANNUAL_RUN_RATE_KEY = "annualRunRate";
  const MIGRATION_PHASE_KEY = "migrationPhase";

  const template = getTemplate(window);

  if (!templateMappingData[template]) {
    history.push("/");
    return null;
  }

  const tilesClassNames = [
    "program-select-tiles-1-item",
    "program-select-tiles-2-items",
  ];

  const [programOptions, setProgramOptions] = useState(
    getProgramOptions(templateMappingData[template].programs)
  );

  const [notifications, setNotifications] = useState([]);
  const [programValue, setProgramValue] = useState(
    programOptions.length ? programOptions[0].value : ""
  );
  const [fundingTypeValue, setFundingTypeValue] = useState("");
  const [fundingTypeOptions, setFundingTypeOptions] = useState([]);
  const [state, setState] = useState({});
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const {
    isHelperPanelOpen,
    helperPanelContent,
    setIsHelperPanelOpen,
    showHelperPanel,
  } = useHelpText();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(async () => {
    document.title = DOMPurify.sanitize(`${TITLE} - New Fund Request`);
    if (
      template === PROGRAM_TYPE.PIF &&
      !(await isFeatureEnabled(FEATURE_FLAG_ENUMS.PIF_ENABLED))
    ) {
      history.push("/");
    }
    updateFundingType({ programValue });
    await handleFeatureFlagSubProgram();
  }, [template]);

  const handleFeatureFlagSubProgram = async () => {
    if (
      await isFeatureEnabled(FEATURE_FLAG_ENUMS.AWS_JUMPSTART_PROGRAM_DISABLED)
    ) {
      var programOptionsWithoutAwsJumpstartProgram = programOptions.filter(
        (programOption) => programOption.value !== AWS_JUMPSTART_PROGRAM_NAME
      );
      var programValue = programOptionsWithoutAwsJumpstartProgram.length
        ? programOptionsWithoutAwsJumpstartProgram[0].value
        : "";
      setProgramOptions(programOptionsWithoutAwsJumpstartProgram);
      updateFundingType({ programValue });
    }
    setIsLoading(false);
  };

  const updateFundingType = ({ programValue }) => {
    const programs = templateMappingData[template].programs;
    let fundingTypes = [];

    if (Object.keys(programs).includes(programValue)) {
      fundingTypes = programs[programValue].typesOfFunding;
    }
    setFundingTypeOptions(getFundingTypeOptions(fundingTypes));
  };

  const submitData = async () => {
    setLoading(true);
    let subProgram = programValue;

    if (template === PROGRAM_TYPE.PIF) {
      subProgram = "Well Architected Partner Program";
    }

    const data = {
      program: template,
      subProgram,
      fundingType: fundingTypeValue,
      accountPifProgramId: programValue,
    };

    if (
      [PROGRAM_TYPE.SMP, PROGRAM_TYPE.POC, PROGRAM_TYPE.PIF].includes(template)
    ) {
      const phase = state[MIGRATION_PHASE_KEY];
      const annualRunRate = state[ANNUAL_RUN_RATE_KEY];

      if (
        [PROGRAM_TYPE.SMP].includes(template) &&
        fundingTypeValue === FUNDING_TYPE.CREDIT &&
        phase !== MIGRATION_PHASE.MIGRATE_MODERNIZE
      ) {
        setErrorMessage({
          setNotificationsItems: setNotifications,
          content: `Only ${MIGRATION_PHASE.MIGRATE_MODERNIZE} is available for Credit.`,
        });
        setLoading(false);
        return;
      }

      if (
        phase === MIGRATION_PHASE.ASSES_BUSINESS_CASE &&
        annualRunRate < 250000
      ) {
        updateError(
          ANNUAL_RUN_RATE_KEY,
          "Annual Run Rate must be greater than $250k USD for Assess Business Case Phase"
        );
        setLoading(false);
        return;
      } else if (phase === MIGRATION_PHASE.MOBILIZE && annualRunRate < 250000) {
        updateError(
          ANNUAL_RUN_RATE_KEY,
          "Annual Run Rate must be greater than $250k USD for Mobilize Phase"
        );
        setLoading(false);
        return;
      } else if (
        (!annualRunRate || annualRunRate < 1) &&
        template !== PROGRAM_TYPE.PIF
      ) {
        updateError(
          ANNUAL_RUN_RATE_KEY,
          "Annual Run Rate must be greater than 0 USD."
        );
        setLoading(false);
        return;
      }
      data[ANNUAL_RUN_RATE_KEY] = {
        units: parseInt(annualRunRate),
        nanos: 0,
        currency: "USD",
      };

      data[MIGRATION_PHASE_KEY] = phase;
    }

    await submitToApi({
      clientToken: `create-${template}-${fundingTypeValue}-${getSubjectId()}-${createSecondGranularityTimestamp()}`,
      ...data,
    });
  };

  const submitToApi = async (payload) => {
    try {
      const response = await retryApiCall({
        callApi: createFundRequest(payload),
      });
      console.log(response);
      //TODO: Add failure condition
      if (hasError(response) && response.errorType === ERROR_TYPE.BANNER) {
        setErrorMessage({
          setNotificationsItems: setNotifications,
          content: response.message,
          status: response.status,
        });
        setLoading(false);
        return;
      }

      history.push(`/fund-request/${response.fundRequestId}/draft`, {
        ...response,
        fundingType: fundingTypeValue,
        program: template,
        subProgram: programValue,
        origin: "ProgramSelect",
      });
    } catch (err) {
      setErrorMessage({
        setNotificationsItems: setNotifications,
      });
      setLoading(false);
      console.error(err);
    }
  };

  const updateField = (key, value) => {
    setState({ ...state, [key]: value });
  };

  const updateError = (key, value) => {
    setError({ ...error, [key]: value });
  };

  const disabled =
    template === PROGRAM_TYPE.SMP
      ? !(
          fundingTypeValue &&
          programValue &&
          state[MIGRATION_PHASE_KEY] &&
          state[ANNUAL_RUN_RATE_KEY]
        )
      : template === PROGRAM_TYPE.POC
      ? !(fundingTypeValue && programValue && state[ANNUAL_RUN_RATE_KEY])
      : !(fundingTypeValue && programValue);

  console.log({ fundingTypeOptions });
  return withAppLayout({
    component: isLoading ? (
      <Loading />
    ) : (
      <SpaceBetween size="l">
        <Header variant="h1">
          Select {convertSMPtoMAP(templateMappingData[template].name)}
        </Header>
        <SubProgram
          template={template}
          programOptions={programOptions}
          programValue={programValue}
          setFundingTypeValue={setFundingTypeValue}
          setFundingTypeOptions={setFundingTypeOptions}
          setProgramValue={setProgramValue}
          updateFundingType={updateFundingType}
        />
        <Container header={<Header variant="h2">Types of Funding</Header>}>
          <SpaceBetween size="s">
            <Box>Select type of funding</Box>

            <Tiles
              className={tilesClassNames[fundingTypeOptions.length - 1]}
              onChange={({ detail }) => {
                setFundingTypeValue(detail.value);
                setState({});
                setError({});
              }}
              value={fundingTypeValue}
              items={fundingTypeOptions}
            />
          </SpaceBetween>
        </Container>
        {[PROGRAM_TYPE.SMP, PROGRAM_TYPE.POC, PROGRAM_TYPE.PIF].includes(
          template
        ) ? (
          <Container
            header={
              <Header variant="h2">
                {template === PROGRAM_TYPE.SMP
                  ? "Migration Eligibility"
                  : "Annual Recurring Revenue"}
              </Header>
            }
          >
            <SpaceBetween size="s">
              <ColumnLayout columns={2}>
                {template === PROGRAM_TYPE.SMP && (
                  <FormField
                    label="Migration Phase"
                    errorText={error[MIGRATION_PHASE_KEY]}
                    info={
                      (
                        helperPanelContent.selfServiceHelperTextFlag
                          ? selfServiceHelperTextEntityMap["migrationPhase"]
                          : helperTextMap["migrationPhase"]
                      ) ? (
                        <Link
                          variant="Info"
                          onFollow={() =>
                            showHelperPanel({
                              title: "Migration Phase",
                              keys: ["migrationPhase"],
                              open: true,
                            })
                          }
                        >
                          Info
                        </Link>
                      ) : (
                        ""
                      )
                    }
                  >
                    <Select
                      id="migrationPhase"
                      selectedOption={{
                        label: state[MIGRATION_PHASE_KEY],
                        value: state[MIGRATION_PHASE_KEY],
                      }}
                      className="step-details-field"
                      placeholder="Choose an option"
                      filteringType="auto"
                      onChange={({ detail }) => {
                        updateField(
                          MIGRATION_PHASE_KEY,
                          detail.selectedOption.value
                        );
                        setError({});
                      }}
                      options={
                        fundingTypeValue
                          ? fundingTypeValue === FUNDING_TYPE.CREDIT
                            ? [
                                {
                                  label: MIGRATION_PHASE.MIGRATE_MODERNIZE,
                                  value: MIGRATION_PHASE.MIGRATE_MODERNIZE,
                                },
                              ]
                            : migrationPhase
                          : []
                      }
                    />
                  </FormField>
                )}
                <FormField
                  label="Annual Recurring Revenue (ARR) Once In Production (USD)"
                  errorText={error[ANNUAL_RUN_RATE_KEY]}
                  description="AWS ARR value entered must match the Simple Monthly Calculator ARR value."
                  info={
                    (
                      helperPanelContent.selfServiceHelperTextFlag
                        ? selfServiceHelperTextEntityMap["annualRunRate"]
                        : helperTextMap["annualRunRate"]
                    ) ? (
                      <Link
                        variant="Info"
                        onFollow={() =>
                          showHelperPanel({
                            title: "Annual Run Rate",
                            keys: ["annualRunRate"],
                            open: true,
                          })
                        }
                      >
                        Info
                      </Link>
                    ) : (
                      ""
                    )
                  }
                >
                  <Input
                    id="annualRunRate"
                    value={
                      state[ANNUAL_RUN_RATE_KEY]
                        ? !isNaN(state[ANNUAL_RUN_RATE_KEY])
                          ? parseFloat(
                              state[ANNUAL_RUN_RATE_KEY]
                            ).toLocaleString("en-US")
                          : state[ANNUAL_RUN_RATE_KEY]
                        : ""
                    }
                    placeholder="Please enter annual run rate for the fund request."
                    onChange={({ detail }) => {
                      const value = detail.value.replace(/,/g, "");
                      const arrPattern = /^\d+$/;
                      if (arrPattern.test(value)) {
                        updateError(ANNUAL_RUN_RATE_KEY, "");
                        updateField(ANNUAL_RUN_RATE_KEY, value);
                      } else {
                        updateError(
                          ANNUAL_RUN_RATE_KEY,
                          "Must be a number value"
                        );
                        updateField(ANNUAL_RUN_RATE_KEY, value);
                      }
                    }}
                  />
                </FormField>
              </ColumnLayout>
            </SpaceBetween>
          </Container>
        ) : (
          ""
        )}
        <Header
          variant="h1"
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                variant="link"
                onClick={() =>
                  window.location.replace(getSfdcLandingPageLink())
                }
              >
                Cancel
              </Button>
              <Button
                id="apfp-canary"
                data-canary-identifier="apfp-canary"
                variant="primary"
                disabled={disabled}
                loading={loading}
                onClick={async () => {
                  await submitData();
                }}
              >
                Let&apos;s get started
              </Button>
            </SpaceBetween>
          }
        />
      </SpaceBetween>
    ),
    breadcrumbs: getBreadcrumbs({ text: "Apply for the program" }),
    notificationsItems: notifications,
    toolsContent: <HelperPanel content={helperPanelContent} />,
    isToolsOpen: isHelperPanelOpen,
    onToolsChange: ({ detail }) => {
      setIsHelperPanelOpen(detail.open);
    },
  })();
};

export default ProgramSelect;
